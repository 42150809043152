import {Tab, TabList, Tabs} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import Section from '@root/components/Section'
import LaunchVariablesTab from '@root/pages/report/ReportViewPage/components/LaunchVariablesTab'
import ReportDataTab from '@root/pages/report/ReportViewPage/components/ReportDataTab'
import ReportStatisticsTab from '@root/pages/report/ReportViewPage/components/ReportStatisticsTab'
import {useState} from 'react'
import ReportAffilationSection from './ReportAffilationSection'
import ReportDataAndTasksSection from './ReportDataAndTasksSection'
import ReportDataSection from './ReportDataSection'

const ReportTabLabels = {
  data: 'Данные',
  stats: 'Статистика',
  variables: 'Переменные теста',
}

const ReviewStep = ({form}) => {
  const [tab, setTab] = useState(ReportTabLabels.data)

  let currentTabContent
  if (tab === ReportTabLabels.data) currentTabContent = <ReportDataTab _form={form} />
  else if (tab === ReportTabLabels.stats) currentTabContent = <ReportStatisticsTab />
  else currentTabContent = <LaunchVariablesTab />

  return (
    <FlexboxColumn sx={{gap: '24px'}}>
      <ReportDataSection form={form} />
      <FlexboxRow sx={{gap: '24px'}}>
        <ReportAffilationSection form={form} />
        <ReportDataAndTasksSection form={form} />
      </FlexboxRow>
      <Section sx={{display: 'flex', flexDirection: 'column', gap: '24px', my: 0}}>
        <Tabs value={tab} onChange={(e, newTab) => setTab(newTab)}>
          <TabList>
            <Tab label={ReportTabLabels.data} value={ReportTabLabels.data} />
            <Tab label={ReportTabLabels.stats} value={ReportTabLabels.stats} />
            <Tab label={ReportTabLabels.variables} value={ReportTabLabels.variables} />
          </TabList>
        </Tabs>
        {currentTabContent}
      </Section>
    </FlexboxColumn>
  )
}

export default ReviewStep
