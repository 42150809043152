/**
 * Default inner cell component
 */

import React, {FC} from 'react'
import clsx from 'clsx'
import {useBodyCellStyles} from '../styles'
import {defaultCellDensity, getCellDensityClassNameBy} from '../../helpers'
import {TableCellLabel} from '../../CellLabel'
import {BodyCellInnerProps} from './types'
import {useBaseCellStyles} from '../../Cell'

export const BodyCellInner: FC<BodyCellInnerProps> = (props: BodyCellInnerProps) => {
  const {density = defaultCellDensity, noWrap, children: value} = props

  const baseClasses = useBaseCellStyles()
  const classes = useBodyCellStyles()
  const densityClassName = baseClasses[getCellDensityClassNameBy(density)]

  return (
    <div className={clsx(classes.innerRoot, densityClassName)}>
      <TableCellLabel bodyCell density={density} noWrap={noWrap}>
        {value}
      </TableCellLabel>
    </div>
  )
}

export default BodyCellInner
