import React, {FC} from 'react'
import {defineColors} from '../utils/defineColors'
import {LogoProps} from '../types'

const primary = '#5faf2d'
const getPathColor = defineColors('#000000')

const Logo: FC<LogoProps> = ({color, ...props}) => (
  <svg fill="none" viewBox="0 0 517 100" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M192.391 99.9027C206.088 99.9027 212.455 93.0929 212.455 85.1727C212.455 66.1495 181.063 74.0697 181.063 61.4123C181.063 56.897 184.839 53.196 193.649 53.196C198.24 53.196 203.348 54.5284 207.865 57.4152L210.308 51.4936C206.014 48.5328 199.72 46.9043 193.649 46.9043C180.026 46.9043 173.807 53.7142 173.807 61.7083C173.807 80.9536 205.199 72.9594 205.199 85.6168C205.199 90.132 201.349 93.611 192.391 93.611C185.727 93.611 179.138 91.0943 175.288 87.6154L172.548 93.3889C176.695 97.238 184.469 99.9027 192.391 99.9027Z"
      fill={getPathColor(color)}
    />
    <path
      d="M239.042 99.7547C250.814 99.7547 259.402 91.3904 259.402 79.6952C259.402 68 250.814 59.7098 239.042 59.7098C227.27 59.7098 218.607 68 218.607 79.6952C218.607 91.3904 227.27 99.7547 239.042 99.7547ZM239.042 93.537C231.49 93.537 225.789 88.0595 225.789 79.6952C225.789 71.3309 231.49 65.9275 239.042 65.9275C246.594 65.9275 252.22 71.3309 252.22 79.6952C252.22 88.0595 246.594 93.537 239.042 93.537Z"
      fill={getPathColor(color)}
    />
    <path d="M269.232 99.3105H276.34V44.3876H269.232V99.3105Z" fill={getPathColor(color)} />
    <path
      d="M319.378 60.0799V80.3614C319.378 88.7997 314.639 93.3889 307.235 93.3889C300.498 93.3889 296.648 89.5399 296.648 81.8418V60.0799H289.54V82.656C289.54 94.2032 296.278 99.7547 306.421 99.7547C311.974 99.7547 316.86 97.46 319.748 93.3889V99.3105H326.485V60.0799H319.378Z"
      fill={getPathColor(color)}
    />
    <path
      d="M359.859 91.9085C358.378 93.0928 356.379 93.759 354.306 93.759C350.382 93.759 348.161 91.3904 348.161 87.1712V65.9275H359.414V60.0799H348.161V51.4936H341.053V60.0799H334.389V65.9275H341.053V87.4673C341.053 95.3875 345.569 99.7547 353.491 99.7547C356.675 99.7547 359.933 98.8664 362.08 97.0159L359.859 91.9085Z"
      fill={getPathColor(color)}
    />
    <path
      d="M374.227 52.5299C377.04 52.5299 379.039 50.4573 379.039 47.7926C379.039 45.2759 376.966 43.2773 374.227 43.2773C371.487 43.2773 369.414 45.3499 369.414 47.9406C369.414 50.5313 371.487 52.5299 374.227 52.5299ZM370.673 99.3105H377.78V60.0799H370.673V99.3105Z"
      fill={getPathColor(color)}
    />
    <path
      d="M408.158 99.7547C419.93 99.7547 428.518 91.3904 428.518 79.6952C428.518 68 419.93 59.7098 408.158 59.7098C396.386 59.7098 387.723 68 387.723 79.6952C387.723 91.3904 396.386 99.7547 408.158 99.7547ZM408.158 93.537C400.606 93.537 394.905 88.0595 394.905 79.6952C394.905 71.3309 400.606 65.9275 408.158 65.9275C415.71 65.9275 421.337 71.3309 421.337 79.6952C421.337 88.0595 415.71 93.537 408.158 93.537Z"
      fill={getPathColor(color)}
    />
    <path
      d="M459.301 59.7098C453.082 59.7098 448.047 62.0044 445.16 66.0015V60.0799H438.348V99.3105H445.456V79.1031C445.456 70.5907 450.342 66.0755 457.894 66.0755C464.632 66.0755 468.482 69.8505 468.482 77.5486V99.3105H475.589V76.7344C475.589 65.1873 468.852 59.7098 459.301 59.7098Z"
      fill={getPathColor(color)}
    />
    <path
      d="M499.997 99.7547C510.436 99.7547 516.952 95.2394 516.952 88.1335C516.952 72.8854 492.075 80.4354 492.075 71.1829C492.075 68.0741 495.111 65.7794 501.33 65.7794C505.18 65.7794 509.104 66.5937 512.658 68.8883L515.693 63.2628C512.287 61.0422 506.512 59.7098 501.404 59.7098C491.335 59.7098 485.041 64.4471 485.041 71.479C485.041 87.0972 509.844 79.4732 509.844 88.2815C509.844 91.6124 507.105 93.611 500.515 93.611C495.407 93.611 490.076 91.9085 486.744 89.5399L483.783 95.1654C487.04 97.7561 493.482 99.7547 499.997 99.7547Z"
      fill={getPathColor(color)}
    />
    <path
      d="M93.6863 98.9857L82.6413 80.6327L71.8243 98.9857H58.8753L75.5122 71.3414L58.5549 45.6777H72.083L82.9446 62.8125L93.3041 45.6777H106.253L89.5733 72.1792L106.712 98.9857H93.6863Z"
      fill={getPathColor(color)}
    />
    <path
      d="M148.806 80.8642C148.806 92.5918 139.97 99.9025 128.392 99.9025C118.186 99.9025 109.593 94.2674 108.755 82.0053H119.8C120.561 87.3354 124.828 89.6209 129.474 89.6209C135.263 89.6209 137.761 85.7369 137.761 80.71C137.761 75.4553 133.876 72.1812 128.468 72.1812C123.975 72.1812 121.003 74.3142 119.632 76.5981H109.074L112.722 45.6797H147.446L146.287 56.0366H121.448L120.461 66.2685C120.461 66.2685 123.07 63.1948 130.977 63.1948C140.503 63.1966 148.806 69.5906 148.806 80.8642Z"
      fill={getPathColor(color)}
    />
    <path
      d="M110.059 39.0412C104.174 16.1103 85.5082 0 46.7694 0H0L6.34936 42.9218C11.1255 81.6512 25.9407 94.1087 52.1607 98.6987C52.2824 98.7175 53.1136 98.8357 53.1238 97.8643C53.1273 97.5148 52.9062 97.2132 52.6029 97.083C34.8812 89.6233 32.859 65.1589 29.0734 40.6106L26.1446 21.0463H52.8428C77.4588 21.0463 96.464 20.6574 108.496 39.6546C108.674 39.9218 108.954 40.1189 109.284 40.1189C109.749 40.1189 110.095 39.7419 110.095 39.2776C110.093 39.1937 110.083 39.1132 110.059 39.0412Z"
      fill={getPathColor(color, primary)}
    />
  </svg>
)

export default Logo
