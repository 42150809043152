import {FC, useContext} from 'react'
import {CustomFilterInputProps} from '@root/components/kit'
import {TemplateSettingsSelect} from '@root/features/launchHistory/TemplateSettingsSelect'
import {tableContext} from '../context'

export const FilterCollectorTemplateSettingsCombobox: FC<CustomFilterInputProps> = ({
  onChange,
  value,
  size,
}) => {
  const ctx = useContext(tableContext)
  if (ctx == null) {
    throw new Error('Context was null!')
  }

  return (
    <TemplateSettingsSelect
      selectProps={{
        size,
        multiple: true,
        stretch: true,
        label: 'Значение',
        chipText: 'Выбрано: ',
        usePortal: true,
      }}
      systemId={ctx.systemId}
      value={value as string}
      onChange={onChange}
    />
  )
}
