import {FC} from 'react'
import {StyleSheet, View} from '@react-pdf/renderer'
import {baseTheme} from '@root/components/kit'

const styles = StyleSheet.create({
  root: {
    height: '1px',
    width: '100%',
    backgroundColor: baseTheme.colors.grey[30],
  },
})

export const Hr: FC = () => {
  return <View style={styles.root} />
}
