import {makeUiStyles} from '../../../theme'
import {TableStyleHelpers} from '../../Cell/styles'

export const useInnerAutocompleteStyles = makeUiStyles(
  (theme) => ({
    root: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
        padding: theme.spaces.x6,
        ...theme.typography.p2,
      },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child':
        {
          paddingLeft: theme.spaces.x6,
        },
      '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        padding: 0,
      },
      '& .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]':
        {
          paddingRight: theme.spaces.x6,
        },
    },
    option: {
      ...theme.typography.p1,
      padding: theme.spaces.join('x2', 'x6'),
      userSelect: 'none',
      '&[aria-selected="true"]': {
        backgroundColor: `${theme.colors.accent[90]}!important`,
        color: theme.colors.white,
        '&:hover': {
          color: theme.colors.grey[100],
        },
      },
      '&[data-focus="true"]': {
        backgroundColor: `${theme.colors.grey[20]} !important`,
        color: theme.colors.grey[100],
      },
    },
    inputWrap: {
      ...TableStyleHelpers.getInnerInputWrap(theme),
      display: 'flex',
      alignItems: 'center',
    },
    inputWrapDisabled: {
      border: `2px solid ${theme.colors.grey[30]}!important`,
      '& > input': {
        color: theme.colors.grey[30],
      },
    },
    input: {
      flex: 1,
      boxSizing: 'border-box',
      width: '100%',
      outline: 'none',
      border: 'none',
      ...theme.typography.p2,
      padding: 0,
      lineHeight: '22px',
      '&::selection': {
        backgroundColor: theme.colors.accent[20],
      },
    },
    iconUp: {
      color: theme.colors.grey[60],
      pointerEvents: 'none',
    },
    loader: {
      '& .x5-MuiCircularProgress-root': {
        width: `${theme.spaces.x20} !important`,
        height: `${theme.spaces.x20} !important`,
      },
    },
    paper: {
      minHeight: theme.spaces.x3 * 10,
      boxShadow: theme.shadows.medium,
      margin: '0!important',
      borderRadius: theme.spaces.x4,
    },
    secondaryLabel: {
      color: theme.colors.grey[80],
    },
  }),
  'table-cell-inner-autocomplete'
)
