import {Button, Chip} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import {useState} from 'react'
import {createEmptyListElement} from '../utils/formUtils'
import FormInputTable from './FormInputTable'

const getSelectedTableFromList = (listRegister, listElements, selectedElementNumber) => {
  const selectedTableIndex = listElements.findIndex(
    (el) => Number(el.number) === Number(selectedElementNumber)
  )
  const selectedTableName = `${listRegister}.${selectedTableIndex}.values.table.0.rows`
  const selectedTableRows = listElements[selectedTableIndex].values.table?.[0].rows || []
  return {selectedTableName, selectedTableRows}
}

const MultipleTablesContent = ({fieldId, listRegister, form, listMeta, readonly}) => {
  // form.watch();

  // FIXME: костыльный способ вызвать ререндер.
  // Сеттер вызывается при изменении формы (добавление/удаление строки).
  // Альтернативный (и правильный) способ -- использовать form.watch,
  // но в этом случае происходит ререндер родительских компонент, что ломает интерфейес.
  const [key, setKey] = useState(Math.random())

  const [selectedTableNumber, setSelectedTableNumber] = useState(
    form.getValues(listRegister)[0].number
  )
  const {selectedTableName, selectedTableRows} = getSelectedTableFromList(
    listRegister,
    form.getValues(listRegister),
    selectedTableNumber
  )

  const handleDeleteTable = (elementNumber) => {
    const newList = form
      .getValues(listRegister)
      .filter((element) => element.number !== elementNumber)
    form.setValue(listRegister, newList)
    setKey(Math.random())
  }
  const handleAddTable = () => {
    const listElements = form.getValues(listRegister)
    const newNumber = Math.max(...listElements.map((el) => el.number)) + 1
    const element = createEmptyListElement(newNumber, listMeta)
    form.setValue(listRegister, [...listElements, element])
    setKey(Math.random())
  }
  const handleSelectTable = (elementNumber) => {
    setSelectedTableNumber(elementNumber)
  }

  return (
    <FlexboxColumn key={key} sx={{gap: '24px'}}>
      <FlexboxRow sx={{gap: '32px'}}>
        <FlexboxRow sx={{gap: '16px'}}>
          {form.getValues(listRegister).map((elementValue) => (
            <Chip
              key={elementValue.number}
              checked={elementValue.number === selectedTableNumber}
              label={`${listMeta.elementLabel}${elementValue.number}`}
              variant="outlined"
              onChange={() => handleSelectTable(elementValue.number)}
              onDelete={
                elementValue.number === selectedTableNumber || readonly
                  ? undefined
                  : () => handleDeleteTable(elementValue.number)
              }
            />
          ))}
        </FlexboxRow>
        {!readonly && (
          <Button variant="text" onClick={handleAddTable}>
            {listMeta.addButtonLabel || 'Добавить'}
          </Button>
        )}
      </FlexboxRow>
      <FormInputTable
        key={selectedTableName}
        fieldId={fieldId}
        form={form}
        readonly={readonly}
        tableMeta={listMeta.elements[0]}
        tableName={selectedTableName}
        tableRows={selectedTableRows}
      />
    </FlexboxColumn>
  )
}

export default MultipleTablesContent
