import React, {FC, MouseEventHandler} from 'react'
import {Close as CloseIcon} from '@x5-react-uikit/icons'
import {LoaderIcon} from '../Loader'
import {BaseAdornmentProps} from './types'
import {inputClasses} from './styles'

interface ClearOrSpinAdornmentProps extends BaseAdornmentProps {
  clear: boolean
  loading?: boolean
  onClearClick: MouseEventHandler
}

export const ClearOrSpinAdornment: FC<ClearOrSpinAdornmentProps> = ({
  size,
  color,
  clear,
  disabled,
  loading,
  onClearClick,
}) => (
  <>
    {clear && !loading && (
      <CloseIcon
        aria-label="close"
        className={inputClasses.clear}
        color={color}
        size={size}
        onClick={!disabled ? onClearClick : null}
      />
    )}
    {loading && <LoaderIcon className={inputClasses.loader} size={size} />}
  </>
)

export default ClearOrSpinAdornment
