import {ReactNode, ComponentType, HTMLAttributes, ReactElement} from 'react'
import {TableBodyCellInnerProps} from '../../types'

export const AutocompleteDefaultOption: BodyCellInnerAutocompleteOption = {key: null, value: ''}

export interface BodyCellInnerAutocompleteOption {
  key: string
  value: string
}

export interface BodyCellInnerAutocompleteOnChange {
  (evt: MouseEvent, newValue: BodyCellInnerAutocompleteOption): void
}

export interface GetOptionsHandleRequest {
  (str: string, newValue: string): Promise<BodyCellInnerAutocompleteOption[]>
}

export interface BodyCellInnerAutocompleteProps
  extends Omit<TableBodyCellInnerProps, 'onChange'>,
    BodyCellInnerAutocompleteViewProps,
    BodyCellInnerAutocompleteEditProps {}

interface BodyCellInnerAutocompleteBaseProps
  extends Pick<TableBodyCellInnerProps, 'density' | 'noWrap'> {
  children: BodyCellInnerAutocompleteOption
}

export interface BodyCellInnerAutocompleteViewProps
  extends BodyCellInnerAutocompleteBaseProps,
    Pick<TableBodyCellInnerProps, 'editable' | 'onStartEdit'> {
  renderValue?: (
    selected: BodyCellInnerAutocompleteOption,
    classes: Record<string, string>
  ) => ReactNode
}

export interface BodyCellInnerAutocompleteEditProps
  extends BodyCellInnerAutocompleteBaseProps,
    Pick<TableBodyCellInnerProps, 'rootRef' | 'onCancel' | 'rowId'> {
  onChange: BodyCellInnerAutocompleteOnChange
  getOptionsHandleRequest?: GetOptionsHandleRequest
  ListboxComponent?: ComponentType<HTMLAttributes<HTMLElement>>
  inputPlaceholder?: string
  filterOptions?: (option, state) => any
  renderInputSelectedValue?: (selected: BodyCellInnerAutocompleteOption) => string
  customRenderSelected?: (option: BodyCellInnerAutocompleteOption, value: any) => boolean
  customOptionLabel?: (option: BodyCellInnerAutocompleteOption) => string
  renderOption?: (option: BodyCellInnerAutocompleteOption) => ReactElement
}

export const EMPTY_VALUE = '‎'
