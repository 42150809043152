import {ReactNode, ReactElement, MouseEvent, CSSProperties} from 'react'
import {sizeTokenValues} from '@x5-react-uikit/tokens'
import {QA} from '../types'

export enum SegmentedControlSizes {
  small = sizeTokenValues.small,
  large = sizeTokenValues.large,
}

export interface SegmentedControlProps extends QA {
  value?: SegmentedControlsProps['value']
  label?: ReactNode
  disabled?: boolean
  selected?: boolean
  icon?: ReactElement
  size?: SegmentedControlSizes
  width?: CSSProperties['width']
}

export interface SegmentedControlsProps extends QA {
  value?: number
  onChange?: (event: MouseEvent<HTMLButtonElement>, value: SegmentedControlsProps['value']) => void
}
