import React, {forwardRef, useRef, useState, useCallback} from 'react'
import clsx from 'clsx'
import {sizeTokenValues, placements} from '@x5-react-uikit/tokens'
import {
  Dropdown,
  SelectList,
  DropdownInnerRefMethods,
  Checkbox,
  SelectOptionType,
} from '@x5-react-uikit/core'
import {
  ArrowDown as ArrowDownIcon,
  ArrowUp as ArrowUpIcon,
  SortAZ as SortAZIcon,
  SortZA as SortZAIcon,
  Filter as FilterIcon,
  EditOn as EditOnIcon,
  Add as AddIcon,
  Close as CloseIcon,
} from '@x5-react-uikit/icons'
import {getTableQA} from '../helpers'
import {
  DataGridHeadCellProps,
  headCellComponentValues,
  HeadCellControlComponent,
  HeadCellCheckboxComponent,
} from './types'
import {StyledHeadCell, StyledHeadCellWrap, StyledHeadCellAction, classes} from './styles'

export const sortDirections = {
  asc: 'asc',
  desc: 'desc',
  unset: 'unset',
} as const

export const columnfilterModes = {
  existed: 'existed',
  added: 'added',
  cleared: 'cleared',
  unset: 'unset',
} as const

export const DataGridHeadCell = forwardRef<HTMLTableCellElement, DataGridHeadCellProps>(
  (
    {
      name,
      qa = 'head-cell',
      children,
      icon,
      component = {sort: null, filter: null, checked: null},
      onChange,
      ...props
    },
    ref
  ) => {
    const dropdownRef = useRef<DropdownInnerRefMethods>()
    const [selected, setSelected] = useState<HeadCellControlComponent['sort']>(sortDirections.unset)
    const [opened, toggleOpened] = useState(false)
    const controlComponent = component as HeadCellControlComponent
    const hasFilter = controlComponent?.filter

    const handleSelectChange = (
      event: MouseEvent,
      value: HeadCellControlComponent['filter'] | HeadCellControlComponent['sort']
    ) => {
      const filterValue = value as HeadCellControlComponent['filter']
      const sortValue = value as HeadCellControlComponent['sort']
      if (sortValue === sortDirections.asc || sortValue === sortDirections.desc) {
        onChange?.(name, {type: 'sort', dir: sortValue})
        setSelected(sortValue)
      }

      if (Object.values(columnfilterModes).includes(filterValue)) {
        onChange?.(name, {type: 'filter', filter: filterValue})
      }
      dropdownRef.current.close()
    }

    const getAdornment = (
      opened: boolean,
      dir?: HeadCellControlComponent['sort'],
      filter?: string
    ) => {
      if (
        (!dir || dir === sortDirections.unset) &&
        (!filter || filter !== columnfilterModes.existed)
      ) {
        return opened ? (
          <ArrowUpIcon className={classes.end} size={sizeTokenValues.small} />
        ) : (
          <ArrowDownIcon className={classes.end} size={sizeTokenValues.small} />
        )
      }

      if (filter === columnfilterModes.existed) {
        return (
          <FilterIcon
            className={clsx(classes.end, classes.selected)}
            size={sizeTokenValues.small}
          />
        )
      }

      return dir === sortDirections.asc ? (
        <SortAZIcon className={clsx(classes.end, classes.selected)} size={sizeTokenValues.small} />
      ) : (
        <SortZAIcon className={clsx(classes.end, classes.selected)} size={sizeTokenValues.small} />
      )
    }

    const renderIcon = () => {
      if (icon) {
        const Icon = icon
        return <Icon className={classes.start} size={sizeTokenValues.small} />
      }
    }

    const renderCheckbox = useCallback(() => {
      const checkboxComponent = component as HeadCellCheckboxComponent
      const handleCheckboxChange = ({target}) =>
        onChange?.(name, {type: 'checkbox', checked: target.checked})

      if (checkboxComponent?.type === headCellComponentValues.checkbox) {
        return <Checkbox checked={checkboxComponent?.checked} onChange={handleCheckboxChange} />
      }
    }, [component, name, onChange])

    const sortItems = controlComponent?.sort
      ? [
          {
            value: sortDirections.asc,
            name: 'По возрастанию',
            icon: <SortAZIcon size={sizeTokenValues.small} />,
            label: 'Сортировка',
          },
          {
            value: sortDirections.desc,
            name: 'По убыванию',
            icon: <SortZAIcon size={sizeTokenValues.small} />,
          },
        ]
      : []

    const filterItems: SelectOptionType[] = hasFilter
      ? [
          {
            value: columnfilterModes.added,
            name:
              controlComponent.filter === columnfilterModes.existed
                ? 'Изменить фильтр'
                : 'Добавить фильтр',
            icon:
              controlComponent.filter === columnfilterModes.existed ? (
                <EditOnIcon size={sizeTokenValues.small} />
              ) : (
                <AddIcon size={sizeTokenValues.small} />
              ),
            label: 'Фильтр',
          },
        ]
      : []

    if (hasFilter && controlComponent.filter === columnfilterModes.existed) {
      filterItems.push({
        value: columnfilterModes.cleared,
        name: 'Убрать фильтр',
        icon: <CloseIcon size={sizeTokenValues.small} />,
      })
    }

    const selectItems = [...sortItems, ...filterItems]

    return (
      <StyledHeadCell ref={ref} data-name={name} {...props}>
        <StyledHeadCellWrap>
          {renderIcon()}
          {renderCheckbox()}
          {hasFilter || controlComponent?.sort ? (
            <Dropdown
              stretch
              action={
                <StyledHeadCellAction data-qa={getTableQA(`${qa}-action`, controlComponent?.sort)}>
                  <span className={classes.text}>{children}</span>
                  {getAdornment(opened, controlComponent?.sort, controlComponent?.filter)}
                </StyledHeadCellAction>
              }
              innerRef={dropdownRef}
              placement={placements.bottomEnd}
              onChange={toggleOpened}
            >
              <SelectList items={selectItems} selected={selected} onChange={handleSelectChange} />
            </Dropdown>
          ) : (
            children
          )}
        </StyledHeadCellWrap>
      </StyledHeadCell>
    )
  }
)

export default DataGridHeadCell
