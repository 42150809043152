import React, {FC, ReactElement} from 'react'
import {InputCell} from '.'
import {isNestedCell} from '../helpers'
import {DataGridControlCellProps} from './types'

export const ControlCell: FC<DataGridControlCellProps> = ({mode, children, value, ...props}) => {
  if (isNestedCell(mode)) {
    const inputValue = React.Children.map(children, (child: ReactElement) => {
      if (typeof child !== 'string') return child.props.children
      return child
    }).join('')

    return <InputCell {...props} type={mode} value={inputValue} />
  }

  return children as JSX.Element
}

export default ControlCell
