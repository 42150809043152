import {restyled} from '../styles'
import {baseTheme} from '../theme/themes'
import {FloatingArrow, FloatingArrowProps} from '@floating-ui/react'
import {forwardRef} from 'react'

export const arrowSize = 4

const defaultColor = baseTheme.colors.grey[90]
const minHeightContent = 36

export const StyledContent = restyled.div({
  zIndex: baseTheme.zIndex.tooltip,
  boxSizing: 'border-box',
  display: 'inline-flex',
  position: 'relative',
  justifyContent: 'center',
  margin: 0,
  padding: baseTheme.spaces.join('x5', 'x6'),
  maxWidth: 360,
  minHeight: minHeightContent,
  backgroundColor: defaultColor,
  color: baseTheme.colors.white,
  borderRadius: baseTheme.spaces.x2,
  ...baseTheme.typography.p2,
})

export const StyledFloatingArrow = forwardRef<SVGSVGElement, FloatingArrowProps>((props, ref) => (
  <FloatingArrow ref={ref} {...props} fill={defaultColor} height={4} width={8} />
))
