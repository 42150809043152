import {makeUiStyles} from '../../../theme'
import {TableStyleHelpers} from '../../Cell/styles'

export const useInnerTextStyles = makeUiStyles(
  (theme) => ({
    innerRootEdit: {
      ...TableStyleHelpers.getInnerInputWrap(theme),
      '& > div': {
        padding: 0,
        width: '100%',
        '&:before, &:after': {
          display: 'none',
        },
      },
      '& textarea': {
        ...TableStyleHelpers.getCellFont(theme),
        '&:focus': {
          outline: 'none',
        },
        '&:before, &:after': {
          display: 'none',
        },
      },
    },
  }),
  'table-cell-inner-text'
)
