import {buttonModes} from '../Button'
import {makeUiStyles} from '../theme'

export const useStyles = makeUiStyles(({spaces, colors}) => {
  const xsmalSize = spaces.x12
  const xxsmalSize = spaces.x8

  return {
    root: {
      color: ({mode}) => (mode !== buttonModes.dark ? colors.grey[60] : colors.grey[40]),
      '&[disabled]': {
        color: ({mode}) => (mode !== buttonModes.dark ? colors.grey[40] : colors.grey[60]),
      },
      '&:hover': {
        backgroundColor: ({mode}) =>
          mode !== buttonModes.dark ? colors.grey[30] : colors.grey[80],
      },
      '&:active': {
        backgroundColor: ({mode}) =>
          mode !== buttonModes.dark ? colors.grey[40] : colors.grey[60],
      },
    },
    xsmall: {
      padding: spaces.x2,
      height: xsmalSize,
      minWidth: xsmalSize,
    },
    xxsmall: {
      padding: 0,
      height: xxsmalSize,
      minWidth: xxsmalSize,
    },
  }
}, 'inner-button')

export default useStyles
