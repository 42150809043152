import React from 'react'

type Ref<T> = React.RefCallback<T> | React.MutableRefObject<T>

/**
 * Функция позволяет присвоить ref нескольким объектам
 * @param rest[] - рефы
 **/

const setRefs =
  <R extends Ref<Element>[]>(...refs: R) =>
  (element: Element) => {
    refs.forEach((ref) => {
      ref instanceof Function ? ref(element) : ref && (ref.current = element)
    })
  }

export {setRefs}
export default setRefs
