import React, {forwardRef, useState, useRef, useMemo} from 'react'
import {getComponentMode} from './helpers'
import {PasswordAdornment} from './PasswordAdornment'
import {ClearOrSpinAdornment} from './ClearOrSpinAdornment'
import {SearchAdornment} from './SearchAdornment'
import {DateAdornment} from './DateAdornment'
import {FieldCaption} from '../FieldCaption'
import {Asterisk} from './Asterisk'
import {
  StyledContainer,
  StyledInput,
  StyledLabel,
  StyledStartAdornment,
  StyledEndAdornment,
  inputClasses,
} from './styles'

import {InputProps, inputSizes} from './types'
import {getQaAttribute, setRefs} from '../utils'

export const Input = forwardRef<HTMLDivElement, InputProps>(
  (
    {
      qa,
      className,
      id,
      label,
      value: inputValue,
      inputProps = {},
      caption,
      error,
      textError,
      disabled,
      size = inputSizes.large,
      endAdornment,
      startAdornment,
      autoComplete = 'off',
      clear,
      loading,
      width,
      unborder,
      required,
      forbidTyping,
      isAbsoluteCaption,
      autoFocus = false,
      inputRef,
      onClick,
      onFocus,
      onBlur,
      onChange,
      onKeyDown,
      ...props
    },
    ref
  ) => {
    const baseRef = useRef<HTMLInputElement>(null)
    const [focused, setFocused] = useState(false)

    const {
      password,
      search,
      passwordVisible = false,
      datePicker = false,
      datePickerRanged = false,
      onPasswordVisibilityChange,
      onClearClick,
      onDateClick,
    } = props

    const getQA = getQaAttribute(
      qa !== null ? getComponentMode({password, search, datePicker}) : null
    )
    const filled = props.filled || Boolean(inputValue?.length)

    const handleFocus: InputProps['onFocus'] = (event) => {
      setFocused(true)
      onFocus?.(event)
    }

    const handleBlur: InputProps['onBlur'] = (event) => {
      setFocused(false)
      onBlur?.(event)
    }

    const adornments = useMemo(() => {
      let startAdornmentContent = search ? <SearchAdornment size={size} /> : startAdornment
      let endAdornmentContent = endAdornment

      if (password) {
        const handlePasswordVisibilityChange = (visible: boolean) => {
          if (onPasswordVisibilityChange) onPasswordVisibilityChange(visible)
        }

        endAdornmentContent = (
          <PasswordAdornment
            disabled={disabled}
            qa={getQA(passwordVisible ? 'visible' : 'invisible')}
            size={size}
            visible={passwordVisible}
            onClick={() => handlePasswordVisibilityChange(!passwordVisible)}
          />
        )
      } else if (search) {
        endAdornmentContent = (
          <ClearOrSpinAdornment
            clear={clear}
            disabled={disabled}
            loading={loading}
            size={size}
            onClearClick={(event) => {
              onClearClick?.(event)
              baseRef.current.focus()
            }}
          />
        )
      } else if (datePicker) {
        endAdornmentContent = (
          <DateAdornment
            size={size}
            onClick={(event) => {
              if (!disabled) onDateClick?.(event)
              baseRef.current.focus()
            }}
          />
        )
      }

      if (endAdornmentContent) {
        endAdornmentContent = (
          <StyledEndAdornment
            interactive={password || clear || datePicker}
            preMatching={loading}
            type="button"
            {...{disabled, size}}
          >
            {endAdornmentContent}
          </StyledEndAdornment>
        )
      }

      if (startAdornmentContent) {
        startAdornmentContent = (
          <StyledStartAdornment disabled={disabled}>{startAdornmentContent}</StyledStartAdornment>
        )
      }

      return {start: startAdornmentContent, end: endAdornmentContent}
    }, [
      clear,
      datePicker,
      disabled,
      startAdornment,
      endAdornment,
      baseRef,
      password,
      passwordVisible,
      search,
      onClearClick,
      onDateClick,
      onPasswordVisibilityChange,
    ])

    const type: InputProps['type'] = useMemo(() => {
      if (props.type) return props.type
      if (password && !passwordVisible) return 'password'
      return 'text'
    }, [props.type, password, passwordVisible])

    const captionValue = useMemo(
      () => (error && textError ? textError : caption),
      [caption, error, textError]
    )

    const fieldProps = {
      readOnly: forbidTyping,
      ...{step: type === 'number' ? 'any' : null, ...inputProps},
      id,
      name: props.name,
      value: inputValue,
      'data-qa': qa ?? getQA('field', [required && 'required', disabled && 'disabled']),
      ref: setRefs(baseRef, inputRef),
      filled,
      autoFocus,
      error,
      type,
      disabled,
      required,
      autoComplete,
      spellCheck: datePicker ? false : true,
      onFocus: handleFocus,
      onBlur: handleBlur,
      onChange,
      onKeyDown,
    }

    return (
      <StyledContainer
        ref={ref}
        className={className}
        cssWidth={width}
        data-qa={getQA()}
        disabled={disabled}
        error={error}
        focused={focused}
        isAbsoluteCaption={isAbsoluteCaption}
        unborder={unborder}
        onClick={onClick}
      >
        <div className={inputClasses.wrap}>
          {adornments.start}
          <div className={inputClasses.field}>
            <StyledLabel
              disabled={fieldProps.disabled}
              filled={filled}
              htmlFor={fieldProps.name}
              size={size}
            >
              {label}
              {required && <Asterisk />}
            </StyledLabel>
            <StyledInput fieldSize={size} {...fieldProps} />
          </div>
          {adornments.end}
        </div>
        {captionValue && (
          <FieldCaption
            disabled={disabled}
            error={error}
            isAbsolute={isAbsoluteCaption}
            qa={getQA('caption')}
            value={captionValue}
            width={width}
          />
        )}
      </StyledContainer>
    )
  }
)

export default Input
