import {Link, Loader, Modal, Typography, baseTheme} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import StepTitle from '@root/components/StepTitle'
import {Datepicker} from '@root/components/kit'
import useNotify from '@root/hooks/useNotify'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {
  useGetTaskByIdQuery,
  useUpdateTaskMutation,
  useUpdateTaskStatusMutation,
} from '@root/redux/api/tasksApi'
import SubmitChangeStatus from './SubmitChangeStatus'
import {isEmpty} from 'lodash'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import {DATE_FORMAT} from '@root/constants'
import {TaskStatuses} from '@root/constants'

dayjs.extend(customParseFormat)

const {typography} = baseTheme

const ChangeToSearchingPerformerModal = ({open, onClose, onOpen}) => {
  const {taskId} = useParams()
  const {notifySuccess, notifyError, notifyWarning} = useNotify()

  // const {form, formMeta, isFormDataLoading} = useTaskFormData();
  // const firstCompleted = useMemo(() => {
  //   if (!open || isFormDataLoading)
  //     return;

  //   const steps = Object.values(form.getValues());
  //   const stepsMeta = Object.values(formMeta).reduce((meta, field) => {
  //     const stepMeta = meta[field.step - 1];
  //     stepMeta[field.id] = field;
  //     return meta;
  //   }, [{}, {}, {}]);
  //   return (
  //     steps.length === 3 &&
  //     isStepCompleted(steps[0], stepsMeta[0]) &&
  //     isStepCompleted(steps[1], stepsMeta[1]) &&
  //     isStepCompleted(steps[2], stepsMeta[2])
  //   )
  // }, [open, isFormDataLoading, form, formMeta]);
  const firstCompleted = true

  const [ltPeriodDates, setLtPeriodDates] = useState(undefined)
  const {data: task} = useGetTaskByIdQuery(taskId)
  useEffect(() => {
    if (task) setLtPeriodDates([task.startAt, task.finishedAt])
  }, [task])
  const secondCompleted = ltPeriodDates && ltPeriodDates[0] && ltPeriodDates[1]

  const handleEditDate = (newValue) => {
    if (newValue[1]) {
      const startAt = dayjs(newValue[0], DATE_FORMAT)
      const finishAt = dayjs(newValue[1], DATE_FORMAT)
      if (!startAt.isBefore(finishAt, 'day')) {
        notifyWarning('Дата окончания работ не может быть раньше или равной дате начала работ')
        return
      }
    }
    setLtPeriodDates(newValue)
  }

  const [updateTask] = useUpdateTaskMutation()
  const [updateTaskStatus] = useUpdateTaskStatusMutation()
  const handleSearchingPerformer = () => {
    const updateTaskData = {}
    if (task.startAt !== ltPeriodDates[0]) updateTaskData.startAt = ltPeriodDates[0]
    if (task.finishedAt !== ltPeriodDates[1]) updateTaskData.finishedAt = ltPeriodDates[1]

    const onSuccess = () => {
      notifySuccess(
        'Начат поиск исполнителя',
        'Статус заявки изменен на “Поиск исполнителя”. Вы можете продолжить работу с заявкой.'
      )
      setApproveModalShown(false)
    }
    const onError = (error) => {
      if (error.status === 409) {
        notifyError('Текущая ИС уже имеет активную заявку.')
      } else {
        console.log(error)
        notifyError()
      }
    }
    const updateStatusData = {nextTaskStatus: TaskStatuses.SEARCHING_PERFORMER}
    if (!isEmpty(updateTaskData))
      updateTask({taskId, update: updateTaskData})
        .unwrap()
        .then(() => updateTaskStatus({taskId, updateData: updateStatusData}).unwrap())
        .then(onSuccess)
        .catch(onError)
    else
      updateTaskStatus({taskId, updateData: updateStatusData})
        .unwrap()
        .then(onSuccess)
        .catch(onError)
  }

  const [approveModalShown, setApproveModalShown] = useState(false)
  const showApproveModal = () => {
    setApproveModalShown(true)
    onClose()
  }
  const cancelApproveModal = () => {
    onOpen()
    setApproveModalShown(false)
  }

  if (ltPeriodDates === undefined) return <Loader />

  return (
    <>
      <Modal
        closeOnOverlay
        buttons={[
          {
            onClick: onClose,
            side: 'right',
            text: 'Отмена',
          },
          {
            disabled: !firstCompleted || !secondCompleted,
            onClick: showApproveModal,
            side: 'right',
            text: 'Начать поиск исполнителя',
          },
        ]}
        description="Убедитесь, что все пункты для изменения статуса выполнены"
        isOpen={open}
        size="medium"
        title="Изменение статуса заявки"
        onClose={onClose}
      >
        <FlexboxColumn sx={{gap: '24px'}}>
          <FlexboxColumn sx={{gap: '16px'}}>
            <StepTitle
              completed={firstCompleted}
              number={1}
              title="Проверить и подтвердить информацию по заявке"
            />
            <Typography variant="p1">
              <span>1.1. В информации о заявке менеджер&nbsp;</span>
              <Link component="span">может отредактировать ИС.&nbsp;</Link>
              <span>
                если ИС нет в списке и она введена вручную, менеджер может добавить ее в базу,
                указав название. После нажатия кнопки “Начать поиск исполнителя”&nbsp;
              </span>
              <span style={typography.h4}>изменить ИС будет невозможно.</span>
              <br />
              <br />
              <span>1.2. Менеджер может&nbsp;</span>
              <Link component="span">редактировать пункты в заявке&nbsp;</Link>
              <span>
                и дополнять их, запрашивая дополнительную информацию у инициатора посредством чата.
              </span>
            </Typography>
          </FlexboxColumn>

          <FlexboxColumn sx={{gap: '16px'}}>
            <StepTitle
              completed={secondCompleted}
              number={2}
              title="Указать дату начала и окончания НТ"
            />
            <Typography variant="p1">
              3.1. Данный шаг предполагает указание периода календарного времени, когда нагрузочное
              тестирование будет проведено.
            </Typography>
            <Datepicker
              required
              date={ltPeriodDates}
              delimiter=" — "
              DropdownProps={{updateOnOpen: true, placement: 'top-start'}}
              InputProps={{label: 'Период проведения НТ'}}
              width="100%"
              onChange={handleEditDate}
            />
          </FlexboxColumn>
        </FlexboxColumn>
      </Modal>
      <SubmitChangeStatus
        handleChangeStatus={handleSearchingPerformer}
        message="
          Статус заявки перейдет с “На рассмотрении” в “Поиск исполнителя”. 
          Вернуться к предыдущему статусу невозможно. 
          Участники заявки получат уведомление о смене статуса по почте
        "
        open={!open && approveModalShown}
        onCancel={cancelApproveModal}
        onClose={() => setApproveModalShown(false)}
      />
    </>
  )
}

export default ChangeToSearchingPerformerModal
