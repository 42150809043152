import styled from '@emotion/styled'
import {baseTheme} from '@root/components/kit'

export const ActionButton = styled.button`
  height: 24px;
  width: 24px;
  box-shadow: none;
  border: none;
  outline: none;
  background: transparent;
  display: inline-block;
  cursor: pointer;
  transition:
    color 100ms ease,
    background-color 100ms ease;
  position: relative;

  svg {
    color: ${baseTheme.colors.grey[60]};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    background: ${baseTheme.colors.grey[40]};
    svg {
      color: ${baseTheme.colors.grey[60]};
    }
  }
`
