import {FC, ReactNode} from 'react'
import {SpringValue, useTransition} from '@react-spring/web'

type Props = {
  show: boolean
  render: (styles: {opacity: SpringValue<number>}) => ReactNode
}

export const FadeInOut: FC<Props> = ({show, render}) => {
  const transition = useTransition(show, {
    from: {opacity: 0},
    enter: {opacity: 1},
    leave: {opacity: 0},
    config: {duration: 150},
  })

  return transition((style, x) => {
    if (!x) {
      return null
    }

    return render(style)
  })
}
