import React from 'react'
import createLogo from '../utils/createSvgLogo'
import {logoVariants, logoColors, logoBrands} from '../types'
import PaketFull from './Full'
import PaketSmall from './Small'

export default createLogo((variant = logoVariants.full, color = logoColors.multi, partialProps) => {
  const props = {
    color,
    width: '100%',
    height: '100%',
    ...partialProps,
  }

  if (variant === logoVariants.icon) return <PaketSmall {...props} />

  return <PaketFull {...props} />
}, `${logoBrands.paket}Logo`)
