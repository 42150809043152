import {Link, Typography} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import {GRAPHS_REGISTER} from '../StatisticsStep/EditGraphDataField'
import TypographyGrey from '@root/components/typography/TypographyGrey'
import Divider from '@root/components/Divider'
import TypographyError from '@root/components/typography/TypographyError'

const ReviewReportStatisticsTab = ({form}) => {
  return (
    <FlexboxColumn sx={{gap: '16px', mt: 'x8'}}>
      {form.getValues(GRAPHS_REGISTER)?.map((graph, graphIndex) => {
        const last = graphIndex === form.getValues(GRAPHS_REGISTER).length - 1
        let label = graph.graphName || 'Безымянный график'
        const error = !graph.graphName || !graph.graphUrl
        return (
          <>
            <FlexboxColumn sx={{gap: '16px'}}>
              <FlexboxColumn>
                <Typography variant="h3">{label}</Typography>
                {error && (
                  <TypographyError variant="p2">
                    Есть незаполненные обязательные пункты. Без их заполнения отправка невозможна.
                  </TypographyError>
                )}
              </FlexboxColumn>
              {graph.graphUrl ? (
                <Link
                  style={{width: 'fit-content'}}
                  onClick={() => window.open(graph.graphUrl, '_blank')}
                >
                  {graph.graphUrl}
                </Link>
              ) : (
                <TypographyGrey>Не указаны</TypographyGrey>
              )}
            </FlexboxColumn>
            {!last && <Divider />}
          </>
        )
      })}
    </FlexboxColumn>
  )
}

export default ReviewReportStatisticsTab
