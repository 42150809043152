import {SortText, SortTextKind} from './internalTypes'

const defaultSortText: SortText = {
  ascending: 'По возрастанию',
  descending: 'По убыванию',
}

const alphabeticalSortText: SortText = {
  ascending: 'От A до Z',
  descending: 'От Z до A',
}

const alphabeticalCyrillicSortText: SortText = {
  ascending: 'От А до Я',
  descending: 'От Я до А',
}

const newestFirstSortText: SortText = {
  descending: 'Сначала новые',
  ascending: 'Сначала старые',
}

const lifeCycleSortText: SortText = {
  ascending: 'Начало жизненного цикла',
  descending: 'Конец жизненного цикла',
}

const closestIndefinite: SortText = {
  ascending: 'Сначала ближайшие',
  descending: 'Сначала бессрочные',
}

export const sortTextKindMap = {
  [SortTextKind.default]: defaultSortText,
  [SortTextKind.alphabetical]: alphabeticalSortText,
  [SortTextKind.alphabeticalCyrillic]: alphabeticalCyrillicSortText,
  [SortTextKind.newestFirst]: newestFirstSortText,
  [SortTextKind.lifeCycle]: lifeCycleSortText,
  [SortTextKind.closestIndefinite]: closestIndefinite,
}
