import {Box} from '@x5-react-uikit/core'
import Section from '@root/components/Section'
import SystemNotSelectedMessage from '../stubs/SystemNotSelectedMessage'
import LaunchHistoryTable from './LaunchHistoryTable'

const LaunchHistoryTab = ({systemId}) => {
  let content
  if (!systemId)
    content = (
      <Box
        sx={{
          display: 'flex',
          minHeight: '424px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SystemNotSelectedMessage />
      </Box>
    )
  else content = <LaunchHistoryTable systemId={systemId} />

  return (
    <Section
      sx={{
        py: undefined,
        px: undefined,
        p: undefined,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        width: '1200px',
      }}
    >
      {content}
    </Section>
  )
}
export default LaunchHistoryTab
