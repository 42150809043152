import {Typography, baseTheme} from '@x5-react-uikit/core'

const {colors} = baseTheme

const TypographyGrey = ({children, ...props}) => (
  <Typography style={{color: colors.grey[60]}} {...props}>
    {children}
  </Typography>
)

export default TypographyGrey
