import React from 'react'
import createLogo from '../utils/createSvgLogo'
import {logoVariants, logoColors, logoBrands} from '../types'
import X5SolutionsFull from './Full'
import Blank from '../Blank'

export default createLogo((variant = logoVariants.full, color = logoColors.multi, partialProps) => {
  const props = {
    color,
    width: '100%',
    height: '100%',
    ...partialProps,
  }

  if (variant === logoVariants.icon) return <Blank {...props} />

  return <X5SolutionsFull {...props} />
}, `${logoBrands.x5Solutions}Logo`)
