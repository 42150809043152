import {MutableRefObject} from 'react'
import dayjs, {Dayjs} from 'dayjs'
import {freezeRangeValues} from '@x5-react-uikit/core'

import {DatepickerProps, DatepickerStepsStore, DatepickerCheckDate} from './types'
import {findDisabledDate} from '../Calendar/Block/helpers'

const yearsCoefficient = 4
const notYearsCoefficient = 2
const dateSteps = 3
const datesSteps = 6
const placeholders = {year: 'гггг', month: 'мм', day: 'дд'}

export function createStepsStore(
  date: DatepickerProps['date'],
  dateFormat: DatepickerProps['dateFormat'],
  delimiter: DatepickerProps['delimiter']
): DatepickerStepsStore {
  const {year, month, day} = placeholders
  const isYearFirst = dateFormat[0] === dateFormat[3]
  const stepsCount = Array.isArray(date) ? datesSteps : dateSteps
  const steps = []

  for (let i = 0; i < stepsCount; i++) {
    const isFirst = i === 0 || i === dateSteps
    const isLast = i === dateSteps - 1 || i === datesSteps - 1
    const isYear = isYearFirst ? isFirst : isLast

    const from =
      i === 0 ? 0 : i === dateSteps ? steps[i - 1].to + delimiter.length : steps[i - 1].to + 1
    const to = isYear ? from + yearsCoefficient : from + notYearsCoefficient
    const placeholder = isYear ? year : isFirst || isLast ? day : month

    steps.push({
      from,
      to,
      placeholder,
      value: placeholder,
    })
  }

  const {from, to} = steps[0]
  const symbol = dateFormat[isYearFirst ? yearsCoefficient : notYearsCoefficient]

  return {
    steps,
    delimiter,
    symbol,
    step: 0,
    range: [from, to],
  }
}

export function updateStepValue(
  symbol: string,
  store: DatepickerStepsStore,
  freezeRange?: DatepickerProps['freezeRange']
): string {
  const step = store.steps[store.step]
  const {length: valueSize} = step.value.split('').filter(isNumeric)

  if (symbol === '') {
    step.value = step.placeholder
    return getStepsValue(store)
  }

  if (valueSize === step.placeholder.length - 1) {
    const maxStep =
      store.steps.length === datesSteps && freezeRange !== freezeRangeValues.end
        ? datesSteps - 1
        : dateSteps - 1

    if (store.step < maxStep) {
      store.step += 1
      const {from, to} = store.steps[store.step]
      store.range = [from, to]
    }
  }

  if (valueSize === step.placeholder.length) {
    step.value = symbol + step.placeholder.slice(1)
  } else {
    const valueAsArray = step.value.split('')
    valueAsArray[valueSize] = symbol
    step.value = valueAsArray.join('')
  }

  return getStepsValue(store)
}

export function getStepsValue(store: DatepickerStepsStore): string {
  return store.steps
    .map((step, i) => {
      const isLast = store.steps.length - 1
      const prefix = i === dateSteps ? store.delimiter : ''
      const postfix = i === isLast || i === dateSteps - 1 ? '' : store.symbol
      return prefix + step.value + postfix
    })
    .join('')
}

export function setStep({
  step,
  store,
  element,
  freezeRange,
}: {
  step: number
  store: DatepickerStepsStore
  element: HTMLInputElement
  freezeRange?: DatepickerProps['freezeRange']
}): void {
  const maxStep = freezeRange === freezeRangeValues.end ? dateSteps - 1 : store.steps.length - 1
  const minStep = freezeRange === freezeRangeValues.start ? dateSteps : 0

  store.step = step > maxStep ? maxStep : step < minStep ? minStep : step
  const {from, to} = store.steps[store.step]
  store.range = [from, to]

  if (element) {
    setRange(store.range, element)
  }
}

export function setRange(range: DatepickerStepsStore['range'], element: HTMLInputElement): void {
  element.setSelectionRange(range[0], range[1])
}

export function isNumeric(value: string | number): boolean {
  return !isNaN(+value)
}

export function setStepBySlide(
  key: string,
  store: DatepickerStepsStore,
  element: HTMLInputElement,
  freezeRange?: DatepickerProps['freezeRange']
): void {
  if (['ArrowLeft', 'ArrowRight'].includes(key)) {
    const newStep = store.step + (key === 'ArrowLeft' ? -1 : 1)
    setStep({
      step: newStep,
      store,
      element,
      freezeRange,
    })
  }
}

export function setValueBySymbol(
  symbol: string,
  store: DatepickerStepsStore,
  element: HTMLInputElement,
  freezeRange?: DatepickerProps['freezeRange']
): void {
  if (isNumeric(symbol) || symbol === 'Backspace') {
    element.value = updateStepValue(symbol === 'Backspace' ? '' : symbol, store, freezeRange)
    setRange(store.range, element)
  }
}

export function setValueBySymbols(
  symbols: string[],
  store: DatepickerStepsStore,
  element: HTMLInputElement,
  freezeRange?: DatepickerProps['freezeRange']
): void {
  symbols.forEach((symbol) => setValueBySymbol(symbol, store, element, freezeRange))
}

export function syncDateWithStepsStore({
  date,
  dateFormat,
  element,
  stepsStoreRef,
  newStepsStore,
  freezeRange,
}: Pick<DatepickerProps, 'date' | 'dateFormat' | 'freezeRange'> & {
  element: HTMLInputElement
  stepsStoreRef: MutableRefObject<DatepickerStepsStore>
  newStepsStore: DatepickerStepsStore
  normalizeDate?: boolean
}): void {
  const normalizedDate = [].concat(formatDate(date, dateFormat))
  stepsStoreRef.current = newStepsStore

  if (freezeRange === freezeRangeValues.end && normalizedDate.length === 2 && !normalizedDate[0]) {
    stepsStoreRef.current.step = dateSteps
  }

  normalizedDate.map((item) => {
    const symbols = item.split('')
    setValueBySymbols(symbols, stepsStoreRef.current, element)
  })
}

export function formatDate(
  date: DatepickerProps['date'],
  dateFormat: DatepickerProps['dateFormat']
): string | string[] {
  return Array.isArray(date)
    ? date.map((item) =>
        item ? dayjs(item, typeof item === 'string' && dateFormat).format(dateFormat) : ''
      )
    : dayjs(date, typeof date === 'string' && dateFormat).format(dateFormat)
}

export function isFocused(element: HTMLElement): boolean {
  return element === document.activeElement
}

export function getDateString(
  date: DatepickerProps['date'],
  dateFormat: DatepickerProps['dateFormat']
): string {
  return [].concat(formatDate(date, dateFormat)).join('')
}

export function checkDate({
  date,
  stepsStore,
  minDate,
  maxDate,
  dateFormat,
  delimiter,
  disabledDates,
  onChange,
  onError,
  isRange,
  freezeRange,
  skipDateValidation = false,
}: DatepickerCheckDate): void {
  let hasError = false
  const normalizedDate = isRange ? date.split(delimiter) : [date]

  const isYearFirst = stepsStore.steps[0].placeholder.length === yearsCoefficient
  const {from: monthFrom, to: monthTo} = stepsStore.steps[1]
  const {from: dayFrom, to: dayTo} = stepsStore.steps[isYearFirst ? 2 : 0]

  const result = normalizedDate.map((item, i) => {
    const currentDate = dayjs(item, dateFormat)
    const info = {
      value: currentDate,
      formatted: currentDate.format(dateFormat),
      position: i,
      status: null,
    }

    const month = parseInt(item.slice(monthFrom, monthTo))
    const day = parseInt(item.slice(dayFrom, dayTo))

    const isMonthValid = month <= 12
    const isDayValid =
      isMonthValid &&
      day <=
        dayjs()
          .month(month - 1)
          .daysInMonth()

    if (currentDate.isValid() && isMonthValid && isDayValid) {
      const {isDisabled} = findDisabledDate(disabledDates, dateFormat, currentDate)

      info.status =
        +currentDate < +minDate
          ? 'min'
          : +currentDate > +maxDate
            ? 'max'
            : isDisabled
              ? 'disabled'
              : 'success'

      if (info.status !== 'success' && !hasError) {
        hasError = true
        return onError?.(info)
      }

      return currentDate
    } else if (!skipDateValidation) {
      info.status = 'invalid'
      hasError = true
      onError?.(info)
    }
  })

  if (!hasError && onChange) {
    const normalizedResult = freezeRange
      ? (result.filter(Boolean) as Dayjs[])
      : (result.filter(Boolean).sort((a, b) => +a - +b) as Dayjs[])

    if (normalizedResult.length) {
      if (freezeRange && isRange) {
        const isTooLittle =
          freezeRange === freezeRangeValues.start && normalizedResult[1] < normalizedResult[0]
        const isTooMuch =
          freezeRange === freezeRangeValues.end && normalizedResult[0] > normalizedResult[1]

        if (isTooLittle || isTooMuch) {
          return onError?.({
            value: isTooLittle ? normalizedResult[1] : normalizedResult[0],
            formatted: (isTooLittle ? normalizedResult[1] : normalizedResult[0]).format(dateFormat),
            status: isTooLittle ? 'min' : 'max',
          })
        }
      }

      isRange
        ? onChange(
            normalizedResult.map((item) => item.format(dateFormat)),
            normalizedResult
          )
        : onChange(normalizedResult[0].format(dateFormat), normalizedResult[0])
    }
  }
}
