import {PREFIX, restyled, doSelectors} from '../styles'
import {StyledAdornmentProps, InputProps, inputSizes} from './types'

export const inputClasses = {
  wrap: `${PREFIX}-input-wrap`,
  field: `${PREFIX}-input-field`,
  asterisk: `${PREFIX}-input-asterisk`,
  clear: `${PREFIX}-input-clear`,
  loader: `${PREFIX}-input-loader`,
  date: `${PREFIX}-input-date`,
} as const

export const inputSelectors = doSelectors(inputClasses)
const borderWidth = 1

export const StyledContainer = restyled.div<
  Partial<InputProps> & {cssWidth: InputProps['width']; focused: boolean}
>(
  ({
    theme: {spaces, colors, widths},
    cssWidth,
    unborder,
    focused,
    error,
    disabled,
    isAbsoluteCaption,
  }) => {
    const getBorderColor = (hovered?: boolean) => {
      if (disabled) return colors.grey[20]
      if (error) return colors.error
      if (focused) return colors.focus
      if (unborder) return 'transparent'
      if (hovered) return colors.grey[40]
      return colors.grey[30]
    }

    const getBoxShadow = () => {
      if (disabled) return null
      if (error) return `inset 0 0 0 1px ${colors.error}`
      if (focused) return `inset 0 0 0 1px ${colors.focus}`
      return null
    }

    return {
      position: 'relative',
      marginBottom: isAbsoluteCaption ? spaces.x12 : null,
      width: cssWidth ?? widths.field,

      [inputSelectors.wrap]: {
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        padding: spaces.join('x0', 'x6'),
        backgroundColor: unborder ? 'transparent' : colors.white,
        borderColor: getBorderColor(),
        borderWidth,
        borderStyle: 'solid',
        borderRadius: spaces.x2,
        boxShadow: getBoxShadow(),
        ':hover': {
          borderColor: getBorderColor(true),
          boxShadow: getBoxShadow(),
        },
      },
      [inputSelectors.field]: {
        position: 'relative',
        overflow: 'hidden',
        flexGrow: 1,
      },
    }
  }
)

export const StyledInput = restyled.input<Partial<InputProps> & {fieldSize: InputProps['size']}>(
  ({theme: {colors, spaces, typography}, disabled, filled, fieldSize}) => {
    const getMargin = () => {
      if (fieldSize === inputSizes.small) {
        return {margin: `${spaces.x2 - borderWidth}px 0`}
      }

      if (fieldSize === inputSizes.large && filled) {
        return {marginTop: spaces.x10 - borderWidth, marginBottom: spaces.x1 + borderWidth}
      }

      return {margin: `${spaces.x5 + borderWidth}px 0`}
    }

    return {
      boxSizing: 'border-box',
      width: '100%',
      height: 'auto',
      padding: 0,
      ...getMargin(),
      backgroundColor: colors.white,
      color: disabled ? colors.grey[40] : colors.grey[100],
      caretColor: colors.focus,
      border: 'none',
      ...typography.p1,
      ':focus, :focus:hover': {
        outline: 'transparent',
      },
      '&[readonly]': {
        cursor: 'default',
      },
      '&[type=number]::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
      },
      '&[type=number]': {
        MozAppearance: 'textfield',
        appearance: 'textfield',
      },
      '&:-webkit-autofill': {
        transition: 'background-color 1000s ease-in-out 0s',
      },
    }
  }
)

export const StyledLabel = restyled.label<Partial<InputProps>>(
  ({theme, filled, size, disabled}) => {
    const {typography, spaces, colors} = theme
    const color = disabled ? colors.grey[40] : colors.grey[60]
    const sizeAsterisk = 5
    const isDefaultLabel = ({filled, size}): boolean => !filled || size === inputSizes.small

    return {
      position: 'absolute',
      zIndex: 1,
      bottom: (size === inputSizes.small ? theme.spaces.x2 : theme.spaces.x6) - borderWidth,
      left: 0,
      right: 0,
      overflow: 'hidden',
      display: size === inputSizes.small && filled ? 'none' : 'block',
      transform: filled ? `translateY(-${spaces.x8}px)` : `translateY(0)`,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color,
      ...typography.p1,
      fontSize: isDefaultLabel({filled, size}) ? typography.p1.fontSize : typography.p3.fontSize,
      lineHeight: isDefaultLabel({filled, size})
        ? typography.p1.lineHeight
        : typography.p3.lineHeight,
      transition: 'transform 0.25s cubic-bezier(0.0, 0, 0.2, 1)',
      userSelect: 'none',
      pointerEvents: 'none',
      [inputSelectors.asterisk]: {
        display: 'inline-block',
        position: 'relative',
        top: filled ? -sizeAsterisk - spaces.x1 : -spaces.x6,
        right: -spaces.x1,
        width: sizeAsterisk,
        height: sizeAsterisk,
        fill: color,
      },
    }
  }
)

export const StyledStartAdornment = restyled.div<StyledAdornmentProps>(
  ({theme: {colors, spaces}, disabled}) => ({
    display: 'flex',
    alignItems: 'center',
    marginRight: spaces.x4,
    color: disabled ? colors.grey[40] : colors.grey[60],
  })
)

export const StyledEndAdornment = restyled.button<StyledAdornmentProps>(
  ({theme, interactive, preMatching, disabled}) => {
    const isActive = interactive && !preMatching && !disabled

    return {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 0,
      padding: !interactive ? 0 : theme.spaces.x2,
      height: '100%',
      backgroundColor: 'transparent',
      border: 'none',
      color: disabled
        ? theme.colors.grey[40]
        : preMatching
          ? theme.colors.accent[90]
          : theme.colors.grey[60],
      transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
      cursor: isActive ? 'pointer' : 'default',
      borderRadius: theme.spaces.x2,
      pointerEvents: disabled ? 'none' : 'auto',
      ':hover': {
        backgroundColor: isActive ? theme.colors.grey[20] : null,
      },
      ':hover:active': {
        backgroundColor: isActive ? theme.colors.grey[40] : null,
        color: isActive ? theme.colors.grey[70] : null,
      },
      [`& ${inputSelectors.loader} svg`]: {
        padding: theme.spaces.x1,
      },
    }
  }
)
