import React, {FC} from 'react'
import {defineColors} from '../utils/defineColors'
import {LogoProps} from '../types'

const primary = '#5faf2d'
const getPathColor = defineColors('#000000')

const Logo: FC<LogoProps> = ({color, ...props}) => (
  <svg fill="none" viewBox="0 0 249 100" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M140.607 86.5089V40.0469H158.679C174.227 40.0469 177.749 47.5469 177.749 54.6496C177.749 62.0839 172.766 69.451 158.613 69.451H146.654V86.5089H140.607ZM146.654 45.6891V63.8088H158.48C167.517 63.8088 171.437 60.0917 171.437 54.5824C171.437 49.4719 168.181 45.6891 158.945 45.6891H146.654Z"
      fill={getPathColor(color)}
    />
    <path
      d="M182.534 60.8215C184.727 54.7806 190.242 51.3965 197.683 51.3965C207.052 51.3965 211.768 56.3084 211.768 66.1321V86.5085H206.187V80.5348C204.394 84.2519 199.077 87.1059 193.497 87.1059C186.321 87.1059 181.471 82.9915 181.471 76.8176C181.471 68.9189 188.315 65.932 206.187 65.932C206.187 59.5596 203.464 56.6399 197.55 56.6399C192.367 56.6399 188.979 58.6979 187.583 62.6808L182.534 60.8215ZM206.187 71.7743V70.5124C191.969 70.5124 187.318 72.1059 187.318 76.8176C187.318 79.9374 190.109 81.8624 194.627 81.8624C201.404 81.8624 206.187 77.6808 206.187 71.7743Z"
      fill={getPathColor(color)}
    />
    <path
      d="M230.041 85.2483L214.627 51.9941H221.204L232.898 77.8798L242.599 51.9941H248.712L230.307 99.7839H224.194L230.041 85.2483Z"
      fill={getPathColor(color)}
    />
    <path
      d="M81.9536 86.5095L72.3186 70.5119L62.8824 86.5095H51.5865L66.098 62.413L51.3069 40.043H63.1081L72.5832 54.9788L81.6202 40.043H92.9161L78.3657 63.1433L93.3152 86.5095H81.9536Z"
      fill={getPathColor(color)}
    />
    <path
      d="M130.034 70.7129C130.034 80.9355 122.326 87.3079 112.226 87.3079C103.322 87.3079 95.8267 82.396 95.0957 71.7091H104.731C105.394 76.3551 109.117 78.3474 113.17 78.3474C118.22 78.3474 120.399 74.9617 120.399 70.5815C120.399 66.0012 117.01 63.1472 112.292 63.1472C108.372 63.1472 105.78 65.0065 104.584 66.9973H95.3753L98.558 40.0469H128.847L127.837 49.0747H106.169L105.308 57.9934C105.308 57.9934 107.583 55.3157 114.481 55.3157C122.791 55.3127 130.034 60.8862 130.034 70.7129Z"
      fill={getPathColor(color)}
    />
    <path
      d="M96.2364 34.2593C91.1027 14.2712 74.8196 0.228516 41.0276 0.228516H0.22876L5.76757 37.6404C9.93402 71.3979 22.8579 82.2581 45.7293 86.2575C45.8354 86.2739 46.5605 86.377 46.5694 85.5302C46.5724 85.2255 46.3796 84.9627 46.115 84.8492C30.6556 78.3468 28.8916 57.0236 25.5892 35.6258L23.0343 18.5723H46.3243C67.7963 18.5723 84.3754 18.2333 94.8715 34.7924C95.027 35.0254 95.2707 35.1972 95.5592 35.1972C95.9643 35.1972 96.2663 34.8686 96.2663 34.4639C96.2663 34.3907 96.2558 34.322 96.2364 34.2593Z"
      fill={getPathColor(color, primary)}
    />
  </svg>
)

export default Logo
