import {makeUiStyles} from '../../theme'
import {TableBlockedZIndexes} from '../styles'
import {TableStyleHelpers} from '../Cell/styles'
import {BodyCellEditIconTypeId} from './CellEditIcon'

export const useBodyCellStyles = makeUiStyles((theme) => {
  const baseSelected = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    content: '""',
    display: 'block',
    pointerEvents: 'none',
    zIndex: 1,
  }

  return {
    root: {
      position: 'relative',
      display: 'table-cell',
      border: '1px solid',
      borderColor: TableStyleHelpers.getBorderColor(theme),
      background: theme.colors.white,
      verticalAlign: 'top',
      textAlign: 'left',
      ...theme.typography.p2,
      '&:focus': {
        outline: 'none',
      },
      '&::after': {
        boxSizing: 'content-box',
      },
    },
    interactive: {
      userSelect: 'none',
      '&:hover::after': {
        ...baseSelected,
        left: '-1px',
        top: '-1px',
        border: `1px solid ${theme.colors.grey[40]}`,
      },
    },
    nonInteractive: {
      cursor: 'default',
      '& *': {
        cursor: 'default',
      },
    },
    selected: {
      background: `${theme.colors.white} !important`,
      '&::after, &:hover::after': {
        ...baseSelected,
        ...TableStyleHelpers.getBoldBorder(theme),
        left: '-2px',
        top: '-2px',
      },
    },
    selectedSecondary: {
      background: `${theme.colors.accent[10]} !important`,
      borderColor: `${theme.colors.accent[20]} !important`,
    },
    editable: {
      outline: 'none',
      [`[data-type*="table-row"]:hover & [data-type*="table-cell-label"]`]: {
        ['-webkit-mask-image']:
          'linear-gradient(to var(--gradient-dir), transparent 16px, black,black,black)',
      },
      [`[data-type*="table-row"] & [data-type*="${BodyCellEditIconTypeId}"]`]: {
        visibility: 'hidden',
      },
      [`[data-type*="table-row"]:hover & [data-type*="${BodyCellEditIconTypeId}"]`]: {
        visibility: 'visible',
      },
    },
    editing: {
      // opacity: 0.5,
      // '&:after, &:hover:after': {
      //   border: 'none',
      // },
      // visibility: 'hidden',
      background: theme.colors.white,
      [`& [data-type="${BodyCellEditIconTypeId}"]`]: {
        visibility: 'visible !important',
      },
    },
    disabled: {
      '& > *': {
        opacity: 0.4,
      },
    },
    blocked: {
      ...TableStyleHelpers.getCellBlocked(theme),
      zIndex: TableBlockedZIndexes.left,
    },
    widthSlider: {
      background: 'red',
      width: theme.spaces.x4,
      height: '100%',
      position: 'absolute',
      top: 0,
      right: `-${theme.spaces.x2}px`,
      cursor: 'col-resize',
      zIndex: TableBlockedZIndexes.cross + 1,
    },

    // shadow in last blocked header
    shadowBlock: {
      position: 'absolute',
      right: 0,
      width: '10px',
      bottom: 0,
      height: '100vh',
      background: 'linear-gradient(90deg, rgba(0,0,0,0.1) 0%, rgba(255,255,255,0) 100%)',
      transform: 'translate(100%, 100%)',
      pointerEvents: 'none',
    },
  }
}, 'table-body-cell')

export default useBodyCellStyles
