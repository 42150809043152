import React, {ReactElement} from 'react'
import {restyled} from '../styles'

const StyledHighlightText = restyled.span<{selected: boolean}>(
  ({theme: {colors, typography}, selected}) => ({
    ...typography.h4,
    color: selected ? colors.accent[90] : null,
  })
)

export function highlightText(
  text: string,
  searchString: string,
  selected = true
): ReactElement | string {
  if (!searchString) return text

  const startIndex = text.toLowerCase().indexOf(searchString.toLowerCase())

  if (startIndex !== -1) {
    const endIndex = startIndex + searchString.length
    return (
      <>
        {text.slice(0, startIndex)}
        <StyledHighlightText selected={selected}>
          {text.slice(startIndex, startIndex + searchString.length)}
        </StyledHighlightText>
        {text.slice(endIndex)}
      </>
    )
  }

  return text
}
