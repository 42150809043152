import React, {CSSProperties, FC, useCallback} from 'react'
import {usePrivateDropdownStyles} from '../../../PrivateDropdown'
import {SelectList} from '../../../Select'
import {BodyCellInnerSelectEditProps} from './types'
import {getCellDensityClassNameBy} from '../../helpers'
import {EditableCellPortal} from '../EditableCellPortal'
import {useBaseCellStyles} from '../../Cell/styles'
import {useInnerSelectStyles} from './styles'

const getStyles = (ref, forFakeBlock) => {
  const editableBorderWidth = 3
  const anchor = ref.current || ref
  const styles: CSSProperties = {}

  if (anchor) {
    const rect = anchor.getBoundingClientRect()
    const targetHeight = rect.height + editableBorderWidth

    styles.width = rect.width
    if (forFakeBlock) {
      styles.height = targetHeight
    } else {
      // o.marginTop = targetHeight;
    }
  }

  return styles
}

export const BodyCellInnerSelectEdit: FC<BodyCellInnerSelectEditProps> = (
  props: BodyCellInnerSelectEditProps
) => {
  const {density, noWrap, rootRef, options, children: value, onCancel, onChange} = props

  const dropdownClasses = usePrivateDropdownStyles()
  const baseClasses = useBaseCellStyles()
  // const densityClassName = baseClasses[getCellDensityClassNameBy(density)]

  const onClickAway = useCallback((e) => {
    e.stopPropagation()
    onCancel && onCancel()
  }, [])

  const onClickFakeSelect = useCallback((e) => {
    onCancel && onCancel()
  }, [])

  return (
    <EditableCellPortal cellRef={rootRef} onClickAway={onClickAway}>
      <>
        <div style={getStyles(rootRef, true)} onClick={onClickFakeSelect} />
        <div className={dropdownClasses.root} style={getStyles(rootRef, false)}>
          <SelectList items={options || []} multiple={false} selected={value} onChange={onChange} />
        </div>
      </>
    </EditableCellPortal>
  )
}

export default BodyCellInnerSelectEdit
