import {ExtractConstValues} from '@root/core/helperTypes'
import {Roles} from '@root/constants'

export class UserRolesInfo {
  isNotPermitted: boolean
  role?: ExtractConstValues<typeof Roles>
  isManager?: boolean
  isBusiness?: boolean
  isSpecialist?: boolean
  isStakeholder?: boolean
  isAdmin?: boolean

  constructor(roleStr?: string) {
    if (!roleStr) {
      this.isNotPermitted = true
      return
    }

    switch (roleStr) {
      case Roles.MANAGER:
        this.isManager = true
        break
      case Roles.BUSINESS:
        this.isBusiness = true
        break
      case Roles.SPECIALIST:
        this.isSpecialist = true
        break
      case Roles.STAKEHOLDER:
        this.isStakeholder = true
        break
      case Roles.ADMIN:
        this.isAdmin = true
        break
      default:
        throw new Error(`Role is out of range: ${roleStr}`)
    }

    this.role = roleStr as ExtractConstValues<typeof Roles>
  }
}
