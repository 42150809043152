import React from 'react'
import createLogo from '../utils/createSvgLogo'
import {logoColors} from '../types'
import BlankSmall from './Small'

export default createLogo((_, color = logoColors.multi, partialProps) => {
  const props = {
    color,
    width: '100%',
    height: '100%',
    ...partialProps,
  }

  return <BlankSmall {...props} />
}, 'NoLogo')
