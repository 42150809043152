import React, {CSSProperties, FC, useCallback, useMemo} from 'react'
import clsx from 'clsx'
import debounce from 'lodash.debounce'
import Autocomplete from '@material-ui/lab/Autocomplete'

import {ArrowUp as ArrowUpIcon} from '@x5-react-uikit/icons'
import {sizeTokenValues} from '@x5-react-uikit/tokens'
import {LoaderBlock} from '../../../Loader'
import {
  AutocompleteDefaultOption,
  BodyCellInnerAutocompleteEditProps,
  BodyCellInnerAutocompleteOption,
} from './types'
import {useInnerAutocompleteStyles} from './styles'
import {getCellDensityClassNameBy} from '../../helpers'
import {EditableCellPortal} from '../EditableCellPortal'
import {useBaseCellStyles} from '../../Cell/styles'

const emptyOptionValue = 'Не выбрано'

const getAutocompleteStyles = (ref) => {
  const wishWidth = 350
  const anchor = ref.current || ref
  const styles: CSSProperties = {}

  if (anchor) {
    const rect = anchor.getBoundingClientRect()
    styles.minWidth = Math.max(rect.width, wishWidth)
  }

  return styles
}

const getInputWrapStyles = (ref) => {
  const fixBorder = 3
  const anchor = ref.current || ref
  const styles: CSSProperties = {}

  if (anchor) {
    const rect = anchor.getBoundingClientRect()
    styles.height = rect.height + fixBorder
  }

  return styles
}

export const BodyCellInnerAutocompleteEdit: FC<BodyCellInnerAutocompleteEditProps> = (
  props: BodyCellInnerAutocompleteEditProps
) => {
  const {
    density,
    noWrap,
    rootRef,
    children: selected,
    onCancel,
    onChange,
    getOptionsHandleRequest,
    rowId,
    ListboxComponent,
    inputPlaceholder,
    filterOptions,
    renderInputSelectedValue,
    customRenderSelected,
    customOptionLabel,
    renderOption,
  } = props

  const baseClasses = useBaseCellStyles()
  const innerClasses = useInnerAutocompleteStyles()
  const densityClassName = baseClasses[getCellDensityClassNameBy(density)]

  const [options, setOptions] = React.useState<BodyCellInnerAutocompleteOption[]>([])
  const [inputValue, setInputValue] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [searched, setSearched] = React.useState(false)

  const getOptions = (str = '') => {
    setLoading(true)
    setSearched(true)

    getOptionsHandleRequest(str, rowId)
      .then((res) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return setOptions([{...AutocompleteDefaultOption}, ...res])
      })
      .catch((err) => setOptions([]))
      .finally(() => setLoading(false))
  }

  React.useEffect(() => {
    setLoading(true)

    let selectedInputVal = typeof selected !== 'object' ? selected : ''
    if (renderInputSelectedValue) selectedInputVal = renderInputSelectedValue(selected)

    setInputValue(selectedInputVal)
    debounceGetOptions('')
  }, [])

  const debounceGetOptions = React.useCallback(
    debounce((str) => getOptions(str), 500),
    []
  )

  const onClickAway = useCallback((e) => {
    e.stopPropagation()
    onCancel && onCancel()
  }, [])

  const muiClasses = useMemo(
    () => ({
      option: innerClasses.option,
      paper: innerClasses.paper,
    }),
    []
  )

  const renderInput = useCallback((params, loading) => {
    const {inputProps, InputProps} = params
    const {className, ...otherInputProps} = inputProps

    return (
      <label
        ref={InputProps.ref}
        className={clsx(
          innerClasses.inputWrap,
          densityClassName,
          loading && innerClasses.inputWrapDisabled
        )}
        style={getInputWrapStyles(rootRef)}
      >
        <input
          className={clsx(innerClasses.input, className)}
          {...otherInputProps}
          autoFocus={true}
          placeholder={inputPlaceholder}
          onFocus={(e) => e.target.select()}
        />
        <ArrowUpIcon className={innerClasses.iconUp} size={sizeTokenValues.small} />
      </label>
    )
  }, [])

  const _onChange = useCallback((event, newValue: BodyCellInnerAutocompleteOption) => {
    onChange && onChange(event, newValue)
  }, [])

  const renderOptionLabel = (option) => {
    if (typeof option !== 'object') return option

    let optionLabel = `${option.key} - ${option.value}`

    if (customOptionLabel) optionLabel = customOptionLabel(option)

    if (option.key === option.value) optionLabel = option.key
    if (option.value === AutocompleteDefaultOption.value) optionLabel = emptyOptionValue

    return optionLabel
  }

  const renderSelected = (option, value) => {
    if (option.value === AutocompleteDefaultOption.value) return option.value === value.value

    return option.key === value.key
  }

  const renderInputValue = (event, newInputValue) => {
    if (loading) return
    if (newInputValue === inputValue) return

    const valueToSave = newInputValue === emptyOptionValue ? '' : newInputValue
    setInputValue(valueToSave)

    debounceGetOptions(!searched ? '' : valueToSave)
  }

  // switch off inner autocomplete options filtering
  const _filterOptions = (options, state) => options

  return (
    <EditableCellPortal cellRef={rootRef} onClickAway={onClickAway}>
      <Autocomplete
        disableClearable
        disableCloseOnSelect
        open
        openOnFocus
        classes={muiClasses}
        filterOptions={filterOptions || _filterOptions}
        getOptionLabel={renderOptionLabel}
        getOptionSelected={customRenderSelected || renderSelected}
        inputValue={inputValue}
        ListboxComponent={ListboxComponent}
        loading={loading}
        loadingText={
          <div className={innerClasses.loader}>
            <LoaderBlock />
          </div>
        } //size={getPxFromSize('large')}
        noOptionsText={'По вашему запросу ничего не найдено'}
        options={options}
        renderInput={(params) => renderInput(params, loading)}
        renderOption={renderOption}
        style={getAutocompleteStyles(rootRef)}
        value={selected}
        onChange={_onChange}
        onInputChange={renderInputValue}
      />
    </EditableCellPortal>
  )
}

export default BodyCellInnerAutocompleteEdit
