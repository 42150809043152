import LoaderFrame from '@root/components/Loader'
import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {getDecodedAuth, updateAuth} from '@root/redux/authSlice'
import {getOnAuthRedirect} from './utils'

const KeycloakCallbackReceiver = ({keycloakClient}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!keycloakClient) return

    const token = keycloakClient.token
    if (token) {
      const decodedAuth = getDecodedAuth(token)
      dispatch(updateAuth(decodedAuth))
      const successAuthRedirect = getOnAuthRedirect()
      navigate(successAuthRedirect)
      // if (!IS_DEBUG)
      //   return removeSuccessAuthRedirect;
    } else navigate('/')
  }, [keycloakClient, dispatch, navigate])
  return <LoaderFrame />
}

export default KeycloakCallbackReceiver
