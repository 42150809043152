import {useCallback, useEffect} from 'react'
import {useTeardownEffect} from './useTeardownEffect'
import {useBlocker} from 'react-router-dom'
import {promiseRejectDelay} from '../utils'

type SaveBeforeTeardownParams = {
  shouldBlock: boolean
  saveAction?: () => Promise<void>
  alertMessage?: string
  saveFailureMessage?: string
}

const defaultAlertMessage = 'Изменения могут не сохраниться. Уйти?'
const defaultSaveFailureMessage = 'Не удалось сохранить изменения. Уйти?'

export const useSaveChangesBeforeTeardown = ({
  alertMessage = defaultAlertMessage,
  saveFailureMessage = defaultSaveFailureMessage,
  shouldBlock,
  saveAction,
}: SaveBeforeTeardownParams) => {
  const handleUnload = useCallback(
    (e: BeforeUnloadEvent) => {
      if (shouldBlock) {
        e.preventDefault()
      }
    },
    [shouldBlock]
  )

  useTeardownEffect({onUnload: handleUnload})

  const blocker = useBlocker(shouldBlock)

  useEffect(() => {
    if (blocker.state !== 'blocked') {
      return
    }

    const ensureWantToExit = (message: string) => {
      const proceed = window.confirm(message)

      if (proceed) {
        // This timeout is needed to avoid a weird "race" on POP navigations
        // between the `window.history` revert navigation and the result of
        // `window.confirm`
        setTimeout(() => blocker.proceed(), 0)
      } else {
        blocker.reset()
      }
    }

    if (saveAction != null) {
      Promise.race([saveAction(), promiseRejectDelay(1000)])
        .then(() => {
          if (blocker.state === 'blocked') {
            blocker.proceed()
          }
        })
        .catch(() => {
          ensureWantToExit(saveFailureMessage)
        })

      return
    } else {
      ensureWantToExit(alertMessage)
    }
  }, [alertMessage, blocker, saveAction, saveFailureMessage])
}
