import React, {FC, useRef, useState} from 'react'
import clsx from 'clsx'
import {
  Button,
  Typography,
  Dropdown as DropdownComponent,
  SelectList,
  DropdownInnerRefMethods,
} from '@x5-react-uikit/core'
import {ArrowDown as ArrowDownIcon, ArrowUp as ArrowUpIcon} from '@x5-react-uikit/icons'
import {sizeTokenValues} from '@x5-react-uikit/tokens'

import {CalendarDropdownProps} from './types'
import {useStyles} from './styles'

const Dropdown: FC<CalendarDropdownProps> = ({title, options, onChange, disabled, ...props}) => {
  const ref = useRef<DropdownInnerRefMethods>()
  const [isOpen, changeOpen] = useState(false)
  const classes = useStyles()
  const {value: selectedValue} = options.find((item) => item.selected) || {}

  const onChangeHandle = (event, value) => {
    const {unit} = options.find((item) => item.value === value)
    onChange(value, unit)
    ref.current.close()
  }

  if (disabled) {
    return (
      <Typography dropdown-disabled="true" variant="h4">
        {title}
      </Typography>
    )
  }

  return (
    <DropdownComponent
      action={
        <Button
          className={clsx(classes.root, isOpen && classes.open)}
          endIcon={
            isOpen ? (
              <ArrowUpIcon size={sizeTokenValues.small} />
            ) : (
              <ArrowDownIcon size={sizeTokenValues.small} />
            )
          }
          size={sizeTokenValues.small}
          variant="outlined"
        >
          <Typography variant="h4">{title}</Typography>
        </Button>
      }
      innerRef={ref}
      onChange={changeOpen}
      {...props}
    >
      <SelectList items={options} selected={selectedValue} onChange={onChangeHandle} />
    </DropdownComponent>
  )
}

export default Dropdown
