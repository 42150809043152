import {Modal, Typography} from '@x5-react-uikit/core'

const SaveTaskModal = ({isOpen, onSave, onClose}) => {
  return (
    <Modal
      closeOnOverlay
      buttons={[
        {
          onClick: onClose,
          side: 'right',
          text: 'Отмена',
        },
        {
          onClick: () => {
            onSave()
            onClose()
          },
          side: 'right',
          text: 'Сохранить',
        },
      ]}
      isOpen={isOpen}
      size="medium"
      title="Сохранить заявку"
      onClose={onClose}
    >
      <Typography variant="p1">
        <span>Все внесенные данные будут сохранены. Данная заявка будет </span>
        <span>отображена в подразделе заявок “Черновики”. </span>
      </Typography>
    </Modal>
  )
}

export default SaveTaskModal
