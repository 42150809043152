import {createContext} from 'react'
import type {SetAction} from 'core/helperTypes'
import type {LaunchInfo} from './types'

export type TableContextState = {
  setLaunchModal: SetAction<LaunchInfo | null>
  systemId: string
  taskId?: string
  rolesMap?: Record<string, string>
}

export const tableContext = createContext<TableContextState | null>(null)
export const TableContextProvider = tableContext.Provider
