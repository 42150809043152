import React, {FC} from 'react'
import {defineColors} from '../utils/defineColors'
import {LogoProps} from '../types'

const primary = '#5faf2d'
const getPathColor = defineColors('#000000')

const Logo: FC<LogoProps> = ({color, ...props}) => (
  <svg fill="none" viewBox="0 0 444 100" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M146.511 40.0469H184.839V45.6891H168.659V86.5104H162.624V45.6891H146.509V40.0469H146.511Z"
      fill={getPathColor(color)}
    />
    <path
      d="M185.171 51.9958H190.742V55.9788C191.67 53.1248 194.589 51.3984 198.633 51.3984C199.761 51.3984 201.286 51.5313 201.817 51.73V57.1063C200.955 56.7748 199.827 56.6419 198.7 56.6419C193.528 56.6419 190.742 59.4301 190.742 64.2748V86.5119H185.171V51.9958Z"
      fill={getPathColor(color)}
    />
    <path
      d="M205.795 60.8235C207.983 54.7825 213.487 51.3984 220.914 51.3984C230.264 51.3984 234.972 56.3103 234.972 66.1341V86.5119H229.401V80.5382C227.611 84.2554 222.306 87.1093 216.736 87.1093C209.575 87.1093 204.734 82.9949 204.734 76.8211C204.734 68.9223 211.564 65.9355 229.401 65.9355C229.401 59.563 226.682 56.6434 220.781 56.6434C215.609 56.6434 212.226 58.7013 210.834 62.6843L205.795 60.8235ZM229.401 71.7763V70.5143C215.21 70.5143 210.569 72.1078 210.569 76.8196C210.569 79.9394 213.354 81.8644 217.863 81.8644C224.627 81.8644 229.401 77.6828 229.401 71.7763Z"
      fill={getPathColor(color)}
    />
    <path
      d="M244.124 51.9958H249.695V57.3064C251.816 53.5236 255.795 51.3984 260.901 51.3984C270.316 51.3984 275.423 56.1774 275.423 67.063V86.5119H269.852V67.5932C269.852 60.0932 266.67 56.5075 259.84 56.5075C254.203 56.5075 249.695 60.0917 249.695 66.8629V86.5104H244.124V51.9958Z"
      fill={getPathColor(color)}
    />
    <path
      d="M283.844 61.0893C283.844 55.2485 288.883 51.3984 296.641 51.3984C304.334 51.3984 309.77 54.917 311.164 60.8235L306.124 62.6828C304.996 58.6341 301.548 56.4432 296.509 56.4432C292.464 56.4432 289.679 58.2354 289.679 60.825C289.679 68.7237 310.964 63.546 310.964 77.0212C310.964 83.1936 305.859 87.1108 297.835 87.1108C289.282 87.1108 283.844 83.9253 281.921 77.6858L286.96 75.8265C288.618 79.9424 292.133 82.066 297.437 82.066C302.411 82.066 305.129 80.2739 305.129 77.0869C305.131 68.9208 283.844 73.8327 283.844 61.0893Z"
      fill={getPathColor(color)}
    />
    <path
      d="M324.162 81.267V99.7855H318.591V51.9958H324.162V58.1682C326.483 54.1195 330.859 51.3984 337.225 51.3984C346.641 51.3984 353.869 58.1024 353.869 68.988C353.869 79.7407 346.839 87.1093 336.761 87.1093C330.528 87.1093 326.614 84.9184 324.162 81.267ZM348.034 69.121C348.034 61.4208 343.06 56.6419 336.097 56.6419C328.803 56.6419 324.16 61.2222 324.16 68.7222V69.9842C324.16 78.0158 329.598 81.8659 336.296 81.8659C343.326 81.8644 348.034 76.8868 348.034 69.121Z"
      fill={getPathColor(color)}
    />
    <path
      d="M377.079 51.3984C387.821 51.3984 395.381 58.8984 395.381 69.1867C395.381 79.6078 387.888 87.1093 377.012 87.1093C366.403 87.1093 358.776 79.6093 358.776 69.1867C358.776 58.8984 366.203 51.3984 377.079 51.3984ZM377.012 81.8644C384.439 81.8644 389.544 76.6867 389.544 69.1867C389.544 61.7524 384.173 56.6419 377.012 56.6419C369.851 56.6419 364.611 61.6867 364.611 69.1867C364.611 76.621 369.718 81.8644 377.012 81.8644Z"
      fill={getPathColor(color)}
    />
    <path
      d="M402.808 51.9958H408.379V55.9788C409.307 53.1248 412.225 51.3984 416.269 51.3984C417.396 51.3984 418.921 51.5313 419.452 51.73V57.1063C418.589 56.7748 417.463 56.6419 416.335 56.6419C411.162 56.6419 408.379 59.4301 408.379 64.2748V86.5119H402.808V51.9958Z"
      fill={getPathColor(color)}
    />
    <path
      d="M423.031 51.9956H428.866V43.168H434.437V51.9956H443.919V57.2391H434.437V76.6208C434.437 80.4708 435.697 81.8642 438.945 81.8642C440.404 81.8642 442.127 81.5984 443.919 81.1339V86.3773C442.195 86.7761 439.609 87.1076 437.553 87.1076C431.32 87.1076 428.866 84.1208 428.866 76.0891V57.2391H423.031V51.9956Z"
      fill={getPathColor(color)}
    />
    <path
      d="M81.7894 86.5115L72.1738 70.5139L62.7567 86.5115H51.4837L65.9674 62.415L51.2062 40.0449H62.9835L72.4394 54.9807L81.4582 40.0449H92.7312L78.2072 63.1438L93.1281 86.51H81.7894V86.5115Z"
      fill={getPathColor(color)}
    />
    <path
      d="M129.774 70.7159C129.774 80.9384 122.082 87.3109 112.002 87.3109C103.117 87.3109 95.636 82.399 94.9064 71.7106H104.522C105.184 76.3566 108.899 78.3488 112.944 78.3488C117.984 78.3488 120.159 74.9632 120.159 70.5815C120.159 66.0012 116.777 63.1472 112.068 63.1472C108.155 63.1472 105.569 65.0066 104.376 66.9973H95.1839L98.3602 40.0469H128.59L127.581 49.0747H105.957L105.098 57.9934C105.098 57.9934 107.369 55.3142 114.252 55.3142C122.546 55.3157 129.774 60.8877 129.774 70.7159Z"
      fill={getPathColor(color)}
    />
    <path
      d="M96.0433 34.2573C90.92 14.2693 74.6698 0.226562 40.9446 0.226562H0.226746L5.75437 37.6399C9.91239 71.3989 22.8102 82.2591 45.6368 86.2585C45.7427 86.2749 46.4663 86.378 46.4753 85.5312C46.4782 85.2265 46.2858 84.9637 46.0217 84.8502C30.5936 78.3478 28.8331 57.0231 25.5374 35.6253L22.9877 18.5718H46.2321C67.6622 18.5718 84.2078 18.2328 94.6827 34.792C94.8378 35.0249 95.081 35.1967 95.369 35.1967C95.7733 35.1967 96.0746 34.8681 96.0746 34.4634C96.0732 34.3902 96.0627 34.3215 96.0433 34.2573Z"
      fill={getPathColor(color, primary)}
    />
  </svg>
)

export default Logo
