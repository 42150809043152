import {Modal, Typography} from '@x5-react-uikit/core'
import useNotify from '@root/hooks/useNotify'
import {useNavigate, useParams} from 'react-router-dom'
import {useDeleteReportConfigurationMutation} from '@root/redux/api/reportConfigurationsApi'

const ConfDeleteModal = ({open, onClose}) => {
  const navigate = useNavigate()
  const {notifySuccess, notifyError} = useNotify()
  const {configurationId} = useParams()

  const [deleteConf] = useDeleteReportConfigurationMutation()
  const handleDelete = () => {
    deleteConf({configurationId})
      .unwrap()
      .then(() => {
        navigate('/reports?tab=configurations')
        notifySuccess('Конфигурация удалена')
      })
      .catch((error) => {
        console.error(error)
        notifyError()
      })
  }

  return (
    <>
      <Modal
        closeOnOverlay
        buttons={[
          {
            onClick: onClose,
            side: 'right',
            text: 'Отмена',
          },
          {
            onClick: handleDelete,
            side: 'right',
            text: 'Удалить',
          },
        ]}
        isOpen={open}
        size="medium"
        title="Удаление отчёта"
        onClose={onClose}
      >
        <Typography>
          Вы действительно хотите удалить Конфигурацию? Конфигурация не подлежит восстановлению.
          Можно будет создать ее повторно.
        </Typography>
      </Modal>
    </>
  )
}

export default ConfDeleteModal
