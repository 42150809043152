import styled from '@emotion/styled'
import {FC, PropsWithChildren} from 'react'
import clsx from 'clsx'

type Props = {
  isNew?: boolean
}

enum classNames {
  new = 'new',
}

const StyledText = styled.span`
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;

  &.${classNames.new} {
    padding-left: 24px;
    &:before {
      background: #1976d2; // TODO: COULD NOT FIND SUCH A COLOR IN THEME OBJECT
      content: '';
      display: block;
    }
  }

  &:before {
    height: 5px;
    width: 5px;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 5px;
  }
`

export const ReadStatus: FC<PropsWithChildren<Props>> = ({isNew, children}) => {
  return (
    <StyledText
      className={clsx({
        [classNames.new]: isNew,
      })}
    >
      {children}
    </StyledText>
  )
}
