import {sizeTokenValues} from '@x5-react-uikit/tokens'
import {PREFIX, restyled, doSelectors} from '../styles'
import {SwitchProps} from './types'

export const switchClasses = {
  label: `${PREFIX}-switch-label`,
  input: `${PREFIX}-switch-input`,
} as const

const selectors = doSelectors(switchClasses)

const heights = {
  small: 16,
  medium: 20,
}
const widths = {
  small: 28,
  medium: 32,
}

export const StyledSwitch = restyled.label<Partial<SwitchProps> & {indeterminate: boolean}>(
  ({theme: {colors, palette, spaces, typography}, checked, indeterminate, disabled, size}) => {
    const isSmall = size === sizeTokenValues.small
    const offset = spaces.x1
    const sizeThumbs = {
      small: heights.small - offset * 2,
      medium: heights.medium - offset * 2,
    }

    const getTransformThumbs = () => {
      if (indeterminate) return `translateX(${(widths[size] - sizeThumbs[size]) / 2}px)`
      if (checked) return `translateX(${widths[size] - sizeThumbs[size] - offset}px)`
      return `translateX(${offset}px)`
    }

    const getBackground = () => {
      if (indeterminate) return disabled ? palette.yellow[20] : palette.yellow[60]
      if (checked) return disabled ? colors.accent[20] : colors.accent[90]
      return disabled ? colors.grey[20] : colors.grey[30]
    }

    const getHoverBackground = () => {
      if (disabled) return null
      if (indeterminate) return palette.yellow[40]
      if (checked) return colors.accent[80]
      return colors.grey[40]
    }

    return {
      position: 'relative',
      display: 'flex',
      height: isSmall ? typography.p2.lineHeight : typography.p1compact.lineHeight,
      userSelect: 'none',
      cursor: 'pointer',
      color: disabled ? colors.grey[40] : null,
      '::before': {
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'block',
        content: '""',
        width: widths[size],
        height: heights[size],
        backgroundColor: getBackground(),
        borderRadius: heights[size] / 2,
        transition: 'background-color 0.2s ease-out',
      },
      '::after': {
        position: 'absolute',
        top: offset,
        left: 0,
        display: 'block',
        content: '""',
        width: sizeThumbs[size],
        height: sizeThumbs[size],
        backgroundColor: colors.white,
        borderRadius: '50%',
        transform: getTransformThumbs(),
        transition: 'transform 0.2s ease-in-out',
      },
      ':hover::before': {
        backgroundColor: getHoverBackground(),
      },
      [selectors.label]: {
        position: 'absolute',
        top: isSmall ? 1 : 0,
        left: widths[size] + spaces.x4,
        ...(isSmall ? typography.p2 : typography.p1compact),
      },
      [selectors.input]: {
        position: 'absolute',
        visibility: 'hidden',
        opacity: 0,
        top: 0,
        left: 0,
        width: 1,
        height: 1,
      },
    }
  }
)
