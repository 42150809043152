import {restyled} from '../styles'
import {StyledLinkProps} from './types'
import isValidProp from '@emotion/is-prop-valid'

function border(pseudolink: StyledLinkProps['pseudolink'], color: string): string {
  return `1px ${pseudolink ? 'dashed' : 'solid'} ${color}`
}

export const StyledLink = restyled('a', {shouldForwardProp: isValidProp})<StyledLinkProps>(
  ({noUnderline, theme: {typography, colors}, disabled, pseudolink}) => ({
    ...typography.p1compact,
    position: 'relative',
    border: 'none',
    backgroundColor: 'transparent',
    padding: 0,
    margin: 0,
    textDecoration: 'none',
    fontSize: 'inherit',
    cursor: disabled ? 'default' : 'pointer',
    pointerEvents: disabled ? 'none' : null,
    color: disabled ? colors.grey[40] : colors.accent[90],
    '&::after': {
      position: 'absolute',
      bottom: -2,
      left: 0,
      content: '""',
      width: '100%',
      height: 1,
      borderTop: pseudolink && !disabled ? border(pseudolink, colors.accent[90]) : null,
    },
    '&:hover': {
      color: colors.accent[80],
      '&::after': {
        borderTop: border(pseudolink, colors.accent[80]),
      },
    },
    '&:visited': {
      color: disabled ? colors.grey[40] : colors.accent[100],
    },
    '&:active': {
      color: colors.accent[100],
      '&::after': {borderTop: 'none'},
    },
    ...(noUnderline
      ? {
          textDecoration: 'none',
          '&::after': {
            display: 'none',
          },
        }
      : {}),
  })
)
