export const getTableRowHoveredJCSS = (code) => {
  return {
    [`& [data-type*="table-row"] [data-type*="${code}"]`]: {
      visibility: 'hidden',
    },
    [`& [data-type*="table-row"]:hover [data-type*="${code}"]`]: {
      visibility: 'visible',
    },
  }
}

export const TableBlockedZIndexes = {
  overCell: 1,
  left: 2,
  top: 3,
  cross: 4,
  extreme: 5,
}
