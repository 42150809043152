import {Checkbox, Loader} from '@x5-react-uikit/core'
import FlexboxRow from '@root/components/FlexboxRow'
import {Controller} from 'react-hook-form'
import {useGetAllRolesQuery} from '@root/redux/api/userApi'

const RoleCheckList = ({control}) => {
  const {data: allRoles, isLoading: isRolesLoading} = useGetAllRolesQuery()

  if (isRolesLoading) return <Loader />

  return (
    <Controller
      control={control}
      name="filters.role.value"
      render={({field: {onChange, value = []}}) => {
        return (
          <FlexboxRow sx={{gap: '16px'}}>
            {Object.keys(allRoles).map((role) => (
              <Checkbox
                checked={value?.includes(role)}
                label={allRoles[role]}
                onChange={(e) =>
                  onChange(e.target.checked ? [...value, role] : value?.filter((r) => r !== role))
                }
              />
            ))}
          </FlexboxRow>
        )
      }}
    />
  )
}

export default RoleCheckList
