import React, {FC} from 'react'
import {LogoProps} from '../types'
import {getPathColor, palette} from './helpers'

const Logo: FC<LogoProps> = ({color, ...props}) => (
  <svg fill="none" viewBox="0 0 94 100" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M30.9667 1.10254H0V98.8973H30.9667V1.10254Z"
      fill={getPathColor(color, palette.first)}
    />
    <path
      d="M80.1242 62.5952C64.387 48.6851 37.9892 54.1134 37.9892 54.1134C37.9892 59.7114 37.2277 77.5231 51.6958 90.585C67.4329 104.495 93.8308 98.9819 93.8308 98.9819C93.8308 98.9819 96.1152 76.4204 80.1242 62.5952Z"
      fill={getPathColor(color, palette.second)}
    />
    <path
      d="M51.7804 9.66933C37.3123 22.6464 38.0738 40.3733 38.0738 46.1409C38.0738 46.1409 64.387 51.5692 80.2088 37.5743C96.1998 23.6642 93.9154 1.10275 93.9154 1.10275C93.9154 1.10275 67.5175 -4.49522 51.7804 9.66933Z"
      fill={getPathColor(color, palette.second)}
    />
  </svg>
)

export default Logo
