import {Dropdown, SelectList} from '@root/components/kit'
import {MoreVertical} from '@x5-react-uikit/icons'
import Paper from '@root/components/Paper'
import {FC, useRef} from 'react'
import {ActionButton} from '@root/components/ActionButton'
import {DropdownInnerRefMethods} from '@root/components/kit'
import {UserRolesInfo} from '@root/core/UserRolesInfo'

type Props = {
  userInfo: UserRolesInfo
  onUpdate: () => void
  onDelete: () => void
}

const ActionsButton: FC<Props> = ({userInfo, onUpdate, onDelete}) => {
  const dropdownRef = useRef<DropdownInnerRefMethods>()

  const actions = {
    ...((userInfo.isAdmin || userInfo.isManager || userInfo.isSpecialist) && {
      update: {name: 'Редактировать', value: 'update', onClick: onUpdate},
    }),
    delete: {name: 'Удалить', value: 'delete', onClick: onDelete},
  }

  return (
    <Dropdown
      disableContentVisual
      usePortal
      action={
        <ActionButton>
          <MoreVertical size="small" />
        </ActionButton>
      }
      className="w-100"
      innerRef={dropdownRef}
      placement="bottom-end"
    >
      <Paper sx={{padding: '8px 0 0 0', backgroundColor: 'white'}}>
        <SelectList
          items={Object.values(actions)}
          selected={[]}
          onChange={(_, actionName) => {
            const action = actions[actionName as keyof typeof actions]
            action.onClick?.()
            dropdownRef?.current?.close()
          }}
        />
      </Paper>
    </Dropdown>
  )
}

export default ActionsButton
