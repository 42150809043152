import React, {FC} from 'react'
import {defineColors} from '../utils/defineColors'
import {LogoProps} from '../types'

const primary = '#5faf2d'
const getPathColor = defineColors('#000000')

const Logo: FC<LogoProps> = ({color, ...props}) => (
  <svg fill="none" viewBox="0 0 353 100" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M151.726 40.0703H157.757V86.5219H151.726V40.0703Z" fill={getPathColor(color)} />
    <path
      d="M168.363 52.0153H173.93V57.0586C175.918 53.4744 179.697 51.418 184.469 51.418C189.308 51.418 192.622 53.4744 194.942 57.323C197.394 53.1429 201.173 51.418 205.945 51.418C213.169 51.418 218.34 54.8678 218.34 63.7612V86.5225H212.773V65.4861C212.773 60.4428 210.917 56.6614 204.62 56.6614C198.788 56.6614 196.135 61.1073 196.135 65.156V86.524H190.568V64.9544C190.568 59.5796 187.918 56.6599 182.615 56.6599C177.644 56.6599 173.931 59.9111 173.931 65.8176V86.521H168.364V52.0153H168.363Z"
      fill={getPathColor(color)}
    />
    <path
      d="M233.055 81.2791V99.7931H227.488V52.0153H233.055V58.1862C235.375 54.1375 239.75 51.418 246.114 51.418C255.527 51.418 262.751 58.1205 262.751 69.0031C262.751 79.7528 255.725 87.1184 245.65 87.1184C239.419 87.1184 235.508 84.929 233.055 81.2791ZM256.917 69.136C256.917 61.4389 251.945 56.6614 244.986 56.6614C237.695 56.6614 233.055 61.2403 233.055 68.7388V69.9992C233.055 78.0279 238.491 81.8779 245.185 81.8779C252.211 81.8765 256.917 76.8989 256.917 69.136Z"
      fill={getPathColor(color)}
    />
    <path
      d="M285.949 51.418C296.686 51.418 304.243 58.9165 304.243 69.2017C304.243 79.6199 296.753 87.1184 285.883 87.1184C275.277 87.1184 267.656 79.6199 267.656 69.2017C267.656 58.9165 275.079 51.418 285.949 51.418ZM285.883 81.8765C293.306 81.8765 298.411 76.7002 298.411 69.2017C298.411 61.7704 293.042 56.6599 285.883 56.6599C278.724 56.6599 273.488 61.7032 273.488 69.2017C273.488 76.6345 278.592 81.8765 285.883 81.8765Z"
      fill={getPathColor(color)}
    />
    <path
      d="M311.667 52.0153H317.235V55.9968C318.162 53.1429 321.079 51.418 325.123 51.418C326.249 51.418 327.774 51.5509 328.305 51.7495V57.1244C327.442 56.7928 326.316 56.6599 325.188 56.6599C320.018 56.6599 317.235 59.4466 317.235 64.2913V86.521H311.667V52.0153Z"
      fill={getPathColor(color)}
    />
    <path
      d="M331.883 52.0137H337.716V43.1875H343.283V52.0137H352.761V57.2556H343.283V76.6328C343.283 80.4814 344.542 81.8748 347.791 81.8748C349.25 81.8748 350.973 81.609 352.761 81.1445V86.3864C351.038 86.7852 348.453 87.1167 346.398 87.1167C340.167 87.1167 337.714 84.1314 337.714 76.1012V57.2556H331.882V52.0137H331.883Z"
      fill={getPathColor(color)}
    />
    <path
      d="M81.7446 86.521L72.1335 70.5279L62.7208 86.521H51.4524L65.9295 62.4305L51.1734 40.0664H62.946L72.399 54.9977L81.4135 40.0664H92.6819L78.166 63.1623L93.0802 86.5225H81.7446V86.521Z"
      fill={getPathColor(color)}
    />
    <path
      d="M129.711 70.7304C129.711 80.9499 122.021 87.3209 111.946 87.3209C103.063 87.3209 95.5863 82.4105 94.8569 71.725H104.468C105.13 76.3711 108.843 78.3618 112.886 78.3618C117.923 78.3618 120.098 74.9777 120.098 70.5975C120.098 66.0186 116.718 63.1647 112.012 63.1647C108.1 63.1647 105.515 65.0225 104.322 67.0133H95.1343L98.3102 40.0703H128.528L127.521 49.0951H105.905L105.047 58.0109C105.047 58.0109 107.317 55.3332 114.197 55.3332C122.485 55.3332 129.711 60.9051 129.711 70.7304Z"
      fill={getPathColor(color)}
    />
    <path
      d="M95.9935 34.2835C90.8725 14.3014 74.6292 0.261719 40.9193 0.261719H0.219299L5.74313 37.6646C9.89905 71.4131 22.792 82.2704 45.6078 86.2698C45.7137 86.2862 46.4357 86.3893 46.4461 85.5425C46.4491 85.2378 46.2567 84.975 45.9926 84.863C30.5713 78.3621 28.811 57.0434 25.5158 35.6515L22.9665 18.6025H46.2C67.6211 18.6025 84.1597 18.2635 94.6301 34.8182C94.7852 35.0511 95.0284 35.2229 95.3163 35.2229C95.7206 35.2229 96.0219 34.8943 96.0219 34.4896C96.0234 34.4164 96.0129 34.3462 95.9935 34.2835Z"
      fill={getPathColor(color, primary)}
    />
  </svg>
)

export default Logo
