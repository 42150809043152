import {inputSelectors} from '../Input/styles'
import {PREFIX, restyled, doSelectors} from '../styles'

export const classes = {
  input: `${PREFIX}-datepicker-input`,
} as const

const selectors = doSelectors(classes)

export const StyledDatepicker = restyled.div<{coordsFreeze: {left: number; right: number}}>(
  ({theme, coordsFreeze}) => ({
    position: 'relative',
    [selectors.input]: {
      userSelect: 'none',
      '&::selection': {
        backgroundColor: theme.colors.accent[10],
      },
    },
    [`& ${inputSelectors.field}::after`]: {
      position: 'absolute',
      zIndex: 1,
      opacity: 0.8,
      top: 5,
      right: coordsFreeze?.right,
      bottom: 5,
      left: coordsFreeze?.left,
      content: coordsFreeze ? "''" : null,
      backgroundColor: theme.colors.white,
      transition: 'all 200ms',
    },
  })
)
