import {FC} from 'react'
import {CustomFilterInputProps} from '@root/components/kit'
import {LaunchStatusSelect} from '@root/features/launchHistory/LaunchStatusSelect'

export const FilterCollectorLaunchStatusSelect: FC<CustomFilterInputProps> = ({
  onChange,
  value,
  size,
}) => {
  return (
    <LaunchStatusSelect
      selectProps={{
        size,
        multiple: true,
        stretch: true,
        label: 'Значение',
        chipText: 'Выбрано: ',
        usePortal: true,
      }}
      value={value as string}
      onChange={onChange}
    />
  )
}
