import React, {ChangeEvent, FC} from 'react'
import clsx from 'clsx'
import {Switch} from '../../../Switch'
import {Tooltip} from '../../../Tooltip'
import {createFakeEvent} from '../../../utils/fakeEvent'
import {defaultCellDensity, getCellDensityClassNameBy} from '../../helpers'
import {useBaseCellStyles} from '../../Cell/styles'
import {BodyCellInnerCheckProps} from './types'
import {sizeTokenValues} from '@x5-react-uikit/tokens'

export const BodyCellInnerCheck: FC<BodyCellInnerCheckProps> = (props: BodyCellInnerCheckProps) => {
  const {
    density = defaultCellDensity,
    editable,
    editing,
    selected,
    Component = Switch,
    TooltipProps = {},
    onChange,
    children: value,
  } = props

  const baseClasses = useBaseCellStyles()
  const densityClassName = baseClasses[getCellDensityClassNameBy(density)]

  const enterHandler = (e) => {
    if (!editable || !selected) {
      return
    }

    if (['Enter', ' '].indexOf(e.key) !== -1) {
      const fakeEventObject = createFakeEvent(
        !value,
        undefined,
        true
      ) as ChangeEvent<HTMLInputElement>
      onChange && onChange(fakeEventObject)
    }
  }

  React.useEffect(() => {
    if (selected) {
      window.addEventListener('keydown', enterHandler)
      return () => window.removeEventListener('keydown', enterHandler)
    } else {
      return () => {}
    }
  }, [selected])

  const hasTooltip = Object.keys(TooltipProps).length
  const componentProps: Record<string, any> = {}

  if (Component === Switch) {
    componentProps.size = sizeTokenValues.small
  }
  const mainContent = (
    <Component checked={value} disabled={!editable} onChange={onChange} {...componentProps} />
  )

  return (
    <div
      className={clsx(
        baseClasses.innerRoot,
        !editable && baseClasses.nonInteractive,
        densityClassName
      )}
    >
      {hasTooltip ? <Tooltip {...TooltipProps}>{mainContent}</Tooltip> : mainContent}
    </div>
  )
}

export default BodyCellInnerCheck
