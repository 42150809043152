import Breadcrumbs from '@root/components/Breadcrumbs'
import LoaderFrame from '@root/components/Loader'
import {ReportConfigurationRequest} from '@root/features/reportConfiguration/types'
import {useGetConfigurationByIdQuery} from '@root/redux/api/reportConfigurationsApi'
import {Typography} from '@x5-react-uikit/core'
import {useEffect, useRef, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useParams} from 'react-router-dom'
import DataStep from './components/DataStep/DataStep'
import ReviewStep from './components/ReviewStep/ReviewStep'
import StatisticsStep from './components/StatisticsStep/StatisticsStep'
import StepsSection from './components/StepsSection'
import {ConfUpdateContextProvider} from './context'

type FormValue = ReportConfigurationRequest | {loading: boolean}

const touchedSteps = [1, 2, 3]

const ReportConfUpdatePage = () => {
  const {configurationId} = useParams()
  const {data: configuration, isLoading: isConfigurationLoading} = useGetConfigurationByIdQuery({
    configurationId,
  })

  const [step, setStep] = useState(1)
  const [completedSteps, setCompletedSteps] = useState([1, 2, 3])

  const focusedFieldRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => step === 1 && focusedFieldRef.current?.scrollIntoView(), [step])

  const {
    reset: formReset,
    trigger: formTrigger,
    ...form
  } = useForm<FormValue>({defaultValues: {loading: true}})
  useEffect(() => {
    if (configuration) {
      formReset({...configuration, systemId: configuration.system.id})
    }
  }, [configuration, formReset])

  if (isConfigurationLoading || form.getValues('loading')) return <LoaderFrame />

  let currentStepComponent
  switch (Number(step)) {
    case 1:
      currentStepComponent = <DataStep form={form} />
      break
    case 2:
      currentStepComponent = <StatisticsStep form={form} />
      break
    case 3:
      currentStepComponent = <ReviewStep form={form} />
      break
    default:
      currentStepComponent = <DataStep form={form} />
  }

  return (
    <ConfUpdateContextProvider value={{setStep, focusedFieldRef}}>
      <div style={{margin: '0 16px 0 16px', width: '1200px'}}>
        <Breadcrumbs
          routes={[
            {to: '/', label: 'Главная'},
            {to: '/reports?tab=configurations', label: 'Отчеты портала'},
            {to: null, label: 'Редактирование конфигурации отчета'},
          ]}
        />
        <Typography variant="h2">Редактирование конфигурации отчета</Typography>
        <StepsSection
          completedSteps={completedSteps}
          currentStep={step}
          form={form}
          formTrigger={formTrigger}
          setCompletedSteps={setCompletedSteps}
          setCurrentStep={setStep}
          touchedSteps={touchedSteps}
        />
        {currentStepComponent}
        <StepsSection
          completedSteps={completedSteps}
          currentStep={step}
          form={form}
          formTrigger={formTrigger}
          setCompletedSteps={setCompletedSteps}
          setCurrentStep={setStep}
          touchedSteps={touchedSteps}
        />
      </div>
    </ConfUpdateContextProvider>
  )
}

export default ReportConfUpdatePage
