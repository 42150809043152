import {Box, Input} from '@x5-react-uikit/core'
import {Select, SelectList} from '@root/components/kit'
import useHandleOutsideClick from '@root/hooks/useHandleOutsideClick'
import {debounce} from 'lodash'
import {useCallback, useEffect, useRef, useState} from 'react'
import {useGetTemplatesQuery} from '@root/redux/api/templatesApi'
import {FilterItemType} from '@root/openapi'

const debouncedChange = debounce((event, cb) => cb(event), 1000)

const emptyRef = {}

const TemplatesDropdown = (props) => {
  const {
    setNameInputValue,
    editing,
    setEditing,
    setSelectedTemplate,
    dropdownInputRef: inputRef,
  } = props.dropdownProps

  const debouncedHandleChange = useCallback((event, cb) => debouncedChange(event, cb), [])

  const closeDropdown = () => {
    setEditing(false)
    setNameInputValue('')
  }

  const ref = useRef()
  useHandleOutsideClick(editing ? ref : emptyRef, closeDropdown)

  const handleSelect = (event, newValue, option) => {
    event.stopPropagation()
    setSelectedTemplate(option.template)
    closeDropdown(event)
  }

  return (
    <Box
      ref={ref}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        px: 'x6',
        py: 'x3',
        gap: '10px',
      }}
    >
      <Input
        width="100%"
        inputRef={inputRef}
        // label="Поиск"
        search
        size="small"
        // value={nameInputValue}
        onChange={(event) =>
          debouncedHandleChange(event, (event) => setNameInputValue(event.target.value))
        }
      />
      <SelectList items={props.options} onChange={handleSelect} />
    </Box>
  )
}

const SelectTemplateButton = ({setSelectedTemplate, systemId}) => {
  const dropdownRef = useRef()
  const dropdownInputRef = useRef()

  const [templatesFilter, setTemplatesFilter] = useState({
    filters: {},
    position: 1,
    pageSize: 9999,
  })
  const {currentData: templates = {items: []}} = useGetTemplatesQuery(
    {systemId, filter: templatesFilter},
    {skip: !systemId}
  )

  const [nameInputValue, setNameInputValue] = useState('')
  useEffect(() => {
    const nameFilter = {value: nameInputValue, type: FilterItemType.LIKE}
    setTemplatesFilter((prev) => ({...prev, filters: {...prev.filters, name: nameFilter}}))
  }, [nameInputValue])

  const [editing, setEditing] = useState(false)

  const options = (
    nameInputValue
      ? templates.items.filter((template) => template.name.startsWith(nameInputValue))
      : templates.items
  ).map((template) => ({name: template.name, value: template.id, template}))

  const handleClick = () => {
    // Объектный тип Boolean здесь используется для того, чтобы ререндер происходил
    // даже в случаях, когда булево значение задаётся равное прежнему состоянию

    setEditing(new Boolean(true))
    setTimeout(() => dropdownInputRef.current?.focus(), 100)
  }

  return (
    <Box height="fit-content" width="fit-content" onClick={handleClick}>
      <Select
        dropdownProps={{
          nameInputValue,
          setNameInputValue,
          setSelectedTemplate,
          setEditing,
          editing,
          dropdownRef,
          dropdownInputRef,
        }}
        dropdownRef={dropdownRef}
        isOpen={!!editing}
        label="Применить шаблон настроек"
        options={options}
        renderDropDownContent={TemplatesDropdown}
        systemId={systemId}
        width="273px"
      />
    </Box>
  )
}

export default SelectTemplateButton
