import {IconButton, Typography} from '@x5-react-uikit/core'
import {Add as AddIcon, Del as DelIcon} from '@x5-react-uikit/icons'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'
import {isEmpty} from 'lodash'
import {useFieldArray} from 'react-hook-form'
import {VariablesName} from '../../utils'
import {RequiredRule} from '@root/constants'

const VariableInputRow = ({
  form,
  insertVariable,
  removeVariable,
  varNumber,
  keyRegister,
  valueRegister,
}) => {
  const handleInsert = () => {
    const focusOptions = {
      shouldFocus: true,
      focusName: `${VariablesName}.${varNumber}.key`,
    }
    const emptyVar = {key: '', value: ''}
    insertVariable(varNumber, emptyVar, focusOptions)
  }
  const handleRemove = () => removeVariable(varNumber - 1)

  return (
    <FlexboxRow sx={{gap: '24px', alignItems: 'center'}}>
      <Typography style={{width: '22px'}}>{varNumber}.</Typography>
      <FormInputText
        // autoFocus
        required
        control={form.control}
        label="Название переменной"
        name={keyRegister}
        rules={RequiredRule}
        width="358px"
      />
      <FormInputText
        required
        control={form.control}
        label="Значение переменной"
        name={valueRegister}
        rules={RequiredRule}
        width="358px"
      />
      <FlexboxRow sx={{gap: '8px'}}>
        <IconButton
          IconComponent={<AddIcon />}
          size="small"
          variant="text"
          onClick={handleInsert}
        />
        <IconButton
          IconComponent={<DelIcon />}
          size="small"
          variant="text"
          onClick={handleRemove}
        />
      </FlexboxRow>
    </FlexboxRow>
  )
}

const VariableInputsList = ({form}) => {
  const {
    fields: variables,
    insert: insertVariable,
    remove: removeVariable,
  } = useFieldArray({control: form.control, name: VariablesName})

  if (isEmpty(variables))
    return (
      <VariableInputRow
        form={form}
        insertVariable={insertVariable}
        keyRegister={`${VariablesName}.0.key`}
        removeVariable={removeVariable}
        valueRegister={`${VariablesName}.0.value`}
        varNumber={1}
      />
    )
  return (
    <FlexboxColumn sx={{gap: '16px'}}>
      {Object.values(variables).map((variable, variableIndex) => (
        <VariableInputRow
          key={variable.id}
          form={form}
          insertVariable={insertVariable}
          keyRegister={`${VariablesName}.${variableIndex}.key`}
          removeVariable={removeVariable}
          valueRegister={`${VariablesName}.${variableIndex}.value`}
          varNumber={variableIndex + 1}
        />
      ))}
    </FlexboxColumn>
  )
}

export default VariableInputsList
