import {FC} from 'react'
import {Tooltip, Typography} from '@root/components/kit'
import LineLimiter from '@root/components/LineLimiter'

export const LineLimitedCell: FC<{value: string; lines: number}> = ({value, lines}) => (
  <Tooltip doNotClone usePortal content={value}>
    <Typography variant="p2">
      <LineLimiter lines={lines}>{value}</LineLimiter>
    </Typography>
  </Tooltip>
)
