import React, {FC} from 'react'
import {LogoProps} from '../types'
import {primary, getPathColor} from './helpers'

const Logo: FC<LogoProps> = ({color, ...props}) => (
  <svg fill="none" viewBox="0 0 236 100" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M43.2413 99.9199C25.1167 99.9199 8.75513 89.6905 0.700228 73.8951C-0.894059 70.7671 0.608599 66.9582 3.93036 65.8114C6.63273 64.8827 9.62987 66.0628 10.9209 68.6079C17.0189 80.6345 29.4603 88.4263 43.2413 88.4263C54.9188 88.4263 65.6359 82.8306 72.3441 73.8082C74.5102 70.8944 77.4878 66.2611 77.4878 58.8111L87.8313 69.6139C87.8313 69.6139 87.2865 71.1628 86.2427 73.1274C85.1975 75.0887 84.7585 75.8267 84.1228 76.8827C75.6108 90.9678 60.1974 99.9199 43.2413 99.9199Z"
      fill={getPathColor(color, primary)}
    />
    <path
      d="M58.6202 36.2277C54.9135 33.7699 49.9257 32.5245 43.7915 32.5245H43.1053V32.5061H37.1032V23.0862H56.4165C59.3092 23.0862 61.6699 20.8982 61.6699 18.099C61.6699 15.2975 59.2808 12.9624 56.4165 12.9624H31.8494C28.9457 12.9624 26.5935 15.3128 26.5935 18.211V37.4298C26.5935 40.3298 28.9457 42.6784 31.8494 42.6784H44.0067C52.1465 42.6784 54.7416 46.405 54.7416 50.2507C54.7416 54.0355 52.6941 60.1071 44.1509 60.1071C37.0865 60.1071 32.8669 55.3326 31.9972 54.555C29.3857 52.2146 26.7483 52.3791 24.9995 53.9181C22.7717 55.8826 23.0469 58.6419 24.3933 60.6805C25.995 63.1082 32.2447 70.6387 44.1509 70.6387C50.9127 70.6387 56.8874 68.2178 60.9692 63.822C64.595 59.9163 66.4521 54.7227 66.0614 49.572C65.6232 43.778 63.0497 39.1646 58.6202 36.2277Z"
      fill={getPathColor(color)}
    />
    <path
      d="M193.601 32.118C191.009 31.1538 188.635 30.5102 186.725 29.8882C184.512 29.1676 182.94 28.5371 182.782 28.4765C180.163 27.4744 178.121 25.3049 179.253 23.052C180.317 20.9212 184.501 19.9322 192.702 23.5499C195.106 24.6091 197.503 23.6875 198.326 22.708C200.109 20.5875 199.471 16.8541 196.069 15.0236C193.692 13.741 189.639 11.7428 181.345 12.5141C174.8 13.1226 169.786 17.4896 168.773 23.29C167.816 28.7786 170.633 32.7233 174.743 35.0868C177.604 36.7332 183.642 38.5017 185.885 39.1169C188.863 39.9332 191.261 40.6577 191.6 42.6946C191.788 43.8109 191.217 45.1655 189.837 46.2534C187.47 48.1208 182.486 48.4158 176.241 44.5502C175.038 43.8059 172.211 41.5878 169.427 44.0123C167.302 45.8612 167.819 49.157 170.549 51.6254C173.007 53.8517 178.47 56.1953 184.206 56.4736C190.008 56.7588 194.334 54.8343 196.526 53.218C199.491 51.0329 201.476 47.3346 201.795 43.9818C202.399 37.6634 198.383 33.8932 193.601 32.118Z"
      fill={getPathColor(color)}
    />
    <path
      d="M233.306 46.0329C232.094 45.5837 230.519 45.8517 229.464 46.0397C228.048 46.2893 226.002 46.4755 224.196 45.9624C221.046 45.0691 219.713 43.6056 219.713 40.8429V22.3454H227.191C230.039 22.3454 232.346 20.1457 232.346 17.4298C232.346 14.7142 230.039 12.5131 227.191 12.5131H219.713V5.45033C219.713 2.48295 217.301 0.0758514 214.327 0.0758514C211.355 0.0758514 208.944 2.48295 208.944 5.45033V12.5131H206.506C203.659 12.5131 201.352 14.7142 201.352 17.4298C201.352 20.1457 203.659 22.3454 206.506 22.3454H208.944V40.8429C208.944 49.5333 215.885 56.6035 224.73 56.6035C233.579 56.6035 235.922 53.4386 235.922 50.4688C235.922 48.3329 234.992 46.6549 233.306 46.0329Z"
      fill={getPathColor(color)}
    />
    <path
      d="M145.06 12.5135V12.5166V12.5135C133.2 12.5135 123.552 22.3741 123.552 34.4943C123.552 46.6149 133.2 56.4766 145.06 56.4766V56.473V56.4766C156.922 56.4766 166.57 46.6149 166.57 34.4943C166.57 22.3741 156.922 12.5135 145.06 12.5135ZM145.06 46.5645V46.5698V46.5645C138.935 46.5645 133.949 42.2209 133.949 34.4943C133.949 26.7695 138.935 22.3723 145.06 22.3723V22.3691V22.3723C151.19 22.3723 156.174 26.7695 156.174 34.4943C156.174 42.2209 151.19 46.5645 145.06 46.5645Z"
      fill={getPathColor(color)}
    />
    <path
      d="M99.9988 12.5141C95.7821 12.5141 90.5226 14.9567 87.8486 18.7283C87.6977 15.089 85.7476 12.6464 82.8942 12.6464C79.9198 12.6464 77.509 15.0539 77.509 18.0212V67.4712C77.509 70.4383 79.9198 72.8457 82.8942 72.8457C85.8669 72.8457 88.277 70.4383 88.277 67.4712V51.2425C90.3159 53.8989 95.3935 56.4907 100.45 56.4907C112.306 56.4907 120.833 46.6156 120.833 34.495C120.833 22.3748 112.236 12.5141 99.9988 12.5141ZM99.3272 46.5652C92.434 46.5652 87.6636 41.7556 87.6636 34.495C87.6636 26.5489 93.4867 22.373 99.3272 22.373C105.453 22.373 110.436 28.0978 110.436 34.495C110.436 40.8907 105.453 46.5652 99.3272 46.5652Z"
      fill={getPathColor(color)}
    />
  </svg>
)

export default Logo
