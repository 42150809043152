import React, {forwardRef, MouseEvent, useCallback} from 'react'

import {StyledCheckbox, checkboxClasses} from './styles'
import {getQaAttribute} from '../utils'
import {CheckboxProps} from './types'

const Done = () => (
  <svg height="10" viewBox="0 0 12 10" width="12">
    <path
      d="M11.3564 2.1907C11.7182 1.80655 11.7193 1.20732 11.3588 0.821901L11.3177 0.778024C10.9235 0.356526 10.2553 0.355348 9.85952 0.775453L7.295 3.49788L4 6.99575L2.14542 5.03461C1.75131 4.61786 1.08814 4.61733 0.693367 5.03346L0.651439 5.07765C0.286014 5.46285 0.285326 6.06647 0.649872 6.45249L3.27205 9.22916C3.66701 9.6474 4.33255 9.64698 4.72699 9.22825L11.3564 2.1907Z"
      fill="white"
    />
  </svg>
)

const Remove = () => (
  <svg height="2" viewBox="0 0 10 2" width="10">
    <rect fill="white" height="2" rx="1" width="10" />
  </svg>
)

export const Checkbox = forwardRef<HTMLLabelElement, CheckboxProps>(
  ({checked, error, onChange, disabled, label, className, qa = 'checkbox', ...props}, ref) => {
    const getQA = getQaAttribute(qa)

    const handleLabelClick = useCallback((e: MouseEvent) => e.stopPropagation(), [])

    return (
      <StyledCheckbox
        ref={ref}
        checked={checked}
        className={className}
        data-qa={getQA()}
        disabled={disabled}
        error={error}
        onClick={handleLabelClick}
      >
        <div className={checkboxClasses.root}>
          {checked === 'halfOn' ? <Remove /> : checked ? <Done /> : null}
        </div>
        <input
          {...props}
          checked={checked === 'halfOn' ? false : checked}
          className={checkboxClasses.input}
          data-qa={getQA('field', [checked && 'checked', disabled && 'disabled'])}
          disabled={disabled}
          type="checkbox"
          onChange={onChange}
        />
        {label ? <div className={checkboxClasses.label}>{label}</div> : null}
      </StyledCheckbox>
    )
  }
)

export default Checkbox
