import {saveFile} from '@root/utils'
import {BACKEND_BASE_URL} from '@root/constants'
import {keycloakClient} from './useKeycloak'
import useNotify from './useNotify'
import {useCallback, useRef, useState} from 'react'

type DownloadParams = {
  path: string
  queryParams?: [string, string][] | Record<string, string>
  body?: any
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
  defaultName?: string
}

const useDownloadFile = ({
  path,
  body,
  method = 'POST',
  defaultName = 'file.xlsx',
  queryParams,
}: DownloadParams) => {
  const {notifyError} = useNotify()
  const [loading, setLoading] = useState(false)
  const controllerRef = useRef<AbortController>()

  const download = useCallback(() => {
    setLoading(true)
    if (controllerRef.current) {
      controllerRef.current.abort()
    }
    controllerRef.current = new AbortController()

    const url = new URL(`${BACKEND_BASE_URL}${path}`)

    if (queryParams != null) {
      url.search = new URLSearchParams(queryParams).toString()
    }

    const options: RequestInit = {
      method: method,
      headers: {
        Authorization: `Bearer ${keycloakClient.token}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      signal: controllerRef.current.signal,
    }
    if (body) options.body = JSON.stringify(body)
    fetch(url, options)
      .then(async (res) => {
        const data = await res.blob()
        return {
          data: data,
          filename: res.headers.get('Content-Disposition') || defaultName,
        }
      })
      .then(({data, filename}) => saveFile(data, filename))
      .catch((err) => {
        console.error(err)
        notifyError()
      })
      .finally(() => {
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
      })
  }, [body, defaultName, method, notifyError, path, queryParams])

  return {
    download,
    loading,
  }
}

export default useDownloadFile
