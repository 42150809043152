import {createSvgIcon} from '@x5-react-uikit/icons'

const Svg = () => (
  <>
    <path
      clipRule="evenodd"
      d="M13.3334 7.33333C13.3334 6.96514 13.0349 6.66667 12.6667 6.66667C12.2986 6.66667 12.0001 6.96514 12.0001 7.33333V11.7239L11.1382 10.8619C10.8778 10.6016 10.4557 10.6016 10.1953 10.8619C9.93499 11.1223 9.93499 11.5444 10.1953 11.8047L12.1953 13.8047C12.4557 14.0651 12.8778 14.0651 13.1382 13.8047L15.1382 11.8047C15.3985 11.5444 15.3985 11.1223 15.1382 10.8619C14.8778 10.6016 14.4557 10.6016 14.1953 10.8619L13.3334 11.7239V7.33333Z"
      fillRule="evenodd"
    />
    <path d="M1.33341 12H4.00008C4.36675 12 4.66675 11.7 4.66675 11.3333C4.66675 10.9667 4.36675 10.6667 4.00008 10.6667H1.33341C0.966748 10.6667 0.666748 10.9667 0.666748 11.3333C0.666748 11.7 0.966748 12 1.33341 12Z" />
    <path d="M0.666748 4.66667C0.666748 5.03333 0.966748 5.33333 1.33341 5.33333H10.6667C11.0334 5.33333 11.3334 5.03333 11.3334 4.66667C11.3334 4.3 11.0334 4 10.6667 4H1.33341C0.966748 4 0.666748 4.3 0.666748 4.66667Z" />
    <path d="M7.33342 8.66667C7.70008 8.66667 8.00008 8.36667 8.00008 8C8.00008 7.63333 7.70008 7.33333 7.33342 7.33333H1.33341C0.966748 7.33333 0.666748 7.63333 0.666748 8C0.666748 8.36667 0.966748 8.66667 1.33341 8.66667H7.33342Z" />
  </>
)

export const SortAscIcon = createSvgIcon({'16': <Svg />, '24': <Svg />}, 'SortAscIcon')
