import React, {FC} from 'react'
import {Checkbox} from '../Checkbox'
import {ListItem} from '../ListItem'
import {CheckedListItemProps} from './types'
import {useStyles} from './styles'

export const ListCheckedItem: FC<CheckedListItemProps> = (props) => {
  const classes = useStyles()

  const {checked, adornment, selected, ...otherProps} = props

  const customAdornment = (
    <Checkbox
      checked={checked}
      onChange={() => null}
      onClick={(event) => event.stopPropagation()}
    />
  )

  return (
    <ListItem
      {...otherProps}
      adornment={customAdornment}
      classes={classes}
      selected={checked === true}
    />
  )
}

export default ListCheckedItem
