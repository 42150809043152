import {useSaveChangesBeforeTeardown} from '@root/hooks/useSaveChangesBeforeTeardown'
import {useCallback} from 'react'
import {getUpdatedFieldIds} from '../utils/formUtils'
import useUpdateFormData from './useUpdateFormData'

type Params = {
  updated: boolean
  stepId: number
  currentStepMeta: {[key: string]: any}
  form: any
  shouldCheckCompleted?: boolean
}

export const useSaveDraftBeforeTeardown = ({
  updated,
  currentStepMeta,
  form,
  stepId,
  shouldCheckCompleted = true,
}: Params) => {
  form.watch()

  const {updateFormData} = useUpdateFormData()
  const saveAction = useCallback(async () => {
    if (updated) {
      return
    }

    const steps = Object.values(form.getValues())
    const updatedFields = getUpdatedFieldIds(
      form.formState.dirtyFields[Number(stepId) - 1],
      currentStepMeta
    )
    if (updatedFields?.length > 0) {
      const updateArgs = {
        steps,
        updatedFields,
        shouldNotify: false,
        currentStepId: stepId
      } as any
      if (shouldCheckCompleted) updateArgs.currentStepMeta = currentStepMeta
      updateFormData(updateArgs)
    }
    // eslint-disable-next-line
  }, [updated, currentStepMeta])

  useSaveChangesBeforeTeardown({shouldBlock: form.formState.isDirty, saveAction})
}
