import {makeUiStyles} from '../theme'

export const usePrivateDropdownStyles = makeUiStyles(
  (theme) => ({
    root: {
      boxShadow: theme.shadows.medium,
      borderRadius: theme.spaces.x4,
      paddingTop: theme.spaces.x4,
      paddingBottom: theme.spaces.x4,
      backgroundColor: theme.colors.white,
      '-webkit-backface-visibility': 'hidden', // фиксит блюр от transform
    },
  }),
  'dropdown'
)

export default usePrivateDropdownStyles
