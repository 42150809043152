import {useSnackbar} from '@root/components/kit'
import {useCallback} from 'react'

const useNotify = () => {
  const {open, close} = useSnackbar()

  const notifySuccess = useCallback((title, message, action) => {
    console.log(message)
    open({
      type: 'success',
      placement: 'right',
      message,
      title,
      closable: true,
      icon: true,
      action,
      // maxWidth: '500px'
    })
    // eslint-disable-next-line
  }, [])

  const notifyError = useCallback(
    (message = 'Что-то пошло не так.', title = 'Ошибка', hideDuration) => {
      console.log(message)
      open({
        type: 'error',
        placement: 'right',
        message,
        title,
        closable: true,
        icon: true,
        hideDuration,
      })
    },
    // eslint-disable-next-line
    []
  )

  const notifyWarning = useCallback((message) => {
    console.log(message)
    open({
      type: 'warning',
      placement: 'right',
      message,
      title: 'Внимание',
      closable: true,
      icon: true,
    })
    // eslint-disable-next-line
  }, [])

  const notifyInfo = useCallback((title, message) => {
    console.log(message)
    open({
      type: 'default',
      placement: 'right',
      message,
      title,
      closable: true,
      icon: true,
    })
    // eslint-disable-next-line
  }, [])

  // eslint-disable-next-line
  const closeSnackbar = useCallback(close, [])

  return {
    notifySuccess,
    notifyError,
    notifyWarning,
    notifyInfo,
    closeSnackbar,
  }
}

export default useNotify
