import {makeUiStyles, BaseThemeValues} from '../theme'

export const useStyles = makeUiStyles((theme: BaseThemeValues) => {
  const baseChildsMargin = 16 + theme.spaces.x4 // 16 (arrow size) + x4 (arrow margin)

  return {
    childsMargin: {
      marginLeft: baseChildsMargin,
    },
    childsBigMargin: {
      marginLeft: baseChildsMargin * 2, // 16 (arrow size) + x4 (arrow margin) + 16 (checkbox size) + x4 (checkbox margin)
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      padding: `${theme.spaces.x3}px ${theme.spaces.x6}px`,
    },
    arrow: {
      marginRight: theme.spaces.x4,
      transition: 'all 0.3s',
      color: theme.colors.grey[60],
      cursor: 'pointer',
    },
    arrowRotate: {
      transform: 'rotate(90deg)',
    },
    highlight: {
      display: 'inline',
      color: theme.colors.accent[90],
    },
    label: {
      padding: theme.spaces.join('x6', 'x6', 'x2'),
      color: theme.colors.grey['70'],
      ...theme.typography.h6,
    },
  }
}, 'tree')

export default useStyles
