import React, {FC} from 'react'
import {defineColors} from '../utils/defineColors'
import {LogoProps} from '../types'

const primary = '#5faf2d'
const getPathColor = defineColors('#000000')

const Logo: FC<LogoProps> = ({color, ...props}) => (
  <svg fill="none" viewBox="0 0 338 100" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M150.827 39.7598H165.058C183.77 39.7598 190.49 50.172 190.49 62.1664C190.49 75.347 182.452 85.8912 164.4 85.8912H150.828V39.7598H150.827ZM156.822 45.3618V80.2891H164.794C178.433 80.2891 184.231 72.6453 184.231 62.1664C184.231 52.5445 179.091 45.3618 164.926 45.3618H156.822Z"
      fill={getPathColor(color)}
    />
    <path
      d="M200.176 38.4414C202.152 38.4414 203.602 39.8916 203.602 41.7362C203.602 43.5808 202.152 45.031 200.176 45.031C198.2 45.031 196.75 43.5808 196.75 41.7362C196.75 39.8916 198.2 38.4414 200.176 38.4414ZM197.408 51.622H202.942V85.891H197.408V51.622Z"
      fill={getPathColor(color)}
    />
    <path
      d="M210.586 60.3872C212.761 54.3908 218.228 51.0293 225.608 51.0293C234.898 51.0293 239.575 55.9062 239.575 65.6586V85.8899H234.041V79.9587C232.262 83.6494 226.992 86.4831 221.457 86.4831C214.341 86.4831 209.532 82.3979 209.532 76.268C209.532 68.4255 216.318 65.4599 234.041 65.4599C234.041 59.1343 231.34 56.2339 225.476 56.2339C220.336 56.2339 216.977 58.2772 215.592 62.2304L210.586 60.3872ZM234.041 71.2606V70.0077C219.942 70.0077 215.33 71.5898 215.33 76.268C215.33 79.3656 218.097 81.2769 222.577 81.2769C229.297 81.2769 234.041 77.1251 234.041 71.2606Z"
      fill={getPathColor(color)}
    />
    <path d="M248.669 37.7832H254.203V85.8912H248.669V37.7832Z" fill={getPathColor(color)} />
    <path
      d="M279.766 51.0293C290.44 51.0293 297.951 58.4759 297.951 68.6909C297.951 79.0379 290.507 86.4845 279.701 86.4845C269.159 86.4845 261.582 79.0379 261.582 68.6909C261.581 58.4759 268.961 51.0293 279.766 51.0293ZM279.699 81.2769C287.078 81.2769 292.152 76.136 292.152 68.6894C292.152 61.3095 286.815 56.2339 279.699 56.2339C272.584 56.2339 267.379 61.2428 267.379 68.6894C267.379 76.0708 272.453 81.2769 279.699 81.2769Z"
      fill={getPathColor(color)}
    />
    <path
      d="M302.826 68.8229C302.826 58.3439 310.6 51.0293 320.154 51.0293C326.478 51.0293 330.3 53.2031 332.343 56.6313V51.6224H337.877V83.255C337.877 94.8534 331.289 99.6636 322.591 99.6636C315.015 99.6636 309.217 96.4356 307.306 91.36L312.247 89.1847C313.565 91.8863 316.727 94.456 322.261 94.456C328.915 94.456 332.341 90.6334 332.341 84.241V79.5628C330.035 83.7799 326.28 86.4815 319.363 86.4815C310.073 86.483 302.826 79.8282 302.826 68.8229ZM332.343 69.0853V67.8324C332.343 60.5177 327.467 56.2339 320.812 56.2339C314.224 56.2339 308.624 61.1761 308.624 68.6894C308.624 76.532 313.565 81.2769 320.483 81.2769C327.731 81.2769 332.343 76.7307 332.343 69.0853Z"
      fill={getPathColor(color)}
    />
    <path
      d="M81.2653 85.8902L71.7108 70.0064L62.3551 85.8902H51.1536L65.5438 61.9667L50.8764 39.7559H62.5789L71.9747 54.5853L80.9362 39.7559H92.1376L77.7074 62.6903L92.5334 85.8887H81.2653V85.8902Z"
      fill={getPathColor(color)}
    />
    <path
      d="M128.943 70.208C128.943 80.3578 121.299 86.6849 111.284 86.6849C102.454 86.6849 95.0225 81.808 94.2975 71.1971H103.852C104.51 75.8101 108.2 77.7881 112.22 77.7881C117.228 77.7881 119.39 74.4266 119.39 70.0776C119.39 65.5298 116.029 62.6962 111.352 62.6962C107.465 62.6962 104.894 64.5423 103.708 66.5188H94.5762L97.7324 39.7617H127.768L126.766 48.7252H105.28L104.426 57.579C104.426 57.579 106.682 54.9204 113.522 54.9204C121.762 54.9189 128.943 60.4512 128.943 70.208Z"
      fill={getPathColor(color)}
    />
    <path
      d="M95.4272 34.0117C90.3364 14.1674 74.191 0.224609 40.6816 0.224609H0.225342L5.71784 37.3687C9.84945 70.8845 22.6653 81.6674 45.3439 85.6383C45.4491 85.6546 46.1681 85.757 46.177 84.9162C46.18 84.6137 45.9888 84.3527 45.7264 84.24C30.3963 77.7839 28.647 56.6125 25.3722 35.3684L22.8387 18.4378H45.9339C67.2264 18.4378 83.6654 18.1012 94.0737 34.5425C94.2279 34.7738 94.4695 34.9444 94.7556 34.9444C95.1574 34.9444 95.4568 34.6181 95.4568 34.2163C95.4568 34.1437 95.448 34.074 95.4272 34.0117Z"
      fill={getPathColor(color, primary)}
    />
  </svg>
)

export default Logo
