import useNotify from '@root/hooks/useNotify'
import {getFormIndiciesById} from '@root/pages/tasks/CreateTaskPage/utils/formUtils'
import {
  GetReceiveOwnersResponse,
  VerifyBudgetResponse,
  VerifyCppResponse,
  resetBudgetState,
  useGetBudgetOwnersQuery,
  useLazyVerifyBudgetQuery,
  useVerifyCppQuery,
} from '@root/redux/api/budgetApi'
import {useGetTaskFormDataQuery} from '@root/redux/api/formsApi'
import {useGetTaskByIdQuery} from '@root/redux/api/tasksApi'
import {useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'

export type FinportalData = {
  cpp: string
  cppVerifyResult: VerifyCppResponse | null
  isCppVerifying: boolean
  workCost: number
  isBudgetVerifying: boolean
  budgetOwners: GetReceiveOwnersResponse['receiveOwners'] | null
  budgetVerify: () => Promise<VerifyBudgetResponse>
  budgetResetVerify: () => void
  budgetVerifyResult: VerifyBudgetResponse | null
}

const useFinportalData = (): FinportalData => {
  const {notifyWarning} = useNotify()
  const {taskId} = useParams()
  const {currentData: task} = useGetTaskByIdQuery(taskId)
  const {currentData: taskFormData} = useGetTaskFormDataQuery({taskId})
  const finportalParams = useMemo(() => {
    if (!taskFormData?.length || !task) return
    const {stepIndex, fieldIndex} = getFormIndiciesById(taskFormData, 'budget_source')
    return {
      cpp: taskFormData[stepIndex].fields[fieldIndex].values.text[0].value,
      workCost: task.workCost,
    }
  }, [task, taskFormData])

  const {currentData: cppVerifyResult = null, isFetching: isCppVerifying} = useVerifyCppQuery(
    {cpp: finportalParams?.cpp},
    {skip: !finportalParams}
  )

  const {currentData: budgetOwnersData} = useGetBudgetOwnersQuery(
    {cpp: finportalParams?.cpp},
    {skip: !cppVerifyResult?.checkBudget}
  )

  const dispatch = useDispatch()

  const [budgetVerify, {isFetching: isBudgetVerifying, currentData: budgetVerifyResult}] =
    useLazyVerifyBudgetQuery()

  const finportalData = useMemo(() => {
    return {
      ...finportalParams,
      cppVerifyResult,
      isCppVerifying,
      isBudgetVerifying,
      budgetOwners: budgetOwnersData?.receiveOwners,
      budgetVerifyResult,
      budgetVerify: () => budgetVerify(finportalParams).unwrap(),
      // .then((result) => !result && notifyWarning('Данные по СПП не получены'))
      // .catch(() => notifyWarning('Данные по СПП не получены')),
      budgetResetVerify: () => dispatch(resetBudgetState),
    }
  }, [
    finportalParams,
    cppVerifyResult,
    isCppVerifying,
    budgetVerify,
    isBudgetVerifying,
    budgetOwnersData,
    budgetVerifyResult,
    notifyWarning,
    dispatch,
  ])

  return finportalData
}

export default useFinportalData
