import {PREFIX, restyled, doSelectors} from '../styles'
import {chipVariants, chipSizes} from './Chip'
import {ChipProps} from './types'

export const classes = {
  label: `${PREFIX}-chip-label`,
  icon: `${PREFIX}-chip-icon`,
} as const

const selectors = doSelectors(classes)

export const StyledChip = restyled.div<
  Partial<ChipProps & {removable: boolean; selectable: boolean}>
>(
  ({
    theme: {colors, spaces, typography},
    size,
    variant,
    checked,
    removable,
    selectable,
    error,
    icon,
  }) => {
    const getPaddingStyles = () => {
      return {
        padding: size === chipSizes.large ? spaces.join('x4', 'x6') : spaces.join('x1', 'x4'),
        paddingLeft: icon ? spaces[size === chipSizes.large ? 'x4' : 'x2'] : null,
        paddingRight: removable ? spaces[size === chipSizes.large ? 'x2' : 'x1'] : null,
      }
    }

    return {
      boxSizing: 'border-box',
      display: 'inline-flex',
      alignItems: 'center',
      height: size === chipSizes.large ? 32 : 20,
      marginRight: spaces.x4,
      marginBottom: spaces.x4,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor:
        variant === chipVariants.outlined ? colors[checked ? 'accent' : 'grey'][30] : 'transparent',
      backgroundColor: colors[checked ? 'accent' : 'grey'][20],
      color: checked ? colors.grey[90] : error ? colors.error : colors.grey[100],
      borderRadius: spaces.x2,
      userSelect: selectable ? 'none' : null,
      cursor: selectable ? 'pointer' : null,
      ...getPaddingStyles(),
      ...typography[size === chipSizes.large ? 'p2' : 'p3'],
      ':hover': {
        backgroundColor: !checked ? colors.grey[30] : null,
      },
      ...(variant === chipVariants.outlined
        ? {
            backgroundColor: checked ? colors.accent[20] : 'transparent',
            ':hover': {
              backgroundColor: !checked ? colors.grey[20] : null,
            },
          }
        : null),
      [selectors.label]: {
        marginRight: removable ? spaces.x2 : null,
        marginLeft: icon ? spaces.x2 : null,
      },
      [selectors.icon]: {
        display: 'inline-flex',
        alignItems: 'center',
        color: error ? colors.error : colors.grey[60],
      },
    }
  }
)
