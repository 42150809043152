import React from 'react'
import {useSnackbar as useNotistack} from 'notistack'

import {SnackbarContent} from './SnackbarContent'
import {useSnackbarStyles} from './styles'
import {SnackbarProps, SnackbarHook} from './types'

export function useSnackbar(): SnackbarHook {
  const classes = useSnackbarStyles()
  const {enqueueSnackbar, closeSnackbar} = useNotistack()

  return {
    open: (props: SnackbarProps) => {
      const key = `${Date.now()}${Math.random()}`
      const isLongMessage = props.closable || props.action

      enqueueSnackbar(<SnackbarContent {...props} close={() => closeSnackbar(key)} />, {
        key,
        className: classes.root,
        autoHideDuration: props.hideDuration || (isLongMessage ? 7000 : 3000),
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: props.placement || 'left',
        },
      })

      return key
    },
    close: closeSnackbar,
  }
}
