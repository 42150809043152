import React, {FC} from 'react'
import {defineColors} from '../utils/defineColors'
import {logoColors, LogoProps} from '../types'

const getPathColor = defineColors('#222429')

const Logo: FC<LogoProps> = ({color, ...props}) => {
  if (color === logoColors.multi) {
    return (
      <svg fill="none" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
          d="M49.8082 0C22.3425 0 0 22.3425 0 49.8082C0 77.274 22.3425 99.6164 49.8082 99.6164C77.274 99.6164 99.6027 77.2603 99.6027 49.8082C99.6027 22.3562 77.2603 0 49.8082 0Z"
          fill="white"
        />
        <path
          d="M74.9315 14.082C71.1233 23.8355 62.4795 25.8629 49.9452 25.8629C48.6027 25.8629 47.274 25.8492 45.9863 25.8218C44.7397 25.8081 43.5616 25.7807 42.411 25.7807C41.3836 25.7807 40.3562 25.7944 39.3425 25.8355C41.0959 21.5204 43.9041 18.5889 47.8904 16.8218C53.4521 14.3423 60.6849 14.3286 67.0548 14.3012C69.8356 14.2875 72.4932 14.2875 74.9315 14.082Z"
          fill="#00923A"
        />
        <path
          d="M78.8356 17.2052C73.2192 29.4518 60.6849 30.6847 49.9452 30.6847C47.589 30.6847 45.3288 30.6299 43.2329 30.6162L40.5206 41.1915C54.3836 29.9038 72.7123 41.0956 70.7534 58.1367C69.4384 69.6162 61.2466 77.123 52.5891 79.2737C43.1507 81.6299 32.7123 78.1093 28.0274 68.4929C27.5343 67.4792 26.6712 65.4381 26.3288 64.3559L36.274 61.8079C36.4521 62.3833 36.7397 62.9312 37.0548 63.4381C43.7123 74.1778 57.2055 68.8079 58.726 57.671C60.274 46.3422 44.3699 40.3011 37.1233 53.5477L28.3014 49.7942L28.1507 49.7258L32.411 32.6436C32.6712 31.4244 33.5617 28.0408 33.7123 27.4792C38.5206 9.80795 54.0822 9.52028 66.5616 9.47918C61.3973 7.3285 55.7397 6.13672 49.8082 6.13672C25.6986 6.13672 6.1507 25.6847 6.1507 49.7942C6.1507 73.9038 25.6986 93.4518 49.8082 93.4518C73.9178 93.4518 93.4658 73.9038 93.4658 49.7942C93.4658 36.8353 87.8082 25.2052 78.8356 17.2052Z"
          fill="#E52322"
        />
      </svg>
    )
  }

  return (
    <svg fill="none" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M50 0C22.4283 0 0 22.4283 0 50C0 77.5717 22.4283 100 50 100C77.5717 100 100 77.5717 100 50C100 22.4283 77.5717 0 50 0ZM69.1681 12.9567C70.8263 12.9567 72.2035 12.9005 73.412 12.7881C71.6976 18.072 68.7465 21.3884 64.1653 23.1872C59.3873 25.0422 53.5694 25.0984 47.9764 25.1546C44.9691 25.1827 42.0742 25.2108 39.4042 25.52C44.1821 13.9966 52.7262 12.8724 61.8606 12.8724C63.1253 12.8724 64.3901 12.9005 65.6267 12.9286C66.8353 12.9286 68.0157 12.9567 69.1681 12.9567ZM50 93.873C25.7729 93.873 6.12704 74.2271 6.12704 50C6.12704 25.7729 25.7729 6.12704 50 6.12704C54.5531 6.12704 58.9376 6.82968 63.041 8.09443C62.6476 8.09443 62.2541 8.09443 61.8325 8.09443C51.7144 8.09443 40.3316 9.61214 34.57 24.7611C34.2608 25.6043 34.1765 25.9134 33.8954 26.8409C33.6987 27.4311 32.1248 33.6706 31.5908 36.0596L28.2462 49.4941L28.3867 49.5503L37.3805 53.3727C44.7723 39.882 60.9612 46.0371 59.3873 57.5885C57.8415 68.9432 44.0978 74.3957 37.3243 63.4626C37.0152 62.9567 36.706 62.3946 36.5374 61.8044L26.3912 64.4182C26.7285 65.5424 27.5998 67.5942 28.1057 68.6341C32.8836 78.4148 43.5076 82.0124 53.1197 79.6234C61.9449 77.4311 70.2642 69.7864 71.6133 58.0944C73.6088 40.7251 54.9466 29.3423 40.8376 40.8375L43.62 30.0169C55.059 29.6234 71.276 31.6751 77.3749 15.7954C87.4086 23.8336 93.8448 36.172 93.8448 50.0562C93.873 74.2552 74.2271 93.873 50 93.873Z"
        fill={getPathColor(color)}
      />
    </svg>
  )
}

export default Logo
