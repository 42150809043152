import {Loader, Modal} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FormInputDatetime from '@root/components/inputs/formInputs/FormInputDatetime'
import FormInputSelect from '@root/components/inputs/formInputs/FormInputSelect'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'
import useNotify from '@root/hooks/useNotify'
import {SystemSubstatusesMap} from '@root/features/systems/constants'
import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useParams} from 'react-router-dom'
import {useGetSystemByIdQuery} from '@root/redux/api/systemsApi'
import {useGetTaskByIdQuery, useUpdateTaskStatusMutation} from '@root/redux/api/tasksApi'
import SubmitChangeStatus from './SubmitChangeStatus'
import {MODAL_INPUT_WIDTH_PX, TaskStatuses, getTaskStatusDisplay} from '@root/constants'

const CompletedStatuses = [
  {
    name: getTaskStatusDisplay(TaskStatuses.COMPLETED_SUCCESSFULLY),
    value: TaskStatuses.COMPLETED_SUCCESSFULLY,
  },
  {
    name: getTaskStatusDisplay(TaskStatuses.SYSTEM_SENT_FOR_REVISION),
    value: TaskStatuses.SYSTEM_SENT_FOR_REVISION,
  },
]

const RevisionSubstatuses = Object.entries(SystemSubstatusesMap)
  .filter(([substatus, {label: substatusLabel}]) => !!substatusLabel)
  .map(([substatus, {label: substatusLabel}]) => ({
    name: substatusLabel,
    value: substatus,
  }))

const RequiredRule = {required: 'Поле не может быть пустым'}

const ChangeToCompletedModal = ({open, onClose, onOpen}) => {
  const {taskId} = useParams()
  const {notifySuccess, notifyError} = useNotify()

  const {data: task} = useGetTaskByIdQuery(taskId)

  const {
    reset: formReset,
    getValues: formGetValues,
    ...form
  } = useForm({defaultValues: {loading: true}})

  const {data: system} = useGetSystemByIdQuery(task?.system.id, {skip: !task})

  useEffect(() => {
    const formValue = formGetValues()
    if (system)
      formReset({
        status: formValue.status || TaskStatuses.COMPLETED_SUCCESSFULLY,
        substatus: system.substatus,
        comment: system.comment,
        expirationDate: system.expirationDate,
      })
  }, [system, formReset, formGetValues])
  const formValue = form.watch()

  const statusIsRevision = formValue.status === TaskStatuses.SYSTEM_SENT_FOR_REVISION

  const [approveModalShown, setApproveModalShown] = useState(false)
  const showApproveModal = form.handleSubmit(() => {
    setApproveModalShown(true)
    onClose()
  })
  const closeApproveModal = () => setApproveModalShown(false)
  const cancelApproveModal = () => {
    closeApproveModal()
    onOpen()
  }

  const [updateTaskStatus] = useUpdateTaskStatusMutation()
  const handleSubmit = () => {
    const updateData = {
      nextTaskStatus: formValue.status,
      systemStatusExpirationDate: formValue.expirationDate,
      systemStatusComment: formValue.comment,
    }
    if (statusIsRevision) updateData.systemSubstatus = formValue.substatus
    updateTaskStatus({taskId, updateData})
      .unwrap()
      .then(() => {
        notifySuccess(
          'Испытания завершены',
          `Статус заявки изменен на “${getTaskStatusDisplay(formValue.status)}”.`
        )
        onClose()
        closeApproveModal()
      })
      .catch((error) => {
        console.log(error)
        notifyError()
      })
  }

  if (formValue.loading) return <Loader />

  return (
    <>
      <Modal
        closeOnOverlay
        buttons={[
          {
            onClick: onClose,
            side: 'right',
            text: 'Отмена',
          },
          {
            onClick: showApproveModal,
            side: 'right',
            text: 'Завершить',
          },
        ]}
        description="Это завершающий этап заявки. Нужно выбрать ее статус."
        isOpen={open}
        size="medium"
        title="Завершение испытаний"
        onClose={onClose}
      >
        <FlexboxColumn sx={{gap: '24px'}}>
          <FormInputSelect
            required
            control={form.control}
            label="Завершающий статус"
            name="status"
            options={CompletedStatuses}
            rules={RequiredRule}
            width={MODAL_INPUT_WIDTH_PX}
          />
          {statusIsRevision && (
            <FormInputSelect
              required
              caption="Необходим, чтобы обратить внимание к комментарию."
              control={form.control}
              label="Предупреждающий подстатус"
              name="substatus"
              options={RevisionSubstatuses}
              rules={RequiredRule}
              width={MODAL_INPUT_WIDTH_PX}
            />
          )}
          <FormInputDatetime
            required
            caption="Срок действия выставленного статуса НТ в списке информационных систем"
            control={form.control}
            label="Срок статуса НТ"
            name="expirationDate"
            rules={RequiredRule}
            width={MODAL_INPUT_WIDTH_PX}
          />
          <FormInputText
            textarea
            control={form.control}
            label="Комментарий"
            name="comment"
            required={statusIsRevision}
            rules={statusIsRevision && RequiredRule}
            width={MODAL_INPUT_WIDTH_PX}
          />
        </FlexboxColumn>
      </Modal>
      <SubmitChangeStatus
        handleChangeStatus={handleSubmit}
        message={`
          Статус заявки перейдет с “В работе” в “${getTaskStatusDisplay(formValue.status)}”. 
          Вернуться к предыдущему статусу невозможно. 
          Участники заявки получат уведомление о смене статуса по почте
        `}
        open={approveModalShown}
        onCancel={cancelApproveModal}
        onClose={closeApproveModal}
      />
    </>
  )
}

export default ChangeToCompletedModal
