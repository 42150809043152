import React, {ChangeEvent, ChangeEventHandler, FC, MouseEventHandler, ReactNode, Ref} from 'react'
import {QA} from '../types'
import {CheckboxState} from '../Checkbox'

export interface Identifiable {
  id: string
}

export type TableColumnWidth = string

export interface TableColumn extends Identifiable {
  type?: TableBodyCellType
  label: string
  description?: string
  renderGroupCode?: string
  width?: TableColumnWidth
  allowSort?: boolean
  allowFilter?: boolean
  allowBlocked?: boolean
  allowExtend?: boolean
  blockedPosition?: number
  isInvisible?: boolean
  selectable?: boolean
  editable?: boolean
  renderFilterValueInput?(item): ReactNode
}

export enum TableCellDensity {
  's' = 's',
  'm' = 'm',
  'l' = 'l',
}

export interface TableProps {
  children: ReactNode
  fixed?: boolean
  densityMode?: string
  framed?: boolean
}

export interface TableHeadProps {
  children: ReactNode
}

export interface TableBodyProps {
  children: ReactNode | ReactNode[]
}

export interface TableRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  highlightHover?: boolean
  selected?: boolean
  children: ReactNode | ReactNode[]
}

// base cell
interface TableFixedCellSepProps {
  /**
   * Cell render like sticky \ position fixed \ blocked
   */
  blocked?: boolean // sticky?: boolean,

  /**
   * Cell render offset value
   */
  blockedPosition?: any // stickyOffset?: string | number,

  /**
   * Parent cell dom reference
   */
  lockedRef?: React.MutableRefObject<any> // TODO replace to rootRef of cell
}

export type TableCellTextAlign = 'left' | 'center' | 'right'

export type TableCellRootRef = Ref<HTMLElement>

export interface TableBaseCell extends TableFixedCellSepProps, QA {
  //Required<TableFixedCellSepProps>, QA {
  rowId?: string
  colId?: string
  rootRef?: TableCellRootRef
  density?: TableCellDensity
  noWrap?: boolean
  //standalone?: boolean; // use div instead td\th styles , like mui component="elementType"
  align?: TableCellTextAlign
  disabled?: boolean
}

export interface TableUniversalComponent {
  bodyCell?: boolean //body?: boolean // like mui variant="head|body"
}

export interface TableCellLabelProps
  extends Pick<TableBaseCell, 'density' | 'noWrap'>,
    TableUniversalComponent {
  left?: boolean
  limitHeight?: boolean
  description?: string
  children: ReactNode
}

// head cell
export enum TableMenuIconType {
  arrow = 'arrow',
  filter = 'filter',
  // sort = 'sort',
  sortUp = 'sortUp',
  sortDown = 'sortDown',
  none = '',
}

export interface TableHeadCellProps
  extends React.HTMLAttributes<HTMLTableCellElement>,
    TableBaseCell {
  label?: ReactNode
  description?: string
  numeric?: boolean
  markerColor?: string
  allowExtend?: boolean
  menuIcon?: TableMenuIconType
  children?: ReactNode
  popperId?: string
  fieldId?: string
  isActive?: boolean
  width?: TableColumnWidth
  markerTitle?: ReactNode
  LabelComponent?: FC<TableCellLabelProps>
  lockTitle?: ReactNode
  //isBlocked?: boolean
  //setBlocked?: (id: string, start?: number) => void
}

// body cell
export enum TableBodyCellType {
  text = 'text', // TODO or multiText
  number = 'number', // TODO  or currency, prefix $ or %
  select = 'select',
  autocomplete = 'autocomplete',
  check = 'check', // checkbox, switch, or maybe radio
  date = 'date', // can setup format, or dateRange
}

export interface TableBodyCellProps
  extends React.HTMLAttributes<HTMLElement>,
    TableBaseCell,
    TableBodyCellSEProps {
  type?: TableBodyCellType
  InnerComponent?: FC<TableBodyCellInnerProps>
  InnerOption?: TableBodyCellInnerOptions //any

  onSelectCell?: OnSelectCellFn
  onEditCell?: OnEditCellFn

  children?: ReactNode
}

export interface TableBodyCellSEProps {
  selectable?: boolean
  selected?: boolean
  selectedSecondary?: boolean
  editable?: boolean
  editing?: boolean
}

export interface TableBodyActiveCellOnCancel {
  (): void
}

export interface TableBodyCellInnerOptions extends Record<string, unknown> {
  onCancel?: TableBodyActiveCellOnCancel
  onChange(event: ChangeEvent<unknown>, a, b, c, d): void //ChangeEventHandler<unknown> // any detail in custom component
  onStartEdit?: MouseEventHandler<HTMLElement>
}

export interface TableBodyCellInnerProps
  extends TableBaseCell,
    TableBodyCellInnerOptions,
    TableBodyCellSEProps {}

export interface TableCheckboxCellProps extends TableBaseCell, TableUniversalComponent {
  checked: CheckboxState
  onChange: ChangeEventHandler<HTMLInputElement>
  tooltip?: ReactNode
}

export interface TableStickCellProps extends TableBaseCell, TableUniversalComponent {
  color?: string
}

export interface TableGhostCellProps extends TableBaseCell, TableUniversalComponent {}

export type Selection = {rowId: string; colId: string}

export interface OnSelectCellFn {
  ({rowId, colId, isAdding}: {rowId: string; colId: string; isAdding?: boolean}): void
}

export type OnSelectCellsFn = (selections: Selection[]) => void

export interface OnEditCellFn {
  (rowId: string, colId: string, nodeRef: HTMLElement): void
}
