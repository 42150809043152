import {Box, Typography} from '@root/components/kit'
import {ChevronDown, ChevronUp} from '@x5-react-uikit/icons'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import TypographyError from '@root/components/typography/TypographyError'
import {getFieldReadonlyComponent} from '@root/pages/tasks/CreateTaskPage/utils/componentUtils'
import {FC, ReactNode, useState} from 'react'
import {ExtractConstValues} from '@root/core/helperTypes'
import {ReportCommonFieldsMeta} from '@root/features/reports/constants'

type Props = {
  meta: ExtractConstValues<typeof ReportCommonFieldsMeta>
  form: any // TODO: Not now, it's really complex object and requires to dig deeper into the functions that use it
  error?: ReactNode
}

const ReportDataAccordion: FC<Props> = ({meta, form, children, error}) => {
  const [expanded, setExpanded] = useState(false)

  let content = children
  if (!content)
    content = getFieldReadonlyComponent({
      fieldMeta: meta,
      form,
      commentTitle: null,
      fileBaseUrl: '/v1/reports/files',
    })

  return (
    <Box>
      <FlexboxRow
        sx={{
          py: 'x16',
          px: 'x12',
          alignItems: 'center',
          backgroundColor: 'white',
        }}
      >
        <FlexboxRow sx={{alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
          <FlexboxColumn>
            <FlexboxRow
              style={{cursor: 'pointer', userSelect: 'none'}}
              onClick={() => setExpanded((prev) => !prev)}
            >
              {expanded ? <ChevronUp /> : <ChevronDown />}
              <FlexboxColumn>
                <Typography variant="h3">{meta.label}</Typography>
                {error && <TypographyError variant="p2">{error}</TypographyError>}
              </FlexboxColumn>
            </FlexboxRow>
          </FlexboxColumn>
        </FlexboxRow>
      </FlexboxRow>
      {expanded && <Box sx={{mb: 'x16'}}>{content}</Box>}
    </Box>
  )
}

export default ReportDataAccordion
