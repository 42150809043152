import {FilterItemType} from '@root/openapi'
import {GetSystemListPayload} from '@root/redux/api/systemsApi'
import {ChangeEvent, useState} from 'react'
import {Combobox, SelectProps} from 'ui-kit'
import useGetSystemsByRole from '../hooks/useGetSystemsByRole'
import {SystemListFilterPayload, SystemToOptionMap} from '../types'
import {systemToOption as defaultSystemToOption} from '../utils'

export const getDefaultFilter = (
  fieldFilters: SystemListFilterPayload['filters'] | undefined
): SystemListFilterPayload => {
  const filters = {pageNumber: 1, pageSize: 9999999, filters: {}} as SystemListFilterPayload
  if (fieldFilters) filters.filters = fieldFilters
  return filters
}

export interface SystemsComboboxProps {
  required?: boolean
  onChange: (e: MouseEvent | KeyboardEvent | ChangeEvent, s: string) => void
  onClear: () => void
  selectedSystemId?: string
  width?: number | string
  caption?: string
  errorOnEmpty?: boolean
  disableValidation?: boolean
  filters?: GetSystemListPayload['filter']['filters']
  searchBy?: string
  systemToOptionMap?: SystemToOptionMap
  label?: string
  comboboxProps?: Partial<
    Omit<
      SelectProps,
      | 'stretch'
      | 'caption'
      | 'error'
      | 'label'
      | 'options'
      | 'preMatching'
      | 'required'
      | 'textError'
      | 'value'
      | 'onClearClick'
      | 'onFilterChange'
    >
  >
}

const SystemsCombobox = ({
  required,
  onChange,
  onClear,
  selectedSystemId,
  width,
  caption,
  errorOnEmpty,
  disableValidation,
  comboboxProps,
  searchBy = 'name|tag',
  label = 'Информационная система',
  systemToOptionMap = defaultSystemToOption,
  ...props
}: SystemsComboboxProps) => {
  const [filter, setFilter] = useState<GetSystemListPayload['filter']>(
    getDefaultFilter(props.filters)
  )

  const onChangeInput = (event) => {
    const systemName = event.target.value
    setFilter((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        [searchBy]: {value: systemName, type: FilterItemType.LIKE},
      },
    }))
  }

  const {systems: allSystems, isSystemsLoading} = useGetSystemsByRole(
    getDefaultFilter(props.filters)
  )
  const {systems} = useGetSystemsByRole(filter, systemToOptionMap)

  const [error, setError] = useState(false)
  const validateValue = (event) => {
    const targetText = event.nativeEvent.relatedTarget?.textContent
    if (allSystems.some((system) => system.name === targetText)) return
    if (filter.filters?.[searchBy]) setError(true)
  }

  const isError = disableValidation ? (errorOnEmpty && !selectedSystemId) || error : false

  return (
    <Combobox
      stretch
      caption={caption}
      error={isError}
      label={label}
      options={systems}
      preMatching={isSystemsLoading}
      required={required}
      textError="Неккоректный формат ИС. Выберите значение из списка."
      value={selectedSystemId}
      width={width}
      onBlur={disableValidation ? undefined : validateValue}
      onChange={(e, system) => {
        onChange(e as globalThis.MouseEvent, system as string)
        if (!disableValidation) {
          setError(false)
        }
        setFilter(getDefaultFilter(props.filters))
      }}
      onClearClick={onClear}
      onFilterChange={onChangeInput}
      {...comboboxProps}
    />
  )
}

export default SystemsCombobox
