import React, {FC} from 'react'
import {logoColors, LogoProps} from '../types'

const Logo: FC<LogoProps> = ({color, ...props}) => {
  if (color === logoColors.white) {
    return (
      <svg fill="none" viewBox="0 0 162 101" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M125.342 1.00586H36.5753V35.4792H125.35V1.00586H125.342Z" fill="white" />
        <path d="M0 100.998V35.571L36.567 0.998047V100.99H0V100.998Z" fill="white" />
        <path d="M162 100.998V35.571L125.35 0.998047V100.99H162V100.998Z" fill="white" />
      </svg>
    )
  }

  return (
    <svg fill="none" viewBox="0 0 162 100" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M125.342 0.0078125H36.5753V34.4811H125.35V0.0078125H125.342Z"
        fill="url(#paint0_linear_1_7)"
      />
      <path d="M0 100V34.5729L36.567 0V99.9917H0V100Z" fill="url(#paint1_linear_1_7)" />
      <path d="M162 100V34.5729L125.35 0V99.9917H162V100Z" fill="url(#paint2_linear_1_7)" />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_1_7"
          x1="45.4823"
          x2="113.461"
          y1="6.28066"
          y2="27.2057"
        >
          <stop stopColor="#00F1FF" />
          <stop offset="1" stopColor="#0185FF" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_1_7"
          x1="18.2856"
          x2="18.2856"
          y1="90.8494"
          y2="8.37669"
        >
          <stop stopColor="#0185FF" />
          <stop offset="1" stopColor="#2FABF5" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_1_7"
          x1="143.671"
          x2="143.671"
          y1="98.4526"
          y2="6.04366"
        >
          <stop offset="0.17" stopColor="#0185FF" />
          <stop offset="0.91" stopColor="#04BCE5" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Logo
