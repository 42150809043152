const getQaAttribute =
  (rootName: string) =>
  (subName?: string, state?: string | string[]): string => {
    if (!rootName) return null
    let endName = ''

    if (typeof state === 'string') endName = state

    if (Array.isArray(state)) {
      endName = state.filter((s) => s).join(':')
    }

    subName = subName ? `-${subName}` : ''
    return `${rootName}${subName}${!endName.length ? '' : ':' + endName}`
  }

export {getQaAttribute}
export default getQaAttribute
