import Divider from '@root/components/Divider'
import Section from '@root/components/Section'
import {useParams} from 'react-router-dom'
import useScrollToField from '../hooks/useScrollToField'
import ParamField from './ParamField'

const StepTechParams = ({form, stepMeta}) => {
  const {stepId} = useParams()

  const {fieldId: focusedFieldId, fieldRef: focusedFieldRef} = useScrollToField()

  const formValue = form.getValues()
  const stepIndex = Object.keys(formValue).find(
    (stepIndex) => Number(formValue[stepIndex].number) === Number(stepId)
  )
  const step = formValue[stepIndex]

  return (
    <Section sx={{display: 'flex', flexDirection: 'column'}}>
      {stepMeta &&
        Object.keys(stepMeta).map((fieldMetaId, fieldIndex) => {
          const hasSibling = fieldIndex + 1 !== Object.keys(stepMeta).length
          return (
            <div
              key={stepMeta.id}
              ref={focusedFieldId === fieldMetaId ? focusedFieldRef : undefined}
            >
              <ParamField
                key={fieldMetaId}
                fieldIndex={fieldIndex}
                fieldMeta={stepMeta[fieldMetaId]}
                fieldValue={step.fields.find((field) => field.id === fieldMetaId)}
                form={form}
                stepIndex={stepIndex}
              />
              {hasSibling && <Divider />}
            </div>
          )
        })}
    </Section>
  )
}

export default StepTechParams
