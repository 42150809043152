import {sizeTokenValues} from '@x5-react-uikit/tokens'
import {cellModeValues} from '@x5-react-uikit/core'
import {CSSProperties} from 'react'
import {BaseTheme} from '@x5-react-uikit/core/dist/theme/themes'
import {sortDirections} from '@root/components/kit'
import {SortAZ as SortAZIcon} from '@x5-react-uikit/icons'

type Colors = BaseTheme['colors']
type Spaces = BaseTheme['spaces']

export const isNestedCell = (mode: keyof typeof cellModeValues) =>
  mode === cellModeValues.text || mode === cellModeValues.number

export const getBorderColorTable = (colors: Colors) => colors.grey[10]

export const getBorderStyleTable = (
  colors: Colors,
  width: CSSProperties['width'] = 1
): CSSProperties => ({
  borderColor: getBorderColorTable(colors),
  borderStyle: 'solid',
  borderWidth: width,
})

export const getPaddingTableCell = (size: sizeTokenValues, spaces: Spaces) =>
  size === sizeTokenValues.small ? spaces.x4 : spaces.x6

const widthStickedColumn = 120

const getStyleStickedColumn = (colors: Colors) => ({
  position: 'sticky',
  backgroundColor: colors.white,
  minWidth: widthStickedColumn,
  maxWidth: widthStickedColumn,
  '&::after': {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: -1,
    content: '""',
    width: 1,
    height: '100%',
    backgroundColor: getBorderColorTable(colors),
  },
})

export const getStickedColumnSelector = (colors: Colors, columnCount: number) => {
  const offset = widthStickedColumn
  const shiftStickedColumn = 1
  let left = -shiftStickedColumn
  const css = {}
  for (let i = 1; i <= columnCount; i++) {
    css[`& td:nth-of-type(${i}), & th:nth-of-type(${i})`] = {
      ...getStyleStickedColumn(colors),
      left,
    }
    left = offset * i - shiftStickedColumn
  }

  return css
}

export const getSortOptions = (labelAsc, labelDesc) => [
  {
    value: sortDirections.asc,
    name: labelAsc,
    icon: <SortAZIcon size={sizeTokenValues.small} />,
    label: 'Сортировка',
  },
  {
    value: sortDirections.desc,
    name: labelDesc,
    icon: <SortAZIcon size={sizeTokenValues.small} />,
  },
]
