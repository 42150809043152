import {Box, Button, Link, Typography} from '@x5-react-uikit/core'
import Breadcrumbs from '@root/components/Breadcrumbs'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import LoaderFrame from '@root/components/Loader'
import Section from '@root/components/Section'
import useNotify from '@root/hooks/useNotify'
import {SpecialistToolsTabs} from '@root/pages/SpecialistToolsPage/constants'
import {useMemo, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useCreateReportMutation, useGetLaunchByIdQuery} from '@root/redux/api/launchesApi'
import {formattedDate} from 'utils'
import ConfigurationsCombobox from './components/ConfigurationsCombobox'
import VariablesModal from './components/VariablesModal'
import {FilterItemType} from '@root/openapi'

const CreateReportPage = () => {
  const [searchParams] = useSearchParams()
  const params = useMemo(
    () => ({
      systemId: searchParams.get('systemId'),
      taskId: searchParams.get('taskId'),
      launchId: searchParams.get('launchId'),
    }),
    [searchParams]
  )
  const cancelRoute = `/tools?systemId=${params.systemId}&tab=${SpecialistToolsTabs.history.value}`

  const navigate = useNavigate()
  const {notifyError, notifySuccess, closeSnackbar} = useNotify()

  const {data: launch, isLoading: isLaunchLoading} = useGetLaunchByIdQuery(params)

  const [selectedConfiguration, setSelectedConfiguration] = useState(null)
  const [selectedConfigurationError, setSelectedConfigurationError] = useState(false)

  const [variablesModalShown, setVariablesModalShown] = useState(false)

  const [createReport] = useCreateReportMutation()
  const handleCreateReport = () => {
    if (!selectedConfiguration) {
      setSelectedConfigurationError(true)
      return
    }
    createReport({launchId: launch.id, configurationId: selectedConfiguration})
      .unwrap()
      .then(() => {
        notifySuccess(
          'Отчёт сформирован',
          'Вы сможете посмотреть его также в разделе отчетов или выгрузить в формате .pdf',
          {
            text: 'В раздел “Отчеты”',
            onClick: () => {
              navigate('/reports')
              closeSnackbar()
            },
          }
        )
        navigate(cancelRoute)
      })
      .catch((error) => {
        console.error(error)
        notifyError()
      })
  }

  const handleCancel = () => navigate(cancelRoute)

  const onSelectConf = (_event, confId) => {
    setSelectedConfiguration(confId)
    setSelectedConfigurationError(null)
  }

  if (isLaunchLoading) return <LoaderFrame />

  return (
    <>
      <Box sx={{mx: 'x8', width: '1200px'}}>
        <Breadcrumbs
          routes={[
            {to: '/', label: 'Главная'},
            {to: '/reports', label: 'Отчёты'},
            {to: null, label: 'Формирование отчёта'},
          ]}
        />
        <Typography variant="h2">Формирование отчета нагрузочного тестирования</Typography>
        <Section sx={{my: 'x10'}}>
          <FlexboxColumn sx={{gap: '40px', width: '400px'}}>
            <FlexboxColumn sx={{gap: '24px'}}>
              <FlexboxColumn sx={{gap: '8px'}}>
                <Typography variant="h3">Вводные данные</Typography>
                <Typography variant="p3">
                  Сверьтесь с вводной информацией и выберите конфигурацию отчета.
                </Typography>
              </FlexboxColumn>
              <FlexboxColumn>
                <Typography variant="h4">Информационная система</Typography>
                <Typography variant="p1">{launch.systemTag || launch.systemName}</Typography>
              </FlexboxColumn>
              <FlexboxColumn>
                <Typography variant="h4">Заявка</Typography>
                <Typography variant="p1">#{launch.taskNumber}</Typography>
              </FlexboxColumn>
              <FlexboxColumn>
                <Typography variant="h4">Тестирование</Typography>
                <Typography variant="p1">
                  ID {launch.pipelineId} от {formattedDate(launch.createdAt)}
                </Typography>
              </FlexboxColumn>
              <FlexboxColumn>
                <Typography variant="h4">Шаблон теста</Typography>
                <FlexboxRow sx={{gap: '8px', alignItems: 'center'}}>
                  <Typography variant="p1">{launch.variablesTemplateName}</Typography>
                  <Link onClick={() => setVariablesModalShown(true)}>Посмотреть</Link>
                </FlexboxRow>
              </FlexboxColumn>
              <ConfigurationsCombobox
                filters={{
                  'system.id': {
                    value: searchParams.get('systemId') as any,
                    type: FilterItemType.EQUAL,
                  },
                }}
                isError={selectedConfigurationError}
                selectedConfId={selectedConfiguration}
                onChange={onSelectConf}
                onClear={() => setSelectedConfiguration(null)}
              />
            </FlexboxColumn>
            <FlexboxRow sx={{gap: '16px'}}>
              <Button variant="outlined" onClick={handleCancel}>
                Отмена
              </Button>
              <Button onClick={handleCreateReport}>Сформировать отчёт</Button>
            </FlexboxRow>
          </FlexboxColumn>
        </Section>
      </Box>
      <VariablesModal
        launch={launch}
        open={variablesModalShown}
        onClose={() => setVariablesModalShown(false)}
      />
    </>
  )
}

export default CreateReportPage
