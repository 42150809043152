import {ReactNode, ReactElement, CSSProperties} from 'react'
import {sizeTokenValues} from '@x5-react-uikit/tokens'
import type {QA} from 'ui-kit/src/types'

export enum SegmentedControlSizes {
  small = sizeTokenValues.small,
  large = sizeTokenValues.large,
}

export interface UncontrolledSegmentedControlItem extends QA {
  value: number
  label: ReactNode
  disabled?: boolean
  selected?: boolean
  icon?: ReactElement
  width?: CSSProperties['width']
}

export interface UncontrolledSegmentedControlsProps extends QA {
  items: UncontrolledSegmentedControlItem[]
  value: number
  size?: SegmentedControlSizes
  onChange: (value: UncontrolledSegmentedControlsProps['value']) => void
}
