import {createContext} from 'react'
import {SetAction} from '@root/core/helperTypes'
import {Template} from '../../types'

export type TableContextState = {
  setTemplateToDelete: SetAction<Template | null>
  setTemplateToEdit: SetAction<Template | null>
}

export const tableContext = createContext<TableContextState | null>(null)
export const TableContextProvider = tableContext.Provider
