import {useSaveChangesBeforeTeardown} from '@root/hooks/useSaveChangesBeforeTeardown'
import {useCallback} from 'react'
import useUpdateFormData from '../../CreateTaskPage/hooks/useUpdateFormData'
import {getFormIndiciesById, getUpdatedFieldIds} from '../../CreateTaskPage/utils/formUtils'
import {UseTaskPageForm} from './useTaskPageForm'
import {useUpdateSystemMutation} from '@root/redux/api/systemsApi'
import {useDispatch} from 'react-redux'
import {invalidateTasksAction, useUpdateTaskMutation} from '@root/redux/api/tasksApi'

type Params = {
  updated: boolean
  stepId: number
  systemId: string
  taskId: string
  currentStepMeta: {[key: string]: any}
  form: UseTaskPageForm['form']
  shouldCheckCompleted?: boolean
}

export const useSaveFormBeforeTeardown = ({
  updated,
  currentStepMeta,
  form,
  stepId,
  taskId,
  systemId,
  shouldCheckCompleted = true,
}: Params) => {
  form.watch()

  const {updateFormData} = useUpdateFormData()
  const [updateSystem] = useUpdateSystemMutation()
  const [updateTask] = useUpdateTaskMutation()
  const dispatch = useDispatch()
  const saveAction = useCallback(async () => {
    if (updated) {
      return
    }

    const dirtyFields = form.formState.dirtyFields as any

    if (dirtyFields.system?.description) {
      try {
        await updateSystem({
          systemId,
          updateData: {description: form.getValues('system.description')},
        }).unwrap()
        dispatch(invalidateTasksAction)
      } catch (error) {
        console.log(error)
      }
    }

    if (dirtyFields.finportal?.workCost) {
      try {
        await updateTask({
          taskId,
          update: {workCost: form.getValues('finportal.workCost')},
        }).unwrap()
      } catch (error) {
        console.log(error)
      }
    }

    const steps = Object.values(form.getValues('formData'))
    const updatedFields = dirtyFields.formData
      ? getUpdatedFieldIds(dirtyFields.formData[Number(stepId) - 1], currentStepMeta)
      : []
    if (dirtyFields.finportal?.cpp) {
      const {stepIndex, fieldIndex} = getFormIndiciesById(
        Object.values(form.getValues('formData')),
        'budget_source'
      )
      steps[stepIndex].fields[fieldIndex].values.text[0].value = form.getValues('finportal.cpp')
      updatedFields.push('budget_source')
    }
    if (updatedFields?.length > 0) {
      const updateArgs = {
        steps: Object.values(form.getValues('formData')),
        updatedFields,
        shouldNotify: false,
        currentStepId: stepId,
      } as any
      if (shouldCheckCompleted) updateArgs.currentStepMeta = currentStepMeta
      updateFormData(updateArgs)
    }
    // eslint-disable-next-line
  }, [updated, currentStepMeta])

  useSaveChangesBeforeTeardown({shouldBlock: !updated, saveAction})
}
