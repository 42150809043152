import React, {forwardRef, createElement, useState, useContext, useMemo, useCallback} from 'react'
import clsx from 'clsx'
import {sizeTokenValues} from '@x5-react-uikit/tokens'
import {
  ChevronRight as ChevronRightIcon,
  ChevronDown as ChevronDownIcon,
} from '@x5-react-uikit/icons'
import {TableContext} from '../Table/context'
import {ControlCell} from '../Controls/ControlCell'
import {DataGridControlCellProps} from '../Controls/types'
import {DataGridCellProps, cellAlignValues, cellSideComponentValues} from './types'
import {dataGridExpanderValues} from '../Row/types'
import {getCellQA, isNestedCell} from '../helpers'
import {DataGridLabel} from '../Label'
import {stateClasses} from '../../styles'
import {StyledCell, StyledExpanderCell, cellClasses} from '../styles'
import {StyledButtonCell} from '../Controls/styles'

const getSideComponents = ({startComponent, endComponent, id, disabled, сompacted, onClick}) => {
  let StartComponent = null
  let EndComponent = null

  if (startComponent?.type === cellSideComponentValues.icon) {
    const Icon = startComponent.component
    StartComponent = <Icon className={cellClasses.start} size={sizeTokenValues.small} />
  }

  if (startComponent?.type === cellSideComponentValues.label) {
    StartComponent = (
      <DataGridLabel className={cellClasses.start} color={startComponent.color}>
        {startComponent.value}
      </DataGridLabel>
    )
  }

  if (endComponent?.type === cellSideComponentValues.icon) {
    const Icon = endComponent.component
    EndComponent = <Icon className={cellClasses.end} size={sizeTokenValues.small} />
  }

  if (endComponent?.type === cellSideComponentValues.label) {
    EndComponent = (
      <DataGridLabel className={cellClasses.end} color={endComponent.color}>
        {endComponent.value}
      </DataGridLabel>
    )
  }

  if (endComponent?.type === cellSideComponentValues.button) {
    const ButtonIcon = endComponent.component
    EndComponent = (
      <StyledButtonCell
        aria-label={ButtonIcon.displayName.slice(0, -4)}
        className={cellClasses.end}
        data-id={`btn-${id}`}
        data-mode={сompacted ? 'single' : 'default'}
        data-qa={getCellQA('button')}
        disabled={disabled}
        onClick={(event) => {
          event.stopPropagation()
          onClick({...event, target: event.currentTarget})
        }}
      >
        <ButtonIcon size={sizeTokenValues.small} />
      </StyledButtonCell>
    )
  }

  return [StartComponent, EndComponent]
}

export const DataGridCell = forwardRef<HTMLTableCellElement, DataGridCellProps>(
  (
    {
      id,
      mode,
      align = cellAlignValues.left,
      disabled,
      indented = true,
      error,
      children,
      startComponent = null,
      endComponent = null,
      qa,
      expander,
      depth,
      onClick,
      onCellChange,
      onExpanderClick,
      ...props
    },
    ref
  ) => {
    const hasContent = Boolean(children)
    const averaged =
      startComponent?.type === cellSideComponentValues.label ||
      endComponent?.type === cellSideComponentValues.label
    const сompacted =
      !startComponent && !hasContent && endComponent?.type === cellSideComponentValues.button
    const context = useContext(TableContext)
    const [focused, setFocused] = useState(false)
    const nested = useMemo(() => isNestedCell(mode), [mode])
    const [StartComponent, EndComponent] = getSideComponents({
      startComponent,
      endComponent,
      id,
      disabled,
      сompacted,
      onClick,
    })

    const handleNestedFocus = () => setFocused(true)
    const handleNestedBlur = () => setFocused(false)
    const handleClick: DataGridCellProps['onClick'] = (event) => {
      const target = event.target as HTMLTableCellElement
      const cell = target.closest('[data-id]') as HTMLTableCellElement
      context._setCurrentCellId(cell?.dataset.id)
      onClick?.(event)
    }

    const controlCellProps: DataGridControlCellProps = useMemo(
      () => ({
        cellId: id,
        mode,
        value: children as string,
        disabled,
        error,
        align,
        onFocus: handleNestedFocus,
        onBlur: handleNestedBlur,
        onChange: onCellChange,
      }),
      [id, mode, children, align, disabled, error, onCellChange]
    )

    const ExpandedIcon =
      expander === dataGridExpanderValues.open ? ChevronDownIcon : ChevronRightIcon

    const WrapCellContent = useCallback(
      ({children}: {children: DataGridCellProps['children']}) => {
        const noExpander = expander === dataGridExpanderValues.none
        const isExpanded = typeof depth === 'number'
        if (StartComponent || EndComponent || isExpanded) {
          return (
            <div className={cellClasses.compound}>
              {isExpanded && (
                <StyledExpanderCell
                  hasContent
                  data-qa={getCellQA('grouped')}
                  depth={depth}
                  onClick={!noExpander ? onExpanderClick : null}
                >
                  {!noExpander && <ExpandedIcon size={sizeTokenValues.small} />}
                </StyledExpanderCell>
              )}
              {StartComponent}
              <div className={cellClasses.inner} data-qa={getCellQA('content')}>
                {children}
              </div>
              {EndComponent}
            </div>
          )
        }

        return children as JSX.Element
      },
      [EndComponent, StartComponent, ExpandedIcon, expander, depth, onExpanderClick]
    )

    const cellContent = useMemo(() => {
      return (
        <WrapCellContent>
          <ControlCell {...controlCellProps}>{children}</ControlCell>
        </WrapCellContent>
      )
    }, [children, controlCellProps, WrapCellContent])

    const dataQa = qa ?? getCellQA(null, [mode, disabled && 'disabled', error && 'error'])

    return createElement(
      StyledCell,
      {
        ...props,
        'data-id': id,
        'data-qa': dataQa,
        hasContent,
        error,
        disabled,
        nested,
        indented,
        align,
        averaged,
        сompacted,
        className: clsx(disabled && stateClasses.isDisabled, focused && stateClasses.isFocused),
        tabIndex: 0,
        onClick: handleClick,
        ref,
      },
      cellContent
    )
  }
)

export default DataGridCell
