import {IconButton, Typography} from '@x5-react-uikit/core'
import {Del as DelIcon} from '@x5-react-uikit/icons'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'
import {RequiredRule} from '@root/constants'

const FIELD_WIDTH = '520px'
const DYNAMIC_FIELDS_REGISTER = 'reportData.dynamicFields'

const EditDynamicField = ({form, number}) => {
  const registerLabel = `${DYNAMIC_FIELDS_REGISTER}.${number - 1}.label`
  const registerValue = `${DYNAMIC_FIELDS_REGISTER}.${number - 1}.values.comment.value`

  const handleDelete = () => {
    const fields = form.getValues(DYNAMIC_FIELDS_REGISTER).reduce((newFields, currentField) => {
      if (currentField.number !== number)
        newFields.push({...currentField, number: newFields.length + 1})
      return newFields
    }, [])
    form.setValue(DYNAMIC_FIELDS_REGISTER, fields)
  }

  return (
    <FlexboxColumn sx={{width: FIELD_WIDTH, gap: '24px'}}>
      <FlexboxRow sx={{justifyContent: 'space-between'}}>
        <Typography variant="h3">Дополнительное текстовое поле №{number}</Typography>
        <IconButton
          IconComponent={<DelIcon />}
          size="small"
          variant="text"
          onClick={handleDelete}
        />
      </FlexboxRow>
      <FormInputText
        required
        control={form.control}
        label="Заголовок поля"
        name={registerLabel}
        rules={RequiredRule}
        width={FIELD_WIDTH}
      />
      <FormInputText
        required
        textarea
        control={form.control}
        label="Комментарий"
        name={registerValue}
        rules={RequiredRule}
        width={FIELD_WIDTH}
      />
    </FlexboxColumn>
  )
}

export default EditDynamicField
