import React, {ChangeEvent, CSSProperties, FC, useCallback} from 'react'
import clsx from 'clsx'
import {IMaskInput} from 'react-imask'
import {BodyCellInnerNumberDefaultScale, BodyCellInnerNumberEditProps} from './types'
import {createFakeEvent} from '../../../utils/fakeEvent'
import {getCellDensityClassNameBy} from '../../helpers'
import {EditableCellPortal} from '../EditableCellPortal'
import {useBaseCellStyles} from '../../Cell/styles'
import {valueIsEmpty} from './helpers'
import {useInnerNumberStyles} from './styles'
import {BodyCellRegistry} from '../BodyCellRegistry'

const getStyles = (ref) => {
  const fixBorderWidth = 3
  const anchor = ref.current || ref
  const styles: CSSProperties = {}

  if (anchor) {
    const rect = anchor.getBoundingClientRect()
    styles.width = rect.width + fixBorderWidth
    styles.height = rect.height + fixBorderWidth
  }

  return styles
}

export const BodyCellInnerNumberEdit: FC<BodyCellInnerNumberEditProps> = (
  props: BodyCellInnerNumberEditProps
) => {
  const {
    density,
    noWrap,
    rootRef,
    children: sourceValue,
    onCancel,
    onChange,
    signed = true,
    scale = BodyCellInnerNumberDefaultScale,
    isFraction,
    min = 0,
    max = Number.MAX_SAFE_INTEGER,
  } = props

  const baseClasses = useBaseCellStyles()
  const innerClasses = useInnerNumberStyles()
  const densityClassName = baseClasses[getCellDensityClassNameBy(density)]

  const enterHandledRef = React.useRef(false)

  const value =
    valueIsEmpty(sourceValue) || Boolean(BodyCellRegistry.startEditingFromOnlySelect)
      ? ''
      : parseFloat(String(sourceValue)) * (isFraction ? 100 : 1)
  const valRef = React.useRef(value)

  // update from props
  if (value !== valRef.current) {
    valRef.current = value
  }

  BodyCellRegistry.startEditingFromOnlySelect = false

  const innerOnChange = (val) => {
    let sendValue
    if (valueIsEmpty(val)) {
      sendValue = null
    } else {
      const numberTry = parseFloat(String(val).replace(/\s/g, ''))
      sendValue = isNaN(numberTry) ? 0 : numberTry
      sendValue = String(sendValue / (isFraction ? 100 : 1))
    }

    const fakeEventObject = createFakeEvent(sendValue) as ChangeEvent<HTMLInputElement>
    onChange && onChange(fakeEventObject)
  }

  const enterHandler = (e) => {
    if (e.key === 'Enter') {
      // fix several enter
      enterHandledRef.current = true

      innerOnChange(valRef.current)
    }
  }

  const onClickAway = useCallback(
    (e) => {
      e.stopPropagation()

      // //restore value
      // valRef.current = value;
      // onCancel && onCancel();

      innerOnChange(valRef.current)
    },
    [valRef.current]
  )

  const onAccept = useCallback((val) => (valRef.current = val), [])

  React.useEffect(() => {
    window.addEventListener('keydown', enterHandler)
    return () => window.removeEventListener('keydown', enterHandler)
  }, [])

  return (
    <EditableCellPortal cellRef={rootRef} onClickAway={onClickAway}>
      <label className={innerClasses.innerRootEdit} style={getStyles(rootRef)}>
        <IMaskInput
          autoFocus
          normalizeZeros
          className={clsx(innerClasses.innerInputNumber, densityClassName)}
          mapToRadix={['.', ',']}
          mask={Number}
          max={max}
          min={min}
          padFractionalZeros={Boolean(scale)}
          radix="."
          scale={scale}
          signed={signed}
          thousandsSeparator=" "
          unmask={false} // true|false|'typed'
          value={String(valRef.current)}
          onAccept={onAccept}
          onFocus={(e) => {
            const {target} = e
            setTimeout(() => target.select(), 0)
          }}
        />
      </label>
    </EditableCellPortal>
  )
}

export default BodyCellInnerNumberEdit
