import React, {FC} from 'react'
import {logoColors, LogoProps} from '../types'
import Blank from '../Blank'

const Logo: FC<LogoProps> = ({color, ...props}) => {
  if (color === logoColors.black) return <Blank color={color} {...props} />

  if (color === logoColors.white) {
    return (
      <svg fill="none" viewBox="0 0 615 100" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M124.236 0H36.2591V34.0565H124.236V0Z" fill="white" />
        <path d="M0 98.8529V34.1374L36.2591 0V98.8529H0Z" fill="white" />
        <path d="M160.576 98.8529V34.1374L124.236 0V98.8529H160.576Z" fill="white" />
        <path
          d="M198.69 33.8398H261.654V98.3023H243.595V50.6807H216.83V98.3023H198.771V33.8398H198.69Z"
          fill="white"
        />
        <path
          d="M323.408 33.8386H341.466V98.3011H323.408V89.0346C320.989 92.4189 317.845 95.2391 314.137 97.173C310.428 99.1069 306.316 100.074 302.124 99.9933C284.307 99.9933 271.086 86.2144 271.086 66.231C271.086 46.3282 284.066 32.1465 302.527 32.1465C310.831 32.1465 319.135 35.7725 323.408 42.3799V33.8386ZM322.843 66.1504C322.843 63.8943 322.44 61.7186 321.634 59.6236C320.828 57.5286 319.538 55.6753 318.006 54.0637C316.474 52.4522 314.62 51.2435 312.524 50.3571C310.509 49.4708 308.251 49.0679 306.075 48.9873C301.641 49.1485 297.448 51.0018 294.385 54.2249C291.321 57.448 289.628 61.7186 289.628 66.1504C289.628 70.5822 291.402 74.8529 294.465 77.9954C297.529 81.138 301.802 82.9913 306.236 83.1524C310.67 83.0718 314.943 81.2186 318.087 78.076C321.07 74.8529 322.843 70.5822 322.843 66.1504Z"
          fill="white"
        />
        <path
          d="M395.724 98.3023L381.374 74.8541H371.458V98.3023H353.964V33.8398H371.458V59.4637H380.81L395.563 33.9204H415.476L396.289 65.7488L417.814 98.3023H395.724Z"
          fill="white"
        />
        <path
          d="M484.001 71.953H435.549C437.484 79.5274 443.611 83.9592 452.076 83.9592C455.301 83.9592 458.445 83.234 461.347 81.9447C464.25 80.6555 466.91 78.7216 469.006 76.3848L479.487 87.0211C473.44 95.1595 462.234 99.9942 450.948 99.9942C431.035 99.9942 416.523 86.0542 416.523 66.232C416.523 45.3622 432.405 32.3086 450.786 32.3086C469.167 32.3086 484.324 45.4428 484.324 65.1845C484.405 66.796 484.243 69.0522 484.001 71.953ZM466.185 60.1886C465.62 52.6143 459.413 47.7796 451.27 47.7796C443.772 47.7796 437.484 51.4862 435.549 60.1886H466.185Z"
          fill="white"
        />
        <path
          d="M546.562 33.8398V50.6807H526.569V98.3023H508.349V50.6807H488.355V33.8398H546.562Z"
          fill="white"
        />
        <path
          d="M596.339 33.7987L592.634 27.6042L588.997 33.7987H584.618L590.277 24.4397L584.55 15.7539H589.131L592.836 21.5444L596.339 15.7539H600.718L595.06 24.709L600.853 33.7987H596.339Z"
          fill="white"
        />
        <path
          d="M615 27.6716C615 31.6441 612.036 34.1354 608.061 34.1354C604.626 34.1354 601.729 32.2501 601.392 28.0756H605.097C605.367 29.8935 606.781 30.6342 608.398 30.6342C610.352 30.6342 611.227 29.2875 611.227 27.6042C611.227 25.8536 609.88 24.709 608.061 24.709C606.512 24.709 605.569 25.4496 605.097 26.1903H601.527L602.739 15.7539H614.461L614.057 19.2551H605.636L605.299 22.689C605.299 22.689 606.175 21.6791 608.87 21.6791C612.171 21.6791 615 23.8337 615 27.6716Z"
          fill="white"
        />
        <path
          d="M601.864 13.529C599.843 5.78588 593.578 0.332031 580.442 0.332031H564.611L566.766 14.8756C568.383 28.0053 573.368 32.1798 582.26 33.7284C582.328 33.7284 582.597 33.7958 582.597 33.4591C582.597 33.3244 582.53 33.2571 582.395 33.1898C576.4 30.6985 575.726 22.4167 574.446 14.0676L573.436 7.46916H582.463C590.816 7.46916 597.216 7.3345 601.325 13.731C601.392 13.7983 601.46 13.8656 601.594 13.8656C601.729 13.8656 601.864 13.731 601.864 13.5963C601.864 13.529 601.864 13.529 601.864 13.529Z"
          fill="white"
        />
      </svg>
    )
  }

  return (
    <svg fill="none" viewBox="0 0 615 100" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M124.236 0H36.2591V34.0565H124.236V0Z" fill="url(#paint0_linear_1_7)" />
      <path d="M0 98.8529V34.1374L36.2591 0V98.8529H0Z" fill="url(#paint1_linear_1_7)" />
      <path
        d="M160.576 98.8529V34.1374L124.236 0V98.8529H160.576Z"
        fill="url(#paint2_linear_1_7)"
      />
      <path
        d="M198.69 33.8398H261.654V98.3023H243.595V50.6807H216.83V98.3023H198.771V33.8398H198.69Z"
        fill="black"
      />
      <path
        d="M323.408 33.8386H341.466V98.3011H323.408V89.0346C320.989 92.4189 317.845 95.2391 314.137 97.173C310.428 99.1069 306.316 100.074 302.124 99.9933C284.307 99.9933 271.086 86.2144 271.086 66.231C271.086 46.3282 284.066 32.1465 302.527 32.1465C310.831 32.1465 319.135 35.7725 323.408 42.3799V33.8386ZM322.843 66.1504C322.843 63.8943 322.44 61.7186 321.634 59.6236C320.828 57.5286 319.538 55.6753 318.006 54.0637C316.474 52.4522 314.62 51.2435 312.524 50.3571C310.509 49.4708 308.251 49.0679 306.075 48.9873C301.641 49.1485 297.448 51.0018 294.385 54.2249C291.321 57.448 289.628 61.7186 289.628 66.1504C289.628 70.5822 291.402 74.8529 294.465 77.9954C297.529 81.138 301.802 82.9913 306.236 83.1524C310.67 83.0718 314.943 81.2186 318.087 78.076C321.07 74.8529 322.843 70.5822 322.843 66.1504Z"
        fill="black"
      />
      <path
        d="M395.724 98.3023L381.374 74.8541H371.458V98.3023H353.964V33.8398H371.458V59.4637H380.81L395.563 33.9204H415.476L396.289 65.7488L417.814 98.3023H395.724Z"
        fill="black"
      />
      <path
        d="M484.001 71.953H435.549C437.484 79.5274 443.611 83.9592 452.076 83.9592C455.301 83.9592 458.445 83.234 461.347 81.9447C464.25 80.6555 466.91 78.7216 469.006 76.3848L479.487 87.0211C473.44 95.1595 462.234 99.9942 450.948 99.9942C431.035 99.9942 416.523 86.0542 416.523 66.232C416.523 45.3622 432.405 32.3086 450.786 32.3086C469.167 32.3086 484.324 45.4428 484.324 65.1845C484.405 66.796 484.243 69.0522 484.001 71.953ZM466.185 60.1886C465.62 52.6143 459.413 47.7796 451.27 47.7796C443.772 47.7796 437.484 51.4862 435.549 60.1886H466.185Z"
        fill="black"
      />
      <path
        d="M546.562 33.8398V50.6807H526.569V98.3023H508.349V50.6807H488.355V33.8398H546.562Z"
        fill="black"
      />
      <path
        d="M596.339 33.7987L592.634 27.6042L588.997 33.7987H584.618L590.277 24.4397L584.55 15.7539H589.131L592.836 21.5444L596.339 15.7539H600.718L595.06 24.709L600.853 33.7987H596.339Z"
        fill="black"
      />
      <path
        d="M615 27.6716C615 31.6441 612.036 34.1354 608.061 34.1354C604.626 34.1354 601.729 32.2501 601.392 28.0756H605.097C605.367 29.8935 606.781 30.6342 608.398 30.6342C610.352 30.6342 611.227 29.2875 611.227 27.6042C611.227 25.8536 609.88 24.709 608.061 24.709C606.512 24.709 605.569 25.4496 605.097 26.1903H601.527L602.739 15.7539H614.461L614.057 19.2551H605.636L605.299 22.689C605.299 22.689 606.175 21.6791 608.87 21.6791C612.171 21.6791 615 23.8337 615 27.6716Z"
        fill="black"
      />
      <path
        d="M601.864 13.529C599.843 5.78588 593.578 0.332031 580.442 0.332031H564.611L566.766 14.8756C568.383 28.0053 573.368 32.1798 582.26 33.7284C582.328 33.7284 582.597 33.7958 582.597 33.4591C582.597 33.3244 582.53 33.2571 582.395 33.1898C576.4 30.6985 575.726 22.4167 574.446 14.0676L573.436 7.46916H582.463C590.816 7.46916 597.216 7.3345 601.325 13.731C601.392 13.7983 601.46 13.8656 601.594 13.8656C601.729 13.8656 601.864 13.731 601.864 13.5963C601.864 13.529 601.864 13.529 601.864 13.529Z"
        fill="#5FAF2D"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_1_7"
          x1="45.1074"
          x2="112.461"
          y1="6.16714"
          y2="26.9516"
        >
          <stop stopColor="#00F0FF" />
          <stop offset="1" stopColor="#0184FF" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_1_7"
          x1="18.1492"
          x2="18.1492"
          y1="89.7847"
          y2="8.23917"
        >
          <stop stopColor="#0184FF" />
          <stop offset="1" stopColor="#2FAAF4" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear_1_7"
          x1="142.435"
          x2="142.435"
          y1="97.3025"
          y2="5.9323"
        >
          <stop offset="0.17" stopColor="#0184FF" />
          <stop offset="0.91" stopColor="#04BBE4" />
        </linearGradient>
      </defs>
    </svg>
  )
}
export default Logo
