import React, {FC} from 'react'
import {defaultCellDensity} from '../../helpers'
import BodyCellInnerDateView from './View'
import BodyCellInnerDateEdit from './Edit'
import {BodyCellInnerDateProps, BodyCellInnerDateEditProps} from './types'

export const BodyCellInnerDate: FC<BodyCellInnerDateProps> = (props: BodyCellInnerDateProps) => {
  const {
    density = defaultCellDensity,
    noWrap,
    editable,
    editing,
    onStartEdit,
    children: value,
  } = props

  return (
    <>
      <BodyCellInnerDateView
        density={density}
        editable={editable}
        noWrap={noWrap}
        onStartEdit={onStartEdit}
      >
        {value}
      </BodyCellInnerDateView>
      {editing && (
        <BodyCellInnerDateEdit {...(props as BodyCellInnerDateEditProps)}>
          {value}
        </BodyCellInnerDateEdit>
      )}
    </>
  )
}

export default BodyCellInnerDate
