import {AppTableState} from '@root/components/appTable'
import {FilterCollectorFilter} from '@root/components/kit'
import {ExtractConstValues} from '@root/core/helperTypes'
import {System} from '@root/features/systems/types'
import {components, FilterItemType, SortRequestType} from '@root/openapi'
import {GetLaunchesPayload} from '@root/redux/api/launchesApi'
import {GetTasksByFilterPayload} from '@root/redux/api/tasksApi'
import {sortDirections} from '@x5-react-uikit/core'
import {isEmpty} from 'lodash'
import {reportConfigurationTabKind} from './types'

// TODO: Same code structure as from LaunchHistoryTable's fieldMapper. Could be refactored, but later

function getExpressionForTab(
  currentTab: reportConfigurationTabKind,
  myEmail: string
): GetTasksByFilterPayload['filters'] {
  switch (currentTab) {
    case reportConfigurationTabKind.all:
      return {}
    case reportConfigurationTabKind.my:
      return {
        'creator.email': {
          value: myEmail as any,
          type: FilterItemType.EQUAL,
        },
      }
    case reportConfigurationTabKind.others:
      return {
        'creator.email': {
          value: myEmail as any,
          type: FilterItemType.NOT_EQUAL,
        },
      }
    default:
      throw new Error('Out of range')
  }
}

function sortValueMapper(
  tableFormat: ExtractConstValues<typeof sortDirections>
): SortRequestType {
  switch (tableFormat) {
    case 'asc':
      return SortRequestType.ASC
    case 'desc':
      return SortRequestType.DESC
    default:
      throw new Error('Out of range')
  }
}

function sortNameMapper(field: keyof System | string): string {
  if (field === 'creator') {
    return 'creator.email'
  }

  return field
}

function getExpressionForField(
  field: FilterCollectorFilter
): [string, components['schemas']['FilterItem']] {
  const fieldName = field.name
  const value = field.value as any

  switch (fieldName) {
    case 'name':
      return ['name', {value, type: FilterItemType.LIKE}]
    case 'system':
      return ['system.id', {value, type: FilterItemType.EQUAL}]
    case 'creator':
      if (isEmpty(value)) {
        return [null, null]
      }
      return ['creator.id', {value, type: FilterItemType.EQUAL}]
    default:
      throw new Error('Out of range')
  }
}

export function fieldMapper(
  tableState: AppTableState<System>,
  myEmail: string
): GetLaunchesPayload['filter'] {
  const tabExpression = getExpressionForTab(tableState.currentTab, myEmail)

  const filterExpressions = tableState.filterValues
    .map(getExpressionForField)
    .filter(([, value]) => !isEmpty(value))
    .reduce((acc, [key, value]) => ({...acc, [key]: value}), {})

  let sortExpressions: GetLaunchesPayload['filter']['sortList'] = (tableState.sort ?? [])
    .filter((sortItem) => sortItem.value !== 'unset')
    .reduce(
      (acc, sortItem) => [
        ...acc,
        {
          value: sortNameMapper(sortItem.name),
          type: sortValueMapper(sortItem.value),
        },
      ],
      []
    )

  const allFilterExpressions = {
    ...filterExpressions,
    ...tabExpression,
  }

  // TODO: KLUDGE
  if (allFilterExpressions['creator.email'] != null || allFilterExpressions['creator.id'] != null) {
    sortExpressions = sortExpressions.filter(
      (sortRequestItem) => sortRequestItem.value !== 'creator.email'
    )
  }

  if (isEmpty(filterExpressions['system.id'])) {
    // TODO: KLUDGE
    sortExpressions.unshift({
      value: 'system',
      type: SortRequestType.DESC,
    })
  }

  return {
    filters: allFilterExpressions,
    sortList: sortExpressions,
    pageNumber: tableState.pages?.currentPage ?? 1,
    pageSize: tableState.pages?.pageSize ?? 20,
  }
}
