import {TypeTokenValue, typeTokenValues, typography} from '@x5-react-uikit/tokens'
import {SnackbarContentProps} from './types'
import {makeUiStyles, BaseThemeValues} from '../theme'

function getColorByType(theme: BaseThemeValues, type: TypeTokenValue): string {
  return {
    default: theme.colors.grey[90],
    error: theme.colors.orange[50],
    warning: theme.colors.yellow[90],
    success: theme.colors.green[60],
  }[type]
}

export const useSnackbarStyles = makeUiStyles(
  (theme) => ({
    root: {
      ...typography.p1compact,
      boxShadow: 'none',
      backgroundColor: 'transparent',
      overflow: 'hidden',
      pointerEvents: 'auto',
      alignItems: 'stretch',
      width: 'fit-content',
      marginTop: theme.spaces.x4,
      minHeight: 48,
      '&&': {
        padding: 0,
      },
      '& #notistack-snackbar': {
        width: '100%',
        alignItems: 'stretch',
        padding: 0,
      },
    },
  }),
  'snackbar'
)

export const useSnackbarContentStyles = makeUiStyles(
  (theme) => ({
    root: {
      zIndex: 2,
      maxWidth: ({maxWidth}: SnackbarContentProps) => maxWidth || 'none',

      '&::before': {
        content: '""',
        height: '100%',
        position: 'absolute',
        width: theme.spaces.x4,
        top: 0,
        left: 0,
        borderTopLeftRadius: theme.spaces.x2,
        borderBottomLeftRadius: theme.spaces.x2,
        backgroundColor: ({type}: SnackbarContentProps) => getColorByType(theme, type),
      },
    },
    wrapper: {
      position: 'relative',
      alignItems: 'center',
      display: 'flex',
      padding: `14px ${theme.spaces.x8}px 14px ${theme.spaces.x6}px`,
      marginLeft: theme.spaces.x2,
      borderTopLeftRadius: theme.spaces.x2,
      borderBottomLeftRadius: theme.spaces.x2,
      backgroundColor: theme.colors.grey[90],
    },
    wrapperSmallPadding: {
      padding: theme.spaces.join('x4', 'x8', 'x4', 'x6'),
      minHeight: 48,
      boxSizing: 'border-box',
    },
    title: {
      marginBottom: theme.spaces.x2,
      color: ({type}: SnackbarContentProps) =>
        type === typeTokenValues.default ? theme.colors.white : getColorByType(theme, type),
      ...theme.typography.h4,
    },
    text: {
      width: '100%',
    },
    left: {
      display: 'flex',
      alignSelf: ({title, action}: SnackbarContentProps) =>
        title || action?.nextLine ? 'flex-start' : 'center',
      marginRight: theme.spaces.x4,
      color: ({type}: SnackbarContentProps) =>
        type === typeTokenValues.default ? theme.colors.white : getColorByType(theme, type),
    },
    right: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    actions: {
      display: 'flex',
      alignSelf: 'flex-start',
    },
    actionsWithUserAction: {
      alignItems: 'center',
    },
    alignActions: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    message: {
      wordBreak: 'break-all',
      alignItems: 'center',
      whiteSpace: 'pre-line',
      ...typography.p1,
    },
    close: {
      marginLeft: theme.spaces.x8,
    },
    userAction: {
      marginLeft: theme.spaces.x8,
    },
    userActionNextLine: {
      marginLeft: 'auto',
      marginTop: theme.spaces.x8,
    },
  }),
  'snackbar-content'
)

export default useSnackbarStyles
