import {sizeTokenValues} from '@x5-react-uikit/tokens'
import {ModalProps} from './types'

export function fixBodyPosition(isOpen: boolean): void {
  if (isOpen) {
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y')
    document.body.style.position = 'fixed'
    document.body.style.top = `-${scrollY}`
    document.body.style.left = '0'
    document.body.style.right = '0'
  } else {
    const scrollY = document.body.style.top
    document.body.style.position = ''
    document.body.style.top = ''
    document.body.style.left = ''
    document.body.style.right = ''
    window.scrollTo(0, parseInt(scrollY || '0') * -1)
  }
}

export function getWidthWithoutPadding(element: HTMLElement): number {
  const {paddingLeft, paddingRight} = window.getComputedStyle(element)
  const {clientWidth} = element

  return clientWidth ? element.clientWidth - parseFloat(paddingLeft) - parseFloat(paddingRight) : 0
}

export function getWidthBySize(size: ModalProps['size']): number {
  switch (size) {
    case sizeTokenValues.small:
      return 440
    case sizeTokenValues.medium:
      return 620
    case sizeTokenValues.large:
      return 920
    default:
      return size as number
  }
}

export function setScrollPosition(): void {
  document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`)
}
