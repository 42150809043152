import {Dayjs} from 'dayjs'
import {QA} from '../types'
import {CalendarDay} from './Day/types'

export type CalendarDisabledDateInput = string | number | {value: string | number; tooltip?: string}
export type CalendarDisabledDateOutput = {value: number | Dayjs | Dayjs[]; tooltip?: string}

export enum freezeRangeValues {
  start = 'start',
  end = 'end',
}

export interface CalendarProps extends QA {
  date: CalendarDay | CalendarDay[]
  onChange: (formatted?: string | string[], date?: CalendarProps['date']) => void
  onChangeViewDate?: (formatted?: string, date?: CalendarProps['date']) => void

  viewDate?: CalendarDay
  minDate?: CalendarDay
  maxDate?: CalendarDay

  freezeRange?: keyof typeof freezeRangeValues
  long?: boolean
  dateFormat?: string
  disabledDates?: CalendarDisabledDateInput[]
  changeViewDateOnChangeDate?: boolean
  className?: string
}
