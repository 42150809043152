import {makeUiStyles} from '../../theme'
import {TableBlockedZIndexes} from '../styles'
import {TableStyleHelpers} from '../Cell/styles'

export const useStyles = makeUiStyles(
  (theme) => ({
    root: {
      ...TableStyleHelpers.getBaseCellRoot(theme),
      fontSize: 0,
      padding: 0,
      borderLeft: 'none',
      borderRight: 'none',
    },
    blocked: {
      ...TableStyleHelpers.getCellBlocked(theme),
      top: 0,
      zIndex: TableBlockedZIndexes.top,
    },
    blockedPrimary: {
      zIndex: TableBlockedZIndexes.cross,
    },
    blockedBodyCell: {
      top: 'auto',
      zIndex: TableBlockedZIndexes.left,
    },
  }),
  'table-stick-cell'
)
