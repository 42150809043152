import React, {
  CSSProperties,
  forwardRef,
  MouseEventHandler,
  PropsWithChildren,
  useState,
} from 'react'
import {sizeTokenValues} from '@x5-react-uikit/tokens'
import {DataGridBase} from '../../kit'

import {TableContext} from '../context'
import TableHeadCell from '../headCell/DataGridHeadCell'
import TableBodyCell from '../bodyCell/DataGridBodyCell'
import {StyledTable} from './styles'

export const tableSizeValues = sizeTokenValues

type Props = PropsWithChildren<{
  size?: sizeTokenValues
  unborder?: boolean
  unstable_stickedColumnCount?: number
  onClick?: MouseEventHandler<HTMLTableElement>
  styles?: CSSProperties
}>

const DataGridTable = forwardRef<HTMLTableElement, Props>(
  (
    {
      children,
      size = tableSizeValues.small,
      unborder = false,
      unstable_stickedColumnCount: stickedColumnCount,
      onClick,
      styles,
    },
    ref
  ) => {
    const [currentCellId, setCurrentCellId] = useState(null)
    const handleCurrentIdChange = (id) => setCurrentCellId(id)

    return (
      <TableContext.Provider
        value={{_setCurrentCellId: handleCurrentIdChange, _currentCellId: currentCellId}}
      >
        <StyledTable
          ref={ref}
          data-size={size}
          unstable_stickedColumnCount={stickedColumnCount}
          {...{size, unborder, onClick}}
          otherStyles={styles}
        >
          {children}
        </StyledTable>
      </TableContext.Provider>
    )
  }
)

export type DataGridTableComponent = typeof DataGridTable & {
  Section: typeof DataGridBase.Section
  Head: typeof DataGridBase.Head
  Row: typeof DataGridBase.Row
  HeadCell: typeof TableHeadCell
  Cell: typeof TableBodyCell
}

const Enhanced: DataGridTableComponent = DataGridTable as DataGridTableComponent

Enhanced.Section = DataGridBase.Section
Enhanced.Head = DataGridBase.Head
Enhanced.Row = DataGridBase.Row
Enhanced.HeadCell = TableHeadCell
Enhanced.Cell = TableBodyCell

export default Enhanced
