import {Link, Typography, baseTheme} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import Section from '@root/components/Section'
import {useParams} from 'react-router-dom'
import {useGetConfigurationByIdQuery} from '@root/redux/api/reportConfigurationsApi'

const {colors} = baseTheme

const ConfDateAndTasks = () => {
  const {configurationId} = useParams()
  const {data: configuration} = useGetConfigurationByIdQuery({configurationId})

  const handleOpenKaiten = () => {
    window.open(configuration.kaitenCardLink, '_blank')
  }

  return (
    <Section sx={{my: 0, width: '588px'}}>
      <FlexboxColumn sx={{gap: '24px'}}>
        <Typography variant="h3">Дата и задачи</Typography>
        <FlexboxColumn sx={{width: '544px', gap: '4px'}}>
          <Typography>Дата</Typography>
          <Typography style={{color: colors.grey[60]}}>
            Автоматически при формировании отчета
          </Typography>
        </FlexboxColumn>
        <FlexboxColumn sx={{width: '544px', gap: '4px'}}>
          <Typography>Задачи в Kaiten</Typography>
          {configuration?.kaitenCardLink ? (
            <Link style={{width: 'fit-content'}} onClick={handleOpenKaiten}>
              Перейти к связанным задачам
            </Link>
          ) : (
            '-'
          )}
        </FlexboxColumn>
      </FlexboxColumn>
    </Section>
  )
}

export default ConfDateAndTasks
