import {Text, type TextProps} from '@react-pdf/renderer'
import {fontX5Ui} from './constants'
import {combineStyles, withLineHeight} from '@root/pdf-components/utils'
import {omit} from 'lodash'
import type {FC} from 'react'
import type {Style} from '@react-pdf/types'

const styles: Record<string, Style> = {
  h1: withLineHeight(28, 36, {
    color: '#222626',
    fontWeight: 500,
    fontFamily: fontX5Ui,
  }),
  h2: withLineHeight(20, 24, {
    color: '#222626',
    fontWeight: 500,
    letterSpacing: '0.12px',
    fontFamily: fontX5Ui,
  }),
}

export type TitleLevel = 'h1' | 'h2'

type Props = TextProps & {
  level: TitleLevel
}

export const Title: FC<Props> = ({level, children, ...other}) => {
  const style = styles[level]

  if (style == null) {
    throw new Error(`Invalid level prop`)
  }

  return (
    <Text {...omit(other, 'style')} style={combineStyles(style, other.style)}>
      {children}
    </Text>
  )
}
