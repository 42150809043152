import React, {forwardRef, useState} from 'react'
import {sizeTokenValues} from '@x5-react-uikit/tokens'
import {TableContext} from './context'
import {tableQAValue} from '../helpers'
import {DataGridTableProps} from './types'
import {StyledTable} from '../styles'

export const dataGridSizeValues = sizeTokenValues

export const DataGridTable = forwardRef<HTMLTableElement, DataGridTableProps>(
  (
    {
      children,
      size = dataGridSizeValues.small,
      unborder = false,
      unstable_stickedColumnCount: stickedColumnCount,
      qa = tableQAValue,
      onClick,
    },
    ref
  ) => {
    const [currentCellId, setCurrentCellId] = useState(null)
    const handleCurrentIdChange = (id: string) => setCurrentCellId(id)

    return (
      <TableContext.Provider
        value={{_setCurrentCellId: handleCurrentIdChange, _currentCellId: currentCellId}}
      >
        <StyledTable
          ref={ref}
          data-qa={qa}
          data-size={size}
          unstable_stickedColumnCount={stickedColumnCount}
          {...{size, unborder, onClick}}
        >
          {children}
        </StyledTable>
      </TableContext.Provider>
    )
  }
)

export default DataGridTable
