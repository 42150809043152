import React from 'react'
import {
  ArrowDown as IconArrow,
  Filter as IconFilter,
  SortAZ as IconSortUp,
  SortZA as IconSortDown,
} from '@x5-react-uikit/icons'
import {TableMenuIconType} from '../types'
import {sizeTokenValues} from '@x5-react-uikit/tokens'

interface Props {
  classes: Record<string, string>
  menuIcon: TableMenuIconType
}

const iconSize = sizeTokenValues.small
const activeColor = '#1565C0'

export const TableHeadCellMenuIcon = ({classes, menuIcon}: Props): JSX.Element => {
  let icon

  switch (menuIcon) {
    case TableMenuIconType.arrow:
      icon = <IconArrow color="#616161" size={iconSize} />
      break

    case TableMenuIconType.filter:
      icon = <IconFilter color={activeColor} size={iconSize} />
      break

    // case TableMenuIconType.sort:
    //     icon = <IconSort color={activeColor} size={iconSize} />
    //     break

    case TableMenuIconType.sortUp:
      icon = <IconSortUp color={activeColor} size={iconSize} />
      break

    case TableMenuIconType.sortDown:
      icon = <IconSortDown color={activeColor} size={iconSize} />
      break

    case TableMenuIconType.none:
    default:
      icon = null
      break
  }

  return <div className={classes.arrow}>{icon}</div>
}

export default TableHeadCellMenuIcon
