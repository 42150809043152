import React, {Ref} from 'react'
import clsx from 'clsx'
import {ArrowDown} from '@x5-react-uikit/icons'
import {BodyCellInnerAutocompleteViewProps, EMPTY_VALUE} from './types'
import {BodyCellEditIcon} from '../CellEditIcon'
import {defaultCellDensity, getCellDensityClassNameBy} from '../../helpers'
import {TableCellLabel} from '../../CellLabel'
import {useBaseCellStyles} from '../../Cell/styles'
import {useInnerAutocompleteStyles} from './styles'

export const defaultRenderValue = (selected, classes) => {
  if (selected) {
    if (typeof selected === 'object' && selected !== null) {
      return selected?.key ? (
        <>
          {selected.value} <span className={classes.secondaryLabel}>({selected.key})</span>
        </>
      ) : (
        ''
      )
    } else {
      return selected
    }
  } else {
    return EMPTY_VALUE
  }
}

const BodyCellInnerAutocompleteViewBase = (
  props: BodyCellInnerAutocompleteViewProps,
  ref: Ref<HTMLDivElement>
) => {
  const {
    density = defaultCellDensity,
    noWrap,
    editable,
    onStartEdit,
    children: selected,
    renderValue = defaultRenderValue,
  } = props

  const baseClasses = useBaseCellStyles()
  const innerClasses = useInnerAutocompleteStyles()
  const densityClassName = baseClasses[getCellDensityClassNameBy(density)]

  return (
    <div
      ref={ref}
      className={clsx(baseClasses.innerRoot, innerClasses.innerRootView, densityClassName)}
    >
      <TableCellLabel bodyCell density={density} noWrap={noWrap}>
        {renderValue(selected, innerClasses)}
      </TableCellLabel>
      {editable && <BodyCellEditIcon Icon={ArrowDown} type="autocomplete" onClick={onStartEdit} />}
    </div>
  )
}

export const BodyCellInnerAutocompleteView = React.forwardRef(BodyCellInnerAutocompleteViewBase)

export default BodyCellInnerAutocompleteView
