import {sizeTokenValues, SizeTokenValue} from '@x5-react-uikit/tokens'
import {CSSObject} from '@emotion/styled'
import {BaseThemeValues} from '../theme'
import {cellModeValues, DataGridCellProps} from './types'
import {getQaAttribute} from '../utils'

export const isNestedCell = (mode: DataGridCellProps['mode']): boolean =>
  mode === cellModeValues.text || mode === cellModeValues.number

export const getBorderColorTable = (colors: BaseThemeValues['colors']): CSSObject['borderColor'] =>
  colors.grey[10]

export const getBorderStyleTable = (
  colors: BaseThemeValues['colors'],
  width: string | number = 1
): CSSObject => ({
  borderColor: getBorderColorTable(colors),
  borderStyle: 'solid',
  borderWidth: width,
})

const widthStickedColumn = 120

const getStyleStickedColumn = (colors: BaseThemeValues['colors']): CSSObject => ({
  position: 'sticky',
  backgroundColor: colors.white,
  minWidth: widthStickedColumn,
  maxWidth: widthStickedColumn,
  '&::after': {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: -1,
    content: '""',
    width: 1,
    height: '100%',
    backgroundColor: getBorderColorTable(colors),
  },
})

export const getStickedColumnSelector = (
  colors: BaseThemeValues['colors'],
  columnCount: number
): CSSObject => {
  const offset = widthStickedColumn
  const shiftStickedColumn = 1
  let left = -shiftStickedColumn
  const css = {}
  for (let i = 1; i <= columnCount; i++) {
    css[`& td:nth-of-type(${i}), & th:nth-of-type(${i})`] = {
      ...getStyleStickedColumn(colors),
      left,
    }
    left = offset * i - shiftStickedColumn
  }

  return css
}

export const getPaddingTableCell = (
  size: SizeTokenValue,
  spaces: BaseThemeValues['spaces']
): CSSObject['padding'] => (size === sizeTokenValues.small ? spaces.x4 : spaces.x6)

export const tableQAValue = 'table'
const cellQAValue = `${tableQAValue}-cell`

export const getTableQA = getQaAttribute(tableQAValue)
export const getCellQA = getQaAttribute(cellQAValue)
