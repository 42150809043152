import React, {Ref} from 'react'
import clsx from 'clsx'
import {BodyCellInnerSelectViewProps} from './types'
import {BodyCellEditIcon} from '../CellEditIcon'
import {defaultCellDensity, getCellDensityClassNameBy} from '../../helpers'
import {TableCellLabel} from '../../CellLabel'
import {useBaseCellStyles} from '../../Cell/styles'

const BodyCellInnerSelectViewBase = (
  props: BodyCellInnerSelectViewProps,
  ref: Ref<HTMLDivElement>
) => {
  const {
    density = defaultCellDensity,
    noWrap,
    editable,
    onStartEdit,
    Icon,
    options,
    children: value,
  } = props

  const found = (options || []).find((item) => item.value === value)
  const renderValue = found ? found.name : ''

  const baseClasses = useBaseCellStyles()
  const densityClassName = baseClasses[getCellDensityClassNameBy(density)]

  return (
    <div ref={ref} className={clsx(baseClasses.innerRoot, densityClassName)}>
      <TableCellLabel bodyCell density={density} noWrap={noWrap}>
        {renderValue}
      </TableCellLabel>
      {editable && <BodyCellEditIcon Icon={Icon} type="select" onClick={onStartEdit} />}
    </div>
  )
}

export const BodyCellInnerSelectView = React.forwardRef(BodyCellInnerSelectViewBase)

export default BodyCellInnerSelectView
