import {makeUiStyles} from '../../theme'
import {TableStyleHelpers} from '../Cell/styles'
import {getTableRowHoveredJCSS} from '../styles'

export const useStyles = makeUiStyles(
  (theme) => ({
    root: {
      display: 'table',
      width: '100%',
      boxSizing: 'border-box',
      borderSpacing: 0,
      borderCollapse: 'collapse',
      '& [role*="table-row"]:first-of-type [role*="table-cell"]': {
        borderTopColor: 'transparent',
      },
      '& [role*="table-row"]:last-of-type [role*="table-cell"]': {
        borderBottomColor: 'transparent',
      },
      '& [role*="table-row"] [role*="table-cell"]:first-of-type': {
        borderLeftColor: 'transparent',
      },
      '& [role*="table-row"] [role*="table-cell"]:last-of-type': {
        borderRightColor: 'transparent',
      },
      '& [role*="table-stick-cell"] + [role*="table-cell"]': {
        borderLeftColor: 'transparent',
      },
      '& > *': {
        fontSize: 0,
        lineHeight: 0,
        letterSpacing: 'normal',
        wordSpacing: 'normal',
        boxSizing: 'border-box',
        '& > *::before': {
          display: 'none',
        },
        '& > *::after': {
          display: 'none',
        },
      },
    },
    fixed: {
      tableLayout: 'fixed',
    },
    framed: {
      position: 'relative',
      // we use before because can't use border at main element
      '&:before': {
        content: "''",
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        border: '1px solid',
        borderColor: TableStyleHelpers.getBorderColor(theme),
        borderRadius: theme.spaces.x2,
        pointerEvents: 'none',
      },
      '& [role*="table-row"]:first-of-type': {
        '& [role*="table-cell"]:first-of-type': {
          borderTopLeftRadius: theme.spaces.x2,
        },
        '& [role*="table-cell"]:last-of-type': {
          borderTopRightRadius: theme.spaces.x2,
        },
      },
      '& [role*="table-row"]:last-of-type': {
        '& [role*="table-cell"]:first-of-type': {
          borderBottomLeftRadius: theme.spaces.x2,
        },
        '& [role*="table-cell"]:last-of-type': {
          borderBottomRightRadius: theme.spaces.x2,
        },
      },
    },
  }),
  'table'
)
