import React, {forwardRef} from 'react'
import {getQaAttribute} from '../utils'
import {RadioProps} from './types'
import {StyledRadio, radioClasses} from './styles'

export const Radio = forwardRef<HTMLLabelElement, RadioProps>(
  ({label, error, checked, disabled, className, qa = 'radio', ...props}, ref) => {
    const getQA = getQaAttribute(qa)

    return (
      <StyledRadio
        ref={ref}
        checked={checked}
        className={className}
        data-qa={getQA()}
        disabled={disabled}
        error={error}
      >
        <input
          checked={checked}
          className={radioClasses.input}
          data-qa={getQA('field', [checked && 'checked', disabled && 'disabled'])}
          disabled={disabled}
          type="radio"
          {...props}
        />
        <span className={radioClasses.label}>{label}</span>
      </StyledRadio>
    )
  }
)

export default Radio
