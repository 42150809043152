import {Typography} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import Section from '@root/components/Section'
import {useParams} from 'react-router-dom'
import {useGetReportByIdQuery} from '@root/redux/api/launchesApi'

const ReportInfo = () => {
  const {reportId} = useParams()
  const {data: report} = useGetReportByIdQuery(reportId)

  return (
    <Section sx={{mb: 0, mt: 'x12'}}>
      <FlexboxColumn sx={{gap: '24px'}}>
        <Typography variant="h3">Данные отчета</Typography>
        <FlexboxRow sx={{gap: '64px'}}>
          <FlexboxColumn sx={{width: '544px', gap: '4px'}}>
            <Typography>Наименование отчета</Typography>
            <Typography>{report?.name || '-'}</Typography>
          </FlexboxColumn>
          <FlexboxColumn sx={{width: '544px', gap: '4px'}}>
            <Typography>Конфигурация</Typography>
            <Typography>{report?.reportConfigurationName || '-'}</Typography>
          </FlexboxColumn>
        </FlexboxRow>
      </FlexboxColumn>
    </Section>
  )
}

export default ReportInfo
