import React, {FC} from 'react'
import {defineColors} from '../utils/defineColors'
import {LogoProps} from '../types'

const primary = '#5faf2d'
const getPathColor = defineColors('#000000')

const Logo: FC<LogoProps> = ({color, ...props}) => (
  <svg fill="none" viewBox="0 0 566 100" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M173.617 98.9194V45.791H193.943C210.098 45.791 214.496 53.4567 214.496 61.7285C214.496 69.3173 209.187 75.1626 201.527 76.7558L218.516 98.9177H210.098L194.324 78.122H180.52V98.9177H173.617V98.9194ZM180.518 52.2425V71.6722H194.17C202.134 71.6722 207.291 67.8009 207.291 61.7302C207.291 56.2657 203.878 52.2442 193.714 52.2442H180.518V52.2425Z"
      fill={getPathColor(color)}
    />
    <path
      d="M221.248 79.1077C221.248 67.3437 229.97 58.5391 241.726 58.5391C253.407 58.5391 261.444 66.4318 261.444 77.9687C261.444 79.1077 261.369 80.397 261.292 81.384H227.769C228.147 88.4417 233.989 93.6023 241.724 93.6023C247.489 93.6023 251.128 91.5531 254.162 86.6948L259.927 88.8191C256.741 95.8017 250.446 99.5961 241.724 99.5961C229.668 99.6012 221.248 91.1757 221.248 79.1077ZM228.074 75.3902H254.922C254.695 69.3946 248.854 64.5363 241.726 64.5363C234.598 64.5363 228.909 69.0138 228.074 75.3902Z"
      fill={getPathColor(color)}
    />
    <path
      d="M267.741 69.5485C270.244 62.641 276.539 58.7715 285.032 58.7715C295.727 58.7715 301.111 64.3879 301.111 75.6209V98.92H294.741V92.0894C292.693 96.3398 286.625 99.6031 280.254 99.6031C272.063 99.6031 266.528 94.8985 266.528 87.8391C266.528 78.8073 274.34 75.392 294.741 75.392C294.741 68.1055 291.631 64.767 284.881 64.767C278.964 64.767 275.097 67.1202 273.504 71.6745L267.741 69.5485ZM294.741 82.0707V80.6277C278.51 80.6277 273.202 82.4498 273.202 87.8374C273.202 91.4047 276.387 93.6058 281.544 93.6058C289.28 93.6058 294.741 88.8244 294.741 82.0707Z"
      fill={getPathColor(color)}
    />
    <path d="M311.578 43.5137H317.948V98.9184H311.578V43.5137Z" fill={getPathColor(color)} />
    <path
      d="M344.494 45.791H382.719V52.2425H351.395V68.7111H380.595V75.1626H351.395V92.4662H383.402V98.9177H344.494V45.791Z"
      fill={getPathColor(color)}
    />
    <path
      d="M392.429 69.8505C392.429 63.1719 398.193 58.7695 407.067 58.7695C415.866 58.7695 422.084 62.791 423.676 69.5465L417.912 71.6708C416.623 67.0414 412.678 64.5363 406.915 64.5363C402.289 64.5363 399.103 66.5854 399.103 69.5465C399.103 78.5783 423.449 72.6578 423.449 88.0643C423.449 95.122 417.61 99.5994 408.432 99.5994C398.649 99.5994 392.429 95.957 390.229 88.8225L395.994 86.6981C397.89 91.4044 401.91 93.8327 407.977 93.8327C413.664 93.8327 416.775 91.7835 416.775 88.1411C416.775 78.8071 392.429 84.4235 392.429 69.8505Z"
      fill={getPathColor(color)}
    />
    <path
      d="M428.379 59.4513H435.053V49.3574H441.423V59.4513H452.269V65.4469H441.423V87.6088C441.423 92.0111 442.865 93.6043 446.58 93.6043C448.249 93.6043 450.22 93.3004 452.268 92.7693V98.7649C450.295 99.2208 447.338 99.5999 444.986 99.5999C437.857 99.5999 435.051 96.1846 435.051 87.0009V65.4469H428.377V59.4513H428.379Z"
      fill={getPathColor(color)}
    />
    <path
      d="M458.793 69.5485C461.296 62.641 467.59 58.7715 476.085 58.7715C486.779 58.7715 492.163 64.3879 492.163 75.6209V98.92H485.793V92.0894C483.745 96.3398 477.677 99.6031 471.306 99.6031C463.115 99.6031 457.58 94.8985 457.58 87.8391C457.58 78.8073 465.392 75.392 485.793 75.392C485.793 68.1055 482.683 64.767 475.933 64.767C470.016 64.767 466.149 67.1202 464.556 71.6745L458.793 69.5485ZM485.793 82.0707V80.6277C469.562 80.6277 464.254 82.4498 464.254 87.8374C464.254 91.4047 467.44 93.6058 472.597 93.6058C480.332 93.6058 485.793 88.8244 485.793 82.0707Z"
      fill={getPathColor(color)}
    />
    <path
      d="M497.322 59.4513H503.996V49.3574H510.366V59.4513H521.212V65.4469H510.366V87.6088C510.366 92.0111 511.808 93.6043 515.523 93.6043C517.192 93.6043 519.163 93.3004 521.211 92.7693V98.7649C519.238 99.2208 516.281 99.5999 513.929 99.5999C506.799 99.5999 503.994 96.1846 503.994 87.0009V65.4469H497.32V59.4513H497.322Z"
      fill={getPathColor(color)}
    />
    <path
      d="M525.536 79.1077C525.536 67.3437 534.258 58.5391 546.014 58.5391C557.695 58.5391 565.732 66.4318 565.732 77.9687C565.732 79.1077 565.657 80.397 565.58 81.384H532.059C532.437 88.4417 538.279 93.6023 546.014 93.6023C551.779 93.6023 555.418 91.5531 558.453 86.6948L564.217 88.8191C561.031 95.8017 554.736 99.5961 546.014 99.5961C533.955 99.6012 525.536 91.1757 525.536 79.1077ZM532.362 75.3902H559.21C558.983 69.3946 553.142 64.5363 546.014 64.5363C538.884 64.5363 533.197 69.0138 532.362 75.3902Z"
      fill={getPathColor(color)}
    />
    <path
      d="M93.5484 98.9189L82.5503 80.6266L71.7791 98.9189H58.885L75.4497 71.366L58.5659 45.7871H72.0367L82.8523 62.8653L93.1679 45.7871H106.062L89.4529 72.201L106.518 98.9189H93.5484Z"
      fill={getPathColor(color)}
    />
    <path
      d="M148.432 80.8579C148.432 92.5467 139.633 99.8333 128.104 99.8333C117.941 99.8333 109.384 94.2168 108.55 81.9969H119.548C120.306 87.3094 124.555 89.5874 129.181 89.5874C134.945 89.5874 137.434 85.7161 137.434 80.7076C137.434 75.4702 133.565 72.2069 128.179 72.2069C123.705 72.2069 120.746 74.3329 119.381 76.6092H108.869L112.502 45.793H147.078L145.925 56.1157H121.191L120.208 66.3138C120.208 66.3138 122.806 63.252 130.679 63.252C140.164 63.2486 148.432 69.6215 148.432 80.8579Z"
      fill={getPathColor(color)}
    />
    <path
      d="M109.852 39.1739C103.992 16.3188 85.4051 0.261719 46.8322 0.261719H0.261108L6.58356 43.0401C11.3395 81.6398 26.0919 94.0578 52.199 98.6309C52.3202 98.6497 53.1478 98.7675 53.1581 97.7993C53.1615 97.4509 52.9414 97.1504 52.6393 97.0206C34.9928 89.5855 32.9791 65.202 29.2096 40.7365L26.2932 21.2368H52.8782C77.3882 21.2368 96.3129 20.8492 108.294 39.7836C108.471 40.05 108.75 40.2464 109.079 40.2464C109.541 40.2464 109.886 39.8707 109.886 39.4079C109.886 39.3242 109.874 39.2457 109.852 39.1739Z"
      fill={getPathColor(color, primary)}
    />
  </svg>
)

export default Logo
