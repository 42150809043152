import {Modal, Typography} from '@x5-react-uikit/core'
import useNotify from '@root/hooks/useNotify'
import {useDeleteReportMutation} from '@root/redux/api/launchesApi'
import {FC} from 'react'

type Props = {
  reportId: string
  onClose: () => void
}
const DeleteReportModal: FC<Props> = ({reportId, onClose}) => {
  const {notifySuccess, notifyError} = useNotify()

  const [deleteReport] = useDeleteReportMutation()
  const handleDeleteReport = () => {
    deleteReport(reportId)
      .unwrap()
      .then(() => {
        notifySuccess('Отчёт удалён')
        onClose()
      })
      .catch(() => {
        notifyError()
        onClose()
      })
  }

  return (
    <>
      <Modal
        closeOnOverlay
        buttons={[
          {
            onClick: onClose,
            side: 'right',
            text: 'Отмена',
          },
          {
            onClick: handleDeleteReport,
            side: 'right',
            text: 'Удалить',
          },
        ]}
        isOpen={!!reportId}
        size="medium"
        title="Удаление шаблона"
        onClose={onClose}
      >
        <Typography variant="p1compact">
          Вы действительно хотите удалить отчет? Отчет не подлежит восстановлению. Можно будет
          создать отчет повторно.
        </Typography>
      </Modal>
    </>
  )
}

export default DeleteReportModal
