import {components} from '@root/openapi'

export enum templatesTabKind {
  all,
  mine,
}

export type Metadata = components['schemas']['MetaDataResponse']

export type Template = components['schemas']['VariablesTemplateResponse']
