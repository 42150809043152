import {Style} from '@react-pdf/types'
import {concat} from 'lodash'

type PdfStyle = Style | Style[]

export function combineStyles(...styles: PdfStyle[]): PdfStyle {
  return concat(styles).reduce((acc, next) => Object.assign(acc, next ?? {}), {})
}

export function withLineHeight(fontSizePx: number, lineHeightPx: number, style: Style) {
  const lhPercent = lineHeightPx / fontSizePx
  const mt = (fontSizePx * (lhPercent - 1)) / 2

  return {
    ...style,
    marginTop: `${mt}px`,
    fontSize: `${fontSizePx}px`,
    lineHeight: lhPercent,
    marginBottom: `-${mt}px`,
  }
}
