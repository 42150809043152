import {BannerProps} from './types'
import {makeUiStyles, BaseThemeValues} from '../theme'

function getColorByType(theme: BaseThemeValues, type: BannerProps['type']): string {
  return {
    default: theme.colors.accent[10],
    error: theme.colors.red[10],
    warning: theme.colors.amber[10],
    success: theme.colors.green[10],
    grey: theme.colors.grey[10],
  }[type]
}

function getBorderColorByType(theme: BaseThemeValues, type: BannerProps['type']): string {
  return {
    default: theme.colors.accent[30],
    error: theme.colors.red[30],
    warning: theme.colors.amber[50],
    success: theme.colors.green[40],
    grey: theme.colors.grey[40],
  }[type]
}

function getIconColorByType(theme: BaseThemeValues, type: BannerProps['type']): string {
  return {
    default: theme.colors.accent[80],
    error: theme.colors.error,
    warning: theme.colors.amber[90],
    success: theme.colors.green[80],
    grey: theme.colors.grey[60],
  }[type]
}

export const useBannerStyles = makeUiStyles(
  (theme: BaseThemeValues) => ({
    root: {
      position: 'relative',
      maxWidth: ({maxWidth}: BannerProps) => maxWidth || 'none',
      backgroundColor: ({type}: BannerProps) => getColorByType(theme, type),
      border: ({type}: BannerProps) => `1px solid ${getBorderColorByType(theme, type)}`,
      borderRadius: 8,
    },
    wrapper: {
      position: 'relative',
      alignItems: 'center',
      display: 'flex',
      padding: theme.spaces.join('x6'),
      paddingLeft: ({icon}: BannerProps) => (icon ? null : theme.spaces.join('x10')),
    },
    titleWrapper: {
      marginBottom: theme.spaces.x3,
    },
    title: {
      alignSelf: 'center',
      wordBreak: 'break-all',
    },
    content: {
      width: '100%',
      ...theme.typography.p1,
    },
    left: {
      display: 'flex',
      alignSelf: 'flex-start',
      marginRight: theme.spaces.x4,
      color: ({type}: BannerProps) => getIconColorByType(theme, type),
    },
    right: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    actions: {
      display: 'flex',
      alignSelf: 'flex-start',
      flexShrink: 0,
      minHeight: 24,
    },
    hideActions: {
      visibility: 'hidden',
    },
    actionsWithUserAction: {
      alignItems: 'center',
      marginLeft: theme.spaces.x4,
    },
    alignActions: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      flexShrink: 0,
    },
    messageWrapper: {
      wordBreak: 'break-all',
      alignItems: 'center',
    },
    message: {
      flexShrink: 1,
    },
    close: {
      right: 0,
      height: 24,
      minWidth: 24,
      position: 'absolute',
    },
    shield: {
      width: 24,
      height: 24,
      marginLeft: theme.spaces.x4,
    },
    userActionNextLine: {
      marginRight: 'auto',
      marginTop: theme.spaces.x3,
    },
  }),
  'banner'
)

export default useBannerStyles
