import {createSvgIcon} from '@x5-react-uikit/icons'

const Svg = () => (
  <path
    clipRule="evenodd"
    d="M13.3334 13.3333C13.3334 13.7015 13.0349 14 12.6667 14C12.2986 14 12.0001 13.7015 12.0001 13.3333V8.94281L11.1382 9.80474C10.8778 10.0651 10.4557 10.0651 10.1953 9.80474C9.93499 9.54439 9.93499 9.12228 10.1953 8.86193L12.1953 6.86193C12.4557 6.60158 12.8778 6.60158 13.1382 6.86193L15.1382 8.86193C15.3985 9.12228 15.3985 9.54439 15.1382 9.80474C14.8778 10.0651 14.4557 10.0651 14.1953 9.80474L13.3334 8.94281V13.3333ZM4.00008 12H1.33341C0.966748 12 0.666748 11.7 0.666748 11.3333C0.666748 10.9667 0.966748 10.6667 1.33341 10.6667H4.00008C4.36675 10.6667 4.66675 10.9667 4.66675 11.3333C4.66675 11.7 4.36675 12 4.00008 12ZM1.33341 5.33333C0.966748 5.33333 0.666748 5.03333 0.666748 4.66667C0.666748 4.3 0.966748 4 1.33341 4H10.6667C11.0334 4 11.3334 4.3 11.3334 4.66667C11.3334 5.03333 11.0334 5.33333 10.6667 5.33333H1.33341ZM8.00008 8C8.00008 8.36667 7.70008 8.66667 7.33341 8.66667H1.33341C0.966748 8.66667 0.666748 8.36667 0.666748 8C0.666748 7.63333 0.966748 7.33333 1.33341 7.33333H7.33341C7.70008 7.33333 8.00008 7.63333 8.00008 8Z"
    fillRule="evenodd"
  />
)

export const SortDescIcon = createSvgIcon({'16': <Svg />, '24': <Svg />}, 'SortDescIcon')
