import {Roles, TaskStatuses} from '@root/constants'
import {ExtractConstValues} from '@root/core/helperTypes'
import {Task} from '@root/features/tasks/types'

export function hasWritePerm(
  userRole: ExtractConstValues<typeof Roles>,
  field: any,
  task: Task
): boolean {
  if (typeof field === 'object') return field.edit.includes(userRole)
  else if (field === 'budget_source') {
    if ([Roles.ADMIN, Roles.MANAGER].includes(userRole as any)) return true
    if (
      userRole === Roles.BUSINESS &&
      [TaskStatuses.DRAFT, TaskStatuses.IN_REVIEW].includes(task.status as any)
    )
      return true
  }
  return false
}
