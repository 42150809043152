import {Box, Radio} from '@x5-react-uikit/core'
import {Controller} from 'react-hook-form'

const FormInputQuestionRadio = ({name, control, boxSx, group, onSelect}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({field: {onChange, value}}) => {
        return (
          <Box sx={boxSx}>
            {group.map((radio) => (
              <Radio
                checked={radio.value === value}
                label={radio.label}
                onChange={() => {
                  onSelect(radio.value)
                  return onChange(radio.value)
                }}
              />
            ))}
          </Box>
        )
      }}
    />
  )
}

export default FormInputQuestionRadio
