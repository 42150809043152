import {Box, Button} from '@root/components/kit'
import {BarChart as BarChartIcon, Download as DownloadIcon} from '@x5-react-uikit/icons'
import FlexboxRow from '@root/components/FlexboxRow'
import {FC, useCallback, useContext, useMemo, useState} from 'react'
import {useGetReportsQuery} from '@root/redux/api/launchesApi'
import DeleteReportModal from './DeleteReportModal'
import {useGetAllRolesQuery, useGetUserinfoQuery} from '@root/redux/api/userApi'
import EmptyIconMessage from '@root/components/stubs/EmptyIconMessage'
import {
  AppTable,
  AppTableColumn,
  AppTableTab,
  ImprovedCellCopy,
  PatchFilterCollectorColumnsFn,
  SortTextKind,
  useAppTable,
} from '@root/components/appTable'
import type {Report} from '@root/features/reports/types'
import RouterLink from '@root/components/RouterLink'
import {fieldMapper} from './fileldMapper'
import {COLLAPSE_IF_SYSTEMS_COUNT} from '@root/features/systems/constants'
import {isEmpty} from 'lodash'
import {NOT_IMPLEMENTED} from '@root/utils'
import {ReportTableActionsButton} from './ReportTableActionsButton'
import {tableContext, TableContextProvider} from './context'
import {useNavigate} from 'react-router-dom'
import {EmptyCell} from '@root/components/appTable/components/EmptyCell'
import {FilterCollectorSystemsCombobox} from '@root/features/systems/components/FilterCollectorSystemsCombobox'
import FilterCollectorEditorCombobox from './FilterCollectorEditorCombobox'
import {useSelector} from 'react-redux'
import {userSelector} from '@root/redux/selectors'
import {useDownloadManyReportsPdf} from '@root/features/reports/hooks/useDownloadManyReportsPdf'

const ReportEditorCell: FC<{editor?: Report['currentEditor']}> = ({editor}) => {
  const ctx = useContext(tableContext)

  if (ctx?.rolesMap == null || editor == null) {
    return <EmptyCell />
  }

  return (
    <ImprovedCellCopy
      copiedText={editor.email}
      shownText={ctx.rolesMap?.[editor?.role] || ''}
      style={{padding: '12px'}}
      successMessage="Почта скопирована в буфер обмена"
    />
  )
}

const ReportsEmptyMessage = () => (
  <EmptyIconMessage
    message="Когда будет создан отчёт, он появится в этой таблице
             и будет доступен для редактирования."
    sx={{width: '1200px', height: '600px', backgroundColor: 'white'}}
    title="Отчёты отсутствуют"
  />
)

export enum ReportsTabKind {
  all,
  reports,
  compareReports,
}

const tabs: AppTableTab<ReportsTabKind>[] = [
  {
    label: 'Все',
    value: ReportsTabKind.all,
  },
  {
    label: 'Отчеты',
    value: ReportsTabKind.reports,
  },
  {
    label: 'Сравнительные отчеты',
    value: ReportsTabKind.compareReports,
  },
]

const baseColumns: AppTableColumn<Report>[] = [
  {
    isCheckbox: true,
    width: 40,
  },
  {
    dataIndex: 'launchId',
    title: 'Заявка',
    width: '13.33333%',
    sortable: {
      enabled: true,
      text: SortTextKind.alphabetical,
    },
    preventRowClickHandler: true,
    renderCell: (_value, row) => (
      <RouterLink to={`/tasks/${row.taskId}`}>#{row.taskNumber}</RouterLink>
    ),
  },
  {
    dataIndex: 'name',
    title: 'Название отчета',
    width: '36.666666%',
    sortable: {
      enabled: true,
      text: SortTextKind.alphabetical,
    },
    filterableOptions: {
      type: 'text',
      name: '№ заявки',
    },
  },
  {
    dataIndex: 'createdAt',
    title: 'Дата и время создания',
    valueRenderType: 'dateTime',
    width: '13.333333%',
    sortable: {
      enabled: true,
      text: SortTextKind.newestFirst,
    },
  },
  {
    dataIndex: 'updatedAt',
    title: 'Дата и время редактирования',
    valueRenderType: 'dateTime',
    width: '13.333333%',
    sortable: {
      enabled: true,
      text: SortTextKind.newestFirst,
    },
  },
  {
    dataIndex: 'currentEditor',
    title: 'Редактор',
    width: 'auto',
    sortable: {
      enabled: true,
      text: SortTextKind.alphabetical,
    },
    preventRowClickHandler: true,
    filterableOptions: {
      type: 'custom',
      customInputComponent: FilterCollectorEditorCombobox,
    },
    bodyCellStyle: {
      padding: 0,
      height: 1,
    },
    renderCell: (_value, row) => <ReportEditorCell editor={row.currentEditor} />,
  },
]

function getId(report: Report) {
  if (report.id == null) {
    throw new Error('Id was null')
  }

  return report.id
}

function reportGroupBySystem(report: Report) {
  const show = report?.system?.systemTag ?? report?.system?.systemName ?? ''
  return isEmpty(show) ? 'Без названия' : `ИС: ${show}`
}

function getDefaultOpen(rows: Report[]) {
  return rows.length < COLLAPSE_IF_SYSTEMS_COUNT
}

const patchFilterCollectorColumns: PatchFilterCollectorColumnsFn = (columns) => {
  return [
    {
      name: 'Информационная система',
      value: 'system',
      type: 'custom',
      customInputComponent: FilterCollectorSystemsCombobox,
    },
    ...columns,
  ]
}

export const ReportsTable = () => {
  const {tableState, tableProps} = useAppTable<Report, ReportsTabKind>({
    pages: {
      currentPage: 1,
      pageSize: 10,
    },
    filterValues: [],
    sort: [{
      name: 'createdAt',
      value: 'desc'
    }],
    currentTab: ReportsTabKind.all,
  })

  const navigate = useNavigate()

  const {data: rolesMap} = useGetAllRolesQuery()
  const {data: userInfo} = useGetUserinfoQuery()

  const currentUser = useSelector(userSelector)
  const customerEmail = useMemo(
    () => (userInfo?.isBusiness ? currentUser?.email : undefined),
    [currentUser?.email, userInfo?.isBusiness]
  )

  const {
    data: cachedReports,
    currentData: reports,
    isFetching: isReportsLoading,
    isError: isReportsError,
  } = useGetReportsQuery(fieldMapper(tableState, customerEmail))

  const checkedReports = useMemo(
    () =>
      (tableProps.checkedRows ?? []).map((id) => (reports?.items ?? []).find((x) => x.id === id)),
    [reports?.items, tableProps.checkedRows]
  )

  const [reportToDelete, setReportToDelete] = useState<string>()

  const handleRowClick = useCallback(
    (report: Report) => {
      navigate(`/reports/${report.id}`)
    },
    [navigate]
  )

  const {
    download: downloadPdf,
    disabled: exportDisabled,
    loading: exportLoading,
  } = useDownloadManyReportsPdf(checkedReports)

  const columns: AppTableColumn<Report>[] = useMemo(() => {
    if (userInfo.isAdmin || userInfo.isManager || userInfo.isSpecialist) {
      return [
        ...baseColumns,
        {
          key: 'actions',
          width: 40,
          title: '',
          bodyCellStyle: {
            padding: '8px 0 0 0',
            textAlign: 'center',
            verticalAlign: 'top',
          },
          preventRowClickHandler: true,
          renderCell: (_value, row) => (
            <ReportTableActionsButton currentEditor={row.currentEditor} reportId={row.id} />
          ),
        },
      ]
    }

    return baseColumns
  }, [userInfo])

  return (
    <TableContextProvider
      value={{
        onUpdate: (id) => navigate(`/reports/${id}/update`),
        onDelete: (id) => setReportToDelete(id),
        userInfo,
        rolesMap,
      }}
    >
      <Box sx={{backgroundColor: 'white'}}>
        <AppTable
          noSideBorders
          cachedData={cachedReports?.items ?? []}
          columns={columns}
          components={{
            Row: {
              style: {cursor: 'pointer'},
            },
            FilterCollector: {
              size: 'large',
            },
            HeadFlexboxRow: {
              sx: {
                py: 'x8',
                height: 'auto',
              },
            },
            Cell: {
              style: {
                padding: '12px',
              },
            },
            Head: {
              style: {
                verticalAlign: 'top',
                whiteSpace: 'unset',
              },
            },
            HeadCell: {
              style: {
                padding: '12px',
              },
            },
          }}
          customEmpty={<ReportsEmptyMessage />}
          data={reports?.items ?? []}
          error={isReportsError}
          getCheckboxRowId={getId}
          getId={getId}
          groupBy={{
            getIdentity: reportGroupBySystem,
            getDefaultOpen: (_group, rows) => getDefaultOpen(rows),
          }}
          loading={isReportsLoading}
          pagination={
            cachedReports
              ? {
                  currentPage: tableState.pages?.currentPage ?? 1,
                  pageSize: tableState.pages?.pageSize ?? 10,
                  totalCount: cachedReports.totalElements,
                  totalPages: cachedReports.totalPages,
                }
              : undefined
          }
          patchFilterCollectorColumns={patchFilterCollectorColumns}
          renderHead={({uncheckWithDividerFn}) => (
            <FlexboxRow sx={{justifyContent: 'end', gap: '16px', flex: 1}}>
              {uncheckWithDividerFn()}
              <Button
                disabled={!checkedReports?.length || exportDisabled}
                loading={exportLoading}
                startIcon={<DownloadIcon size="small" />}
                variant="text"
                onClick={downloadPdf}
              >
                Скачать
              </Button>
              <Button
                disabled={!checkedReports?.length}
                startIcon={<BarChartIcon size="small" />}
                variant="text"
                onClick={NOT_IMPLEMENTED}
              >
                В сравнение
              </Button>
            </FlexboxRow>
          )}
          style={{
            width: '1200px',
            maxWidth: '1200px',
            minHeight: 400,
          }}
          tabs={{
            current: tableState.currentTab,
            list: tabs,
          }}
          onRowClick={handleRowClick}
          {...tableProps}
        />
        <DeleteReportModal reportId={reportToDelete} onClose={() => setReportToDelete(null)} />
      </Box>
    </TableContextProvider>
  )
}

export default ReportsTable
