import React, {FC} from 'react'
import {Typography} from '@x5-react-uikit/core'
import clsx from 'clsx'
import {useStyles} from './styles'

const Week: FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'].map((item, i) => (
        <Typography key={i} className={clsx(classes.day, i > 4 && classes.holiday)} variant="p1">
          {item}
        </Typography>
      ))}
    </div>
  )
}

export {Week}
export default Week
