import {createApi} from '@reduxjs/toolkit/query/react'
import redirectLoginOn401Query from './queries/redirectLoginOn401Query'
import {OpenApiRequestBody, OpenApiResponseType} from '@root/core/helperTypes'

const tags = ['Configuration']

const basePath = '/reports/configurations'

type BasePath = `/v1/reports/configurations`

type GetConfigurationsResponse = OpenApiResponseType<`${BasePath}/list`, 'post'>
type GetConfigurationByIdResponse = OpenApiResponseType<`${BasePath}/{configurationId}`>
type CreateReportConfigurationResponse = OpenApiResponseType<`${BasePath}`, 'post'>
type UpdateReportConfigurationResponse = OpenApiResponseType<
  `${BasePath}/{configurationId}`,
  'patch'
>
type DeleteReportConfigurationResponse = OpenApiResponseType<
  `${BasePath}/{configurationId}`,
  'delete'
>

type IdPayload = {
  configurationId: string
}

type GetConfigurationsPayload = {
  filter: OpenApiRequestBody<`${BasePath}/list`>
}

type CreateReportConfigurationPayload = {
  createData: OpenApiRequestBody<`${BasePath}`>
}

type UpdateReportConfigurationPayload = IdPayload & {
  updateData: OpenApiRequestBody<`${BasePath}/{configurationId}`, 'patch'>
}

export const reportConfigurationsApi = createApi({
  reducerPath: 'reportConfigurationsApi',
  tagTypes: tags,
  baseQuery: redirectLoginOn401Query(),
  endpoints: (build) => ({
    getConfigurations: build.query<GetConfigurationsResponse, GetConfigurationsPayload>({
      query: ({filter}) => ({
        url: `${basePath}/list`,
        method: 'POST',
        body: filter,
      }),
      providesTags: tags,
    }),
    getConfigurationById: build.query<GetConfigurationByIdResponse, IdPayload>({
      query: ({configurationId}) => `${basePath}/${configurationId}`,
      providesTags: tags,
    }),
    createReportConfiguration: build.mutation<
      CreateReportConfigurationResponse,
      CreateReportConfigurationPayload
    >({
      query: ({createData}) => ({
        url: basePath,
        method: 'POST',
        body: createData,
      }),
      invalidatesTags: tags,
    }),
    updateReportConfiguration: build.mutation<
      UpdateReportConfigurationResponse,
      UpdateReportConfigurationPayload
    >({
      query: ({configurationId, updateData}) => ({
        url: `${basePath}/${configurationId}`,
        method: 'PATCH',
        body: updateData,
      }),
      invalidatesTags: tags,
    }),
    deleteReportConfiguration: build.mutation<DeleteReportConfigurationResponse, IdPayload>({
      query: ({configurationId}) => ({
        url: `${basePath}/${configurationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: tags,
    }),
  }),
})

export const {
  useGetConfigurationsQuery,
  useGetConfigurationByIdQuery,
  useCreateReportConfigurationMutation,
  useUpdateReportConfigurationMutation,
  useDeleteReportConfigurationMutation,
} = reportConfigurationsApi
