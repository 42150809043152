import {Box, Typography} from '@x5-react-uikit/core'

const Chip = ({backgroundColor, textColor, text, sx}) => (
  <Box
    sx={{
      py: 'x1',
      px: 'x3',
      mt: 'x2',
      borderRadius: 'x2',
      color: textColor,
      backgroundColor,
      width: 'fit-content',
      ...sx,
    }}
  >
    <Typography variant="p2">{text}</Typography>
  </Box>
)

export default Chip
