import {Typography} from '@x5-react-uikit/core'
import Breadcrumbs from '@root/components/Breadcrumbs'
import {useEffect, useRef, useState} from 'react'
import StepsSection from './components/StepsSection'
import {useForm} from 'react-hook-form'
import DataStep from './components/DataStep/DataStep'
import StatisticsStep from './components/StatisticsStep/StatisticsStep'
import ReviewStep from './components/ReviewStep/ReviewStep'
import {ConfUpdateContextProvider} from './context'

const ReportConfCreatePage = () => {
  const [step, setStep] = useState(1)
  const [completedSteps, setCompletedSteps] = useState([])
  const [touchedSteps, setTouchedSteps] = useState([])

  const focusedFieldRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => step === 1 && focusedFieldRef.current?.scrollIntoView(), [step])

  const {trigger: formTrigger, ...form} = useForm()

  let currentStepComponent
  switch (Number(step)) {
    case 1:
      currentStepComponent = <DataStep form={form} />
      break
    case 2:
      currentStepComponent = <StatisticsStep form={form} />
      break
    case 3:
      currentStepComponent = <ReviewStep form={form}/>
      break
    default:
      currentStepComponent = <DataStep form={form} />
  }

  return (
    <ConfUpdateContextProvider value={{setStep, focusedFieldRef}}>
      <div style={{margin: '0 16px 0 16px', width: '1200px'}}>
        <Breadcrumbs
          routes={[
            {to: '/', label: 'Главная'},
            {to: '/reports', label: 'Отчеты портала'},
            {to: null, label: 'Создание конфигурации отчета'},
          ]}
        />
        <Typography variant="h2">Создание конфигурации отчета</Typography>
        <StepsSection
          completedSteps={completedSteps}
          currentStep={step}
          form={form}
          formTrigger={formTrigger}
          setCompletedSteps={setCompletedSteps}
          setCurrentStep={setStep}
          setTouchedSteps={setTouchedSteps as any}
          touchedSteps={touchedSteps}
        />
        {currentStepComponent}
        <StepsSection
          completedSteps={completedSteps}
          currentStep={step}
          form={form}
          formTrigger={formTrigger}
          setCompletedSteps={setCompletedSteps}
          setCurrentStep={setStep}
          setTouchedSteps={setTouchedSteps as any}
          touchedSteps={touchedSteps}
        />
      </div>
    </ConfUpdateContextProvider>
  )
}

export default ReportConfCreatePage
