import {Typography} from '@root/components/kit'
import Divider from '@root/components/Divider'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import Section from '@root/components/Section'
import {useParams} from 'react-router-dom'
import useScrollToField from '../hooks/useScrollToField'
import ParamField from './ParamField'
import SectionInitiator from './SectionInitiator'

// TODO: Поправить вёрстку
// TODO: Добавить актуальное значение инициатора

const StepCommonParams = ({form, stepMeta}) => {
  const {stepId} = useParams()

  const {fieldId: focusedFieldId, fieldRef: focusedFieldRef} = useScrollToField()

  const formValue = form.getValues()
  const stepIndex = Object.keys(formValue).find(
    (stepIndex) => Number(formValue[stepIndex].number) === Number(stepId)
  )
  const step = formValue[stepIndex]

  const budgetSourceFieldMeta = stepMeta['budget_source']

  return (
    <Section sx={{display: 'flex', flexDirection: 'column'}}>
      <FlexboxRow sx={{gap: '120px', alignSelf: 'stretch'}}>
        <FlexboxColumn sx={{width: '540px'}}>
          <Typography style={{marginBottom: '24px'}} variant="h3">
            Заказчик
          </Typography>
          <SectionInitiator />
        </FlexboxColumn>

        <ParamField
          fieldIndex={0}
          fieldMeta={budgetSourceFieldMeta}
          fieldValue={step.fields.find((field) => field.id === budgetSourceFieldMeta.id)}
          form={form}
          stepIndex={stepIndex}
          sx={{my: 0}}
        />
      </FlexboxRow>
      <Divider sx={{my: 'x16'}} />
      {stepMeta &&
        Object.keys(stepMeta).map((fieldMetaId, fieldIndex) => {
          if (fieldMetaId !== 'budget_source') {
            const hasSibling = fieldIndex + 1 !== Object.keys(stepMeta).length
            return (
              <div
                key={stepMeta.id}
                ref={focusedFieldId === fieldMetaId ? focusedFieldRef : undefined}
              >
                <ParamField
                  key={fieldMetaId}
                  fieldIndex={fieldIndex}
                  fieldMeta={stepMeta[fieldMetaId]}
                  fieldValue={step.fields.find((field) => field.id === fieldMetaId)}
                  form={form}
                  stepIndex={stepIndex}
                />
                {hasSibling && <Divider />}
              </div>
            )
          }
          return null
        })}
    </Section>
  )
}

export default StepCommonParams
