import {makeUiStyles} from '../../theme'

const zero = '0 !important'

export const useStyles = makeUiStyles(
  () => ({
    root: {
      padding: zero,
      userSelect: 'none',
      overflow: 'hidden !important',
      '& *': {
        padding: zero,
        minHeight: zero,
        minWidth: zero,
      },
    },
  }),
  'table-ghost-cell'
)
