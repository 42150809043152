import React, {FC} from 'react'
import {ArrowDown} from '@x5-react-uikit/icons'
import {defaultCellDensity} from '../../helpers'
import BodyCellInnerSelectView from './View'
import BodyCellInnerSelectEdit from './Edit'
import {BodyCellInnerSelectProps, BodyCellInnerSelectEditProps} from './types'

export const BodyCellInnerSelect: FC<BodyCellInnerSelectProps> = (
  props: BodyCellInnerSelectProps
) => {
  const {
    density = defaultCellDensity,
    noWrap,
    editable,
    editing,
    onStartEdit,
    Icon = ArrowDown,
    options,
    children: value,
  } = props

  return (
    <>
      <BodyCellInnerSelectView
        density={density}
        editable={editable}
        Icon={Icon}
        noWrap={noWrap}
        options={options}
        onStartEdit={onStartEdit}
      >
        {value}
      </BodyCellInnerSelectView>
      {editing && (
        <BodyCellInnerSelectEdit {...(props as BodyCellInnerSelectEditProps)}>
          {value}
        </BodyCellInnerSelectEdit>
      )}
    </>
  )
}

export default BodyCellInnerSelect
