import React, {FC, MouseEvent, useCallback, useContext, useMemo, useRef} from 'react'
import {tableContext} from './context'
import type {Report} from '@root/features/reports/types'
import {ActionButton} from '@root/components/ActionButton'
import {MoreVertical} from '@x5-react-uikit/icons'
import Paper from '@root/components/Paper'
import {
  Dropdown,
  DropdownInnerRefMethods,
  ListItem,
  SelectList,
  SelectListProps,
  SelectOptionType,
  Tooltip,
} from '@root/components/kit'
import {userSelector} from '@root/redux/selectors'
import {useSelector} from 'react-redux'
import {isRoleAllowed, isSameEditorOrNull} from '@root/features/reports/canEditReport'

type Props = {
  reportId: string
  currentEditor?: Report['currentEditor']
}

enum actionsEnum {
  delete,
  update,
}

export const ReportTableActionsButton: FC<Props> = ({reportId, currentEditor}) => {
  const dropdownRef = useRef<DropdownInnerRefMethods>()
  const ctx = useContext(tableContext)
  if (ctx == null) {
    throw new Error('Context was null!')
  }

  const currentUser = useSelector(userSelector)

  const reportLocked = !isSameEditorOrNull(currentUser, currentEditor)

  const actions = useMemo(() => {
    const arr: SelectOptionType[] = [
      {name: 'Удалить', value: actionsEnum.delete, disabled: reportLocked},
    ]

    if (isRoleAllowed(ctx.userInfo)) {
      arr.push({
        name: 'Редактировать',
        value: actionsEnum.update,
        disabled: reportLocked,
      })
    }

    return arr
  }, [ctx.userInfo, reportLocked])

  const handleChange: SelectListProps['onChange'] = useCallback(
    (_, actionName) => {
      switch (actionName as actionsEnum) {
        case actionsEnum.delete:
          if (!reportLocked) {
            ctx.onDelete(reportId)
          }
          break
        case actionsEnum.update:
          if (!reportLocked) {
            ctx.onUpdate(reportId)
          }
          break
      }

      dropdownRef?.current?.close()
    },
    [ctx, reportLocked, reportId]
  )

  const renderOption: SelectListProps['renderOption'] = useCallback(
    (option, isActive, onClick) => {
      const commonProps = {
        disabled: option.disabled,
        adornment: option.icon,
        selected: isActive,
        text: option.name,
        onClick: (event: MouseEvent<HTMLElement>) => !option.disabled && onClick(event, option),
      }

      if (reportLocked) {
        return (
          <Tooltip
            key={option.value}
            doNotClone
            usePortal
            content={`${currentEditor.email} сейчас редактирует отчет. Редактирование или удаление недоступно.`}
            zIndex={100000}
          >
            <ListItem {...commonProps} />
          </Tooltip>
        )
      }

      return <ListItem key={option.value} {...commonProps} />
    },
    [currentEditor?.email, reportLocked]
  )

  if (!ctx.userInfo) {
    return <></>
  }

  return (
    <Dropdown
      disableContentVisual
      usePortal
      action={
        <ActionButton disabled={ctx.userInfo.isBusiness}>
          <MoreVertical size="small" />
        </ActionButton>
      }
      className="w-100"
      innerRef={dropdownRef}
      placement="bottom-end"
    >
      <Paper sx={{padding: '8px 0 0 0', backgroundColor: 'white'}}>
        <SelectList
          items={actions}
          renderOption={renderOption}
          selected={[]}
          onChange={handleChange}
        />
      </Paper>
    </Dropdown>
  )
}
