import React, {FC, useCallback, useMemo, useRef} from 'react'
import {DropdownInnerRefMethods, Select, SelectOptionType, SelectProps} from '@root/components/kit'
import {useGetTemplatesQuery} from '@root/redux/api/templatesApi'

type Props = {
  systemId: string
  onChange: (next: string) => void
  value: string
  selectProps?: Omit<
    SelectProps,
    'value' | 'onChange' | 'options' | 'filter' | 'onFilterChange' | 'onClearClick'
  >
}

export const TemplateSettingsSelect: FC<Props> = ({systemId, onChange, value, selectProps}) => {
  const {currentData} = useGetTemplatesQuery({
    systemId,
    filter: {
      pageNumber: 1,
      filters: {},
      pageSize: 9999999, // TODO: Don't like that. Search could run when user scrolls combobox options.
    },
  })

  const ref = useRef<DropdownInnerRefMethods>(null)

  const options: SelectOptionType[] = useMemo(
    () =>
      currentData?.items?.map((x) => ({
        name: x.name,
        value: x.id,
      })) ?? [],
    [currentData?.items]
  )

  const handleChange: SelectProps['onChange'] = useCallback(
    (_e, val) => {
      onChange(val as string)
    },
    [onChange]
  )

  const onClearClick: SelectProps['onClearClick'] = useCallback(() => {
    onChange(null)
  }, [onChange])

  return (
    <Select
      {...selectProps}
      dropdownRef={ref}
      options={options}
      value={value}
      onChange={handleChange}
      onClearClick={onClearClick}
    />
  )
}
