import React, {FC} from 'react'
import {LogoProps, logoColors} from '../types'
import Blank from '../Blank'

const Logo: FC<LogoProps> = ({color, ...props}) => {
  if (color === logoColors.white) return <Blank color={color} {...props} />

  return (
    <svg fill="none" viewBox="0 0 129 100" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M104.591 40.5411C103.473 43.9532 101.794 47.343 98.96 49.556C95.7784 52.0355 91.5536 52.709 87.5286 52.9015C85.5827 52.9977 83.5998 53.0125 81.7131 52.4944C77.8064 51.4434 74.7359 48.2238 73.1229 44.5157C71.4951 40.8076 71.177 36.6554 71.3619 32.6142C71.5913 27.6404 72.6346 22.5038 75.6533 18.5515C78.6721 14.5917 84.8724 13.141 89.8297 13.4445C96.6071 13.8664 101.505 18.1 104.28 23.4882C106.951 28.6766 106.411 34.9974 104.591 40.5411ZM126.758 26.0269C124.627 25.2572 119.966 24.3172 116.037 23.6955C115.326 22.2448 114.305 20.5795 112.744 18.4627C110.48 15.3911 105.108 10.3359 99.7739 7.10888C86.9811 -0.633011 71.9243 -1.97267 57.7257 2.70503C45.5693 6.7092 36.9199 12.7488 31.7185 23.1108C28.8773 28.7876 26.7612 38.0764 26.7612 38.0764C18.7111 36.4111 11.6895 32.9398 2.2929 27.6922C-1.95409 39.4161 -0.252336 56.3135 6.99122 65.5431C15.685 76.6156 26.9979 81.841 37.2455 83.5508C30.1943 84.8534 22.4994 86.6372 22.4994 86.6372C22.4994 86.6372 29.2398 92.9802 44.9182 97.014C58.1549 100.404 68.8833 100.263 75.1058 99.6711C82.1274 99.1678 89.1342 96.8733 95.9856 92.3141C112.7 81.2341 122.104 59.1039 119.966 38.972C123.51 35.4933 126.891 32.0221 128.415 29.5056C128.763 28.9357 129.051 28.3288 128.992 27.8107C128.911 26.9077 127.831 26.4118 126.758 26.0269Z"
        fill="black"
      />
      <path
        d="M93.9657 38.1805C91.1689 38.1805 88.8974 34.3614 88.8974 29.6467C88.8974 24.9393 91.1689 21.1202 93.9657 21.1202C96.7625 21.1202 99.034 24.9393 99.034 29.6467C99.0414 34.3614 96.7625 38.1805 93.9657 38.1805ZM104.28 23.4887C101.505 18.1004 96.6071 13.8594 89.8297 13.4449C84.8724 13.1341 78.6721 14.5848 75.6533 18.5519C72.6346 22.5043 71.5913 27.6409 71.362 32.6146C71.177 36.6558 71.4951 40.808 73.1229 44.5161C74.7359 48.2242 77.8064 51.4438 81.7131 52.4948C83.5998 53.0129 85.5827 52.9981 87.5286 52.9019C91.5536 52.7021 95.7784 52.036 98.96 49.5491C101.786 47.3435 103.473 43.9536 104.591 40.5415C106.411 34.9979 106.951 28.6697 104.28 23.4887Z"
        fill="white"
      />
      <path
        d="M99.034 29.6534C99.034 34.3607 96.7625 38.1872 93.9657 38.1872C91.1689 38.1872 88.8974 34.3681 88.8974 29.6534C88.8974 24.9461 91.1689 21.127 93.9657 21.127C96.7625 21.1196 99.034 24.9387 99.034 29.6534Z"
        fill="black"
      />
    </svg>
  )
}

export default Logo
