export const ReportCommonFieldsMeta = {
  goal: {
    step: 1,
    id: 'goal',
    label: 'Цель тестирования',
    values: [
      {
        id: 'd4a15ffc-3966-4dd2-a4f8-bff7c11b5e69',
        type: 'comment',
        textarea: true,
        placeholder: 'Комментарий',
      },
    ],
  },
  conclusion: {
    step: 1,
    id: 'conclusion',
    label: 'Выводы',
    values: [
      {
        id: 'd4a15ffc-3966-4dd2-a4f8-bff7c11b5e69',
        type: 'comment',
        textarea: true,
        placeholder: 'Комментарий',
      },
    ],
  },
  approach: {
    step: 1,
    id: 'approach',
    label: 'Подход к тестированию',
    values: [
      {
        id: 'd4a15ffc-3966-4dd2-a4f8-bff7c11b5e69',
        type: 'comment',
        textarea: true,
        placeholder: 'Комментарий',
      },
    ],
  },
  limit: {
    step: 1,
    id: 'limit',
    label: 'Ограничения тестирования',
    values: [
      {
        id: 'd4a15ffc-3966-4dd2-a4f8-bff7c11b5e69',
        type: 'comment',
        textarea: true,
        placeholder: 'Комментарий',
      },
    ],
  },
  configuration: {
    step: 1,
    id: 'configuration',
    label: 'Конфигурация среды',
    values: [
      {
        id: '875eaaef-9cba-4144-b863-1f2cb5cda8c2',
        type: 'table',
        addButtonLabel: 'Добавить строку',
        columns: {
          '15ddb543-260a-4e6a-a365-ec18eb177b94': {
            id: '15ddb543-260a-4e6a-a365-ec18eb177b94',
            label: 'Назначение',
            type: 'text',
            rows: [''],
          },
          'f6159a8b-7c79-4ad4-91d1-f2a961aaa978': {
            id: 'f6159a8b-7c79-4ad4-91d1-f2a961aaa978',
            label: 'Стенд',
            type: 'text',
            rows: ['Прод', 'test'],
          },
          '10b44dbc-fa13-4fa8-954a-867d09e8edbc': {
            id: '10b44dbc-fa13-4fa8-954a-867d09e8edbc',
            label: 'Кол-во серверов',
            type: 'text',
            rows: ['', ''],
          },
          '8f105d47-06b3-4aba-b7e7-045eeab7700f': {
            id: '8f105d47-06b3-4aba-b7e7-045eeab7700f',
            label: 'ОС',
            type: 'text',
            rows: ['', ''],
          },
          '2690b29c-8c33-4ea7-8263-c856b07ebf70': {
            id: '2690b29c-8c33-4ea7-8263-c856b07ebf70',
            label: 'СПО (СП, СУБД)',
            type: 'text',
            rows: ['', ''],
          },
          '1392a93e-a8ad-4bff-94ed-55a9885a7cc6': {
            id: '1392a93e-a8ad-4bff-94ed-55a9885a7cc6',
            label: 'CPU',
            type: 'text',
            rows: ['', ''],
          },
          'c60bb79e-7906-42f9-a78c-3332e9b66cbb': {
            id: 'c60bb79e-7906-42f9-a78c-3332e9b66cbb',
            label: 'Memory',
            type: 'text',
            rows: ['', ''],
          },
          'e7519209-dc2c-4484-aa5b-36e613453be4': {
            id: 'e7519209-dc2c-4484-aa5b-36e613453be4',
            label: 'Hdd',
            type: 'text',
            rows: ['', ''],
          },
        } as const,
      },
      {
        id: 'd4a15ffc-3966-4dd2-a4f8-bff7c11b5e69',
        type: 'comment',
        textarea: true,
        placeholder: 'Комментарий (при необходимости)',
      },
    ] as const,
  },
} as const

export const GRAPHS_REGISTER = 'statistics.graphs'
export const FILES_REGISTER = 'statistics.fileFields'
