import {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react'

export function useDimensions<Element extends HTMLElement>() {
  const ref = useRef<Element | null>(null)
  const [dimensions, setDimensions] = useState<DOMRect | null>(null)

  const refresh = useCallback(() => {
    const domRect = ref.current?.getBoundingClientRect()

    if (domRect) {
      setDimensions(domRect)
    }
  }, [])

  useLayoutEffect(() => {
    refresh()
  }, [refresh])

  useEffect(() => {
    const listener = () => refresh()
    window.addEventListener('resize', listener)

    return () => window.removeEventListener('resize', listener)
  }, [refresh])

  return {dimensions, ref, refresh}
}
