import React, {FC, MouseEventHandler} from 'react'
import {Search as SearchIcon} from '@x5-react-uikit/icons'
import {BaseAdornmentProps} from './types'

interface SearchAdornmentProps extends BaseAdornmentProps {
  onClick?: MouseEventHandler
}

export const SearchAdornment: FC<SearchAdornmentProps> = ({size, color, onClick}) => (
  <SearchIcon color={color} size={size} onClick={onClick} />
)

export default SearchAdornment
