import {
  ComponentType,
  forwardRef,
  HTMLProps,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {useSpring, animated} from '@react-spring/web'
import styled from '@emotion/styled'

export type ExpanderNodeProps = {
  open: boolean
}

type Props = HTMLProps<HTMLDivElement> & {
  expanderNode: ComponentType<ExpanderNodeProps>
  defaultOpen?: boolean
}

const StyledExpander = styled.div`
  cursor: pointer;
`

export const SpringAccordion = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({children, expanderNode: ExpanderNode, defaultOpen, ...other}, ref) => {
    const contentRef = useRef<HTMLDivElement>(null)
    const [shouldAnimate, setShouldAnimate] = useState(!defaultOpen)
    const [open, setOpen] = useState(defaultOpen)

    const [style, api] = useSpring(
      () => ({
        height: '0px',
        config: {
          duration: shouldAnimate ? undefined : 0,
        },
      }),
      []
    )

    useEffect(() => {
      api.start({
        height: (open ? contentRef.current.offsetHeight : 0) + 'px',
        config: {
          duration: shouldAnimate ? undefined : 0,
        },
      })
    }, [api, contentRef, open, shouldAnimate])

    const handleToggleClick = useCallback(() => {
      setShouldAnimate(true)
      setOpen((prev) => !prev)
    }, [setOpen])

    return (
      <div ref={ref} {...other}>
        <StyledExpander onClick={handleToggleClick}>
          <ExpanderNode open={open} />
        </StyledExpander>

        <animated.div
          style={{
            overflow: 'hidden',
            width: '100%',
            ...style,
          }}
        >
          <div ref={contentRef}>{children}</div>
        </animated.div>
      </div>
    )
  }
)

export default SpringAccordion
