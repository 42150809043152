import {Box, Typography, baseTheme} from '@x5-react-uikit/core'
import {Done} from '@x5-react-uikit/icons'
import FlexboxRow from '@root/components/FlexboxRow'

const {colors} = baseTheme

const WarningCircle = () => (
  <Box
    sx={{
      padding: '0 4px 0 4px',
      borderRadius: 'x20',
      border: `1px solid`,
      width: '16px',
      height: '16px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'red.80',
      color: 'white',
      position: 'relative',
      right: '12px',
    }}
  >
    <Typography variant="p3">!</Typography>
  </Box>
)

const StepTitle = ({
  title,
  number,
  completed,
  current,
  disabled,
  showWarning = true,
  handleClick,
}) => {
  // const {stepId} = useParams();
  // const isCurrent = Number(stepId) === number;
  const value =
    completed && !current ? <Done size="small" /> : <Typography variant="p3">{number}</Typography>

  const isNotFinished = !current && !disabled && !completed

  let numberColor
  if (disabled) numberColor = 'grey.50'
  else if (isNotFinished) numberColor = 'accent.80'
  else numberColor = 'white'

  return (
    <FlexboxRow
      sx={{
        gap: '8px',
        alignItems: 'center',
        cursor: disabled || !handleClick ? 'default' : 'pointer',
      }}
      onClick={() => !disabled && handleClick?.()}
    >
      <FlexboxRow>
        <Box
          sx={{
            p: 'x2',
            borderRadius: 'x8',
            width: '32px',
            height: '32px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: numberColor,
            backgroundColor: completed || current ? 'accent.80' : 'white',
            border: `1px solid`,
            borderColor: disabled ? 'grey.50' : 'accent.80',
          }}
        >
          {value}
        </Box>
        {isNotFinished && showWarning && <WarningCircle />}
      </FlexboxRow>
      <Typography style={{color: disabled ? colors.grey[50] : colors.accent[80]}} variant="p1">
        {title}
      </Typography>
    </FlexboxRow>
  )
}

export default StepTitle
