import {restyled} from '../styles'
import {makeUiStyles} from '../theme'
import {FieldCaptionProps} from './types'
import {InputProps} from '../Input/types'

export const StyledCaption = restyled.div<
  Partial<FieldCaptionProps> & {cssWidth: InputProps['width']}
>(({theme: {spaces, colors, widths, typography}, cssWidth, error, isAbsolute}) => ({
  padding: spaces.join('x2', 'x0'),
  ...typography.p3,
  color: error ? colors.error : colors.grey[60],
  wordWrap: 'break-word',
  width: cssWidth ?? widths.field,
  ...(isAbsolute
    ? {
        position: 'absolute',
        overflow: 'hidden',
        bottom: -(spaces.x12 - spaces.x2),
        padding: 0,
        height: typography.p3.lineHeight,
        width: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }
    : null),
}))

export const useCaptionStyles = makeUiStyles((theme) => {
  const bottomOffset = theme.spaces.x12

  return {
    container: {
      position: 'relative',
      marginBottom: ({isAbsolute}) => (isAbsolute ? bottomOffset : null),
    },
    root: {
      padding: theme.spaces.join('x2', 'x0'),
      ...theme.typography.p3,
      color: theme.colors.grey[60],
      wordWrap: 'break-word',
      width: ({stretch, width}) => {
        if (stretch) return '100%'
        return width ?? theme.widths.field
      },
    },
    isAbsolute: {
      position: 'absolute',
      overflow: 'hidden',
      bottom: -(bottomOffset - theme.spaces.x2),
      padding: 0,
      height: theme.typography.p3.lineHeight,
      width: '100%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    error: {
      color: theme.colors.error,
    },
  }
}, 'field-caption')
