import {Modal, Typography} from '@x5-react-uikit/core'
import useNotify from '@root/hooks/useNotify'
import {useDeleteReportConfigurationMutation} from '@root/redux/api/reportConfigurationsApi'

const DeleteConfigurationModal = ({configurationId, onClose}) => {
  const {notifySuccess, notifyError} = useNotify()

  const [deleteReport] = useDeleteReportConfigurationMutation()
  const handleDeleteReport = () => {
    deleteReport({configurationId})
      .unwrap()
      .then(() => {
        notifySuccess('Отчёт удалён')
        onClose()
      })
      .catch(() => {
        notifyError()
        onClose()
      })
  }

  return (
    <>
      <Modal
        closeOnOverlay
        buttons={[
          {
            onClick: onClose,
            side: 'right',
            text: 'Отмена',
          },
          {
            onClick: handleDeleteReport,
            side: 'right',
            text: 'Удалить',
          },
        ]}
        isOpen={!!configurationId}
        size="medium"
        title="Удаление конфигурации"
        onClose={onClose}
      >
        <Typography>
          Вы действительно хотите удалить конфигурацию? Конфигурация не подлежит восстановлению.
          Можно будет создать конфигурацию повторно.
        </Typography>
      </Modal>
    </>
  )
}

export default DeleteConfigurationModal
