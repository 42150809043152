import {RequiredRule} from '@root/constants'
import SystemsCombobox from '@root/features/systems/components/SystemsCombobox'
import {Controller} from 'react-hook-form'

const FormInputSystems = ({form}) => {
  form.watch()

  const onClear = () => form.setValue('systemId', null)

  return (
    <Controller
      control={form.control}
      name={'systemId'}
      render={({field: {onChange, value}, fieldState: {error}}) => {
        return (
          <SystemsCombobox
            errorOnEmpty={error}
            selectedSystemId={value}
            width="586px"
            onChange={(e, newSystem) => onChange(newSystem)}
            onClear={onClear}
          />
        )
      }}
      rules={RequiredRule}
    />
  )
}

export default FormInputSystems
