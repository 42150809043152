import {Box, Typography, Button} from '@root/components/kit'
import {ChevronDown, ChevronUp} from '@x5-react-uikit/icons'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import TypographyError from '@root/components/typography/TypographyError'
import {getFieldReadonlyComponent} from '@root/pages/tasks/CreateTaskPage/utils/componentUtils'
import {FC, ReactNode, useContext, useRef, useState} from 'react'
import {ExtractConstValues} from '@root/core/helperTypes'
import {ReportCommonFieldsMeta} from '@root/features/reports/constants'
import {confUpdateContext} from '@root/pages/report/ReportConfCreatePage/context'

type Props = {
  meta: ExtractConstValues<typeof ReportCommonFieldsMeta>
  form: any // TODO: Not now, it's really complex object and requires to dig deeper into the functions that use it
  navigateToEdit?: boolean
  error?: ReactNode
}

const ConfDataAccordion: FC<Props> = ({meta, form, children, error, navigateToEdit}) => {
  const [expanded, setExpanded] = useState(false)

  const ref = useRef<HTMLDivElement>()

  const confUpdateCtx = useContext(confUpdateContext)

  let content = children
  if (!content)
    content = getFieldReadonlyComponent({
      fieldMeta: meta,
      form,
      commentTitle: null,
      fileBaseUrl: '/v1/reports/files',
    })

  return (
    <Box ref={ref}>
      <FlexboxRow
        sx={{
          py: 'x16',
          px: 'x12',
          alignItems: 'center',
          backgroundColor: 'white',
        }}
      >
        <FlexboxRow sx={{width: '100%'}}>
          <FlexboxColumn sx={{width: '100%'}}>
            <FlexboxRow
              sx={
                {
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                  cursor: 'pointer',
                  userSelect: 'none',
                } as any
              }
              onClick={() => setExpanded((prev) => !prev)}
            >
              <FlexboxRow>
                {expanded ? <ChevronUp /> : <ChevronDown />}
                <FlexboxColumn>
                  <Typography variant="h3">{meta.label}</Typography>
                  {error && <TypographyError variant="p2">{error}</TypographyError>}
                </FlexboxColumn>
              </FlexboxRow>
              {navigateToEdit && confUpdateCtx && (
                <Button
                  variant="text"
                  onClick={(e) => {
                    e.stopPropagation()
                    confUpdateCtx.setStep(1)
                    confUpdateCtx.focusedFieldRef.current = ref.current
                  }}
                >
                  Вернуться к пункту
                </Button>
              )}
            </FlexboxRow>
          </FlexboxColumn>
        </FlexboxRow>
      </FlexboxRow>
      {expanded && <Box sx={{mb: 'x16'}}>{content}</Box>}
    </Box>
  )
}

export default ConfDataAccordion
