import {makeUiStyles} from '../../theme'

export const useStyles = makeUiStyles(
  (theme) => ({
    root: {
      display: 'flex',
    },
    day: {
      width: theme.spaces.x20,
      height: theme.spaces.x20,
      textAlign: 'center',
      '&&': {
        lineHeight: `${theme.spaces.x20}px`,
      },
    },
    holiday: {
      color: theme.colors.error,
    },
  }),
  'calendar-week'
)
