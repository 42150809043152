import {Datepicker, DatepickerProps} from '@root/components/kit'
import {DATE_FORMAT} from '@root/constants'
import {Controller, ControllerProps, FieldPath, FieldValues} from 'react-hook-form'

type Props<TFieldValues extends FieldValues> = {
  name: FieldPath<TFieldValues>
  label?: string
  caption?: string
  dateFormat?: string
  controllerProps?: Omit<ControllerProps<TFieldValues>, 'control' | 'rules' | 'disabled'>
  required?: boolean
  width?: DatepickerProps['width']
  size?: DatepickerProps['size']
} & Pick<ControllerProps<TFieldValues>, 'control' | 'rules' | 'disabled'>

function FormInputDatetime<TFieldValues extends FieldValues>({
  name,
  control,
  label,
  caption,
  dateFormat = DATE_FORMAT,
  disabled,
  controllerProps,
  width,
  size = 'large',
  required,
  rules,
  ...inputProps
}: Props<TFieldValues>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({field: {onChange, value}, fieldState: {error}, formState}) => {
        return (
          <Datepicker
            date={value}
            dateFormat={dateFormat}
            delimiter=" — "
            disabled={disabled}
            DropdownProps={{updateOnOpen: true, action: null}}
            InputProps={{
              label: label,
              error: error != null,
              caption: error?.message || caption,
              ...inputProps,
            }}
            required={required}
            size={size}
            width={width}
            onChange={onChange}
          />
        )
      }}
      rules={rules}
      {...controllerProps}
    />
  )
}

export default FormInputDatetime
