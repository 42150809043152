import {InputProps} from '../Input/types'
import {SelectProps, SelectOptionType} from './types'

export const getDifferentProps = ({
  id,
  label,
  name,
  size,
  required,
  stretch,
  error,
  textError,
  disabled,
  noWrap,
  preMatching,
  renderDropDownContent,
  unborder,
  caption,
  isAbsoluteCaption,
  placement = 'bottom-start',
  dropdownWidth,
  header,
  footer,
  hint,
  width,
  dropdownProps,
  usePortal,
}: SelectProps): {
  inputProps: Partial<InputProps> & {label: InputProps['label']}
  selectBaseProps: Partial<SelectProps>
  commonProps: Partial<SelectProps>
} => {
  const inputProps = {
    id,
    label,
    name,
    required,
    unborder,
    textError,
    size,
    caption,
    isAbsoluteCaption,
    width,
  }
  const selectBaseProps = {
    noWrap,
    preMatching,
    renderDropDownContent,
    placement,
    dropdownWidth,
    usePortal,
  }
  const commonProps = {error, disabled, stretch, header, footer, hint, dropdownProps}
  return {inputProps, selectBaseProps, commonProps}
}

export const filterOptions = (options: SelectOptionType[], value: string): SelectOptionType[] => {
  return options.filter((item) => item.name.toLowerCase().match(value.toLowerCase().trim()))
}
