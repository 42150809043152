import {Loader, Typography} from '@x5-react-uikit/core'
import Breadcrumbs from '@root/components/Breadcrumbs'
import {FC, useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useParams} from 'react-router-dom'
import {useGetTaskFormDataQuery, useGetTaskFormMetaQuery} from '@root/redux/api/formsApi'
import StepCommonParams from './components/StepCommonParams'
import StepReview from './components/StepReview'
import StepTechParams from './components/StepTechParams'
import StepsSection from './components/StepsSection'
import {initStepFormData} from './utils/formUtils'
import {useGetTaskByIdQuery} from '@root/redux/api/tasksApi'
import {CreateTaskContextProvider} from './context'
import {match} from 'ts-pattern'
import {TaskContextProvider} from '../context'
import {useSaveDraftBeforeTeardown} from './hooks/useSaveDraftBeforeTeardown'
import {TaskFormData} from '@root/features/tasks/types'

// TODO: TYPES
type FormValue = TaskFormData | {isLoading: boolean}

const isFormInited = (form: any, stepId: any) => {
  const formValue = form.getValues()

  return (
    !formValue.isLoading &&
    Object.values(formValue).some((step: any) => Number(step.number) === Number(stepId))
  )
}

const formDefault = {isLoading: true}

// TODO: Изменить поведение кнопки "Отменить создание" для отозванных заявок

type Props = {
  type?: 'draft' | 'withdrawn'
}

const CreateTaskPage: FC<Props> = ({type = 'draft'}) => {
  const {taskId, stepId} = useParams()

  const {data: task} = useGetTaskByIdQuery(taskId, {skip: !taskId || type == null})

  const {data: taskFormMeta} = useGetTaskFormMetaQuery({taskId})
  const {data: taskFormData, isFetching: isTaskFormDataFetching} = useGetTaskFormDataQuery(
    {taskId},
    {skip: !taskFormMeta}
  )
  const [taskFormDataUpdated, setTaskFormDataUpdated] = useState(false)

  const form = useForm<FormValue>({defaultValues: formDefault})

  const currentStepMeta = useMemo(
    () =>
      taskFormMeta?.fields.reduce(
        (acc, cur) => (cur.step === Number(stepId) ? {...acc, [cur.id]: cur} : acc),
        {}
      ),
    [taskFormMeta, stepId]
  )
  useEffect(() => {
    if (taskFormData && !isTaskFormDataFetching) {
      let formValue
      let step = taskFormData.find((step) => Number(step.number) === Number(stepId))
      if (step) {
        formValue = taskFormData
      } else {
        step = initStepFormData(currentStepMeta, stepId)
        formValue = [...taskFormData, step]
      }
      form.reset(formValue)
    }
  }, [stepId, taskFormData, isTaskFormDataFetching, form, currentStepMeta])

  useSaveDraftBeforeTeardown({
    updated: taskFormDataUpdated,
    currentStepMeta,
    form,
    stepId: Number(stepId),
  })

  if (!isFormInited(form, stepId)) return <Loader />

  let currentStepComponent
  switch (Number(stepId)) {
    case 1:
      currentStepComponent = <StepCommonParams form={form} stepMeta={currentStepMeta} />
      break
    case 2:
      currentStepComponent = <StepTechParams form={form} stepMeta={currentStepMeta} />
      break
    case 3:
      currentStepComponent = <StepReview form={form} meta={taskFormMeta} />
      break
    default:
      currentStepComponent = <h1>NOT IMPLEMENTED</h1>
  }

  const draft = type === 'draft'

  const title = match(type)
    .with('draft', () => `Черновик заявки #${task?.number}`)
    .with('withdrawn', () => `Отозванная заявка #${task?.number}`)
    .otherwise(() => `Создание заявки`)

  return (
    <TaskContextProvider value={{taskId}}>
      <CreateTaskContextProvider value={{setUpdated: setTaskFormDataUpdated}}>
        <div style={{margin: '0 16px 0 16px', width: '100%'}}>
          <Breadcrumbs
            routes={[
              {to: '/', label: 'Главная'},
              {to: null, label: title},
            ]}
          />
          <Typography variant="h2">{title}</Typography>
          <StepsSection
            currentStepMeta={currentStepMeta}
            displayButtons={Number(stepId) !== 3}
            draft={draft}
            form={form}
          />
          {currentStepComponent}
          {Number(stepId) !== 3 && (
            <StepsSection
              currentStepMeta={currentStepMeta}
              displayButtons={Number(stepId) !== 3}
              draft={draft}
              form={form}
            />
          )}
        </div>
      </CreateTaskContextProvider>
    </TaskContextProvider>
  )
}

export default CreateTaskPage
