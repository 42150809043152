import {makeStyles, Styles} from '@mui/styles'

import {ThemeProvider} from '../provider'
import {BaseThemeValues} from '../types'
import {themes, baseTheme} from '../themes'

type Theme = BaseThemeValues
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type StyleCreator = (theme: Theme) => Styles<Theme, any>

const uiStyles = (styleCreator: StyleCreator) => (): unknown =>
  styleCreator(themes[ThemeProvider.themeName] ?? baseTheme)

export const makeUiStyles = (
  styleCreator: StyleCreator,
  name?: string
): ReturnType<typeof makeStyles> =>
  makeStyles(uiStyles(styleCreator), {
    classNamePrefix: name,
    name,
  })
