import React, {FC} from 'react'
import {LogoProps, logoColors} from '../types'
import Blank from '../Blank'

const Logo: FC<LogoProps> = ({color, ...props}) => {
  if (color === logoColors.white) return <Blank color={color} {...props} />

  return (
    <svg fill="none" viewBox="0 0 261 100" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M31.3395 37.3179L31.3461 62.3904C29.4332 63.6493 27.1244 64.6511 24.4925 65.2773C19.3868 66.4966 16.1216 66.2 16.115 59.0355L16.1084 40.9562L0 44.8054L0.00659278 67.1755C0.00659278 78.2749 4.55812 86.6719 20.7457 82.8227C25.2115 81.7616 29.1165 79.9358 31.3527 78.4199L31.3593 99.9991L47.4678 96.1697L47.4545 33.4688L31.3395 37.3179Z"
        fill="black"
      />
      <path
        d="M82.7719 42.0634L71.5382 65.0003L68.6687 71.2355L68.5104 71.275L68.8271 64.6576L68.8205 45.3853L54.3875 48.8324L54.4007 94.5217L68.273 91.2262L79.4341 68.4079L82.3827 61.9684L82.4618 61.9486L82.2244 68.4606L82.2309 87.9109L96.6573 84.4835L96.6507 38.748L82.7719 42.0634Z"
        fill="black"
      />
      <path
        d="M164.508 22.5547L148.723 26.3248L139.877 49.9802L139.87 28.4405L125.358 31.9008L125.365 53.4339L116.506 34.0166L100.793 37.7603L111.407 57.8367L99.6914 83.766L115.8 79.9366L125.365 56.0374V77.6627L139.883 74.2155L139.877 52.5836L149.448 71.9416L165.557 68.1122L153.828 47.7326L164.508 22.5547Z"
        fill="black"
      />
      <path
        d="M197.041 14.7911L185.807 37.7808L182.931 44.0358L182.773 44.0753L183.09 37.4447V18.1262L168.657 21.5668L168.663 67.3748L182.542 64.0792L193.703 41.2082L196.652 34.7489L196.731 34.7292L196.494 41.2609V60.7573L210.927 57.33L210.92 11.4824L197.041 14.7911Z"
        fill="black"
      />
      <path
        d="M261 45.4257L246.409 24.8681L259.001 0L242.972 3.82942L231.738 28.7239L231.732 6.51199L217.299 9.95912L217.305 55.8132L231.738 52.3859V30.1674L244.179 49.4265L261 45.4257Z"
        fill="black"
      />
    </svg>
  )
}

export default Logo
