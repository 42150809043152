import {Box, Button, Tooltip, Typography} from '@x5-react-uikit/core'
import Breadcrumbs from '@root/components/Breadcrumbs'
import FlexboxRow from '@root/components/FlexboxRow'
import {Tab, TabList, Tabs} from '@root/components/kit'
import useNotify from '@root/hooks/useNotify'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {
  useGetActiveLaunchQuery,
  useStartLaunchMutation,
  useStopLaunchMutation,
} from '@root/redux/api/launchesApi'
import {useGetSystemMetaDataQuery} from '@root/redux/api/systemMetaDataApi'
import {useGetSystemActiveTaskQuery} from '@root/redux/api/systemsApi'
import {useGetTasksByFilterQuery} from '@root/redux/api/tasksApi'
import {useGetUserinfoQuery} from '@root/redux/api/userApi'
import SystemsCombobox from '@root/features/systems/components/SystemsCombobox'
import LaunchHistoryTab from './components/launchHistoryTab/LaunchHistoryTab'
import SystemSettingsTab from './components/systemSettingsTab/SystemSettingsTab'
import TemplatesTab from './components/templatesTab/TemplatesTab'
import {SpecialistToolsTabs} from './constants'
import SpecialistToolsContext from './context'
import useCreateOrUpdateMetadata from './hooks/useCreateOrUpdateMetadata'
import {getTasksFilter, isMetadataFilled} from './utils'
import {Roles} from '@root/constants'
import {Metadata} from './types'

const SectionTabsRow = ({sectionTab, setSectionTab}) => {
  const {data: userInfo} = useGetUserinfoQuery()
  return (
    <Tabs
      style={{alignSelf: 'start'}}
      value={sectionTab}
      onChange={(e, newTab) => setSectionTab(newTab)}
    >
      <TabList style={{display: 'block'}}>
        <Tab
          label={SpecialistToolsTabs.settings.label}
          value={SpecialistToolsTabs.settings.value}
        />
        {!userInfo.isBusiness && (
          <Tab
            label={SpecialistToolsTabs.templates.label}
            value={SpecialistToolsTabs.templates.value}
          />
        )}
        <Tab label={SpecialistToolsTabs.history.label} value={SpecialistToolsTabs.history.value} />
      </TabList>
    </Tabs>
  )
}

type FormValue = Metadata | {notInited: boolean}

const SpecialistToolsPage = () => {
  const {notifySuccess, notifyError} = useNotify()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const {data: userInfo} = useGetUserinfoQuery()

  const [sectionTab, setSectionTab] = useState(
    searchParams.get('tab') ? Number(searchParams.get('tab')) : SpecialistToolsTabs.settings.value
  )
  const [selectedSystemId, setSelectedSystemId] = useState(searchParams.get('systemId') || null)

  const {currentData: systemMetaData} = useGetSystemMetaDataQuery(
    {systemId: selectedSystemId},
    {skip: selectedSystemId == null}
  )

  const createOrUpdateMetadata = useCreateOrUpdateMetadata(selectedSystemId)
  const handleSetTemplateId = useCallback(
    (newTemplate) => {
      const data = {
        variables: newTemplate.variables,
        templateId: newTemplate.id,
      }
      createOrUpdateMetadata(data)
        .then(() => {
          setPreviousMeta(systemMetaData)
        })
        .catch((error) => {
          console.error(error)
          notifyError()
        })
    },
    [createOrUpdateMetadata, systemMetaData, notifyError]
  )

  const [previousMeta, setPreviousMeta] = useState(null)
  const handleSetPreviousTemplateId = useCallback(() => {
    createOrUpdateMetadata(previousMeta)
      .then(() => {
        setPreviousMeta(null)
      })
      .catch((error) => {
        console.error(error)
        notifyError()
      })
  }, [createOrUpdateMetadata, previousMeta, notifyError])

  const {currentData: tasks} = useGetTasksByFilterQuery(getTasksFilter(selectedSystemId) as any, {
    skip: !selectedSystemId,
  })
  const {currentData: activeTask} = useGetSystemActiveTaskQuery(selectedSystemId, {
    skip: !selectedSystemId,
  })
  const {currentData: activeLaunch} = useGetActiveLaunchQuery(
    {systemId: selectedSystemId, taskId: activeTask?.id},
    {skip: !selectedSystemId || !activeTask}
  )

  const {
    clearErrors: clearFormErrors,
    reset: resetFormValue,
    ...form
  } = useForm<FormValue>({defaultValues: {notInited: true}})
  useEffect(() => {
    if (systemMetaData)
      resetFormValue({
        variables: systemMetaData.variables,
        templateId: systemMetaData.templateId,
      })
  }, [systemMetaData, resetFormValue])

  // WARNING: возможно, следует добавить проверку статуса активной заявки
  // let startDisabledMsg = selectedSystemId && activeTask && systemMetaData;
  let startDisabledMsg
  if (!selectedSystemId) startDisabledMsg = 'ИС не выбрана'
  else if (!activeTask) startDisabledMsg = 'Отсутствует активная заявка'
  else if (!isMetadataFilled(systemMetaData as Metadata))
    startDisabledMsg = 'Имеются незаполненные параметры'

  const [startTest, {isLoading: isTestStarting}] = useStartLaunchMutation()
  const handleStartTest = () => {
    if (startDisabledMsg) return

    startTest({systemId: selectedSystemId, taskId: activeTask?.id})
      .unwrap()
      .then(() => {
        notifySuccess('Тест запущен')
      })
      .catch((error) => {
        notifyError('Не удалось запустить тест')
        console.error(error)
      })
  }

  let stopBtnShown = activeLaunch && !activeLaunch.finishedAt
  stopBtnShown &&=
    userInfo.isAdmin ||
    userInfo.isManager ||
    userInfo.isSpecialist ||
    (userInfo.isBusiness && activeLaunch.creator.role === (Roles.BUSINESS as string))

  const [stopTest, {isLoading: isTestStopping}] = useStopLaunchMutation()
  const handleStopTest = async () => {
    if (!activeLaunch) return
    stopTest({systemId: selectedSystemId, taskId: activeTask?.id})
      .unwrap()
      .then(() => {
        notifySuccess('Тест завершён')
      })
      .catch((error) => {
        notifyError('Не удалось завершить тест')
        console.error(error)
      })
  }

  let lastTask = tasks?.items[0]
  const handleNavigateToTask = () => {
    if (activeTask) navigate(`/tasks/${activeTask.id}`)
    else if (lastTask) navigate(`/tasks/${tasks.items[0].id}`)
    else notifyError()
  }

  const providerValue = useMemo(() => {
    return {
      selectedSystemId,
      setSectionTab,
      handleSetPreviousTemplateId,
      handleSetTemplateId,
      // selectedTemplate,
      form,
      clearFormErrors,
      resetFormValue,
      previousMeta,
      setPreviousMeta,
    }
  }, [
    selectedSystemId,
    handleSetPreviousTemplateId,
    handleSetTemplateId,
    form,
    clearFormErrors,
    resetFormValue,
    previousMeta,
  ])

  let currentTabContent
  if (sectionTab === SpecialistToolsTabs.settings.value) currentTabContent = <SystemSettingsTab />
  else if (sectionTab === SpecialistToolsTabs.templates.value)
    currentTabContent = <TemplatesTab systemId={selectedSystemId} />
  else if (sectionTab === SpecialistToolsTabs.history.value)
    currentTabContent = <LaunchHistoryTab systemId={selectedSystemId} />

  return (
    <>
      <Box sx={{mx: 'x8'}}>
        <Breadcrumbs
          routes={[
            {to: '/tasks', label: 'Главная'},
            {to: null, label: 'Рабочий стол тестировщика'},
          ]}
        />
        <FlexboxRow sx={{gap: '16px', mb: 'x12', justifyContent: 'space-between'}}>
          <Typography variant="h2">Раздел тестирования</Typography>
          <FlexboxRow sx={{gap: '16px'}}>
            <Tooltip content={!activeTask && !lastTask && 'Активная заявка отсутствует'}>
              <div>
                <Button
                  disabled={!activeTask && !lastTask}
                  variant="outlined"
                  onClick={handleNavigateToTask}
                >
                  Перейти к {activeTask ? 'активной' : 'последней'} заявке
                </Button>
              </div>
            </Tooltip>
            {stopBtnShown ? (
              <Button loading={isTestStopping} onClick={handleStopTest}>
                Завершить
              </Button>
            ) : (
              <Tooltip content={startDisabledMsg}>
                <div>
                  <Button
                    disabled={startDisabledMsg || isTestStarting}
                    loading={isTestStarting}
                    onClick={handleStartTest}
                  >
                    Запустить
                  </Button>
                </div>
              </Tooltip>
            )}
          </FlexboxRow>
        </FlexboxRow>
        <FlexboxRow sx={{justifyContent: 'space-between', alignItems: 'flex-end'}}>
          <SectionTabsRow key={sectionTab} sectionTab={sectionTab} setSectionTab={setSectionTab} />
          <SystemsCombobox
            key={selectedSystemId}
            selectedSystemId={selectedSystemId}
            width="588px"
            onChange={(e, systemId) => setSelectedSystemId(systemId)}
            onClear={() => setSelectedSystemId(null)}
          />
        </FlexboxRow>
        <Box sx={{mt: 'x12', width: '1200px'}}>
          <SpecialistToolsContext.Provider value={providerValue}>
            {currentTabContent}
          </SpecialistToolsContext.Provider>
        </Box>
      </Box>
    </>
  )
}

export default SpecialistToolsPage
