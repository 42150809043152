import React, {useRef, useState, FC, useEffect, FocusEvent} from 'react'
import {
  Input,
  Dropdown,
  Calendar,
  DropdownInnerRefMethods,
  inputSizes,
  freezeRangeValues,
} from '@x5-react-uikit/core'

import {getMinDate, getMaxDate} from '../Calendar/helpers'
import {calculateFontWidth} from '../utils/calculateSize'
import {
  createStepsStore,
  getStepsValue,
  setStep,
  setStepBySlide,
  setValueBySymbol,
  syncDateWithStepsStore,
  checkDate,
  isNumeric,
  getDateString,
  isFocused,
} from './helpers'

import {DatepickerProps} from './types'
import {StyledDatepicker, classes} from './styles'

export const defaultDateFormat = 'DD.MM.YYYY'

const Datepicker: FC<DatepickerProps> = ({
  dateFormat = defaultDateFormat,
  InputProps = {label: 'Дата'},
  DropdownProps,
  date,
  delimiter = ' — ',
  size = inputSizes.large,
  minDate,
  maxDate,
  disabledDates,
  width,
  onChange,
  onError,
  disabled,
  required,
  freezeRange,
  ...props
}) => {
  const normalizedMinDate = getMinDate(minDate, maxDate, dateFormat)
  const normalizedMaxDate = getMaxDate(minDate, maxDate, dateFormat)

  const isRange = Array.isArray(date)

  const stepsStoreRef = useRef(createStepsStore(date, dateFormat, delimiter))
  const defaultInputRef = useRef<HTMLInputElement>()
  const inputRef = InputProps.inputRef || defaultInputRef
  const dropdownRef = useRef<DropdownInnerRefMethods>({})

  const isDefaultFilled = Boolean(isRange ? (date as []).length : date)
  const [isInputFilled, fillInput] = useState(isDefaultFilled)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [coordsFreeze, setLeftFreeze] = useState(null)

  const checkDateOptions = {
    minDate: normalizedMinDate,
    maxDate: normalizedMaxDate,
    disabledDates,
    dateFormat,
    delimiter,
    isRange,
    freezeRange,
    onChange,
    onError: (info) => {
      let newDate = isRange ? [] : ''

      if (freezeRange === freezeRangeValues.start) {
        newDate = [date[0], '']
      }

      if (freezeRange === freezeRangeValues.end) {
        newDate = ['', date[1]]
      }

      const store = createStepsStore(newDate, dateFormat, delimiter)

      if (info.status !== 'invalid') {
        onError?.(info)
      } else {
        onChange(newDate, newDate)
      }

      if (freezeRange) {
        syncDateWithStepsStore({
          date: newDate,
          dateFormat,
          stepsStoreRef,
          newStepsStore: store,
          element: inputRef.current,
          freezeRange,
          normalizeDate: false,
        })
      } else {
        stepsStoreRef.current = createStepsStore(newDate, dateFormat, delimiter)
        inputRef.current.value = getStepsValue(stepsStoreRef.current)

        setStep({
          step: 0,
          store: stepsStoreRef.current,
          element: inputRef.current,
        })
      }
    },
  }

  const onKeyDown = (event) => {
    if (event.key === 'Tab') {
      inputRef.current.blur()
    }

    if (!['Escape', 'Enter'].includes(event.code)) {
      event.preventDefault()

      if (event.code === 'Space') return false
      setStepBySlide(event.key, stepsStoreRef.current, inputRef.current, freezeRange)
      setValueBySymbol(event.key, stepsStoreRef.current, inputRef.current, freezeRange)
    }
  }

  const onOpen = () => dropdownRef.current.open()
  const onMouseDown = (event) => {
    event.preventDefault()
    !isFocused(inputRef.current) && inputRef.current.focus()
  }

  const onChangeDropdown = (isOpen) => {
    if (!isOpen) {
      checkDate({
        ...checkDateOptions,
        date: inputRef.current.value,
        stepsStore: stepsStoreRef.current,
      })
    }
    setIsDropdownOpen(isOpen)
  }

  const onBlur = () => {
    if (!isDropdownOpen) {
      checkDate({
        ...checkDateOptions,
        date: inputRef.current.value,
        stepsStore: stepsStoreRef.current,
      })
    }
  }

  const onFocus = (event: FocusEvent<HTMLInputElement>) => {
    const value = getStepsValue(stepsStoreRef.current)

    if (!isNumeric(parseInt(value))) {
      inputRef.current.value = value
      setStep({
        step: 0,
        store: stepsStoreRef.current,
        element: inputRef.current,
        freezeRange,
      })
    } else
      setStep({
        step: stepsStoreRef.current.step,
        store: stepsStoreRef.current,
        element: inputRef.current,
        freezeRange,
      })

    fillInput(true)
    InputProps.onFocus?.(event)
  }

  const onChangeHandle = (formatted, value) => {
    onChange(formatted, value)
    if (isRange ? value.length === 2 : true) {
      dropdownRef.current.close()
    }
  }

  const isInputFocused = isFocused(inputRef.current)
  useEffect(() => {
    const hasDate = !!(isRange ? (date as []).length : date)

    if (!isInputFocused && hasDate) {
      syncDateWithStepsStore({
        date,
        dateFormat,
        stepsStoreRef,
        newStepsStore: createStepsStore(date, dateFormat, delimiter),
        element: inputRef.current,
        freezeRange,
      })
    }
    /* eslint-disable */
  }, [getDateString(date, dateFormat), isInputFocused])

  useEffect(() => {
    async function addFreezeLayout() {
      const date = getStepsValue(stepsStoreRef.current)
      const [dateChunk] = date.split(delimiter)

      const dateChunkWidth = await calculateFontWidth(dateChunk, inputRef.current)
      const delimiterWidth = await calculateFontWidth(delimiter, inputRef.current, {
        paddingLeft: '0px',
      })
      const coords = {
        left: freezeRange === freezeRangeValues.start ? 0 : dateChunkWidth + delimiterWidth,
        right: freezeRange === freezeRangeValues.end ? 0 : dateChunkWidth + delimiterWidth,
      }
      setLeftFreeze(coords)
    }

    if (isRange && freezeRange) {
      addFreezeLayout()
    }
  }, [])

  return (
    <StyledDatepicker coordsFreeze={coordsFreeze}>
      <Input
        {...InputProps}
        required={required}
        disabled={disabled}
        size={size}
        datePicker
        width={isRange && !width ? 255 : width}
        filled={isInputFilled}
        onFocus={onFocus}
        onBlur={onBlur}
        inputRef={inputRef}
        onDateClick={onOpen}
        inputProps={{onKeyDown, onMouseDown, className: classes.input}}
      />
      <Dropdown
        disableContentVisual
        innerRef={dropdownRef}
        onChange={onChangeDropdown}
        action=""
        {...DropdownProps}
      >
        <Calendar
          {...props}
          freezeRange={freezeRange}
          dateFormat={dateFormat}
          onChange={onChangeHandle}
          date={date}
          minDate={minDate}
          maxDate={maxDate}
          disabledDates={disabledDates}
          changeViewDateOnChangeDate
        />
      </Dropdown>
    </StyledDatepicker>
  )
}

export {Datepicker}
export default Datepicker
