import React, {FC} from 'react'
import {LogoProps, RenderSVGFunc, logoVariants, logoColors} from '../types'

function createLogo(renderSVGFun: RenderSVGFunc, displayName: string): FC<LogoProps> {
  const Component: FC<LogoProps> = ({variant, color, ...props}: LogoProps) => (
    <>{renderSVGFun(variant as logoVariants, color as logoColors, props)}</>
  )

  Component.displayName = displayName
  return Component
}

export default createLogo
