import {System} from '@root/features/systems/types'
import {TaskFormData} from '@root/features/tasks/types'
import {
  getOrCreateFieldData,
  initStepFormData,
} from '@root/pages/tasks/CreateTaskPage/utils/formUtils'
import {useGetTaskFormDataQuery, useGetTaskFormMetaQuery} from '@root/redux/api/formsApi'
import {pickBy} from 'lodash'
import {useEffect, useMemo, useState} from 'react'
import {useForm, UseFormReturn} from 'react-hook-form'
import {useParams} from 'react-router-dom'

type TaskPageFormValue =
  | {
      formData: TaskFormData
      system: Pick<System, 'description'>
      finportal: {
        cpp: string
        workCost: number
      }
    }
  | {loading: boolean}

// type FormValue = TaskFormData | {loading: boolean}

export type UseTaskPageForm = {
  isFormDataLoading: boolean
  form: UseFormReturn<TaskPageFormValue>
  formMeta: any
  stepIndices: any
}

export const getStepMeta = (formMeta, stepNumber) =>
  pickBy(formMeta, (field) => field.step === stepNumber)

const useTaskPageForm = (): UseTaskPageForm => {
  const {taskId} = useParams()

  const {data: taskFormMeta, isLoading: isTaskMetaLoading} = useGetTaskFormMetaQuery({taskId})
  const formMeta = useMemo(
    () =>
      taskFormMeta &&
      taskFormMeta.fields.reduce((meta, field) => ({...meta, [field.id]: field}), {}),
    [taskFormMeta]
  )

  const {data: taskFormData, isLoading: isTaskDataLoading} = useGetTaskFormDataQuery(
    {taskId},
    {skip: !taskFormMeta}
  )

  const form = useForm<TaskPageFormValue>({defaultValues: {loading: true}})

  const [stepIndices, setStepIndices] = useState({})

  useEffect(() => {
    if (!taskFormData) return
    // if (!getFormValues('loading')) return
    let formData = structuredClone(Object.values(taskFormData))
    let indices = {}
    for (let stepNum = 1; stepNum <= 3; stepNum++) {
      let stepIndex = formData.findIndex((step) => step.number === stepNum)
      // Проверяем наличие каждого шага в формдате.
      // Если шаг отсутствует -- инициализируем с полями с дефолтными значениями
      if (stepIndex === -1) {
        formData.push(initStepFormData(getStepMeta(formMeta, stepNum), stepNum) as any)
        stepIndex = formData.length - 1
      }
      // Проверяем наличие каждого поля, описанного в мете.
      // Если поле отсутствует -- инициализируем с дефолтным значением
      else {
        for (let fieldMeta of Object.values(formMeta).filter(
          (field: any) => field.step === stepNum
        )) {
          const fieldData = getOrCreateFieldData(fieldMeta, formData)
          if (fieldData.fieldCreated) {
            formData[stepIndex].fields[fieldData.fieldIndex] = fieldData.fieldValue
          }
        }
      }
      indices[stepNum] = stepIndex
    }
    setStepIndices(indices)
    form.reset({formData})
  }, [taskFormData, formMeta, form.reset])

  return {
    isFormDataLoading: isTaskMetaLoading || isTaskDataLoading || form.getValues('loading'),
    form,
    formMeta,
    stepIndices,
  }
}

export default useTaskPageForm
