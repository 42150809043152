import {Link, Typography} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import Section from '@root/components/Section'
import TypographyGrey from '@root/components/typography/TypographyGrey'
import {formattedDate} from 'utils'

const ReportDataAndTasksSection = ({form}) => {
  return (
    <Section sx={{display: 'flex', flexDirection: 'column', gap: '24px', my: 0, width: '588px'}}>
      <Typography variant="h3">Даты и задачи</Typography>
      <FlexboxRow sx={{justifyContent: 'space-between'}}>
        <FlexboxColumn sx={{width: '242px', gap: '4px'}}>
          <Typography>Завершение тестирования</Typography>
          <Typography>
            {form.getValues('testFinishedAt') && formattedDate(form.getValues('testFinishedAt'))}
          </Typography>
        </FlexboxColumn>
        <FlexboxColumn sx={{width: '242px', gap: '4px'}}>
          <Typography>Дата формирования отчета</Typography>
          <TypographyGrey variant="p2">Автоматически</TypographyGrey>
        </FlexboxColumn>
      </FlexboxRow>
      <FlexboxColumn sx={{width: '242px', gap: '4px'}}>
        <Typography>Задачи в Kaiten</Typography>
        {form.getValues('kaitenCardLink') ? (
          <Link
            style={{width: 'fit-content'}}
            onClick={() => window.open(form.getValues('kaitenCardLink'), '_blank')}
          >
            Перейти к связанным задачам
          </Link>
        ) : (
          <TypographyGrey>Не указаны</TypographyGrey>
        )}
      </FlexboxColumn>
    </Section>
  )
}

export default ReportDataAndTasksSection
