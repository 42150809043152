import FlexboxColumn from '@root/components/FlexboxColumn'
import {Typography} from '@x5-react-uikit/core'
import {ReactComponent as EmptySvg} from 'assets/Empty.svg'
import {FC} from 'react'

interface Props {
  message?: string
  description?: string
}

export const EmptyTableStub: FC<Props> = ({message = 'Ничего не найдено.', description}) => {
  return (
    <FlexboxColumn
      sx={{mt: 'x12', alignItems: 'center', justifyContent: 'center', height: '462px'}}
    >
      <EmptySvg />
      <FlexboxColumn sx={{mt: 'x20', gap: '8px', alignItems: 'center'}}>
        <Typography variant="h4">{message}</Typography>
        {description && <Typography variant="p2">{description}</Typography>}
      </FlexboxColumn>
    </FlexboxColumn>
  )
}
