import React, {forwardRef} from 'react'
import {getTableQA} from '../helpers'
import {DataGridHeadProps} from './types'
import {StyledHead} from './styles'

export const DataGridHead = forwardRef<HTMLTableSectionElement, DataGridHeadProps>(
  ({sticked, children, qa = 'head', ...other}, ref) => (
    <StyledHead ref={ref} data-qa={getTableQA(qa)} sticked={sticked} {...other}>
      <tr>{children}</tr>
    </StyledHead>
  )
)

export default DataGridHead
