import {makeUiStyles} from '../../theme'

export const useStyles = makeUiStyles(
  (theme) => ({
    root: {
      ...theme.typography.p1,
      position: 'relative',
      width: theme.spaces.x20,
      height: theme.spaces.x20,
      background: 'none',
      border: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      transition: 'background .15s',
      color: theme.colors.grey[100],
      borderRadius: theme.spaces.x2,

      '&:not(&$disabled, &$selected):hover': {background: theme.colors.grey[20]},
    },
    disabled: {
      color: theme.colors.grey[40],
      '&:hover': {borderRadius: 0},
    },
    otherMonthDay: {color: theme.colors.grey[60]},
    hide: {opacity: 0, visibility: 'hidden'},
    betweenPeriod: {
      background: theme.colors.accent[10],
      '&:not(:hover)': {borderRadius: 0},
    },
    selected: {
      background: theme.colors.accent[90],
      color: theme.colors.white,
      '&&:hover': {
        background: theme.colors.accent[90],
      },
    },
    selectedStart: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    selectedEnd: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    today: {
      '&:after': {
        position: 'absolute',
        content: '""',
        left: theme.spaces.x1,
        top: theme.spaces.x1,
        right: theme.spaces.x1,
        bottom: theme.spaces.x1,
        border: `1px solid ${theme.colors.accent[90]}`,
        borderRadius: theme.spaces.x2,
      },
    },
  }),
  'calendar-day'
)
