import {makeUiStyles} from '../../theme'

export const useStyles = makeUiStyles(
  () => ({
    root: {
      display: 'table-row-group',
    },
  }),
  'table-head'
)
