import React, {forwardRef} from 'react'
import {ButtonBaseProps} from './types'
import {StyledButtonBase} from './styles'

export const ButtonBase = forwardRef<HTMLButtonElement, ButtonBaseProps>(
  ({children, type = 'button', ...props}, ref) => (
    <StyledButtonBase ref={ref} type={type} {...props}>
      {children}
    </StyledButtonBase>
  )
)

export default ButtonBase
