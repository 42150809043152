import {makeUiStyles} from '../../../theme'
import {TableStyleHelpers} from '../../Cell/styles'

export const useInnerNumberStyles = makeUiStyles(
  (theme) => ({
    innerRootView: {
      textAlign: 'right',
    },
    innerRootEdit: {
      ...TableStyleHelpers.getInnerInputWrap(theme),
    },
    innerInputNumber: {
      boxSizing: 'border-box',
      width: '100%',
      textAlign: 'right',
      outline: 'none',
      border: 'none',
      borderRadius: theme.spaces.x2,
      ...TableStyleHelpers.getCellFont(theme),
    },
    errorIcon: {
      color: theme.colors.error,
    },
  }),
  'table-cell-inner-number'
)
