import React, {FC, useCallback, useMemo, useRef, useState} from 'react'
import {DndContext, closestCenter} from '@dnd-kit/core'
import {SortableContext, arrayMove} from '@dnd-kit/sortable'

import {Button} from '../Button'
import {sizeTokenValues} from '@x5-react-uikit/tokens'

import {DropdownAction as Action} from './DropdownAction'
import {Content} from './Content'

import {StyledContainer, classes} from './styles'
import {FilterCollectorFilter, FilterCollectorProps} from './types'
import {excludeEmptyFilters} from './helpers'
import {Dropdown, DropdownInnerRefMethods} from '../Dropdown'
import {inputSizes} from '../Input'
import {isEmpty} from 'lodash'

export const filterCollectorClasses = classes
export const FilterCollector: FC<FilterCollectorProps> = ({
  filters,
  columns,
  size = inputSizes.small,
  onChange,
  onApply,
  onReset,
}) => {
  const dropdownRef = useRef<DropdownInnerRefMethods>({})
  const filterIDs = useMemo(() => filters.map((filter) => filter.name), [filters])
  const [activeFilterId, setActiveFilterId] = useState<FilterCollectorFilter['name'] | null>(null)
  const [isApplied, setApply] = useState(false)

  const handleClose = useCallback(() => dropdownRef.current.close(), [])

  const handleResetFilters = useCallback(() => {
    setApply(false)
    onChange([])
    onReset?.()
  }, [onChange, onReset])

  const handleApply = useCallback(() => {
    const newFilters = excludeEmptyFilters(filters)

    dropdownRef.current.close()
    setTimeout(() => {
      onChange(newFilters)
      setApply(true)
      onApply?.(newFilters)
    })
  }, [filters, onApply, onChange])

  const handleDragStart = useCallback(({active}) => {
    if (active) {
      setActiveFilterId(active.id)
    }
  }, [])

  const handleDragEnd = useCallback(
    ({over}) => {
      setActiveFilterId(null)

      if (over) {
        const overIndex = filterIDs.indexOf(over.id)
        const activeIndex = filterIDs.indexOf(activeFilterId)

        if (activeIndex !== overIndex) {
          const sortedFilters = arrayMove(filters, activeIndex, overIndex)
          onChange(sortedFilters)
        }
      }
    },
    [activeFilterId, filterIDs, filters, onChange]
  )

  const handleToggleDropdown = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) {
        const newFilters = excludeEmptyFilters(filters)
        onChange(newFilters)
        setApply(true)
        onApply?.(newFilters)
      }
    },
    [filters, onApply, onChange]
  )

  const handleChange = useCallback(
    (filters: FilterCollectorFilter[]) => {
      onChange(filters)
      setApply(false)
    },
    [onChange]
  )

  const appliedLength = useMemo(() => filters.filter((x) => !isEmpty(x.value)).length, [filters])

  return (
    <Dropdown
      disableContentVisual
      action={<Action count={isApplied ? appliedLength : 0} iconSize={size} />}
      innerRef={dropdownRef}
      onChange={handleToggleDropdown}
    >
      <StyledContainer>
        <div className={classes.header}>Настройка фильтра</div>
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
        >
          <SortableContext items={filterIDs}>
            <Content columns={columns} filters={filters} size={size} onChange={handleChange} />
          </SortableContext>
        </DndContext>
        <div className={classes.footer}>
          <div className={classes.footerLeft}>
            {filters.length ? (
              <Button size={sizeTokenValues.small} variant="text" onClick={handleResetFilters}>
                Сбросить фильтры
              </Button>
            ) : null}
          </div>
          <div className={classes.footerRight}>
            <Button size={sizeTokenValues.small} variant="outlined" onClick={handleClose}>
              Закрыть
            </Button>
            <Button
              disabled={!filters.length}
              size={sizeTokenValues.small}
              variant="primary"
              onClick={handleApply}
            >
              Применить
            </Button>
          </div>
        </div>
      </StyledContainer>
    </Dropdown>
  )
}

export default FilterCollector
