import React, {FC, useEffect, useState} from 'react'
import {defaultDateFormat} from '@x5-react-uikit/core'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import 'dayjs/locale/ru'

import Block from './Block'

import {
  createBlock,
  generateDays,
  normalizeChangedDate,
  addFreezedDateToDisabledDates,
  normalizeViewDate,
  getMinDate,
  getMaxDate,
} from './helpers'
import {getDateString} from '../Datepicker/helpers'

import {getQaAttribute} from '../utils'
import {CalendarProps} from './types'
import {useStyles} from './styles'
import clsx from 'clsx'

dayjs.extend(customParseFormat)
dayjs.locale('ru')

const Calendar: FC<CalendarProps> = ({
  dateFormat = defaultDateFormat,
  viewDate: viewDateFromProps = dayjs(),
  onChangeViewDate: onChangeViewDateFromProps,
  disabledDates: disabledDatesFromProps,
  minDate,
  maxDate,
  date,
  long,
  onChange,
  changeViewDateOnChangeDate,
  qa = 'calendar',
  className,
  freezeRange,
}) => {
  const getQA = getQaAttribute(qa)
  const classes = useStyles()

  const normalizedMinDate = getMinDate(minDate, maxDate, dateFormat)
  const normalizedMaxDate = getMaxDate(minDate, maxDate, dateFormat)

  const disabledDates = addFreezedDateToDisabledDates({
    freezeRange,
    date,
    dateFormat,
    maxDate: normalizedMaxDate,
    minDate: normalizedMinDate,
    disabledDates: disabledDatesFromProps,
  })

  const [hoverDate, setHoverDate] = useState('')
  const [viewDate, updateViewDate] = useState(viewDateFromProps)
  const normalizedViewDate = normalizeViewDate({
    viewDate,
    dateFormat,
    minDate: normalizedMinDate,
    maxDate: normalizedMaxDate,
  })

  const nextViewDate = normalizedViewDate.month(normalizedViewDate.month() + 1)
  const [blockElements, updateBlockElements] = useState(
    createBlock(
      generateDays({
        date: normalizedViewDate,
        minDate: normalizedMinDate,
        maxDate: normalizedMaxDate,
        dateFormat,
        hideOtherMonthDays: long,
      })
    )
  )

  const onChangeDate = (value) => {
    const newDate = normalizeChangedDate(value, date, dateFormat, freezeRange)
    onChange(
      Array.isArray(newDate) ? newDate.map((item) => (item ? item.format(dateFormat) : '')) : value,
      newDate
    )
  }

  const onChangeViewDate = (value, unit) => {
    let newDate = dayjs(value, dateFormat)

    if (unit === 'year') {
      newDate = newDate.month(normalizedViewDate.month())
    }

    newDate = normalizeViewDate({
      viewDate: newDate,
      dateFormat,
      minDate: normalizedMinDate,
      maxDate: normalizedMaxDate,
    })

    if (onChangeViewDateFromProps) {
      onChangeViewDateFromProps(newDate.format(dateFormat), newDate)
    }

    updateViewDate(newDate)
    updateBlockElements(
      createBlock(
        generateDays({
          date: newDate,
          minDate: normalizedMinDate,
          maxDate: normalizedMaxDate,
          dateFormat,
          hideOtherMonthDays: long,
        })
      )
    )
  }

  /*
   * Меняем viewDate, если поменялся date
   * Это нужно когда календарь открыт и мы вводим дату вручную в Datepicker
   * */

  useEffect(() => {
    if (changeViewDateOnChangeDate) {
      const actualDate = Array.isArray(date) ? date[long ? 0 : 1] : date

      if (actualDate) {
        onChangeViewDate(actualDate, '')
      }
    }
    /* eslint-disable */
  }, [changeViewDateOnChangeDate, getDateString(date, dateFormat)])

  return (
    <div className={clsx(classes.root, className)} data-qa={getQA()}>
      <Block
        hoverDate={hoverDate}
        onChangeHoverDate={setHoverDate}
        dateFormat={dateFormat}
        viewDate={normalizedViewDate}
        minDate={normalizedMinDate}
        maxDate={normalizedMaxDate}
        date={date}
        elements={blockElements}
        onChangeViewDate={onChangeViewDate}
        onChangeDate={onChangeDate}
        long={long}
        hideArrow={long ? 'right' : null}
        disabledDates={disabledDates}
        qa={getQA('block-1')}
        freezeRange={freezeRange}
      />
      {long && (
        <Block
          hoverDate={hoverDate}
          onChangeHoverDate={setHoverDate}
          className={classes.offsetBlock}
          dateFormat={dateFormat}
          viewDate={nextViewDate}
          minDate={normalizedMinDate}
          maxDate={normalizedMaxDate}
          date={date}
          onChangeViewDate={onChangeViewDate}
          onChangeDate={onChangeDate}
          long={long}
          hideArrow="left"
          disabledDates={disabledDates}
          qa={getQA('block-2')}
          freezeRange={freezeRange}
          elements={createBlock(
            generateDays({
              date: nextViewDate,
              minDate: normalizedMinDate,
              maxDate: normalizedMaxDate,
              dateFormat,
              hideOtherMonthDays: long,
            })
          )}
        />
      )}
    </div>
  )
}

export default Calendar
export {Calendar}
