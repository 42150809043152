import {Box, Link, Typography, baseTheme} from '@x5-react-uikit/core'
import {ChevronRight} from '@x5-react-uikit/icons'
import {Fragment} from 'react'
import {useNavigate} from 'react-router-dom'

const {colors} = baseTheme

export default function Breadcrumbs({routes}) {
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        margin: '24px 0 8px 0',
        gap: '4px',
      }}
    >
      {routes.map((route, index) => {
        const routeEl = route.to ? (
          <Link component={Typography} variant="p3" onClick={() => navigate(route.to)}>
            {route.label}
          </Link>
        ) : (
          <Typography key={route.label} style={{color: colors.grey[50]}} variant="p3">
            {route.label}
          </Typography>
        )
        return index !== routes.length - 1 ? (
          <Fragment key={route.to}>
            {routeEl}
            <ChevronRight size="small" />
          </Fragment>
        ) : (
          routeEl
        )
      })}
    </Box>
  )
}
