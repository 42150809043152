import {Typography, baseTheme} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import Section from '@root/components/Section'
import SectionField from './SectionField'
import {useGetActiveLaunchQuery} from '@root/redux/api/launchesApi'
import {useGetSystemActiveTaskQuery} from '@root/redux/api/systemsApi'

const {colors} = baseTheme

const LastRunSection = ({systemId}) => {
  const {currentData: activeTask} = useGetSystemActiveTaskQuery(systemId, {skip: !systemId})
  const {currentData: activeLaunch} = useGetActiveLaunchQuery(
    {systemId: systemId, taskId: activeTask?.id},
    {skip: !systemId || !activeTask}
  )

  return (
    <Section sx={{my: 0, width: '588px'}}>
      <FlexboxColumn sx={{gap: '24px'}}>
        <Typography variant="h3">Данные о последнем запуске</Typography>
        <SectionField>
          <Typography>Инициатор запуска</Typography>
          {activeLaunch?.creator ? (
            <Typography>
              {`${activeLaunch?.creator.name || ''} ${activeLaunch?.creator.email}`}
            </Typography>
          ) : (
            <Typography style={{color: colors.grey[60]}}>Отсутствует</Typography>
          )}
        </SectionField>
        <SectionField>
          <Typography>Отчет</Typography>
          {true ? (
            <Typography style={{color: colors.grey[60]}}>Не сформирован</Typography>
          ) : (
            <Typography>-</Typography>
          )}
        </SectionField>
      </FlexboxColumn>
    </Section>
  )
}

export default LastRunSection
