import {DropdownInnerRefMethods} from '@root/components/kit'
import {RequiredRule} from '@root/constants'
import {Button, Dropdown, Typography, baseTheme} from '@x5-react-uikit/core'
import {ArrowDown as ArrowDownIcon, Info} from '@x5-react-uikit/icons'
import FlexboxRow from '@root/components/FlexboxRow'
import Section from '@root/components/Section'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'
import useNotify from '@root/hooks/useNotify'
import useCreateOrUpdateMetadata from '@root/pages/SpecialistToolsPage/hooks/useCreateOrUpdateMetadata'
import {
  UniqueVarNameRule as UniqueVarKeyRule,
  getDuplicatedVarRegisters,
} from '@root/pages/SpecialistToolsPage/utils'
import {useContext, useRef} from 'react'
import {useGetSystemMetaDataQuery} from '@root/redux/api/systemMetaDataApi'
import {useCreateTemplateMutation} from '@root/redux/api/templatesApi'
import SpecialistToolsContext from '../../context'

const UniqueTemplateName = {type: 'custom', message: 'Название должно быть уникальным'}

const {colors} = baseTheme

const Banner = () => (
  <FlexboxRow
    sx={{
      height: '32px',
      borderRadius: 'x2',
      backgroundColor: 'accent.10',
      p: 'x4',
      gap: '4px',
    }}
  >
    <Info color={colors.accent[80]} size="small" />
    <Typography variant="p3">Название должно быть уникальным</Typography>
  </FlexboxRow>
)

const SaveNewTemplateButton = ({form, closeModal}) => {
  const {notifySuccess, notifyError} = useNotify()

  const {setPreviousMeta, selectedSystemId: systemId} = useContext(SpecialistToolsContext)

  const dropdownRef = useRef<DropdownInnerRefMethods>()

  const {currentData: systemMetaData} = useGetSystemMetaDataQuery(
    {systemId},
    {skip: systemId == null}
  )

  const [createTemplate, {isLoading: isCreateLoading}] = useCreateTemplateMutation()
  const createOrUpdateMetadata = useCreateOrUpdateMetadata(systemId)
  const handleCreateTemplate = form.handleSubmit(() => {
    const formValue = form.getValues()
    if (!formValue.name) {
      form.setError('name', RequiredRule)
      return
    }
    const duplicats = getDuplicatedVarRegisters(formValue.variables)
    if (duplicats.length > 0) {
      duplicats.forEach((registerName) => form.setError(registerName, UniqueVarKeyRule))
      return
    }
    const createData = {
      name: formValue.name,
      variables: formValue.variables,
      systemId,
    }
    createTemplate({systemId, createData})
      .unwrap()
      .then((createdTemplate) => {
        return createOrUpdateMetadata({
          templateId: createdTemplate.id,
          variables: createdTemplate.variables,
        })
      })
      .then(() => {
        setPreviousMeta(systemMetaData)
        notifySuccess(`Новый шаблон сохранён`)
        dropdownRef?.current?.close()
        closeModal()
      })
      .catch((error) => {
        if (error.status === 409) {
          form.setError('name', UniqueTemplateName)
        } else {
          console.error(error)
          notifyError()
        }
      })
  })

  return (
    <Dropdown
      updateOnOpen
      action={
        <Button
          endIcon={<ArrowDownIcon color={colors.grey[60]} size="small" />}
          size="small"
          variant="outlined"
        >
          Сохранить как новый шаблон
        </Button>
      }
      innerRef={dropdownRef}
      placement="top-start"
    >
      <Section
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          my: 'x0',
          p: 'x5',
          width: '320px',
        }}
      >
        <Banner />
        <FormInputText
          required
          control={form.control}
          label="Название шаблона"
          name={'name'}
          size={'small'}
          width="100%"
        />
        <FlexboxRow sx={{gap: '8px', width: '100%', justifyContent: 'flex-end'}}>
          <Button size="small" variant="text" onClick={() => dropdownRef?.current?.close()}>
            Отмена
          </Button>
          <Button
            loading={isCreateLoading}
            size="small"
            variant="outlined"
            onClick={handleCreateTemplate}
          >
            Сохранить
          </Button>
        </FlexboxRow>
      </Section>
    </Dropdown>
  )
}

export default SaveNewTemplateButton
