import {makeUiStyles} from '../theme'
import {DropdownProps} from './types'

export const useDropdownStyles = makeUiStyles((theme) => ({
  container: {
    width: ({stretch}: DropdownProps) => (stretch ? '100%' : 'fit-content'),
    maxWidth: '100%',
  },
  content: {
    width: ({width}: DropdownProps) => width,
    zIndex: 999,
    maxWidth: '100%',
  },
  contentVisual: {
    borderRadius: theme.spaces.x4,
    backgroundColor: theme.colors.white,
    boxShadow: theme.shadows.medium,
    padding: theme.spaces.join('x4', 'x0'),
  },
  hide: {
    display: 'none',
  },
}))
