import {Box, type BoxProps} from 'ui-kit'
import {ReactNode} from 'react'

const Section = ({children, sx}: {children: ReactNode; sx?: BoxProps['sx']}) => {
  return (
    <Box
      sx={{
        my: 'x12',
        p: 'x12',
        backgroundColor: 'white' as any,
        borderRadius: 'x4',
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

export default Section
