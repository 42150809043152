import styled from '@emotion/styled'
import {baseTheme} from '@x5-react-uikit/core'
import {animated} from '@react-spring/web'
import {ArrowDown, ArrowUp as ArrowUpIcon} from '@x5-react-uikit/icons'
import React, {CSSProperties, forwardRef} from 'react'
import clsx from 'clsx'
import {FadeInOut} from '@root/components/FadeInOut'
import {sizeTokenValues} from '@x5-react-uikit/tokens'
import type {ExtractConstValues} from '@root/core/helperTypes'
import {sortDirections} from '@root/components/kit'

const hasSortClass = 'has-sort'
const activeClass = 'active'

const StyledArrowsButton = styled.button`
  height: 24px;
  width: 24px;
  box-shadow: none;
  border: none;
  outline: none;
  background: transparent;
  border-radius: 2px;
  display: block;
  cursor: pointer;
  position: absolute;
  right: -4px;
  top: -4px;

  transition:
    color 100ms ease,
    background-color 100ms ease;

  svg {
    color: ${baseTheme.colors.grey[60]};

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    background: ${baseTheme.colors.grey[50]};
    svg {
      color: #50565e;
    }
  }

  &.${activeClass} {
    background: ${baseTheme.colors.grey[40]};
    svg {
      color: #50565e;
    }
  }

  &.${hasSortClass} {
    svg {
      color: ${baseTheme.colors.accent[70]};
    }
  }

  &.${hasSortClass}.${activeClass} {
    svg {
      color: ${baseTheme.colors.grey[70]};
    }
  }
`

const AnimatedArrowUpIcon = animated(ArrowUpIcon)
const AnimatedArrowDownIcon = animated(ArrowDown)

type SortDirections = ExtractConstValues<typeof sortDirections>

type Props = {
  open: boolean
  dir: SortDirections
  style?: CSSProperties
}
export const ArrowsButton = forwardRef<HTMLButtonElement, Props>(({open, dir, style}, ref) => {
  const hasSort = dir !== 'unset'

  const showArrowUp = dir === 'desc'

  return (
    <StyledArrowsButton
      ref={ref}
      className={clsx({
        [activeClass]: open,
        [hasSortClass]: hasSort,
      })}
      style={style}
    >
      <FadeInOut
        render={(styles) => <AnimatedArrowDownIcon size={sizeTokenValues.small} style={styles} />}
        show={!showArrowUp}
      />
      <FadeInOut
        render={(styles) => <AnimatedArrowUpIcon size={sizeTokenValues.small} style={styles} />}
        show={showArrowUp}
      />
    </StyledArrowsButton>
  )
})
