import React, {FC} from 'react'
import {defineColors} from '../utils/defineColors'
import {LogoProps} from '../types'

const primary = '#5faf2d'
const getPathColor = defineColors('#000000')

const Logo: FC<LogoProps> = ({color, ...props}) => (
  <svg fill="none" viewBox="0 0 226 100" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M160.454 45.791H167.352V98.9177H160.454V45.791Z" fill={getPathColor(color)} />
    <path
      d="M180.242 45.791H196.619C218.151 45.791 225.886 57.7821 225.886 71.5953C225.886 86.7746 216.636 98.9177 195.862 98.9177H180.242V45.791ZM187.141 52.2425V92.4679H196.315C212.01 92.4679 218.682 83.665 218.682 71.597C218.682 60.5161 212.767 52.2442 196.467 52.2442H187.141V52.2425Z"
      fill={getPathColor(color)}
    />
    <path
      d="M93.5184 98.9189L82.5238 80.6266L71.756 98.9189H58.8661L75.4255 71.366L58.5471 45.7871H72.0136L82.8257 62.8653L93.138 45.7871H106.028L89.4242 72.201L106.483 98.9189H93.5184Z"
      fill={getPathColor(color)}
    />
    <path
      d="M148.384 80.8579C148.384 92.5467 139.588 99.8333 128.063 99.8333C117.903 99.8333 109.349 94.2168 108.515 81.9969H119.51C120.267 87.3094 124.515 89.5874 129.14 89.5874C134.902 89.5874 137.39 85.7161 137.39 80.7076C137.39 75.4702 133.522 72.2069 128.138 72.2069C123.665 72.2069 120.707 74.3329 119.343 76.6092H108.834L112.466 45.793H147.031L145.878 56.1157H121.153L120.17 66.3138C120.17 66.3138 122.766 63.252 130.637 63.252C140.119 63.2486 148.384 69.6215 148.384 80.8579Z"
      fill={getPathColor(color)}
    />
    <path
      d="M109.817 39.174C103.959 16.3188 85.3778 0.261719 46.8173 0.261719H0.261047L6.58147 43.0401C11.3359 81.6398 26.0835 94.0578 52.1824 98.6309C52.3035 98.6497 53.1309 98.7675 53.1411 97.7993C53.1445 97.451 52.9244 97.1504 52.6225 97.0206C34.9816 89.5855 32.9686 65.2037 29.2002 40.7365L26.2848 21.2368H52.8613C77.3634 21.2368 96.2821 20.8492 108.259 39.7836C108.437 40.05 108.715 40.2464 109.044 40.2464C109.506 40.2464 109.851 39.8707 109.851 39.4079C109.851 39.3242 109.839 39.2457 109.817 39.174Z"
      fill={getPathColor(color, primary)}
    />
  </svg>
)

export default Logo
