import {useEffect, useState} from 'react'

const useLazyModal = () => {
  const [modalShown, setModalShown] = useState(false)
  const [modalTouched, setModalTouched] = useState(undefined)
  useEffect(() => {
    if (modalShown) {
      setModalTouched(true)
    }
  }, [modalShown])

  return {modalShown, setModalShown, modalTouched}
}

export default useLazyModal
