import {CSSProperties} from 'react'
import isPropValid from '@emotion/is-prop-valid'
import {BoxProps} from './types'
import {BaseThemeValues} from '../theme/types'
import {aliases, multiples, scales} from './helpers'
import styled from '@emotion/styled'

const getStyles = (theme: BaseThemeValues, cssProps: BoxProps['sx']): CSSProperties => {
  if (!cssProps) return null
  const styles = {}
  Object.keys(cssProps).forEach((cssKey) => {
    const name = cssKey !== 'bg' ? cssKey : 'backgroundColor'
    const isColors = scales[name] === 'colors' || cssKey === 'bg'
    if (['children', 'as'].includes(name)) return
    if (isColors && ['white', 'success', 'error'].includes(cssProps[name])) {
      styles[name] = theme.colors[cssProps[name]]
      return
    }
    if (isColors && ['accent', 'grey'].some((str) => cssProps[cssKey]?.startsWith(str))) {
      const [shade, level] = cssProps[cssKey]?.split('.')
      styles[name] = theme.colors[shade][level]
      return
    }
    if (isColors) {
      const [shade, level] = cssProps[cssKey]?.split('.')
      styles[name] = theme.palette[shade][level]
      return
    }
    if (aliases[name] && multiples[aliases[name]]) {
      multiples[aliases[name]].forEach(
        (multiple: keyof typeof multiples) => (styles[multiple] = theme.spaces[cssProps[name]])
      )
      return
    }
    if (aliases[name]) {
      styles[aliases[name]] = theme.spaces[cssProps[name]]
      return
    }
    if (scales[name] === 'radii') {
      styles[name] = theme.spaces[cssProps[name]]
      return
    }
    styles[name] = cssProps[name]
  })
  return styles
}

export const Box = styled('div', {
  shouldForwardProp: (prop) => !['color', 'width', 'height'].includes(prop) && isPropValid(prop),
})<BoxProps>(({theme, ...props}) => {
  return {
    boxSizing: 'border-box',
    margin: 0,
    minWidth: 0,
    ...getStyles(theme, props),
    ...getStyles(theme, props.sx),
    ...props.css,
  }
})
