import {Modal} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FormInputDatetime from '@root/components/inputs/formInputs/FormInputDatetime'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'
import {RequiredRule} from '@root/constants'
import useNotify from '@root/hooks/useNotify'
import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useParams} from 'react-router-dom'
import {useGetSystemByIdQuery} from '@root/redux/api/systemsApi'
import {useGetTaskByIdQuery, useUpdateTaskStatusMutation} from '@root/redux/api/tasksApi'
import SubmitChangeStatus from './SubmitChangeStatus'
import {MODAL_INPUT_WIDTH_PX} from '@root/constants'
import {TaskStatuses} from '@root/constants'

const ChangeToNotRequiredModal = ({open, onClose, onOpen}) => {
  const {taskId} = useParams()
  const {notifyError, notifySuccess} = useNotify()

  const {reset: formReset, ...form} = useForm({defaultValues: {loading: true}})

  const [approveModalShown, setApproveModalShown] = useState(false)
  const closeEditModal = () => {
    onClose()
    form.clearErrors()
    formReset({
      expirationDate: system.expirationDate,
      comment: system.comment,
    })
  }
  const showApproveModal = form.handleSubmit(() => {
    setApproveModalShown(true)
    onClose()
  })
  const closeApproveModal = () => setApproveModalShown(false)
  const cancelApproveModal = () => {
    closeApproveModal()
    onOpen()
  }

  const {data: task} = useGetTaskByIdQuery(taskId)
  const {data: system} = useGetSystemByIdQuery(task?.system.id, {skip: !task})
  useEffect(() => {
    if (!task || !system) return
    formReset({
      expirationDate: system.expirationDate,
      comment: system.comment,
    })
  }, [task, system, formReset])

  // const [updateTask] = useUpdateTaskMutation();
  const [updateTaskStatus] = useUpdateTaskStatusMutation()
  const handleSetNotRequired = () => {
    const formValue = form.getValues()
    const updateData = {
      nextTaskStatus: TaskStatuses.NOT_REQUIRED,
      systemStatusExpirationDate: formValue.expirationDate,
      systemStatusComment: formValue.comment,
    }
    updateTaskStatus({taskId, updateData})
      .unwrap()
      .then(() => {
        notifySuccess(
          'Заявка переведена в статус “НТ не требуется”',
          'Участники заявки получат уведомление об этом в outlook.'
        )
        closeApproveModal()
      })
      .catch((error) => {
        console.error(error)
        notifyError()
      })
  }

  return (
    <>
      <Modal
        closeOnOverlay
        buttons={[
          {
            onClick: closeEditModal,
            side: 'right',
            text: 'Отмена',
          },
          {
            onClick: showApproveModal,
            side: 'right',
            text: 'НТ не требуется',
          },
        ]}
        description="Убедитесь, что все пункты для изменения статуса выполнены"
        isOpen={open}
        size="medium"
        title="Перевод в статус “НТ не требуется”"
        onClose={closeEditModal}
      >
        {!form.getValues('loading') && open && (
          <FlexboxColumn sx={{gap: '24px'}}>
            <FormInputDatetime
              key="expirationDate"
              required
              caption="
              Выберите дату окончания действия статуса. 
              По ее истечению статус изменится на “НТ отсутствует”
            "
              control={form.control}
              label="Срок действия статуса"
              name="expirationDate"
              rules={RequiredRule}
              width={MODAL_INPUT_WIDTH_PX}
            />
            <FormInputText
              key="comment"
              required
              textarea
              control={form.control}
              label="Резолюция (комментарий) по НТ"
              name="comment"
              rules={RequiredRule}
              width={MODAL_INPUT_WIDTH_PX}
            />
          </FlexboxColumn>
        )}
      </Modal>
      <SubmitChangeStatus
        handleChangeStatus={handleSetNotRequired}
        message="
          Статус заявки перейдет с “На рассмотрении” в завершающий статус “НТ не требуется”. 
          Участники заявки будут оповещены об этом.
        "
        open={!open && approveModalShown}
        onCancel={cancelApproveModal}
        onClose={closeApproveModal}
      />
    </>
  )
}

export default ChangeToNotRequiredModal
