import {Typography} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import {ReactComponent as SystemNotSelectedSvg} from '../../assets/SystemNotSelected.svg'
import {FC} from 'react'
import type {BoxProps} from 'ui-kit'

type Props = {
  sx?: BoxProps['sx']
}

const SystemNotSelectedMessage: FC<Props> = ({sx}) => {
  return (
    <FlexboxColumn sx={{alignItems: 'center', justifyContent: 'center', height: '184px', ...sx}}>
      <SystemNotSelectedSvg />
      <FlexboxColumn sx={{mt: 'x20', gap: '8px', alignItems: 'center'}}>
        <Typography variant="h4">ИС не выбрана</Typography>
        <Typography style={{textAlign: 'center'}} variant="p1">
          Выберите Информационную систему, затем заявку, чтобы работать по ней
        </Typography>
      </FlexboxColumn>
    </FlexboxColumn>
  )
}

export default SystemNotSelectedMessage
