import {TextareaProps} from './types'
import {PREFIX, restyled} from '../styles'

const borderWidth = 1
export const textareaClasses = {
  textarea: `${PREFIX}-textarea`,
  asterisk: `${PREFIX}-textarea-asterisk`,
  container: `${PREFIX}-textarea-container`,
} as const

export const StyledWrapper = restyled.div<Partial<TextareaProps> & {focused: boolean}>(
  ({theme, isAbsoluteCaption, width, focused, disabled, error, filled}) => {
    const getBorderColor = (hovered?: boolean) => {
      if (disabled) return theme.colors.grey[20]
      if (error) return theme.colors.error
      if (focused) return theme.colors.focus
      if (hovered) return theme.colors.grey[40]

      return theme.colors.grey[30]
    }

    const getBoxShadow = () => {
      if (disabled) return null
      if (error) return `0 0 0 1px ${theme.colors.error}`
      if (focused) return `0 0 0 1px ${theme.colors.focus}`
      return null
    }

    return {
      position: 'relative',
      marginBottom: isAbsoluteCaption ? theme.spaces.x12 : null,
      width: width ?? theme.widths.field,

      [`.${textareaClasses.container}`]: {
        position: 'relative',
        display: 'flex',
        overflow: 'hidden',
        borderRadius: 4,
        borderWidth,
        borderStyle: 'solid',
        borderColor: getBorderColor(),
        boxShadow: getBoxShadow(),
        pointerEvents: disabled ? 'none' : null,
        ':hover': {
          borderColor: getBorderColor(true),
        },
      },

      [`.${textareaClasses.textarea}`]: {
        ...theme.typography.p1,
        caretColor: theme.colors.focus,
        width: '100%',
        minWidth: '100%',
        maxWidth: '100%',
        boxSizing: 'border-box',
        border: 'none',
        outline: 'none',
        padding: theme.spaces.x6,
        paddingTop: filled ? theme.spaces.x10 : null,
        paddingBottom: filled ? theme.spaces.x2 : null,
        color: disabled ? theme.colors.grey[40] : 'initial',
        backgroundColor: 'transparent',
      },
    }
  }
)

export const StyledLabel = restyled.label<Partial<TextareaProps>>(
  ({theme, filled, disabled, labelStyle}) => {
    const color = disabled ? theme.colors.grey[40] : theme.colors.grey[60]
    const sizeAsterisk = 5

    return {
      position: 'absolute',
      backgroundColor: filled ? theme.colors.white : null,
      zIndex: 1,
      paddingLeft: theme.spaces.x6,
      paddingTop: theme.spaces.x6,
      right: 0,
      top: 0,
      left: 0,
      overflow: 'hidden',
      transform: `translateY(-${filled ? 6 : 0}px)`,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color,

      ...theme.typography.p1,
      fontSize: filled ? theme.typography.p3.fontSize : theme.typography.p1.fontSize,
      lineHeight: filled ? theme.typography.p3.lineHeight : theme.typography.p1.lineHeight,
      transition: 'transform 0.25s cubic-bezier(0.0, 0, 0.2, 1)',
      userSelect: 'none',
      pointerEvents: 'none',
      ...labelStyle,

      [`.${textareaClasses.asterisk}`]: {
        display: 'inline-block',
        position: 'relative',
        top: filled ? -sizeAsterisk - theme.spaces.x1 : -theme.spaces.x6,
        right: -theme.spaces.x1,
        width: sizeAsterisk,
        height: sizeAsterisk,
        fill: color,
      },
    }
  }
)

export const StyledResizer = restyled.span<Partial<TextareaProps>>(({theme, disabled}) => {
  const sizeResizer = 12.3

  return {
    width: sizeResizer,
    height: sizeResizer,
    padding: theme.spaces.x1,
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    backgroundColor: theme.colors.white,
    position: 'absolute',
    bottom: 0,
    right: 0,
    pointerEvents: 'none',
    paddingRight: theme.spaces.x1 * 0.25,
    paddingBottom: theme.spaces.x1 * 0.25,

    '::before, ::after': {
      display: 'inline-block',
      content: '""',
      height: 1.5,
      backgroundColor: theme.colors.grey[disabled ? 40 : 60],
      borderRadius: theme.spaces.x1 * 2.5,
    },

    '::before': {
      overflow: 'visible',
      width: sizeResizer,
      marginTop: '50%',
      marginBottom: theme.spaces.x1 * 1.5,
      transform: 'rotate(-45deg) translateY(-100%)',
      transformOrigin: '50% 50%',
    },

    '::after': {
      width: 6.3,
      transform: 'rotate(-45deg)',
      transformOrigin: '0% 0%',
    },
  }
})
