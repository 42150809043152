import {CustomFilterInputProps} from '@root/components/kit'
import {TagCombobox} from '@root/features/systems/components/TagCombobox'
import {FC} from 'react'

export const FilterCollectorTagCombobox: FC<CustomFilterInputProps> = ({
  onChange,
  value,
  size,
}) => {
  return (
    <TagCombobox
      value={value as string}
      onChange={onChange}
    />
  )
}
