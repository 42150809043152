import {defineColors} from '../utils/defineColors'

export const palette = {
  first: '#14582a',
  second: '#65b32e',
  third: '#118938',
  fourth: '#adce6d',
}

export const getPathColor = defineColors('#222429')
