import {makeUiStyles} from '../../theme'
import {TableBlockedZIndexes} from '../styles'
import {TableStyleHelpers} from '../Cell/styles'

export const useStyles = makeUiStyles(
  (theme) => ({
    root: {
      ...TableStyleHelpers.getBaseCellRoot(theme),
      padding: '0',
      userSelect: 'none',
      transition: 'background-color .1s',
      '&::after': {
        boxSizing: 'content-box',
      },
    },
    interactive: {
      '&:hover': {
        backgroundColor: '#EBEDEF',
      },
    },
    inner: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      position: 'relative',
      boxSizing: 'border-box',
      lineHeight: 0,
      '& > * + *': {
        marginLeft: theme.spaces.x2,
      },
    },
    isActive: {
      '&::after': {
        border: `1px solid ${theme.colors.accent[90]} !important`,
        width: 'calc(100% - 1px) !important',
      },
    },
    labelTooltip: {
      overflow: 'hidden',
      flex: '1',
    },
    blockedIcon: {
      minWidth: '16px',
      color: theme.colors.grey[60],
      position: 'relative',
      opacity: 0.5,
    },
    isBlocked: {
      opacity: 1,
    },
    marker: {
      minWidth: '16px',
    },
    extend: {
      minWidth: '16px',
      color: theme.colors.grey[80],
    },
    arrow: {
      minWidth: '16px',
    },
    blocked: {
      ...TableStyleHelpers.getCellBlocked(theme),
      zIndex: TableBlockedZIndexes.top,
      top: 0,
      '&::after': {
        ...TableStyleHelpers.getCellBlocked(theme)['&::after'],
        borderBottomWidth: '2px',
      },
    },
    blockedPrimary: {
      zIndex: TableBlockedZIndexes.cross,
    },
  }),
  'table-head-cell'
)
