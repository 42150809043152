import {ButtonDropDown, IconButton, Typography, type BoxProps} from 'ui-kit'
import FlexboxRow from '@root/components/FlexboxRow'
import type {FC} from 'react'
import {SetAction} from '../../core/helperTypes'

type Props = {
  pageSizeActual: number
  pageNumber: number
  setPageNumber: SetAction<number>
  totalSize: number
  totalPages: number
  pageSizeOptions: number[]
  selectedPageSizeOption: number
  setSelectedPageSizeOption: SetAction<number>
  style?: BoxProps['sx']
}

const TablePagination: FC<Props> = ({
  pageSizeActual,
  pageNumber,
  setPageNumber,
  totalSize,
  totalPages,
  pageSizeOptions,
  selectedPageSizeOption,
  setSelectedPageSizeOption,
  style,
}) => {
  const firstElIndex = pageNumber === 1 ? 1 : (pageNumber - 1) * selectedPageSizeOption
  const lastElIndex = (pageNumber - 1) * selectedPageSizeOption + pageSizeActual

  return (
    <FlexboxRow
      sx={{p: 'x6', gap: '16px', justifyContent: 'flex-end', alignItems: 'center', ...style}}
    >
      <ButtonDropDown
        caption={selectedPageSizeOption.toString()}
        options={pageSizeOptions.map((num) => ({name: num.toString(), value: num}))}
        size="small"
        variant="outlined"
        onOptionClick={(event, value) => setSelectedPageSizeOption(value as number)}
      />
      <Typography variant="p1">{`${firstElIndex}-${lastElIndex} из ${totalSize}`}</Typography>
      <FlexboxRow sx={{gap: '8px'}}>
        <IconButton
          disabled={pageNumber === 1}
          icon="ChevronLeft"
          size="small"
          variant="text"
          onClick={() => setPageNumber((prev) => prev - 1)}
        />
        <IconButton
          disabled={pageNumber === totalPages}
          icon="ChevronRight"
          size="small"
          variant="text"
          onClick={() => setPageNumber((prev) => prev + 1)}
        />
      </FlexboxRow>
    </FlexboxRow>
  )
}

export default TablePagination
