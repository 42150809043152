import React, {FC, MouseEventHandler} from 'react'
import {
  UncontrolledSegmentedControlsProps,
  UncontrolledSegmentedControlItem,
  SegmentedControlSizes,
} from './types'
import {StyledSegmentedControls, StyledSegmentedControl} from './styles'
import {getQaAttribute} from 'ui-kit'

type ItemProps = UncontrolledSegmentedControlItem & {
  size?: SegmentedControlSizes
  onChange: (value: number) => void
  selected: boolean
}

const Item: FC<ItemProps> = ({
  qa = 'uncontrolled-segmented',
  size = SegmentedControlSizes.large,
  label,
  value,
  icon,
  disabled,
  selected,
  width = 'auto',
  onChange,
}) => {
  const getQA = getQaAttribute(qa)

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    const {currentTarget} = event
    const id = Number(currentTarget.dataset.id)
    onChange(id)
  }

  return (
    <StyledSegmentedControl
      cssWidth={width}
      data-id={String(value)}
      data-qa={getQA('element', [selected && 'selected', disabled && 'disabled'])}
      disabled={disabled}
      selected={selected}
      size={size}
      onClick={handleClick}
    >
      {icon ?? label}
    </StyledSegmentedControl>
  )
}

export const UncontrolledSegmentedControls: FC<UncontrolledSegmentedControlsProps> = ({
  qa = 'segmented',
  value: valueProp,
  items,
  size,
  onChange,
}) => {
  const getQA = getQaAttribute(qa)

  const handleChange = (newValue: UncontrolledSegmentedControlsProps['value']) => {
    onChange(newValue)
  }

  return (
    <StyledSegmentedControls data-qa={getQA()}>
      {items.map((x) => (
        <Item
          key={x.value}
          selected={x.value === valueProp}
          size={size}
          {...x}
          onChange={handleChange}
        />
      ))}
    </StyledSegmentedControls>
  )
}

export default UncontrolledSegmentedControls
