import {useEffect, useRef, RefObject} from 'react'

/**
 * Функция позволяет обнаружить щелчок за пределами компонента
 **/

function useClickAway(ref: RefObject<Element>, handler?: (event?: MouseEvent) => void): void {
  const handleRef = useRef<(...args: unknown[]) => void>()

  handleRef.current = handler
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return
      }

      handleRef.current && handleRef.current(event)
    }

    if (handler) {
      document.addEventListener('click', listener, true)
    }

    return () => {
      document.removeEventListener('click', listener)
    }
    /* eslint-disable */
  }, [])
}

export {useClickAway}
export default useClickAway
