import {useContext, useRef} from 'react'
import {Dropdown, SelectList} from '@root/components/kit'
import {MoreVertical} from '@x5-react-uikit/icons'
import Paper from '@root/components/Paper'
import {tableContext} from './tableContext'
import {ActionButton} from '@root/components/ActionButton'

const TemplateActionsButton = ({template}) => {
  const {setTemplateToDelete, setTemplateToEdit} = useContext(tableContext)

  const dropdownRef = useRef()

  const actions = {
    edit: {
      name: 'Редактировать',
      value: 'edit',
      onClick: () => setTemplateToEdit(template),
    },
    delete: {
      name: 'Удалить',
      value: 'delete',
      onClick: () => setTemplateToDelete(template),
    },
  }

  return (
    <Dropdown
      disableContentVisual
      action={
        <ActionButton>
          <MoreVertical size="small" />
        </ActionButton>
      }
      className="w-100"
      innerRef={dropdownRef}
      placement="bottom-end"
    >
      <Paper sx={{padding: '8px 0 0 0', backgroundColor: 'white'}}>
        <SelectList
          items={Object.values(actions)}
          onChange={(_, actionName) => {
            const action = actions[actionName]
            action.onClick?.()
            dropdownRef.current.close()
          }}
        />
      </Paper>
    </Dropdown>
  )
}

export default TemplateActionsButton
