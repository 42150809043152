import FlexboxColumn from '@root/components/FlexboxColumn'
import {Roles} from '@root/constants'
import {TaskRequestPayload} from '@root/features/tasks/types'
import useNotify from '@root/hooks/useNotify'
import {useGetTaskByIdQuery, useUpdateTaskMutation} from '@root/redux/api/tasksApi'
import {Loader, Modal} from '@x5-react-uikit/core'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useParams} from 'react-router-dom'
import {AssigneeFormValue} from '../../types'
import StakeholdersComboboxList from '../StakeholdersComboboxList'
import UsersCombobox from '../UsersCombobox'
import SubmitChangeAssignee from './SubmitChangeAssignee'

const performerSelectWidthPx = 524

const ChangeAssigneeModal = ({open, onClose, onOpen}) => {
  const {taskId} = useParams()
  const {notifySuccess, notifyError} = useNotify()

  const form = useForm<AssigneeFormValue>()
  form.watch()

  const {data: task, isFetching: isTaskFetching} = useGetTaskByIdQuery(taskId)
  useEffect(() => {
    form.reset({
      manager: task.manager,
      usersWithAccess: task.usersWithAccess,
    })
  }, [task, form.reset])

  const [submitModalShown, setSubmitModalShown] = useState(false)
  const showSubmitModal = () => {
    setSubmitModalShown(true)
    onClose()
  }
  const cancelSubmitAssignee = () => {
    setSubmitModalShown(false)
    onOpen()
  }
  const cancelAssignee = () => {
    form.reset({
      manager: task.manager,
      usersWithAccess: task.usersWithAccess,
    })
    onClose()
  }

  const [updateTask, {isLoading: isTaskUpdating}] = useUpdateTaskMutation()
  const handleSubmit = form.handleSubmit(() => {
    const update: TaskRequestPayload = {}
    if (form.formState.dirtyFields['manager']) update.manager = form.getValues('manager')
    if (form.formState.dirtyFields['usersWithAccess'])
      update.usersWithAccess = (form.getValues('usersWithAccess').filter(Boolean) || []).map((s) => s.id)
    updateTask({taskId, update})
      .unwrap()
      .then(() => {
        notifySuccess(
          'Изменения сохранены',
          'Изменения вступили в силу. Участники получат уведомление в Outlook.'
        )
        setSubmitModalShown(false)
      })
      .catch((error) => {
        console.log(error)
        notifyError()
      })
  })

  if (isTaskFetching) return <Loader />

  return (
    <>
      <Modal
        closeOnOverlay
        buttons={[
          {
            onClick: cancelAssignee,
            side: 'right',
            text: 'Отмена',
          },
          {
            onClick: showSubmitModal,
            side: 'right',
            text: 'Сохранить',
            disabled: isEmpty(form.formState.dirtyFields),
          },
        ]}
        isOpen={open}
        size="medium"
        title="Изменение данных “Работа с заявкой”"
        onClose={cancelAssignee}
      >
        <FlexboxColumn sx={{gap: '24px'}}>
          <UsersCombobox
            required={false}
            label="Менеджер НТ"
            roles={[Roles.MANAGER]}
            selectedUser={form.getValues('manager')}
            widthPx={performerSelectWidthPx}
            onChange={(event, manager) => form.setValue('manager', manager, {shouldDirty: true})}
            onClear={() => form.setValue('manager', null, {shouldDirty: true})}
          />
          <StakeholdersComboboxList inputWidth={performerSelectWidthPx} form={form} />
        </FlexboxColumn>
      </Modal>
      <SubmitChangeAssignee
        handleSubmit={handleSubmit}
        isSubmiting={isTaskUpdating}
        manager={form.getValues('manager')}
        open={submitModalShown}
        usersWithAccess={form.getValues('usersWithAccess')?.filter(Boolean)}
        onCancel={cancelSubmitAssignee}
        onClose={() => setSubmitModalShown(false)}
      />
    </>
  )
}

export default ChangeAssigneeModal
