import {Box, Typography} from '@root/components/kit'
import {SystemStatusCode, SystemStatusesMap} from '@root/features/systems/constants'
import {FC} from 'react'
import type {BoxProps} from 'ui-kit'

type Props = {
  statusName: SystemStatusCode | keyof SystemStatusCode
  sx?: BoxProps['sx']
}
const SystemStatusChip: FC<Props> = ({statusName, sx}) => {
  const statusMeta = SystemStatusesMap[statusName]
  return (
    <Box
      sx={{
        py: 'x1',
        px: 'x3',
        mt: 'x2',
        borderRadius: 'x2',
        color: statusMeta.color,
        backgroundColor: statusMeta.backgroundColor,
        width: 'fit-content',
        ...sx,
      }}
    >
      <Typography variant="p2">{statusMeta.label}</Typography>
    </Box>
  )
}

export default SystemStatusChip
