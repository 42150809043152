import {Loader} from '@x5-react-uikit/core'
import Divider from '@root/components/Divider'
import {useGetUserinfoQuery} from '@root/redux/api/userApi'
import useTaskPageForm, {getStepMeta} from '../hooks/useTaskPageForm'
import FieldAccordeon from './FieldAccordeon'
import {useParams} from 'react-router-dom'
import {useGetTaskByIdQuery} from '@root/redux/api/tasksApi'
import {useSaveFormBeforeTeardown} from '../hooks/useSaveFormBeforeTeardown'
import {useMemo} from 'react'

const STEP_NUMBER = 3

const ToolParamsSection = () => {
  const {taskId} = useParams()
  const {data: task} = useGetTaskByIdQuery(taskId)

  const {form, formMeta, isFormDataLoading, stepIndices} = useTaskPageForm()
  const stepIndex = stepIndices[STEP_NUMBER]

  const {data: userInfo} = useGetUserinfoQuery()

  const stepMeta = useMemo(() => getStepMeta(formMeta, STEP_NUMBER), [formMeta])
  useSaveFormBeforeTeardown({
    stepId: STEP_NUMBER,
    systemId: task?.system.id,
    taskId,
    updated: !form.formState.isDirty,
    currentStepMeta: stepMeta,
    form,
    shouldCheckCompleted: false,
  })

  if (isFormDataLoading) return <Loader />

  const formValue = form.getValues('formData')
  const fieldsMeta = Object.keys(formMeta).filter((fieldId) => formMeta[fieldId].step === 3)

  return (
    <>
      {fieldsMeta.map((fieldId, index) => {
        const last = index === fieldsMeta.length - 1
        const fieldIdIndex = formValue[stepIndex].fields.findIndex((field) => field.id === fieldId)
        const field = formMeta[fieldId]
        return (
          <>
            <FieldAccordeon
              fieldIndex={fieldIdIndex}
              fieldMeta={field}
              form={form}
              readonly={!field.edit.includes(userInfo.role)}
              stepIndex={stepIndex}
              title={field.label}
            />
            {!last && <Divider />}
          </>
        )
      })}
    </>
  )
}

export default ToolParamsSection
