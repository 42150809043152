import React, {FC} from 'react'
import {LogoProps} from '../types'
import {primary, getPathColor} from './helpers'

const Logo: FC<LogoProps> = ({color, ...props}) => (
  <svg fill="none" viewBox="0 0 392 120" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M93.5534 99.1732L82.5507 80.8113L71.7756 99.1732H58.8759L75.4482 71.516L58.5572 45.8398H72.0336L82.8542 62.9827L93.174 45.8398H106.074L89.4558 72.3541L106.529 99.1732H93.5534V99.1732Z"
      fill={getPathColor(color)}
    />
    <path
      d="M148.461 81.0437C148.461 92.7771 139.659 100.091 128.125 100.091C117.957 100.091 109.397 94.4533 108.563 82.1866H119.565C120.324 87.5199 124.573 89.8057 129.202 89.8057C134.969 89.8057 137.458 85.9199 137.458 80.8914C137.458 75.6342 133.588 72.358 128.201 72.358C123.724 72.358 120.764 74.4914 119.398 76.7771H108.881L112.516 45.8438H147.106L145.953 56.2057H121.208L120.225 66.4418C120.225 66.4418 122.824 63.3676 130.701 63.3676C140.19 63.3676 148.461 69.7637 148.461 81.0437Z"
      fill={getPathColor(color)}
    />
    <path
      d="M109.864 39.1997C104.002 16.2587 85.4076 0.140625 46.8184 0.140625H0.227661L6.55232 43.0816C11.31 81.8282 26.0688 94.293 52.187 98.8835C52.3084 98.9025 53.1355 99.0206 53.1469 98.0492C53.1507 97.6987 52.9306 97.3978 52.6271 97.2682C34.9735 89.8054 32.9588 65.3292 29.1875 40.7692L26.2699 21.1959H52.8661C77.387 21.1959 96.3192 20.8073 108.305 39.813C108.483 40.0797 108.76 40.2778 109.09 40.2778C109.553 40.2778 109.898 39.9006 109.898 39.4359C109.898 39.3521 109.887 39.2721 109.864 39.1997Z"
      fill={getPathColor(color, primary)}
    />
    <path
      d="M211.791 71.394C211.791 71.394 214.545 101.055 184.694 101.055C168.941 101.055 156.808 88.9521 156.808 72.5026C156.808 56.0531 169.017 43.9502 185.168 43.9502C197.616 43.9502 207.541 51.1464 211.32 62.2207H204.309C201.001 54.9445 193.834 50.1978 185.088 50.1978C172.64 50.1978 163.424 59.6911 163.424 72.6626C163.424 85.2378 172.17 94.9673 185.088 94.9673C195.958 94.9673 204.229 88.0073 205.178 77.0131H183.958V71.3978H211.791V71.394Z"
      fill={getPathColor(color)}
    />
    <path
      d="M245.349 55.9696V62.6934C244.64 62.6134 243.695 62.5334 242.591 62.5334C233.531 62.5334 227.229 68.4648 227.229 79.6191V100.183H220.692V56.6781H227.229V66.4077C229.828 59.92 235.025 55.8096 242.667 55.8096C243.695 55.8134 244.719 55.8896 245.349 55.9696Z"
      fill={getPathColor(color)}
    />
    <path
      d="M293.192 78.4344C293.192 91.4859 283.346 101.055 270.818 101.055C258.37 101.055 248.525 91.4059 248.525 78.4344C248.525 65.383 258.53 55.8135 270.818 55.8135C283.187 55.8135 293.192 65.383 293.192 78.4344ZM255.141 78.4344C255.141 88.004 262.073 94.8078 270.818 94.8078C279.64 94.8078 286.571 88.004 286.571 78.4344C286.571 68.8649 279.56 62.0611 270.818 62.0611C262.153 62.0611 255.141 68.7849 255.141 78.4344Z"
      fill={getPathColor(color)}
    />
    <path
      d="M338.409 56.6816V100.186H331.872V91.5654C328.958 97.2607 323.916 101.059 316.43 101.059C306.82 101.059 301.068 94.8912 301.068 84.2893V56.6854H307.605V83.0245C307.605 90.7769 311.229 94.8112 318.32 94.8112C326.906 94.8112 331.868 88.6435 331.868 78.9902V56.6854H338.409V56.6816Z"
      fill={getPathColor(color)}
    />
    <path
      d="M391.53 78.4343C391.53 91.5658 383.023 101.055 371.205 101.055C363.723 101.055 357.892 97.2572 354.583 91.4058V119.958H348.046V56.682H354.583V65.6191C357.892 59.6877 363.799 55.8096 371.285 55.8096C383.023 55.8134 391.53 65.2229 391.53 78.4343ZM384.913 78.4343C384.913 68.941 378.77 62.061 370.025 62.061C361.204 62.061 354.507 68.8648 354.507 78.4343C354.507 88.0039 361.204 94.8077 370.025 94.8077C378.77 94.8077 384.913 88.0039 384.913 78.4343Z"
      fill={getPathColor(color)}
    />
  </svg>
)

export default Logo
