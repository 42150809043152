import {FC} from 'react'
import {Title} from './Title'
import {Subtitle} from './Subtitle'
import {View} from '@react-pdf/renderer'

type Props = {
  title: string
  subtitle?: string
}
export const Heading: FC<Props> = ({title, subtitle}) => (
  <View>
    <Title level="h1">{title}</Title>
    {subtitle && <Subtitle style={{marginTop: '4px'}}>{subtitle}</Subtitle>}
  </View>
)
