import Divider from '@root/components/Divider'
import FlexboxColumn from '@root/components/FlexboxColumn'
import LoaderFrame from '@root/components/Loader'
import ReportDataAccordion from '@root/features/reports/components/ReportDataAccordion'
import {ReportCommonFieldsMeta} from '@root/features/reports/constants'
import {formatReportData, getDynamicFieldMeta} from '@root/features/reports/utils'
import {isNumber} from 'lodash'
import {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useParams} from 'react-router-dom'
import {useGetReportByIdQuery} from '@root/redux/api/launchesApi'

const ReportDataTab = ({_form}) => {
  const {reportId} = useParams()

  const {reset: formResetValue, ...form} = useForm({
    defaultValues: _form?.getValues('reportData')
      ? Object.assign({}, formatReportData(_form.getValues('reportData')))
      : {loading: true},
  })

  const {data: report} = useGetReportByIdQuery(reportId, {skip: _form?.getValues('reportData')})
  useEffect(() => {
    if (!_form?.getValues('reportData') && report?.reportData)
      formResetValue(formatReportData(report.reportData))
    // eslint-disable-next-line
  }, [report, formResetValue])

  const formValue = form.getValues()

  if (formValue.loading) return <LoaderFrame height="600px" />

  const dynamicFields = formValue[0].fields.filter((field) => isNumber(field.number))
  return (
    <FlexboxColumn>
      <ReportDataAccordion form={form} meta={ReportCommonFieldsMeta.goal} />
      <Divider />
      <ReportDataAccordion form={form} meta={ReportCommonFieldsMeta.conclusion} />
      <Divider />
      <ReportDataAccordion form={form} meta={ReportCommonFieldsMeta.approach} />
      <Divider />
      <ReportDataAccordion form={form} meta={ReportCommonFieldsMeta.limit} />
      <Divider />
      <ReportDataAccordion form={form} meta={ReportCommonFieldsMeta.configuration} />
      {dynamicFields.length > 0 && <Divider />}
      {dynamicFields.map((field, fieldIndex) => {
        const meta = getDynamicFieldMeta(field)
        const last = fieldIndex === dynamicFields.length - 1
        return (
          <>
            <ReportDataAccordion form={form} meta={meta} />
            {!last && <Divider />}
          </>
        )
      })}
    </FlexboxColumn>
  )
}

export default ReportDataTab
