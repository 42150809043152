import {DataGridTable} from './Table'
import {DataGridSection} from './Section'
import {DataGridHead} from './Head'
import {DataGridRow} from './Row'
import {DataGridCell} from './Cell'
import {DataGridHeadCell} from './HeadCell'
import {DataGridLabel} from './Label'
import {DataGridBaseParams} from './types'

export const DataGridBase: DataGridBaseParams = {
  Table: DataGridTable,
  Section: DataGridSection,
  Head: DataGridHead,
  Row: DataGridRow,
  HeadCell: DataGridHeadCell,
  Cell: DataGridCell,
  Label: DataGridLabel,
}

export default DataGridBase
