import {CheckboxState} from '../Checkbox/types'
import {CheckboxTreeOption, CheckboxTreeOptionValue} from './types'
import {flatten} from '../utils/flatten'

export const getChildsClassName = (
  classes: Record<string, string>,
  option: CheckboxTreeOption,
  level: number
): string => {
  const hasChilds = option.childs

  if (hasChilds && level) {
    return classes.childsMargin
  }

  if (!hasChilds) {
    return level ? classes.childsBigMargin : classes.childsMargin
  }
}

// Функция вызывается, если включен режим блокировки верхних уровней
export const shouldBeDisabled = (
  option: CheckboxTreeOption,
  values: CheckboxTreeOptionValue[]
): boolean => {
  if (option.childs) {
    const isAllChildsChecked = option.childs.every((child) => values.includes(child.value))

    // Если выбраны все потомки, то элемент должен быть активным
    if (isAllChildsChecked) {
      return false
    }

    // Если выбран хотя бы один потомок, то элемент должен быть неактивным
    // Также проверяем потомки потомка и т.д
    return option.childs.some((child) => {
      const isCheckedChild = values.includes(child.value)

      if (isCheckedChild) return true
      if (child.childs) return shouldBeDisabled(child, values)

      return false
    })
  }

  return false
}

export const shouldBeChecked = (
  option: CheckboxTreeOption,
  values: CheckboxTreeOptionValue[]
): CheckboxState => {
  const isChecked = values.includes(option.value)

  if (!isChecked && option.childs) {
    // Если выбран хотя бы один потомок или потомок потомка и т.д, то возвращаем halfOn
    const hasSomeCheckedChild = option.childs.some((child) => {
      const isChildChecked = values.includes(child.value)

      if (!isChildChecked && child.childs) {
        return shouldBeChecked(child, values)
      }

      return isChildChecked
    })

    if (hasSomeCheckedChild) {
      return 'halfOn'
    }
  }

  return isChecked
}

export const shouldBeExpand = (
  expanded: CheckboxTreeOptionValue[],
  option: CheckboxTreeOption
): boolean => {
  return expanded.includes(option.value)
}

export const getChildValues = (option: CheckboxTreeOption): CheckboxTreeOptionValue[] => {
  // Получаем все дочерние элементы, пример: [1, 2, 3, [4, 5, [6, 7, 8]]]
  const nestedChilds = option.childs.map((child) => {
    if (child.childs) {
      return [child.value, ...getChildValues(child)]
    }

    return child.value
  })

  // Собираем в один уровень
  return flatten(nestedChilds)
}

export const getParentValues = (options: CheckboxTreeOption[]): CheckboxTreeOptionValue[] => {
  // Получаем все родительские элементы, пример: [1, 2, 3, false, [4, 5, [6, 7, 8]]]
  const nestedValues = options
    .map((option) => {
      if (option.childs) {
        return [option.value, ...getParentValues(option.childs)]
      }
      return false
    })
    .filter(Boolean) as CheckboxTreeOptionValue[][]

  return flatten(nestedValues)
}

export const checkAllChildValues = (
  values: CheckboxTreeOptionValue[],
  option: CheckboxTreeOption,
  isChecked: boolean
): CheckboxTreeOptionValue[] => {
  // Получаем все дочерние элементы
  const childValues = getChildValues(option)

  // Если нажали на родителя, добавляем все дочерние элементы
  if (isChecked) {
    return values.concat(childValues)
  }

  // Если убрали галочку, то убираем все дочерние элементы
  return values.filter((value) => !childValues.includes(value))
}

export const checkAllParents = (
  values: CheckboxTreeOptionValue[],
  option: CheckboxTreeOption,
  isChecked: boolean
): CheckboxTreeOptionValue[] => {
  if (option.parent) {
    const isAllChildsChecked = option.parent.childs.every((child) => values.includes(child.value))

    // Если мы добавили галочку и все дочерние элементы выбраны
    // То тогда добавляем родительский элемент
    // Также проверяем прародитель и т.д
    if (isChecked && isAllChildsChecked) {
      values = checkAllParents(values.concat(option.parent.value), option.parent, isChecked)

      // Если мы убрали галочку, то убираем всех родителей
    } else if (!isChecked) {
      values = checkAllParents(
        values.filter((value) => value !== option.parent.value),
        option.parent,
        isChecked
      )
    }
  }

  return values
}

export const filterOptions = (
  options: CheckboxTreeOption[],
  searchStr?: string,
  parent?: CheckboxTreeOption
): CheckboxTreeOption[] =>
  options.map((option) => {
    let filteredChilds

    // Фильтруем потомков
    if (option.childs) {
      filteredChilds = filterOptions(option.childs, searchStr, {...option, parent})
    }

    const childVisible = filteredChilds && filteredChilds.some((child) => child.isVisible)

    // По умолчанию элемент виден: ("string".includes("") === true)
    // Также родитель виден, если хотя бы один потомок виден
    // Когда мы делаем поиск, то если какой-то потомок подходит под поиск
    // То считаем, что и родитель подходит
    const isVisible =
      childVisible || option.name.toLowerCase().includes(searchStr.toLowerCase() || '')

    const newOption = {
      ...option,
      parent,
      isVisible,
    }

    if (filteredChilds) {
      newOption.childs = filteredChilds
    }

    return newOption
  })
