import {Box, Button, Typography} from '@root/components/kit'
import Breadcrumbs from '@root/components/Breadcrumbs'
import LoaderFrame from '@root/components/Loader'
import Section from '@root/components/Section'
import useNotify from '@root/hooks/useNotify'
import {useTeardownEffect} from '@root/hooks/useTeardownEffect'
import {useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate, useParams} from 'react-router-dom'
import {useGetSystemFinishedTaskQuery, useGetSystemsQuery} from '@root/redux/api/systemsApi'
import {
  useCreateTaskMutation,
  useGetTaskByIdQuery,
  useUpdateTaskMutation,
} from '@root/redux/api/tasksApi'
import CopyTaskModal from './components/CopyFinishedTaskModal'
import SystemsCombobox from './components/SystemsCombobox'
import {System} from '@root/features/systems/types'

const SystemsFilter = {pageNumber: 1, pageSize: 9999999, filters: {}}
const hideDuration = 999999

const SelectSystemPage = ({draft}) => {
  const {taskId} = useParams()
  const navigate = useNavigate()

  const {notifyError, closeSnackbar} = useNotify()
  useTeardownEffect({onUnmount: closeSnackbar})

  const {data: systemsData, isLoading: isSystemsLoading} = useGetSystemsQuery({
    filter: SystemsFilter,
  })

  const systemsById = useMemo(
    () =>
      (systemsData?.items ?? []).reduce(
        (acc, next) => {
          acc[next.id] = next
          return acc
        },
        {} as Record<string, System>
      ),
    [systemsData]
  )

  const form = useForm()
  const selectedSystemId = form.watch('systemId')
  const selectedSystem = useMemo(
    () => selectedSystemId && systemsById[selectedSystemId],
    [selectedSystemId, systemsById]
  )
  const {currentData: draftTask} = useGetTaskByIdQuery(taskId, {skip: !taskId})
  const {currentData: finishedTask} = useGetSystemFinishedTaskQuery(selectedSystemId, {
    skip: !selectedSystemId,
  })
  const [copyModalOpen, setCopyModalOpen] = useState(false)

  const [createTask, {isLoading: isCreatingTask}] = useCreateTaskMutation()
  const [updateTask, {isLoading: isUpdatingTask}] = useUpdateTaskMutation()
  const handleSelectSystem = async () => {
    const onError = (error) => {
      if (error.status === 409 && error.data?.messages?.[0])
        notifyError(error.data.messages[0], 'Ошибка', hideDuration)
      else notifyError()
    }
    if (draftTask) {
      await updateTask({taskId, update: {system: selectedSystem}})
        .unwrap()
        .then((updated) => navigate(`/tasks/draft/${taskId}/steps/1`))
        .catch(onError)
    } else if (finishedTask) {
      setCopyModalOpen(true)
    } else {
      await createTask({system: selectedSystem})
        .unwrap()
        .then((created) => {
          navigate(`/tasks/create/${created.id}/steps/1`)
        })
        .catch(onError)
    }
  }

  if (isSystemsLoading) return <LoaderFrame />

  return (
    <>
      <div style={{margin: '0 16px 0 16px'}}>
        <Breadcrumbs
          routes={[
            {to: '/tasks', label: 'Главная'},
            {to: null, label: 'Создание заявки: выбор ИС'},
          ]}
        />
        <Typography variant="h2">Создание заявки</Typography>
        <Section>
          <Typography style={{marginBottom: '8px'}} variant="h3">
            Добро пожаловать в создание заявок.
          </Typography>
          <Typography variant="p3">
            Необходимо выбрать Информационную систему, для которой создается заявка на нагрузочное
            тестирование.
          </Typography>
          <div style={{marginBottom: '24px', marginTop: '24px'}}>
            <SystemsCombobox form={form} />
          </div>
          <Box sx={{display: 'flex', alignItems: 'center', gap: '24px', mb: 'x4'}}>
            <Button
              variant="secondary"
              onClick={(e) => {
                e.preventDefault()
                navigate('/tasks')
              }}
            >
              На главную
            </Button>
            <Button
              variant="primary"
              onClick={(e) => {
                e.preventDefault()
                form.handleSubmit(handleSelectSystem, (error) =>
                  notifyError('Не удалось выбрать ИС.')
                )()
              }}
              loading={isCreatingTask || isUpdatingTask}
              disabled={isCreatingTask || isUpdatingTask}
            >
              Продолжить
            </Button>
          </Box>
        </Section>
      </div>
      <CopyTaskModal
        open={copyModalOpen}
        system={selectedSystem}
        onClose={() => setCopyModalOpen(false)}
      />
    </>
  )
}

export default SelectSystemPage
