import React, {FC} from 'react'
import {inputClasses} from './styles'

export const Asterisk: FC = () => (
  <svg
    className={inputClasses.asterisk}
    fill="none"
    height="4"
    viewBox="0 0 4 4"
    width="4"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.90389 2.51545L2.96059 2L3.90389 1.48455C3.99573 1.43436 4.02723 1.32309 3.97421 1.23618L3.68603 0.763818C3.63301 0.676909 3.51562 0.647091 3.4236 0.697273L2.4803 1.21273V0.181818C2.4803 0.0814546 2.39423 0 2.28818 0H1.71182C1.60577 0 1.5197 0.0814546 1.5197 0.181818V1.21273L0.576402 0.697273C0.484377 0.647091 0.366992 0.676909 0.313968 0.763818L0.0257898 1.23618C-0.0272349 1.32309 0.00408043 1.43436 0.0961052 1.48455L1.03941 2L0.0961052 2.51545C0.00427255 2.56564 -0.0272349 2.67691 0.0257898 2.76382L0.313968 3.23618C0.366992 3.32309 0.484569 3.35291 0.576402 3.30273L1.5197 2.78727V3.81818C1.5197 3.91855 1.60577 4 1.71182 4H2.28818C2.39423 4 2.4803 3.91855 2.4803 3.81818V2.78727L3.4236 3.30273C3.51543 3.35291 3.63301 3.32309 3.68603 3.23618L3.97421 2.76382C4.02723 2.67691 3.99592 2.56564 3.90389 2.51545Z" />
  </svg>
)
