import {Typography} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'
import {useEffect} from 'react'

const EditCommentField = ({form, meta, fieldIndex}) => {
  const register = `reportData.commonFields.${fieldIndex}.values.comment.value`

  useEffect(() => {
    const fieldIdRegister = `reportData.commonFields.${fieldIndex}.id`
    if (!form.getValues(fieldIdRegister)) form.setValue(fieldIdRegister, meta.id)

    const inputIdRegister = `reportData.commonFields.${fieldIndex}.values.comment.id`
    if (!form.getValues(inputIdRegister)) form.setValue(inputIdRegister, meta.values[0].id)
  }, [form, meta, fieldIndex])

  return (
    <FlexboxColumn sx={{gap: '24px'}}>
      <Typography variant="h3">{meta.label}</Typography>
      <FormInputText
        textarea
        control={form.control}
        label="Комментарий"
        name={register}
        width="520px"
      />
    </FlexboxColumn>
  )
}

export default EditCommentField
