import {Input} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import {SetAction} from '@root/core/helperTypes'
import {FC} from 'react'
import {UseTaskPageForm} from '../hooks/useTaskPageForm'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'


type Props = {
  form: UseTaskPageForm['form']
}

const BudgetFieldEdit: FC<Props> = ({form}) => {
  // const onChangeCpp = (e) => setCppInputValue(e.target.value)
  // const onChangeCost = (e) => setCostInputValue(e.target.value)

  return (
    <FlexboxColumn sx={{gap: '24px', width: '536px'}}>
      <FormInputText 
        name='finportal.cpp'
        control={form.control}
        caption='Пример: I-041114-05'
        label="Номер статьи"
        width="300px"
      />
      {/* <Input
        caption="Пример: I-041114-05"
        label="Номер статьи"
        value={cppInputValue}
        width="300px"
        onChange={onChangeCpp}
        error={cppInvalidError}
        textError={cppInvalidError && 'Статьи нет в базе. Проверьте и измените номер'}
      /> */}
      <FormInputText 
        name='finportal.workCost'
        control={form.control}
        caption='За выполнение нагрузочного тестирования'
        label="Стоимость работ"
        width="300px"
      />
      {/* <Input
        caption="За выполнение нагрузочного тестирования"
        label="Стоимость работ"
        value={costInputValue?.toString()}
        width="300px"
        onChange={onChangeCost}
      /> */}
    </FlexboxColumn>
  )
}

export default BudgetFieldEdit
