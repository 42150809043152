import {baseTheme} from '@x5-react-uikit/core'

export const COLLAPSE_IF_SYSTEMS_COUNT = 5

const {colors} = baseTheme

export enum SystemStatusCode {
  NOT_REQUIRED = 'NOT_REQUIRED',
  IN_DEVELOPMENT = 'IN_DEVELOPMENT',
  CARRIED_OUT = 'CARRIED_OUT',
  MISSING = 'MISSING',
}

export const SystemStatusesMap = {
  [SystemStatusCode.NOT_REQUIRED]: {
    label: 'НТ не требуется',
    backgroundColor: 'blue.10',
    color: 'blue.90',
    name: SystemStatusCode.NOT_REQUIRED,
  },
  [SystemStatusCode.IN_DEVELOPMENT]: {
    label: 'НТ в работе',
    backgroundColor: 'orange.10',
    color: 'yellow.90',
    name: SystemStatusCode.IN_DEVELOPMENT,
  },
  [SystemStatusCode.CARRIED_OUT]: {
    label: 'НТ проведено',
    backgroundColor: 'green.10',
    color: 'green.90',
    name: SystemStatusCode.CARRIED_OUT,
  },
  [SystemStatusCode.MISSING]: {
    label: 'НТ отсутствует',
    backgroundColor: 'red.10',
    color: 'red.90',
    name: SystemStatusCode.MISSING,
  },
} as const

export const SystemSubstatusesMap = {
  YELLOW: {
    label: 'Желтый подстатус',
    color: colors.yellow[70],
    name: 'YELLOW',
  },
  RED: {
    label: 'Красный подстатус',
    color: colors.error,
    name: 'RED',
  },
  WITHOUT_COMMENTS: {
    name: 'WITHOUT_COMMENTS',
  },
} as const
