import {Box, Tab, TabList, Tabs, Typography} from '@x5-react-uikit/core'
import Breadcrumbs from '@root/components/Breadcrumbs'
import {useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import useContentTabs from './hooks/useContentTabs'
import TasksTable from './components/TasksTable'
import Section from '@root/components/Section'
import {taskStatusTabKind} from './types'

const TopTabLabels = {
  [taskStatusTabKind.active]: 'Действующие заявки',
  [taskStatusTabKind.draft]: 'Черновики',
  [taskStatusTabKind.withdrawn]: 'Отозвана',
}

const TaskListPage = () => {
  const [searchParams] = useSearchParams()
  const [selectedTab, setSelectedTab] = useState(() => {
    const queryTab = searchParams.get('topTab')

    if (queryTab) {
      const parsedNumber = Number.parseInt(queryTab)
      if (taskStatusTabKind[parsedNumber] != null) {
        return parsedNumber
      }
    }

    return taskStatusTabKind.active
  })

  const {topTabs: availableTabs} = useContentTabs()

  return (
    <Box sx={{mx: 'x8'}}>
      <Breadcrumbs routes={[{to: null, label: 'Главная'}]} />
      <Typography variant="h2">Раздел с заявками</Typography>
      <Box sx={{my: 'x10'}}>
        {availableTabs?.length > 0 && (
          <Tabs value={selectedTab} onChange={(e, newTab) => setSelectedTab(newTab)}>
            <TabList>
              {availableTabs.map((t) => (
                <Tab key={t} label={TopTabLabels[t]} value={t} />
              ))}
            </TabList>
          </Tabs>
        )}
      </Box>
      <Section
        sx={{
          py: undefined,
          px: undefined,
          p: undefined,
          minWidth: 1480,
        }}
      >
        <TasksTable statusTab={selectedTab} />
      </Section>
    </Box>
  )
}

export default TaskListPage
