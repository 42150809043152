import {Navigate, Outlet, useLocation} from 'react-router-dom'
import {useGetUserinfoQuery} from '@root/redux/api/userApi'

/**
 * Проверяет наличие доступа роли юзера к дочернему компоненту.
 * Если доступ отсутствует, перенаправляет на главную страницу.
 */
const PageAuthorization = ({allowedRoles}) => {
  const location = useLocation()
  const {data: userInfo = {role: null}} = useGetUserinfoQuery()

  if (!allowedRoles.includes(userInfo.role))
    return <Navigate replace state={{from: location}} to="/" />
  return <Outlet />
}

export default PageAuthorization
