import {systemToOption as defaultSystemToOption} from '@root/features/systems/utils'
import {FilterItemType} from '@root/openapi'
import {GetSystemListPayload, useGetSystemsQuery} from '@root/redux/api/systemsApi'
import {useGetUserinfoQuery} from '@root/redux/api/userApi'
import {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {SystemToOptionMap} from '../types'

const useGetSystemsByRole = (
  requestFilter: GetSystemListPayload['filter'],
  systemToOption: SystemToOptionMap = defaultSystemToOption
) => {
  const user = useSelector((state: any) => state.auth.user)
  const {data: userInfo} = useGetUserinfoQuery()

  const filter = useMemo(() => {
    const result = requestFilter
    // Для юзеров с ролью BUSINESS выводим только те системы, в которых у юзера есть заявки
    if (userInfo?.isBusiness) {
      result.filters ||= {}
      result.filters['tasks.creator.email'] = {value: user.email, type: FilterItemType.LIKE}
    }
    return result
  }, [requestFilter, userInfo, user.email])

  const {data: rawSystems, isLoading: isSystemsLoading} = useGetSystemsQuery({filter})

  const systems = useMemo(() => rawSystems?.items?.map(systemToOption) ?? [], [rawSystems])

  return {systems, isSystemsLoading}
}

export default useGetSystemsByRole
