import React, {FC} from 'react'
import {FieldCaptionProps} from './types'
import {StyledCaption} from './styles'

export const FieldCaption: FC<FieldCaptionProps> = ({value, qa = 'caption', width, ...props}) => (
  <StyledCaption cssWidth={width} data-qa={qa} title={props.isAbsolute ? value : null} {...props}>
    {value}
  </StyledCaption>
)

export default FieldCaption
