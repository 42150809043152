import React, {
  FC,
  useState,
  useRef,
  useEffect,
  useCallback,
  useLayoutEffect,
  MouseEvent,
  ChangeEvent,
} from 'react'
import {ArrowDown as ArrowDownIcon, ArrowUp as ArrowUpIcon} from '@x5-react-uikit/icons'
import {sizeTokenValues, placements} from '@x5-react-uikit/tokens'
import {Tooltip} from '../Tooltip'
import {Input} from '../Input'
import {SelectBase} from './SelectBase'
import {DoubleAdornment} from './SelectAdornment'
import {SelectChip} from './SelectChip'
import {SelectMultipleValueType, SelectProps, SelectValueType} from './types'
import {StyledSelect, selectClasses} from './styles'
import {getQaAttribute} from '../utils'
import {calculateFontWidth} from '../utils/calculateSize'
import {filterOptions, getDifferentProps} from './helpers'
import {DropdownInnerRefMethods} from '../Dropdown'

export const Select: FC<SelectProps> = (props) => {
  const {
    multiple,
    value: valueFromProps,
    className,
    size,
    disabled = false,
    isOpen = false,
    qa = 'select',
    filter,
    onOpen,
    onChange,
    onClearClick,
    onFilterChange,
    onClearFilterClick,
    chipText,
    usePortal,
    dropdownRef: propDropdownRef,
  } = props
  const value = valueFromProps ?? ''
  const isTree = props.options?.[0]?.childs
  const {inputProps, selectBaseProps, commonProps} = getDifferentProps(props)
  const getQA = getQaAttribute(qa)
  const inputRef = useRef(null)
  const chipRef = useRef(null)
  const [isShowInputTooltip, setIsShowInputTooltip] = useState(false)
  const dropdownRef = useRef<DropdownInnerRefMethods | null>(propDropdownRef?.current ?? null)

  const [options, setOptions] = useState(props.options)
  const [opened, setOpened] = useState(isOpen && !disabled)
  const [inputValue, setInputValue] = useState('')
  const [chipWidth, setChipWidth] = useState(0)
  const count = (value as SelectMultipleValueType)?.length

  useEffect(() => {
    async function setTooltip() {
      if (inputRef.current) {
        const valueWidth = await calculateFontWidth(inputValue, inputRef.current)
        const inputWidth = inputRef.current?.offsetWidth
        setIsShowInputTooltip(valueWidth > inputWidth)
      }
    }

    setTooltip().then()
  }, [inputValue])

  useEffect(() => {
    if (!multiple) {
      const getItem = (val: SelectValueType) => props.options.find((option) => option.value === val)
      const found = getItem(value)
      setInputValue(found ? found.name : '')
      dropdownRef.current?.close()
    }

    if (isOpen) {
      dropdownRef.current?.open()
    }

    if (multiple && count) {
      setInputValue(filter || '')
    }

    setOptions(props.options)
  }, [multiple, filter, value, inputValue, count, props.options, isOpen])

  useLayoutEffect(() => {
    if (multiple) {
      setChipWidth(chipRef.current?.clientWidth)
    }
  }, [multiple, chipRef.current?.clientWidth, count])

  const handleInputClick = (event: MouseEvent<HTMLInputElement>) => {
    if (disabled) event.stopPropagation()
  }

  const handleClearFilterClick = useCallback(
    (event: MouseEvent) => {
      setInputValue('')
      onClearFilterClick?.(event)
    },
    [onClearFilterClick]
  )

  const handleClearClick = (event: MouseEvent) => {
    onClearClick?.(event)
    handleClearFilterClick(event)
  }

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {target} = event

    dropdownRef.current.open()
    setInputValue(target.value)
    onFilterChange?.(event)

    if (!isTree) {
      setOptions(filterOptions(props.options, target.value))
    }
  }

  const handleDropdownChange = (opened: boolean) => {
    onOpen?.(opened)
    setOpened(opened)
  }

  const AdornmentIcon = opened ? ArrowUpIcon : ArrowDownIcon

  const getEndAdornment = useCallback(() => {
    const trimmedFilter = filter?.trim()
    if (trimmedFilter && onClearFilterClick) {
      return (
        <DoubleAdornment
          clearable={!!trimmedFilter.length}
          opened={opened}
          size={size}
          onClick={handleClearFilterClick}
        />
      )
    }
    return <AdornmentIcon size={sizeTokenValues.small} />
  }, [filter, onClearFilterClick, AdornmentIcon, opened, size, handleClearFilterClick])

  const SelectInput = (
    <>
      <Input
        {...commonProps}
        endAdornment={getEndAdornment()}
        filled={!!count}
        forbidTyping={!multiple || (multiple && typeof filter !== 'string')}
        inputProps={{className: selectClasses.input}}
        inputRef={inputRef}
        qa={getQA('input', disabled && 'disabled')}
        value={inputValue}
        onChange={handleFilterChange}
        onClick={handleInputClick}
        {...inputProps}
        width="100%"
      />
      {multiple && (
        <SelectChip
          ref={chipRef}
          count={count}
          disabled={disabled}
          size={size}
          text={chipText}
          onClearClick={handleClearClick}
        />
      )}
    </>
  )

  return (
    <Tooltip content={isShowInputTooltip ? inputValue : null} placement={placements.bottomStart}>
      <StyledSelect className={className} data-qa={getQA('holder')} opened={opened}>
        <SelectBase
          {...commonProps}
          action={SelectInput}
          getQA={getQA}
          highlighted={multiple}
          innerRef={dropdownRef}
          inputValue={inputValue}
          multiple={multiple}
          options={options}
          usePortal={usePortal}
          value={value}
          onChange={onChange}
          onDropdownChange={handleDropdownChange}
          {...selectBaseProps}
        />
      </StyledSelect>
    </Tooltip>
  )
}

export default Select
