import React from 'react'
import ErrorMessage from './stubs/ErrorMessage'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {hasError: false, error: null}
  }

  static getDerivedStateFromError(error) {
    return {hasError: true, error}
  }

  componentDidCatch(error, errorInfo) {
    console.error(error)
    console.error(errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorMessage error="Что-то пошло не так." extraMessage={this.state.error.toString()} />
      )
    }
    return this.props.children
  }
}
