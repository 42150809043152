import React, {FC} from 'react'
import {LogoProps} from '../types'
import {getPathColor, palette} from './helpers'

const Logo: FC<LogoProps> = ({color, ...props}) => {
  return (
    <svg fill="none" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M63.0932 31.6058L50.0001 44.699L36.9112 31.6058C29.6794 24.3783 29.6794 12.6557 36.9112 5.42387C44.1387 -1.80796 55.8614 -1.80796 63.0932 5.42387C70.3207 12.6557 70.3207 24.3783 63.0932 31.6058Z"
        fill={getPathColor(color, palette.second)}
      />
      <path
        d="M36.9112 68.3939L50.0044 55.3008L63.0932 68.3939C70.325 75.6214 70.325 87.3484 63.0932 94.5759C55.8657 101.808 44.1431 101.808 36.9112 94.5759C29.6794 87.3484 29.6794 75.6257 36.9112 68.3939Z"
        fill={getPathColor(color, palette.first)}
      />
      <path
        d="M68.3941 63.0931L55.301 50L68.3941 36.9112C75.6259 29.6793 87.3486 29.6793 94.5761 36.9112C101.808 44.143 101.808 55.8656 94.5761 63.0931C87.3486 70.325 75.6259 70.325 68.3941 63.0931Z"
        fill={getPathColor(color, palette.third)}
      />
      <path
        d="M31.6058 36.9112L44.699 50.0043L31.6058 63.0931C24.3783 70.325 12.6557 70.325 5.42387 63.0931C-1.80796 55.8656 -1.80796 44.143 5.42387 36.9112C12.6557 29.6793 24.3783 29.6793 31.6058 36.9112Z"
        fill={getPathColor(color, palette.fourth)}
      />
    </svg>
  )
}

export default Logo
