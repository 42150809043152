import React, {FC} from 'react'
import dayjs from 'dayjs'
import clsx from 'clsx'

import Day from '../Day'
import Week from '../Week'
import Header from '../Header'

import {getQaAttribute} from '../../utils'
import {CalendarBlockProps} from './types'
import {freezeRangeValues} from '../types'
import {getDayStates, findDisabledDate} from './helpers'

import {useStyles} from './styles'

const Block: FC<CalendarBlockProps> = ({
  date,
  viewDate,
  dateFormat,
  minDate,
  maxDate,
  elements,
  onChangeViewDate,
  onChangeDate,
  long,
  hideArrow,
  className,
  disabledDates,
  qa,
  hoverDate,
  onChangeHoverDate,
  freezeRange,
}) => {
  const getQA = getQaAttribute(qa)
  const classes = useStyles()
  const isHover =
    Array.isArray(date) && (date.length === 1 || freezeRange === freezeRangeValues.end)

  const onChangeViewDateHandle = (event) => {
    const {value} = event.currentTarget.dataset
    onChangeViewDate(value)
  }

  const onChangeDateHandle = (event) => {
    const {value} = event.currentTarget.dataset
    if (!long) onChangeViewDateHandle(event)

    onChangeHoverDate('')
    onChangeDate(value)
  }

  const onMouseOver = (event) => {
    if (isHover) {
      const {value} = event.currentTarget.dataset
      onChangeHoverDate(value)
    }
  }

  const onMouseOut = () => {
    if (isHover) {
      onChangeHoverDate('')
    }
  }

  return (
    <div className={clsx(classes.root, className)} data-qa={getQA()}>
      <Header
        dateFormat={dateFormat}
        hideArrow={hideArrow}
        long={long}
        maxDate={maxDate}
        minDate={minDate}
        qa={getQA('header')}
        viewDate={viewDate}
        onChangeViewDate={onChangeViewDate}
        onChangeViewDateHandle={onChangeViewDateHandle}
      />
      <Week />
      <div>
        {elements.map((days, i) => (
          <div key={i} className={classes.block}>
            {days.map((day, j) => {
              if (!day) {
                return (
                  <Day key={`${i}-${j}`} qa={getQA('day', 'hide')} states={['hide']} value="none" />
                )
              }

              const {isDisabled, tooltip} = findDisabledDate(
                disabledDates,
                dateFormat,
                dayjs(day, dateFormat)
              )
              const states = getDayStates({
                day,
                date,
                viewDate,
                dateFormat,
                hoverDate,
                minDate,
                maxDate,
                isDisabled,
                freezeRange,
              })

              return (
                <Day
                  key={`${i}-${j}`}
                  dateFormat={dateFormat}
                  qa={getQA('day', states)}
                  states={states}
                  tooltip={tooltip}
                  value={day}
                  onClick={onChangeDateHandle}
                  onMouseOut={onMouseOut}
                  onMouseOver={onMouseOver}
                />
              )
            })}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Block
