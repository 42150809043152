import {createApi} from '@reduxjs/toolkit/query/react'
import redirectLoginOn401Query from './queries/redirectLoginOn401Query'
import {OpenApiRequestBody, OpenApiResponseType} from '@root/core/helperTypes'

const Tags = {
  Launches: 'Launches',
  Reports: 'Reports',
}

const basePath = (systemId: string, taskId: string) =>
  `/systems/${systemId}/tasks/${taskId}/launches`

type SystemIdTaskId = {
  systemId: string
  taskId: string
}

// Start launches types

type LaunchUrl = `/v1/systems/{systemId}/tasks/{taskId}/launches`

export type GetLaunchesResponse = OpenApiResponseType<`${LaunchUrl}/list`, 'post'>
export type GetLaunchByIdResponse = OpenApiResponseType<`${LaunchUrl}/{id}`>
export type GetLastLaunchResponse = OpenApiResponseType<`${LaunchUrl}/last`>
export type StopLaunchResponse = OpenApiResponseType<`${LaunchUrl}/stop`, 'post'>
export type StartLaunchResponse = OpenApiResponseType<`${LaunchUrl}/start`, 'post'>

export type GetLaunchesPayload = SystemIdTaskId & {
  filter: OpenApiRequestBody<`${LaunchUrl}/list`>
}
export type GetLaunchByIdPayload = SystemIdTaskId & {
  launchId: string
}
export type GetLastLaunchPayload = SystemIdTaskId
export type StartLaunchPayload = SystemIdTaskId
export type StopLaunchPayload = SystemIdTaskId

// End launches

// Start reports

export type CreateReportResponse = OpenApiResponseType<'/v1/reports', 'post'>
export type ChangeReportConfigurationResponse = OpenApiResponseType<
  '/v1/reports/{reportId}/change-configuration',
  'post'
>
export type GetReportsListResponse = OpenApiResponseType<'/v1/reports/list', 'post'>
export type GetReportByIdResponse = OpenApiResponseType<'/v1/reports/{reportId}'>
export type GetReportFileByNameResponse = OpenApiResponseType<'/v1/reports/files/{nameInStore}'>
export type UpdateReportResponse = OpenApiResponseType<'/v1/reports/{reportId}', 'patch'>
export type DeleteReportResponse = OpenApiResponseType<'/v1/reports/{reportId}', 'delete'>
export type EditReportResponse = OpenApiResponseType<'/v1/reports/{reportId}/edit', 'post'>
export type DeleteEditReportResponse = OpenApiResponseType<'/v1/reports/{reportId}/edit', 'delete'>

export type CreateReportPayload = OpenApiRequestBody<'/v1/reports'>

export type ChangeReportConfigurationPayload = {
  reportId: string
} & OpenApiRequestBody<'/v1/reports/{reportId}/change-configuration'>

export type GetReportsListPayload = OpenApiRequestBody<'/v1/reports/list'>
export type UpdateReportPayload = {
  reportId: string
  // updateData: OpenApiRequestBody<'/v1/reports/{reportId}', 'patch'>
  formData: any // fixme
}

// End reports

export const launchesApi = createApi({
  reducerPath: 'launchesApi',
  tagTypes: [Tags.Launches, Tags.Reports],
  baseQuery: redirectLoginOn401Query(),
  endpoints: (build) => ({
    getLaunches: build.query<GetLaunchesResponse, GetLaunchesPayload>({
      query: ({systemId, taskId, filter}) => ({
        url: `${basePath(systemId, taskId)}/list`,
        method: 'POST',
        body: filter,
      }),
      providesTags: [Tags.Launches],
    }),

    getLaunchById: build.query<GetLaunchByIdResponse, GetLaunchByIdPayload>({
      query: ({systemId, taskId, launchId}) => `${basePath(systemId, taskId)}/${launchId}`,
      providesTags: [Tags.Launches],
    }),

    getActiveLaunch: build.query<GetLastLaunchResponse, GetLastLaunchPayload>({
      query: ({systemId, taskId}) => `${basePath(systemId, taskId)}/last`,
      providesTags: [Tags.Launches],
    }),

    startLaunch: build.mutation<StartLaunchResponse, StartLaunchPayload>({
      query: ({systemId, taskId}) => ({
        url: `${basePath(systemId, taskId)}/start`,
        method: 'POST',
      }),
      invalidatesTags: [Tags.Launches],
    }),

    stopLaunch: build.mutation<StopLaunchResponse, StopLaunchPayload>({
      query: ({systemId, taskId}) => ({
        url: `${basePath(systemId, taskId)}/stop`,
        method: 'POST',
      }),
      invalidatesTags: [Tags.Launches],
    }),

    getReportById: build.query<GetReportByIdResponse, string>({
      query: (reportId) => `/reports/${reportId}`,
      providesTags: [Tags.Reports],
    }),

    getReportFileByName: build.query<GetReportFileByNameResponse, string>({
      query: (nameInStore) => {
        return {
          url: `/reports/files/${nameInStore}`,
          responseHandler: async (response) => await response.blob(),
        }
      },
      providesTags: [Tags.Reports],
    }),

    createReport: build.mutation<CreateReportResponse, CreateReportPayload>({
      query: ({launchId, configurationId}) => ({
        url: '/reports',
        method: 'POST',
        body: {launchId, configurationId},
      }),
      invalidatesTags: [Tags.Launches, Tags.Reports],
    }),

    updateReport: build.mutation<UpdateReportResponse, UpdateReportPayload>({
      query: ({reportId, formData}) => ({
        url: `/reports/${reportId}`,
        method: 'PATCH',
        body: formData,
      }),
      invalidatesTags: [Tags.Reports],
    }),

    editReport: build.mutation<EditReportResponse, string>({
      query: (reportId) => ({
        url: `/reports/${reportId}/edit`,
        method: 'POST',
      }),
      invalidatesTags: [Tags.Reports],
    }),

    removeEditReport: build.mutation<DeleteEditReportResponse, string>({
      query: (reportId) => ({
        url: `/reports/${reportId}/edit`,
        method: 'DELETE',
      }),
      invalidatesTags: [Tags.Reports],
    }),

    changeReportConfiguration: build.mutation<
      ChangeReportConfigurationResponse,
      ChangeReportConfigurationPayload
    >({
      query: ({reportId, configurationId}) => ({
        url: `/reports/${reportId}/change-configuration`,
        method: 'POST',
        body: {configurationId},
      }),
      invalidatesTags: [Tags.Reports],
    }),

    getReports: build.query<GetReportsListResponse, GetReportsListPayload>({
      query: (filter) => ({
        url: '/reports/list',
        method: 'POST',
        body: filter,
      }),
      providesTags: [Tags.Reports],
    }),

    deleteReport: build.mutation<DeleteReportResponse, string>({
      query: (reportId) => ({
        url: `/reports/${reportId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [Tags.Reports],
    }),
  }),
})

export const {
  useGetLaunchByIdQuery,
  useGetLaunchesQuery,
  useGetActiveLaunchQuery,
  useStartLaunchMutation,
  useStopLaunchMutation,
  useGetReportByIdQuery,
  useGetReportFileByNameQuery,
  useEditReportMutation,
  useRemoveEditReportMutation,
  useCreateReportMutation,
  useUpdateReportMutation,
  useChangeReportConfigurationMutation,
  useGetReportsQuery,
  useDeleteReportMutation,
} = launchesApi

export default launchesApi
