import {Box, Typography} from '@x5-react-uikit/core'
import Breadcrumbs from '@root/components/Breadcrumbs'
import SystemsTable from './components/SystemsTable'
import Section from '@root/components/Section'

const SystemsPage = () => {
  return (
    <Box sx={{mx: 'x8'}}>
      <Breadcrumbs
        routes={[
          {to: '/tasks', label: 'Главная'},
          {to: null, label: 'Информационные системы'},
        ]}
      />
      <Typography variant="h2">{'Информационные системы'}</Typography>
      <Section
        sx={{
          py: undefined,
          px: undefined,
          p: undefined,
          minWidth: 1200,
        }}
      >
        <SystemsTable />
      </Section>
    </Box>
  )
}

export default SystemsPage
