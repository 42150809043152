import {FC, useCallback} from 'react'
import {CustomFilterInputProps} from '@root/components/kit'
import SystemsCombobox, {
  SystemsComboboxProps,
} from '@root/features/systems/components/SystemsCombobox'

export const FilterCollectorSystemsCombobox: FC<CustomFilterInputProps> = ({
  onChange,
  value,
  size,
}) => {
  const handleChange: SystemsComboboxProps['onChange'] = useCallback(
    (_e, system) => {
      onChange(system)
    },
    [onChange]
  )

  const onClear = useCallback(() => {
    onChange(null)
  }, [onChange])

  return (
    <SystemsCombobox
      disableValidation
      comboboxProps={{
        size,
        usePortal: true,
      }}
      selectedSystemId={value as string}
      width="100%"
      onChange={handleChange}
      onClear={onClear}
    />
  )
}
