import {makeUiStyles} from '../../../theme'

export const useCellErrorIconStyles = makeUiStyles(
  (theme) => ({
    root: {
      color: theme.colors.error,
    },
    right: {
      marginLeft: theme.spaces.x2,
    },
    left: {
      marginRight: theme.spaces.x2,
    },
  }),
  'body-cell-error-icon'
)

export default useCellErrorIconStyles
