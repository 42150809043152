import {IconButton} from '@x5-react-uikit/core'
import {Del as DelIcon} from '@x5-react-uikit/icons'
import FlexboxRow from '@root/components/FlexboxRow'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'
import {RequiredRule} from '@root/constants'

export const GRAPHS_REGISTER = 'statistics.graphs'

const GraphDataField = ({form, index, onRemove}) => {
  const registerName = `${GRAPHS_REGISTER}.${index}.graphName`
  const registerUrl = `${GRAPHS_REGISTER}.${index}.graphUrl`

  return (
    <FlexboxRow sx={{gap: '16px', alignItems: 'top'}}>
      <FormInputText
        required
        control={form.control}
        label="Название графика"
        name={registerName}
        rules={RequiredRule}
        width="540px"
      />
      <FormInputText
        required
        control={form.control}
        label="Ссылка на график в Grafana "
        name={registerUrl}
        rules={RequiredRule}
        width="540px"
      />
      <IconButton
        disabled={!onRemove}
        IconComponent={<DelIcon />}
        variant="text"
        onClick={onRemove}
      />
    </FlexboxRow>
  )
}

export default GraphDataField
