import React, {FC} from 'react'
import {LogoProps} from '../types'
import {primary, getPathColor} from './helpers'

const Logo: FC<LogoProps> = ({color, ...props}) => (
  <svg fill="none" viewBox="0 0 88 88" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M58.6203 24.2277C54.9135 21.7699 49.9257 20.5245 43.7915 20.5245H43.1054V20.5061H37.1032V11.0862H56.4165C59.3093 11.0862 61.67 8.89825 61.67 6.09896C61.67 3.29754 59.2809 0.962433 56.4165 0.962433H31.8494C28.9457 0.962433 26.5935 3.31279 26.5935 6.21101V25.4298C26.5935 28.3298 28.9457 30.6784 31.8494 30.6784H44.0067C52.1465 30.6784 54.7416 34.405 54.7416 38.2507C54.7416 42.0355 52.6942 48.1071 44.1509 48.1071C37.0865 48.1071 32.867 43.3326 31.9972 42.555C29.3857 40.2146 26.7484 40.3791 24.9996 41.9181C22.7717 43.8826 23.0469 46.6419 24.3933 48.6805C25.9951 51.1082 32.2447 58.6387 44.1509 58.6387C50.9127 58.6387 56.8875 56.2178 60.9693 51.822C64.595 47.9163 66.4521 42.7227 66.0615 37.572C65.6232 31.778 63.0498 27.1646 58.6203 24.2277Z"
      fill={getPathColor(color)}
    />
    <path
      clipRule="evenodd"
      d="M43.2414 76.4262C43.4947 76.4262 43.7476 76.4235 44 76.4183C44.2524 76.4235 44.5053 76.4262 44.7586 76.4262C58.5396 76.4262 70.9811 68.6343 77.079 56.6077C78.37 54.0627 81.3672 52.8826 84.0695 53.8113C87.3913 54.9581 88.894 58.767 87.2997 61.895C79.2448 77.6904 62.8832 87.9198 44.7586 87.9198C44.5054 87.9198 44.2525 87.9178 44 87.9138C43.7475 87.9178 43.4946 87.9198 43.2414 87.9198C25.1168 87.9198 8.75522 77.6904 0.70032 61.895C-0.893968 58.767 0.608691 54.9581 3.93045 53.8113C6.63282 52.8826 9.62996 54.0627 10.9209 56.6077C17.0189 68.6343 29.4604 76.4262 43.2414 76.4262Z"
      fill={getPathColor(color, primary)}
      fillRule="evenodd"
    />
  </svg>
)

export default Logo
