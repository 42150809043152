import {SpaceTokenName, SpaceTokenRecord} from '@x5-react-uikit/tokens'
import ButtonBase from '../Button/ButtonBase'
import {SegmentedControlSizes, SegmentedControlProps} from './types'
import {restyled} from '../styles'

const borderWidth = 2
const cutBorder = (spaces: SpaceTokenRecord, spaceNames: [SpaceTokenName, SpaceTokenName]) =>
  `${spaces[spaceNames[0]] - borderWidth}px ${spaces[spaceNames[1]] - borderWidth}px `

export const StyledSegmentedControl = restyled(ButtonBase)<
  Partial<SegmentedControlProps> & {cssWidth: SegmentedControlProps['width']}
>(({theme: {spaces, colors, typography}, size, selected, disabled, cssWidth}) => ({
  position: 'relative',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: cssWidth,
  padding:
    size === SegmentedControlSizes.small
      ? cutBorder(spaces, ['x2', 'x8'])
      : cutBorder(spaces, ['x6', 'x10']),
  borderWidth,
  borderStyle: 'solid',
  borderColor: selected ? colors.grey[30] : 'transparent',
  backgroundColor: selected ? colors.white : null,
  color: colors.grey[disabled ? 40 : selected ? 100 : 60],
  cursor: disabled ? 'no-drop' : 'pointer',
  borderRadius: spaces.x2,
  ...typography.p1,
  ':hover': {
    backgroundColor: disabled || selected ? null : colors.grey[30],
    color: disabled || selected ? null : colors.grey[100],
  },
  '::after': {
    position: 'absolute',
    top: size === SegmentedControlSizes.small ? 7 : 14,
    right: -3,
    width: 1,
    height: size === SegmentedControlSizes.small ? spaces.x8 : spaces.x10,
    content: !selected ? '""' : null,
    backgroundColor: colors.grey[30],
  },
  ':last-of-type::after': {
    display: 'none',
  },
}))

export const StyledSegmentedControls = restyled.div(({theme: {colors, spaces}}) => ({
  width: 'fit-content',
  backgroundColor: colors.grey[20],
  borderRadius: spaces.x2,
}))
