import type {User} from '@root/core/types'
import type {UserRolesInfo} from '@root/core/UserRolesInfo'
import type {Report} from '@root/features/reports/types'

// This does not mean the user can edit the report, but ensures that they have a valid role.
export function isRoleAllowed(currentUserRolesInfo: UserRolesInfo) {
  return (
    currentUserRolesInfo.isAdmin ||
    currentUserRolesInfo.isManager ||
    currentUserRolesInfo.isSpecialist
  )
}

export function isSameEditorOrNull(currentUser: User, currentEditor?: Partial<User>) {
  return currentEditor == null || currentUser.email === currentEditor.email
}

// Ensures that the user can edit report.
export function canEditReport(
  currentUserRolesInfo: UserRolesInfo,
  currentUser: User,
  report: Report
): boolean {
  return (
    isSameEditorOrNull(currentUser, report.currentEditor) && isRoleAllowed(currentUserRolesInfo)
  )
}
