import React from 'react'
import createLogo from '../utils/createSvgLogo'
import {logoVariants, logoColors, logoBrands} from '../types'
import X5Full from './Full'
import X5Small from './Small'

export default createLogo((variant = logoVariants.full, color = logoColors.multi, partialProps) => {
  const props = {
    color,
    width: '100%',
    height: '100%',
    ...partialProps,
  }

  if (variant === logoVariants.icon) return <X5Small {...props} />

  return <X5Full {...props} />
}, `${logoBrands.x5}Logo`)
