import React, {FC} from 'react'
import {defaultCellDensity} from '../../helpers'
import {BodyCellInnerTextProps} from './types'
import {BodyCellInnerTextView} from './View'
import {BodyCellInnerTextEdit} from './Edit'

export const BodyCellInnerText: FC<BodyCellInnerTextProps> = (props: BodyCellInnerTextProps) => {
  const {
    density = defaultCellDensity,
    noWrap,
    rootRef,
    editable,
    editing,
    onStartEdit,
    children: value,
    onCancel,
    onChange,
  } = props

  return (
    <>
      <BodyCellInnerTextView
        density={density}
        editable={editable}
        noWrap={noWrap}
        onStartEdit={onStartEdit}
      >
        {value}
      </BodyCellInnerTextView>
      {editing && (
        <BodyCellInnerTextEdit
          density={density}
          noWrap={noWrap}
          rootRef={rootRef}
          onCancel={onCancel}
          onChange={onChange}
        >
          {value}
        </BodyCellInnerTextEdit>
      )}
    </>
  )
}

export default BodyCellInnerText
