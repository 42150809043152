import React, {ChangeEvent, CSSProperties, FC, useCallback} from 'react'
import TextField from '@material-ui/core/TextField'
import {makeStyles} from '@material-ui/core/styles'
import {createFakeEvent} from '../../../utils/fakeEvent'
import {useScrollStyles} from '../../../Scroll'
import {EditableCellPortal} from '../EditableCellPortal'
import {getCellDensityClassNameBy} from '../../helpers'
import {useBaseCellStyles} from '../../Cell/styles'
import {BodyCellInnerTextEditProps} from './types'
import {useInnerTextStyles} from './styles'
import clsx from 'clsx'

export const EMPTY_VALUE = '‎'
const cellNormalWidth = 150
const getMaxWidth = (current, minWidth) =>
  current <= cellNormalWidth ? current + minWidth : current

const getStyles = (ref) => {
  const fixBorderWidth = 3
  const minWidth = 100
  const anchor = ref.current || ref
  const styles: CSSProperties = {}

  if (anchor) {
    const rect = anchor.getBoundingClientRect()
    styles.minHeight = rect.height + fixBorderWidth
    styles.minWidth = getMaxWidth(rect.width, minWidth) + fixBorderWidth
  }

  return styles
}

export const BodyCellInnerTextEdit: FC<BodyCellInnerTextEditProps> = (
  props: BodyCellInnerTextEditProps
) => {
  const {density, rootRef, children: value, onCancel, onChange} = props
  const [localValue, setLocalValue] = React.useState(value === EMPTY_VALUE ? '' : value)

  const getFEObject = () => createFakeEvent(localValue) as ChangeEvent<HTMLInputElement>

  const onClickAway = useCallback(
    (e) => {
      e.stopPropagation()

      const fakeEventObject = getFEObject()
      onChange && onChange(fakeEventObject, localValue)
      onCancel && onCancel()
    },
    [localValue]
  )

  const _onChangeTempValue = useCallback((event) => {
    setLocalValue(event.target.value)
  }, [])

  const _onKeyDown = (e) => {
    // escape new line on text editing
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()

      // apply changes on Enter
      const fakeEventObject = getFEObject()
      onChange && onChange(fakeEventObject, localValue)
    }
  }

  const rootClasses = getStyles(rootRef)
  const useStyles = makeStyles({
    root: {
      minHeight: rootClasses.minHeight,
      width: '100%',
    },
  })

  const muClasses = useStyles()
  const classes = React.useMemo(() => ({root: muClasses.root}), [])
  const baseClasses = useBaseCellStyles()
  const innerClasses = useInnerTextStyles()
  const densityClassName = baseClasses[getCellDensityClassNameBy(density)]
  const scrollClasses = useScrollStyles()
  const inputProps = React.useMemo(
    () => ({className: clsx(densityClassName, scrollClasses.root)}),
    [density]
  )

  return (
    <EditableCellPortal cellRef={rootRef} onClickAway={onClickAway}>
      <TextField
        autoFocus
        multiline
        className={innerClasses.innerRootEdit}
        inputProps={inputProps}
        rowsMax="8"
        style={getStyles(rootRef)}
        value={localValue}
        onChange={_onChangeTempValue}
        onKeyDown={_onKeyDown}
      />
    </EditableCellPortal>
  )
}

export default BodyCellInnerTextEdit
