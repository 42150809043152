import {contentHeight, containerMaxWidth} from './helpers'
import {restyled, PREFIX} from '../styles'

export const classes = {
  header: `${PREFIX}-filter-collector-header`,
  footer: `${PREFIX}-filter-collector-footer`,
  footerLeft: `${PREFIX}-filter-collector-footer-left`,
  footerRight: `${PREFIX}-filter-collector-footer-right`,
  dropdownAction: `${PREFIX}-filter-collector-dropdown-action`,
  dropdownActionContent: `${PREFIX}-filter-collector-dropdown-action-content`,
  rows: `${PREFIX}-filter-collector-rows`,
  row: `${PREFIX}-filter-collector-row`,
  contentDescription: `${PREFIX}-filter-collector-content-description`,
  contentAddButton: `${PREFIX}-filter-collector-content-add-button`,
  dragIndicatorIcon: `${PREFIX}-filter-collector-drag-indicator-icon`,
  columnSelector: `${PREFIX}-filter-collector-column-selector`,
  valueInput: `${PREFIX}-filter-collector-value-input`,
} as const

export const StyledContainer = restyled('div')(({theme}) => ({
  borderRadius: 8,
  background: theme.colors.white,
  boxShadow: theme.shadows.medium,
  maxWidth: containerMaxWidth,

  [`.${classes.header}`]: {
    ...theme.typography.h6,
    color: theme.colors.grey[80],
    padding: `${theme.spaces.x8 - theme.spaces.x1}px ${theme.spaces.x8}px`,
  },
  [`.${classes.footer}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spaces.join('x6', 'x8'),
  },
  [`.${classes.footerLeft}`]: {
    marginRight: theme.spaces.x4,
  },
  [`.${classes.footerRight}`]: {
    'button:last-of-type': {
      marginLeft: theme.spaces.x4,
    },
  },
  [`&& .${classes.dropdownAction}`]: {
    backgroundColor: theme.colors.accent[10],
    paddingRight: theme.spaces.x4,
  },
  [`.${classes.dropdownActionContent}`]: {
    ...theme.typography.p2,
  },
}))

export const StyledContent = restyled.div<{hasScroll: boolean}>(({theme, hasScroll}) => ({
  width: '640px',
  height: contentHeight,
  boxSizing: 'border-box',
  overflow: 'auto',
  padding: theme.spaces.join('x4', 'x8'),
  borderTop: hasScroll ? `1px solid ${theme.colors.grey[20]}` : null,
  borderBottom: hasScroll ? `1px solid ${theme.colors.grey[20]}` : null,
  [`.${classes.rows}`]: {
    display: 'flex',
    flexDirection: 'column',
  },
  [`.${classes.contentDescription}`]: {
    ...theme.typography.p1,
  },
  [`.${classes.contentAddButton}`]: {
    margin: theme.spaces.join('x4', 'x0'),
  },
  [`.${classes.row}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spaces.x2,
    padding: theme.spaces.x2,
  },
  [`.${classes.columnSelector}`]: {
    flex: 1,
  },
  [`.${classes.valueInput}`]: {
    flex: 1,
    maxWidth: 240,
  },
  [`.${classes.dragIndicatorIcon}`]: {
    flexShrink: 0,
    cursor: 'pointer',
    outline: 'none',
  },
}))
