import {isArray} from 'lodash'

export const InputTypes = {
  text: 'text',
  switcher: 'switcher',
  checkList: 'checkList',
  checkbox: 'checkbox',
  table: 'table',
  dateInput: 'dateInput',
  list: 'list',
  file: 'file',
  comment: 'comment',
}
const inputsNoFillRequired = [
  InputTypes.comment,
  InputTypes.checkbox,
  InputTypes.switcher,
  InputTypes.file,
  InputTypes.checkList,
]

export const getInputDefaultValue = (meta) => {
  switch (meta.type) {
    case InputTypes.comment:
    case InputTypes.text:
      return {
        id: meta.id,
        value: '',
      }
    case InputTypes.switcher:
      return false
    case InputTypes.checkList:
      return meta.values.map((checkboxMeta) => ({
        id: checkboxMeta.id,
        label: checkboxMeta.label,
        value: false,
      }))
    default:
      throw new Error(`field "${meta.type}" is not implemented`)
  }
}
export const getDefaultAnswer = () => true

export const createEmptyTableRow = (tableMeta) => {
  return {
    cells: Object.values(tableMeta.columns).map((columnMeta) => ({
      columnId: columnMeta.id,
      values: {
        [columnMeta.type]: getInputDefaultValue(columnMeta),
      },
    })),
  }
}

export const createEmptyListElement = (number, listMeta) => {
  const element = {
    number,
    values: {},
  }
  listMeta.elements.forEach((elementMeta) => {
    element.values[elementMeta.type] ||= []
    let inputValue
    switch (elementMeta.type) {
      case InputTypes.text:
        inputValue = getInputDefaultValue(elementMeta)
        break
      case InputTypes.table:
        inputValue = {
          id: elementMeta.id,
          rows: [createEmptyTableRow(elementMeta)],
        }
        break
      default:
        throw new Error(`field "${elementMeta.type}" is not implemented`)
    }
    element.values[elementMeta.type].push(inputValue)
  })
  return element
}

/**
 * Инициализирует объект step с первичным состоянием.
 */
export const initStepFormData = (stepMeta = {}, stepId) => {
  const emptyFields = []
  for (const fieldId in stepMeta) {
    const fieldMeta = stepMeta[fieldId]
    let fieldData = {id: fieldMeta.id}
    if (fieldMeta.question) fieldData.question = getDefaultAnswer()

    let valuesMeta
    if (fieldMeta.question) {
      valuesMeta = fieldMeta.question.true.values
      fieldData.question = getDefaultAnswer()
    } else {
      valuesMeta = fieldMeta.values
    }

    fieldData.values = {}
    for (const valueMeta of valuesMeta) {
      switch (valueMeta.type) {
        case InputTypes.text:
          fieldData.values.text ||= []
          fieldData.values.text.push(getInputDefaultValue(valueMeta))
          break
        case InputTypes.comment:
          fieldData.values.comment = {
            id: valueMeta.id,
            value: '',
          }
          break
        case InputTypes.checkbox:
          fieldData.values.checkbox ||= []
          fieldData.values.checkbox.push({
            id: valueMeta.id,
            value: false,
          })
          break
        case InputTypes.dateInput:
          fieldData.values.dateInput ||= []
          fieldData.values.dateInput.push({
            id: valueMeta.id,
            value: null,
          })
          break
        case InputTypes.list: {
          fieldData.values.list ||= []
          const listValuesRow = {
            id: valueMeta.id,
            elements: [
              {
                number: 1,
                values: {},
              },
            ],
          }
          listValuesRow.elements[0].values = valueMeta.elements.reduce((values, inputMeta) => {
            switch (inputMeta.type) {
              case 'text':
                values.text ||= []
                values.text.push({
                  id: inputMeta.id,
                  value: '',
                })
                break
              case 'table':
                values.table ||= []
                values.table.push({
                  id: inputMeta.id,
                  rows: [createEmptyTableRow(inputMeta)],
                })
                break
              default:
                throw new Error(`field "${inputMeta.type}" is not implemented`)
            }
            return values
          }, {})
          fieldData.values.list.push(listValuesRow)
          break
        }
        case InputTypes.table: {
          fieldData.values.table ||= []
          const table = {
            id: valueMeta.id,
            rows: [createEmptyTableRow(valueMeta)],
          }
          fieldData.values.table.push(table)
          break
        }
        case InputTypes.file:
          fieldData.values.file = {
            items: [],
          }
          if (valueMeta.comment)
            fieldData.values.file.comment = {
              id: valueMeta.comment.id,
              value: '',
            }
          break
        default:
          throw new Error(`field "${valueMeta.type}" is not implemented`)
      }
    }
    emptyFields.push(fieldData)
  }

  return {
    number: Number(stepId),
    completed: false,
    fields: emptyFields,
  }
}

/**
 * !Рекурсивный вызов в теле функции!
 *
 * Проверяет, что значение инпута заполнено.
 *
 * @param {Object} inputObj
 * @param {Object} inputMeta
 * @returns {Boolean}
 */
export const checkInputFilled = (inputObj, inputMeta) => {
  let isFilled = true
  if (inputsNoFillRequired.includes(inputMeta.type)) return isFilled

  switch (inputMeta.type) {
    case InputTypes.text:
    case InputTypes.dateInput:
      isFilled = !!inputObj.value
      break
    case InputTypes.table: {
      const allCells = inputObj.rows.reduce((reduced, row) => [...reduced, ...row.cells], [])
      for (const cell of allCells) {
        if (inputMeta.columns[cell.columnId].type === InputTypes.text)
          isFilled = !!cell.values.text.value
      }
      break
    }
    case InputTypes.list:
      for (const nestedInputMeta of inputMeta.elements) {
        if (inputsNoFillRequired.includes(nestedInputMeta.type)) continue

        const nestedInputObjects = inputObj.elements.reduce((nestedObjs, listElement) => {
          const values = listElement.values[nestedInputMeta.type]
          const nestedObj = values.find((value) => value.id === nestedInputMeta.id)
          return [...nestedObjs, nestedObj]
        }, [])
        for (const nestedInputObject of nestedInputObjects) {
          isFilled = checkInputFilled(nestedInputObject, nestedInputMeta)
        }
      }
      break
    default:
      throw new Error(`field cannot be handled: ${inputMeta.type}`)
  }
  return isFilled
}

export const isStepCompleted = (stepValue, stepMeta) => {
  const requiredBlankFields = getBlankFields([stepValue], {fields: Object.values(stepMeta)}, true)
  return requiredBlankFields.length === 0
}

export const getBlankInputs = (field, fieldMeta) => {
  const blankInputs = []

  let fieldMetaValues = fieldMeta.values
  if (!fieldMetaValues) {
    const answer = field.question
    fieldMetaValues = fieldMeta.question[answer].values
  }

  const inputTypes = Object.keys(field.values).filter((t) => field.values[t])
  // Случай, когда field состоит из массива чекбоксов и комментария, обрабатываем отдельно.
  // Логика следующая: если ни один из чекбоксов не активен, то комментарий должен быть заполнен.
  if (
    inputTypes.length === 2 &&
    inputTypes.includes(InputTypes.checkbox) &&
    inputTypes.includes(InputTypes.comment)
  ) {
    if (!field.values.checkbox.some((input) => input.value) && !field.values.comment.value)
      blankInputs.push(fieldMetaValues.find((meta) => meta.type === InputTypes.comment))
    return blankInputs
  }

  for (const inputMeta of fieldMetaValues) {
    if (inputsNoFillRequired.includes(inputMeta.type)) continue
    const inputObj =
      inputMeta.type === InputTypes.comment
        ? field.values[inputMeta.type]
        : field.values[inputMeta.type].find((i) => i.id === inputMeta.id)
    const isFilled = checkInputFilled(inputObj, inputMeta)
    if (!isFilled) {
      blankInputs.push(inputMeta)
    }
  }
  return blankInputs
}

export const getBlankFields = (formValue, formMeta, onlyRequired) => {
  const blankFields = []

  for (const fieldMeta of formMeta.fields) {
    if (onlyRequired && !fieldMeta.required) continue

    const field = formValue
      .find((step) => step.number === fieldMeta.step)
      ?.fields.find((f) => f.id === fieldMeta.id)

    if (!field) {
      blankFields.push(fieldMeta)
      continue
    }

    const blankInputs = getBlankInputs(field, fieldMeta)
    if (blankInputs.length > 0) blankFields.push(fieldMeta)

    // let fieldMetaValues = fieldMeta.values;
    // if (!fieldMetaValues) {
    //   const answer = field.question;
    //   fieldMetaValues = fieldMeta.question[answer].values;
    // }

    // for (const inputMeta of fieldMetaValues) {
    //   if (inputsNoFillRequired.includes(inputMeta.type))
    //     continue;
    //   const inputObj = inputMeta.type === InputTypes.comment
    //     ? field.values[inputMeta.type]
    //     : field.values[inputMeta.type].find(i => i.id === inputMeta.id);
    //   const isFilled = checkInputFilled(inputObj, inputMeta);
    //   if (!isFilled) {
    //     blankFields.push(fieldMeta);
    //     break;
    //   }
    // }
  }
  return blankFields
}

export const getAddedFiles = (stepValue) => {
  const toAdd = []

  for (const field of stepValue.fields) {
    if (!field.values.file?.items.length) continue

    for (const fileObj of field.values.file.items) {
      if (fileObj.file) toAdd.push(fileObj.file)
    }
  }
  return toAdd
}

export const getRemovedFilesMeta = (stepValue) => {
  const toDelete = []

  for (const field of stepValue.fields) {
    if (!field.values.file?.items.length) continue

    for (const fileObj of field.values.file.items) {
      if (fileObj.shouldRemoved) {
        let fieldFilesToDelete = toDelete.find((f) => f.id === field.id)
        if (!fieldFilesToDelete) {
          fieldFilesToDelete = {id: field.id, files: [fileObj.nameInStore]}
          toDelete.push(fieldFilesToDelete)
        } else fieldFilesToDelete.files.push(fileObj.nameInStore)
      }
    }
  }
  return toDelete
}

export const prepareFilesMetaToPush = (stepValue) => {
  for (const field of stepValue.fields) {
    if (field.values.file)
      field.values.file.items = field.values.file.items.map((fileObj) => {
        const prepared = {name: fileObj.name}
        if (fileObj.date) prepared.date = fileObj.date
        if (fileObj.nameInStore) prepared.nameInStore = fileObj.nameInStore
        return prepared
      })
  }
}

export const getOrCreateFieldData = (fieldMeta, _formValue, baseRegister) => {
  const formValue = isArray(_formValue) ? _formValue : Object.values(_formValue)
  const stepIndex = formValue.findIndex((step) => step.number === fieldMeta.step)
  let fieldCreated = false
  let fieldValue
  let fieldRegister
  let fieldIndex = formValue[stepIndex]?.fields.findIndex((field) => field.id === fieldMeta.id)
  if (fieldIndex !== -1) {
    fieldValue = formValue[stepIndex].fields[fieldIndex]
  } else {
    fieldCreated = true
    fieldIndex = formValue[stepIndex].fields.length
    fieldValue = initStepFormData({[fieldMeta.id]: fieldMeta}).fields[0]
  }
  fieldRegister = `${baseRegister ? baseRegister + '.' : ''}${stepIndex}.fields.${fieldIndex}`
  return {fieldIndex, fieldValue, fieldRegister, fieldCreated}
}

export const getFormIndiciesById = (formData, fieldId) => {
  let stepIndex, fieldIndex
  for (let [newStepIndex, step] of formData.entries()) {
    fieldIndex = step.fields.findIndex((field) => field.id === fieldId)
    if (fieldIndex !== -1) {
      stepIndex = newStepIndex
      break
    }
  }
  return {stepIndex, fieldIndex}
}

export const getUpdatedFieldIds = (step, currentStepMeta) => {
  return step?.fields
    .map((field, fieldIndex) => !!field && fieldIndex)
    .filter((field) => field !== undefined)
    .map((index) => Object.keys(currentStepMeta).at(index))
}
