import {Box, Tab, TabList, Tabs, Typography} from '@x5-react-uikit/core'
import Breadcrumbs from '@root/components/Breadcrumbs'
import FlexboxRow from '@root/components/FlexboxRow'
import LoaderFrame from '@root/components/Loader'
import {TaskStatuses, getTaskStatusDisplay, taskStatusToColor} from '@root/constants'
import {useState} from 'react'
import {useParams} from 'react-router-dom'
import {useGetTaskByIdQuery} from '@root/redux/api/tasksApi'
import TaskActionButtons from './components/TaskActionButtons'
import TaskInfoTab from './components/TaskInfoTab'
import ChangeToCompletedModal from './components/modal/ChangeStatusToCompleted'
import ChangeToNotRequiredModal from './components/modal/ChangeStatusToNotRequired'
import ChangeToSearchingPerformerModal from './components/modal/ChangeStatusToSearching'
import ChangeToSuspendModal from './components/modal/ChangeStatusToSuspend'
import ChangeToWithdrawnModal from './components/modal/ChangeStatusToWithdrawn'
import {TaskContextProvider} from '../context'
import ChangeToInProgressModal from './components/modal/ChangeStatusToInProgress'

const StatusChip = ({status}) => {
  const {color, backgroundColor} = taskStatusToColor(status)
  return (
    <Box
      sx={{
        py: 'x2',
        px: 'x6',
        borderRadius: 'x4',
        color,
        backgroundColor,
        width: 'fit-content',
      }}
    >
      <Typography variant="p1">{getTaskStatusDisplay(status)}</Typography>
    </Box>
  )
}

const TaskSectionTabLabels = {
  information: 'Информация о заявке',
  // reports: 'Отчёты',
  // history: 'История действий'
}

const TaskPage = () => {
  const {taskId} = useParams()
  const [taskSectionTab, setTaskSectionTab] = useState(TaskSectionTabLabels.information)

  const {data: task, isLoading: isTaskLoading} = useGetTaskByIdQuery(taskId)
  // const {data: userInfo} = useGetUserinfoQuery();

  const [modalChangeStatus, setModalChangeStatus] = useState(false)
  const closeChangeStatusModal = () => setModalChangeStatus(null)

  if (isTaskLoading) return <LoaderFrame height="600px" />

  let currentTabContent
  if (taskSectionTab === TaskSectionTabLabels.information) currentTabContent = <TaskInfoTab />

  return (
    <TaskContextProvider value={{taskId}}>
      <Box sx={{mx: 'x8'}}>
        <Breadcrumbs
          routes={[
            {to: '/tasks', label: 'Главная'},
            {to: null, label: `Заявка №${task.number}`},
          ]}
        />
        <FlexboxRow sx={{gap: '16px', mb: 'x12', justifyContent: 'space-between'}}>
          <FlexboxRow sx={{gap: '16px', alignItems: 'center'}}>
            <Typography variant="h2">{`Заявка №${task.number}`}</Typography>
            <StatusChip status={task.status} />
          </FlexboxRow>
          <FlexboxRow sx={{gap: '16px'}}>
            <TaskActionButtons setModalChangeStatus={setModalChangeStatus} />
          </FlexboxRow>
        </FlexboxRow>
        <Tabs value={taskSectionTab} onChange={(e, newTab) => setTaskSectionTab(newTab)}>
          <TabList>
            <Tab
              label={TaskSectionTabLabels.information}
              value={TaskSectionTabLabels.information}
            />
          </TabList>
        </Tabs>
        <Box sx={{width: '1200px'}}>{currentTabContent}</Box>
      </Box>
      <ChangeToSearchingPerformerModal
        open={modalChangeStatus === TaskStatuses.SEARCHING_PERFORMER}
        onClose={closeChangeStatusModal}
        onOpen={() => setModalChangeStatus(TaskStatuses.SEARCHING_PERFORMER)}
      />
      <ChangeToInProgressModal
        open={modalChangeStatus === TaskStatuses.IN_PROGRESS}
        onClose={closeChangeStatusModal}
        onOpen={() => setModalChangeStatus(TaskStatuses.IN_PROGRESS)}
      />
      <ChangeToCompletedModal
        open={modalChangeStatus === TaskStatuses.COMPLETED_SUCCESSFULLY}
        onClose={closeChangeStatusModal}
        onOpen={() => setModalChangeStatus(TaskStatuses.COMPLETED_SUCCESSFULLY)}
      />
      <ChangeToWithdrawnModal
        open={modalChangeStatus === TaskStatuses.WITHDRAWN}
        onClose={closeChangeStatusModal}
      />
      <ChangeToSuspendModal
        open={modalChangeStatus === TaskStatuses.SUSPENDED}
        onClose={closeChangeStatusModal}
        onOpen={() => setModalChangeStatus(TaskStatuses.SUSPENDED)}
      />
      <ChangeToNotRequiredModal
        open={modalChangeStatus === TaskStatuses.NOT_REQUIRED}
        onClose={closeChangeStatusModal}
        onOpen={() => setModalChangeStatus(TaskStatuses.NOT_REQUIRED)}
      />
    </TaskContextProvider>
  )
}

export default TaskPage
