import {makeUiStyles} from '../../theme'
import {TableStyleHelpers} from '../Cell/styles'

export const useStyles = makeUiStyles(
  (theme) => ({
    root: {
      display: 'table-header-group',
      borderBottom: `2px solid ${TableStyleHelpers.getBorderColor(theme)}`,
    },
  }),
  'table-head'
)
