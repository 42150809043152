import {forwardRef, createElement} from 'react'
import {DataGridSectionProps} from './types'

export const DataGridSection = forwardRef<HTMLTableSectionElement, DataGridSectionProps>(
  ({role = 'body', children, ...props}, ref) => {
    const section = role === 'body' ? 'tbody' : 'tfoot'

    return createElement(section, {...props, ref}, children)
  }
)

export default DataGridSection
