import React, {FC} from 'react'
import {defineColors} from '../utils/defineColors'
import {logoColors, LogoProps} from '../types'

const getPathColor = defineColors('#222429')

const Logo: FC<LogoProps> = ({color, ...props}) => {
  if (color === logoColors.multi) {
    return (
      <svg fill="none" viewBox="0 0 429 100" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
          d="M112.359 72.1905V31.5908H145.369V72.1905H137.313V39.3477H120.429V72.1905H112.359Z"
          fill="#E52322"
        />
        <path
          d="M176.422 42.6074V72.1907H168.958V61.9308C167.512 62.1921 165.984 62.5359 164.125 62.5909L157.955 72.1907H149.569L156.95 61.5457C153.08 59.9228 151.042 57.1034 151.042 52.7023C151.042 46.6784 155.449 42.6074 164.166 42.6074H176.422V42.6074ZM168.958 48.7964H164.386C160.572 48.7964 158.671 50.158 158.671 52.8123C158.671 55.4117 160.517 56.7183 164.221 56.7183C165.86 56.7183 167.43 56.6082 168.958 56.3332V48.7964Z"
          fill="#E52322"
        />
        <path
          d="M226.867 45.1106C229.153 47.3386 230.296 50.3781 230.296 54.339V72.2045H222.611V55.7418C222.611 51.2308 220.215 48.8515 216.676 48.8515C212.806 48.8515 210.135 51.6159 210.135 55.9619V72.1907H202.45V55.7968C202.45 51.3958 200.109 48.8515 196.46 48.8515C192.7 48.8515 189.974 51.6159 189.974 56.0169V72.1907H182.289V42.6075H189.808V46.8435C191.502 43.749 194.986 41.741 198.856 41.796C202.56 41.796 205.989 43.584 207.573 47.0085C209.749 43.749 213.784 41.796 218.191 41.796C221.744 41.796 224.636 42.8825 226.867 45.1106Z"
          fill="#E52322"
        />
        <path
          d="M296.328 46.3626C299.11 49.3471 300.474 53.0467 300.474 57.379C300.474 61.725 299.055 65.4109 296.273 68.3954C293.492 71.3798 290.008 72.9064 285.821 72.9064C282.172 72.9064 278.812 71.3111 276.512 68.5054V84.3629H268.827V42.608H276.457V46.4864C278.894 43.4607 282.117 41.8516 285.876 41.8516C290.063 41.8516 293.547 43.3644 296.328 46.3626ZM290.434 63.4579C291.963 61.8351 292.72 59.8133 292.72 57.434C292.72 55.0409 291.963 53.033 290.434 51.4101C288.906 49.7872 286.95 48.962 284.499 48.962C282.103 48.962 280.147 49.7734 278.619 51.4101C277.09 53.033 276.333 55.0547 276.333 57.434C276.333 59.8271 277.09 61.8351 278.619 63.4579C280.147 65.0808 282.103 65.906 284.499 65.906C286.95 65.906 288.92 65.0808 290.434 63.4579Z"
          fill="#E52322"
        />
        <path
          d="M307.704 46.362C310.706 43.3225 314.506 41.7959 319.147 41.7959C323.775 41.7959 327.589 43.3225 330.591 46.362C333.593 49.3464 335.053 53.0461 335.053 57.3783C335.053 61.7794 333.58 65.4653 330.591 68.4497C327.589 71.4342 323.788 72.9608 319.147 72.9608C314.52 72.9608 310.706 71.4342 307.704 68.4497C304.701 65.4653 303.242 61.7656 303.242 57.3783C303.242 53.0323 304.715 49.3464 307.704 46.362ZM319.147 65.8504C321.488 65.8504 323.403 65.0389 324.973 63.4023C326.556 61.7244 327.369 59.7164 327.369 57.3783C327.369 55.0403 326.556 53.0323 324.973 51.4094C323.389 49.7865 321.488 48.9613 319.147 48.9613C316.806 48.9613 314.851 49.7728 313.267 51.4094C311.683 53.0323 310.926 55.054 310.926 57.3783C310.926 59.7164 311.683 61.7244 313.267 63.4023C314.851 65.0252 316.806 65.8504 319.147 65.8504Z"
          fill="#E52322"
        />
        <path
          d="M356.673 42.6074H364.357V72.1907H356.673V62.2333C354.429 63.5124 351.991 64.1588 350.077 64.1588C346.538 64.1588 343.922 63.5124 341.581 61.9308C339.239 60.2529 337.931 57.1034 337.931 52.5373V42.6074H345.56V52.0009C345.56 55.4805 347.364 57.2134 350.958 57.2134C352.969 57.2134 354.69 56.9383 356.673 56.1406V42.6074Z"
          fill="#E52322"
        />
        <path
          d="M389.752 72.1909L377.923 58.5339V72.1909H370.238V42.5664H377.923V54.2979L388.706 42.6077H398.401L385.166 56.3059L399.172 72.1909H389.752Z"
          fill="#E52322"
        />
        <path
          d="M421.701 42.6081H429V72.1914H421.701V68.2854C419.154 71.3662 415.986 72.8928 412.227 72.8928C407.971 72.8928 404.432 71.4212 401.664 68.4367C398.882 65.4522 397.519 61.7114 397.519 57.2553C397.519 52.7992 398.938 49.1134 401.719 46.2389C404.501 43.3095 408.04 41.8379 412.227 41.8379C415.931 41.8379 419.195 43.5158 421.701 46.4727V42.6081ZM419.402 51.3552C417.818 49.6773 415.862 48.8521 413.466 48.8521C411.015 48.8521 409.059 49.6635 407.476 51.3552C405.947 53.033 405.19 55.041 405.19 57.3791C405.19 59.7722 405.947 61.7801 407.531 63.458C409.114 65.1359 411.07 65.9611 413.466 65.9611C415.862 65.9611 417.832 65.1497 419.402 63.458C420.985 61.7801 421.798 59.7722 421.798 57.3791C421.798 55.041 420.985 53.033 419.402 51.3552Z"
          fill="#E52322"
        />
        <path
          d="M264.352 60.0879H242.07C243.158 64.2689 246.05 66.3869 250.732 66.3869C253.941 66.3869 257.521 65.1904 260.234 63.0449L263.319 67.8998C259.573 71.3931 255.304 72.8509 250.402 72.8509C245.169 72.8509 241.189 71.3243 238.421 68.3399C235.639 65.3554 234.276 61.6558 234.276 57.3785C234.276 52.8675 235.694 49.1816 238.572 46.2521C241.464 43.3227 245.169 41.8511 249.741 41.8511C254.037 41.8511 257.645 43.2127 260.413 45.867C263.195 48.5214 264.613 52.111 264.613 56.7184C264.613 57.7499 264.503 58.8914 264.352 60.0879ZM244.301 30.7247C246.862 30.7247 248.873 32.7327 248.873 35.1807C248.873 37.8351 246.917 39.8018 244.301 39.8018C241.684 39.8018 239.729 37.8489 239.729 35.1807C239.729 32.7327 241.739 30.7247 244.301 30.7247ZM242.07 54.3941H257.163C256.461 50.5431 253.679 48.2051 249.754 48.2051C245.885 48.2051 242.993 50.5294 242.07 54.3941ZM251.545 35.167C251.545 32.6089 253.555 30.7109 256.172 30.7109C258.733 30.7109 260.689 32.7189 260.689 35.167C260.689 37.8214 258.733 39.7881 256.172 39.7881C253.555 39.7881 251.545 37.8351 251.545 35.167Z"
          fill="#E52322"
        />
        <path
          d="M50.072 0C22.4608 0 0 22.4316 0 50.0069C0 77.5822 22.4608 100.014 50.072 100.014C77.6833 100.014 100.13 77.5684 100.13 50.0069C100.13 22.4453 77.6695 0 50.072 0Z"
          fill="white"
        />
        <path
          d="M75.3284 14.1387C71.5 23.931 62.8104 25.9665 50.2097 25.9665C48.8602 25.9665 47.5244 25.9527 46.2299 25.9252C44.9767 25.9115 43.7924 25.884 42.6356 25.884C41.6027 25.884 40.5699 25.8977 39.5508 25.939C41.3136 21.6067 44.1366 18.6635 48.1441 16.8893C53.7352 14.4 61.0064 14.3862 67.41 14.3587C70.2055 14.345 72.8771 14.345 75.3284 14.1387Z"
          fill="#00923A"
        />
        <path
          d="M79.253 17.2738C73.6068 29.5692 61.0062 30.807 50.2096 30.807C47.8409 30.807 45.5687 30.752 43.4617 30.7382L40.735 41.3557C54.6714 30.0231 73.0973 41.2595 71.128 58.3685C69.806 69.8938 61.5708 77.4306 52.8674 79.5898C43.3791 81.9554 32.8854 78.4208 28.1757 68.766C27.6799 67.7483 26.8123 65.699 26.4681 64.6125L36.4659 62.0544C36.645 62.632 36.9342 63.1822 37.2509 63.691C43.9437 74.4736 57.5083 69.0823 59.0369 57.9009C60.5931 46.527 44.6047 40.4618 37.3198 53.7612L28.4511 49.9928L28.2996 49.924L32.5825 32.7737C32.8441 31.5497 33.7392 28.1526 33.8907 27.5887C38.7244 9.84701 54.3685 9.55819 66.914 9.51693C61.7223 7.35767 56.0348 6.16113 50.0719 6.16113C25.8346 6.16113 6.18311 25.787 6.18311 49.9928C6.18311 74.1985 25.8346 93.8245 50.0719 93.8245C74.3092 93.8245 93.9606 74.1985 93.9606 49.9928C93.9606 36.9822 88.2731 25.3057 79.253 17.2738Z"
          fill="#E52322"
        />
      </svg>
    )
  }

  return (
    <svg fill="none" viewBox="0 0 426 100" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M112.129 71.1639V30.8604H144.809V71.1639H136.835V38.5894H120.102V71.1639H112.129Z"
        fill={getPathColor(color)}
      />
      <path
        d="M175.552 41.8211V71.1915H168.168V60.9891C166.736 61.2421 165.22 61.5794 163.395 61.6356L157.274 71.1634H148.964L156.264 60.5957C152.445 58.9655 150.424 56.1831 150.424 51.8267C150.424 45.8402 154.804 41.793 163.423 41.793H175.552V41.8211ZM168.168 47.9481H163.647C159.885 47.9481 157.976 49.2972 157.976 51.9391C157.976 54.5248 159.801 55.8177 163.479 55.8177C165.107 55.8177 166.652 55.7053 168.168 55.4523V47.9481Z"
        fill={getPathColor(color)}
      />
      <path
        d="M225.498 44.2946C227.772 46.515 228.895 49.5223 228.895 53.4571V71.1918H221.286V54.8624C221.286 50.3935 218.9 48.0327 215.418 48.0327C211.6 48.0327 208.961 50.787 208.961 55.0872V71.1918H201.353V54.9186C201.353 50.5622 199.022 48.0327 195.429 48.0327C191.695 48.0327 188.999 50.787 188.999 55.1434V71.1918H181.391V41.8213H188.831V46.0372C190.515 42.9737 193.941 40.9781 197.787 41.0344C201.465 41.0344 204.862 42.805 206.406 46.2058C208.568 42.9737 212.555 41.0344 216.935 41.0344C220.416 41.0063 223.28 42.0743 225.498 44.2946Z"
        fill={getPathColor(color)}
      />
      <path
        d="M294.255 45.5313C297.006 48.4824 298.354 52.1642 298.354 56.4644C298.354 60.7646 296.95 64.4464 294.199 67.3975C291.447 70.3486 287.994 71.8663 283.839 71.8663C280.217 71.8663 276.904 70.2924 274.63 67.5099V83.2491H267.022V41.8214H274.574V45.6718C276.988 42.6645 280.189 41.0625 283.895 41.0625C288.05 41.0625 291.504 42.5521 294.255 45.5313ZM288.443 62.5071C289.959 60.877 290.717 58.9096 290.717 56.5206C290.717 54.1597 289.959 52.1642 288.443 50.5341C286.927 48.9321 284.99 48.117 282.576 48.117C280.189 48.117 278.252 48.9321 276.764 50.5341C275.248 52.1642 274.49 54.1316 274.49 56.5206C274.49 58.8815 275.248 60.877 276.764 62.5071C278.28 64.1373 280.217 64.9242 282.576 64.9242C284.99 64.9242 286.927 64.1092 288.443 62.5071Z"
        fill={getPathColor(color)}
      />
      <path
        d="M305.541 45.5309C308.517 42.5236 312.279 41.0059 316.856 41.0059C321.432 41.0059 325.222 42.5236 328.17 45.5309C331.146 48.482 332.606 52.1638 332.606 56.464C332.606 60.8204 331.146 64.5022 328.17 67.4533C325.194 70.4044 321.432 71.9221 316.856 71.9221C312.279 71.9221 308.489 70.4044 305.541 67.4533C302.565 64.5022 301.105 60.8204 301.105 56.464C301.105 52.1638 302.565 48.482 305.541 45.5309ZM316.856 64.8676C319.186 64.8676 321.067 64.0525 322.639 62.4505C324.212 60.7923 325.026 58.7967 325.026 56.464C325.026 54.1593 324.212 52.1638 322.639 50.5337C321.067 48.9317 319.186 48.1166 316.856 48.1166C314.526 48.1166 312.588 48.9317 311.044 50.5337C309.472 52.1638 308.714 54.1312 308.714 56.464C308.714 58.7686 309.472 60.7641 311.044 62.4505C312.588 64.0525 314.526 64.8676 316.856 64.8676Z"
        fill={getPathColor(color)}
      />
      <path
        d="M354 41.8213H361.608V71.1917H354V61.2985C351.782 62.5633 349.367 63.2097 347.486 63.2097C343.977 63.2097 341.394 62.5633 339.064 60.9894C336.733 59.3311 335.442 56.1833 335.442 51.6583V41.8213H342.994V51.1524C342.994 54.6094 344.763 56.3238 348.329 56.3238C350.322 56.3238 352.035 56.0428 354 55.2558V41.8213Z"
        fill={getPathColor(color)}
      />
      <path
        d="M386.736 71.1632L375.056 57.6163V71.1632H367.448V41.7646H375.056V53.4285L385.725 41.8209H395.327L382.216 55.3959L396.085 71.1632H386.736Z"
        fill={getPathColor(color)}
      />
      <path
        d="M418.377 41.8216H425.593V71.192H418.377V67.3134C415.85 70.3769 412.706 71.8946 409 71.8946C404.789 71.8946 401.279 70.4331 398.528 67.482C395.776 64.531 394.429 60.7929 394.429 56.3803C394.429 51.9677 395.833 48.2859 398.584 45.4472C401.335 42.5242 404.845 41.0908 409 41.0908C412.678 41.0908 415.907 42.7491 418.377 45.7001V41.8216ZM416.103 50.4781C414.531 48.8199 412.594 48.0048 410.235 48.0048C407.821 48.0048 405.856 48.8199 404.311 50.4781C402.795 52.1363 402.037 54.1318 402.037 56.4646C402.037 58.8255 402.795 60.821 404.368 62.5073C405.94 64.1656 407.877 64.9806 410.235 64.9806C412.622 64.9806 414.559 64.1656 416.103 62.5073C417.675 60.8491 418.49 58.8536 418.49 56.4646C418.49 54.16 417.675 52.1645 416.103 50.4781Z"
        fill={getPathColor(color)}
      />
      <path
        d="M262.586 59.1622H240.518C241.585 63.3218 244.449 65.4017 249.081 65.4017C252.254 65.4017 255.819 64.2212 258.487 62.0852L261.547 66.8913C257.841 70.3483 253.601 71.8097 248.744 71.8097C243.578 71.8097 239.62 70.292 236.868 67.341C234.117 64.3899 232.769 60.708 232.769 56.464C232.769 51.9952 234.173 48.3415 237.037 45.4185C239.901 42.4955 243.55 41.0621 248.099 41.0621C252.366 41.0621 255.932 42.4112 258.655 45.0531C261.406 47.6951 262.81 51.2364 262.81 55.8176C262.866 56.8294 262.754 57.9818 262.586 59.1622ZM242.764 30.0166C245.291 30.0166 247.284 32.0121 247.284 34.4292C247.284 37.0711 245.347 39.0104 242.764 39.0104C240.181 39.0104 238.244 37.0711 238.244 34.4292C238.216 32.0121 240.209 30.0166 242.764 30.0166ZM240.546 53.513H255.483C254.781 49.6906 252.029 47.3578 248.155 47.3578C244.308 47.3578 241.445 49.6625 240.546 53.513ZM249.924 34.4292C249.924 31.8997 251.917 30.0166 254.5 30.0166C257.027 30.0166 258.964 32.0121 258.964 34.4292C258.964 37.0711 257.027 39.0104 254.5 39.0104C251.917 39.0104 249.924 37.0711 249.924 34.4292Z"
        fill={getPathColor(color)}
      />
      <path
        d="M49.9465 0C22.4043 0 0 22.4283 0 50C0 77.5717 22.4043 100 49.9465 100C77.4887 100 99.893 77.5717 99.893 50C99.893 22.4283 77.4887 0 49.9465 0ZM69.094 12.9567C70.7505 12.9567 72.1262 12.9005 73.3335 12.7881C71.6208 18.072 68.6729 21.3884 64.0966 23.1872C59.3237 25.0422 53.5121 25.0984 47.925 25.1546C44.921 25.1827 42.0292 25.2108 39.362 25.52C44.1348 13.9966 52.6698 12.8724 61.7944 12.8724C63.0578 12.8724 64.3212 12.9005 65.5565 12.9286C66.7638 12.9286 67.9429 12.9567 69.094 12.9567ZM49.9465 93.873C25.7453 93.873 6.12048 74.2271 6.12048 50C6.12048 25.7729 25.7453 6.12704 49.9465 6.12704C54.4947 6.12704 58.8745 6.82968 62.9736 8.09443C62.5805 8.09443 62.1874 8.09443 61.7663 8.09443C51.6591 8.09443 40.2885 9.61214 34.533 24.7611C34.2242 25.6043 34.1399 25.9134 33.8592 26.8409C33.6626 27.4311 32.0904 33.6706 31.557 36.0596L28.216 49.4941L28.3564 49.5503L37.3405 53.3727C44.7244 39.882 60.896 46.0371 59.3237 57.5885C57.7796 68.9432 44.0506 74.3957 37.2844 63.4626C36.9756 62.9567 36.6667 62.3946 36.4983 61.8044L26.363 64.4182C26.6999 65.5424 27.5702 67.5942 28.0756 68.6341C32.8484 78.4148 43.461 82.0124 53.0629 79.6234C61.8786 77.4311 70.189 69.7864 71.5366 58.0944C73.53 40.7251 54.8878 29.3423 40.7938 40.8375L43.5733 30.0169C55.0001 29.6234 71.1997 31.6751 77.2921 15.7954C87.3151 23.8336 93.7444 36.172 93.7444 50.0562C93.7725 74.2552 74.1477 93.873 49.9465 93.873Z"
        fill={getPathColor(color)}
      />
    </svg>
  )
}
export default Logo
