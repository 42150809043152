import {restyled, PREFIX} from '@x5-react-uikit/core'
import ButtonBase from '../controls/ButtonBase'
import type {CSSProperties} from 'react'
import type {CSSObject} from '@emotion/styled'

export const classes = {
  start: `${PREFIX}-head-cell-start`,
  end: `${PREFIX}-head-cell-end`,
  selected: `${PREFIX}-head-cell-selected`,
  text: `${PREFIX}-head-cell-text`,
}

export const StyledHeadCell = restyled.th<{disabled?: boolean}>(({theme, disabled, style}) => {
  return {
    '&.no-side-borders': {
      '&:first-of-type': {
        borderLeft: 0,
      },
      '&:last-of-type': {
        borderRight: 0,
      },
    },
    ...(disabled
      ? {
          pointerEvents: 'none',
          color: theme.colors.grey[40],
          ...style,
        }
      : {}),
  }
})

export const StyledHeadCellWrap = restyled.div<CSSProperties>(
  ({theme: {spaces, colors}, width}) => ({
    width,
    display: 'flex',
    alignItems: 'center',
    gap: spaces.x2,
    [`.${classes.selected} path`]: {
      fill: colors.accent[90],
    },
    [`.${classes.start}`]: {
      marginRight: spaces.x2,
    },
    [`.${classes.end}`]: {
      marginLeft: spaces.x2,
    },
  })
)

export const StyledHeadCellAction = restyled(ButtonBase)<{paddingX: number | string}>(({
  paddingX,
}) => {
  const styles: CSSObject = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    cursor: 'pointer',
  }
  if (paddingX) {
    styles.paddingLeft = styles.paddingRight = paddingX
  }
  return {'&&': styles}
})
