import React, {FC, ReactElement} from 'react'
import clsx from 'clsx'

import {Button} from '@x5-react-uikit/core'
import {
  Close as CloseIcon,
  Done as DoneIcon,
  Warning as WarningIcon,
  Error as ErrorIcon,
} from '@x5-react-uikit/icons'
import {sizeTokenValues, typeTokenValues} from '@x5-react-uikit/tokens'

import {InnerButton, innerButtonSizes} from '../InnerButton'
import {getQaAttribute} from '../utils'
import {SnackbarContentProps} from './types'
import {useSnackbarContentStyles} from './styles'

const UserAction: FC<
  Pick<SnackbarContentProps, 'type' | 'action'> & {
    className?: string
    getQA: ReturnType<typeof getQaAttribute>
  }
> = ({action: actionProp, className, type, getQA}) => {
  const classes = useSnackbarContentStyles({type})

  if (!actionProp) {
    return null
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {nextLine, ...action} = actionProp

  return (
    <Button
      {...action}
      className={clsx(classes.userAction, className, action.className)}
      mode="dark"
      qa={getQA('user-action')}
      size={sizeTokenValues.small}
      variant="text"
    >
      {action.text}
    </Button>
  )
}

const Actions: FC<
  Pick<SnackbarContentProps, 'type' | 'action' | 'closable' | 'close'> & {
    isShowUserAction: boolean
    getQA: ReturnType<typeof getQaAttribute>
  }
> = ({type, action, isShowUserAction, closable, close, getQA}) => {
  const classes = useSnackbarContentStyles({type})

  return (
    <div className={clsx(classes.actions, isShowUserAction && classes.actionsWithUserAction)}>
      {isShowUserAction && <UserAction action={action} getQA={getQA} type={type} />}
      {closable && (
        <InnerButton
          aria-label="Close"
          className={classes.close}
          IconComponent={<CloseIcon size={sizeTokenValues.small} />}
          mode="dark"
          qa={getQA('close')}
          size={innerButtonSizes.xsmall}
          variant="text"
          onClick={close}
        />
      )}
    </div>
  )
}

const Icon: FC<Pick<SnackbarContentProps, 'icon' | 'type'>> = ({icon, type}) => {
  if (!icon) {
    return null
  }

  if (typeof icon === 'object' && type === typeTokenValues.default) {
    return icon as ReactElement
  } else if (type === typeTokenValues.success) {
    return <DoneIcon />
  } else if (type === typeTokenValues.warning) {
    return <WarningIcon />
  } else if (type === typeTokenValues.error) {
    return <ErrorIcon />
  }

  return null
}

export const SnackbarContent: FC<SnackbarContentProps> = ({
  type = typeTokenValues.default,
  action,
  closable,
  maxWidth,
  title,
  message,
  icon,
  close,
  qa = 'snackbar',
}) => {
  const getQA = getQaAttribute(qa)
  const isShowUserActionInActions = title ? false : !action?.nextLine
  const isShowIcon = icon && (type !== typeTokenValues.default || typeof icon === 'object')
  const classes = useSnackbarContentStyles({
    type,
    maxWidth,
    title,
    action,
  })

  return (
    <div className={classes.root} data-qa={getQA()}>
      <div
        className={clsx(classes.wrapper, isShowUserActionInActions && classes.wrapperSmallPadding)}
        data-qa={getQA('wrapper')}
      >
        {isShowIcon && (
          <div className={classes.left} data-qa={getQA('left')}>
            <Icon icon={icon} type={type} />
          </div>
        )}
        <div className={classes.right} data-qa={getQA('right')}>
          <div className={classes.text}>
            {title && (
              <div className={clsx(classes.title, classes.alignActions)} data-qa={getQA('title')}>
                {title}
                <Actions
                  action={action}
                  closable={closable}
                  close={close}
                  getQA={getQA}
                  isShowUserAction={isShowUserActionInActions}
                  type={type}
                />
              </div>
            )}
            <div className={clsx(classes.alignActions, classes.message)} data-qa={getQA('message')}>
              {message}
              {!title && (
                <Actions
                  action={action}
                  closable={closable}
                  close={close}
                  getQA={getQA}
                  isShowUserAction={isShowUserActionInActions}
                  type={type}
                />
              )}
            </div>
          </div>
          {!isShowUserActionInActions && (
            <UserAction
              action={action}
              className={classes.userActionNextLine}
              getQA={getQA}
              type={type}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default SnackbarContent
