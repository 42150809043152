import React, {
  ChangeEvent,
  CSSProperties,
  FC,
  MouseEventHandler,
  useEffect,
  useRef,
  MouseEvent,
} from 'react'
import clsx from 'clsx'
import {TableBodyActiveCellOnCancel, TableBodyCellInnerProps, TableBodyCellProps} from '../types'
import {defaultCellDensity, getBlockedPositionStyle} from '../helpers'
import {useBodyCellStyles} from './styles'
import {createFakeEvent} from '../../utils/fakeEvent'
import {getInnerComponentByType} from './getInnerComponentByType'
import {BodyCellRegistry} from './BodyCellRegistry'

const TableBodyCellBase = (props: TableBodyCellProps, forwardRootRef) => {
  const {
    density = defaultCellDensity,
    noWrap,
    type,
    InnerComponent,
    InnerOption,
    children,
    blocked,
    blockedPosition,
    align,
    qa,
    selectable,
    selected,
    selectedSecondary,
    editable,
    editing,
    disabled,
    rowId,
    colId,
    onSelectCell,
    onEditCell,
    className,
    ...otherProps
  } = props

  const classes = useBodyCellStyles()
  const rootRef = useRef()

  const rootStyles: CSSProperties = React.useMemo(
    () => ({
      // width,
      ...(align ? {align} : {}),
      ...getBlockedPositionStyle(blocked ? blockedPosition : undefined),
    }),
    [align, blocked, blockedPosition]
  )

  const onDoubleClick = React.useCallback(
    (e) => {
      if (!editable) {
        return
      }

      if (!selected) {
        onSelectCell &&
          onSelectCell({
            rowId,
            colId,
          })
      }
      if (!editing) {
        onEditCell && onEditCell(rowId, colId, e.currentTarget)
      }
    },
    [editable, selectable, selected, editing, rowId, colId]
  )

  const onClick = React.useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const isMeta = event.metaKey || event.ctrlKey

      if (!editable) {
        // TODO use selectable property too
        return
      }

      onSelectCell &&
        onSelectCell({
          rowId,
          colId,
          isAdding: isMeta,
        })
    },
    [editable, selectable, selected, editing, rowId, colId]
  )

  const {onChange, onCancel, ...otherInnerOption} = InnerOption || {}

  const _onChange = React.useCallback(
    (e, a?, b?, c?, d?) => {
      e.stopPropagation && e.stopPropagation()

      onEditCell && onEditCell('', '', null)
      onChange && onChange(e, a, b, c, d) // ...arguments);
    },
    [onChange]
  )

  // make common handler - addEventListener
  const onKeyDown = React.useCallback(
    (e) => {
      if (!selected || !editable) {
        // nothing
      } else {
        if (e.ctrlKey || e.altKey || e.metaKey) {
          return
        }

        const isCancelKey = ['Esc', 'Escape'].indexOf(e.key) !== -1
        const isEnableEditing = ['Enter', ' '].indexOf(e.key) !== -1
        const isDeleteKey = ['Delete', 'Backspace'].indexOf(e.key) !== -1
        const isStartInput = /^[\w\dёя]$/i.test(e.key)
        if (!editing) {
          if (isDeleteKey) {
            const fakeEventObject = createFakeEvent(null) as ChangeEvent<HTMLInputElement>
            _onChange(fakeEventObject)
          } else if (isCancelKey) {
            onSelectCell &&
              onSelectCell({
                rowId: '',
                colId: '',
              })
          } else if (isEnableEditing || isStartInput) {
            if (onEditCell) {
              BodyCellRegistry.startEditingFromOnlySelect = true
              onEditCell(rowId, colId, e.currentTarget)
            }
          }
        } else if (isCancelKey) {
          onEditCell && onEditCell('', '', null)
        }
      }
    },
    [editable, selected, editing, rowId, colId]
  )

  const _onCancel: TableBodyActiveCellOnCancel = React.useCallback(() => {
    onEditCell && onEditCell('', '', null)
    onCancel && onCancel()
  }, [onChange])

  const onStartEdit: MouseEventHandler<HTMLElement> = React.useCallback(
    (e) => {
      onEditCell && onEditCell(rowId, colId, e.currentTarget)
    },
    [rowId, colId]
  )

  const Component: FC<any> = InnerComponent || getInnerComponentByType(type)

  const roles = ['table-cell', 'table-body-cell']
  if (editable) {
    roles.push('table-editable-cell')
  }
  // console.log(props)

  const mainRef = forwardRootRef || rootRef

  const markAsSelected = editable && (editing || selected)

  return (
    <div
      ref={mainRef}
      className={clsx(
        classes.root,
        editable && classes.editable,
        blocked && classes.blocked,
        (selectable || editable) && classes.interactive,
        markAsSelected && (selectedSecondary ? classes.selectedSecondary : classes.selected),
        editing && classes.editing,
        disabled && classes.disabled,
        className
      )}
      data-col={colId}
      data-qa={qa}
      data-row={rowId}
      data-type={roles.join(' ')}
      style={rootStyles}
      onClick={onClick}
      {...otherProps}
      tabIndex={0}
      onDoubleClick={onDoubleClick}
      onKeyDown={onKeyDown}
    >
      <>
        <Component
          colId={colId}
          density={density}
          editable={editable}
          editing={editing}
          noWrap={noWrap}
          rootRef={mainRef}
          rowId={rowId}
          selectable={selectable}
          selected={selected}
          {...otherInnerOption}
          onCancel={_onCancel}
          onChange={_onChange}
          onStartEdit={onStartEdit}
        >
          {children}
        </Component>
        {/* <div className={classes.widthSlider} /> */}
      </>
    </div>
  )
}

TableBodyCellBase.displayName = 'TableBodyCellBase'

export const TableBodyCell = React.forwardRef(TableBodyCellBase)
// const TableBodyCell = React.memo(TableBodyCellBase);
// const TableBodyCell = React.memo(TableBodyCellBase, isEqual);

// const memoCompare = (p: Props, n: Props) => {
//   const {InnerOption: p_InnerOption, ...p_o} = p;
//   const {InnerOption: n_InnerOption, ...n_o} = n;
//   const mainIsEqual = shallowEqual(p_o, n_o);
//
//   if(!mainIsEqual) {
//     console.log('TableBodyCellBase mainIsNOTEqual', p_o, n_o);
//     return false
//   } else {
//     const r = false
//
//     console.log('mainIsEqual', r);
//
//     return r
//   }
// }
// const TableBodyCell = React.memo(TableBodyCellBase, memoCompare);

// const compare = () => false
// const TableBodyCell = React.memo(TableBodyCellBase, compare);

export default TableBodyCell
