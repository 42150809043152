import {useCallback} from 'react'
import {
  useCreateSystemMetaDataMutation,
  useGetSystemMetaDataQuery,
  useUpdateSystemMetaDataMutation,
} from '@root/redux/api/systemMetaDataApi'

const useCreateOrUpdateMetadata = (systemId) => {
  const {error: metadataError, isError: isMetadataError} = useGetSystemMetaDataQuery(
    {systemId},
    {skip: systemId == null}
  )

  const [createMetadata] = useCreateSystemMetaDataMutation()
  const [updateMetadata] = useUpdateSystemMetaDataMutation()

  const createOrUpdateMetadata = useCallback(
    (fields) => {
      // TODO: проверять код ошибки. Если 404, то createMetadata
      const mutation =
        isMetadataError && metadataError
          ? createMetadata({systemId, createData: {...fields, systemId}})
          : updateMetadata({systemId, updateData: {...fields}})

      return mutation.unwrap()
    },
    [systemId, createMetadata, updateMetadata, metadataError, isMetadataError]
  )

  return createOrUpdateMetadata
}

export default useCreateOrUpdateMetadata
