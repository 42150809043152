import React, {FC} from 'react'
import {defineColors} from '../utils/defineColors'
import {LogoProps} from '../types'

const primary = '#5faf2d'
const getPathColor = defineColors('#000000')

const Logo: FC<LogoProps> = ({color, ...props}) => (
  <svg fill="none" viewBox="0 0 395 100" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M85.9875 90.9305L75.8501 74.0711L65.922 90.9305H54.0372L69.3069 65.536L53.743 41.9609H66.1595L76.1286 57.7013L85.6367 41.9609H97.5216L82.2125 66.3056L97.9431 90.9305H85.9875Z"
      fill={getPathColor(color)}
    />
    <path
      d="M136.578 74.284C136.578 85.0572 128.468 91.7729 117.842 91.7729C108.474 91.7729 100.587 86.5964 99.8179 75.3322H109.955C110.654 80.2285 114.57 82.3281 118.834 82.3281C124.147 82.3281 126.441 78.7601 126.441 74.1423C126.441 69.3153 122.875 66.3076 117.911 66.3076C113.787 66.3076 111.059 68.2671 109.801 70.365H100.11L103.459 41.9629H135.329L134.266 51.477H111.468L110.562 60.8761C110.562 60.8761 112.956 58.0526 120.214 58.0526C128.957 58.0542 136.578 63.9279 136.578 74.284Z"
      fill={getPathColor(color)}
    />
    <path
      d="M101.015 35.8639C95.6134 14.7991 78.4814 0 42.926 0H0L5.8276 39.4287C10.2113 75.0062 23.809 86.4498 47.8744 90.6662C47.986 90.6835 48.7489 90.7921 48.7583 89.8997C48.7615 89.5787 48.5586 89.3017 48.2802 89.182C32.0148 82.3294 30.1588 59.856 26.6842 37.3056L23.9962 19.3335H48.5004C71.0935 19.3335 88.537 18.9762 99.5803 36.4273C99.7439 36.6729 100 36.8539 100.304 36.8539C100.73 36.8539 101.048 36.5076 101.048 36.0811C101.046 36.004 101.037 35.93 101.015 35.8639Z"
      fill={getPathColor(color, primary)}
    />
    <path
      d="M212.253 91.2559L212.183 41.9648H206.413L186.145 76.6094L165.597 41.9648H159.826V91.2559H166.582V55.5551L184.386 85.2706H187.623L205.427 55.3438L205.498 91.2559H212.253Z"
      fill={getPathColor(color)}
    />
    <path
      d="M260.169 72.807C260.169 61.3292 252.499 53.5835 241.732 53.5835C230.965 53.5835 223.013 61.5404 223.013 72.5957C223.013 83.7214 231.035 91.6784 243.069 91.6784C249.261 91.6784 254.399 89.5659 257.777 85.6226L254.047 81.2569C251.302 84.2847 247.643 85.7635 243.28 85.7635C235.891 85.7635 230.613 81.3977 229.768 74.849H260.028C260.099 74.2153 260.169 73.3703 260.169 72.807ZM241.732 59.2871C248.276 59.2871 252.991 63.7233 253.695 69.9903H229.768C230.472 63.6529 235.257 59.2871 241.732 59.2871Z"
      fill={getPathColor(color)}
    />
    <path
      d="M302.262 85.341V53.9355H273.339L272.776 66.54C272.283 75.9757 271.368 85.0593 266.02 85.341H264.05V99.917H270.453V91.2559H301.628V99.917H307.962V85.341H302.262ZM278.968 67.0329L279.32 59.8505H295.506V85.341H274.605C277.983 82.3835 278.687 74.7786 278.968 67.0329Z"
      fill={getPathColor(color)}
    />
    <path
      d="M316.763 91.2559H322.955L345.756 64.0754V91.2559H352.512V53.9355H346.389L323.518 81.116V53.9355H316.763V91.2559Z"
      fill={getPathColor(color)}
    />
    <path
      d="M378.925 53.5835C372.943 53.5835 367.384 55.2734 363.513 58.3717L366.328 63.4417C369.214 60.9771 373.717 59.428 378.151 59.428C384.836 59.428 388.144 62.7375 388.144 68.4412V69.7791H377.447C366.328 69.7791 362.458 74.7082 362.458 80.6935C362.458 87.1718 367.806 91.6784 376.251 91.6784C382.092 91.6784 386.244 89.7067 388.496 86.3268V91.2559H394.899V68.7228C394.899 58.5126 389.129 53.5835 378.925 53.5835ZM377.377 86.4676C372.24 86.4676 369.143 84.1439 369.143 80.4119C369.143 77.2432 371.043 74.6378 377.729 74.6378H388.144V79.8485C386.455 84.1439 382.444 86.4676 377.377 86.4676Z"
      fill={getPathColor(color)}
    />
  </svg>
)

export default Logo
