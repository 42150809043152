import {ReactNode, MouseEvent as ReactMouseEvent, HTMLAttributes} from 'react'
import {QA} from '../../types'

export const dataGridExpanderValues = {
  close: 'close',
  open: 'open',
  none: 'none',
} as const

type DataGridExpanderUnion = keyof typeof dataGridExpanderValues

type DataGridGroupingProps = {
  expander?: DataGridExpanderUnion
  depth?: number
  onExpanderToggle?: (
    event: ReactMouseEvent<HTMLButtonElement>,
    level: DataGridGroupingProps['depth'],
    expander: DataGridExpanderUnion
  ) => void
}

export interface DataGridRowProps
  extends DataGridGroupingProps,
    QA,
    HTMLAttributes<HTMLTableRowElement> {
  selected?: boolean
  children: ReactNode
}
