import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import useNotify from '@root/hooks/useNotify'
import {useUpdateSystemMutation} from '@root/redux/api/systemsApi'
import {invalidateTasksAction, useGetTaskByIdQuery} from '@root/redux/api/tasksApi'
import {Box, Typography} from '@x5-react-uikit/core'
import {ChevronDown, ChevronUp, Done} from '@x5-react-uikit/icons'
import {FC, useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'
import {Button, Textarea} from 'ui-kit'
import {UseTaskPageForm} from '../hooks/useTaskPageForm'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'

type Props = {
  form: UseTaskPageForm['form']
  readonly?: boolean
}

const SystemField: FC<Props> = ({readonly, form}) => {
  const {taskId} = useParams()
  const {data: task} = useGetTaskByIdQuery(taskId)
  const {notifyError, notifySuccess} = useNotify()
  const [editing, setEditing] = useState(false)
  const [expanded, setExpanded] = useState(false)

  // const [systemDesc, setSystemDesc] = useState(task?.system.description)
  useEffect(
    () => task && form.setValue('system.description', task.system.description),
    [task]
  )

  const handleSetEditing = () => {
    if (!expanded) setExpanded(true)
    setEditing(true)
  }

  const handleCancelEditing = () => {
    form.setValue('system.description', task.system.description)
    setEditing(false)
  }

  // const form = useForm()
  const [updateSystem, {isLoading: isUpdating}] = useUpdateSystemMutation()
  const dispatch = useDispatch()
  const handleSave = form.handleSubmit(() => {
    updateSystem({
      systemId: task.system.id,
      updateData: {description: form.getValues('system.description')},
    })
      .unwrap()
      .then(() => {
        dispatch(invalidateTasksAction)
        handleCancelEditing()
        notifySuccess('Изменения сохранены')
      })
      .catch(() => notifyError())
  })

  return (
    <>
      <FlexboxRow
        sx={{
          py: 'x16',
          // px: 'x12',
          alignItems: 'center',
          backgroundColor: 'white',
        }}
      >
        <FlexboxRow sx={{alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
          <FlexboxColumn>
            <FlexboxRow
              sx={{cursor: 'pointer', userSelect: 'none'} as any}
              onClick={() => setExpanded((prev) => !prev)}
            >
              {expanded ? <ChevronUp /> : <ChevronDown />}
              <Typography variant="h3">Выбранная ИС</Typography>
            </FlexboxRow>
          </FlexboxColumn>
          {!readonly &&
            (!editing ? (
              <Button variant="text" onClick={handleSetEditing}>
                Редактировать пункт
              </Button>
            ) : (
              <FlexboxRow sx={{gap: '16px'}}>
                <Button size="medium" variant="outlined" onClick={handleCancelEditing}>
                  Отмена
                </Button>
                <Button
                  size="medium"
                  startIcon={<Done size="small" />}
                  style={{textWrap: 'nowrap'}}
                  onClick={handleSave}
                  loading={isUpdating}
                >
                  Сохранить изменения
                </Button>
              </FlexboxRow>
            ))}
        </FlexboxRow>
      </FlexboxRow>
      {expanded && (
        <Box sx={{mb: 'x16'}}>
          <FlexboxColumn sx={{gap: '24px'}}>
            <FlexboxRow sx={{gap: '80px'}}>
              <FlexboxColumn sx={{width: '560px', gap: '2px'}}>
                <Typography variant="p1">Выбранная ИС</Typography>
                <Typography variant="p1">{task.system.name || '-'}</Typography>
              </FlexboxColumn>
              <FlexboxColumn sx={{width: '560px', gap: '2px'}}>
                <Typography variant="p1">Класс критичности</Typography>
                <Typography variant="p1">{task.system.criticalityClass || '-'}</Typography>
              </FlexboxColumn>
            </FlexboxRow>
            <FlexboxRow sx={{gap: '80px'}}>
              {editing ? (
                <FormInputText
                  textarea
                  control={form.control}
                  name="system.description"
                  label="Описание ИС"
                  width="560px"
                />
              ) : (
                <FlexboxColumn sx={{width: '560px', gap: '2px'}}>
                  <Typography variant="p1">Описание ИС</Typography>
                  <Typography variant="p1">{task.system.description || '-'}</Typography>
                </FlexboxColumn>
              )}
              <FlexboxColumn sx={{width: '560px', gap: '2px'}}>
                <Typography variant="p1">Тег</Typography>
                <Typography variant="p1">{task.system.tag || '-'}</Typography>
              </FlexboxColumn>
            </FlexboxRow>
          </FlexboxColumn>
        </Box>
      )}
    </>
  )
}

export default SystemField
