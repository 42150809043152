import {Box, Tab, TabList, Tabs, Typography} from '@root/components/kit'
import Breadcrumbs from '@root/components/Breadcrumbs'
import {ReactNode, useEffect, useState} from 'react'
import {ReportsTable} from './components/ReportsTab/ReportsTable'
import ConfigurationsTable from './components/ConfigurationsTab/ConfigurationsTable'
import {useSearchParams} from 'react-router-dom'
import {useGetUserinfoQuery} from '@root/redux/api/userApi'
import {isEmpty} from 'lodash'

const TopTabs = {
  reports: 'reports',
  configurations: 'configurations',
}

const TopTabLabels = {
  reports: 'Отчеты',
  configurations: 'Конфигурации отчетов',
}

const ReportListPage = () => {
  const {data: userInfo} = useGetUserinfoQuery()

  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedTab, setSelectedTab] = useState(
    TopTabs[searchParams.get('tab')] || TopTabs.reports
  )

  useEffect(() => {
    if (!userInfo) {
      return
    }

    const tabQuery = searchParams.get('tab')

    if (userInfo.isBusiness) {
      setSelectedTab(TopTabs.reports)

      if (!isEmpty(tabQuery)) {
        searchParams.delete('tab')
        setSearchParams(searchParams)
      }
    }
  }, [searchParams, setSearchParams, userInfo])

  if (userInfo == null) {
    return <></>
  }

  let currentTabContent: ReactNode
  if (selectedTab === TopTabs.reports) currentTabContent = <ReportsTable />
  else if (selectedTab === TopTabs.configurations) currentTabContent = <ConfigurationsTable />

  return (
    <Box sx={{mx: 'x8'}}>
      <Breadcrumbs
        routes={[
          {to: '/', label: 'Главная'},
          {to: null, label: 'Отчеты портала'},
        ]}
      />
      <Typography variant="h2">Отчёты портала</Typography>
      <Box sx={{my: 'x10'}}>
        {!userInfo.isBusiness && (
          <Tabs value={selectedTab} onChange={(e, newTab) => setSelectedTab(newTab)}>
            <TabList>
              {Object.keys(TopTabs).map((t) => (
                <Tab key={t} label={TopTabLabels[t]} value={t} />
              ))}
            </TabList>
          </Tabs>
        )}
      </Box>
      {currentTabContent}
    </Box>
  )
}

export default ReportListPage
