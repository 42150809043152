import React from 'react'
import {Popper, Paper, ClickAwayListener} from '@material-ui/core'
import {usePrivateDropdownStyles} from './styles'
import {DropDownListProps} from './types'
import {baseTheme} from '../theme'

const getStyles = (anchor): Record<string, number> => {
  const o: Record<string, number> = {zIndex: baseTheme.zIndex.dropdown}

  if (anchor) {
    const rect = anchor.getBoundingClientRect()
    if (rect.width) {
      o.minWidth = rect.width
    }
  }

  return o
}

export const PrivateDropdown = (props: DropDownListProps): JSX.Element => {
  const {
    id,
    open,
    anchor,
    onClickAway,
    children,
    qa,
    autoDirection = true,
    placement = 'bottom-start',
  } = props

  const paperStyles = usePrivateDropdownStyles(0)
  const ref = React.useRef()

  const clickAway = (e): void => onClickAway(e, ref)

  const disabledModifiers = {
    flip: {
      enabled: false,
    },
    preventOverflow: {
      enabled: false,
      boundariesElement: 'scrollParent',
    },
    hide: {
      enabled: false,
    },
  }

  return (
    <Popper
      ref={ref}
      disablePortal
      anchorEl={anchor}
      data-qa={qa}
      modifiers={autoDirection ? undefined : disabledModifiers}
      open={open}
      placement={placement}
      role={undefined}
      style={getStyles(anchor)}
      transition={false}
    >
      <ClickAwayListener onClickAway={clickAway}>
        <Paper className={paperStyles.root}>{children}</Paper>
      </ClickAwayListener>
    </Popper>
  )
}

export default PrivateDropdown
