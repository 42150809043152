export enum LaunchStatusType {
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
  CANCELED = 'CANCELED',
  SKIPPED = 'SKIPPED',
  RUNNING = 'RUNNING',
  MANUAL = 'MANUAL',
  SCHEDULED = 'SCHEDULED',
  WAITING_FOR_RESOURCE = 'WAITING_FOR_RESOURCE',
}

export const LaunchStatusTextMap: Record<LaunchStatusType, string> = {
  [LaunchStatusType.CREATED]: 'Создан',
  [LaunchStatusType.PENDING]: 'В ожидании',
  [LaunchStatusType.FAILED]: 'Неудачный',
  [LaunchStatusType.SUCCESS]: 'Успешный',
  [LaunchStatusType.CANCELED]: 'Отмененный',
  [LaunchStatusType.SKIPPED]: 'Пропущенный',
  [LaunchStatusType.RUNNING]: 'В процессе',
  [LaunchStatusType.MANUAL]: 'В ручном управлении',
  [LaunchStatusType.SCHEDULED]: 'Запланирован',
  [LaunchStatusType.WAITING_FOR_RESOURCE]: 'В ожидании ресурса',
}
