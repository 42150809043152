import {makeUiStyles} from '../theme'

export const useStyles = makeUiStyles(
  (theme) => ({
    root: {
      userSelect: 'none',
      padding: theme.spaces.join('x2', 'x6'),
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
      transition: '100ms color ease, 100ms background-color ease',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
    adornment: {
      marginRight: theme.spaces.x4,
      fontSize: 0,
      lineHeight: 0,
    },
    text: {
      ...theme.typography.p1,
    },
    noWrapText: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    disabled: {
      'body &': {
        cursor: 'default !important',
        opacity: 0.5,
        '&:hover': {
          backgroundColor: 'none',
        },
      },
      'body & *': {
        cursor: 'default',
      },
    },
    unselected: {
      backgroundColor: theme.colors.white,
      color: theme.colors.grey[100],
      '&:hover': {
        backgroundColor: theme.colors.grey[20],
        color: theme.colors.grey[100],
      },
    },
    selected: {
      cursor: 'default',
      color: theme.colors.white,
      backgroundColor: theme.colors.accent[90],
      '&:hover': {
        backgroundColor: theme.colors.accent[70],
        color: theme.colors.white,
      },
    },
    secondary: {
      '$root&': {
        color: theme.colors.grey[40],
        cursor: 'default',
        ...theme.typography.p1compact,
      },
      '$root&:hover': {
        backgroundColor: 'unset',
      },
    },
  }),
  'list-item'
)
