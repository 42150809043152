import {sizeTokenValues} from '@x5-react-uikit/tokens'
import {restyled} from '@x5-react-uikit/core'
import {
  getBorderColorTable,
  getBorderStyleTable,
  getPaddingTableCell,
  getStickedColumnSelector,
} from '../helpers'
import {CSSProperties} from 'react'

export const tableSizeValues = sizeTokenValues

type Params = {
  size: any
  unborder: boolean
  unstable_stickedColumnCount: number
  otherStyles: CSSProperties
}

export const StyledTable = restyled.table<Params>(
  ({
    theme: {spaces, colors, typography},
    size,
    unborder,
    unstable_stickedColumnCount: columnCount,
    otherStyles,
  }) => {
    return {
      tableLayout: 'fixed',
      position: 'relative',
      borderCollapse: 'collapse',
      color: colors.grey[100],
      fontFamily: typography.base.fontFamily,
      fontSize:
        size === sizeTokenValues.large ? typography.p1compact.fontSize : typography.p2.fontSize,
      lineHeight:
        size === sizeTokenValues.large ? typography.p1compact.lineHeight : typography.p2.lineHeight,
      letterSpacing:
        size === sizeTokenValues.large
          ? typography.p1compact.letterSpacing
          : typography.p2.letterSpacing,
      ...getBorderStyleTable(colors),
      '& td:not(.custom), & th:not(.custom)': {
        boxSizing: 'border-box',
        padding: getPaddingTableCell(size, spaces),
        ...getBorderStyleTable(colors, unborder ? '1px 0' : 1),
      },
      '& th:not(.custom)': {
        position: unborder ? 'relative' : null,
        borderBottomWidth: 2,
        fontSize: size === sizeTokenValues.large ? typography.h4.fontSize : typography.h5.fontSize,
        lineHeight:
          size === sizeTokenValues.large ? typography.h4.lineHeight : typography.h5.lineHeight,
        fontWeight: parseInt(typography.h4.fontWeight),
        letterSpacing:
          size === sizeTokenValues.large
            ? typography.h4.letterSpacing
            : typography.h5.letterSpacing,
        '&:hover': {
          borderBottomColor: colors.grey[20],
          backgroundColor: colors.grey[10],
        },
        '&:hover::after': {
          backgroundColor: colors.grey[20],
        },
        '&::after': unborder
          ? {
              position: 'absolute',
              top: spaces.x4,
              left: '100%',
              bottom: spaces.x4,
              content: '""',
              display: 'block',
              marginLeft: -spaces.x1,
              width: spaces.x1,
              height: 'auto',
              backgroundColor: getBorderColorTable(colors),
            }
          : null,
        '&:last-child::after': {
          display: unborder ? 'none' : null,
        },
      },
      '& tr:not(.custom) >': columnCount > 0 ? getStickedColumnSelector(colors, columnCount) : null,
      ...otherStyles,
    }
  }
)
