import React, {FC, ReactElement} from 'react'
import {useBaseCellStyles} from '../Cell/styles'
import {ClickAwayListenerProps, Popover} from '@material-ui/core'

export interface EditableCellPortalProps extends Pick<ClickAwayListenerProps, 'onClickAway'> {
  children: ReactElement
  cellRef: any //Ref<HTMLDivElement>
}

//TODO replace to Popper
export const EditableCellPortal: FC<EditableCellPortalProps> = (props) => {
  const {children, cellRef, onClickAway} = props

  const classes = useBaseCellStyles()

  let anchor = null
  if (cellRef) {
    anchor = cellRef.current || cellRef
  }

  return (
    <Popover
      anchorEl={anchor}
      classes={{paper: classes.innerPortal}}
      disablePortal={false}
      PaperProps={{style: {overflow: 'visible'}}}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      TransitionProps={{timeout: 0}}
      onClose={onClickAway}
      open={!!anchor}
      //modal={true}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {/*<ClickAwayListener onClickAway={onClickAway}>*/}
      {children}
      {/*</ClickAwayListener>*/}
    </Popover>
  )
}

export default EditableCellPortal
