import React, {FC} from 'react'
import {LogoProps} from '../types'
import {palette, getPathColor} from './helpers'

const Logo: FC<LogoProps> = ({color, ...props}) => {
  return (
    <svg fill="none" viewBox="0 0 493 100" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M390.51 67.7383H371.056V73.1843H377.95V89.7063H383.616V73.1843H390.51V67.7383Z"
        fill={getPathColor(color, palette.second)}
      />
      <path
        d="M121.5 78.721C121.5 72.1755 126.422 67.123 133.098 67.123C137.4 67.123 141.138 69.4076 142.938 72.7915L138.063 75.645C137.182 73.8439 135.296 72.7017 133.055 72.7017C129.677 72.7017 127.171 75.2044 127.171 78.721C127.171 82.1948 129.677 84.7018 133.055 84.7018C135.339 84.7018 137.229 83.6023 138.11 81.8012L143.028 84.6162C141.142 88.043 137.404 90.3275 133.102 90.3275C126.422 90.3232 121.5 85.2707 121.5 78.721Z"
        fill={getPathColor(color, palette.second)}
      />
      <path
        d="M166.566 67.7387L158.573 89.7067C156.285 96.034 152.248 98.8062 146.971 98.4939V93.219C149.96 93.2661 151.543 92.2094 152.595 89.4414L143.721 67.7344H149.742L155.627 82.4981L160.502 67.7344H166.566V67.7387Z"
        fill={getPathColor(color, palette.second)}
      />
      <path
        d="M189.321 89.7106H183.655V73.056H174.909V89.7106H169.243V67.7383H189.321V89.7106Z"
        fill={getPathColor(color, palette.second)}
      />
      <path
        d="M204.606 85.1852C206.799 85.1852 208.557 84.2654 209.524 82.9905L214.091 85.6258C212.025 88.6162 208.732 90.3275 204.516 90.3275C197.135 90.3275 192.477 85.275 192.477 78.7253C192.477 72.1798 197.177 67.123 204.075 67.123C210.576 67.123 215.186 72.2653 215.186 78.7253C215.186 79.5595 215.1 80.3081 214.968 81.0568H198.409C199.2 83.9531 201.616 85.1852 204.606 85.1852ZM209.524 76.6589C208.822 73.4974 206.449 72.2225 204.075 72.2225C201.043 72.2225 198.982 73.8482 198.319 76.6589H209.524Z"
        fill={getPathColor(color, palette.second)}
      />
      <path
        d="M241.764 78.7204C241.764 85.2701 236.936 90.3226 231.047 90.3226C227.844 90.3226 225.513 89.1375 223.931 87.114V98.4937H218.265V67.7342H223.931V70.3267C225.513 68.3032 227.844 67.1182 231.047 67.1182C236.932 67.1224 241.764 72.1749 241.764 78.7204ZM236.098 78.7204C236.098 75.0284 233.506 72.5257 229.991 72.5257C226.523 72.5257 223.931 75.0284 223.931 78.7204C223.931 82.4124 226.523 84.9193 229.991 84.9193C233.506 84.9193 236.098 82.4166 236.098 78.7204Z"
        fill={getPathColor(color, palette.second)}
      />
      <path
        d="M267.723 89.7106H262.232V76.8763L256.655 85.6678H255.949L250.326 76.7907V89.7106H244.882V67.7383H250.463L256.304 76.9661L262.146 67.7383H267.723V89.7106Z"
        fill={getPathColor(color, palette.second)}
      />
      <path
        d="M294.383 67.7391V89.7071H288.716V87.1146C287.138 89.0911 284.765 90.3232 281.557 90.3232C275.716 90.3232 270.883 85.2707 270.883 78.721C270.883 72.1755 275.716 67.123 281.557 67.123C284.765 67.123 287.138 68.3551 288.716 70.3316V67.7391H294.383ZM288.716 78.721C288.716 75.029 286.125 72.5263 282.614 72.5263C279.141 72.5263 276.549 75.029 276.549 78.721C276.549 82.413 279.141 84.9199 282.614 84.9199C286.125 84.9199 288.716 82.4172 288.716 78.721Z"
        fill={getPathColor(color, palette.second)}
      />
      <path
        d="M322.141 78.7204C322.141 85.2701 317.313 90.3226 311.424 90.3226C308.221 90.3226 305.891 89.1375 304.308 87.114V98.4937H298.638V67.7342H304.304V70.3267C305.886 68.3032 308.217 67.1182 311.42 67.1182C317.309 67.1224 322.141 72.1749 322.141 78.7204ZM316.475 78.7204C316.475 75.0284 313.883 72.5257 310.368 72.5257C306.896 72.5257 304.304 75.0284 304.304 78.7204C304.304 82.4124 306.896 84.9193 310.368 84.9193C313.883 84.9193 316.475 82.4166 316.475 78.7204Z"
        fill={getPathColor(color, palette.second)}
      />
      <path
        d="M358.671 85.1852C360.865 85.1852 362.623 84.2654 363.589 82.9905L368.157 85.6258C366.091 88.6162 362.798 90.3275 358.582 90.3275C351.2 90.3275 346.543 85.275 346.543 78.7253C346.543 72.1798 351.243 67.123 358.141 67.123C364.641 67.123 369.251 72.2653 369.251 78.7253C369.251 79.5595 369.166 80.3081 369.033 81.0568H352.475C353.266 83.9531 355.682 85.1852 358.671 85.1852ZM363.589 76.6589C362.888 73.4974 360.514 72.2225 358.141 72.2225C355.109 72.2225 353.048 73.8482 352.385 76.6589H363.589Z"
        fill={getPathColor(color, palette.second)}
      />
      <path
        d="M339.641 89.7063H346.231L337.823 78.5918L345.961 67.7383H339.2L332.863 76.6581H330.925V67.7383H325.259V89.7063H330.925V81.0517H333.299L339.641 89.7063Z"
        fill={getPathColor(color, palette.second)}
      />
      <path
        d="M426.428 15.4092H400.278V22.729H409.545V44.9323H417.161V22.729H426.428V15.4092Z"
        fill={getPathColor(color, palette.first)}
      />
      <path
        d="M174.495 38.8487C177.446 38.8487 179.802 37.6081 181.106 35.8969L187.243 39.4391C184.468 43.452 180.046 45.7579 174.375 45.7579C164.458 45.7579 158.201 38.9685 158.201 30.1685C158.201 21.3727 164.518 14.5791 173.785 14.5791C182.517 14.5791 188.718 21.4882 188.718 30.1685C188.718 31.2936 188.603 32.299 188.428 33.3001H166.173C167.237 37.1931 170.483 38.8487 174.495 38.8487ZM181.106 27.392C180.161 23.1396 176.975 21.4283 173.789 21.4283C169.714 21.4283 166.942 23.6102 166.053 27.392H181.106Z"
        fill={getPathColor(color, palette.first)}
      />
      <path
        d="M225.62 30.1685C225.62 38.9685 219.129 45.7579 211.221 45.7579C206.911 45.7579 203.78 44.1664 201.659 41.4498V56.7398H194.043V15.4091H201.659V18.8914C203.78 16.1748 206.911 14.5791 211.221 14.5791C219.129 14.5791 225.62 21.3727 225.62 30.1685ZM218.008 30.1685C218.008 25.2102 214.523 21.8433 209.802 21.8433C205.14 21.8433 201.659 25.2102 201.659 30.1685C201.659 35.1311 205.14 38.4979 209.802 38.4979C214.523 38.4979 218.008 35.1311 218.008 30.1685Z"
        fill={getPathColor(color, palette.first)}
      />
      <path
        d="M245.763 38.8487C248.714 38.8487 251.07 37.6081 252.374 35.8969L258.511 39.4391C255.736 43.452 251.314 45.7579 245.643 45.7579C235.726 45.7579 229.469 38.9685 229.469 30.1685C229.469 21.3727 235.786 14.5791 245.053 14.5791C253.786 14.5791 259.986 21.4882 259.986 30.1685C259.986 31.2936 259.867 32.299 259.696 33.3001H237.441C238.501 37.1931 241.747 38.8487 245.763 38.8487ZM252.374 27.392C251.429 23.1396 248.243 21.4283 245.053 21.4283C240.977 21.4283 238.206 23.6102 237.317 27.392H252.374Z"
        fill={getPathColor(color, palette.first)}
      />
      <path
        d="M330.066 30.1685C330.066 38.9685 323.574 45.7579 315.667 45.7579C311.356 45.7579 308.23 44.1664 306.104 41.4498V56.7398H298.488V15.4091H306.104V18.8914C308.226 16.1748 311.356 14.5791 315.667 14.5791C323.574 14.5791 330.066 21.3727 330.066 30.1685ZM322.454 30.1685C322.454 25.2102 318.968 21.8433 314.247 21.8433C309.585 21.8433 306.104 25.2102 306.104 30.1685C306.104 35.1311 309.585 38.4979 314.247 38.4979C318.968 38.4979 322.454 35.1311 322.454 30.1685Z"
        fill={getPathColor(color, palette.first)}
      />
      <path
        d="M350.208 38.849C353.159 38.849 355.52 37.6083 356.82 35.8971L362.956 39.4394C360.181 43.4522 355.755 45.7581 350.088 45.7581C340.171 45.7581 333.915 38.9688 333.915 30.1687C333.915 21.373 340.231 14.5793 349.498 14.5793C358.231 14.5793 364.428 21.4885 364.428 30.1687C364.428 31.2939 364.312 32.2992 364.137 33.3003H341.886C342.947 37.1934 346.192 38.849 350.208 38.849ZM339.521 8.32047C339.521 6.13864 341.347 4.30762 343.532 4.30762C345.718 4.30762 347.488 6.13864 347.488 8.32047C347.488 10.5066 345.718 12.2777 343.532 12.2777C341.351 12.2777 339.521 10.5066 339.521 8.32047ZM356.815 27.3922C355.87 23.1398 352.684 21.4286 349.498 21.4286C345.423 21.4286 342.651 23.6104 341.762 27.3922H356.815ZM350.738 8.32047C350.738 6.13864 352.569 4.30762 354.75 4.30762C356.935 4.30762 358.706 6.13864 358.706 8.32047C358.706 10.5066 356.935 12.2777 354.75 12.2777C352.564 12.2777 350.738 10.5066 350.738 8.32047Z"
        fill={getPathColor(color, palette.first)}
      />
      <path
        d="M367.986 30.1685C367.986 21.3727 374.597 14.5791 383.569 14.5791C389.351 14.5791 394.372 17.6508 396.792 22.1984L390.241 26.0358C389.056 23.6144 386.52 22.0786 383.514 22.0786C378.963 22.0786 375.602 25.4455 375.602 30.1685C375.602 34.8359 378.968 38.2028 383.514 38.2028C386.584 38.2028 389.12 36.7268 390.3 34.3054L396.912 38.083C394.376 42.6862 389.355 45.7579 383.573 45.7579C374.597 45.7579 367.986 38.9685 367.986 30.1685Z"
        fill={getPathColor(color, palette.first)}
      />
      <path
        d="M428.917 30.1685C428.917 21.3727 435.82 14.5791 444.501 14.5791C453.178 14.5791 460.144 21.3727 460.144 30.1685C460.144 38.9685 453.178 45.7579 444.501 45.7579C435.82 45.7579 428.917 38.9685 428.917 30.1685ZM452.528 30.1685C452.528 25.3899 449.043 22.023 444.501 22.023C440.015 22.023 436.529 25.3899 436.529 30.1685C436.529 34.9557 440.011 38.3183 444.501 38.3183C449.043 38.3183 452.528 34.9557 452.528 30.1685Z"
        fill={getPathColor(color, palette.first)}
      />
      <path
        d="M284.636 44.9323H293.489L282.194 29.9932L293.134 15.4092H284.046L275.532 27.3964H272.923V15.4092H265.306V44.9323H272.923V33.2959H276.113L284.636 44.9323Z"
        fill={getPathColor(color, palette.first)}
      />
      <path
        d="M483.404 15.4092L474.881 27.3964H472.281V15.4092H464.665V44.9323H472.281V33.2959H475.471L483.99 44.9323H492.847L481.548 29.9932L492.492 15.4092H483.404Z"
        fill={getPathColor(color, palette.first)}
      />
      <path
        d="M153.206 44.9324H145.059V11.9269H130.481V44.9324H122.334V4.30762H153.206V44.9324Z"
        fill={getPathColor(color, palette.first)}
      />
      <path
        d="M63.3395 32.1068L50.3474 45.1037L37.3597 32.1068C30.1837 24.9325 30.1837 13.296 37.3597 6.11738C44.5314 -1.06126 56.1635 -1.06126 63.3395 6.11738C70.5112 13.296 70.5112 24.9325 63.3395 32.1068Z"
        fill={getPathColor(color, palette.second)}
      />
      <path
        d="M37.3597 68.6248L50.3518 55.6279L63.3395 68.6248C70.5155 75.7992 70.5155 87.4399 63.3395 94.6143C56.1678 101.793 44.5357 101.793 37.3597 94.6143C30.1837 87.4399 30.1837 75.8034 37.3597 68.6248Z"
        fill={getPathColor(color, palette.first)}
      />
      <path
        d="M68.5996 63.3627L55.6075 50.3658L68.5996 37.3732C75.7756 30.1946 87.4077 30.1946 94.5794 37.3732C101.755 44.5519 101.755 56.1883 94.5794 63.3627C87.4077 70.5413 75.7756 70.5413 68.5996 63.3627Z"
        fill={getPathColor(color, palette.third)}
      />
      <path
        d="M32.0953 37.3732L45.0873 50.3701L32.0953 63.3627C24.9236 70.5413 13.2914 70.5413 6.11546 63.3627C-1.06054 56.1883 -1.06054 44.5519 6.11546 37.3732C13.2914 30.1946 24.9236 30.1946 32.0953 37.3732Z"
        fill={getPathColor(color, palette.fourth)}
      />
    </svg>
  )
}

export default Logo
