import {Checkbox} from '@x5-react-uikit/core'
import {Controller, FieldPath, FieldValues} from 'react-hook-form'
import {ComponentProps} from 'react'

type ControllerProps<TFieldValues extends FieldValues> = ComponentProps<
  typeof Controller<TFieldValues>
>

type Props<TFieldValues extends FieldValues> = {
  name: FieldPath<TFieldValues>
  label?: string
} & Pick<ControllerProps<TFieldValues>, 'control' | 'rules' | 'disabled'>

function FormInputCheckbox<TFieldValues extends FieldValues>({
  name,
  control,
  label,
  disabled,
}: Props<TFieldValues>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({field: {onChange, value}, fieldState: {error}, formState}) => {
        return <Checkbox checked={value} disabled={disabled} label={label} onChange={onChange} />
      }}
    />
  )
}

export default FormInputCheckbox
