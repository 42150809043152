import {Modal, Typography} from '@x5-react-uikit/core'
import useNotify from '@root/hooks/useNotify'
import {useDeleteTemplateMutation} from '@root/redux/api/templatesApi'

const TemplateDeleteModal = ({template, systemId, onClose}) => {
  const {notifySuccess, notifyError} = useNotify()

  const [deleteTemplate] = useDeleteTemplateMutation()
  const handleDeleteTemplate = () => {
    deleteTemplate({systemId, templateId: template.id})
      .unwrap()
      .then(() => {
        notifySuccess('Шаблон удалён')
        onClose()
      })
      .catch(() => notifyError())
  }

  return (
    <>
      <Modal
        closeOnOverlay
        buttons={[
          {
            onClick: onClose,
            side: 'right',
            text: 'Отмена',
          },
          {
            onClick: handleDeleteTemplate,
            side: 'right',
            text: 'Удалить шаблон',
          },
        ]}
        isOpen={!!template}
        size="medium"
        title="Удаление шаблона"
        onClose={onClose}
      >
        <Typography>
          Шаблон настроек теста будет удален без возможности сохранения. Вы можете создать новый.
        </Typography>
      </Modal>
    </>
  )
}

export default TemplateDeleteModal
