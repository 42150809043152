import {Typography} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import {ReactComponent as ErrorSvg} from 'assets/Error.svg'
import {ReactComponent as EmptySvg} from 'assets/Empty.svg'
import FlexboxRow from '@root/components/FlexboxRow'
import {FC} from 'react'
import type {BoxProps} from 'ui-kit'

type Props = {
  sx?: BoxProps['sx']
}

const ErrorMessage: FC<Props> = ({sx}) => {
  return (
    <FlexboxColumn sx={{mt: 'x12', alignItems: 'center', justifyContent: 'center', ...sx}}>
      <FlexboxRow sx={{alignItems: 'end'}}>
        <ErrorSvg style={{position: 'absolute'}} />
        <EmptySvg />
      </FlexboxRow>
      <FlexboxColumn sx={{mt: 'x20', gap: '8px', alignItems: 'center'}}>
        <Typography variant="h4">Произошла непредвиденная ошибка</Typography>
        <Typography style={{textAlign: 'center'}} variant="p1">
          Что-то пошло не так. Пожалуйста, перезагрузите страницу.
        </Typography>
      </FlexboxColumn>
    </FlexboxColumn>
  )
}

export default ErrorMessage
