import React, {forwardRef} from 'react'
import {sizeTokenValues} from '@x5-react-uikit/tokens'
import * as icons from '@x5-react-uikit/icons'
import {Button} from '../Button/Button'
import {buttonVariants} from '../Button'
import {IconButtonProps} from './types'
import {restyled} from '../styles'

const StyledIconButton = restyled(Button)(({theme: {colors}, variant}) => {
  const a =
    variant === buttonVariants.text
      ? {
          color: colors.grey[70],
          '&:hover': {
            backgroundColor: colors.grey[20],
          },
          '&:active': {
            backgroundColor: colors.grey[40],
          },
        }
      : {}

  return {
    transition: '100ms color ease, 100ms background-color ease',
    ...a,
  }
})

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({icon, IconComponent, qa, ...props}, ref) => {
    const Icon = icons[icon]

    return (
      <StyledIconButton {...props} ref={ref} equated data-qa={qa}>
        {IconComponent || <Icon size={sizeTokenValues.large} />}
      </StyledIconButton>
    )
  }
)

export default IconButton
