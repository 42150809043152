import {FC, useContext, useMemo} from 'react'
import {tableContext} from './context'
import ActionsButton from '../ActionsButton'

type Props = {
  configurationId: string
}

export const ConfigurationsTableActionsButton: FC<Props> = ({configurationId}) => {
  const ctx = useContext(tableContext)
  if (ctx == null) {
    throw new Error('Context was null!')
  }

  const {onDelete, onUpdate} = useMemo(
    () => ({
      onDelete: () => ctx.onDelete(configurationId),
      onUpdate: () => ctx.onUpdate(configurationId),
    }),
    [ctx, configurationId]
  )

  if (!ctx.userInfo) {
    return <></>
  }

  return <ActionsButton userInfo={ctx.userInfo} onDelete={onDelete} onUpdate={onUpdate} />
}
