import type {SetAction} from 'core/helperTypes'
import React, {createContext} from 'react'

export type ConfUpdateContext = {
  setStep: SetAction<number>
  focusedFieldRef: React.MutableRefObject<HTMLDivElement>
}

export const confUpdateContext = createContext<ConfUpdateContext | null>(null)
export const ConfUpdateContextProvider = confUpdateContext.Provider
