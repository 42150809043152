import React, {FC} from 'react'
import clsx from 'clsx'
import {ListItemProps} from './types'
import {useStyles} from './styles'

export const ListItem: FC<ListItemProps> = (props: ListItemProps) => {
  const classes = useStyles()

  const {
    classes: overrideClasses = {},
    adornment,
    text,
    selected,
    disabled,
    secondary,
    noWrap = true,
    onClick,
    style,
    qa,
  } = props

  // const clss = {
  //   ...classes,
  //   ...overrideClasses,
  // }

  // override not all classes
  const unselectedClass = overrideClasses.unselected || classes.unselected
  const selectedClass = overrideClasses.selected || classes.selected

  const showSelectedClass = selected && !disabled

  return (
    <div
      className={clsx('list-item', classes.root, {
        [classes.disabled]: disabled,
        [classes.noWrap]: noWrap,
        [classes.hasAdornment]: adornment,
        [unselectedClass]: !selected,
        [classes.secondary]: secondary,
        [selectedClass]: showSelectedClass,
        selected: showSelectedClass,
      })}
      data-qa={qa}
      style={style}
      tabIndex={0}
      onClick={onClick}
    >
      {adornment && <div className={classes.adornment}>{adornment}</div>}

      <div className={clsx(classes.text, noWrap && classes.noWrapText)}>{text}</div>
    </div>
  )
}

export default ListItem
