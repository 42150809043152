import React, {CSSProperties, FC} from 'react'
import clsx from 'clsx'
import {ColumnExpand as ColumnExpandIcon, Marker as IconMarker} from '@x5-react-uikit/icons'
import {Tooltip} from '../../Tooltip'
import {TableHeadCellProps, TableMenuIconType} from '../types'
import {TableHeadCellMenuIcon} from './MenuIcon'
import {useBaseCellStyles} from '../Cell/styles'
import {useStyles} from './styles'
import {defaultCellDensity, getBlockedPositionStyle, getCellDensityClassNameBy} from '../helpers'
import {TableCellLabel} from '../CellLabel'
import {sizeTokenValues} from '@x5-react-uikit/tokens'

const iconSize = sizeTokenValues.small

function renderMarker(classes, color?: string, tooltip?: any): JSX.Element {
  const icon = (
    <div className={classes.marker}>
      <IconMarker color={color} size={iconSize} />
    </div>
  )

  return tooltip ? <Tooltip content={tooltip}>{icon}</Tooltip> : icon
}

function renderExtend(classes): JSX.Element {
  return (
    <div className={classes.extend}>
      <ColumnExpandIcon size={iconSize} />
    </div>
  )
}

export const TableHeadCell: FC<TableHeadCellProps> = (props: TableHeadCellProps): JSX.Element => {
  const {
    density = defaultCellDensity,
    noWrap,
    label,
    description,

    markerColor,
    allowExtend,
    menuIcon,
    popperId,
    fieldId,
    width,
    markerTitle,
    isActive,
    blocked,
    blockedPosition,
    lockedRef,
    align,
    qa,
    rowId,
    colId,
    lockTitle, // hold it, we just extract from otherProps
    LabelComponent = TableCellLabel,
    className,
    ...otherProps
  } = props

  const baseClasses = useBaseCellStyles()
  const classes = useStyles()

  const styles: CSSProperties = React.useMemo(
    () => ({
      width,
      ...(align ? {align} : {}),
      ...getBlockedPositionStyle(blocked ? blockedPosition : undefined),
    }),
    [width, align, blocked, blockedPosition]
  )

  const canInteractive = menuIcon !== TableMenuIconType.none
  const activeBorderClass = canInteractive && isActive && classes.isActive
  const blockedPrimary =
    blocked && (blockedPosition || blockedPosition === 0) && classes.blockedPrimary
  const densityClassName = baseClasses[getCellDensityClassNameBy(density)]
  const hasLabel = label !== undefined && label !== null && label !== ''

  return (
    <div
      ref={(el) => {
        if (lockedRef) {
          lockedRef.current = el
        }
      }}
      aria-describedby={popperId}
      className={clsx(
        classes.root,
        canInteractive && classes.interactive,
        blocked && classes.blocked,
        blockedPrimary,
        activeBorderClass,
        className
      )}
      data-col={colId}
      data-field={fieldId}
      data-qa={qa ? `${qa}-head` : null}
      data-row={rowId}
      data-type="table-cell table-head-cell"
      style={styles}
      {...otherProps}
    >
      <div className={clsx(classes.inner, densityClassName)} data-type="table-head-cell-inner">
        {markerColor && renderMarker(classes, markerColor, markerTitle)}
        {hasLabel && (
          <LabelComponent
            bodyCell={false}
            density={density}
            description={description}
            noWrap={noWrap}
          >
            {label}
          </LabelComponent>
        )}
        {allowExtend && renderExtend(classes)}
        {menuIcon && <TableHeadCellMenuIcon classes={classes} menuIcon={menuIcon} />}
      </div>
    </div>
  )
}

TableHeadCell.defaultProps = {
  menuIcon: TableMenuIconType.none,
}

export default TableHeadCell
