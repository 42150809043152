import {Document, Page, StyleSheet, View, Text} from '@react-pdf/renderer'
import {
  fontX5Ui,
  Section,
  withLineHeight,
  Heading,
  Link,
  Title,
  Hr,
  Table,
  Column,
} from '@root/pdf-components'
import {baseTheme} from '@x5-react-uikit/core'
import type {Report} from '@root/features/reports/types'
import {FC, Fragment, ReactNode, useMemo} from 'react'
import {sortBy} from 'lodash'
import {ReportCommonFieldsMeta} from '@root/features/reports/constants'
import {LaunchInfo} from '@root/pages/SpecialistToolsPage/components/launchHistoryTab/types'
import {formatDateRuLocale, formatDateTimeRuLocale} from '@root/utils'

type Props = {
  report: Report
  launch: LaunchInfo
}

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#EFF1F5',
    padding: '16px',
    gap: '24px',
  },
  text: withLineHeight(16, 20, {
    fontWeight: 400,
    fontFamily: fontX5Ui,
    letterSpacing: '0.12px',
  }),
  listItem: {
    flexDirection: 'row',
    gap: '16px',
    alignItems: 'baseline',
  },
  testVariables: {
    gap: '16px',
  },
  testVariableItem: {
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: '24px',
    padding: '14px 16px',
    backgroundColor: '#F5F7F7',
  },
  testVariableItemTextIndex: withLineHeight(16, 20, {
    fontFamily: fontX5Ui,
    letterSpacing: '0.12px',
    color: '#222626',
  }),
  testVariableItemTextKey: {
    fontFamily: fontX5Ui,
    letterSpacing: '0.12px',
    color: baseTheme.colors.grey[100],
    fontWeight: 500,
  },
  testVariableItemTextValue: {
    fontFamily: fontX5Ui,
    letterSpacing: '0.12px',
    color: '#222626',
  },
})

enum AnchorsKind {
  TestingObjective = '-1',
  Conclusions = '-2',
  // Statistics = 'Statistics',
  TestingApproach = '-3',
  TestingLimitations = '-4',
  EnvironmentConfiguration = '-5',
  TestVariables = '-6',
}

type Anchor = {
  text: string
  href: string
}

const anchors: Anchor[] = [
  {
    text: 'Цель тестирования',
    href: AnchorsKind.TestingObjective,
  },
  {
    text: 'Выводы',
    href: AnchorsKind.Conclusions,
  },
  // {
  //   text: "Статистика",
  //   href: anchorsKind.Statistics
  // },
  {
    text: 'Подход к тестированию',
    href: AnchorsKind.TestingApproach,
  },
  {
    text: 'Ограничения тестирования',
    href: AnchorsKind.TestingLimitations,
  },
  {
    text: 'Конфигурация среды',
    href: AnchorsKind.EnvironmentConfiguration,
  },
  {
    text: 'Переменные теста',
    href: AnchorsKind.TestVariables,
  },
]

const noDataStub = (
  <Text style={{fontFamily: fontX5Ui, color: baseTheme.colors.grey[60]}}>Не заполнен</Text>
)

const withHttp = (url: string) => (!/^https?:\/\//i.test(url) ? `http://${url}` : url)

function enhanceText(input: string): ReactNode | null {
  if (!input) {
    return null
  }

  const urlRegex = /(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,6})(\/\S*)?/gi
  const result = []
  let lastIndex = 0

  let match: RegExpExecArray
  while ((match = urlRegex.exec(input)) !== null) {
    if (match.index > lastIndex) {
      result.push(<Text key={lastIndex}>{input.substring(lastIndex, match.index)}</Text>)
    }

    result.push(
      <Link key={match.index} href={withHttp(match[0])}>
        {match[0]}
      </Link>
    )

    lastIndex = match.index + match[0].length
  }

  if (lastIndex < input.length) {
    result.push(<Text key={lastIndex}>{input.substring(lastIndex)}</Text>)
  }

  return <Text style={styles.text}>{result}</Text>
}

const tableMeta = ReportCommonFieldsMeta.configuration.values[0]

if (tableMeta == null || tableMeta.type !== 'table') {
  throw new Error('Unexpected error. Unable to find default table declaration for report')
}

const configurationTableColumns = Object.values(tableMeta.columns).map(
  (x, i) =>
    ({
      dataIndex: x.id as string,
    }) as Column
)

export const PdfReport: FC<Props> = ({report, launch}) => {
  const commonFieldsMap = useMemo(() => {
    return (report.reportData?.commonFields ?? []).reduce(
      (acc, next) => {
        acc[next.id] = next
        return acc
      },
      {} as Record<string, Report['reportData']['commonFields'][0]>
    )
  }, [report.reportData?.commonFields])

  const dynamicFieldsMap = useMemo(() => {
    return (report.reportData?.dynamicFields ?? []).reduce(
      (acc, next) => {
        acc[next.id] = next
        return acc
      },
      {} as Record<string, Report['reportData']['dynamicFields'][0]>
    )
  }, [report.reportData?.dynamicFields])

  const anchorsWithDynamicFields: Anchor[] = useMemo(
    () => [
      ...anchors,
      ...sortBy(Object.values(dynamicFieldsMap), 'number').map((x, i) => ({
        text: x.label!,
        href: x.id ? x.id.toString() : i.toString(),
      })),
    ],
    [dynamicFieldsMap]
  )

  const tableData = useMemo(() => {
    return commonFieldsMap.configuration.values.table[0].rows.map(({cells}) => {
      return cells.reduce(
        (acc, next) => {
          acc[next.columnId] = next.values.text.value
          return acc
        },
        {} as Record<string, string>
      )
    })
  }, [commonFieldsMap.configuration.values.table])

  const formDateTimeFormatted = formatDateTimeRuLocale(report.createdAt)
  const pipelineDateFormatted = formatDateRuLocale(launch.finishedAt)

  return (
    <Document>
      <Page wrap dpi={72} size={[1600]} style={styles.page}>
        <Section style={{gap: '16px'}}>
          <Heading
            subtitle={`Сформирован ${report.creator.name} (${report.creator.email}) ${formDateTimeFormatted}`}
            title={report.name}
          />
          <View style={styles.listItem}>
            <Text style={styles.text}>Тестирование</Text>
            <Text style={styles.text}>
              ID {launch.pipelineId} от {pipelineDateFormatted}
            </Text>
          </View>
          <View style={styles.listItem}>
            <Text style={styles.text}>Шаблон переменных</Text>
            <Text style={styles.text}>{report.variablesTemplateName}</Text>
          </View>
          <View style={styles.listItem}>
            <Text style={styles.text}>Информационная система</Text>
            <Text style={styles.text}>{report.system.systemTag}</Text>
          </View>
          <View style={styles.listItem}>
            <Text style={styles.text}>Конфигурация</Text>
            <Text style={styles.text}>{report.reportConfigurationName}</Text>
          </View>
          <View style={styles.listItem}>
            <Text style={styles.text}>Заявка</Text>
            <Text style={styles.text}>#{report.taskNumber}</Text>
          </View>
          <View style={styles.listItem}>
            <Text style={styles.text}>Задачи в kaiten</Text>
            <Link href={report.kaitenCardLink}>Перейти к связанным задачам</Link>
          </View>
        </Section>
        <Section>
          <View>
            <View style={{marginBottom: '24px'}}>
              <Title level="h2">Оглавление</Title>
            </View>
            <View>
              {anchorsWithDynamicFields.map((x, i) => (
                <Link key={i} src={`#${x.href}`}>
                  • {x.text}
                </Link>
              ))}
            </View>
          </View>
        </Section>
        <Section style={{gap: '24px'}}>
          <View>
            <View style={{marginBottom: '24px'}}>
              <Title level="h2">Цель тестирования</Title>
            </View>
            <Text id={AnchorsKind.TestingObjective} style={styles.text}>
              {enhanceText(commonFieldsMap?.goals?.values?.comment?.value) ?? noDataStub}
            </Text>
          </View>
          <Hr />
          <View>
            <View style={{marginBottom: '24px'}}>
              <Title level="h2">Выводы</Title>
            </View>
            <Text id={AnchorsKind.Conclusions} style={styles.text}>
              {enhanceText(commonFieldsMap.conclusions?.values?.comment?.value) ?? noDataStub}
            </Text>
          </View>

          {/* TODO: STATISTICS SHOULD BE PLACED HERE */}

          <Hr />
          <View>
            <View style={{marginBottom: '24px'}}>
              <Title level="h2">Подход к тестированию</Title>
            </View>
            <Text id={AnchorsKind.TestingApproach} style={styles.text}>
              {enhanceText(commonFieldsMap.approach?.values?.comment?.value) ?? noDataStub}
            </Text>
          </View>
          <Hr />
          <View>
            <View style={{marginBottom: '24px'}}>
              <Title level="h2">Ограничения тестирования</Title>
            </View>
            <Text id={AnchorsKind.TestingLimitations} style={styles.text}>
              {enhanceText(commonFieldsMap.limit?.values?.comment?.value) ?? noDataStub}
            </Text>
          </View>
          <Hr />
          <View>
            <View style={{marginBottom: '24px'}}>
              <Title level="h2">Конфигурация среды</Title>
            </View>
            <View id={AnchorsKind.EnvironmentConfiguration}>
              <Table columns={configurationTableColumns} data={tableData} />
            </View>
          </View>
          {Object.values(dynamicFieldsMap).length > 0 && <Hr />}
          {Object.values(dynamicFieldsMap).map((x, i) => (
            <Fragment key={`dynamic-section-${x.id}`}>
              <View>
                <View style={{marginBottom: '24px'}}>
                  <Title level="h2">{x.label}</Title>
                </View>
                <Text id={x.id} style={styles.text}>
                  {enhanceText(x.values?.comment?.value) ?? noDataStub}
                </Text>
              </View>
              {Object.values(dynamicFieldsMap).length - 1 !== i && <Hr />}
            </Fragment>
          ))}
        </Section>
        <Section>
          <View>
            <View style={{marginBottom: '24px'}}>
              <Title level="h2">Переменные теста</Title>
            </View>

            <View id={AnchorsKind.TestVariables} style={styles.testVariables}>
              {report.variables.map(({key, value}, i) => (
                <View key={i} style={styles.testVariableItem}>
                  <Text style={styles.testVariableItemTextIndex}>{i + 1}.</Text>
                  <Text style={styles.testVariableItemTextKey}>{key}:</Text>
                  <Text style={styles.testVariableItemTextValue}>{value}</Text>
                </View>
              ))}
            </View>
          </View>
        </Section>
      </Page>
    </Document>
  )
}
