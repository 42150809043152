import {createApi} from '@reduxjs/toolkit/query/react'
import redirectLoginOn401Query from './queries/redirectLoginOn401Query'
import {OpenApiRequestBody, OpenApiResponseType} from '@root/core/helperTypes'

const tags = ['Systems']

type GetSystemListResponse = OpenApiResponseType<'/v1/systems/list', 'post'>

export type GetSystemListPayload = {
  filter: OpenApiRequestBody<'/v1/systems/list', 'post'>
}

type GetSystemByIdResponse = OpenApiResponseType<'/v1/systems/{systemId}'>
type GetSystemActiveTaskResponse = OpenApiResponseType<'/v1/systems/{systemId}/active-task'>
type UpdateSystemResponse = OpenApiResponseType<'/v1/systems/{systemId}', 'patch'>

type UpdateSystemPayload = {
  systemId: string
  updateData: OpenApiRequestBody<'/v1/systems/{systemId}', 'patch'>
}

type GetSystemFinishedTaskResponse =
  OpenApiResponseType<'/v1/systems/{systemId}/last-finished-task'>

// TODO: UNCOMMENT LATER
// type DeleteSystemResponse = OpenApiResponseType<'/v1/systems/{systemId}', 'delete'>

export const systemsApi = createApi({
  reducerPath: 'systemsApi',
  tagTypes: tags,
  baseQuery: redirectLoginOn401Query(),
  endpoints: (build) => ({
    getSystems: build.query<GetSystemListResponse, GetSystemListPayload>({
      query: ({filter}) => ({
        url: '/systems/list',
        method: 'POST',
        body: filter,
      }),
      providesTags: tags,
    }),

    getSystemById: build.query<GetSystemByIdResponse, string>({
      query: (systemId) => `/systems/${systemId}`,
      providesTags: tags,
    }),

    getSystemActiveTask: build.query<GetSystemActiveTaskResponse, string>({
      query: (systemId) => `/systems/${systemId}/active-task`,
      providesTags: tags,
    }),

    getSystemFinishedTask: build.query<GetSystemFinishedTaskResponse, string>({
      query: (systemId) => `/systems/${systemId}/last-finished-task`,
      providesTags: tags,
    }),

    updateSystem: build.mutation<UpdateSystemResponse, UpdateSystemPayload>({
      query: ({systemId, updateData}) => ({
        url: `/systems/${systemId}`,
        method: 'PATCH',
        body: updateData,
      }),
      invalidatesTags: tags,
    }),

    // TODO: NOT IMPLEMENTED ON BACKEND
    // deleteSystem: build.mutation<DeleteSystemResponse, string>({
    //   query: (systemId) => ({
    //     url: `/systems/${systemId}`,
    //     method: 'DELETE',
    //   }),
    //   invalidatesTags: tags
    // }),
  }),
})

export const {
  useGetSystemsQuery,
  useGetSystemByIdQuery,
  useGetSystemActiveTaskQuery,
  useGetSystemFinishedTaskQuery,
  useUpdateSystemMutation,
  // useDeleteSystemMutation
} = systemsApi

export const invalidateSystemsAction = systemsApi.util.invalidateTags(tags)
