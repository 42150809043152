import {FC, useCallback, useEffect} from 'react'
import {useFieldArray} from 'react-hook-form'
import {useComparativeReportContext} from '../context'
import type {FormData} from '../types'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import {IconButton, Typography} from '@x5-react-uikit/core'
import {Add as AddIcon, Del as DelIcon} from '@x5-react-uikit/icons'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'
import {Button} from 'ui-kit'
import Divider from '@root/components/Divider'

import {EditableField} from './EditableField'

const FIELD_WIDTH = '520px'

type FieldProps = {
  index: number
  remove: (index: number) => void
  isEdit: boolean
}

const DYNAMIC_FIELDS_REGISTER = `reportData.dynamicFields` as const

const Field: FC<FieldProps> = ({index, remove, isEdit}) => {
  const {control, setValue, getValues} = useComparativeReportContext()

  const registerLabel = `${DYNAMIC_FIELDS_REGISTER}.${index}.label` as const
  const registerValue = `${DYNAMIC_FIELDS_REGISTER}.${index}.values.comment.value` as const

  const handleDelete = useCallback(() => {
    remove(index)
  }, [remove, index])

  useEffect(() => {
    const registerNumber = `${DYNAMIC_FIELDS_REGISTER}.${index}.number` as const
    setValue(registerNumber, index + 1)
  }, [index, setValue])

  return (
    <FlexboxColumn sx={{width: FIELD_WIDTH, gap: '24px'}}>
      {isEdit && (
        <FlexboxRow sx={{justifyContent: 'space-between'}}>
          <Typography variant="h3">Дополнительное текстовое поле №{index + 1}</Typography>
          <IconButton
            IconComponent={<DelIcon />}
            size="small"
            variant="text"
            onClick={handleDelete}
          />
        </FlexboxRow>
      )}
      <EditableField label="Заголовок поля:" value={getValues(registerLabel)}>
        <FormInputText<FormData>
          required
          control={control}
          label="Заголовок поля"
          name={registerLabel}
          width={FIELD_WIDTH}
        />
      </EditableField>
      <EditableField label="Комментарий:" value={getValues(registerValue)}>
        <FormInputText<FormData>
          required
          textarea
          control={control}
          label="Комментарий"
          name={registerValue}
          width={FIELD_WIDTH}
        />
      </EditableField>
    </FlexboxColumn>
  )
}

export const DynamicFields: FC = () => {
  const {control, isEdit} = useComparativeReportContext()

  const {fields, append, remove} = useFieldArray({
    control,
    name: 'reportData.dynamicFields',
  })

  const addDynamicField = useCallback(() => {
    append({
      values: {comment: {value: ''}},
      label: '',
    })
  }, [append])

  return (
    <>
      {(fields.length > 0 || isEdit) && <Divider />}
      {fields.map((field, index) => (
        <Field key={field.id} index={index} isEdit={isEdit} remove={remove} />
      ))}
      {isEdit && (
        <Button
          startIcon={<AddIcon size="small" />}
          style={{width: 'fit-content'}}
          variant="text"
          onClick={addDynamicField}
        >
          Добавить новое текстовое поле
        </Button>
      )}
    </>
  )
}
