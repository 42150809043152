import {makeUiStyles} from '../../theme'
import {TableBlockedZIndexes} from '../styles'
import {TableStyleHelpers} from '../Cell/styles'

export const useStyles = makeUiStyles(
  (theme) => ({
    root: {
      ...TableStyleHelpers.getBaseCellRoot(theme),
      textAlign: 'center',
    },
    blocked: {
      ...TableStyleHelpers.getCellBlocked(theme),
      top: 0,
      zIndex: TableBlockedZIndexes.top,
    },
    blockedPrimary: {
      zIndex: TableBlockedZIndexes.cross,
    },
    blockedFirst: {
      zIndex: TableBlockedZIndexes.extreme,
    },
    blockedBodyCell: {
      top: 'auto',
      zIndex: TableBlockedZIndexes.left,
    },
  }),
  'table-checkbox-cell'
)
