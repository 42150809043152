import React, {FC, MouseEventHandler} from 'react'
import clsx from 'clsx'
import {IconProps} from '@x5-react-uikit/icons'
import {sizeTokenValues} from '@x5-react-uikit/tokens'
import {useCellEditIconStyles} from './styles'
import {qaCellEditName} from '../../helpers'

interface Props {
  onClick: MouseEventHandler<HTMLDivElement>
  Icon: FC<IconProps>
  type: string
  left?: boolean
}

export const BodyCellEditIconTypeId = 'table-cell-edit-icon'

export const BodyCellEditIcon: FC<Props> = (props: Props) => {
  const {onClick, Icon, left = false, type} = props

  const classes = useCellEditIconStyles()

  return (
    <div className={clsx(classes.root, left && classes.left)}>
      <div
        className={classes.icon}
        data-qa={`${qaCellEditName}-${type}-icon`}
        data-type={BodyCellEditIconTypeId}
        onClick={onClick}
      >
        <Icon size={sizeTokenValues.small} />
      </div>
    </div>
  )
}

export default BodyCellEditIcon
