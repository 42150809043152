import {IconButtonProps} from '../IconButton'
import {QA} from '../types'

export enum innerButtonSizes {
  'small' = 'small',
  'xsmall' = 'xsmall',
  'xxsmall' = 'xxsmall',
}

type InnerButtonSize = keyof typeof innerButtonSizes

export interface InnerButtonProps
  extends QA,
    Omit<IconButtonProps, 'loading' | 'href' | 'component' | 'size'> {
  size?: InnerButtonSize
}
