import React, {FC, useState, useRef, useLayoutEffect} from 'react'

import {useScrollStyles, Button} from '@x5-react-uikit/core'
import {Add as AddIcon} from '@x5-react-uikit/icons'
import {sizeTokenValues} from '@x5-react-uikit/tokens'

import {Item} from './Item'

import {StyledContent, classes} from './styles'
import {FilterCollectorProps} from './types'
import {contentHeight, temporaryColumnNamePrefix} from './helpers'

export const Content: FC<FilterCollectorProps> = ({filters, columns, onChange, size}) => {
  const scrollClasses = useScrollStyles()
  const contentRef = useRef<HTMLDivElement>()

  const [hasScroll, setHasScroll] = useState(false)
  const [multiselectFilterValues, setMultiselectFilterValues] = useState<Record<string, string>>({})

  useLayoutEffect(() => {
    if (contentRef.current) {
      setHasScroll(contentRef.current.scrollHeight > contentHeight)
    }
  }, [filters])

  const handleAddFilter = () => {
    onChange(filters.concat({name: `${temporaryColumnNamePrefix}-${Date.now()}`, value: ''}))
  }

  return (
    <StyledContent ref={contentRef} className={scrollClasses.root} hasScroll={hasScroll}>
      {filters.length ? (
        <div className={classes.rows}>
          {filters.map((filter) => (
            <Item
              key={filter.name}
              columns={columns}
              filter={filter}
              filters={filters}
              multiselectFilterValues={multiselectFilterValues}
              setMultiselectFilterValues={setMultiselectFilterValues}
              size={size}
              onChange={onChange}
            />
          ))}
        </div>
      ) : (
        <div className={classes.contentDescription}>
          Это коллектор фильтров. Используйте его, чтобы применять или изменять фильтры к колонкам
          таблицы. Чтобы начать, нажмите «Добавить фильтр».
        </div>
      )}
      <Button
        className={classes.contentAddButton}
        size={sizeTokenValues.small}
        startIcon={<AddIcon size={sizeTokenValues.small} />}
        variant="text"
        onClick={handleAddFilter}
      >
        Добавить фильтр
      </Button>
    </StyledContent>
  )
}

export default Content
