import {makeUiStyles} from '../theme'

export const getScrollJCSS = ({spaces, colors}) => ({
  '&::-webkit-scrollbar': {
    width: spaces.x8,
    height: spaces.x8,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  '&::-webkit-scrollbar-button': {
    display: 'none',
    height: 0,
    width: 0,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: colors.grey[30],
    backgroundClip: 'padding-box',
    border: `${spaces.x2}px solid rgba(0,0,0,0)`,
    borderRadius: spaces.x8,
    minHeight: 36,
  },
  // '&::-webkit-scrollbar-thumb:hover': {
  //   backgroundColor: colors.grey[20],
  // },
  '&::-webkit-scrollbar-corner': {
    backgroundColor: 'rgba(0,0,0,0)',
    display: 'none',
  },
})

export const useScrollStyles = makeUiStyles(
  (theme) => ({
    root: getScrollJCSS(theme),
  }),
  'scroll'
)

export default useScrollStyles
