import React, {FC} from 'react'
import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'

import {Select} from '../Select'
import {IconButton} from '../IconButton'
import {Tooltip} from '../Tooltip'
import {Close as CloseIcon} from '@x5-react-uikit/icons'
import {sizeTokenValues, placements} from '@x5-react-uikit/tokens'
import {DragIndicatorIcon} from '../Icons/DragIndicatorIcon'

import {SubItem} from './SubItem'

import {FilterCollectorFilter, FilterCollectorCondition, FilterCollectorItemProps} from './types'
import {classes} from './styles'
import {conditionColumns as defaultConditionColumns, updateFilters} from './helpers'

export const Item: FC<FilterCollectorItemProps> = ({
  filters,
  columns,
  filter,
  size,
  onChange,
  setMultiselectFilterValues,
  multiselectFilterValues,
}) => {
  const isEmptyColumn = filter.name.includes('__column__')
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({
    id: filter.name,
    resizeObserverConfig: {},
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const includedFilters = filters
    .filter((item) => item.name !== filter.name)
    .map((item) => item.name)
  const normalizedColumns = columns.map((column) => ({
    ...column,
    disabled: includedFilters.includes(column.value),
  }))

  const currentColumn = columns.find((col) => col.value === filter.name)

  const handleChangeColumn = (
    filter: FilterCollectorFilter,
    name: FilterCollectorFilter['name']
  ) => {
    const newFilters = updateFilters(filters, filter.name, {name, value: ''})
    onChange(newFilters)
  }

  const handleChangeCondition = (
    filter: FilterCollectorFilter,
    condition: FilterCollectorCondition
  ) => {
    const newFilters = updateFilters(filters, filter.name, {...filter, condition})
    onChange(newFilters)
  }

  const handleRemoveFilter = (name: FilterCollectorFilter['name']) => {
    const newFilters = filters.filter((item) => item.name !== name)
    onChange(newFilters)
  }

  return (
    <div ref={setNodeRef} className={classes.row} style={style}>
      <DragIndicatorIcon
        {...attributes}
        {...listeners}
        className={classes.dragIndicatorIcon}
        size={sizeTokenValues.small}
      />
      <Select
        stretch
        usePortal
        className={classes.columnSelector}
        label="Столбец"
        options={normalizedColumns}
        size={size}
        value={isEmptyColumn ? null : filter.name}
        onChange={(_, name: FilterCollectorFilter['name']) => handleChangeColumn(filter, name)}
      />
      <div className={classes.valueInput}>
        {currentColumn?.conditionColumns?.length > 0 && (
          <Select
            usePortal
            className={classes.columnSelector}
            disabled={isEmptyColumn}
            label="Условие"
            options={currentColumn?.conditionColumns || defaultConditionColumns}
            size={size}
            value={filter.condition}
            onChange={(_, condition: FilterCollectorCondition) =>
              handleChangeCondition(filter, condition)
            }
          />
        )}
        <SubItem
          columns={columns}
          disabled={isEmptyColumn}
          filter={filter}
          filters={filters}
          multiselectFilterValues={multiselectFilterValues}
          setMultiselectFilterValues={setMultiselectFilterValues}
          size={size}
          onChange={onChange}
        />
      </div>
      <Tooltip content="Удалить фильтр" placement={placements.bottomEnd}>
        <IconButton
          IconComponent={<CloseIcon />}
          size={sizeTokenValues.small}
          variant="text"
          onClick={() => handleRemoveFilter(filter.name)}
        />
      </Tooltip>
    </div>
  )
}

export default Item
