import {createApi} from '@reduxjs/toolkit/query/react'
import {OpenApiResponseType} from '@root/core/helperTypes'
import redirectLoginOn401Query from './queries/redirectLoginOn401Query'
import {withQueryParams} from './utils'
import {components} from '@root/openapi'


type FinportalUrl = `/v1/finportal`
type CppPayload = {cpp: string}
export type VerifyBudgetResponse = components['schemas']['FinPortalResponse']
export type VerifyBudgetPayload = CppPayload & {workCost: number | string}
export type VerifyCppResponse = OpenApiResponseType<`${FinportalUrl}/verify/{sppCode}`, 'get'>
export type GetReceiveOwnersResponse = OpenApiResponseType<
  `${FinportalUrl}/receive-owners/{sppCode}`,
  'get'
>

export const budgetApi = createApi({
  reducerPath: 'budgetApi',
  baseQuery: redirectLoginOn401Query(),
  endpoints: (build) => ({
    verifyBudget: build.query<VerifyBudgetResponse, VerifyBudgetPayload>({
      query: ({cpp, workCost}) => withQueryParams('/finportal/verify', {cpp, workCost}),
    }),
    verifyCpp: build.query<VerifyCppResponse, CppPayload>({
      query: ({cpp}) => `/finportal/verify/${cpp}`,
    }),
    getBudgetOwners: build.query<GetReceiveOwnersResponse, CppPayload>({
      query: ({cpp}) => `/finportal/receive-owners/${cpp}`,
    }),
  }),
})

export const {
  useLazyVerifyBudgetQuery,
  useVerifyCppQuery,
  useLazyVerifyCppQuery,
  useGetBudgetOwnersQuery,
} = budgetApi

export const resetBudgetState = budgetApi.util.resetApiState()
