import React, {forwardRef} from 'react'
import {DataGridRowProps} from './types'
import {StyledRow} from './styles'

export const DataGridRow = forwardRef<HTMLTableRowElement, DataGridRowProps>(
  ({children, ...props}, ref) => {
    return (
      <StyledRow ref={ref} {...props}>
        {children}
      </StyledRow>
    )
  }
)

export default DataGridRow
