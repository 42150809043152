import {GRAPHS_REGISTER} from '@root/features/reports/constants'
import {IconButton, baseTheme} from '@x5-react-uikit/core'
import {Del as DelIcon, Refresh as RefreshIcon} from '@x5-react-uikit/icons'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'
import {RequiredRule} from '@root/constants'
import {useState} from 'react'

const {colors} = baseTheme

const GraphDataShowField = ({form, index, onRemove}) => {
  const registerName = `${GRAPHS_REGISTER}.${index}.graphName`
  const registerUrl = `${GRAPHS_REGISTER}.${index}.graphUrl`
  const registerComment = `${GRAPHS_REGISTER}.${index}.comment`

  const [graphUrl, setGraphUrl] = useState(form.getValues(registerUrl))
  const handleShowUpdatedGraph = () => {
    setGraphUrl(form.getValues(registerUrl))
  }

  return (
    <FlexboxColumn sx={{gap: '16px'}}>
      <FlexboxRow sx={{gap: '24px', alignItems: 'top'}}>
        <IconButton
          disabled={!graphUrl}
          IconComponent={<RefreshIcon />}
          style={{color: colors.grey[60]}}
          variant="text"
          onClick={handleShowUpdatedGraph}
        />
        <FormInputText
          required
          control={form.control}
          label="Название графика"
          name={registerName}
          rules={RequiredRule}
          width="540px"
        />
        <FormInputText
          required
          control={form.control}
          label="Ссылка на график в Grafana "
          name={registerUrl}
          rules={RequiredRule}
          width="540px"
        />
        <IconButton
          disabled={!onRemove}
          IconComponent={<DelIcon />}
          variant="text"
          onClick={onRemove}
        />
      </FlexboxRow>
      {graphUrl && (
        <iframe
          src={graphUrl}
          style={{height: '400px'}}
          title={form.getValues(registerName)}
        ></iframe>
      )}
      <FormInputText
        textarea
        control={form.control}
        label="Комментарий"
        name={registerComment}
        width="520px"
      />
    </FlexboxColumn>
  )
}

export default GraphDataShowField
