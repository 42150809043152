import React, {CSSProperties, useLayoutEffect, useRef, RefObject, FC} from 'react'
import clsx from 'clsx'
import {Tooltip} from '../../Tooltip'
import {Checkbox} from '../../Checkbox'
import {TableCheckboxCellProps} from '../types'
import {useStyles} from './styles'
import {
  defaultCellDensity,
  getBlockedPositionStyle,
  getCellDensityClassNameBy,
  getTableIconCellWidth,
} from '../helpers'
import {useBaseCellStyles} from '../Cell/styles'

export const TableCheckboxCell: FC<TableCheckboxCellProps> & {width: number} = (
  props: TableCheckboxCellProps
) => {
  const {
    density = defaultCellDensity,
    checked,
    onChange,
    tooltip,
    blocked,
    blockedPosition,
    bodyCell,
    disabled,
    qa,
    rowId,
    colId,
  } = props

  const cellRef: RefObject<HTMLDivElement> = useRef()
  const baseClasses = useBaseCellStyles()
  const classes = useStyles()

  const styles: CSSProperties = React.useMemo(
    () => ({
      width: getTableIconCellWidth(density),
      ...getBlockedPositionStyle(blocked ? blockedPosition : undefined),
    }),
    [blocked, blockedPosition]
  )

  const checkbox = (
    <Checkbox checked={checked} data-qa={qa} disabled={disabled} onChange={onChange} />
  )

  const densityClassName = baseClasses[getCellDensityClassNameBy(density)]
  const blockedPrimary =
    blocked && (blockedPosition || blockedPosition === 0) && classes.blockedPrimary
  const blockedFirst = blocked && (blockedPosition || blockedPosition === 0) && classes.blockedFirst
  const classNames = clsx(
    classes.root,
    densityClassName,
    blocked && classes.blocked,
    bodyCell && blocked && classes.blockedBodyCell,
    blockedPrimary,
    blockedFirst
  )

  const roles = ['table-cell', 'table-checkbox-cell']
  roles.push(bodyCell ? 'table-body-cell' : 'table-head-cell')

  useLayoutEffect(() => {
    if (TableCheckboxCell.width === cellRef?.current.offsetWidth) return
    TableCheckboxCell.width = cellRef.current.offsetWidth
  }, [])

  return (
    <div
      ref={cellRef}
      className={classNames}
      data-col={colId}
      data-row={rowId}
      data-type={roles.join(' ')}
      style={styles}
    >
      {tooltip ? (
        <Tooltip content={tooltip} placement="right-start" qa={qa ? `${qa}-tooltip` : null}>
          {checkbox}
        </Tooltip>
      ) : (
        checkbox
      )}
    </div>
  )
}

TableCheckboxCell.width = 32

export default TableCheckboxCell
