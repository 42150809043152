import {makeUiStyles} from '../theme'
import {SelectListProps, SelectChipProps, SelectAdornmentProps} from './types'
import {inputSizes} from '../Input'
import {PREFIX, restyled, doSelectors} from '../styles'
import {inputSelectors} from '../Input/styles'

export const selectClasses = {
  input: `${PREFIX}-select-input`,
} as const

export const selectChipClasses = {
  count: `${PREFIX}-select-chip-count`,
  close: `${PREFIX}-select-chip-close`,
} as const

export const selectHintClasses = {
  close: `${PREFIX}-select-hint-close`,
  icon: `${PREFIX}-select-hint-icon`,
  content: `${PREFIX}-select-hint-content`,
} as const

export const selectAdornmentClasses = {
  clear: `${PREFIX}-select-adornment-clear`,
} as const

export const selectMenuClasses = {
  root: `${PREFIX}-select-menu-root`,
  label: `${PREFIX}-select-menu-label`,
  caption: `${PREFIX}-select-menu-caption`,
  header: `${PREFIX}-select-menu-header`,
  footer: `${PREFIX}-select-menu-footer`,
} as const

const selectSelectors = doSelectors(selectClasses)
const selectChipSelectors = doSelectors(selectChipClasses)
const selectHintSelectors = doSelectors(selectHintClasses)
const selectAdornmentSelectors = doSelectors(selectAdornmentClasses)
const selectMenuSelectors = doSelectors(selectMenuClasses)
const borderWidth = 1

export const StyledSelectHint = restyled.div(({theme: {spaces, colors, typography}}) => ({
  display: 'flex',
  margin: spaces.join('x0', 'x4', 'x4'),
  padding: spaces.x2,
  borderRadius: 4,
  backgroundColor: colors.accent[10],
  ...typography.p3,
  [selectHintSelectors.close]: {
    marginLeft: 'auto',
  },
  [selectHintSelectors.icon]: {
    flexShrink: 0,
    fill: colors.accent[80],
    margin: spaces.x2,
  },
  [selectHintSelectors.content]: {
    display: 'flex',
    alignItems: 'center',
    margin: spaces.join('x1', 'x3', 'x1', 'x0'),
  },
}))

export const StyledSelectMenu = restyled.div<{isTree: boolean}>(
  ({theme: {colors, spaces, typography}, isTree}) => ({
    [selectMenuSelectors.root]: {
      listStyle: 'none',
      overflowY: 'auto',
      margin: 0,
      paddingBottom: isTree ? spaces.x4 : null,
      maxHeight: 224, // 32 * 7
      color: colors.grey[100],
      ':focus': {
        outline: 'none',
      },
    },
    [selectMenuSelectors.label]: {
      padding: spaces.join('x4', 'x6', 'x2'),
      color: colors.grey['70'],
      ...typography.h6,
    },
    [selectMenuSelectors.caption]: {
      padding: spaces.join('x0', 'x6', 'x0'),
      margin: spaces.join('x0', 'x0', 'x5'),
      color: colors.grey['70'],
      ...typography.h6,
    },
    [selectMenuSelectors.header]: {
      padding: spaces.join('x2', 'x6', 'x6'),
      borderBottom: `1px solid ${colors.grey[20]}`,
    },
    [selectMenuSelectors.footer]: {
      padding: spaces.join('x6', 'x6', 'x2', 'x6'),
      borderTop: `1px solid ${colors.grey[20]}`,
    },
  })
)

export const StyledSelectChip = restyled.div<Partial<SelectChipProps>>(
  ({theme: {colors, spaces, typography}, size, disabled}) => ({
    position: 'absolute',
    top: !size || size === inputSizes.large ? spaces.x10 + borderWidth : spaces.x3,
    left: !size || size === inputSizes.large ? spaces.x6 : spaces.x4,
    display: 'flex',
    alignItems: 'center',
    padding: spaces.x2 - borderWidth,
    backgroundColor: !disabled ? colors.grey[70] : colors.grey[20],
    color: !disabled ? colors.white : colors.grey[40],
    borderRadius: spaces.x1,
    ...typography.p2,
    [selectChipSelectors.count]: {
      display: 'inline-flex',
      alignItems: 'center',
      textAlign: 'center',
      padding: spaces.join('x0', 'x2'),
    },
    [selectChipSelectors.close]: {
      display: 'inline-flex',
      margin: 0,
      padding: 0,
      backgroundColor: 'transparent',
      border: 'none',
      color: colors.grey[40],
      cursor: 'pointer',
      pointerEvents: disabled ? 'none' : null,
      ':hover': {
        backgroundColor: colors.grey[60],
      },
    },
  })
)

export const StyledSelectAdornment = restyled.div<Partial<SelectAdornmentProps>>(
  ({theme: {spaces, colors}}) => ({
    display: 'flex',
    alignItems: 'center',
    [selectAdornmentSelectors.clear]: {
      marginRight: spaces.x1,
      padding: spaces.x2,
      borderRadius: spaces.x2,
      cursor: 'pointer',
      ':hover': {
        backgroundColor: colors.grey[20],
      },
      ':hover:active': {
        backgroundColor: colors.grey[40],
      },
    },
  })
)

export const useMenuItemStyles = makeUiStyles(
  (theme) => ({
    root: ({noWrap}: SelectListProps) => ({
      padding: theme.spaces.join('x2', 'x6'),
      display: 'flex',
      alignItems: noWrap ? 'center' : 'flex-start',
      // width: '100%',

      ...theme.typography.p1,
      height: noWrap ? 32 : 'auto',
      lineHeight: noWrap ? theme.typography.p1.lineHeight : '1.3',
      whiteSpace: noWrap ? 'nowrap' : 'normal',
      color: theme.colors.grey[100],

      '&:hover': {
        backgroundColor: theme.colors.grey[20],
        '& .check-icon svg': {
          display: 'inline-block',
          fill: `${theme.colors.grey[100]}!important`,
        },
      },
      '&.Mui-disabled': {
        opacity: 0.4,
      },

      '& .check-icon': {
        width: 16,
        height: 16,
        minWidth: 16,
        borderRadius: 4,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.colors.grey[20],
        marginRight: theme.spaces.x6,
        backgroundColor: theme.colors.grey[20],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
          display: 'none',
        },
      },
      '& .check-name': {
        textOverflow: 'ellipsis',
        overflow: noWrap ? 'hidden' : 'visibility',
      },
    }),
    selected: ({multiple}: SelectListProps) => ({
      backgroundColor: `${
        multiple
          ? '#E3F2FD' // background-picked
          : theme.colors.accent[90]
      }!important`,
      color: multiple ? theme.colors.grey[100] : theme.colors.white,
      '& .check-icon': {
        backgroundColor: theme.colors.accent[90],
        borderColor: theme.colors.accent[90],
        '& svg': {
          display: 'inline-block',
          fill: `${theme.colors.white}!important`,
        },
      },
    }),
  }),
  'select-menu-item'
)

export const StyledSelect = restyled.div<{offset?: number; opened: boolean}>(
  ({theme: {colors, spaces}, opened}) => ({
    position: 'relative',
    [`${inputSelectors.wrap}, ${inputSelectors.wrap}:hover`]: {
      ...(opened
        ? {
            borderColor: colors.accent[90],
            boxShadow: `inset 0 0 0 1px ${colors.accent[90]}`,
          }
        : null),
    },
    [selectSelectors.input]: {
      // paddingLeft: offset ? offset + spaces.x2 : null,
      textOverflow: 'ellipsis',
    },
  })
)
