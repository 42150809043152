import {SVGProps, ReactElement} from 'react'
import {QA} from '../types'

export enum logoVariants {
  full = 'full',
  icon = 'icon',
}

export enum logoColors {
  multi = 'multi',
  white = 'white',
  black = 'black',
}

export enum logoBrands {
  x5 = 'x5',
  perekrestok = 'perekrestok',
  pyaterochka = 'pyaterochka',
  karusel = 'karusel',
  mnogoLososya = 'mnogoLososya',
  chizhik = 'chizhik',
  chizhikVertical = 'chizhikVertical',
  chizhikText = 'chizhikText',
  fivePost = 'fivePost',
  fivePostGreen = 'fivePostGreen',
  x5Tech = 'x5Tech',
  x5Import = 'x5Import',
  x5Bank = 'x5Bank',
  x5Card = 'x5Card',
  x5Pay = 'x5Pay',
  x5Transport = 'x5Transport',
  x5ReadyFood = 'x5ReadyFood',
  x5ID = 'x5ID',
  x5RealEstate = 'x5RealEstate',
  x5Solutions = 'x5Solutions',
  x5Lab = 'x5Lab',
  x5Media = 'x5Media',
  x5Digital = 'x5Digital',
  x5Dialog = 'x5Dialog',
  x5FoodTech = 'x5FoodTech',
  paket = 'paket',
  okolo = 'okolo',
  foodRu = 'foodRu',
}

export interface LogoProps extends SVGProps<SVGSVGElement>, QA {
  brand: keyof typeof logoBrands
  variant?: keyof typeof logoVariants
  color?: keyof typeof logoColors
}

export interface RenderSVGFunc {
  (variant: logoVariants, color: logoColors, otherProps: LogoProps): ReactElement
}
