import type {EndpointBuilder} from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import type {BaseQueryFn} from '@reduxjs/toolkit/src/query/baseQueryTypes'
import {OpenApiResponseType, SetAction} from '@root/core/helperTypes'
import {paths} from '@root/openapi'
import dayjs from 'dayjs'
import {memo} from 'react'
import {isFunction} from 'lodash'

export const formattedDate = (date: string) => new Date(Date.parse(date)).toLocaleString('ru')

export const formatDateTimeRuLocale = (dateTime: string) =>
  dayjs(dateTime).format('DD.MM.YYYY в hh:mm')
export const formatDateRuLocale = (date: string) => dayjs(date).format('DD.MM.YYYY')

export const NOT_IMPLEMENTED = () => alert('Не реализовано.')

export const copyToClipboard = async (
  text: string,
  onSuccess?: () => void,
  onError?: () => void
) => {
  try {
    await navigator.clipboard.writeText(text)
    onSuccess?.()
  } catch (error) {
    console.error(error)
    onError?.()
  }
}

export function promiseRejectDelay(delay: number) {
  return new Promise((_, reject) => setTimeout(() => reject(), delay))
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export function enhanceBuilder<
  TQuery extends string,
  TReducer extends string,
  TUri extends keyof paths,
>(builder: EndpointBuilder<BaseQueryFn, TQuery, TReducer>, uri: TUri) {
  return builder.query<OpenApiResponseType<TUri>, string>({
    query: () => uri,
  })
}

export function wrapStringEllipsis(input: string, maxLength: number) {
  if (maxLength < 3) {
    throw new Error('Max length must be greater than 3')
  }

  if (input.length > maxLength) {
    return input.substring(0, maxLength - 3) + '...'
  }

  return input
}

export function parseEnum<T extends Record<string, string | number>>(
  enumType: T,
  value: string | number
): T[keyof T] {
  const enumKey = Object.keys(enumType).find((key) => enumType[key] === value)

  if (enumKey) {
    return enumType[enumKey] as T[keyof T]
  } else {
    throw new Error(`Invalid value '${value}' for enum`)
  }
}

export function saveFile(data: Blob, filename: string) {
  const url = window.URL.createObjectURL(data)
  const a = document.createElement('a')
  a.href = url
  a.download = filename
  document.body.appendChild(a)
  a.click()
  a.remove()
}

export function id<T>(x: T) {
  return x
}

export const genericMemo: <T>(component: T) => T = memo

export function extractValueFromAction<T>(prevValue: T, actionArg: Parameters<SetAction<T>>[0]) {
  if (isFunction(actionArg)) {
    return actionArg(prevValue)
  } else {
    return actionArg
  }
}
