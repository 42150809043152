import React, {Ref} from 'react'
import clsx from 'clsx'
import {Calendar as CalendarIcon} from '@x5-react-uikit/icons'
import {BodyCellInnerDateViewProps} from './types'
import {defaultDateFormat} from '../../../Datepicker'
import {BodyCellEditIcon} from '../CellEditIcon'
import {defaultCellDensity, getCellDensityClassNameBy} from '../../helpers'
import {TableCellLabel} from '../../CellLabel'
import {useBaseCellStyles} from '../../Cell/styles'
import dayjs from 'dayjs'

const BodyCellInnerDateViewBase = (props: BodyCellInnerDateViewProps, ref: Ref<HTMLDivElement>) => {
  const {density = defaultCellDensity, noWrap, editable, onStartEdit, children: value} = props

  const format = (val) => dayjs(val).format(defaultDateFormat)
  const renderValue = value ? format(value) : ''

  const baseClasses = useBaseCellStyles()
  const densityClassName = baseClasses[getCellDensityClassNameBy(density)]

  return (
    <div ref={ref} className={clsx(baseClasses.innerRoot, densityClassName)}>
      <TableCellLabel bodyCell density={density} noWrap={noWrap}>
        {renderValue}
      </TableCellLabel>
      {editable && <BodyCellEditIcon Icon={CalendarIcon} type="date" onClick={onStartEdit} />}
    </div>
  )
}

export const BodyCellInnerDateView = React.forwardRef(BodyCellInnerDateViewBase)

export default BodyCellInnerDateView
