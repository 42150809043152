import {restyled} from '../../styles'
import {getBorderColorTable} from '../helpers'
import {DataGridHeadProps} from './types'

export const StyledHead = restyled.thead<DataGridHeadProps>(
  ({theme}) => ({
    borderBottomWidth: 2,
    whiteSpace: 'nowrap',
    textAlign: 'left',
    color: theme.colors.grey[100],
  }),
  ({theme: {colors}, sticked}) =>
    sticked
      ? {
          position: 'relative',
          zIndex: 10,
          '& tr > th': {
            position: 'sticky',
            top: 0,
            backgroundColor: colors.white,
            '&::before': {
              position: 'absolute',
              top: -1,
              right: 0,
              left: 0,
              content: '""',
              height: 1,
              backgroundColor: getBorderColorTable(colors),
            },
            '&::after': {
              position: 'absolute',
              bottom: -2,
              right: 0,
              left: 0,
              content: '""',
              height: 2,
              backgroundColor: getBorderColorTable(colors),
            },
          },
        }
      : null
)
