import {createApi} from '@reduxjs/toolkit/query/react'
import redirectLoginOn401Query from './queries/redirectLoginOn401Query'
import {withQueryParams} from './utils'

const tags = ['SystemMetaData']

const basePath = (systemId) => `/systems/${systemId}/metadata`

export const systemMetaDataApi = createApi({
  reducerPath: 'systemMetaDataApi',
  tagTypes: tags,
  baseQuery: redirectLoginOn401Query(),
  endpoints: (build) => ({
    getSystemMetaData: build.query({
      query: ({systemId}) => basePath(systemId),
      /** *
       * @typedef {Object} MetaData
       * @property {Number} gitlabProjectId
       * @property {string} gitlabProjectName
       * @property {String} gitlabRepoUrl
       * @property {String} gitlabBranch
       * @property {String} templateId
       * @property {Object[]} variables
       * @property {Boolean} isFilled
       *
       * @returns {MetaData}
       */
      transformResponse(response) {
        response.isFilled =
          Number.isInteger(response?.gitlabProjectId) &&
          response?.gitlabRepoUrl &&
          response?.gitlabBranch
        return response
      },
      providesTags: tags,
    }),
    getGitlabProjects: build.query({
      query: ({systemId, projectURL}) => {
        // Убираем из query-параметра http://
        const projectPath = projectURL.replace(/(^\w+:|^)\/\//, '')
        return withQueryParams(`${basePath(systemId)}/projects`, {projectURL: projectPath})
      },
    }),
    getGitlabBranches: build.query({
      query: ({systemId, projectId}) =>
        withQueryParams(`${basePath(systemId)}/branches`, {projectId}),
    }),
    createSystemMetaData: build.mutation({
      query: ({systemId, createData}) => ({
        url: basePath(systemId),
        method: 'POST',
        body: createData,
      }),
      invalidatesTags: tags,
    }),
    updateSystemMetaData: build.mutation({
      query: ({systemId, updateData}) => ({
        url: basePath(systemId),
        method: 'PATCH',
        body: updateData,
      }),
      invalidatesTags: tags,
    }),
  }),
})

export const {
  useGetSystemMetaDataQuery,
  useGetGitlabBranchesQuery,
  useGetGitlabProjectsQuery,
  useCreateSystemMetaDataMutation,
  useUpdateSystemMetaDataMutation,
} = systemMetaDataApi
