import React, {forwardRef, useState, useEffect, useCallback} from 'react'
import {SwitchProps, switchSizes} from './types'
import {getQaAttribute} from '../utils'
import {StyledSwitch, switchClasses} from './styles'

export const Switch = forwardRef<HTMLLabelElement, SwitchProps>(
  (
    {
      label,
      size = switchSizes.medium,
      checked,
      halfOn = false,
      disabled,
      className,
      qa = 'switch',
      onChange,
      ...props
    },
    ref
  ) => {
    const indeterminate = halfOn || checked === 'halfOn'
    const [isHalfOn, setIsHalfOn] = useState(indeterminate)
    const getQA = getQaAttribute(qa)

    useEffect(() => {
      if (halfOn) {
        console.warn(
          '%cWarning %c[@x5-react-uikit/core][Switch] %cprop halfOn is deprecated; use checked="halfOn"',
          'font-weight: bold; color: red',
          'font-weight: bold;',
          'font-weight: normal;'
        )
      }
      /* eslint-disable  */
    }, [])

    const onChangeSwitch: SwitchProps['onChange'] = useCallback(
      (event) => {
        if (isHalfOn) {
          setIsHalfOn(false)
        }
        onChange?.(event)
      },
      [onChange, isHalfOn]
    )

    return (
      <StyledSwitch
        ref={ref}
        data-qa={getQA()}
        className={className}
        size={size}
        checked={checked}
        disabled={disabled}
        indeterminate={indeterminate}
      >
        <input
          data-qa={getQA('input', [
            checked === true && 'checked',
            indeterminate && 'indeterminate',
            disabled && 'disabled',
          ])}
          className={switchClasses.input}
          type="checkbox"
          checked={indeterminate ? false : (checked as boolean)}
          disabled={disabled}
          onChange={onChangeSwitch}
          {...props}
        />
        <span className={switchClasses.label}>{label}</span>
      </StyledSwitch>
    )
  }
)

export default Switch
