import {useEffect, useState} from 'react'
import {debounce} from '@mui/material'

const useFilterUsers = ({pageNumber, pageSize, getFormValue, watchFormValue}) => {
  const [filter, setFilter] = useState({pageNumber, pageSize})

  useEffect(() => setFilter((prev) => ({...prev, pageNumber})), [pageNumber])
  useEffect(() => setFilter((prev) => ({...prev, pageSize})), [pageSize])

  useEffect(() => {
    const onFormUpdate = (value, change) => {
      const cb = (value) => {
        if (value.filters.role) {
          if (!value.filters.role.value?.length) delete value.filters.role
          else if (!value.filters.role.type) value.filters.role.type = 'EQUAL'
        }
        if (value.filters.email) {
          if (!value.filters.email?.value) delete value.filters.email
          else if (!value.filters.email?.type) value.filters.email.type = 'LIKE'
        }
        setFilter((prev) => ({...prev, ...structuredClone(value)}))
      }
      return change.name === 'filters.email.value' ? debounce(cb, 1000)(value) : cb(value)
    }
    const subscription = watchFormValue(onFormUpdate)
    return () => subscription.unsubscribe()
  }, [watchFormValue, getFormValue])

  return {filter}
}

export default useFilterUsers
