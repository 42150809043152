import {Modal, Typography, baseTheme} from '@x5-react-uikit/core'
import {useParams} from 'react-router-dom'

const {typography} = baseTheme

const CancelModal = ({isOpen, onSubmit, onClose}) => {
  const {configurationId} = useParams()

  return (
    <Modal
      closeOnOverlay
      buttons={
        configurationId
          ? [
              {
                onClick: onClose,
                side: 'right',
                text: 'Продолжить редактирование',
              },
              {
                onClick: onSubmit,
                side: 'right',
                text: 'Отменить редактирование',
              },
            ]
          : [
              {
                onClick: onClose,
                side: 'right',
                text: 'Отмена',
              },
              {
                onClick: onSubmit,
                side: 'right',
                text: 'Удалить конфигурацию',
              },
            ]
      }
      isOpen={isOpen}
      size="medium"
      title="Отменить создание конфигурации?"
      onClose={onClose}
    >
      <Typography variant="p1">
        <span>Все внесенные изменения </span>
        {configurationId ? (
          <span style={typography.h4}>не будут сохранены. </span>
        ) : (
          <>
            <span style={typography.h4}>не будут сохранены. Конфигурация будет удалена. </span>
            <span>Вы можете создать ее повторно, заполнив данные заново.</span>
          </>
        )}
      </Typography>
    </Modal>
  )
}

export default CancelModal
