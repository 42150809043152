import {useAuthGuard} from '@root/hooks/useAuthGuard'
import {useCanEditReport} from './useCanEditReport'
import type {Report} from '../types'

export const useEditReportGuard = (report?: Report) => {
  const canEdit = useCanEditReport(report)

  useAuthGuard({
    redirectUri: '/reports',
    hasAccess: canEdit,
  })
}
