import {ComponentType, Dispatch, SetStateAction} from 'react'
import {InputSize} from '../Input'

export enum filterCollectorColumnType {
  select = 'select',
  combobox = 'combobox',
  multiselect = 'multiselect',
  text = 'text',
  date = 'date',
  period = 'period',
  custom = 'custom',
}

export type FilterCollectorValue = (number | string)[] | string | number

export enum FilterCollectorCondition {
  equal = 'equal',
  in = 'in',
  notIn = 'notIn',
}

export type CustomFilterInputProps = {
  onChange: (newValue: FilterCollectorValue) => void
  value: FilterCollectorValue
  size?: InputSize
}

export interface FilterCollectorColumn {
  value: string
  name: string
  disabled?: boolean
  type?: keyof typeof filterCollectorColumnType
  customInputComponent?: ComponentType<CustomFilterInputProps>
  data?: {name: string; value: string | number}[]
  conditionColumns?: {name: string; value: FilterCollectorCondition}[] | null
}

export interface FilterCollectorFilter {
  name: string
  value: FilterCollectorValue
  condition?: keyof typeof FilterCollectorCondition
}

export interface FilterCollectorProps {
  columns: FilterCollectorColumn[]
  filters: FilterCollectorFilter[]
  onChange: (newValues: FilterCollectorProps['filters']) => void
  onApply?: (newValues: FilterCollectorProps['filters']) => void
  onReset?: () => void
  size?: InputSize
}

export interface FilterCollectorItemProps
  extends Pick<FilterCollectorProps, 'columns' | 'filters' | 'onChange' | 'size'> {
  filter: FilterCollectorFilter
  multiselectFilterValues: Record<string, string>
  setMultiselectFilterValues: Dispatch<
    SetStateAction<FilterCollectorItemProps['multiselectFilterValues']>
  >
}
