import {Typography} from '@x5-react-uikit/core'
import {ReactComponent as EmptySvg} from 'assets/Empty.svg'
import FlexboxColumn from '@root/components/FlexboxColumn'

const LaunchesEmptyMessage = () => {
  return (
    <FlexboxColumn
      sx={{mt: 'x12', alignItems: 'center', justifyContent: 'center', height: '462px'}}
    >
      <EmptySvg />
      <FlexboxColumn sx={{mt: 'x20', gap: '8px', alignItems: 'center'}}>
        <Typography variant="h4">Запуски пока отсутствуют.</Typography>
        {/* <Typography variant="p1" style={{textAlign: 'center'}}>
          Вы можете создать заявку на проведение нагрузочного<br />тестирования,
          нажав на кнопку выше, и отправить<br />ее на рассмотрение.
        </Typography> */}
      </FlexboxColumn>
    </FlexboxColumn>
  )
}

export default LaunchesEmptyMessage
