import {Dayjs} from 'dayjs'
import {CalendarBlockProps} from '../Block/types'
import {CalendarDropdownProps} from '../Dropdown/types'

export function getMonths({
  date,
  dateFormat,
  minDate,
  maxDate,
}: Pick<CalendarBlockProps, 'dateFormat' | 'minDate' | 'maxDate'> & {
  date: Dayjs
}): CalendarDropdownProps['options'] {
  const month = date.month()
  const year = date.year()

  const minMonth = minDate.month()
  const maxMonth = maxDate.month()
  const minYear = minDate.year()
  const maxYear = maxDate.year()

  return [
    {name: 'Январь', value: 0},
    {name: 'Февраль', value: 1},
    {name: 'Март', value: 2},
    {name: 'Апрель', value: 3},
    {name: 'Май', value: 4},
    {name: 'Июнь', value: 5},
    {name: 'Июль', value: 6},
    {name: 'Август', value: 7},
    {name: 'Сентябрь', value: 8},
    {name: 'Октябрь', value: 9},
    {name: 'Ноябрь', value: 10},
    {name: 'Декабрь', value: 11},
  ].map((item) => ({
    name: item.name,
    value: date.month(item.value).format(dateFormat),
    selected: item.value === month,
    unit: 'month',
    disabled:
      (year === maxYear && item.value > maxMonth) || (year === minYear && item.value < minMonth),
  }))
}

export function getYears({
  date,
  dateFormat,
  minDate,
  maxDate,
}: Pick<CalendarBlockProps, 'dateFormat' | 'minDate' | 'maxDate'> & {
  date: Dayjs
}): CalendarDropdownProps['options'] {
  const years = []
  const minYear = minDate.year()
  const maxYear = maxDate.year()
  const currentYear = date.year()

  for (let i = minYear; i <= maxYear; i++) {
    years.push({
      name: i,
      value: minDate.year(i).format(dateFormat),
      selected: i === currentYear,
      unit: 'year',
    })
  }

  return years
}
