import {PREFIX, restyled, doSelectors} from '../styles'
import {FileUploaderProps} from './types'

export const uploaderClasses = {
  root: `${PREFIX}-uploader-root`,
  wrap: `${PREFIX}-uploader-wrap`,
  preview: `${PREFIX}-uploader-preview`,
  info: `${PREFIX}-uploader-info`,
  icon: `${PREFIX}-uploader-icon`,
  label: `${PREFIX}-uploader-label`,
  link: `${PREFIX}-uploader-link`,
  input: `${PREFIX}-uploader-input`,
} as const

const selectors = doSelectors(uploaderClasses)

export const StyledUploader = restyled.div<
  FileUploaderProps & {overed?: boolean; cssWidth: FileUploaderProps['width']}
>(({theme: {spaces, colors, typography}, disabled, error, cssWidth, overed}) => ({
  minWidth: 'min-content',
  maxWidth: cssWidth,
  [selectors.wrap]: {
    position: 'relative',
    boxSizing: 'border-box',
    padding: spaces.join('x12', 'x12', 'x0'),
    width: '100%',
    minHeight: 120,
    textAlign: 'center',
    borderStyle: 'dashed',
    borderWidth: 1,
    backgroundColor: overed ? colors.accent[5] : colors.white,
    borderColor: overed ? colors.accent[90] : error ? colors.error : colors.grey[30],
    borderRadius: spaces.x2,
    ':hover': {
      backgroundColor: !disabled ? colors.grey[5] : null,
      borderColor: !disabled ? colors.grey[40] : null,
    },
  },
  [selectors.root]: {
    marginBottom: spaces.x4,
    color: colors.grey[disabled ? 40 : 100],
    ...typography.p2,
  },
  [selectors.icon]: {
    marginBottom: spaces.x4,
    color: disabled ? colors.grey[40] : colors.accent[90],
  },
  [selectors.info]: {
    color: colors.grey[disabled ? 40 : 60],
    ...typography.p3,
  },
  [selectors.label]: {
    position: 'absolute',
    zIndex: 0,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    cursor: disabled ? 'no-drop' : 'pointer',
  },
  [selectors.link]: {
    lineHeight: 1,
  },
  [selectors.input]: {
    display: 'none',
  },
  [selectors.preview]: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    margin: spaces.join('x6', 'x0'),
  },
}))
