import {Box, baseTheme} from '@x5-react-uikit/core'

const Paper = ({children, sx}) => {
  return (
    <Box
      sx={{
        boxShadow: baseTheme.shadows.medium,
        borderRadius: 'x4',
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

export default Paper
