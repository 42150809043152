import {useEffect} from 'react'

export type TeardownParams = {
  onUnmount?: () => void
  onUnload?: (event: BeforeUnloadEvent) => void
}

export const useTeardownEffect = ({onUnmount, onUnload}: TeardownParams) => {
  useEffect(() => {
    return () => {
      if (onUnmount) {
        onUnmount()
      }
    }
  }, [onUnmount])

  useEffect(() => {
    const handleUnload = (event: BeforeUnloadEvent) => {
      if (onUnload) {
        onUnload(event)
      }
    }

    if (onUnload) {
      window.addEventListener('beforeunload', handleUnload)
    }

    return () => {
      if (onUnload) {
        window.removeEventListener('beforeunload', handleUnload)
      }
    }
  }, [onUnload])
}
