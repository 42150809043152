import React, {
  forwardRef,
  useState,
  ComponentType,
  CSSProperties,
  HTMLAttributes,
  useMemo,
  useCallback,
  ReactNode,
} from 'react'
import clsx from 'clsx'
import {PlacementTokenValues, SizeTokenValue, sizeTokenValues} from '@x5-react-uikit/tokens'
import {SortAscIcon, SortDescIcon, SelectList, sortDirections} from 'ui-kit'

import {
  StyledHeadCell,
  StyledHeadCellAction,
  StyledHeadCellWrap,
  classes,
  DropdownContent,
} from './styles'

import type {ExtractConstValues} from 'core/helperTypes'
import type {SelectListPropsOnChange} from '@root/components/kit'

import {SortTextKind} from '../../internalTypes'
import {sortTextKindMap} from '../../constants'
import {ArrowsButton} from './ArrowsButton'
import {
  autoUpdate,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStyles,
} from '@floating-ui/react'
import {usePreviousValue} from 'shared'
import CellTypography from '@root/components/dataGridTable/CellTypography'

type SortDirections = ExtractConstValues<typeof sortDirections>

type OnChangeAction = (name: string, sortDirection: SortDirections) => void

export type AppTableHeadCellProps = {
  title: ReactNode
  name: string
  sortName?: string
  placement?: PlacementTokenValues
  qa?: string
  icon?: ComponentType<{className: string; size: SizeTokenValue}>
  width?: CSSProperties['width']
  titleStyle?: CSSProperties
  noSideBorders?: boolean
  sortOptions?: SortTextKind
  sortValue?: SortDirections
  onSortChange?: OnChangeAction
  style?: CSSProperties
} & Omit<HTMLAttributes<HTMLTableCellElement>, 'onChange' | 'title'>

export const AppTableHeadCell = forwardRef<HTMLTableCellElement, AppTableHeadCellProps>(
  (
    {
      placement = 'bottom-start',
      title,
      name,
      sortName,
      qa = 'head-cell', // TODO: NOT USED
      icon,
      sortOptions,
      onSortChange,
      width,
      style,
      noSideBorders,
      sortValue = 'unset',
      ...props
    },
    ref
  ) => {
    const [isOpen, setOpen] = useState(false)
    const prevSort = usePreviousValue(sortValue)

    const handleSelectChange: SelectListPropsOnChange = useCallback(
      (_event, value) => {
        const sortValue = value
        if (sortValue === sortDirections.asc || sortValue === sortDirections.desc) {
          const next = prevSort === sortValue ? 'unset' : sortValue

          onSortChange?.(sortName, next)
        }

        setOpen(false)
      },
      [onSortChange, prevSort, sortName]
    )

    const handleActionClick = useCallback(() => setOpen((prev) => !prev), [setOpen])

    const {floatingStyles, refs, context} = useFloating({
      open: isOpen,
      onOpenChange: setOpen,
      strategy: 'absolute',
      placement,
      middleware: [shift()],
      whileElementsMounted: autoUpdate,
    })

    const {styles: transitionStyles, isMounted} = useTransitionStyles(context, {
      duration: 150,
    })

    const click = useClick(context)
    const dismiss = useDismiss(context)

    const {getReferenceProps, getFloatingProps} = useInteractions([click, dismiss])

    const renderIcon = () => {
      if (icon) {
        const Icon = icon
        return <Icon className={classes.start} size={sizeTokenValues.small} />
      }
    }

    const listItems = useMemo(() => {
      if (sortOptions == null) {
        return null
      }
      const text = sortTextKindMap[sortOptions]

      return [
        {
          value: sortDirections.asc,
          name: text.ascending,
          label: 'СОРТИРОВКА',
          icon: <SortAscIcon size={sizeTokenValues.small} />,
        },
        {
          value: sortDirections.desc,
          name: text.descending,
          icon: <SortDescIcon size={sizeTokenValues.small} />,
        },
      ]
    }, [sortOptions])

    const titleNode = (
      <CellTypography noPadding typographyStyle={{fontWeight: 500}}>
        {title}
      </CellTypography>
    )

    return (
      <StyledHeadCell
        ref={ref}
        className={clsx(noSideBorders && 'no-side-borders')}
        data-name={name}
        style={{
          ...style,
          ...(width ? {width} : {}),
        }}
        {...props}
      >
        <StyledHeadCellWrap style={{width}}>
          {renderIcon()}
          {sortOptions != null ? (
            <>
              <StyledHeadCellAction
                paddingX={width ? '12px' : undefined}
                onClick={handleActionClick}
              >
                {titleNode}
                <ArrowsButton
                  ref={refs.setReference}
                  dir={sortValue}
                  open={isOpen}
                  {...getReferenceProps()}
                />
              </StyledHeadCellAction>

              {isMounted && (
                <DropdownContent
                  ref={refs.setFloating}
                  style={{...floatingStyles, ...transitionStyles}}
                  {...getFloatingProps()}
                >
                  <SelectList
                    items={listItems}
                    selected={sortValue}
                    onChange={handleSelectChange}
                  />
                </DropdownContent>
              )}
            </>
          ) : (
            titleNode
          )}
        </StyledHeadCellWrap>
      </StyledHeadCell>
    )
  }
)

export default AppTableHeadCell
