import React, {FC} from 'react'
import {defineColors} from '../utils/defineColors'
import {LogoProps} from '../types'

const primary = '#5faf2d'
const getPathColor = defineColors('#000000')

const Logo: FC<LogoProps> = ({color, ...props}) => (
  <svg fill="none" viewBox="0 0 336 100" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M198.43 52.2188H167.41V67.4805H183.187C195.852 67.4805 202.374 72.9471 202.374 82.6665C202.374 93.9044 194.259 98.9147 181.138 98.9147H160.508V45.7656H198.43V52.2188ZM167.41 73.9337V92.4598H181.67C190.467 92.4598 195.169 89.4984 195.169 82.7405C195.169 76.8934 191.15 73.9337 182.124 73.9337H167.41Z"
      fill={getPathColor(color)}
    />
    <path
      d="M208.898 69.5317C211.4 62.6222 217.695 58.75 226.189 58.75C236.884 58.75 242.269 64.3681 242.269 75.606V98.9153H235.898V92.0799C233.85 96.3326 227.784 99.5971 221.412 99.5971C213.22 99.5971 207.683 94.8898 207.683 87.8288C207.683 78.7948 215.495 75.3771 235.897 75.3771C235.897 68.0888 232.787 64.7469 226.036 64.7469C220.12 64.7469 216.252 67.1005 214.659 71.6563L208.898 69.5317ZM235.898 82.0592V80.6164C219.668 80.6164 214.358 82.438 214.358 87.8288C214.358 91.3981 217.544 93.6002 222.702 93.6002C230.438 93.5985 235.898 88.8154 235.898 82.0592Z"
      fill={getPathColor(color)}
    />
    <path
      d="M282.239 81.8301H259.107V98.9134H252.736V59.4316H259.107V75.8314H282.239V59.4316H288.61V98.9134H282.239V81.8301Z"
      fill={getPathColor(color)}
    />
    <path
      d="M299.989 59.4316H306.359V77.1228L325.927 59.4316H334.877L314.475 77.5773L335.862 98.9134H326.912L309.62 81.527L306.359 84.5642V98.9151H299.989V59.4316Z"
      fill={getPathColor(color)}
    />
    <path
      d="M93.5455 98.9143L82.5467 80.6154L71.7767 98.9143H58.8826L75.4487 71.3506L58.5644 45.7617H72.0347L82.8511 62.8468L93.1671 45.7617H106.061L89.4504 72.1856L106.517 98.9143H93.5455Z"
      fill={getPathColor(color)}
    />
    <path
      d="M148.431 80.8465C148.431 92.5408 139.632 99.829 128.103 99.829C117.939 99.829 109.384 94.2109 108.55 81.9846H119.549C120.307 87.2997 124.555 89.5776 129.182 89.5776C134.947 89.5776 137.434 85.7054 137.434 80.6933C137.434 75.454 133.566 72.1895 128.181 72.1895C123.706 72.1895 120.747 74.3159 119.382 76.5938H108.87L112.502 45.7656H147.077L145.925 56.0928H121.191L120.209 66.2942C120.209 66.2942 122.806 63.2312 130.68 63.2312C140.163 63.2295 148.431 69.6034 148.431 80.8465Z"
      fill={getPathColor(color)}
    />
    <path
      d="M109.85 39.1444C103.99 16.2811 85.4034 0.21875 46.8312 0.21875H0.259705L6.58206 43.0132C11.3376 81.6289 26.0909 94.0497 52.1973 98.6261C52.3194 98.6451 53.145 98.7621 53.157 97.7945C53.1604 97.445 52.9403 97.1454 52.6376 97.0163C34.9914 89.5783 32.9774 65.186 29.2074 40.7094L26.2904 21.2019H52.8749C77.3853 21.2019 96.3093 20.8145 108.29 39.7556C108.469 40.0207 108.746 40.2188 109.074 40.2188C109.537 40.2188 109.883 39.8434 109.883 39.3803C109.885 39.2959 109.872 39.2167 109.85 39.1444Z"
      fill={getPathColor(color, primary)}
    />
  </svg>
)

export default Logo
