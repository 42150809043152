import {makeUiStyles} from '../../theme'

export const useStyles = makeUiStyles(
  (theme) => ({
    open: null,
    root: {
      borderColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
        borderColor: theme.colors.grey[40],
      },
      // Таким образом мы получаем приоритет над стилями :hover
      '&$open': {
        borderColor: theme.colors.accent[90],
      },
    },
  }),
  'calendar-dropdown'
)
