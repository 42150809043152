import {Modal, Typography, baseTheme} from '@x5-react-uikit/core'

const {typography} = baseTheme

const CancelTaskModal = ({isOpen, onSave, onRemove, onClose}) => {
  return (
    <Modal
      closeOnOverlay
      buttons={[
        {
          onClick: () => {
            onSave()
            onClose()
          },
          side: 'right',
          text: 'Сохранить заявку',
        },
        {
          onClick: onRemove,
          side: 'right',
          text: 'Удалить заявку',
        },
      ]}
      isOpen={isOpen}
      size="medium"
      title="Отменить создание заявки?"
      onClose={onClose}
    >
      <Typography variant="p1">
        <span>Все внесенные изменения </span>
        <span style={typography.h4}>не будут сохранены. Заявка будет удалена. </span>
        <span>
          Вы можете сохранить ее в качестве Черновика, чтобы вернуться к заполнению позже.
        </span>
      </Typography>
    </Modal>
  )
}

export default CancelTaskModal
