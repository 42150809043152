import {keyframes} from '@emotion/react'
import {restyled, PREFIX} from '../styles'
import {LoaderProps} from './types'

export const classes = {
  item: `${PREFIX}-loader-item`,
}

export const progressKeyframe = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`
export const dashKeyframe = keyframes`
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }
  
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
`

export const StyledRoot = restyled.div(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  userSelect: 'none',
  color: theme.colors.accent[90],
}))

export const StyledSvg = restyled.svg({
  display: 'block',
})

export const StyledCircle = restyled.circle({
  stroke: 'currentColor',
  strokeDasharray: '80px, 200px',
  strokeDashoffset: 0,
  animation: `${dashKeyframe} 1.4s ease-in-out infinite`,
})

export const StyledContainer = restyled.div<LoaderProps>(({size}) => ({
  display: 'inline-block',
  width: size,
  height: size,
  animation: `${progressKeyframe} 1.4s ease-in-out infinite`,
}))

export const StyledMessage = restyled.div(({theme}) => ({
  marginTop: theme.spaces.x4,
  padding: theme.spaces.x4,
  color: theme.colors.grey[100],
  ...theme.typography.p1,
}))

export const StyledInner = restyled.div(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [`.${classes.item} &`]: {
    marginRight: theme.spaces.x5,
  },
}))

export const StyledItem = restyled.div(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spaces.join('x3', 'x6'),
  color: theme.colors.grey[40],
}))

export const StyledBlock = restyled.div(({theme}) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  backgroundColor: theme.colors.white,
  zIndex: theme.zIndex.loader,
}))
