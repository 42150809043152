import {forwardRef, createElement, ComponentPropsWithRef, FC} from 'react'
import {LinkProps} from './types'
import {StyledLink} from './styles'
import {Link as LinkDom} from 'react-router-dom'

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({children, qa = 'link', component, ...props}, ref) =>
    createElement(
      StyledLink,
      {
        ...props,
        ref,
        as: component || 'a',
        'data-qa': qa,
      },
      children
    )
)

export default Link
