import {makeUiStyles} from '../../theme'
import {getCellDensityClassNameBy, getTableCellDensitySize} from '../helpers'
import {TableCellDensity} from '../types'

export const useBaseCellStyles = makeUiStyles(
  (theme) => ({
    // density
    [getCellDensityClassNameBy(TableCellDensity.s)]: {
      padding: getTableCellDensitySize(TableCellDensity.s),
      minHeight: '16px',
      minWidth: '16px',
      '--density': getTableCellDensitySize(TableCellDensity.s),
    },
    [getCellDensityClassNameBy(TableCellDensity.m)]: {
      padding: getTableCellDensitySize(TableCellDensity.m),
      minHeight: '16px',
      minWidth: '16px',
      '--density': getTableCellDensitySize(TableCellDensity.m),
    },
    [getCellDensityClassNameBy(TableCellDensity.l)]: {
      padding: getTableCellDensitySize(TableCellDensity.l),
      minHeight: '24px',
      minWidth: '24px',
      '--density': getTableCellDensitySize(TableCellDensity.l),
    },

    // inner
    innerPortal: {
      boxShadow: 'none',
      background: 'none',
    },
    innerRoot: {
      display: 'flex',
      boxShadow: 'none',
    },
  }),
  'table-base-cell'
)

const getBorderColor = (theme) => theme.colors.grey[10]

const getBaseCellRoot = (theme) => ({
  display: 'table-cell',
  border: '1px solid',
  borderColor: getBorderColor(theme),
  background: theme.colors.white,
  verticalAlign: 'top',
  textAlign: 'left',
  boxSizing: 'border-box',
  lineHeight: 0, // for clear spacing bugs
  outline: 'none',
})

const getBoldBorder = (theme) => ({
  border: `2px solid ${theme.colors.accent[90]}!important`,
  borderRadius: theme.spaces.x2,
})

const getCellFont = (theme) => ({
  ...theme.typography.p2,
  letterSpacing: 'normal',
})

const getInnerInputWrap = (theme) => ({
  display: 'block',
  boxSizing: 'border-box',
  background: theme.colors.white,
  outline: 'none',
  minHeight: '100%',
  ...getBoldBorder(theme),
  position: 'relative',
  left: '-1px',
  top: '-1px',
})

const getFakeInnerInputWrap = (theme) => ({
  border: 'none',
  top: '1px',
  left: '1px',
})

const getCellBlocked = (theme) => ({
  position: 'sticky',
  // :after for filling the holes between cells
  '&:after': {
    position: 'absolute',
    width: '100%',
    height: '100%',
    content: '""',
    display: 'block',
    pointerEvents: 'none',
    border: '1px solid',
    borderColor: getBorderColor(theme),
    left: '-1px',
    top: '-1px',
  },
})

export const TableStyleHelpers: Record<string, any> = {
  getBaseCellRoot,
  getBoldBorder,
  getBorderColor,
  getCellFont,
  getInnerInputWrap,
  getFakeInnerInputWrap,
  getCellBlocked,
}
