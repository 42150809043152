import {Typography} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import {ReactComponent as ErrorSvg} from 'assets/Error.svg'
import {ReactComponent as EmptySvg} from 'assets/Empty.svg'
import FlexboxRow from '@root/components/FlexboxRow'
import {FC} from 'react'
import {BoxProps} from 'ui-kit'

type Props = {
  sx?: BoxProps['sx']
  message?: string
  description?: string
}

export const ErrorTableStub: FC<Props> = ({
  sx,
  message = 'Произошла непредвиденная ошибка',
  description = 'Что-то пошло не так. Пожалуйста, перезагрузите страницу.',
}) => {
  return (
    <FlexboxColumn sx={{mt: 'x12', alignItems: 'center', justifyContent: 'center', ...sx}}>
      <FlexboxRow sx={{alignItems: 'end'}}>
        <ErrorSvg style={{position: 'absolute'}} />
        <EmptySvg />
      </FlexboxRow>
      <FlexboxColumn sx={{mt: 'x20', gap: '8px', alignItems: 'center'}}>
        <Typography variant="h4">{message}</Typography>
        <Typography style={{textAlign: 'center'}} variant="p1">
          {description}
        </Typography>
      </FlexboxColumn>
    </FlexboxColumn>
  )
}

export default ErrorTableStub
