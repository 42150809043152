import React from 'react'
import clsx from 'clsx'
import {UITypography} from '../theme'
import {useTypographyStyles} from './styles'

export type TypographyVariant = keyof UITypography

export interface TypographyProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: TypographyVariant
}

export const Typography = React.forwardRef<HTMLDivElement, TypographyProps>((props, ref) => {
  const classes = useTypographyStyles()
  const {variant = 'p1', className, children, ...otherProps} = props

  return (
    <div {...otherProps} ref={ref} className={clsx(className, classes.base, classes[variant])}>
      {children}
    </div>
  )
})

export default Typography
