import React, {FC} from 'react'
import {defineColors} from '../utils/defineColors'
import {LogoProps} from '../types'

const primary = '#5faf2d'
const getPathColor = defineColors('#000000')

const Logo: FC<LogoProps> = ({color, ...props}) => (
  <svg fill="none" viewBox="0 0 323 100" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M140.498 40.0469H146.539V62.4812L170.039 40.0469H177.94L156.033 61.0878L179.533 86.5089H171.568L151.519 65.0693L146.54 69.8482V86.5089H140.5V40.0469H140.498Z"
      fill={getPathColor(color)}
    />
    <path
      d="M183.119 60.8235C185.31 54.7825 190.82 51.3984 198.254 51.3984C207.615 51.3984 212.328 56.3103 212.328 66.1341V86.5104H206.752V80.5367C204.96 84.2539 199.648 87.1078 194.072 87.1078C186.902 87.1078 182.056 82.9934 182.056 76.8196C182.056 68.9208 188.895 65.934 206.752 65.934C206.752 59.5615 204.03 56.6419 198.121 56.6419C192.943 56.6419 189.558 58.6998 188.163 62.6828L183.119 60.8235ZM206.753 71.7748V70.5128C192.547 70.5128 187.9 72.1063 187.9 76.8181C187.9 79.9379 190.689 81.8629 195.203 81.8629C201.974 81.8629 206.753 77.6813 206.753 71.7748Z"
      fill={getPathColor(color)}
    />
    <path
      d="M227.068 81.267V99.7855H221.492V51.9958H227.068V58.1682C229.392 54.1195 233.773 51.3984 240.147 51.3984C249.574 51.3984 256.811 58.1024 256.811 68.988C256.811 79.7407 249.774 87.1078 239.682 87.1078C233.441 87.1078 229.523 84.917 227.068 81.267ZM250.967 69.1195C250.967 61.4208 245.989 56.6419 239.017 56.6419C231.715 56.6419 227.068 61.2207 227.068 68.7222V69.9842C227.068 78.0158 232.512 81.8659 239.216 81.8659C246.254 81.8629 250.967 76.8853 250.967 69.1195Z"
      fill={getPathColor(color)}
    />
    <path
      d="M259.266 51.9941H290.07V57.2376H277.389V86.5088H271.813V57.2376H259.266V51.9941Z"
      fill={getPathColor(color)}
    />
    <path
      d="M293.722 60.8235C295.913 54.7825 301.422 51.3984 308.858 51.3984C318.219 51.3984 322.931 56.3103 322.931 66.1341V86.5104H317.355V80.5367C315.563 84.2539 310.251 87.1078 304.675 87.1078C297.505 87.1078 292.66 82.9934 292.66 76.8196C292.66 68.9208 299.498 65.934 317.355 65.934C317.355 59.5615 314.634 56.6419 308.725 56.6419C303.546 56.6419 300.161 58.6998 298.766 62.6828L293.722 60.8235ZM317.355 71.7748V70.5128C303.149 70.5128 298.502 72.1063 298.502 76.8181C298.502 79.9379 301.29 81.8629 305.804 81.8629C312.575 81.8629 317.355 77.6813 317.355 71.7748Z"
      fill={getPathColor(color)}
    />
    <path
      d="M81.8852 86.5095L72.2583 70.5119L62.83 86.5095H51.5435L66.0429 62.413L51.2642 40.043H63.0555L72.5227 54.9788L81.5521 40.043H92.8386L78.2988 63.1433L93.2374 86.5095H81.8852Z"
      fill={getPathColor(color)}
    />
    <path
      d="M129.926 70.7129C129.926 80.9355 122.224 87.3079 112.133 87.3079C103.236 87.3079 95.7469 82.396 95.0164 71.7091H104.643C105.307 76.3551 109.026 78.3474 113.075 78.3474C118.121 78.3474 120.299 74.9617 120.299 70.5815C120.299 66.0012 116.913 63.1472 112.199 63.1472C108.282 63.1472 105.692 65.0065 104.497 66.9973H95.2958L98.4759 40.0469H128.74L127.73 49.0747H106.08L105.22 57.9934C105.22 57.9934 107.493 55.3157 114.385 55.3157C122.689 55.3127 129.926 60.8862 129.926 70.7129Z"
      fill={getPathColor(color)}
    />
    <path
      d="M96.1561 34.2593C91.0267 14.2712 74.7572 0.228516 40.9934 0.228516H0.228516L5.76271 37.6404C9.92568 71.3979 22.8388 82.2581 45.6911 86.2575C45.7971 86.2739 46.5216 86.377 46.5306 85.5302C46.5335 85.2255 46.3409 84.9627 46.0765 84.8492C30.63 78.3468 28.8674 57.0236 25.5678 35.6258L23.0151 18.5723H46.2856C67.7398 18.5723 84.305 18.2333 94.7909 34.7924C94.9462 35.0254 95.1897 35.1972 95.478 35.1972C95.8828 35.1972 96.1845 34.8686 96.1845 34.4639C96.186 34.3907 96.1755 34.322 96.1561 34.2593Z"
      fill={getPathColor(color, primary)}
    />
  </svg>
)

export default Logo
