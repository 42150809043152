import {Box, Typography} from '@x5-react-uikit/core'
import Breadcrumbs from '@root/components/Breadcrumbs'
import UsersTable from './components/UsersTable'

const AdminPage = () => {
  return (
    <Box sx={{mx: 'x8'}}>
      <Breadcrumbs routes={[{to: null, label: 'Главная'}]} />
      <Typography variant="h2">Пользователи</Typography>
      <Box sx={{my: 'x10'}}>
        <UsersTable />
      </Box>
    </Box>
  )
}

export default AdminPage
