import {
  ChangeEventHandler,
  FocusEventHandler,
  MouseEventHandler,
  KeyboardEventHandler,
  ReactElement,
  CSSProperties,
  Ref,
} from 'react'
import {sizeTokenValues} from '@x5-react-uikit/tokens'
import {IconSizes} from '@x5-react-uikit/icons'
import {QA} from '../types'

export const inputSizes = {
  small: sizeTokenValues.small,
  large: sizeTokenValues.large,
} as const

export type InputSize = keyof typeof inputSizes
type InputMode = 'text' | 'number' | 'tel' | 'url' | 'email' | 'search' | 'password'
type AdornmentSize = Extract<InputSize, IconSizes>

export interface InputProps extends QA {
  id?: string
  name?: string
  className?: string
  value?: string
  filled?: boolean
  width?: CSSProperties['width']
  size?: InputSize
  type?: InputMode
  required?: boolean
  disabled?: boolean
  loading?: boolean
  autoFocus?: boolean
  onClick?: MouseEventHandler
  onChange?: ChangeEventHandler<HTMLInputElement>
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>

  inputProps?: Record<string, unknown>
  inputRef?: Ref<HTMLInputElement>
  label: string
  caption?: string
  error?: boolean
  textError?: string
  unborder?: boolean
  forbidTyping?: boolean
  autoComplete?: string
  isAbsoluteCaption?: boolean
  endAdornment?: ReactElement
  startAdornment?: ReactElement

  password?: boolean
  search?: boolean
  clear?: boolean
  datePicker?: boolean
  datePickerRanged?: boolean
  passwordVisible?: boolean
  onClearClick?: MouseEventHandler
  onDateClick?: MouseEventHandler
  onPasswordVisibilityChange?: (visible: boolean) => void
}

export interface BaseAdornmentProps {
  disabled?: boolean
  size?: AdornmentSize
  color?: string
}

export interface StyledAdornmentProps {
  disabled: boolean
  interactive?: boolean
  preMatching?: boolean
}
