import X5KitProvider from './Provider'
import ErrorBoundary from './components/ErrorBoundary'
import './App.css'
import {AppRoutes} from './routes'

function App() {
  return (
    <X5KitProvider>
      <ErrorBoundary>
        <AppRoutes />
      </ErrorBoundary>
    </X5KitProvider>
  )
}

export default App
