import React, {FC} from 'react'
import {defaultCellDensity} from '../../helpers'
import BodyCellInnerNumberView from './View'
import BodyCellInnerNumberEdit from './Edit'
import {
  BodyCellInnerNumberDefaultChar,
  BodyCellInnerNumberDefaultScale,
  BodyCellInnerNumberEditProps,
  BodyCellInnerNumberProps,
} from './types'

export const BodyCellInnerNumber: FC<BodyCellInnerNumberProps> = (
  props: BodyCellInnerNumberProps
) => {
  const {
    density = defaultCellDensity,
    noWrap,
    editable,
    editing,
    onStartEdit,
    children: value,
    char = BodyCellInnerNumberDefaultChar,
    scale = BodyCellInnerNumberDefaultScale,
    signed = true,
    min = 0,
    max = Number.MAX_SAFE_INTEGER,
    isFraction = false,
    errorText,
  } = props

  return (
    <>
      <BodyCellInnerNumberView
        char={char}
        density={density}
        editable={editable}
        errorText={errorText}
        isFraction={isFraction}
        noWrap={noWrap}
        scale={scale}
        onStartEdit={onStartEdit}
      >
        {value}
      </BodyCellInnerNumberView>
      {editing && (
        <BodyCellInnerNumberEdit {...(props as BodyCellInnerNumberEditProps)}>
          {value}
        </BodyCellInnerNumberEdit>
      )}
    </>
  )
}

export default BodyCellInnerNumber
