import {PREFIX, restyled, doSelectors} from '../styles'
import {RadioProps} from './types'

export const radioClasses = {
  label: `${PREFIX}-radio-label`,
  input: `${PREFIX}-radio-input`,
} as const

const selectors = doSelectors(radioClasses)

export const StyledRadio = restyled.label<RadioProps>(
  ({theme: {colors, spaces, typography}, checked, disabled, error}) => {
    const outerSize = spaces.x8
    const innerSize = spaces.x3
    const offset = (outerSize - innerSize) / 2
    const borderWidth = 1

    const getStateStyles = (hovered?: boolean) => {
      if (checked && disabled) {
        return {
          borderColor: colors.accent[20],
          backgroundColor: colors.accent[20],
        }
      }

      if (!checked && disabled) {
        return {
          borderColor: error ? colors.error : colors.grey[20],
        }
      }

      if (error) {
        return {
          borderColor: colors.error,
          backgroundColor: colors.white,
        }
      }

      if (!hovered) {
        return {
          borderColor: checked ? colors.accent[90] : colors.grey[30],
          backgroundColor: checked ? colors.accent[90] : colors.white,
        }
      }

      return {
        backgroundColor: checked ? colors.accent[80] : colors.white,
        borderColor: checked ? colors.accent[80] : colors.grey[40],
      }
    }

    return {
      position: 'relative',
      display: 'flex',
      height: typography.p1compact.lineHeight,
      color: disabled ? colors.grey[40] : null,
      pointerEvents: disabled ? 'none' : null,
      userSelect: 'none',
      cursor: 'pointer',
      '::before': {
        position: 'absolute',
        top: 0,
        left: 0,
        boxSizing: 'border-box',
        content: '""',
        width: outerSize,
        height: outerSize,
        borderRadius: '50%',
        borderStyle: 'solid',
        borderWidth,
        ...getStateStyles(),
      },
      '::after': {
        position: 'absolute',
        top: offset,
        left: offset,
        content: '""',
        width: innerSize,
        height: innerSize,
        borderRadius: '50%',
        backgroundColor: colors.white,
      },
      ':hover::before': {
        ...getStateStyles(true),
      },
      [selectors.label]: {
        position: 'relative',
        top: -spaces.x1,
        marginLeft: outerSize + spaces.x4,
        ...typography.p1compact,
      },
      [selectors.input]: {
        position: 'absolute',
        visibility: 'hidden',
        opacity: 0,
        top: 0,
        left: 0,
        width: 1,
        height: 1,
      },
    }
  }
)
