import {makeUiStyles} from '../../theme'

export const useCellLabelStyles = makeUiStyles(
  () => ({
    label: {
      flex: 1,
      wordBreak: 'break-word',
      '--gradient-dir': 'right',
    },
    left: {
      '--gradient-dir': 'left',
    },
    labelNoWrap: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    labelTooltip: {
      overflow: 'hidden',
      flex: '1',
    },
    limitHeight: {
      display: '-webkit-box',
      '-webkit-line-clamp': 4,
      '-webkit-box-orient': 'vertical',
      maxHeight: '4.6em',
      overflow: 'hidden',
      position: 'relative',
      // '&:after': {
      //   content: '""',
      //   position: 'absolute',
      //   display: 'block',
      //   width: '100%',
      //   height: '1em',
      //   bottom: 0,
      //   background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
      // },
    },
  }),
  'table-cell-label'
)

export default useCellLabelStyles
