import {Box, Button, Tab, TabList, Tabs, Typography} from '@x5-react-uikit/core'
import Breadcrumbs from '@root/components/Breadcrumbs'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import LoaderFrame from '@root/components/Loader'
import Section from '@root/components/Section'
import ErrorMessage from '@root/pages/SpecialistToolsPage/components/stubs/ErrorMessage'
import {useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {useGetConfigurationByIdQuery} from '@root/redux/api/reportConfigurationsApi'
import ConfAffilation from './components/ConfAffilation'
import ConfDateAndTasks from './components/ConfDataAndTasks'
import ConfDataTab from './components/ConfDataTab'
import ConfDeleteModal from './components/ConfDeleteModal'
import ConfStatisticsTab from './components/ConfStatisticsTab'

const ConfSectionLabels = {
  data: 'Данные',
  stats: 'Статистика',
}

const ReportConfViewPage = () => {
  const navigate = useNavigate()
  const {configurationId} = useParams()
  const {
    data: configuration,
    isLoading: isConfLoading,
    isError: isGetConfError,
  } = useGetConfigurationByIdQuery({configurationId})
  const [sectionTab, setSectionTab] = useState(ConfSectionLabels.data)

  const handleEdit = () => navigate(`/reports/configurations/${configurationId}/update`)
  const [deleteModalShown, setDeleteModalShown] = useState(false)
  const handleDelete = () => setDeleteModalShown(true)

  if (isConfLoading) return <LoaderFrame />

  if (isGetConfError)
    return (
      <div style={{width: '100%'}}>
        <ErrorMessage />
      </div>
    )

  let currentTabContent = <></>
  if (sectionTab === ConfSectionLabels.data) currentTabContent = <ConfDataTab />
  else if (sectionTab === ConfSectionLabels.stats) currentTabContent = <ConfStatisticsTab />

  return (
    <>
      <Box sx={{mx: 'x8', width: '1200px'}}>
        <Breadcrumbs
          routes={[
            {to: '/tasks', label: 'Главная'},
            {to: '/reports?tab=configurations', label: 'Отчёты портала'},
            {to: null, label: 'Просмотр отчета'},
          ]}
        />
        <FlexboxRow sx={{mb: 'x12', gap: '16px', justifyContent: 'space-between'}}>
          <FlexboxRow sx={{gap: '16px', alignItems: 'center', flex: 1}}>
            <Typography variant="h2">Конфигурация {configuration.name}</Typography>
          </FlexboxRow>
          <FlexboxRow sx={{gap: '16px'}}>
            <Button variant="outlined" onClick={handleDelete}>
              Удалить
            </Button>
            <Button onClick={handleEdit}>Редактировать</Button>
          </FlexboxRow>
        </FlexboxRow>
        <FlexboxColumn sx={{gap: '24px'}}>
          <FlexboxRow sx={{gap: '24px'}}>
            <ConfAffilation />
            <ConfDateAndTasks />
          </FlexboxRow>
          <Section sx={{my: 0, mb: 'x12'}}>
            <Tabs value={sectionTab} onChange={(e, newTab) => setSectionTab(newTab)}>
              <TabList>
                <Tab label={ConfSectionLabels.data} value={ConfSectionLabels.data} />
                <Tab label={ConfSectionLabels.stats} value={ConfSectionLabels.stats} />
              </TabList>
            </Tabs>
            {currentTabContent}
          </Section>
        </FlexboxColumn>
      </Box>
      <ConfDeleteModal open={deleteModalShown} onClose={() => setDeleteModalShown(false)} />
    </>
  )
}

export default ReportConfViewPage
