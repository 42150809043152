import React, {FC} from 'react'
import clsx from 'clsx'

import {IconButton, Link, Typography} from '@x5-react-uikit/core'
import {
  Close as CloseIcon,
  Done as DoneIcon,
  WarningOutline as WarningIcon,
  Error as ErrorIcon,
  Info as InfoIcon,
} from '@x5-react-uikit/icons'
import {sizeTokenValues, typeTokenValues} from '@x5-react-uikit/tokens'

import {getQaAttribute} from '../utils'
import {BannerProps} from './types'
import {useBannerStyles} from './styles'

const UserAction: FC<
  Pick<BannerProps, 'type' | 'action'> & {
    className?: string
    getQA: ReturnType<typeof getQaAttribute>
  }
> = ({action: actionProp, className, type, getQA}) => {
  const classes = useBannerStyles({type})

  if (!actionProp) {
    return null
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {nextLine, ...action} = actionProp

  return (
    <Link
      {...action}
      className={clsx(classes.userAction, className, action.className)}
      qa={getQA('user-action')}
    >
      {action.text}
    </Link>
  )
}

const Actions: FC<
  Pick<BannerProps, 'type' | 'action' | 'onClose'> & {
    isShowUserAction: boolean
    isHide?: boolean
    getQA: ReturnType<typeof getQaAttribute>
  }
> = ({type, action, isShowUserAction, isHide, onClose, getQA}) => {
  const classes = useBannerStyles({type})

  return (
    <div
      className={clsx(
        classes.actions,
        isHide && classes.hideActions,
        isShowUserAction && classes.actionsWithUserAction
      )}
    >
      {isShowUserAction && <UserAction action={action} getQA={getQA} type={type} />}
      {onClose && (
        <>
          <IconButton
            aria-label="Close"
            className={classes.close}
            IconComponent={<CloseIcon size={sizeTokenValues.small} />}
            qa={getQA('close')}
            size={sizeTokenValues.small}
            variant="text"
            onClick={onClose}
          />
          <div className={classes.shield} />
        </>
      )}
    </div>
  )
}

const Icon: FC<Pick<BannerProps, 'icon' | 'type'>> = ({icon, type}) => {
  if (!icon) {
    return null
  }

  if (type === typeTokenValues.success) {
    return <DoneIcon />
  } else if (type === typeTokenValues.warning) {
    return <WarningIcon />
  } else if (type === typeTokenValues.error) {
    return <ErrorIcon />
  }

  return <InfoIcon />
}

export const Banner: FC<BannerProps> = ({
  type = typeTokenValues.default,
  action,
  maxWidth,
  title,
  message,
  icon,
  onClose,
  qa = 'banner',
}) => {
  const getQA = getQaAttribute(qa)
  const isShowUserActionInActions = !(title || action?.nextLine)
  const classes = useBannerStyles({
    type,
    maxWidth,
    title,
    action,
    icon,
    onClose,
  })

  return (
    <div className={classes.root} data-qa={getQA()}>
      <div className={clsx(classes.wrapper)} data-qa={getQA('wrapper')}>
        {icon && (
          <div className={classes.left} data-qa={getQA('left')}>
            <Icon icon={icon} type={type} />
          </div>
        )}
        <div className={classes.right} data-qa={getQA('right')}>
          <div className={classes.content}>
            {title && (
              <div
                className={clsx(classes.alignActions, classes.titleWrapper)}
                data-qa={getQA('title')}
              >
                <Typography className={classes.title} variant="h4">
                  {title}
                </Typography>
                <Actions
                  action={action}
                  getQA={getQA}
                  isShowUserAction={isShowUserActionInActions}
                  type={type}
                  onClose={onClose}
                />
              </div>
            )}
            <div
              className={clsx(classes.alignActions, classes.messageWrapper)}
              data-qa={getQA('message')}
            >
              <div className={classes.message}>{message}</div>
              <Actions
                action={action}
                getQA={getQA}
                isHide={!!title}
                isShowUserAction={isShowUserActionInActions}
                type={type}
                onClose={onClose}
              />
            </div>
          </div>
          {!isShowUserActionInActions && (
            <UserAction
              action={action}
              className={classes.userActionNextLine}
              getQA={getQA}
              type={type}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Banner
