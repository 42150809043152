import {makeUiStyles} from '../theme'

export const useStyles = makeUiStyles(
  (theme) => ({
    root: {
      display: 'flex',
      width: 'fit-content',
      background: theme.colors.white,
      zIndex: 9,
      boxShadow: theme.shadows.medium,
      borderRadius: theme.spaces.x4,
      boxSizing: 'border-box',
      padding: theme.spaces.join('x10', 'x8'),
    },
    offsetBlock: {
      marginLeft: theme.spaces.x16,
    },
  }),
  'calendar'
)
