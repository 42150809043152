import React, {Ref} from 'react'
import clsx from 'clsx'
import {EditOn as CreateIcon} from '@x5-react-uikit/icons'
import {BodyCellInnerNumberViewProps} from './types'
import {BodyCellEditIcon} from '../CellEditIcon'
import {getCellDensityClassNameBy} from '../../helpers'
import {TableCellLabel} from '../../CellLabel'
import {useBaseCellStyles} from '../../Cell/styles'
import {valueIsEmpty} from './helpers'
import {useInnerNumberStyles} from './styles'
import BodyCellErrorIcon from '../ErrorIcon'

const NumberFormatCache = {}

export const defaultRenderValue = (value, scale, isFraction, char) => {
  let result
  if (valueIsEmpty(value)) {
    result = ''
  } else {
    const fValue = parseFloat(String(value).replace(/\s/g, '')) * (isFraction ? 100 : 1)
    if (isNaN(fValue)) {
      result = value
    } else {
      // result = `${Number.parseFloat(fValue).toFixed(scale)} ${char}`;
      // we don't use style: "currency" for custom char
      NumberFormatCache[scale] =
        NumberFormatCache[scale] ||
        new Intl.NumberFormat('ru-RU', {
          minimumFractionDigits: scale,
          maximumFractionDigits: scale,
        })

      const prepared = NumberFormatCache[scale].format(fValue).replace(/,/g, '.')

      result = `${prepared} ${char}`.trim()
    }
  }

  return result
}

const BodyCellInnerNumberViewBase = (
  props: BodyCellInnerNumberViewProps,
  ref: Ref<HTMLDivElement>
) => {
  const {
    density,
    noWrap,
    editable,
    onStartEdit,
    char,
    scale,
    isFraction,
    children: value,
    renderView = defaultRenderValue,
    errorText,
  } = props

  const baseClasses = useBaseCellStyles()
  const innerClasses = useInnerNumberStyles()
  const densityClassName = baseClasses[getCellDensityClassNameBy(density)]

  return (
    <div
      ref={ref}
      className={clsx(baseClasses.innerRoot, innerClasses.innerRootView, densityClassName)}
    >
      <TableCellLabel bodyCell density={density} left={false} noWrap={noWrap}>
        {renderView(value, scale, isFraction, char)}
      </TableCellLabel>
      {Boolean(errorText) && (
        <BodyCellErrorIcon className={innerClasses.errorIcon} message={errorText} />
      )}
      {editable && <BodyCellEditIcon left Icon={CreateIcon} type="number" onClick={onStartEdit} />}
    </div>
  )
}

export const BodyCellInnerNumberView = React.forwardRef(BodyCellInnerNumberViewBase)

export default BodyCellInnerNumberView
