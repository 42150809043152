import React, {forwardRef, createElement, MouseEventHandler} from 'react'
import {Loader} from '@x5-react-uikit/core'
import {getPxFromSize} from '@x5-react-uikit/icons'
import {ButtonProps, buttonSizes, buttonVariants, buttonTypes, buttonModes} from './types'
import {getQaAttribute} from '../utils'
import {StyledButton, buttonClasses} from './styles'

const loaderSize = getPxFromSize(buttonSizes.small)

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = buttonVariants.primary,
      size = buttonSizes.medium,
      mode = buttonModes.light,
      qa = 'btn',
      type,
      href,
      component,
      disabled,
      loading,
      children,
      onClick,
      ...props
    },
    ref
  ) => {
    const getQA = getQaAttribute(qa)
    const buttonType = buttonTypes[type || 'button']
    const as = component ?? (href ? 'a' : null)
    const hasLoader = loading

    const handleClick: MouseEventHandler = (event) => !disabled && onClick?.(event)

    return createElement(
      StyledButton,
      {
        ref,
        'data-qa': getQA(),
        'data-type': buttonType,
        hasLoader,
        as,
        type: href || component ? null : buttonType,
        href: !href || component ? null : href,
        disabled,
        size,
        variant,
        mode,
        onClick: handleClick,
        ...props,
      },
      <>
        {hasLoader && <Loader className={buttonClasses.loader} size={loaderSize} />}
        {props.startIcon}
        <span className={buttonClasses.content} data-qa={getQA('content')}>
          {children}
        </span>
        {props.endIcon}
      </>
    )
  }
)

export default Button
