import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  FC,
  ChangeEvent,
  MouseEvent as ReactMouseEvent,
  FocusEvent,
} from 'react'
import {sizeTokenValues} from '@x5-react-uikit/tokens'
import {EditOn as EditOnIcon} from '@x5-react-uikit/icons'
import {} from '@x5-react-uikit/core'
import {StyledInputCell, StyledFieldCell, classes} from './styles'
import {TableContext} from '../context'
import {cellModeValues, DataGridInputCellProps} from 'ui-kit'

export const InputCell: FC<DataGridInputCellProps> = ({
  type = 'text',
  value,
  disabled,
  align,
  cellId,
  onChange,
  onFocus,
  onBlur,
}) => {
  const context = useContext(TableContext)
  const ref = useRef(null)
  const [inputValue, setInputValue] = useState(value)
  const [editing, setEditing] = useState(false)

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputValue(event.target.value)
    onChange?.(event, cellId)
  }

  useEffect(() => {
    if (cellId !== context._currentCellId) {
      setEditing(false)
      ref.current.blur()
    }
  }, [context._currentCellId, cellId])

  const handleClick = (
    event: ReactMouseEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDivElement, MouseEvent>
  ) => {
    if (disabled) return
    if (event.detail === 2) {
      setEditing(true)
    }
  }

  const handleBlur = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEditing(false)
    onBlur?.(event)
  }

  const fieldProps = {
    ref,
    className: classes.input,
    value: inputValue,
    'data-cell-id': cellId,
    onClick: handleClick,
    onChange: handleChange,
    onFocus: onFocus,
    onBlur: handleBlur,
  }

  const RenderField =
    type === cellModeValues.text ? (
      <StyledFieldCell {...fieldProps} />
    ) : (
      <StyledFieldCell as="input" {...fieldProps} type={type} />
    )

  return editing ? (
    RenderField
  ) : (
    <StyledInputCell ref={ref} onClick={handleClick} {...{align, disabled}}>
      {inputValue} <EditOnIcon className={classes.icon} size={sizeTokenValues.small} />
    </StyledInputCell>
  )
}

export default InputCell
