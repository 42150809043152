import {StylesProvider, createGenerateClassName} from '@material-ui/core/styles'
import {Grow, SnackbarProvider, ThemeProvider} from '@x5-react-uikit/core'

const X5KitProvider = ({productName = 'lt-portal', children}) => {
  const generateClassName = createGenerateClassName({
    productionPrefix: productName,
    seed: productName,
  })

  return (
    <ThemeProvider>
      <StylesProvider generateClassName={generateClassName}>
        <SnackbarProvider TransitionComponent={Grow}>{children}</SnackbarProvider>
      </StylesProvider>
    </ThemeProvider>
  )
}

export default X5KitProvider
