import {useInView} from '@react-spring/web'
import FlexboxColumn from '@root/components/FlexboxColumn'
import LoaderFrame from '@root/components/Loader'
import {FileField} from '@root/features/reports/types'
import {useGetReportFileByNameQuery} from '@root/redux/api/launchesApi'
import {Typography} from '@x5-react-uikit/core'

const ReportImage = ({fileField}: {fileField: FileField}) => {
  const [imageRef, isImageInView] = useInView()

  const {isFetching: isImageFetching, data: imageBlob} = useGetReportFileByNameQuery(
    fileField.files[0].nameInStore,
    {
      skip: !isImageInView || !fileField.files[0].nameInStore,
    }
  )

  return (
    <div ref={imageRef}>
      <FlexboxColumn sx={{gap: '16px'}}>
        {!isImageInView || isImageFetching ? (
          <LoaderFrame backgroundColor="grey" height="485px" />
        ) : (
          <>
            <Typography variant="h3">{fileField.name}</Typography>
            <img alt={fileField.name} src={URL.createObjectURL(imageBlob as Blob)} />
            <FlexboxColumn sx={{gap: '24px'}}>
              <Typography variant="p1">Комментарий:</Typography>
              <Typography variant="p1">{fileField.comment}</Typography>
            </FlexboxColumn>
          </>
        )}
      </FlexboxColumn>
    </div>
  )
}

export default ReportImage
