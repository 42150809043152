import Divider from '@root/components/Divider'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'
import {Modal} from '@root/components/kit'
import {RequiredRule} from '@root/constants'
import useNotify from '@root/hooks/useNotify'
import {UniqueVarNameRule, getDuplicatedVarRegisters} from '@root/pages/SpecialistToolsPage/utils'
import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useUpdateTemplateMutation} from '@root/redux/api/templatesApi'
import SystemsCombobox from '@root/features/systems/components/SystemsCombobox'
import VariableInputsList from './VariableInputsList'
import SaveNewTemplateButton from '../systemSettingsTab/SaveNewTemplateButton'

const TemplateUpdateModal = ({open, systemId, template, onClose}) => {
  const {notifySuccess, notifyError} = useNotify()

  const [selectedSystemId, setSelectedSystemId] = useState(systemId)
  useEffect(() => !open && setSelectedSystemId(systemId), [open, systemId])

  const {
    clearErrors: clearFormErrors,
    reset: resetFormValue,
    ...form
  } = useForm({defaultValues: {notInited: true}})
  useEffect(() => {
    if (template) resetFormValue(template)
  }, [template, resetFormValue])

  const [updateTemplate] = useUpdateTemplateMutation()
  const handleSubmit = form.handleSubmit((fields) => {
    const duplicats = getDuplicatedVarRegisters(fields.variables)
    if (duplicats.length > 0) {
      duplicats.forEach((registerName) => form.setError(registerName, UniqueVarNameRule))
      return
    }
    const updateData = {}
    if (selectedSystemId !== systemId) updateData.systemId = selectedSystemId
    if (form.formState.dirtyFields.variables) updateData.variables = fields.variables
    if (form.formState.dirtyFields.name) updateData.name = fields.name
    updateTemplate({systemId: selectedSystemId, templateId: template.id, updateData})
      .unwrap()
      .then(() => {
        notifySuccess('Шаблон обновлён')
        onClose()
      })
      .catch((error) => {
        notifyError()
        console.log(error)
      })
  })

  return (
    <>
      <Modal
        closeOnOverlay
        buttons={[
          {
            onClick: onClose,
            side: 'right',
            text: 'Отмена',
          },
          {
            side: 'right',
            text: 'Сохранить как новый шаблон',
            content: <SaveNewTemplateButton closeModal={onClose} form={form} systemId={systemId} />,
          },
          {
            onClick: handleSubmit,
            side: 'right',
            text: 'Сохранить изменения',
          },
        ]}
        isOpen={open}
        size="medium"
        title="Редактирование шаблона настроек"
        variant="sideModal"
        onClose={onClose}
      >
        {open && (
          <FlexboxColumn sx={{gap: '24px'}}>
            <FlexboxRow sx={{gap: '24px'}}>
              <SystemsCombobox
                errorOnEmpty
                required
                caption="ИС, к которой будет принадлежать шаблон"
                selectedSystemId={selectedSystemId}
                width={396}
                onChange={(e, newSystemId) => setSelectedSystemId(newSystemId)}
                onClear={() => setSelectedSystemId(null)}
              />
              <FormInputText
                required
                control={form.control}
                label="Название шаблона"
                name="name"
                rules={RequiredRule}
                width="100%"
              />
            </FlexboxRow>
            <Divider />
            <VariableInputsList form={form} />
          </FlexboxColumn>
        )}
      </Modal>
    </>
  )
}

export default TemplateUpdateModal
