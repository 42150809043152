import React, {FC} from 'react'

import {Tooltip} from '../Tooltip'
import {IconButton} from '../IconButton'
import {Button} from '../Button'
import {Filter as FilterIcon, CancelFilter as CancelFilterIcon} from '@x5-react-uikit/icons'
import {sizeTokenValues, placements} from '@x5-react-uikit/tokens'

import {classes} from './styles'

export const DropdownAction: FC<{count: number; iconSize: keyof typeof sizeTokenValues}> = ({
  count,
  iconSize = sizeTokenValues.small,
}) => {
  const noFilters = count === 0
  const content = noFilters ? 'Нет активных фильтров' : ''

  return (
    <Tooltip content={content} placement={placements.bottomStart}>
      {!noFilters ? (
        <Button
          className={classes.dropdownAction}
          size={sizeTokenValues.small}
          startIcon={<FilterIcon size={iconSize} />}
          variant="text"
        >
          <span className={classes.dropdownActionContent}>{count}</span>
        </Button>
      ) : (
        <IconButton
          IconComponent={<CancelFilterIcon size={iconSize} />}
          size={sizeTokenValues.small}
          variant="text"
        />
      )}
    </Tooltip>
  )
}

export default DropdownAction
