import {Select} from '@x5-react-uikit/core'
import {Controller, FieldPath, FieldValues} from 'react-hook-form'
import {ComponentProps} from 'react'
import {SelectOptionType, SelectProps} from '@root/components/kit'

type ControllerProps<TFieldValues extends FieldValues> = ComponentProps<
  typeof Controller<TFieldValues>
>

type Props<TFieldValues extends FieldValues> = {
  name: FieldPath<TFieldValues>
  options: SelectOptionType[]
  label?: string
} & Pick<ControllerProps<TFieldValues>, 'control' | 'rules' | 'disabled'> &
  Omit<SelectProps, 'disabled' | 'options' | 'value' | 'onChange' | 'label' | 'textError'>

function FormInputSelect<TFieldValues extends FieldValues>({
  name,
  control,
  rules,
  options,
  label,
  disabled,
  ...inputProps
}: Props<TFieldValues>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({field: {onChange, value}, fieldState: {error}}) => {
        return (
          <Select
            disabled={disabled}
            error={!!error}
            label={label}
            options={options}
            textError={error?.message}
            value={value}
            onChange={(e, newValue) => {
              onChange(newValue)
            }}
            {...inputProps}
          />
        )
      }}
      rules={rules}
    />
  )
}

export default FormInputSelect
