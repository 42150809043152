import {Box} from '@x5-react-uikit/core'
import {ChevronDown, ChevronUp} from '@x5-react-uikit/icons'
import {useState} from 'react'
import FlexboxRow from './FlexboxRow'

const Accordion = ({
  titleEl,
  children,
  boxSx,
  headerSx,
  expanded,
  onClick,
  chevronSize = 'large',
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  // if is controlled
  if (expanded !== undefined && onClick)
    return (
      <Box sx={boxSx}>
        <FlexboxRow sx={headerSx} onClick={onClick}>
          {expanded ? <ChevronUp size={chevronSize} /> : <ChevronDown size={chevronSize} />}
          {titleEl}
        </FlexboxRow>
        {expanded && children}
      </Box>
    )

  return (
    <Box sx={boxSx}>
      <FlexboxRow sx={headerSx} onClick={() => setIsExpanded(!isExpanded)}>
        {isExpanded ? <ChevronUp size={chevronSize} /> : <ChevronDown size={chevronSize} />}
        {titleEl}
      </FlexboxRow>
      {isExpanded && children}
    </Box>
  )
}

export default Accordion
