import {FC, CSSProperties} from 'react'
import {QA} from '../types'
import {ChipProps} from '../Chip/types'
import {mimeTypes} from './helpers'

export const uploaderStatuses = {
  empty: 'empty',
  loading: 'loading',
  error: 'error',
  done: 'done',
} as const

type DefaultPreviewsProps = Pick<FileUploaderProps, 'items' | 'maxLabelLength'> &
  Pick<ChipProps, 'onDelete'>

export interface FileUploaderItem {
  file: File
  objectURL?: string
  status?: keyof typeof uploaderStatuses
  tooltip?: string
  [key: string]: unknown
}

export interface FileUploaderProps extends QA {
  items?: FileUploaderItem[]
  maxFilesCount?: number | null
  maxFileSize?: number
  multiple?: boolean
  accept?: Array<keyof typeof mimeTypes> | null
  disabled?: boolean
  text?: string
  error?: boolean
  textError?: string
  maxLabelLength?: number
  width?: CSSProperties['width']
  renderPreviewsContent?: FC<DefaultPreviewsProps>
  onSelect?: (files: File[]) => void
  onRemove?: (file: File) => void
}
