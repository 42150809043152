import {createContext} from 'react'
import {SetAction} from '@root/core/helperTypes'
import {System} from '@root/features/systems/types'

export type TableContextState = {
  downloadXLS: () => void
  setCurrentSystem: SetAction<System>
  setShowTagModal: SetAction<boolean>
  setShowStatusModal: SetAction<boolean>
  setShowCommentModal: SetAction<boolean>
}

export const tableContext = createContext<TableContextState | null>(null)
export const TableContextProvider = tableContext.Provider
