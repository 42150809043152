import React, {FC, useState, useRef, useEffect, useCallback, MouseEvent, ChangeEvent} from 'react'
import {Input} from '../Input'
import {DropdownInnerRefMethods} from '../Dropdown'
import {SelectBase} from './SelectBase'
import {DoubleAdornment} from './SelectAdornment'
import {SelectProps} from './types'
import {getQaAttribute} from '../utils'
import {filterOptions, getDifferentProps} from './helpers'
import {StyledSelect, selectClasses} from './styles'

export const Combobox: FC<SelectProps> = (props) => {
  const {
    value: valueFromProps,
    size,
    disabled = false,
    isOpen = false,
    qa = 'combobox',
    onOpen,
    onChange,
    onFilterChange,
    onClearClick,
    onBlur,
  } = props
  const value = valueFromProps ?? ''
  const {inputProps, selectBaseProps, commonProps} = getDifferentProps(props)
  const getQA = getQaAttribute(qa)
  const dropdownRef = useRef<DropdownInnerRefMethods>()
  const inputRef = useRef<HTMLInputElement>(null)

  const [options, setOptions] = useState(props.options)
  const [opened, setOpened] = useState(isOpen && !disabled)
  const [inputValue, setInputValue] = useState('')
  const [isFilterMode, setFilterMode] = useState(false)

  useEffect(() => {
    setOptions(props.options.filter((option) => option.name && option.value))
  }, [props.options])

  useEffect(() => {
    if (!isFilterMode) {
      const found = options.find((option) => option.value === value)
      setInputValue(found?.name || '')
    }
    if ((isFilterMode && !!inputValue.length) || isOpen) {
      dropdownRef.current.open()
    } else {
      setTimeout(() => dropdownRef.current.close(), 100)
    }
  }, [options, isFilterMode, inputValue, value, isOpen])

  const handleDropdownChange = (opened: boolean) => {
    setOpened(opened)
    onOpen && onOpen(opened)
  }

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {target} = event
    setFilterMode(true)
    setInputValue(target.value)
    if (!onFilterChange) {
      setOptions(filterOptions(options, target.value))
      return
    }
    onFilterChange(event)
    setOptions(options)
  }

  const handleClearClick = (event: MouseEvent<SVGElement>) => {
    if (disabled) return
    event.stopPropagation()
    setInputValue('')
    setOptions(options)
    onClearClick(event)
  }

  const handleInputClick = (event: MouseEvent<HTMLInputElement>) => {
    if (disabled) event.stopPropagation()
  }

  const innerOnChange = useCallback(
    (event, option) => {
      setFilterMode(false)
      onChange(event, option)
    },
    [onChange]
  )

  const ComboboxInput = (
    <Input
      {...commonProps}
      endAdornment={
        <DoubleAdornment
          clearable={!!inputValue.length}
          opened={opened}
          size={size}
          onClick={handleClearClick}
        />
      }
      inputProps={{className: selectClasses.input}}
      inputRef={inputRef}
      qa={getQA('input', disabled && 'disabled')}
      value={inputValue}
      onBlur={onBlur}
      onChange={handleFilterChange}
      onClick={handleInputClick}
      {...inputProps}
    />
  )

  return (
    <StyledSelect opened={opened}>
      <SelectBase
        {...commonProps}
        highlighted
        action={ComboboxInput}
        getQA={getQA}
        innerRef={dropdownRef}
        inputValue={inputValue}
        multiple={false}
        options={options}
        value={value}
        onChange={innerOnChange}
        onDropdownChange={handleDropdownChange}
        {...selectBaseProps}
      />
    </StyledSelect>
  )
}

export default Combobox
