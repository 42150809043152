import {type ExtractConstValues} from '@root/core/helperTypes'
import {type BoxProps} from 'ui-kit'

export const GRAFANA_URL: string = process.env.REACT_APP_GRAFANA_URL
export const GRAFANA_IFRAME_URL: string = process.env.REACT_APP_GRAFANA_URL
export const GITLAB_CONTROLLER_URL: string = process.env.REACT_APP_GITLAB_CONTROLLER_URL
export const BEARER_TOKEN: string = process.env.REACT_APP_BEARER_TOKEN
export const BACKEND_BASE_URL: string = process.env.REACT_APP_BACKEND_BASE_URL
export const IS_DEBUG: boolean = (process.env.REACT_APP_IS_DEBUG as unknown as boolean) || false
export const KEYCLOAK_BASE_URL: string = process.env.REACT_APP_KEYCLOAK_BASE_URL
export const KEYCLOAK_REALM: string = process.env.REACT_APP_KEYCLOAK_REALM
export const KEYCLOAK_CLIENT_ID: string = process.env.REACT_APP_KEYCLOAK_CLIENT_ID
export const KEYCLOAK_LOGIN_REDIRECT: string = process.env.REACT_APP_KEYCLOAK_LOGIN_REDIRECT

export const FEATURE_ENABLE_REPORT_PDF_EXPORT: string =
  process.env.REACT_APP_FEATURE_ENABLE_REPORT_PDF_EXPORT

export const DATE_FORMAT = 'DD.MM.YYYY'

export const INPUT_EMPTY_MESSAGE = 'Поле не может быть пустым'
export const RequiredRule = {required: INPUT_EMPTY_MESSAGE}

export const MODAL_INPUT_WIDTH_PX = '572px'

export const Roles = {
  BUSINESS: 'BUSINESS',
  SPECIALIST: 'SPECIALIST',
  STAKEHOLDER: 'STAKEHOLDER',
  ADMIN: 'ADMIN',
  MANAGER: 'MANAGER',
} as const

export const TaskStatuses = {
  DRAFT: 'DRAFT',
  IN_REVIEW: 'IN_REVIEW',
  WITHDRAWN: 'WITHDRAWN',
  SEARCHING_PERFORMER: 'SEARCHING_PERFORMER',
  SUSPENDED: 'SUSPENDED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED_SUCCESSFULLY: 'COMPLETED_SUCCESSFULLY',
  SYSTEM_SENT_FOR_REVISION: 'SYSTEM_SENT_FOR_REVISION',
  NOT_REQUIRED: 'NOT_REQUIRED',
} as const

export const getTaskStatusDisplay = (status: ExtractConstValues<typeof TaskStatuses>): string => {
  switch (status) {
    case TaskStatuses.DRAFT:
      return 'Черновик'
    case TaskStatuses.IN_REVIEW:
      return 'На рассмотрении'
    case TaskStatuses.WITHDRAWN:
      return 'Отозвана'
    case TaskStatuses.SEARCHING_PERFORMER:
      return 'Поиск исполнителя'
    case TaskStatuses.SUSPENDED:
      return 'Приостановлена'
    case TaskStatuses.IN_PROGRESS:
      return 'В работе'
    case TaskStatuses.COMPLETED_SUCCESSFULLY:
      return 'НТ завершено успешно'
    case TaskStatuses.SYSTEM_SENT_FOR_REVISION:
      return 'Отправлена на доработку'
    case TaskStatuses.NOT_REQUIRED:
      return 'НТ не требуется'
    default:
      return status
  }
}

type StatusColor = {
  backgroundColor: BoxProps['sx']['color']
  color: BoxProps['sx']['color']
}

export const taskStatusToColor = (status: ExtractConstValues<typeof TaskStatuses>): StatusColor => {
  switch (status) {
    case TaskStatuses.DRAFT:
      return {backgroundColor: 'grey.20', color: 'grey.80'}
    case TaskStatuses.IN_REVIEW:
      return {backgroundColor: 'indigo.10', color: 'indigo.80'}
    case TaskStatuses.SEARCHING_PERFORMER:
      return {backgroundColor: 'blue.10', color: 'blue.80'}
    case TaskStatuses.IN_PROGRESS:
      return {backgroundColor: 'cyan.10', color: 'cyan.80'}
    case TaskStatuses.COMPLETED_SUCCESSFULLY:
      return {backgroundColor: 'green.10', color: 'green.80'}
    case TaskStatuses.SYSTEM_SENT_FOR_REVISION:
      return {backgroundColor: 'green.20', color: 'green.100'}
    case TaskStatuses.SUSPENDED:
      return {backgroundColor: 'yellow.10', color: 'yellow.90'}
    case TaskStatuses.WITHDRAWN:
      return {backgroundColor: 'red.10', color: 'red.80'}
    case TaskStatuses.NOT_REQUIRED:
      return {backgroundColor: 'olive.20', color: 'olive.100'}
    default:
      throw new Error('Out of range')
  }
}

export const CONTENT_TYPE_JSON = {type: 'application/json'}
