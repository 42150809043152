import {Modal, Typography} from '@x5-react-uikit/core'
import {Warning} from '@x5-react-uikit/icons'
import Divider from '@root/components/Divider'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import {SystemStatusCode, SystemSubstatusesMap} from '@root/features/systems/constants'
import SystemStatusChip from '../SystemStatusChip'
import {getTagOrName} from '@root/features/systems/utils'
import {FC} from 'react'
import {System} from '@root/features/systems/types'
import {useGetUserinfoQuery} from '@root/redux/api/userApi'

type Props = {
  isOpen: boolean
  onCancel: () => void
  onStatusChange: () => void
  system: System
}
const SystemStatusCommentModal: FC<Props> = ({isOpen, system, onCancel, onStatusChange}) => {
  const {data: userInfo} = useGetUserinfoQuery()
  const buttons = [
    {
      onClick: onCancel,
      side: 'right' as const,
      text: 'Закрыть',
    },
  ]
  if (userInfo.isAdmin)
    buttons.unshift({
      onClick: onStatusChange,
      side: 'right' as const,
      text: 'Изменить статус НТ',
    })
  return (
    <Modal
      closeOnOverlay
      buttons={buttons}
      isOpen={isOpen}
      size="medium"
      title="Комментарий к статусу нагрузочного тестирования"
      onClose={onCancel}
    >
      <FlexboxColumn sx={{gap: '24px'}}>
        <FlexboxRow sx={{gap: '40px'}}>
          <Typography style={{width: '210px'}} variant="h4">
            Информационная система
          </Typography>
          <Typography variant="p1">{getTagOrName(system)}</Typography>
        </FlexboxRow>
        <FlexboxRow sx={{gap: '40px'}}>
          <Typography style={{width: '210px'}} variant="h4">
            Текущий статус
          </Typography>
          {system?.status ? (
            <FlexboxRow sx={{gap: '10px', alignItems: 'center'}}>
              <SystemStatusChip
                statusName={system.status as keyof SystemStatusCode}
                sx={{mt: 'x0'}}
              />
              {system.substatus && (
                <Warning color={SystemSubstatusesMap[system.substatus].color} size="small" />
              )}
              <Typography variant="p1">
                {system.expirationDate ? `до ${system.expirationDate}` : 'действует бессрочно'}
              </Typography>
            </FlexboxRow>
          ) : (
            <Typography variant="p1">-</Typography>
          )}
        </FlexboxRow>
        <Divider />
        <FlexboxColumn sx={{gap: '8px'}}>
          <Typography variant="h4">Комментарий</Typography>
          <Typography variant="p1">{system?.comment || '-'}</Typography>
        </FlexboxColumn>
      </FlexboxColumn>
    </Modal>
  )
}

export default SystemStatusCommentModal
