import {Font} from '@react-pdf/renderer'
import {fontX5Ui} from './constants'

Font.register({
  family: fontX5Ui,
  src: '/fonts/X5-Sans-UI_Regular.ttf',
  fontWeight: 'normal',
})

Font.register({
  family: fontX5Ui,
  src: '/fonts/X5-Sans-UI_Medium.ttf',
  fontWeight: 'medium',
})

Font.register({
  family: fontX5Ui,
  src: '/fonts/X5-Sans-UI_Bold.ttf',
  fontWeight: 'bold',
})

export * from './constants'
export * from './utils'
export * from './Title'
export * from './Subtitle'
export * from './Heading'
export * from './Link'
export * from './Section'
export * from './Hr'
export * from './Table'
