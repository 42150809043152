import React, {Ref} from 'react'
import clsx from 'clsx'
import {EditOn as CreateIcon} from '@x5-react-uikit/icons'
import {BodyCellEditIcon} from '../CellEditIcon'
import {useBaseCellStyles} from '../../Cell/styles'
import {getCellDensityClassNameBy} from '../../helpers'
import {TableCellLabel} from '../../CellLabel'
import {useInnerTextStyles} from './styles'
import {BodyCellInnerTextViewProps} from './types'

const BodyCellInnerTextViewBase = (props: BodyCellInnerTextViewProps, ref: Ref<HTMLDivElement>) => {
  const {density, noWrap, editable, onStartEdit, children: value} = props

  const baseClasses = useBaseCellStyles()
  const innerClasses = useInnerTextStyles()
  const densityClassName = baseClasses[getCellDensityClassNameBy(density)]

  return (
    <div
      ref={ref}
      className={clsx(baseClasses.innerRoot, innerClasses.innerRootText, densityClassName)}
    >
      <TableCellLabel bodyCell density={density} limitHeight={!noWrap} noWrap={noWrap}>
        {value}
      </TableCellLabel>
      {editable && <BodyCellEditIcon Icon={CreateIcon} type="text" onClick={onStartEdit} />}
    </div>
  )
}

export const BodyCellInnerTextView = React.forwardRef(BodyCellInnerTextViewBase)

export default BodyCellInnerTextView
