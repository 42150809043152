import React, {FC} from 'react'
import {getCellQA} from '../helpers'
import {DataGridLabelProps} from './types'
import {StyledLabel} from './styles'

export const DataGridLabel: FC<DataGridLabelProps> = ({
  color,
  className,
  qa = 'label',
  children,
}) => {
  if (!children) return null

  return <StyledLabel data-qa={getCellQA(qa)} {...{color, className, children}} />
}

export default DataGridLabel
