import {Typography, baseTheme} from '@x5-react-uikit/core'

const {colors} = baseTheme

const TypographyError = ({children, ...props}) => (
  <Typography style={{color: colors.error}} {...props}>
    {children}
  </Typography>
)

export default TypographyError
