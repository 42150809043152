export function calculateFontWidth(
  value: string,
  externalElement: HTMLElement,
  options?: {paddingLeft?: string}
): Promise<number> {
  return (document as Document & {fonts: {ready: Promise<unknown>}}).fonts.ready.then(() => {
    const element = document.createElement('span')
    const text = document.createTextNode(value)
    const styles = getComputedStyle(externalElement)

    element.appendChild(text)

    element.style.display = 'inline-flex'
    element.style.fontSize = styles.fontSize
    element.style.fontWeight = styles.fontWeight
    element.style.fontFamily = styles.fontFamily
    element.style.paddingLeft = options?.paddingLeft || styles.paddingLeft
    element.style.whiteSpace = 'break-spaces'
    element.style.position = 'absolute'
    element.style.visibility = 'hidden'

    document.body.appendChild(element)
    const width = element.offsetWidth
    element.parentNode.removeChild(element)

    return width
  })
}
