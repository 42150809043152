import React, {FC, MouseEventHandler} from 'react'
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@x5-react-uikit/icons'
import {BaseAdornmentProps} from './types'
import {QA} from '../types'

interface PasswordAdornmentProps extends BaseAdornmentProps, QA {
  visible?: boolean
  onClick?: MouseEventHandler
}

export const PasswordAdornment: FC<PasswordAdornmentProps> = ({
  size,
  color,
  visible,
  onClick,
  qa,
}) => {
  const iconProps = {size, color, onClick}
  return (
    <div data-qa={qa}>
      {visible ? <VisibilityIcon {...iconProps} /> : <VisibilityOffIcon {...iconProps} />}
    </div>
  )
}

export default PasswordAdornment
