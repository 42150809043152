import {restyled, stateClasses} from '../../styles'
import {DataGridLabelProps} from './types'

export const StyledLabel = restyled.div<DataGridLabelProps>(
  ({theme: {spaces, palette, typography}, color}) => ({
    ...typography.p2,
    marginBottom: 'auto',
    padding: spaces.join('x1', 'x3'),
    backgroundColor: palette[color][10],
    color: palette[color][90],
    borderRadius: spaces.x2,
    whiteSpace: 'nowrap',
    cursor: 'default',
    [`.${stateClasses.isDisabled} &&`]: {
      color: palette[color][30],
    },
  })
)
