import {Link, Radio, Typography, baseTheme} from 'ui-kit'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import FormInputCheckbox from '@root/components/inputs/formInputs/FormInputCheckbox'
import FormInputDatetime from '@root/components/inputs/formInputs/FormInputDatetime'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'
import FileContent from '../components/FileContent'
import FormInputTable from '../components/FormInputTable'
import ListContent from '../components/ListContent'
import MultipleTablesContent from '../components/MultipleTablesContent'
import {TextareaInputWidth, getWidth} from '../constants'
import {InputTypes, getDefaultAnswer, getOrCreateFieldData} from './formUtils'
import useDownloadFile from '@root/hooks/useDownloadFile'

const {colors} = baseTheme

const NotFilledText = () => (
  <Typography style={{color: colors.grey[60]}} variant="p1">
    Не заполнен
  </Typography>
)

const FileLink = ({fileBaseUrl, fileItem}) => {
  const {download: handleDownloadFile} = useDownloadFile({
    path: `${fileBaseUrl}/${fileItem.nameInStore}`,
    defaultName: fileItem.name,
    method: 'GET',
  })
  return (
    <Link style={{width: 'fit-content'}} onClick={handleDownloadFile}>
      {fileItem.name}
    </Link>
  )
}

const CommentContent = ({comment, title = 'Комментарий:'}) => {
  // const displayedTitle = title || (!comment && 'Комментарий:');
  return (
    <FlexboxColumn sx={{gap: '16px'}}>
      {title && <Typography variant="p1">{title}</Typography>}
      {comment ? <Typography variant="p1">{comment}</Typography> : <NotFilledText />}
    </FlexboxColumn>
  )
}

export const getFieldReadonlyComponent = ({
  fieldMeta,
  form,
  commentTitle,
  fileBaseUrl,
  baseRegister = '',
}) => {
  const {fieldValue, fieldRegister} = getOrCreateFieldData(
    fieldMeta,
    form.getValues(baseRegister || undefined),
    baseRegister
  )

  let fieldMetaValue
  let fieldValueAnswer
  if (fieldMeta.question) {
    fieldValueAnswer = fieldValue ? fieldValue.question : getDefaultAnswer()
    // Значением может быть как false, так и null.
    // В случае question=null нужно проверить наличие ключа null в мете.
    fieldValueAnswer =
      fieldValueAnswer === null && fieldMeta.question[fieldValueAnswer]
        ? fieldValueAnswer
        : !!fieldValueAnswer
    fieldMetaValue = fieldMeta.question[fieldValueAnswer].values
  } else {
    fieldMetaValue = fieldMeta.values
  }
  const inputTypes = [...new Set(fieldMetaValue.map((i) => i.type))]

  return (
    <>
      {fieldMeta.question && (
        <FlexboxColumn sx={{gap: '4px', mb: 'x12'}}>
          <Typography>{fieldMeta.question.label}</Typography>
          <Radio checked label={fieldMeta.question[fieldValueAnswer].label} />
        </FlexboxColumn>
      )}
      {inputTypes.map((inputType) => {
        const inputsMeta = fieldMetaValue.filter((inputMeta) => inputMeta.type === inputType)
        switch (inputType) {
          case InputTypes.dateInput:
          case InputTypes.text: {
            const remarks = inputsMeta
              .filter((inputMeta) => inputMeta.remark)
              .map((inputMeta) => inputMeta.remark)
            return (
              <FlexboxRow sx={{flexWrap: 'wrap', boxSizing: 'border-box'}}>
                {inputsMeta.map((inputMeta, i) => {
                  const inputValue = fieldValue?.values[inputType]?.find(
                    (text) => text.id === inputMeta.id
                  )
                  return (
                    <FlexboxColumn
                      key={i}
                      sx={{
                        flex: '0 0 50%',
                        boxSizing: 'border-box',
                        gap: '4px',
                        mb: 'x12',
                      }}
                    >
                      {inputMeta.label && (
                        <Typography variant="p1">
                          {`${inputMeta.label} ${inputMeta.remark ? '*' : ''}`}
                        </Typography>
                      )}
                      {inputValue?.value ? (
                        <Typography variant="p1">{inputValue.value}</Typography>
                      ) : (
                        <NotFilledText />
                      )}
                    </FlexboxColumn>
                  )
                })}
                {remarks.length > 0 && (
                  <FlexboxColumn sx={{mb: 'x12'}}>
                    {remarks.map((remark, i) => (
                      <Typography key={i} style={{color: colors.grey[60]}} variant="p3">
                        *{remark}
                      </Typography>
                    ))}
                  </FlexboxColumn>
                )}
              </FlexboxRow>
            )
          }
          case InputTypes.checkbox:
            return (
              <FlexboxColumn sx={{gap: '16px'}}>
                {inputsMeta.map((inputMeta, i) => {
                  const inputValue = fieldValue?.values.checkbox?.find(
                    (text) => text.id === inputMeta.id
                  )
                  return (
                    <FlexboxColumn
                      key={i}
                      sx={{flex: '0 0 50%', boxSizing: 'border-box', gap: '4px'}}
                    >
                      <Typography
                        style={{color: colors.grey[inputValue?.value ? 100 : 40]}}
                        variant="p1"
                      >
                        {inputMeta.label}
                      </Typography>
                    </FlexboxColumn>
                  )
                })}
              </FlexboxColumn>
            )
          case InputTypes.comment: {
            const inputValue = fieldValue?.values.comment
            return <CommentContent comment={inputValue?.value} title={commentTitle} />
          }
          case InputTypes.table: {
            const tableIndex = 0
            const tableMeta = inputsMeta[tableIndex]
            const tableValue = fieldValue?.values.table?.find((table) => table.id === tableMeta.id)
            if (!tableValue) return <NotFilledText />

            const tableRegister = `${fieldRegister}.values.table.${tableIndex}.rows`
            return (
              <div style={{paddingBottom: '12px'}}>
                <FormInputTable
                  readonly
                  fieldId={fieldMeta.id}
                  form={form}
                  tableMeta={tableMeta}
                  tableName={tableRegister}
                  tableRows={tableValue.rows}
                />
              </div>
            )
          }
          case InputTypes.file:
            return (
              <FlexboxColumn gap="4px">
                {fieldValue?.values.file.items?.map((fileItem) => (
                  <FileLink
                    key={fileItem.nameInStore}
                    fieldName={fieldMeta.id}
                    fileBaseUrl={fileBaseUrl}
                    fileItem={fileItem}
                  />
                ))}
                <CommentContent
                  comment={fieldValue?.values.file.comment?.value}
                  title={commentTitle}
                />
              </FlexboxColumn>
            )
          case InputTypes.list: {
            const listIndex = 0
            const listMeta = inputsMeta[listIndex]
            const listRegister = `${fieldRegister}.values.list.${listIndex}.elements`
            const listElements = fieldValue?.values.list[listIndex].elements
            if (!listElements) return <NotFilledText />

            switch (listMeta.listType) {
              case 'collapsed':
                return (
                  <ListContent
                    readonly
                    form={form}
                    listElements={listElements}
                    listMeta={listMeta}
                    listRegister={listRegister}
                  />
                )
              case 'chips':
                return (
                  <MultipleTablesContent
                    readonly
                    fieldId={fieldMeta.id}
                    form={form}
                    listElements={listElements}
                    listMeta={listMeta}
                    listRegister={listRegister}
                  />
                )
              default:
                return <h1>not implemented</h1>
            }
          }
          default:
            return <h1>not implemented</h1>
        }
      })}
    </>
  )
}

export const getFieldComponent = ({
  inputMeta,
  form,
  indexes,
  fieldValue,
  inputProps = {},
  rootRegister = '',
}) => {
  const inputValue = fieldValue.values[inputMeta.type]
  if (Array.isArray(inputValue))
    indexes.input = inputValue.findIndex((inputVal) => inputVal.id === inputMeta.id)

  const baseRegisterName = `${rootRegister ? rootRegister + '.' : ''}${indexes.step}.fields.${indexes.field}.values`
  let inputName
  switch (inputMeta.type) {
    case InputTypes.comment:
      inputName = `${baseRegisterName}.comment.value`
      return (
        <FormInputText
          key={inputName}
          caption={inputMeta.hint}
          control={form.control}
          label={inputMeta.placeholder}
          name={inputName}
          textarea={inputMeta.textarea}
          width={TextareaInputWidth}
          {...inputProps}
        />
      )
    case InputTypes.text:
      inputName = `${baseRegisterName}.text.${indexes.input}.value`
      return (
        <FormInputText
          key={inputName}
          caption={inputMeta.hint}
          control={form.control}
          label={inputMeta.placeholder}
          name={inputName}
          textarea={inputMeta.textarea}
          width={getWidth(fieldValue.id, inputMeta)}
          {...inputProps}
        />
      )
    case InputTypes.checkbox:
      inputName = `${baseRegisterName}.checkbox.${indexes.input}.value`
      return (
        <FormInputCheckbox
          key={inputName}
          control={form.control}
          label={inputMeta.label}
          name={inputName}
          {...inputProps}
        />
      )
    case InputTypes.dateInput:
      inputName = `${baseRegisterName}.dateInput.${indexes.input}.value`
      return (
        <FlexboxColumn sx={{gap: '8px'}}>
          <Typography variant="p1">{inputMeta.label}</Typography>
          <FormInputDatetime
            key={inputName}
            caption={inputMeta.hint}
            control={form.control}
            label="Дата"
            name={inputName}
            width="290px"
            {...inputProps}
          />
        </FlexboxColumn>
      )
    case InputTypes.table:
      inputName = `${baseRegisterName}.table.${indexes.input}.rows`
      return (
        <FormInputTable
          key={inputName}
          fieldId={fieldValue.id}
          form={form}
          tableMeta={inputMeta}
          tableName={inputName}
          tableRows={fieldValue.values.table[indexes.input].rows}
          {...inputProps}
        />
      )
    case InputTypes.file:
      return (
        <FileContent
          baseRegisterName={baseRegisterName}
          fileInputProps={inputProps}
          form={form}
          inputMeta={inputMeta}
        />
      )
    case InputTypes.list: {
      const listRegister = `${baseRegisterName}.list.${indexes.input}.elements`
      const listElements = fieldValue.values.list[indexes.input].elements
      switch (inputMeta.listType) {
        case 'chips':
          return (
            <MultipleTablesContent
              fieldId={fieldValue.id}
              form={form}
              listElements={listElements}
              listMeta={inputMeta}
              listRegister={listRegister}
            />
          )
        case 'collapsed':
          return (
            <ListContent
              form={form}
              listElements={listElements}
              listMeta={inputMeta}
              listRegister={listRegister}
            />
          )
        default:
          return <h1>NOT IMPLEMENTED</h1>
      }
    }
    default:
      return <h1>NOT IMPLEMENTED</h1>
  }
}
