import React, {
  forwardRef,
  useRef,
  useState,
  useCallback,
  ComponentType,
  ReactNode,
  CSSProperties,
  HTMLAttributes,
  PropsWithChildren,
} from 'react'
import clsx from 'clsx'
import {PlacementTokenValues, SizeTokenValue, sizeTokenValues} from '@x5-react-uikit/tokens'
import {
  Dropdown,
  SelectList,
  Checkbox,
  sortDirections,
  columnfilterModes,
  DropdownInnerRefMethods,
} from '@x5-react-uikit/core'
import {
  ArrowDown as ArrowDownIcon,
  ArrowUp as ArrowUpIcon,
  SortAZ as SortAZIcon,
  SortZA as SortZAIcon,
  Filter as FilterIcon,
  EditOn as EditOnIcon,
  Add as AddIcon,
  Close as CloseIcon,
} from '@x5-react-uikit/icons'
import {StyledHeadCell, StyledHeadCellAction, StyledHeadCellWrap, classes} from './styles'

import type {ExtractConstValues} from 'core/helperTypes'
import type {SelectListPropsOnChange} from '../../kit'

import {isString} from 'lodash'

type OnChangeActionPayload = {
  type: 'checkbox' | 'filter' | 'sort'
  dir?: ExtractConstValues<typeof sortDirections>
  filter?: ExtractConstValues<typeof columnfilterModes>
  checked?: boolean
}

type OnChangeAction = (name: string, payload: OnChangeActionPayload) => void

type SortDirections = ExtractConstValues<typeof sortDirections>
type ColumnfilterModes = ExtractConstValues<typeof columnfilterModes>

type Props = PropsWithChildren<{
  name: string
  placement?: PlacementTokenValues
  qa?: string
  icon?: ComponentType<{className: string; size: SizeTokenValue}>
  component?: {
    filter: ColumnfilterModes | null
    checked: boolean | null
    sort: SortDirections | null
    type?: 'checkbox'
  }
  width?: CSSProperties['width']
  titleStyle?: CSSProperties
  noSideBorders?: boolean
  sortOptions?: {
    value: SortDirections
    name: string
    icon?: ReactNode
    label?: string
  }[]
  onChange?: OnChangeAction
  style?: CSSProperties
}> &
  HTMLAttributes<HTMLTableCellElement>

export const TableHeadCell = forwardRef<HTMLTableCellElement, Props>(
  (
    {
      placement,
      name,
      qa = 'head-cell', // TODO: NOT USED
      children,
      icon,
      component = {sort: null, filter: null, checked: null},
      sortOptions,
      onChange,
      width,
      style,
      noSideBorders,
      ...props
    },
    ref
  ) => {
    const dropdownRef = useRef<DropdownInnerRefMethods>()
    const [selected, setSelected] = useState<SortDirections>(sortDirections.unset)
    const [opened, toggleOpened] = useState(false)
    const hasFilter = component?.filter

    const handleSelectChange: SelectListPropsOnChange = (_event, value) => {
      const filterValue = value
      const sortValue = value
      if (sortValue === sortDirections.asc || sortValue === sortDirections.desc) {
        onChange?.(name, {type: 'sort', dir: sortValue})
        setSelected(sortValue)
      }

      if (isString(filterValue) && Object.values<string>(columnfilterModes).includes(filterValue)) {
        onChange?.(name, {type: 'filter', filter: filterValue as ColumnfilterModes})
      }

      dropdownRef.current.close()
    }

    const getAdornment = (opened, dir, filter) => {
      if (
        (!dir || dir === sortDirections.unset) &&
        (!filter || filter !== columnfilterModes.existed)
      ) {
        return opened ? (
          <ArrowUpIcon className={classes.end} size={sizeTokenValues.small} />
        ) : (
          <ArrowDownIcon className={classes.end} size={sizeTokenValues.small} />
        )
      }

      if (filter === columnfilterModes.existed) {
        return (
          <FilterIcon
            className={clsx(classes.end, classes.selected)}
            size={sizeTokenValues.small}
          />
        )
      }

      return dir === sortDirections.asc ? (
        <SortAZIcon className={clsx(classes.end, classes.selected)} size={sizeTokenValues.small} />
      ) : (
        <SortZAIcon className={clsx(classes.end, classes.selected)} size={sizeTokenValues.small} />
      )
    }

    const renderIcon = () => {
      if (icon) {
        const Icon = icon
        return <Icon className={classes.start} size={sizeTokenValues.small} />
      }
    }

    const renderCheckbox = useCallback(() => {
      const handleCheckboxChange = ({target}) =>
        onChange?.(name, {type: 'checkbox', checked: target.checked})

      if (component?.type === 'checkbox') {
        return <Checkbox checked={component?.checked} onChange={handleCheckboxChange} />
      }
    }, [component, name, onChange])

    const sortItems = component?.sort
      ? sortOptions || [
          {
            value: sortDirections.asc,
            name: 'По возрастанию',
            icon: <SortAZIcon size={sizeTokenValues.small} />,
            label: 'Сортировка',
          },
          {
            value: sortDirections.desc,
            name: 'По убыванию',
            icon: <SortZAIcon size={sizeTokenValues.small} />,
          },
        ]
      : []

    const filterItems: {value: string; name: string; icon: ReactNode; label?: string}[] = hasFilter
      ? [
          {
            value: columnfilterModes.added as string,
            name:
              component.filter === columnfilterModes.existed
                ? 'Изменить фильтр'
                : 'Добавить фильтр',
            icon:
              component.filter === columnfilterModes.existed ? (
                <EditOnIcon size={sizeTokenValues.small} />
              ) : (
                <AddIcon size={sizeTokenValues.small} />
              ),
            label: 'Фильтр',
          },
        ]
      : []

    if (hasFilter && component.filter === columnfilterModes.existed) {
      filterItems.push({
        value: columnfilterModes.cleared as string,
        name: 'Убрать фильтр',
        icon: <CloseIcon size={sizeTokenValues.small} />,
      })
    }

    const selectItems = [...sortItems, ...filterItems]

    return (
      <StyledHeadCell
        ref={ref}
        className={clsx(noSideBorders && 'no-side-borders')}
        data-name={name}
        style={{
          ...style,
          ...(width ? {width} : {}),
        }}
        {...props}
      >
        <StyledHeadCellWrap style={{width}}>
          {renderIcon()}
          {renderCheckbox()}
          {hasFilter || component?.sort ? (
            <Dropdown
              stretch
              action={
                <StyledHeadCellAction paddingX={width ? '12px' : undefined}>
                  <span className={classes.text} style={props.titleStyle}>
                    {children}
                  </span>
                  {getAdornment(opened, component?.sort, component?.filter)}
                </StyledHeadCellAction>
              }
              innerRef={dropdownRef}
              placement={placement}
              onChange={toggleOpened}
            >
              <SelectList items={selectItems} selected={selected} onChange={handleSelectChange} />
            </Dropdown>
          ) : (
            children
          )}
        </StyledHeadCellWrap>
      </StyledHeadCell>
    )
  }
)

export default TableHeadCell
