import {Box} from '@x5-react-uikit/core'
import FlexboxRow from '@root/components/FlexboxRow'
import GitlabSection from './GitlabSection'
import TestSettingsSection from './TestSettingsSection'
import LastRunSection from './LastRunSection'
import {useContext} from 'react'
import SpecialistToolsContext from '../../context'

const SystemSettingsTab = () => {
  const {selectedSystemId: systemId} = useContext(SpecialistToolsContext)

  return (
    <Box sx={{gap: '24px'}}>
      <FlexboxRow sx={{gap: '24px'}}>
        <GitlabSection systemId={systemId} />
        <LastRunSection systemId={systemId} />
      </FlexboxRow>
      <TestSettingsSection />
    </Box>
  )
}
export default SystemSettingsTab
