import {Modal, Typography} from '@x5-react-uikit/core'
import {TaskStatuses} from '@root/constants'
import useNotify from '@root/hooks/useNotify'
import {useNavigate, useParams} from 'react-router-dom'
import {useUpdateTaskStatusMutation} from '@root/redux/api/tasksApi'

const ChangeToWithdrawnModal = ({open, onClose}) => {
  const navigate = useNavigate()
  const {taskId} = useParams()
  const {notifyError, notifySuccess} = useNotify()

  const [updateTaskStatus] = useUpdateTaskStatusMutation()
  const handleChangeStatus = () => {
    const updateData = {nextTaskStatus: TaskStatuses.WITHDRAWN}
    updateTaskStatus({taskId, updateData})
      .unwrap()
      .then(() => {
        notifySuccess(
          'Заявка успешно отозвана',
          'Участники заявки получат уведомление об этом в outlook.'
        )
        onClose()
        navigate(`/tasks/draft/${taskId}/steps/1`)
      })
      .catch(() => notifyError())
  }

  return (
    <Modal
      closeOnOverlay
      buttons={[
        {
          onClick: onClose,
          side: 'right',
          text: 'Отмена',
        },
        {
          onClick: handleChangeStatus,
          side: 'right',
          text: 'Отозвать заявку',
        },
      ]}
      isOpen={open}
      size="medium"
      title="Отзыв заявки"
      onClose={onClose}
    >
      <Typography>
        Заявка будет отозвана, работы по ней будут окончены. Она будет переведена в ваши черновики.
        Вы сможете подать ее на рассмотрение снова, когда будете готовы.
      </Typography>
    </Modal>
  )
}

export default ChangeToWithdrawnModal
