import {LoaderIcon, Modal, baseTheme} from '@x5-react-uikit/core'
import {Done as DoneIcon, Error as ErrorIcon} from '@x5-react-uikit/icons'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FormInputSelect from '@root/components/inputs/formInputs/FormInputSelect'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'
import useNotify from '@root/hooks/useNotify'
import {isEmpty} from 'lodash'
import useCreateOrUpdateMetadata from '@root/pages/SpecialistToolsPage/hooks/useCreateOrUpdateMetadata'
import {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {
  useGetGitlabBranchesQuery,
  useGetGitlabProjectsQuery,
  useGetSystemMetaDataQuery,
} from '@root/redux/api/systemMetaDataApi'
import {GitlabFields} from '../../constants'
import {MODAL_INPUT_WIDTH_PX} from '@root/constants'
import {RequiredRule} from '@root/constants'

const {colors} = baseTheme

const RepoUrlValidityRule = {
  type: 'custom',
  message: 'Некорректная ссылка. Проверьте введенные данные',
}

const GitlabModal = ({systemId, open, onClose}) => {
  const {notifySuccess, notifyError} = useNotify()

  const {
    setError: setFormError,
    setValue: setFormValue,
    watch: watchFormValue,
    clearErrors: clearFormErrors,
    reset: resetFormValue,
    ...form
  } = useForm()
  useEffect(() => {
    const onFormUpdate = (value, change) => {
      if (change.name === GitlabFields.gitlabRepoUrl) {
        setFormValue(GitlabFields.gitlabProjectId, null)
        setFormValue(GitlabFields.gitlabBranch, null)
      }
      if (change.name === GitlabFields.gitlabProjectId) {
        setFormValue(GitlabFields.gitlabBranch, null)
      }
    }
    const subscription = watchFormValue(onFormUpdate)
    return () => subscription.unsubscribe()
  }, [watchFormValue, setFormValue])

  const formValue = watchFormValue()

  const {currentData: metaData} = useGetSystemMetaDataQuery({systemId}, {skip: systemId == null})
  useEffect(() => {
    resetFormValue({
      [GitlabFields.gitlabProjectId]: metaData?.[GitlabFields.gitlabProjectId],
      [GitlabFields.gitlabRepoUrl]: metaData?.[GitlabFields.gitlabRepoUrl],
      [GitlabFields.gitlabBranch]: metaData?.[GitlabFields.gitlabBranch],
    })
  }, [resetFormValue, metaData])

  const {
    currentData: projects,
    isFetching: isProjectsFetching,
    isError: projectsError,
  } = useGetGitlabProjectsQuery(
    {systemId, projectURL: formValue.gitlabRepoUrl},
    {skip: !formValue.gitlabRepoUrl}
  )

  const {currentData: branches} = useGetGitlabBranchesQuery(
    {systemId, projectId: formValue.gitlabProjectId},
    {skip: !formValue.gitlabProjectId}
  )
  useEffect(() => {
    if (!projectsError) clearFormErrors(GitlabFields.gitlabRepoUrl)
    else setFormError(GitlabFields.gitlabRepoUrl, RepoUrlValidityRule)
  }, [projectsError, setFormError, clearFormErrors])

  const createOrUpdateMetadata = useCreateOrUpdateMetadata(systemId)
  const handleSave = form.handleSubmit(
    async (fields) => {
      const createData = {
        ...fields,
        gitlabProjectName: projects.find((project) => project.id === fields.gitlabProjectId)?.name,
      }
      return createOrUpdateMetadata(createData)
        .then(() => {
          notifySuccess(
            'Изменения сохранены',
            'Сохраненные настройки будут использоваться при запуске следующего теста.'
          )
          onClose()
        })
        .catch((error) => {
          notifyError()
          console.log(error)
        })
    },
    (error) => {
      console.log(error)
    }
  )

  const handleCancel = () => {
    onClose()
    resetFormValue({
      [GitlabFields.gitlabProjectId]: metaData?.[GitlabFields.gitlabProjectId],
      [GitlabFields.gitlabRepoUrl]: metaData?.[GitlabFields.gitlabRepoUrl],
      [GitlabFields.gitlabBranch]: metaData?.[GitlabFields.gitlabBranch],
    })
  }

  let repoUrlEndAdornment = <DoneIcon color={colors.accent[90]} />
  if (isProjectsFetching) repoUrlEndAdornment = <LoaderIcon color={colors.accent[90]} />
  else if (projectsError || projects?.length === 0)
    repoUrlEndAdornment = <ErrorIcon color={colors.error} />
  else if (!formValue.gitlabRepoUrl) repoUrlEndAdornment = undefined

  return (
    <>
      <Modal
        closeOnOverlay
        buttons={[
          {
            onClick: handleCancel,
            side: 'right',
            text: 'Отмена',
          },
          {
            // TODO: исправить логику сабмита и обработки ошибок. По неизвестной причине
            // при сабмите ошибки затираются, поэтому впилен следующий костыль
            onClick: isEmpty(form.formState.errors) ? handleSave : () => {},
            side: 'right',
            text: 'Сохранить изменения',
          },
        ]}
        description="Введите соответствующие параметры для работы с заявкой"
        isOpen={open}
        size="medium"
        title="GitLab"
        onClose={handleCancel}
      >
        <FlexboxColumn sx={{gap: '24px'}}>
          <FormInputText
            control={form.control}
            endAdornment={repoUrlEndAdornment}
            label="Ссылка на репозиторий"
            name={GitlabFields.gitlabRepoUrl}
            rules={RequiredRule}
            width={MODAL_INPUT_WIDTH_PX}
          />
          <FormInputSelect
            control={form.control}
            disabled={projectsError || projects?.length === 0}
            label="Название проекта"
            name={GitlabFields.gitlabProjectId}
            options={projects?.map((project) => ({name: project.name, value: project.id})) || []}
            rules={RequiredRule}
            width={MODAL_INPUT_WIDTH_PX}
          />
          <FormInputSelect
            control={form.control}
            disabled={!branches}
            label="Ветка"
            name={GitlabFields.gitlabBranch}
            options={branches?.map((branch) => ({name: branch, value: branch})) || []}
            rules={RequiredRule}
            width={MODAL_INPUT_WIDTH_PX}
          />
        </FlexboxColumn>
      </Modal>
    </>
  )
}

export default GitlabModal
