import React, {CSSProperties, FC, useState, useEffect} from 'react'
import clsx from 'clsx'

import {usePrivateDropdownStyles} from '../../../PrivateDropdown'
import {EditableCellPortal} from '../EditableCellPortal'
import {Calendar} from '../../../Calendar'

import {BodyCellInnerDateEditProps} from './types'
import {useInnerDateStyles} from './styles'

const getStyles = (ref, forFakeBlock) => {
  const editableBorderWidth = 3
  const anchor = ref.current || ref
  const styles: CSSProperties = {}

  if (anchor) {
    const rect = anchor.getBoundingClientRect()
    const targetHeight = rect.height + editableBorderWidth

    if (forFakeBlock) {
      styles.width = rect.width
      styles.height = targetHeight
    }
  }

  return styles
}

export const BodyCellInnerDateEdit: FC<BodyCellInnerDateEditProps> = (
  props: BodyCellInnerDateEditProps
) => {
  const {rootRef, children: value, onCancel, onChange} = props
  const [date, setDate] = useState('')

  const dropdownClasses = usePrivateDropdownStyles()
  const innerClasses = useInnerDateStyles()

  const onClickAway = (event) => {
    event.stopPropagation()
    onCancel && onCancel()
  }

  const onClickFakeDate = () => onCancel && onCancel()
  const onPick = (formatted, date) => {
    setDate(formatted)
    onChange(date)
  }

  useEffect(() => {
    setDate((value as string) || '')
  }, [value])

  return (
    <EditableCellPortal cellRef={rootRef} onClickAway={onClickAway}>
      <>
        <div
          className={innerClasses.innerFakeInput}
          style={getStyles(rootRef, true)}
          onClick={onClickFakeDate}
        />
        <div
          className={clsx(innerClasses.innerDropdown, dropdownClasses.root)}
          style={getStyles(rootRef, false)}
        >
          <Calendar date={date} onChange={onPick} />
        </div>
      </>
    </EditableCellPortal>
  )
}

export default BodyCellInnerDateEdit
