import FlexboxColumn from '@root/components/FlexboxColumn'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'
import {FC} from 'react'
import {UseTaskPageForm} from '../hooks/useTaskPageForm'

type Props = {
  form: UseTaskPageForm['form']
  cppEditable: boolean
}

const BudgetFieldEdit: FC<Props> = ({form, cppEditable = true}) => {
  // const onChangeCpp = (e) => setCppInputValue(e.target.value)
  // const onChangeCost = (e) => setCostInputValue(e.target.value)

  return (
    <FlexboxColumn sx={{gap: '24px', width: '536px'}}>
      <FormInputText
        caption="Пример: I-041114-05"
        control={form.control}
        disabled={!cppEditable}
        label="Номер статьи"
        name="finportal.cpp"
        width="300px"
      />
      <FormInputText
        caption="За выполнение нагрузочного тестирования"
        control={form.control}
        label="Стоимость работ"
        name="finportal.workCost"
        width="300px"
      />
    </FlexboxColumn>
  )
}

export default BudgetFieldEdit
