import React, {FC, useCallback} from 'react'
import {Select, SelectOptionType, SelectProps} from '@root/components/kit'
import {SystemStatusesMap} from '../constants'

type Props = {
  onChange: (next: string) => void
  value: string
  selectProps?: Omit<
    SelectProps,
    'value' | 'onChange' | 'options' | 'filter' | 'onFilterChange' | 'onClearClick'
  >
}

const options: SelectOptionType[] = Object.entries(SystemStatusesMap).map(([key, value]) => ({
  name: value.label,
  value: key,
}))

export const SystemStatusSelect: FC<Props> = ({onChange, value, selectProps}) => {
  const handleChange: SelectProps['onChange'] = useCallback(
    (_e, val) => {
      onChange(val as string)
    },
    [onChange]
  )

  const onClearClick: SelectProps['onClearClick'] = useCallback(() => {
    onChange(null)
  }, [onChange])

  return (
    <Select
      {...selectProps}
      options={options}
      value={value}
      width="100%"
      onChange={handleChange}
      onClearClick={onClearClick}
    />
  )
}
