import React, {forwardRef} from 'react'
import clsx from 'clsx'
import {sizeTokenValues} from '@x5-react-uikit/tokens'
import * as icons from '@x5-react-uikit/icons'
import {IconButton} from '../IconButton'
import {InnerButtonProps, innerButtonSizes} from './types'
import {useStyles} from './styles'

export const InnerButton = forwardRef<HTMLButtonElement, InnerButtonProps>(
  ({icon, size, IconComponent, mode, ...props}, ref) => {
    const Icon = icons[icon]
    const classes = useStyles({mode})

    return (
      <IconButton
        {...props}
        ref={ref}
        className={clsx(
          classes.root,
          size !== innerButtonSizes.small && classes[size],
          props.className
        )}
        IconComponent={IconComponent || <Icon size={sizeTokenValues.small} />}
      />
    )
  }
)

export default InnerButton
