import {makeUiStyles} from '../../theme'

export const useStyles = makeUiStyles(
  (theme) => ({
    root: {
      display: 'table-row',
    },
    hovered: {
      '&:hover > div': {
        background: theme.colors.grey[10],
        borderColor: theme.colors.grey[20],
        '&:after': {
          borderTopColor: 'transparent',
          borderColor: theme.colors.grey[20],
        },
      },
    },
    selected: {
      '& > div': {
        background: theme.colors.accent[10],
        borderColor: theme.colors.accent[20],
        '&:after': {
          borderTopColor: 'transparent',
          borderColor: theme.colors.accent[20],
        },
      },
    },
  }),
  'table-row'
)
