import {KEYCLOAK_REALM} from '@root/constants'
import {KEYCLOAK_CLIENT_ID} from '@root/constants'
import {KEYCLOAK_LOGIN_REDIRECT} from '@root/constants'
import {KEYCLOAK_BASE_URL} from '@root/constants'
import Keycloak from 'keycloak-js'
import {useCallback, useEffect, useState} from 'react'

const keycloakSetting = {
  url: KEYCLOAK_BASE_URL,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID,
}

/**
 * @type {Keycloak.KeycloakInstance}
 */
export const keycloakClient = new Keycloak(keycloakSetting)

function silentRenewRec(minValidity = 60) {
  if (!keycloakClient.tokenParsed) {
    return
  }

  const timeout =
    ((keycloakClient.tokenParsed.exp * 1000 - Date.now()) * 0.001 - minValidity) * 1000

  setTimeout(() => {
    keycloakClient
      .updateToken(minValidity)
      .then(() => {
        silentRenewRec(minValidity)
      })
      .catch(() => {
        console.error('Failed to refresh the token, or the session has expired')
        keycloakClient.login()
      })
  }, timeout)
}

/**
 * @typedef {Object} KeycloakHelper
 * @property {Keycloak.KeycloakInstance} keycloakClient
 * @property {Function} logout
 * @type {KeycloakHelper}
 */
const useKeycloak = () => {
  const [inited, setInited] = useState(false)

  useEffect(() => {
    keycloakClient
      .init({
        redirectUri: KEYCLOAK_LOGIN_REDIRECT,
        pkceMethod: 'S256',
        checkLoginIframe: true,
      })
      .then(() => {
        setInited(true)
        silentRenewRec()
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  const logout = useCallback(() => {
    let url = new URL(keycloakClient.createLogoutUrl())
    url.searchParams.delete('redirect_uri')
    url.searchParams.set('post_logout_redirect_uri', window.location.href)
    url.searchParams.set('id_token_hint', keycloakClient.idToken)
    window.location.href = url
  }, [])

  return inited ? {keycloakClient, keycloakLogout: logout} : {}
}

export default useKeycloak
