import {Box, IconButton, baseTheme} from '@x5-react-uikit/core'
import {Apps as AppsIcon, ChevronRight as ChevronRightIcon} from '@x5-react-uikit/icons'
import FlexboxRow from '@root/components/FlexboxRow'
import {useDispatch} from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'
import {invalidateSystemsAction} from '@root/redux/api/systemsApi'
import {invalidateTasksAction} from '@root/redux/api/tasksApi'
import {useGetUserinfoQuery} from '@root/redux/api/userApi'
import {NOT_IMPLEMENTED} from 'utils'
import FlexboxColumn from '../FlexboxColumn'
import {ReactComponent as ReportsIcon} from './assets/Reports.svg'
import {ReactComponent as SpecialistToolsIcon} from './assets/SpecialistTools.svg'
import {ReactComponent as SystemsIcon} from './assets/Systems.svg'
import {ReactComponent as TasksIcon} from './assets/Tasks.svg'
import {Roles} from '../../constants'

const {colors} = baseTheme

const ButtonKeys = {
  tasks: 'tasks',
  admin: 'admin',
  systems: 'systems',
  tools: 'tools',
  reports: 'reports',
}

const getActiveButtonKey = (pathname) => {
  if (pathname.startsWith('/tasks')) return ButtonKeys.tasks
  if (pathname.startsWith('/admin')) return ButtonKeys.admin
  if (pathname.startsWith('/systems')) return ButtonKeys.systems
  if (pathname.startsWith('/tools')) return ButtonKeys.tools
  if (pathname.startsWith('/reports')) return ButtonKeys.reports
}

const SidebarButton = ({iconComponent, buttonKey, onClick}) => {
  const location = useLocation()
  const activeButton = getActiveButtonKey(location.pathname)

  if (buttonKey === activeButton)
    return (
      <FlexboxRow
        sx={{backgroundColor: 'white', borderRadius: 'x2', width: '40px', height: '40px'}}
      >
        <Box
          sx={{
            position: 'absolute',
            minWidth: '3px',
            height: '26px',
            borderTopRightRadius: 'x10',
            borderBottomRightRadius: 'x10',
            backgroundColor: 'accent.90',
            alignSelf: 'center',
          }}
        />
        <IconButton
          IconComponent={iconComponent}
          style={{color: colors.accent[90]}}
          variant="text"
          onClick={onClick}
        />
      </FlexboxRow>
    )
  return <IconButton IconComponent={iconComponent} variant="text" onClick={onClick} />
}

const Sidebar = () => {
  const {data: userInfo = {role: ''}} = useGetUserinfoQuery()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  /**
   * Инвалидируем кеш хранилища при переходах в Раздел заявок или в Раздел ИС
   */
  const clearCacheAndNavigate = (route) => {
    const clickedButton = getActiveButtonKey(route)
    if (clickedButton === ButtonKeys.systems) {
      dispatch(invalidateSystemsAction)
    } else if (clickedButton === ButtonKeys.tasks) {
      dispatch(invalidateTasksAction)
    }
    navigate(route)
  }

  return (
    <FlexboxColumn
      sx={{
        width: '48px',
        height: '90vh',
        mt: 'x2',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <FlexboxColumn>
        <SidebarButton
          buttonKey={ButtonKeys.tasks}
          iconComponent={<TasksIcon />}
          onClick={() => clearCacheAndNavigate('/tasks')}
        />
        {[Roles.ADMIN, Roles.MANAGER, Roles.SPECIALIST].includes(userInfo.role) && (
          <SidebarButton
            buttonKey={ButtonKeys.systems}
            iconComponent={<SystemsIcon />}
            onClick={() => clearCacheAndNavigate('/systems')}
          />
        )}
        <SidebarButton
          buttonKey={ButtonKeys.reports}
          iconComponent={<ReportsIcon />}
          onClick={() => clearCacheAndNavigate('/reports')}
        />
        {userInfo.isAdmin && (
          <SidebarButton
            buttonKey={ButtonKeys.admin}
            iconComponent={<AppsIcon />}
            onClick={() => clearCacheAndNavigate('/admin')}
          />
        )}
        {[Roles.ADMIN, Roles.SPECIALIST, Roles.MANAGER, Roles.BUSINESS].includes(userInfo.role) && (
          <SidebarButton
            buttonKey={ButtonKeys.tools}
            iconComponent={<SpecialistToolsIcon />}
            onClick={() => clearCacheAndNavigate('/tools')}
          />
        )}
      </FlexboxColumn>
      <IconButton IconComponent={<ChevronRightIcon />} variant="text" onClick={NOT_IMPLEMENTED} />
    </FlexboxColumn>
  )
}

export default Sidebar
