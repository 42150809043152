import React, {FC, MouseEventHandler} from 'react'
import {Calendar as CalendarIcon} from '@x5-react-uikit/icons'
import {BaseAdornmentProps} from './types'

interface DateAdornmentProps extends BaseAdornmentProps {
  onClick?: MouseEventHandler
}

export const DateAdornment: FC<DateAdornmentProps> = ({size = 'large', color, onClick}) => (
  <CalendarIcon color={color} size={size} onClick={onClick} />
)

export default DateAdornment
