import type {FC} from 'react'
import {Typography} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import Section from '@root/components/Section'
import {useParams} from 'react-router-dom'
import {useGetReportByIdQuery} from '@root/redux/api/launchesApi'

const ReportAffiliation: FC = () => {
  const {reportId} = useParams()
  const {data: report} = useGetReportByIdQuery(reportId)

  return (
    <Section sx={{my: 'x0', width: '588px'}}>
      <FlexboxColumn sx={{gap: '24px'}}>
        <Typography variant="h3">Принадлежность</Typography>
        <FlexboxColumn sx={{gap: '24px'}}>
          <FlexboxRow sx={{gap: '64px'}}>
            <FlexboxColumn sx={{width: '544px', gap: '4px'}}>
              <Typography variant="p1compact">Тестирование</Typography>
              <Typography variant="p1compact">
                {report?.testNumber ? `ID ${report.testNumber}` : '-'}
              </Typography>
            </FlexboxColumn>
            <FlexboxColumn sx={{width: '544px', gap: '4px'}}>
              <Typography variant="p1compact">Шаблон переменных</Typography>
              <Typography variant="p1compact">{report?.variablesTemplateName || '-'}</Typography>
            </FlexboxColumn>
          </FlexboxRow>
          <FlexboxRow sx={{gap: '64px'}}>
            <FlexboxColumn sx={{width: '544px', gap: '4px'}}>
              <Typography variant="p1compact">Информационная система</Typography>
              <Typography variant="p1compact">{report?.system?.systemTag || '-'}</Typography>
            </FlexboxColumn>
            <FlexboxColumn sx={{width: '544px', gap: '4px'}}>
              <Typography variant="p1compact">Заявка</Typography>
              <Typography variant="p1compact">
                {report?.taskNumber ? `#${report.taskNumber}` : '-'}
              </Typography>
            </FlexboxColumn>
          </FlexboxRow>
        </FlexboxColumn>
      </FlexboxColumn>
    </Section>
  )
}

export default ReportAffiliation
