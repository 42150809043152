import {sizeTokenValues} from '@x5-react-uikit/tokens'
import {
  getPaddingTableCell,
  getBorderColorTable,
  getBorderStyleTable,
  getStickedColumnSelector,
} from './helpers'
import {DataGridTableProps} from './Table/types'
import {DataGridRowProps} from './Row/types'
import {StyledButtonCell} from './Controls/styles'
import {DataGridCellProps, cellAlignValues} from './Cell/types'
import {PREFIX, restyled, stateClasses} from '../styles'

export const classes = {
  unborder: `${PREFIX}-datagrid-table-unborder`,
} as const

export const cellClasses = {
  compound: `${PREFIX}-datagrid-cell-compound`,
  inner: `${PREFIX}-datagrid-cell-inner`,
  start: `${PREFIX}-datagrid-cell-start`,
  end: `${PREFIX}-datagrid-cell-end`,
} as const

export const StyledTable = restyled.table<DataGridTableProps>(
  ({
    theme: {spaces, colors, typography},
    size,
    unborder,
    unstable_stickedColumnCount: columnCount,
  }) => {
    return {
      position: 'relative',
      borderCollapse: 'collapse',
      color: colors.grey[100],
      fontFamily: typography.base.fontFamily,
      fontSize:
        size === sizeTokenValues.large ? typography.p1compact.fontSize : typography.p2.fontSize,
      lineHeight:
        size === sizeTokenValues.large ? typography.p1compact.lineHeight : typography.p2.lineHeight,
      letterSpacing:
        size === sizeTokenValues.large
          ? typography.p1compact.letterSpacing
          : typography.p2.letterSpacing,
      ...getBorderStyleTable(colors),
      '& td, & th': {
        boxSizing: 'border-box',
        padding: getPaddingTableCell(size, spaces),
        ...getBorderStyleTable(colors, unborder ? '1px 0' : 1),
      },
      '& th': {
        position: unborder ? 'relative' : null,
        borderBottomWidth: 2,
        fontSize: size === sizeTokenValues.large ? typography.h4.fontSize : typography.h5.fontSize,
        lineHeight:
          size === sizeTokenValues.large ? typography.h4.lineHeight : typography.h5.lineHeight,
        fontWeight: parseInt(typography.h4.fontWeight),
        letterSpacing:
          size === sizeTokenValues.large
            ? typography.h4.letterSpacing
            : typography.h5.letterSpacing,
        '&:hover': {
          borderBottomColor: colors.grey[20],
          backgroundColor: colors.grey[10],
        },
        '&:hover::after': {
          backgroundColor: colors.grey[20],
        },
        '&::after': unborder
          ? {
              position: 'absolute',
              top: spaces.x4,
              left: '100%',
              bottom: spaces.x4,
              content: '""',
              display: 'block',
              marginLeft: -spaces.x1,
              width: spaces.x1,
              height: 'auto',
              backgroundColor: getBorderColorTable(colors),
            }
          : null,
        '&:last-child::after': {
          display: unborder ? 'none' : null,
        },
      },
      '& tr >': columnCount > 0 ? getStickedColumnSelector(colors, columnCount) : null,
    }
  }
)

export const StyledExpanderCell = restyled(StyledButtonCell)<{
  depth: DataGridCellProps['depth']
  hasContent: boolean
}>(({theme: {spaces}, depth, hasContent}) => ({
  '&&': {
    marginLeft: spaces.x10 * depth,
    marginRight: hasContent ? spaces.x4 : null,
  },
}))

export const StyledCell = restyled.td<any>(
  ({
    theme: {colors, spaces},
    hasContent,
    error,
    disabled,
    nested,
    indented,
    align,
    noWrap,
    compacted,
    averaged,
  }) => {
    const radius = spaces.x2
    const colorDisabled = colors.grey[40]

    return {
      position: nested ? 'relative' : null,
      verticalAlign: 'top',
      textAlign: align === cellAlignValues.right ? 'right' : 'left',
      caretColor: colors.accent[90],
      whiteSpace: noWrap ? 'nowrap' : null,
      color: disabled ? colorDisabled : null,
      'th&': {
        textAlign: 'left',
      },
      'td&': {
        padding: !indented ? 0 : null,
      },
      'td&:hover': {
        backgroundColor: colors.grey[20],
        outline: `1px solid ${colors.grey[40]}`,
      },
      [`td&:focus, &.${stateClasses.isFocused}`]: {
        backgroundColor: colors.white,
        boxShadow: `inset 0 0 0 2px ${colors.accent[90]}`,
        borderRadius: radius,
        outline: 'none',
      },
      [`td&:hover:focus, &.${stateClasses.isFocused}:hover`]: {
        backgroundColor: colors.white,
        outline: 'none',
      },
      [`& .${cellClasses.compound}`]: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      [`& .${cellClasses.inner}`]: {
        flexGrow: 1,
      },
      [`& .${cellClasses.start}`]: {
        marginRight: hasContent ? spaces.x4 : null,
      },
      [`& .${cellClasses.end}`]: {
        marginLeft: hasContent ? spaces.x4 : null,
      },
      '[data-size=small] &': {
        padding: compacted ? spaces.x2 : averaged ? spaces.x3 : null,
      },
      '[data-size=medium] &': {
        padding: compacted ? spaces.x4 : averaged ? spaces.x5 : null,
      },
      '[data-size=large] &': {
        padding: compacted ? spaces.x5 : averaged ? spaces.x6 : null,
      },
      ...(error
        ? {
            boxShadow: `inset 0 0 0 2px ${colors.error}`,
            borderRadius: radius,
            caretColor: colors.error,
          }
        : null),
    }
  }
)

export const StyledRow = restyled.tr<DataGridRowProps>(({theme: {colors}, selected}) => ({
  '&:hover td': {
    backgroundColor: colors.grey[10],
    borderColor: colors.grey[20],
    [`&:focus, &.${stateClasses.isFocused}`]: {
      backgroundColor: colors.white,
    },
  },
  '& td': selected
    ? {
        backgroundColor: colors.accent[10],
        borderColor: colors.accent[20],
        borderTop: `1px solid ${colors.accent[60]}`,
      }
    : null,
}))
