import {createContext} from 'react'
import {UserRolesInfo} from '@root/core/UserRolesInfo'

export type TableContextState = {
  rolesMap: Record<string, string>
  userInfo: UserRolesInfo
  onUpdate: (id: string) => void
  onDelete: (id: string) => void
}

export const tableContext = createContext<TableContextState | null>(null)
export const TableContextProvider = tableContext.Provider
