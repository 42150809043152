import React, {FC} from 'react'
import {ThemeProvider as EmotionThemeProvider, CacheProvider, Global, css} from '@emotion/react'
import createCache from '@emotion/cache'

import {ThemeContext} from './context'
import {baseTheme} from './themes'
import {ThemeProviderProps} from './types'
import {FontStyles} from './styles/font'

const insertionPoint = document.createElement('noscript')
insertionPoint.id = 'insert-emotion'
document.head.appendChild(insertionPoint)

const x5Cache = createCache({
  key: 'kit',
  insertionPoint,
})

export const ThemeProvider: FC<ThemeProviderProps> & {themeName?: string} = ({
  theme,
  children,
  _font = 'join',
}) => {
  const currentTheme = theme ?? baseTheme
  const globalStyles = _font
    ? FontStyles
    : css(`body {
            font-family: inherit;
        }`)

  ThemeProvider.themeName = currentTheme.name

  if (!_font) {
    Object.keys(currentTheme.typography).forEach((key) => {
      currentTheme.typography[key].fontFamily = 'inherit'
    })
  }

  return (
    <ThemeContext.Provider value={{theme: currentTheme}}>
      <Global styles={globalStyles} />
      <CacheProvider value={x5Cache}>
        <EmotionThemeProvider theme={currentTheme}>{children}</EmotionThemeProvider>
      </CacheProvider>
    </ThemeContext.Provider>
  )
}
