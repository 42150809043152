import React, {ChangeEvent, FC, useCallback} from 'react'
import {Select} from '../Select'
import {Input} from '../Input'
import {Datepicker} from '../Datepicker'

import {
  FilterCollectorFilter,
  filterCollectorColumnType,
  FilterCollectorItemProps,
  CustomFilterInputProps,
} from './types'
import {updateFilters, subItemWidth} from './helpers'
import {Combobox} from '../Select'

export const SubItem: FC<FilterCollectorItemProps & {disabled: boolean}> = ({
  filter,
  filters,
  columns,
  onChange,
  setMultiselectFilterValues,
  multiselectFilterValues,
  size,
  disabled,
}) => {
  const column = columns.find((item) => item.value === filter.name)

  const handleCustomChange: CustomFilterInputProps['onChange'] = useCallback(
    (value) => {
      const newFilters = updateFilters(filters, filter.name, {...filter, value})
      onChange(newFilters)
    },
    [filter, filters, onChange]
  )

  if (column?.type === 'custom') {
    if (column.customInputComponent == null) {
      throw new Error(`Please provide a customInputComponent prop for this column ${column.name}`)
    }

    const C = column.customInputComponent

    return <C size={size} value={filter.value} onChange={handleCustomChange} />
  }

  const handleText = (event) => {
    const {value} = event.target
    const newFilters = updateFilters(filters, filter.name, {...filter, value})
    onChange(newFilters)
  }

  const handleSelect = (_, value: FilterCollectorFilter['name']) => {
    const newFilters = updateFilters(filters, filter.name, {...filter, value})
    onChange(newFilters)
  }

  const handleDateChange = (value: string | string[]) => {
    const newFilters = updateFilters(filters, filter.name, {...filter, value})
    onChange(newFilters)
  }

  const handleMultiselectFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target

    setMultiselectFilterValues({
      ...multiselectFilterValues,
      [filter.name]: value,
    })
  }

  const clearMultiselectFilterHandle = () => {
    const newFilters = updateFilters(filters, filter.name, {...filter, value: []})

    onChange(newFilters)
    setMultiselectFilterValues({
      ...multiselectFilterValues,
      [filter.name]: '',
    })
  }
  if ((column?.type as filterCollectorColumnType) === filterCollectorColumnType.combobox) {
    const filteredData = column.data || []
    return (
      <Combobox
        label="Значение"
        options={filteredData}
        size={size}
        value={filter.value}
        width={subItemWidth}
        onChange={handleSelect}
        onClearClick={clearMultiselectFilterHandle}
        onFilterChange={handleMultiselectFilterChange}
      />
    )
  }

  if (
    [filterCollectorColumnType.date, filterCollectorColumnType.period].includes(
      column?.type as filterCollectorColumnType
    )
  ) {
    const isPeriod = column?.type === filterCollectorColumnType.period
    const value = filter.value as string | string[]

    return (
      <Datepicker
        date={isPeriod ? value || [] : value || ''}
        InputProps={{label: 'Дата'}}
        size={size}
        width={subItemWidth}
        onChange={handleDateChange}
      />
    )
  }

  if (
    [filterCollectorColumnType.multiselect, filterCollectorColumnType.select].includes(
      column?.type as filterCollectorColumnType
    )
  ) {
    const isMultiselect = column?.type === filterCollectorColumnType.multiselect
    const dataFilterValue = multiselectFilterValues[filter.name] || ''
    const filteredData = column.data?.filter((option) =>
      option.name.toLocaleLowerCase().match(dataFilterValue.toLocaleLowerCase())
    )

    return (
      <Select
        usePortal
        filter={dataFilterValue}
        label="Значение"
        multiple={isMultiselect}
        options={filteredData || []}
        size={size}
        value={filter.value}
        width={subItemWidth}
        onChange={handleSelect}
        onClearClick={clearMultiselectFilterHandle}
        onFilterChange={handleMultiselectFilterChange}
      />
    )
  }

  return (
    <Input
      disabled={disabled}
      label="Значение"
      size={size}
      value={filter.value as string}
      width={subItemWidth}
      onChange={handleText}
    />
  )
}

export default SubItem
