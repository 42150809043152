import {Modal} from '@root/components/kit'
import useNotify from '@root/hooks/useNotify'
import useCreateOrUpdateMetadata from '@root/pages/SpecialistToolsPage/hooks/useCreateOrUpdateMetadata'
import {
  UniqueVarNameRule as UniqueVarKeyRule,
  getDuplicatedVarRegisters,
} from '@root/pages/SpecialistToolsPage/utils'
import {useContext} from 'react'
import {useGetUserinfoQuery} from '@root/redux/api/userApi'
import SpecialistToolsContext from '../../context'
import VariableInputsList from '../templatesTab/VariableInputsList'
import SaveNewTemplateButton from './SaveNewTemplateButton'

const VariablesUpdateModal = ({open, onClose}) => {
  const {notifySuccess, notifyError} = useNotify()

  const {data: userInfo} = useGetUserinfoQuery()

  const {form, selectedSystemId: systemId} = useContext(SpecialistToolsContext)

  const createOrUpdateMetadata = useCreateOrUpdateMetadata(systemId)
  const handleSaveLaunchVariables = form.handleSubmit((fields) => {
    const duplicats = getDuplicatedVarRegisters(fields.variables)
    if (duplicats.length > 0) {
      duplicats.forEach((registerName) => form.setError(registerName, UniqueVarKeyRule))
      return
    }

    createOrUpdateMetadata(fields)
      .then(() => {
        notifySuccess(
          'Изменения сохранены',
          'Сохраненные настройки будут использоваться при запуске следующего теста.'
        )
        onClose()
      })
      .catch((error) => {
        console.error(error)
        notifyError()
      })
  })

  const buttons = [
    {
      onClick: onClose,
      side: 'right',
      text: 'Отмена',
    },
    {
      onClick: handleSaveLaunchVariables,
      side: 'right',
      text: 'Сохранить изменения',
    },
  ]
  if (!userInfo.isBusiness)
    buttons.splice(1, 0, {
      side: 'right',
      text: 'Сохранить как новый шаблон',
      content: <SaveNewTemplateButton closeModal={onClose} form={form} systemId={systemId} />,
    })

  return (
    <>
      <Modal
        closeOnOverlay
        buttons={buttons}
        isOpen={open}
        size="medium"
        title="Редактирование шаблона настроек"
        variant="sideModal"
        onClose={onClose}
      >
        {open && <VariableInputsList form={form} />}
      </Modal>
    </>
  )
}

export default VariablesUpdateModal
