// @ts-nocheck TODO: JUST NEED TO DEFINE d.ts for woff modules
import {css} from '@emotion/react'
import {typography} from '@x5-react-uikit/tokens'
import X5SansUIRegular from '../fonts/X5-Sans-UI_Regular/X5-Sans-UI_Regular.woff'
import X5SansUIRegular2 from '../fonts/X5-Sans-UI_Regular/X5-Sans-UI_Regular.woff2'

import X5SansUIMedium from '../fonts/X5-Sans-UI_Medium/X5-Sans-UI_Medium.woff'
import X5SansUIMedium2 from '../fonts/X5-Sans-UI_Medium/X5-Sans-UI_Medium.woff2'

import X5SansUIBold from '../fonts/X5-Sans-UI_Bold/X5-Sans-UI_Bold.woff'
import X5SansUIBold2 from '../fonts/X5-Sans-UI_Bold/X5-Sans-UI_Bold.woff2'

export const FontStyles = css`
  body {
    @font-face {
      font-family: 'X5 Sans UI';
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src:
        url(${X5SansUIRegular2}) format('woff2'),
        url(${X5SansUIRegular}) format('woff');
    }

    @font-face {
      font-family: 'X5 Sans UI';
      font-style: normal;
      font-weight: 500;
      font-display: swap;
      src:
        url(${X5SansUIMedium2}) format('woff2'),
        url(${X5SansUIMedium}) format('woff');
    }

    @font-face {
      font-family: 'X5 Sans UI';
      font-style: normal;
      font-weight: 700;
      font-display: swap;
      src:
        url(${X5SansUIBold2}) format('woff2'),
        url(${X5SansUIBold}) format('woff');
    }

    font-family: ${typography.base.fontFamily};
  }
`
