import {PREFIX, restyled, doSelectors} from '../styles'
import {ButtonBase} from '../Button/ButtonBase'
import {StyledTabProps, StyledTabListProps} from './types'

export const tabsClasses = {
  holder: `${PREFIX}-tablist-holder`,
  counter: `${PREFIX}-tab-counter`,
} as const

const selectors = doSelectors(tabsClasses)

export const StyledTabs = restyled.div(({theme}) => ({...theme.typography.base}))

export const StyledTabList = restyled.div<StyledTabListProps>(
  ({theme: {spaces, colors}, stripeWidth, left, disabled}) => ({
    position: 'relative',
    overflowX: 'hidden',
    flex: '1 1 auto',
    display: 'inline-block',
    width: 'fit-content',
    whiteSpace: 'nowrap',
    [selectors.holder]: {
      position: 'relative',
      display: 'flex',
      overflow: 'hidden',
      minWidth: 50,
      minHeight: 40,
      height: spaces.x20,
      width: 'fit-content',
      borderBottom: `1px solid ${colors.grey[20]}`,
      '::after': {
        position: 'absolute',
        right: 0,
        bottom: 0,
        left,
        content: '""',
        height: spaces.x1,
        width: stripeWidth,
        backgroundColor: !disabled ? colors.accent[90] : colors.grey[40],
        transition: 'all 0.17s cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      },
    },
  })
)

export const StyledTab = restyled(ButtonBase)<StyledTabProps>(({
  theme: {colors, spaces, typography},
  selected,
  disabled,
}) => {
  const counterSize = spaces.x8
  const disabledColor = colors.grey[40]
  const getBackground = () => {
    if (disabled || (selected && disabled)) return colors.grey[20]
    if (selected) return colors.accent[90]
    return colors.grey[30]
  }

  return {
    position: 'relative',
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    padding: spaces.join('x4', 'x8'),
    height: spaces.x20,
    minWidth: 52,
    color: !disabled ? colors.grey[100] : disabledColor,
    cursor: !disabled ? 'pointer' : 'no-drop',
    transition: 'color 0.15s, background-color 0.25s',
    ...typography.p1,
    ':hover': {
      backgroundColor: !disabled && !selected ? colors.grey[20] : 'unset',
    },
    svg: {
      marginRight: spaces.x4,
      fill: !disabled ? colors.grey[60] : disabledColor,
    },
    [selectors.counter]: {
      display: 'inline-block',
      padding: spaces.join('x0', 'x2'),
      marginLeft: spaces.x4,
      height: counterSize,
      backgroundColor: getBackground(),
      color: selected ? colors.white : 'inherit',
      borderRadius: counterSize / 2,
      ...typography.p3,
    },
  }
})

export const StyledTabPanel = restyled.div<{selected: boolean}>(({theme, selected}) => ({
  display: selected ? null : 'none',
  padding: theme.spaces.join('x6', 'x0'),
}))
