import React from 'react'
import {WarningOutline as WarningOutlineIcon} from '@x5-react-uikit/icons'
import {Chip, chipVariants} from '../Chip'
import {Loader} from '../Loader'
import {FileUploaderProps, uploaderStatuses} from './types'
import {getUniqueFilename} from './helpers'

export const DefaultPreviews: FileUploaderProps['renderPreviewsContent'] = ({
  items,
  onDelete,
  maxLabelLength,
}) => {
  const shortening = (label: string, maxLabelLength: number) => {
    const maxLabelLengthHalf = Math.floor(maxLabelLength / 2)
    return `${label.slice(0, maxLabelLengthHalf - 2)}...${label.slice(label.length - maxLabelLengthHalf + 1)}`
  }

  return (
    <>
      {items.map(({file, status, tooltip}) => {
        const isError = status === uploaderStatuses.error
        return (
          <Chip
            key={file.name}
            error={isError}
            icon={
              status === uploaderStatuses.loading ? (
                <Loader />
              ) : isError ? (
                <WarningOutlineIcon />
              ) : null
            }
            label={file.name}
            maxLabelLength={maxLabelLength}
            name={getUniqueFilename(file)}
            shorteningFunc={shortening}
            tooltip={tooltip}
            variant={chipVariants.filled}
            onDelete={onDelete}
          />
        )
      })}
    </>
  )
}
