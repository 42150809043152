import {Text, type TextProps} from '@react-pdf/renderer'
import {fontX5Ui} from './constants'
import type {FC, PropsWithChildren} from 'react'
import {combineStyles, withLineHeight} from '@root/pdf-components/utils'
import {omit} from 'lodash'

const styles = withLineHeight(16, 20, {
  color: '#6D7678',
  fontWeight: 400,
  fontFamily: fontX5Ui,
  letterSpacing: '0.12px',
})

export const Subtitle: FC<TextProps> = ({children, ...other}) => {
  return (
    <Text {...omit(other, 'style')} style={combineStyles(styles, other.style)}>
      {children}
    </Text>
  )
}
