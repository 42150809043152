import React, {FC} from 'react'
import {Dropdown, DropdownProps} from '../Dropdown'
import {ListItem} from '../ListItem'
import {LoaderItem} from '../Loader/LoaderItem'
import {DefaultDropDownContent} from './DefaultDropDownContent'
import {SelectProps, SelectListProps} from './types'

type SelectBaseProps = Pick<
  SelectProps,
  | 'multiple'
  | 'value'
  | 'options'
  | 'error'
  | 'disabled'
  | 'stretch'
  | 'unborder'
  | 'noWrap'
  | 'preMatching'
  | 'renderDropDownContent'
  | 'dropdownWidth'
  | 'usePortal'
> &
  Pick<SelectListProps, 'inputValue' | 'highlighted'> &
  Pick<DropdownProps, 'action' | 'innerRef' | 'stretch' | 'placement'> & {
    getQA: (subName: string) => string
    onChange: SelectProps['onChange']
    onDropdownChange: DropdownProps['onChange']
  }

export const SelectBase: FC<SelectBaseProps> = ({
  action,
  stretch,
  innerRef,
  onDropdownChange,
  renderDropDownContent,
  getQA,
  preMatching,
  placement,
  dropdownWidth,
  usePortal,
  ...props
}) => {
  const RenderContentComponent = renderDropDownContent || DefaultDropDownContent

  const renderContent = () => {
    if (preMatching) return <LoaderItem text="Поиск совпадений" />

    if (!preMatching && !props.options.length && !renderDropDownContent) {
      return <ListItem secondary text="Ничего не найдено" />
    }

    return <RenderContentComponent qa={getQA('list')} {...props} />
  }

  return (
    <Dropdown
      useReferenceWidth
      action={action}
      innerRef={innerRef}
      isSameWidth={!dropdownWidth}
      placement={placement}
      qa={getQA('')}
      stretch={stretch}
      usePortal={usePortal}
      width="100%"
      onChange={onDropdownChange}
    >
      {renderContent()}
    </Dropdown>
  )
}

export default SelectBase
