import {Banner, Button, Link, Typography} from '@x5-react-uikit/core'
import FlexboxRow from '@root/components/FlexboxRow'
import LoaderFrame from '@root/components/Loader'
import Section from '@root/components/Section'
import {useContext, useState} from 'react'
import {useGetSystemMetaDataQuery} from '@root/redux/api/systemMetaDataApi'
import {useGetTemplateByIdQuery} from '@root/redux/api/templatesApi'
import SpecialistToolsContext from '../../context'
import SystemNotSelectedMessage from '../stubs/SystemNotSelectedMessage'
import TemplateNotApliedMessage from '../stubs/TempateNotApliedMessage'
import SelectTemplateButton from './SelectTemplateButton'
import VariablesList from './VariablesList'
import VariablesUpdateModal from './VariablesUpdateModal'

console.log(console.log(console.log()))

const TestSettingsHead = ({
  systemId,
  setSelectedTemplateId,
  selectedTemplateId,
  setUpdateModalShown,
}) => {
  return (
    <>
      <FlexboxRow sx={{justifyContent: 'space-between', alignItems: 'center'}}>
        <Typography variant="h3">Настройки теста</Typography>
        <FlexboxRow sx={{gap: '16px'}}>
          {systemId && (
            <SelectTemplateButton setSelectedTemplate={setSelectedTemplateId} systemId={systemId} />
          )}
          {selectedTemplateId && (
            <Button onClick={() => setUpdateModalShown(true)}>Редактировать настройки</Button>
          )}
        </FlexboxRow>
      </FlexboxRow>
    </>
  )
}

const TestSettingsSection = () => {
  const [updateTemplateModalShown, setUpdateTemplateModalShown] = useState(false)

  const {
    selectedSystemId: systemId,
    setSectionTab: setTab,
    form,
    handleSetPreviousTemplateId,
    handleSetTemplateId,
    previousMeta,
  } = useContext(SpecialistToolsContext)
  const {currentData: systemMetaData} = useGetSystemMetaDataQuery(
    {systemId},
    {skip: systemId == null}
  )

  const {data: selectedTemplate} = useGetTemplateByIdQuery(
    {systemId, templateId: systemMetaData?.templateId},
    {skip: !systemMetaData?.templateId}
  )

  if (!systemId)
    return (
      <Section sx={{minHeight: '424px'}}>
        <TestSettingsHead systemId={systemId} />
        <SystemNotSelectedMessage sx={{mt: 'x12'}} />
      </Section>
    )

  if (!selectedTemplate)
    return (
      <Section sx={{minHeight: '424px'}}>
        <TestSettingsHead setSelectedTemplateId={handleSetTemplateId} systemId={systemId} />
        <TemplateNotApliedMessage setTab={setTab} />
      </Section>
    )

  let bannerMessage = `Применен шаблон "${selectedTemplate.name}".`
  if (previousMeta)
    bannerMessage += ' Вы можете вернуться к предыдущему значению или применить другой шаблон.'

  return (
    <>
      <Section sx={{display: 'flex', flexDirection: 'column', gap: '32px'}}>
        <TestSettingsHead
          selectedTemplateId={systemMetaData?.templateId}
          setSelectedTemplateId={handleSetTemplateId}
          setUpdateModalShown={setUpdateTemplateModalShown}
          systemId={systemId}
        />
        <Banner
          action={
            previousMeta && {
              text: 'Вернуть предыдущие настройки',
              component: Link,
              onClick: handleSetPreviousTemplateId,
            }
          }
          message={bannerMessage}
          type="grey"
        />
        {form.getValues('notInited') ? (
          <LoaderFrame />
        ) : (
          systemMetaData?.variables && <VariablesList variables={systemMetaData.variables} />
        )}
      </Section>
      <VariablesUpdateModal
        open={updateTemplateModalShown}
        onClose={() => setUpdateTemplateModalShown(false)}
      />
    </>
  )
}

export default TestSettingsSection
