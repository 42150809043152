import FlexboxRow from '@root/components/FlexboxRow'
import RouterLink from '@root/components/RouterLink'
import {AppTable, AppTableColumn, SortTextKind, useAppTable} from '@root/components/appTable'
import {LineLimitedCell} from '@root/components/appTable/components/LineLimitedCell'
import {Box, Button, Tooltip} from '@root/components/kit'
import {SystemStatusCode, SystemSubstatusesMap} from '@root/features/systems/constants'
import type {System} from '@root/features/systems/types'
import useDownloadFile from '@root/hooks/useDownloadFile'
import SystemStatusChip from '@root/pages/SystemsPage/components/SystemStatusChip'
import {useGetSystemsQuery} from '@root/redux/api/systemsApi'
import {invalidateTasksAction} from '@root/redux/api/tasksApi'
import {Download as DownloadIcon, Warning as WarningIcon} from '@x5-react-uikit/icons'
import {isEmpty} from 'lodash'
import {FC, useCallback, useMemo, useState} from 'react'
import {useDispatch} from 'react-redux'
import {NOT_IMPLEMENTED, parseEnum} from 'utils'
import SystemActionsButton from './SystemActionsButton'
import {TableContextProvider} from './context'
import {fieldMapper} from './fileldMapper'
import {FilterCollectorCriticalityClassSelect} from './filterCollector/FilterCollectorCriticalityClassSelect'
import {FilterCollectorMFSMStatusSelect} from './filterCollector/FilterCollectorMFSMStatusSelect'
import {FilterCollectorSystemStatusSelect} from './filterCollector/FilterCollectorSystemStatusSelect'
import SystemStatusChangeModal from './modal/SystemStatusChangeModal'
import SystemStatusCommentModal from './modal/SystemStatusCommentModal'
import SystemTagChangeModal from './modal/SystemTagChangeModal'
import {FilterCollectorNameCombobox} from './filterCollector/FilterCollectorNameSelect'
import {FilterCollectorTagCombobox} from './filterCollector/FilterCollectorTagSelect'

const TaskCountLink: FC<{count: string | number; id: string}> = ({count, id}) => {
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(invalidateTasksAction)
  }, [dispatch])

  if (count == null || count === 0) {
    return null
  }

  return (
    <RouterLink to={`/tasks?systemId=${id}`} onClick={handleClick}>
      {count} шт.
    </RouterLink>
  )
}

const TestLaunchStatus: FC<{system: System}> = ({system}) => {
  if (system.status == null) {
    return null
  }

  return (
    <FlexboxRow sx={{alignItems: 'center'}}>
      <Box sx={{width: '127px'}}>
        <SystemStatusChip statusName={parseEnum(SystemStatusCode, system.status)} />
      </Box>
      {system.substatus && system.substatus !== SystemSubstatusesMap.WITHOUT_COMMENTS.name && (
        <Tooltip content={system.comment}>
          <WarningIcon
            color={SystemSubstatusesMap[system.substatus].color}
            size="small"
            style={{marginTop: '4px'}}
          />
        </Tooltip>
      )}
    </FlexboxRow>
  )
}

const columns: AppTableColumn<System>[] = [
  {
    isCheckbox: true,
    width: 40,
  },
  {
    dataIndex: 'name',
    title: 'Информационная система',
    titleNode: (
      <>
        Информационная <br />
        система
      </>
    ),
    filterableOptions: {
      type: 'custom',
      customInputComponent: FilterCollectorNameCombobox,
    },
    sortable: {
      enabled: true,
      text: SortTextKind.alphabeticalCyrillic,
    },
    width: '16.5%',
    valueRenderType: 'string',
    renderCell: (val) => {
      return <LineLimitedCell lines={2} value={val as string} />
    },
  },
  {
    dataIndex: 'tag',
    title: 'Тег',
    filterableOptions: {
      type: 'custom',
      customInputComponent: FilterCollectorTagCombobox,
    },
    sortable: {
      enabled: true,
      text: SortTextKind.alphabeticalCyrillic,
    },
    width: '12.5%',
    renderCell: (_val, row) => <LineLimitedCell lines={2} value={row.tag} />,
  },
  {
    dataIndex: 'cod',
    title: 'Код',
    filterableOptions: {
      type: 'text',
    },
    sortable: {
      enabled: true,
      text: SortTextKind.default,
    },
    width: '10.833333%',
    valueRenderType: 'string',
  },
  {
    dataIndex: 'mfsmStatus',
    title: 'Статус MFSM',
    titleNode: (
      <>
        Статус <br />
        MFSM
      </>
    ),
    filterableOptions: {
      type: 'custom',
      customInputComponent: FilterCollectorMFSMStatusSelect,
    },
    width: '10.833333%',
  },
  {
    dataIndex: 'criticalityClass',
    title: 'Класс критичности',
    titleNode: (
      <>
        Класс <br />
        критичности
      </>
    ),
    filterableOptions: {
      type: 'custom',
      customInputComponent: FilterCollectorCriticalityClassSelect,
    },
    sortable: undefined,
    width: '10.833333%',
  },
  {
    dataIndex: 'taskCount',
    title: 'Заявки',
    filterableOptions: undefined,
    sortable: {
      enabled: true,
      text: SortTextKind.default,
    },
    width: '8.333333%',
    renderCell: (value, row) => <TaskCountLink count={value} id={row.id} />,
  },
  {
    dataIndex: 'status',
    title: 'Статус НТ',
    filterableOptions: {
      type: 'custom',
      customInputComponent: FilterCollectorSystemStatusSelect,
    },
    sortable: undefined,
    width: '14.1666666%',
    renderCell: (_val, row) => <TestLaunchStatus system={row} />,
  },
  {
    dataIndex: 'expirationDate',
    title: 'Срок статуса',
    titleNode: (
      <>
        Срок <br />
        статуса
      </>
    ),
    filterableOptions: {
      type: 'date',
    },
    sortable: {
      enabled: true,
      text: SortTextKind.closestIndefinite,
    },
    width: '10%',
    renderCell: (_val, row) => {
      const value = row.expirationDate

      if (isEmpty(value)) {
        return `Бессрочно`
      }

      return `до ${value}`
    },
  },
  {
    key: 'actions',
    width: 32,
    bodyCellStyle: {
      padding: '4px 0 0 0',
      textAlign: 'center',
      verticalAlign: 'top',
    },
    renderCell: (_val, row) => <SystemActionsButton system={row} />,
  },
]

function getId(system: System) {
  if (system.id == null) {
    throw new Error('Id was null')
  }

  return system.id
}

const responsiveWidth = 'min(100%, 91vw)'

const SystemsTable = () => {
  const {tableState, tableProps} = useAppTable<System>({
    pages: {
      currentPage: 1,
      pageSize: 20,
    },
    filterValues: [],
  })

  const {
    data: cachedSystems,
    currentData: systems,
    isFetching: isSystemsLoading,
    isError: isSystemsError,
  } = useGetSystemsQuery({
    filter: fieldMapper(tableState),
  })

  const checkedSystems = useMemo(
    () =>
      (tableProps.checkedRows ?? []).map((id) => (systems?.items ?? []).find((x) => x.id === id)),
    [systems?.items, tableProps.checkedRows]
  )

  const {download: handleDownloadSelected, loading: exportLoading} = useDownloadFile({
    path: '/v1/systems/xlsx',
    body: checkedSystems.length > 0 ? checkedSystems : [],
    defaultName: `${checkedSystems[0]?.id}.xlsx`,
  })

  const [currentSystem, setCurrentSystem] = useState<System | null>(null)
  const [showStatusModal, setShowStatusModal] = useState(false)
  const [showCommentModal, setShowCommentModal] = useState(false)
  const [showTagModal, setShowTagModal] = useState(false)

  return (
    <TableContextProvider
      value={{
        downloadXLS: NOT_IMPLEMENTED,
        setShowTagModal,
        setShowStatusModal,
        setShowCommentModal,
        setCurrentSystem,
      }}
    >
      <AppTable
        noSideBorders
        cachedData={cachedSystems?.items ?? []}
        columns={columns}
        components={{
          FilterCollector: {
            size: 'large',
          },
          HeadFlexboxRow: {
            sx: {
              py: 'x8',
              height: 'auto',
            },
          },
          Cell: {
            style: {
              padding: '12px',
            },
          },
          Head: {
            style: {
              verticalAlign: 'top',
              whiteSpace: 'unset',
            },
          },
          HeadCell: {
            style: {
              padding: '12px',
            },
          },
        }}
        data={systems?.items ?? []}
        error={isSystemsError}
        getCheckboxRowId={getId}
        getId={getId}
        loading={isSystemsLoading}
        pagination={
          cachedSystems
            ? {
                currentPage: tableState.pages?.currentPage ?? 1,
                pageSize: tableState.pages?.pageSize ?? 20,
                totalCount: cachedSystems.totalElements,
                totalPages: cachedSystems.totalPages,
              }
            : undefined
        }
        renderHead={({uncheckWithDividerFn}) => (
          <FlexboxRow sx={{justifyContent: 'end', gap: '16px', width: responsiveWidth}}>
            {uncheckWithDividerFn()}
            <Button
              disabled={exportLoading}
              loading={exportLoading}
              startIcon={<DownloadIcon />}
              onClick={handleDownloadSelected}
            >
              Выгрузить в excel
            </Button>
          </FlexboxRow>
        )}
        style={{
          width: '1200px',
          maxWidth: '1200px',
          minHeight: 400,
        }}
        {...tableProps}
      />

      <SystemStatusChangeModal
        isOpen={showStatusModal}
        system={currentSystem}
        onCancel={() => setShowStatusModal(false)}
      />
      <SystemStatusCommentModal
        isOpen={showCommentModal}
        system={currentSystem}
        onCancel={() => setShowCommentModal(false)}
        onStatusChange={() => {
          setShowCommentModal(false)
          setShowStatusModal(showCommentModal)
        }}
      />
      <SystemTagChangeModal
        isOpen={showTagModal}
        system={currentSystem}
        onCancel={() => setShowTagModal(false)}
      />
    </TableContextProvider>
  )
}

export default SystemsTable
