import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import Section from '@root/components/Section'
import {Datepicker} from '@root/components/kit'
import {TaskStatuses, getTaskStatusDisplay} from '@root/constants'
import {TaskFormData} from '@root/features/tasks/types'
import useNotify from '@root/hooks/useNotify'
import {
  useDeleteTaskMutation,
  useGetTaskByIdQuery,
  useUpdateTaskMutation,
  useUpdateTaskStatusMutation,
} from '@root/redux/api/tasksApi'
import {useGetUserinfoQuery} from '@root/redux/api/userApi'
import {Box, Button, Loader, Tooltip, Typography, baseTheme} from '@x5-react-uikit/core'
import {Error as ErrorIcon} from '@x5-react-uikit/icons'
import {isEmpty} from 'lodash'
import {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import useUpdateFormData from '../hooks/useUpdateFormData'
import {CheckBudgetResponse} from '../types'
import {getFormIndiciesById} from '../utils/formUtils'
import CancelTaskModal from './CancelTaskModal'
import SaveTaskModal from './SaveTaskModal'
import SubmitTaskModal from './SubmitTaskModal'
import {useLazyVerifyCppQuery, VerifyCppResponse} from '@root/redux/api/budgetApi'

const {colors} = baseTheme

const WorkDatesSection = ({blankFields, formDataForm}) => {
  const navigate = useNavigate()
  const {notifyError, notifySuccess, notifyWarning} = useNotify()
  const {taskId, stepId} = useParams()

  const {data: userInfo} = useGetUserinfoQuery()

  const {data: task, isFetching: isTaskFetching} = useGetTaskByIdQuery(taskId)

  const [ltPeriodDates, setLtPeridodDates] = useState(undefined)
  useEffect(() => {
    if (task && !isTaskFetching) {
      setLtPeridodDates([task.startAt, task.finishedAt])
    }
  }, [task, isTaskFetching])

  const {updateFormData, isUpdating: isUpdatingFormData} = useUpdateFormData()
  const handleUpdateFormData = () => {
    const updatedDates = {} as any
    if (task.startAt !== ltPeriodDates[0]) updatedDates.startAt = ltPeriodDates[0]
    if (task.finishedAt !== ltPeriodDates[1]) updatedDates.finishedAt = ltPeriodDates[1]
    if (!isEmpty(updatedDates))
      updateTaskData({taskId, update: updatedDates})
        .unwrap()
        .then(() => {
          setLtPeridodDates(undefined)
          const steps = formDataForm.getValues()
          updateFormData({steps: Object.values(steps)})
        })
        .catch(() => notifyError('Не удалось сохранить выбранные даты.'))
  }

  const [cancelModalShown, setCancelModalShown] = useState(false)
  const [saveModalShown, setSaveModalShown] = useState(false)
  const [submitModalShown, setSubmitModalShown] = useState(false)

  const [updateTaskData, {isLoading: isUpdatingTaskData}] = useUpdateTaskMutation()
  const [updateTaskStatus, {isLoading: isUpdatingTaskStatus}] = useUpdateTaskStatusMutation()

  const [dateErrors, setDateErrors] = useState([false, false])

  const handleEditDate = (value, field) => {
    const dateIndex = field === 'startAt' ? 0 : 1
    setDateErrors((prev) => {
      const errors = [...prev]
      errors[dateIndex] = null
      return errors
    })
    setLtPeridodDates((prev) => {
      const dates = [...prev]
      dates[dateIndex] = value
      return dates
    })
  }

  const [verifyCpp, {isFetching: isVerifyingBudget}] = useLazyVerifyCppQuery()
  const handleModalInReview = () => {
    if (!ltPeriodDates[0] || !ltPeriodDates[1]) {
      setDateErrors([!ltPeriodDates[0], !ltPeriodDates[1]])
      return
    }
    if (dateErrors) setDateErrors([false, false])
    setSubmitModalShown(true)
  }
  const handleSubmitInReview = async () => {
    const onStatusChanged = (task) => {
      setSubmitModalShown(false)
      notifySuccess(
        'Статус заявки обновлён',
        `Заявка перешла в статус "${getTaskStatusDisplay(task.status)}"`
      )
      const steps = Object.values(formDataForm.getValues() as TaskFormData)
      const lastStep = steps.find((step) => step.number === 3)
      lastStep.completed = true
      updateFormData({steps, shouldNotify: false})
      navigate('/tasks')
    }

    let budgetChecked: VerifyCppResponse
    try {
      const steps = Object.values(formDataForm.getValues() as TaskFormData)
      const {stepIndex, fieldIndex} = getFormIndiciesById(steps, 'budget_source')
      const cpp = steps[stepIndex].fields[fieldIndex].values.text[0].value
      budgetChecked = await verifyCpp({cpp}).unwrap()
      if (!budgetChecked.checkBudget) {
        notifyError(
          'СПП не прошло проверку. Измените его номер и попробуйте снова',
          'СПП указано некорректно'
        )
        setSubmitModalShown(false)
        return
      }
    } catch {
      notifyWarning('Не удалось проверить номер СПП.')
    }

    const updatedDates = {} as {startAt: string; finishedAt: string}
    if (task.startAt !== ltPeriodDates[0]) updatedDates.startAt = ltPeriodDates[0]
    if (task.finishedAt !== ltPeriodDates[1]) updatedDates.finishedAt = ltPeriodDates[1]

    const statusUpdate = {nextTaskStatus: TaskStatuses.IN_REVIEW} as any
    if (!isEmpty(updatedDates))
      updateTaskData({taskId, update: updatedDates})
        .unwrap()
        .then(() => updateTaskStatus({taskId, updateData: statusUpdate}).unwrap())
        .then(onStatusChanged)
        .catch(() => notifyError('Не удалось обновить статус заявки.'))
    else
      updateTaskStatus({taskId, updateData: statusUpdate})
        .unwrap()
        .then(onStatusChanged)
        .catch(() => notifyError('Не удалось обновить статус заявки.'))
  }

  const [deleteTask] = useDeleteTaskMutation()
  const handleRemoveTask = () => {
    deleteTask(taskId)
      .unwrap()
      .then(() => {
        notifySuccess('Заявка удалена.')
        navigate('/tasks')
      })
      .catch((error) => notifyError(JSON.stringify(error)))
  }

  if (isTaskFetching || ltPeriodDates === undefined)
    return (
      <Section sx={{height: '240px'}}>
        <Loader />
      </Section>
    )

  return (
    <>
      <Section>
        <FlexboxColumn sx={{mb: 'x12'}}>
          <Typography variant="h3">Выбор желаемой даты начала и окончания работ по НТ</Typography>
          <Typography style={{color: colors.grey[50]}} variant="p3">
            Выберите даты для начала и окончания работ. Если все обязательные поля не заполнены, то
            выбор будет недоступен.
          </Typography>
        </FlexboxColumn>
        <FlexboxColumn sx={{gap: '4px'}}>
          {!isTaskFetching && (
            <FlexboxRow sx={{gap: '24px', alignItems: 'center', width: '600px'}}>
              <Datepicker
                required
                date={ltPeriodDates[0]}
                disabled={blankFields}
                InputProps={{
                  label: 'Дата',
                  error: dateErrors[0],
                }}
                label="Дата"
                width="250px"
                onChange={(newValue) => handleEditDate(newValue, 'startAt')}
              />
              <Box sx={{height: '1px', width: '40px', flex: '1 0 0', backgroundColor: 'grey.30'}} />
              <Datepicker
                required
                date={ltPeriodDates[1]}
                disabled={blankFields}
                disabledDates={ltPeriodDates[0] ? [`01.01.1111 ${ltPeriodDates[0]}`] : undefined}
                InputProps={{
                  label: 'Дата',
                  error: dateErrors[1],
                }}
                label="Дата"
                width="250px"
                onChange={(newValue) => handleEditDate(newValue, 'finishedAt')}
              />
            </FlexboxRow>
          )}
          {dateErrors.find(Boolean) && (
            <Typography style={{color: colors.error}} variant="p3">
              Нужно заполнить дату, прежде чем отправить заявку на рассмотрение
            </Typography>
          )}
        </FlexboxColumn>
        {blankFields && (
          <FlexboxRow sx={{alignItems: 'center', mt: 'x12', gap: '8px', color: 'red.60'}}>
            <ErrorIcon />
            <Typography variant="p2">
              Вы не заполнили обязательные поля. Шаг, в котором есть незаполненные пункты, а также
              пункты снизу отмечены.
            </Typography>
          </FlexboxRow>
        )}
        <FlexboxRow sx={{justifyContent: 'space-between', mt: 'x20'}}>
          <FlexboxRow sx={{gap: '16px'}}>
            {task?.status === TaskStatuses.WITHDRAWN && !userInfo?.isAdmin ? (
              <Button variant="outlined" onClick={() => navigate('/tasks')}>
                Вернуться к заявкам
              </Button>
            ) : (
              <Button variant="outlined" onClick={() => setCancelModalShown(true)}>
                Отменить создание
              </Button>
            )}
            <Button variant="secondary" onClick={() => setSaveModalShown(true)}>
              Сохранить заявку
            </Button>
          </FlexboxRow>
          <FlexboxRow sx={{gap: '16px'}}>
            <Button
              disabled={Number(stepId) === 1}
              onClick={() => {
                const prevStep = Number(stepId) - 1
                navigate(`/tasks/create/${taskId}/steps/${prevStep}`)
              }}
            >
              Предыдущий шаг
            </Button>
            <Tooltip content={blankFields && 'Необходимо заполнить обязательные поля'}>
              <div>
                <Button disabled={blankFields} onClick={handleModalInReview}>
                  Отправить заявку
                </Button>
              </div>
            </Tooltip>
          </FlexboxRow>
        </FlexboxRow>
      </Section>
      <SubmitTaskModal
        isOpen={submitModalShown}
        onCancel={() => setSubmitModalShown(false)}
        onSubmit={handleSubmitInReview}
        isSubmitting={
          isVerifyingBudget || isUpdatingFormData || isUpdatingTaskData || isUpdatingTaskStatus
        }
      />
      <CancelTaskModal
        isOpen={cancelModalShown}
        onClose={() => setCancelModalShown(false)}
        onRemove={handleRemoveTask}
        onSave={handleUpdateFormData}
      />
      <SaveTaskModal
        isOpen={saveModalShown}
        onClose={() => setSaveModalShown(false)}
        onSave={handleUpdateFormData}
      />
    </>
  )
}

export default WorkDatesSection
