import {configureStore} from '@reduxjs/toolkit'
import {formsApi} from './api/formsApi'
import {launchesApi} from './api/launchesApi'
import {systemMetaDataApi} from './api/systemMetaDataApi'
import {systemsApi} from './api/systemsApi'
import {tasksApi} from './api/tasksApi'
import {templatesApi} from './api/templatesApi'
import {userApi} from './api/userApi'
import authReducer from './authSlice'
import {budgetApi} from './api/budgetApi'
import {reportConfigurationsApi} from './api/reportConfigurationsApi'

const {reducers, middlewares} = [
  budgetApi,
  formsApi,
  launchesApi,
  reportConfigurationsApi,
  systemsApi,
  systemMetaDataApi,
  tasksApi,
  templatesApi,
  userApi,
].reduce(
  (acc, api) => ({
    reducers: {...acc.reducers, [api.reducerPath]: api.reducer},
    middlewares: [...acc.middlewares, api.middleware],
  }),
  {reducers: {}, middlewares: []}
)

export const store = configureStore({
  reducer: {
    auth: authReducer,
    ...reducers,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({serializableCheck: false}).concat(...middlewares),
})

export type RootState = ReturnType<typeof store.getState>
