import {Box, BoxProps} from 'ui-kit'
import type {CSSProperties, FC, PropsWithChildren} from 'react'

type Props = PropsWithChildren<BoxProps> & {
  style?: CSSProperties
}

const FlexboxRow: FC<Props> = ({children, sx, ...otherProps}) => (
  <Box sx={{display: 'flex', ...(sx ?? {})}} {...otherProps}>
    {children}
  </Box>
)

export default FlexboxRow
