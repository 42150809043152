import React, {useState, FC} from 'react'
import {sizeTokenValues} from '@x5-react-uikit/tokens'

import {Error as HintIcon, Close as CloseIcon} from '@x5-react-uikit/icons'
import {StyledSelectHint, selectHintClasses} from './styles'
import {InnerButton, innerButtonSizes} from '../InnerButton'

const Hint: FC = ({children}) => {
  const [isOpen, setOpen] = useState(true)

  if (!isOpen || !children) return null

  return (
    <StyledSelectHint>
      <HintIcon className={selectHintClasses.icon} size={sizeTokenValues.small} />
      <span className={selectHintClasses.content}>{children}</span>
      <InnerButton
        className={selectHintClasses.close}
        IconComponent={<CloseIcon size={sizeTokenValues.small} />}
        size={innerButtonSizes.xsmall}
        variant="text"
        onClick={() => setOpen(false)}
      />
    </StyledSelectHint>
  )
}

export {Hint}
export default Hint
