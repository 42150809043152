import {Input, inputSizes, Textarea} from '@root/components/kit'
import {CSSProperties} from 'react'
import {Controller, ControllerProps, FieldPath, FieldValues} from 'react-hook-form'

const DEBOUNCE_WAIT_MS = 700

// TODO: добавить debounce изменения инпута

type Props<TFieldValues extends FieldValues> = {
  name: FieldPath<TFieldValues>
  label?: string
  debounceWaitMs?: number
  textarea?: boolean
  disabled?: boolean
  value?: string
  caption?: string
  width?: CSSProperties['width']
  required?: boolean
  size?: keyof typeof inputSizes
} & Pick<ControllerProps<TFieldValues>, 'control' | 'rules'>

function FormInputText<TFieldValues extends FieldValues>({
  name,
  control,
  label,
  rules,
  debounceWaitMs = DEBOUNCE_WAIT_MS,
  textarea = false,
  ...inputWrapperProps
}: Props<TFieldValues>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({field: {onChange, value, ref}, fieldState: {isDirty, error}, formState}) => {
        if (textarea)
          return (
            <Textarea
              key={name}
              error={!!error}
              label={label}
              textError={error?.message}
              value={value}
              // onChange={debounce(onChange, debounceWaitMs)}
              onChange={onChange}
              {...inputWrapperProps}
            />
          )
        return (
          <Input
            key={name}
            loading
            error={!!error}
            filled={!!value}
            inputRef={ref}
            label={label}
            textError={error?.message}
            value={value || ''}
            onChange={onChange}
            {...inputWrapperProps}
          />
        )
      }}
      rules={rules}
    />
  )
}

export default FormInputText
