import {mimeTypes} from 'ui-kit'
import {FileUploader} from '@root/components/kit'
import {Controller} from 'react-hook-form'

const fileDataToFileItems = (fileObjects) =>
  fileObjects
    ?.filter((fileObj) => !fileObj.shouldRemoved)
    .map((fileObj) => {
      if (fileObj.file) return fileObj

      const ext = fileObj.name.split('.').pop()
      const mimeType = mimeTypes[ext] || ext
      return {
        ...fileObj,
        file: new File([fileObj.name], fileObj.name, {type: mimeType}),
      }
    })
const filesToFileData = (files) =>
  files.filter((file) => !file.shouldRemoved).map((file) => ({name: file.name, file}))

const FormInputFile = ({name, control, label, ...uploaderProps}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({field: {onChange, value}, fieldState: {error}, formState}) => {
        const fileObjects = value || []
        return (
          <FileUploader
            key={fileObjects.length}
            multiple
            accept={['jpg', 'jpeg', 'png', 'pdf', 'pptx', 'doc', 'zip']}
            disabled={
              uploaderProps?.maxFilesCount &&
              fileObjects.filter((f) => !f.shouldRemoved).length >= uploaderProps.maxFilesCount
            }
            items={fileDataToFileItems(fileObjects)}
            maxFileSize={10}
            style={{width: '520px'}}
            onRemove={(file) => {
              onChange(
                fileObjects
                  .filter((fileObj) => !!fileObj.nameInStore || fileObj.name !== file.name)
                  .map((fileObj) =>
                    fileObj.name === file.name ? {...fileObj, shouldRemoved: true} : fileObj
                  )
              )
            }}
            onSelect={(files) => {
              onChange(filesToFileData(files))
            }}
            {...uploaderProps}
          />
        )
      }}
    />
  )
}

export default FormInputFile
