import {useCallback, useRef, useState} from 'react'
import {pdf} from '@react-pdf/renderer'
import {PdfReport} from '../PdfReport'
import {Report} from '../types'
import {saveFile} from '@root/utils'
import launchesApi from '@root/redux/api/launchesApi'
import {isEmpty} from 'lodash'

export const useDownloadManyReportsPdf = (reports?: Report[]) => {
  const [getLaunchById] = launchesApi.useLazyGetLaunchByIdQuery()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const mainPromise = useRef<Promise<[Report, Blob][]>>()

  const isEmptyReports = isEmpty(reports)

  const download = useCallback(() => {
    if (isEmptyReports || mainPromise.current != null) {
      return
    }

    const promises: Promise<[Report, Blob]>[] = (reports ?? []).map(async (report) => {
      try {
        const launch = await getLaunchById({
          systemId: report!.system.systemId,
          launchId: report!.launchId,
          taskId: report!.taskId,
        }).unwrap()

        const blob = await pdf(<PdfReport launch={launch} report={report} />).toBlob()

        return [report, blob] as [Report, Blob]
      } catch (err) {
        console.error(`Unexpected error while forming report pdf:`)
        console.error(err)
        setError(true)
        throw err
      }
    })

    setLoading(true)

    mainPromise.current = Promise.all(promises)

    mainPromise.current
      .then((exported) => {
        exported.forEach(([report, blob]) => {
          saveFile(blob, `Отчет ${report.name}`)
        })
      })
      .catch((err) => {
        console.error(`Unexpected error while forming report pdf:`)
        console.error(err)
        setError(true)
      })
      .finally(() => {
        setLoading(false)
        mainPromise.current = null
      })
  }, [getLaunchById, reports])

  return {
    download,
    disabled: isEmptyReports,
    loading,
    error,
  }
}
