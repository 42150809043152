export const formatReportData = (reportData) => {
  const result = [
    {
      number: 1,
      fields: [...(reportData.commonFields || []), ...(reportData.dynamicFields || [])],
    },
  ]

  return result
}

export const getDynamicFieldMeta = (field) => {
  return {
    step: 1,
    id: field.id,
    label: field.label,
    values: [
      {
        id: field.values.comment.id,
        type: 'comment',
      },
    ],
  }
}
