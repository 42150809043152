import {Box, Typography} from '@root/components/kit'
import {getTaskStatusDisplay, TaskStatuses, taskStatusToColor} from '@root/constants'
import {ExtractConstValues} from '@root/core/helperTypes'

const TaskStatusChip = ({status}: {status: ExtractConstValues<typeof TaskStatuses>}) => {
  const {color, backgroundColor} = taskStatusToColor(status)
  return (
    <Box
      sx={{
        py: 'x1',
        px: 'x3',
        mt: 'x2',
        borderRadius: 'x2',
        color,
        backgroundColor,
        width: 'fit-content',
        maxWidth: '190px',
      }}
    >
      <Typography variant="p2">{getTaskStatusDisplay(status)}</Typography>
    </Box>
  )
}

export default TaskStatusChip
