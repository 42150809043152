import {Box, Button, Tab, TabList, Tabs, Typography} from '@x5-react-uikit/core'
import {Download} from '@x5-react-uikit/icons'
import Breadcrumbs from '@root/components/Breadcrumbs'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import Section from '@root/components/Section'
import ErrorMessage from '@root/pages/SpecialistToolsPage/components/stubs/ErrorMessage'
import {useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {useGetReportByIdQuery} from '@root/redux/api/launchesApi'
import LaunchVariablesTab from './components/LaunchVariablesTab'
import ReportAffiliation from './components/ReportAffiliation'
import ReportDataTab from './components/ReportDataTab'
import ReportDateAndTasks from './components/ReportDateAndTasks'
import ReportInfo from './components/ReportInfo'
import ReportStatisticsTab from './components/ReportStatisticsTab'
import ReportDeleteModal from './components/ReportDeleteModal'
import {useDownloadReportPdf} from '@root/features/reports/hooks/useDownloadReportPdf'
import {useGetUserinfoQuery} from '@root/redux/api/userApi'
import {useCanEditReport} from '@root/features/reports/hooks/useCanEditReport'

const ReportSectionLabels = {
  data: 'Данные',
  stats: 'Статистика',
  variables: 'Переменные теста',
}

const ReportViewPage = () => {
  const navigate = useNavigate()
  const {reportId} = useParams()
  const {isError: isGetReportError, data: report} = useGetReportByIdQuery(reportId)
  const [sectionTab, setSectionTab] = useState(ReportSectionLabels.data)

  const {data: userInfo} = useGetUserinfoQuery()

  const reportPdf = useDownloadReportPdf(report)

  const handleEdit = () => navigate(`/reports/${reportId}/update`)
  const [deleteModalShown, setDeleteModalShown] = useState(false)
  const handleDelete = () => setDeleteModalShown(true)

  const canEdit = useCanEditReport(report, false)

  if (isGetReportError)
    return (
      <div style={{width: '100%'}}>
        <ErrorMessage />
      </div>
    )

  let currentTabContent = <></>
  if (sectionTab === ReportSectionLabels.data) {
    // @ts-ignore
    currentTabContent = <ReportDataTab />
  } else if (sectionTab === ReportSectionLabels.variables)
    currentTabContent = <LaunchVariablesTab />
  else if (sectionTab === ReportSectionLabels.stats) currentTabContent = <ReportStatisticsTab />

  return (
    <>
      <Box sx={{mx: 'x8', width: '1200px'}}>
        <Breadcrumbs
          routes={[
            {to: '/tasks', label: 'Главная'},
            {to: '/reports', label: 'Отчёты портала'},
            {to: null, label: 'Просмотр отчета'},
          ]}
        />
        <FlexboxRow sx={{gap: '16px', justifyContent: 'space-between'}}>
          <FlexboxRow sx={{gap: '16px', alignItems: 'center'}}>
            <Typography variant="h2">Сформированный отчёт</Typography>
          </FlexboxRow>
          <FlexboxRow sx={{gap: '16px'}}>
            {(userInfo.isAdmin || userInfo.isManager || userInfo.isSpecialist) && (
              <>
                <Button disabled={!canEdit} variant="outlined" onClick={handleDelete}>
                  Удалить
                </Button>
                <Button disabled={!canEdit} variant="outlined" onClick={handleEdit}>
                  Редактировать
                </Button>
              </>
            )}
            <Button
              disabled={reportPdf.disabled}
              loading={reportPdf.loading}
              startIcon={<Download size="small" />}
              variant="outlined"
              onClick={reportPdf.download}
            >
              Скачать отчёт
            </Button>
          </FlexboxRow>
        </FlexboxRow>
        <FlexboxColumn sx={{gap: '24px'}}>
          <ReportInfo />
          <FlexboxRow sx={{gap: '24px'}}>
            <ReportAffiliation />
            <ReportDateAndTasks />
          </FlexboxRow>
          <Section sx={{my: 'x0', mb: 'x12'}}>
            <Tabs value={sectionTab} onChange={(e, newTab) => setSectionTab(newTab.toString())}>
              <TabList>
                <Tab label={ReportSectionLabels.data} value={ReportSectionLabels.data} />
                <Tab label={ReportSectionLabels.stats} value={ReportSectionLabels.stats} />
                <Tab label={ReportSectionLabels.variables} value={ReportSectionLabels.variables} />
              </TabList>
            </Tabs>
            {currentTabContent}
          </Section>
        </FlexboxColumn>
      </Box>
      <ReportDeleteModal open={deleteModalShown} onClose={() => setDeleteModalShown(false)} />
    </>
  )
}

export default ReportViewPage
