import {Button, Link, Typography, baseTheme} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import Section from '@root/components/Section'
import {useState} from 'react'
import {useGetSystemMetaDataQuery} from '@root/redux/api/systemMetaDataApi'
import SectionField from './SectionField'
import GitlabModal from './GitlabModal'
import {useGetActiveLaunchQuery} from '@root/redux/api/launchesApi'
import {useGetSystemActiveTaskQuery} from '@root/redux/api/systemsApi'
import {useGetUserinfoQuery} from '@root/redux/api/userApi'

const {colors} = baseTheme

const NoSystemMessage = () => (
  <Section sx={{my: 0, width: '588px'}}>
    <FlexboxColumn sx={{gap: '24px'}}>
      <FlexboxRow sx={{alignItems: 'center', justifyContent: 'space-between'}}>
        <Typography variant="h3">GitLab</Typography>
        <Button
          disabled
          style={{
            paddingTop: 0,
            paddingBottom: 0,
            height: 'fit-content',
          }}
          variant="text"
        >
          Редактировать
        </Button>
      </FlexboxRow>
      <FlexboxColumn
        sx={{gap: '8px', height: '120px', alignItems: 'center', justifyContent: 'center'}}
      >
        <Typography variant="h4">ИС и заявка не выбрана</Typography>
        <FlexboxColumn sx={{width: '328px', alignItems: 'center'}}>
          <Typography>Выберите Информационную систему,</Typography>
          <Typography>затем заявку, чтобы работать по ней</Typography>
        </FlexboxColumn>
      </FlexboxColumn>
    </FlexboxColumn>
  </Section>
)

const GitlabSection = ({systemId}) => {
  const {currentData: metaData} = useGetSystemMetaDataQuery({systemId}, {skip: !systemId})
  const {currentData: activeTask} = useGetSystemActiveTaskQuery(systemId, {skip: !systemId})
  const {currentData: activeLaunch} = useGetActiveLaunchQuery(
    {systemId: systemId, taskId: activeTask?.id},
    {skip: !systemId || !activeTask, pollingInterval: 5000}
  )

  const {data: userInfo} = useGetUserinfoQuery()

  const [modalShown, setModalShown] = useState(false)

  if (!systemId) return <NoSystemMessage />

  return (
    <>
      <Section sx={{my: 0, width: '588px'}}>
        <FlexboxColumn sx={{gap: '24px'}}>
          <FlexboxRow sx={{alignItems: 'center', justifyContent: 'space-between'}}>
            <Typography variant="h3">GitLab</Typography>
            {!userInfo.isBusiness && (
              <Button
                style={{height: '24px', padding: '0 8px 0 8px'}}
                variant="text"
                onClick={() => setModalShown(true)}
              >
                Редактировать
              </Button>
            )}
          </FlexboxRow>
          <FlexboxRow sx={{my: 0, justifyContent: 'space-between'}}>
            <SectionField>
              <Typography>Ссылка на репозиторий</Typography>
              {metaData?.gitlabRepoUrl ? (
                <Link
                  href={metaData.gitlabRepoUrl}
                  style={{textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}
                  target="_blank"
                >
                  {metaData.gitlabRepoUrl}
                </Link>
              ) : (
                <Typography style={{color: colors.grey[60]}}>Отсутствует</Typography>
              )}
            </SectionField>
            <SectionField>
              <Typography>Название проекта</Typography>
              {metaData?.gitlabProjectName ? (
                <Typography>{metaData.gitlabProjectName}</Typography>
              ) : (
                <Typography style={{color: colors.grey[60]}}>Отсутствует</Typography>
              )}
            </SectionField>
          </FlexboxRow>
          <FlexboxRow sx={{my: 0, justifyContent: 'space-between'}}>
            <SectionField>
              <Typography>Выбранная ветка</Typography>
              {metaData?.gitlabBranch ? (
                <Typography>{metaData.gitlabBranch}</Typography>
              ) : (
                <Typography style={{color: colors.grey[60]}}>Ещё не назначено</Typography>
              )}
            </SectionField>
            <SectionField>
              <Typography>Статус тестирования</Typography>
              {activeLaunch ? (
                <Typography>{activeLaunch.status}</Typography> // TODO
              ) : (
                <Typography style={{color: colors.grey[60]}}>Отсутствует</Typography>
              )}
            </SectionField>
          </FlexboxRow>
        </FlexboxColumn>
      </Section>
      <GitlabModal open={modalShown} systemId={systemId} onClose={() => setModalShown(false)} />
    </>
  )
}

export default GitlabSection
