import {KEYCLOAK_REALM} from '@root/constants'
import {KEYCLOAK_CLIENT_ID} from '@root/constants'
import {KEYCLOAK_LOGIN_REDIRECT} from '@root/constants'
import {KEYCLOAK_BASE_URL} from '@root/constants'
import Keycloak, {KeycloakInitOptions} from 'keycloak-js'
import {useCallback, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {updateJwtToken} from '@root/redux/authSlice'
import {
  clearKeycloakStorage,
  getAccessToken,
  getIdToken,
  getLocked,
  getRefreshToken,
  isLockExpired,
  isTokenStoreEvent,
  setAccessToken,
  setIdToken,
  setLock,
  setRefreshToken,
  tokenPairRefreshedAction,
} from '@root/keycloak'
import {match} from 'ts-pattern'
import {createLogger} from '../logger'
import {v4 as uuidv4} from 'uuid'

const log = createLogger('useKeycloak')

const publisherId = uuidv4()
let lockOwner = false

window.addEventListener('beforeunload', () => {
  if (lockOwner) {
    setLock(false)
  }
})

const keycloakSetting = {
  url: KEYCLOAK_BASE_URL,
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID,
}

const bc = new BroadcastChannel('token-pair-channel')

if (isLockExpired()) {
  setLock(false)
}

export const keycloakClient = Keycloak(keycloakSetting)

function silentRenewRec(minValidity = 30) {
  if (!keycloakClient.tokenParsed) {
    return
  }

  const timeout =
    ((keycloakClient.tokenParsed.exp * 1000 - Date.now()) * 0.001 - minValidity) * 1000

  setTimeout(() => {
    if (getLocked()) {
      return
    }

    if (getAccessToken() !== keycloakClient.token) {
      return
    }

    lockOwner = true
    setLock(true)
    log.debug('set locked true')

    keycloakClient
      .updateToken(minValidity)
      .then(() => {
        silentRenewRec(minValidity)

        setIdToken(keycloakClient.idToken)
        setAccessToken(keycloakClient.token)
        setRefreshToken(keycloakClient.refreshToken)

        bc.postMessage(
          tokenPairRefreshedAction(
            publisherId,
            keycloakClient.idToken,
            keycloakClient.token,
            keycloakClient.refreshToken
          )
        )
        log.debug('Successfully refreshed & published refresh bc action')
      })
      .catch(() => {
        log.error('Failed to refresh the token, or the session has expired')
        keycloakClient.login()
      })
      .finally(() => {
        log.debug('Set locked false')
        setLock(false)
        lockOwner = false
      })
  }, timeout)
}

const initParams: KeycloakInitOptions = {
  redirectUri: KEYCLOAK_LOGIN_REDIRECT,
  pkceMethod: 'S256',
  checkLoginIframe: true,
}

const useKeycloak = () => {
  const [init, setInit] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    keycloakClient
      .init({
        ...initParams,
        token: getAccessToken(),
        refreshToken: getRefreshToken(),
        idToken: getIdToken(),
      })
      .then(() => {
        setInit(true)
        silentRenewRec()
      })
      .catch((err) => {
        console.error(err)
        clearKeycloakStorage()
        window.location.reload()
      })

    const persistAndDispatch = () => {
      dispatch(updateJwtToken(keycloakClient.token))
      setIdToken(keycloakClient.idToken)
      setRefreshToken(keycloakClient.refreshToken)
      setAccessToken(keycloakClient.token)
    }

    const registerEventHandlers = () => {
      keycloakClient.onAuthRefreshSuccess = persistAndDispatch
      keycloakClient.onAuthSuccess = persistAndDispatch
    }

    registerEventHandlers()

    bc.onmessage = (e) => {
      const data = e.data

      if (!isTokenStoreEvent(data)) {
        return
      }

      if (data.publisherId === publisherId) {
        return
      }

      match(data)
        .with({type: 'token-pair-refreshed'}, ({payload: {accessToken, refreshToken, idToken}}) => {
          log.debug('get token pair refreshed event')

          keycloakClient.init({
            token: accessToken,
            refreshToken,
            idToken,
            ...initParams,
          })

          registerEventHandlers()

          dispatch(updateJwtToken(accessToken))
        })
        .otherwise(() => {
          throw new Error('Unknown event type')
        })
    }

    return () => {
      bc.close()
    }
  }, [dispatch])

  const logout = useCallback(() => {
    const url = new URL(keycloakClient.createLogoutUrl())
    url.searchParams.delete('redirect_uri')
    url.searchParams.set('post_logout_redirect_uri', window.location.href)
    url.searchParams.set('id_token_hint', keycloakClient.idToken)
    window.location.href = url.toString()
  }, [])

  return init ? {keycloakClient, keycloakLogout: logout} : {}
}

export default useKeycloak
