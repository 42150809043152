import React, {FC} from 'react'
import {LogoProps} from '../types'
import {palette, getPathColor} from './helpers'

const Logo: FC<LogoProps> = ({color, ...props}) => (
  <svg fill="none" viewBox="0 0 918 100" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M31.0258 1.45996H0V98.9052H31.0258V1.45996Z"
      fill={getPathColor(color, palette.first)}
    />
    <path
      d="M79.9372 62.7741C64.2418 48.9055 37.9611 54.38 37.9611 54.38C37.9611 59.8544 37.231 77.7376 51.8314 90.5114C67.5269 104.38 93.8076 98.9055 93.8076 98.9055C93.8076 98.9055 95.9976 76.2778 79.9372 62.7741Z"
      fill={getPathColor(color, palette.second)}
    />
    <path
      d="M51.4664 9.85422C36.866 22.6279 37.596 40.5112 37.596 45.9856C37.596 45.9856 63.8767 51.4601 79.5721 37.5914C95.6326 23.7228 93.4425 1.46006 93.4425 1.46006C93.4425 1.46006 67.5268 -4.01439 51.4664 9.85422Z"
      fill={getPathColor(color, palette.second)}
    />
    <path
      d="M488.383 43.0658L466.118 1.45996H441.297L476.338 65.3286L458.452 98.5403H483.638L533.645 1.45996H509.554L488.383 43.0658Z"
      fill={getPathColor(color, palette.first)}
    />
    <path
      d="M584.016 21.8978C592.776 21.8978 600.076 25.1825 605.186 30.292L621.612 13.8686C612.487 5.10949 600.441 0 584.381 0C576.351 0 568.686 1.45985 561.02 5.10949C544.23 13.1387 534.375 29.927 534.375 50C534.375 70.438 545.325 88.3212 563.21 95.9854C569.781 98.5401 577.446 100 585.111 100C599.711 100 609.932 95.6204 620.882 85.4015L607.377 68.9781C600.441 75.1825 594.236 78.4672 584.381 78.4672C568.686 78.4672 557.735 65.6934 557.735 49.635C557.735 34.6715 568.321 21.8978 584.016 21.8978Z"
      fill={getPathColor(color, palette.first)}
    />
    <path
      d="M639.132 98.5403H712.499V78.1023H661.763V59.4892H700.089V39.4162H661.763V21.8979H712.499V1.45996H639.132V98.5403Z"
      fill={getPathColor(color, palette.first)}
    />
    <path
      d="M908.875 42.3359C902.305 35.4016 893.544 32.1169 883.324 32.1169H862.884V1.45996H839.888V98.5403H883.324C906.32 98.5403 918.365 81.7519 918.365 65.3286C918 56.5695 914.715 48.5403 908.875 42.3359ZM881.864 78.1023H862.884V52.1899H881.864C890.259 52.1899 895.369 58.7592 895.369 65.3286C895.369 71.8979 890.259 78.1023 881.864 78.1023Z"
      fill={getPathColor(color, palette.first)}
    />
    <path
      d="M751.19 20.803C747.54 74.8176 738.78 77.7373 727.465 76.6424L726.735 98.9052C753.381 102.555 765.791 91.9709 771.631 40.876L773.821 21.8979H795.357V98.5403H817.987V1.45996H753.015L751.19 20.803Z"
      fill={getPathColor(color, palette.first)}
    />
    <path
      d="M210.976 1.45996L178.49 41.241V1.45996H155.494V98.5403H178.49V58.0293L209.516 98.5403H239.812L199.295 48.5403L241.272 1.45996H210.976Z"
      fill={getPathColor(color, palette.first)}
    />
    <path
      d="M284.708 1.45996L244.557 98.5403H268.282L276.313 78.1023H314.639L323.034 98.5403H347.855L305.513 1.45996H284.708ZM284.343 57.6644L294.928 29.9271L306.243 57.6644H284.343Z"
      fill={getPathColor(color, palette.first)}
    />
    <path
      d="M402.606 1.45996H358.805V98.5403H381.8L382.165 72.6279H400.416C413.921 72.6279 421.951 68.6133 428.522 62.0439C434.362 55.8395 438.012 45.2556 438.012 36.8614C437.647 20.0731 425.602 1.45996 402.606 1.45996ZM400.781 52.1899H381.8V22.9928H400.781C409.541 22.9928 414.286 31.022 414.286 37.5914C414.286 44.5257 409.176 52.1899 400.781 52.1899Z"
      fill={getPathColor(color, palette.first)}
    />
  </svg>
)

export default Logo
