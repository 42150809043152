import {isEmpty} from 'lodash'

export function displayUser(user: {name?: string; email?: string; role?: string}): string | null {
  const emailEmpty = isEmpty(user.email)
  const nameEmpty = isEmpty(user.name)

  if (!(emailEmpty && nameEmpty)) {
    return `${user.name} (${user.email})`
  }

  return user.name ?? user.email
}
