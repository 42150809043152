import {Typography, baseTheme} from '@x5-react-uikit/core'
import Divider from '@root/components/Divider'
import FlexboxColumn from '@root/components/FlexboxColumn'
import LoaderFrame from '@root/components/Loader'
import ReportDataAccordion from '@root/features/reports/components/ReportDataAccordion'
import {ReportCommonFieldsMeta} from '@root/features/reports/constants'
import {formatReportData, getDynamicFieldMeta} from '@root/features/reports/utils'
import {isNumber} from 'lodash'
import {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {useParams} from 'react-router-dom'
import {useGetConfigurationByIdQuery} from '@root/redux/api/reportConfigurationsApi'

const {colors} = baseTheme

const ConfDataTab = () => {
  const {configurationId} = useParams()

  const {reset: formResetValue, ...form} = useForm({defaultValues: {loading: true}})
  const {data: configuration} = useGetConfigurationByIdQuery({configurationId})
  useEffect(() => {
    if (configuration?.reportData) formResetValue(formatReportData(configuration.reportData))
    else if (configuration) formResetValue({loading: false})
  }, [configuration, formResetValue])

  const formValue = form.getValues()

  if (formValue.loading) return <LoaderFrame height="600px" />

  const dynamicFields = formValue[0].fields.filter((field) => isNumber(field.number))
  return (
    <FlexboxColumn>
      <ReportDataAccordion form={form} meta={ReportCommonFieldsMeta.goal} />
      <Divider />
      <ReportDataAccordion meta={ReportCommonFieldsMeta.conclusion}>
        <Typography style={{color: colors.grey[60]}}>
          Заполняется путем редактирования сформированного отчета
        </Typography>
      </ReportDataAccordion>
      <Divider />
      <ReportDataAccordion form={form} meta={ReportCommonFieldsMeta.approach} />
      <Divider />
      <ReportDataAccordion form={form} meta={ReportCommonFieldsMeta.limit} />
      <Divider />
      <ReportDataAccordion meta={ReportCommonFieldsMeta.configuration}>
        <Typography style={{color: colors.grey[60]}}>
          Автоматически заполняется информацией из методики при формировании отчета
        </Typography>
      </ReportDataAccordion>
      {dynamicFields.length > 0 && <Divider />}
      {dynamicFields.map((field, fieldIndex) => {
        const meta = getDynamicFieldMeta(field)
        const last = fieldIndex === dynamicFields.length - 1
        return (
          <>
            <ReportDataAccordion form={form} meta={meta} />
            {!last && <Divider />}
          </>
        )
      })}
    </FlexboxColumn>
  )
}

export default ConfDataTab
