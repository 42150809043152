import React, {forwardRef, useState, useMemo} from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import {useScrollStyles} from '@x5-react-uikit/core'
import clsx from 'clsx'

import {FieldCaption} from '../FieldCaption'
import {Asterisk} from './Asterisk'
import {StyledWrapper, StyledLabel, StyledResizer, textareaClasses} from './styles'

import {TextareaProps} from './types'
import {getQaAttribute} from '../utils'

export const Textarea = forwardRef<HTMLDivElement, TextareaProps>(
  (
    {
      qa,
      className,
      label,
      caption,
      error,
      textError,
      disabled,
      width,
      required,
      isAbsoluteCaption,
      onFocus,
      onBlur,
      rows: minRows,
      rowsMax: maxRows,
      labelStyle,
      textareaRef,
      ...props
    },
    ref
  ) => {
    const scrollClasses = useScrollStyles()
    const [focused, setFocused] = useState(false)

    const filled = props.filled || Boolean(props.value?.length)
    const getQA = getQaAttribute(qa !== null ? 'textarea' : null)
    const captionValue = useMemo(
      () => (error && textError ? textError : caption),
      [caption, error, textError]
    )

    const handleFocus: TextareaProps['onFocus'] = (event) => {
      setFocused(true)
      onFocus?.(event)
    }

    const handleBlur: TextareaProps['onBlur'] = (event) => {
      setFocused(false)
      onBlur?.(event)
    }

    return (
      <StyledWrapper
        ref={ref}
        className={className}
        data-qa={getQA('wrapper')}
        disabled={disabled}
        error={error}
        filled={filled}
        focused={focused}
        isAbsoluteCaption={isAbsoluteCaption}
        width={width}
      >
        <div className={textareaClasses.container} data-qa={getQA('container')}>
          <TextareaAutosize
            {...props}
            ref={textareaRef}
            className={clsx(textareaClasses.textarea, scrollClasses.root)}
            data-qa={getQA('field')}
            disabled={disabled}
            maxRows={Number(maxRows)}
            minRows={Number(minRows)}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
          <StyledResizer data-qa={getQA('resizer')} />
          <StyledLabel
            data-qa={getQA('label')}
            disabled={disabled}
            filled={filled}
            labelStyle={labelStyle}
          >
            {label}
            {required && <Asterisk />}
          </StyledLabel>
        </div>
        {captionValue && (
          <FieldCaption
            data-qa={getQA('caption')}
            disabled={disabled}
            error={error}
            isAbsolute={isAbsoluteCaption}
            value={captionValue}
          />
        )}
      </StyledWrapper>
    )
  }
)

export default Textarea
