import {ChangeEventHandler, ReactNode} from 'react'
import {TableBodyCellInnerProps} from '../../types'

export const BodyCellInnerNumberDefaultScale = 2
export const BodyCellInnerNumberDefaultChar = ''

export interface BodyCellInnerNumberProps
  extends Omit<TableBodyCellInnerProps, 'onChange'>,
    BodyCellInnerNumberViewProps,
    BodyCellInnerNumberEditProps {}

interface BodyCellInnerNumberBaseProps extends Pick<TableBodyCellInnerProps, 'density' | 'noWrap'> {
  scale?: number
  char?: string
  isFraction?: boolean
  children: string | number
}

export interface BodyCellInnerNumberViewProps
  extends BodyCellInnerNumberBaseProps,
    Pick<TableBodyCellInnerProps, 'editable' | 'onStartEdit'> {
  char?: string
  errorText?: string
  renderView?: (value, scale, char) => ReactNode
}

export interface BodyCellInnerNumberEditProps
  extends BodyCellInnerNumberBaseProps,
    Pick<TableBodyCellInnerProps, 'rootRef' | 'onCancel'> {
  onChange: ChangeEventHandler<HTMLInputElement>
  signed?: boolean
  min?: number
  max?: number
}
