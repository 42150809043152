import React, {FC} from 'react'
import {defineColors} from '../utils/defineColors'
import {LogoProps} from '../types'

const getPathColor = defineColors('#000000')

const Logo: FC<LogoProps> = ({color, ...props}) => (
  <svg fill="none" viewBox="0 0 337 100" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M167.956 45.7266H211.967V52.2057H193.388V99.0828H186.46V52.2057H167.956V45.7266Z"
      fill={getPathColor(color)}
    />
    <path
      d="M209.076 79.1881C209.076 67.3737 217.832 58.5312 229.634 58.5312C241.36 58.5312 249.431 66.4596 249.431 78.0442C249.431 79.1881 249.356 80.4829 249.279 81.4742H215.623C216.004 88.5621 221.868 93.7465 229.634 93.7465C235.421 93.7465 239.075 91.6885 242.121 86.8094L247.908 88.9446C244.71 95.9571 238.39 99.7678 229.634 99.7678C217.527 99.7678 209.076 91.3078 209.076 79.1881ZM215.928 75.4529H242.883C242.656 69.4316 236.791 64.5525 229.635 64.5525C222.476 64.5525 216.766 69.0492 215.928 75.4529Z"
      fill={getPathColor(color)}
    />
    <path
      d="M293.217 88.9452C290.018 95.9577 283.698 99.7684 275.247 99.7684C263.063 99.7684 254.307 91.1558 254.307 79.1887C254.307 67.2217 262.988 58.7617 275.247 58.7617C283.775 58.7617 290.171 62.6496 293.369 69.5849L287.582 71.72C284.841 67.2217 280.577 64.783 275.247 64.783C266.871 64.783 261.007 70.7288 261.007 79.1887C261.007 87.7259 266.87 93.7471 275.247 93.7471C280.729 93.7471 284.536 91.5365 287.429 86.8101L293.217 88.9452Z"
      fill={getPathColor(color)}
    />
    <path
      d="M301.059 43.4395H307.456V65.5437C309.892 61.1997 314.461 58.7593 320.324 58.7593C331.136 58.7593 337 64.2472 337 76.7476V99.0817H330.603V77.3599C330.603 68.7473 326.949 64.7823 319.106 64.7823C312.634 64.7823 307.456 68.7456 307.456 76.5213V99.0834H301.059V43.4395Z"
      fill={getPathColor(color)}
    />
    <path
      d="M93.6558 99.0823L82.6144 80.7115L71.8009 99.0823H58.8561L75.4876 71.4112L58.5358 45.7227H72.0596L82.9177 62.8741L93.2738 45.7227H106.219L89.5442 72.2498L106.678 99.0823H93.6558Z"
      fill={getPathColor(color)}
    />
    <path
      d="M148.758 80.9433C148.758 92.6823 139.925 100 128.351 100C118.147 100 109.558 94.3595 108.72 82.0855H119.761C120.522 87.4207 124.788 89.7085 129.432 89.7085C135.219 89.7085 137.717 85.8207 137.717 80.7889C137.717 75.5291 133.833 72.2518 128.427 72.2518C123.935 72.2518 120.964 74.3869 119.593 76.673H109.039L112.686 45.7246H147.398L146.24 56.0916H121.409L120.423 66.3334C120.423 66.3334 123.03 63.2568 130.935 63.2568C140.458 63.2585 148.758 69.6588 148.758 80.9433Z"
      fill={getPathColor(color)}
    />
    <path
      d="M110.023 39.0791C104.14 16.1259 85.4805 0 46.7542 0H0L6.34731 42.9635C11.1219 81.7304 25.9323 94.2 52.1439 98.7944C52.2655 98.8133 53.0964 98.9316 53.1067 97.9592C53.1101 97.6094 52.8891 97.3075 52.5859 97.1772C34.8699 89.7102 32.8484 65.2221 29.064 40.65L26.1362 21.0667H52.8257C77.4337 21.0667 96.4328 20.6774 108.461 39.693C108.639 39.9606 108.918 40.1578 109.249 40.1578C109.713 40.1578 110.059 39.7805 110.059 39.3157C110.058 39.2317 110.047 39.1511 110.023 39.0791Z"
      fill={getPathColor(color)}
    />
  </svg>
)

export default Logo
