import {baseTheme} from '../theme'
import {TableCellDensity} from './types'

export const defaultCellDensity = TableCellDensity.s

export const stickTableCellWidth = '4px'

export const qaCellEditName = 'cell-edit'

export const getTableCellDensitySize = (den: TableCellDensity, theme = baseTheme) => {
  switch (den) {
    case TableCellDensity.s:
      return theme.spaces.x4

    case TableCellDensity.m:
      return theme.spaces.x6

    case TableCellDensity.l:
      return theme.spaces.x6

    default:
      return getTableCellDensitySize(defaultCellDensity, theme)
  }
}

export const getTableIconCellWidth = (den: TableCellDensity) => {
  switch (den) {
    case TableCellDensity.s:
      return '32px'

    case TableCellDensity.m:
      return '40px'

    case TableCellDensity.l:
      return '44px'

    default:
      return getTableIconCellWidth(defaultCellDensity)
  }
}

const getCaseString = (str) => (str ? str[0].toUpperCase() + str.slice(1) : str)

export const getCellDensityClassNameBy = (den: TableCellDensity) =>
  `cellDensity${getCaseString(den)}`

export function getBlockedPositionStyle(pos) {
  return pos !== undefined ? {left: pos} : {}
}

// CellId
const cellIdSepChar = '_'

export const getCellId = (rowId: string, colId: string): string =>
  [rowId, colId].filter((i) => i !== '').join(cellIdSepChar)

export const getFromCellId = (cellId: string): [string, string] => {
  const [rowId, colId] = cellId.split(cellIdSepChar)

  return [String(rowId), String(colId)]
}
