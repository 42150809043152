export const InputWidths = {
  monitoring: '520px',
}
export const TextInputWidth = '300px'
export const TextareaInputWidth = '520px'
export const getWidth = (fieldName, inputMeta) => {
  if (inputMeta.textarea) return TextareaInputWidth
  return InputWidths[fieldName] || TextInputWidth
}

const TableColumnWidths = {
  stakeholders: {
    'Роль в проекте': '240px',
    Контакты: '260px',
  },
  configuration: {
    Назначение: '160px',
    Стенд: '135px',
    'Кол-во серверов': '140px',
    ОС: '140px',
    'СПО (СП, СУБД)': '140px',
    CPU: '140px',
    Memory: '120px',
    Hdd: '135px',
  },
  scripts: {
    'Название операции': '400px',
    Шаги: '460px',
    'Требования к времени выполнения': '270px',
  },
  profiles: {
    'Наименование операции': '300px',
    Категория: '320px',
    Интенсивность: '160px',
  },
  emulators: {
    'Название ИС': '248px',
    'Протокол взаимодействия с ИС (http/amqp/TCP и т.д.)': '240px',
  },
  load_profiles: {
    'Наименование сценария': '400px',
    TPS: '400px',
  },
}
export const getTableColumnWidth = (fieldId, columnLabel) => {
  return TableColumnWidths[fieldId]?.[columnLabel]
}
