import Divider from '@root/components/Divider'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'
import {Modal} from '@root/components/kit'
import {RequiredRule} from '@root/constants'
import useNotify from '@root/hooks/useNotify'
import {
  UniqueTemplateNameRule,
  UniqueVarNameRule,
  getDuplicatedVarRegisters,
} from '@root/pages/SpecialistToolsPage/utils'
import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useCreateTemplateMutation} from '@root/redux/api/templatesApi'
import SystemsCombobox from '@root/features/systems/components/SystemsCombobox'
import VariableInputsList from './VariableInputsList'

const TemplateCreateModal = ({systemId, open, onClose}) => {
  const {notifySuccess, notifyError} = useNotify()

  const [selectedSystemId, setSelectedSystemId] = useState(systemId)

  const {clearErrors: clearFormErrors, reset: resetFormValue, ...form} = useForm()

  useEffect(() => {
    if (open) {
      setSelectedSystemId(systemId)
      resetFormValue({name: '', variables: []})
    }
  }, [open, systemId, resetFormValue])

  const [createTemplate] = useCreateTemplateMutation()
  const handleSubmit = form.handleSubmit((fields) => {
    const duplicats = getDuplicatedVarRegisters(fields.variables)
    if (duplicats.length > 0) {
      duplicats.forEach((registerName) => form.setError(registerName, UniqueVarNameRule))
      return
    }
    const createData = {
      name: fields.name,
      variables: fields.variables,
      systemId: selectedSystemId,
    }
    createTemplate({systemId: selectedSystemId, createData})
      .unwrap()
      .then(() => {
        notifySuccess('Шаблон сохранён')
        onClose()
      })
      .catch((error) => {
        if (error.status === 409) {
          form.setError('name', UniqueTemplateNameRule)
          return
        }
        notifyError()
        console.log(error)
      })
  })

  return (
    <>
      <Modal
        closeOnOverlay
        buttons={[
          {
            onClick: onClose,
            side: 'right',
            text: 'Отмена',
          },
          {
            onClick: handleSubmit,
            side: 'right',
            text: 'Сохранить изменения',
          },
        ]}
        isOpen={open}
        size="medium"
        title="Создание шаблона настроек"
        variant="sideModal"
        onClose={onClose}
      >
        {open && (
          <FlexboxColumn sx={{gap: '24px'}}>
            <FlexboxRow sx={{gap: '24px'}}>
              <SystemsCombobox
                errorOnEmpty
                required
                caption="ИС, к которой будет принадлежать шаблон"
                selectedSystemId={selectedSystemId}
                width={396}
                onChange={(e, newSystem) => setSelectedSystemId(newSystem)}
                onClear={() => setSelectedSystemId(null)}
              />
              <FormInputText
                required
                control={form.control}
                label="Название шаблона"
                name="name"
                rules={RequiredRule}
                width="100%"
              />
            </FlexboxRow>
            <Divider />
            <VariableInputsList form={form} />
          </FlexboxColumn>
        )}
      </Modal>
    </>
  )
}

export default TemplateCreateModal
