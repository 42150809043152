import {DataGridRowProps} from './types'
import {restyled, stateClasses} from '@root/components/kit'

export const StyledRow = restyled.tr<DataGridRowProps>(({theme: {colors}, selected}) => ({
  '&:hover td:not(.custom)': {
    backgroundColor: colors.grey[10],
    borderColor: colors.grey[20],
    [`&:focus, &.${stateClasses.isFocused}`]: {
      backgroundColor: colors.white,
    },
  },
  '& td:not(.custom)': selected
    ? {
        backgroundColor: colors.accent[10],
        borderColor: colors.accent[20],
        borderTop: `1px solid ${colors.accent[60]}`,
      }
    : null,
}))
