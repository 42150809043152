import {makeUiStyles} from '../../../theme'

export const useInnerDateStyles = makeUiStyles(
  (theme) => ({
    innerFakeInput: {},
    innerDropdown: {
      width: 'auto',
      height: 'auto', //'364px',
      padding: theme.spaces.x12,
    },
  }),
  'table-cell-inner-select'
)
