import React, {useState, useMemo, useLayoutEffect, ChangeEvent, FC} from 'react'
import clsx from 'clsx'
import {ChevronRight as ChevronRightIcon} from '@x5-react-uikit/icons'
import {sizeTokenValues} from '@x5-react-uikit/tokens'
import {ListItem} from '../ListItem'
import {Checkbox} from '../Checkbox'

import {highlightText} from '../utils/highlightText'
import {getQaAttribute} from '../utils'
import {
  shouldBeDisabled,
  filterOptions,
  checkAllChildValues,
  checkAllParents,
  getParentValues,
  shouldBeChecked,
  shouldBeExpand,
  getChildsClassName,
} from './helpers'

import {CheckboxTreeProps, CheckboxTreeOption, CheckboxTreeOptionValue} from './types'
import {useStyles} from './styles'

export const CheckboxTree: FC<CheckboxTreeProps> = ({
  options,
  values,
  onlyUserSelected,
  searchStr,
  showNotFound,
  qa = 'checkbox-tree',
  onChange,
  ...props
}) => {
  const getQA = getQaAttribute(qa)
  const classes = useStyles()
  const [expanded, setExpanded] = useState<CheckboxTreeOptionValue[]>([])

  const filteredOptions = useMemo(() => filterOptions(options, searchStr), [options, searchStr])
  const parentValues = useMemo(() => getParentValues(options), [options])
  const isNotFound = useMemo(() => {
    return filteredOptions.every((option) => !option.isVisible)
  }, [filteredOptions])

  useLayoutEffect(() => {
    if (searchStr) {
      setExpanded(parentValues)
    }
  }, [parentValues, searchStr])

  const renderTree = (options: CheckboxTreeOption[], level: number) => {
    return (
      <>
        {options.map((option) => {
          if (!option.isVisible) return null

          const isChecked = shouldBeChecked(option, values)
          const isDisabled = onlyUserSelected && shouldBeDisabled(option, values)
          const isExpanded = shouldBeExpand(expanded, option)
          const className = getChildsClassName(classes, option, level)

          return (
            <div key={option.value} className={className} data-qa={getQA('parent')}>
              {option.label ? <div className={classes.label}>{option.label}</div> : null}
              <div className={classes.item} data-qa={getQA('child')}>
                {option.childs && (
                  <ChevronRightIcon
                    className={clsx(classes.arrow, isExpanded && classes.arrowRotate)}
                    size={sizeTokenValues.small}
                    onClick={() => handleToggleExpand(option.value)}
                  />
                )}
                <Checkbox
                  checked={isChecked}
                  disabled={isDisabled}
                  label={highlightText(option.name, searchStr)}
                  onChange={(event) => handleCheckboxChange(option, event.target.checked, event)}
                />
              </div>
              {option.childs && isExpanded && renderTree(option.childs, level + 1)}
            </div>
          )
        })}
      </>
    )
  }

  const handleToggleExpand = (value: CheckboxTreeOptionValue) => {
    const newExpanded = expanded.includes(value)
      ? expanded.filter((item) => item !== value)
      : [...expanded, value]
    setExpanded(newExpanded)
  }

  const handleCheckboxChange = (
    option: CheckboxTreeOption,
    isChecked: boolean,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    let newValues = isChecked
      ? [...values, option.value]
      : values.filter((value) => value !== option.value)

    if (option.childs) {
      newValues = checkAllChildValues(newValues, option, isChecked)
    }

    if (option.parent) {
      newValues = checkAllParents(newValues, option, isChecked)
    }

    onChange(newValues, event)
  }

  if (isNotFound && showNotFound) {
    return <ListItem secondary text="Ничего не найдено" />
  }

  return (
    <div {...props} data-qa={getQA()}>
      {renderTree(filteredOptions, 0)}
    </div>
  )
}

export default CheckboxTree
