import React, {FC} from 'react'
import clsx from 'clsx'
import {TooltipPlacement, Tooltip} from '@x5-react-uikit/core'
import {Error as ErrorIcon} from '@x5-react-uikit/icons'
import {useCellErrorIconStyles} from './styles'
import {sizeTokenValues} from '@x5-react-uikit/tokens'

export interface BodyCellErrorIconProps {
  className: string
  message: string
  left?: boolean
  direction?: TooltipPlacement
}

export const BodyCellErrorIcon: FC<BodyCellErrorIconProps> = (props: BodyCellErrorIconProps) => {
  const {message, direction = 'bottom', left} = props

  const classes = useCellErrorIconStyles()

  return (
    <Tooltip content={message} placement={direction}>
      <ErrorIcon
        className={clsx(classes.root, left ? classes.left : classes.right)}
        size={sizeTokenValues.small}
      />
    </Tooltip>
  )
}

export default BodyCellErrorIcon
