import {Box, Dropdown, IconButton, Logo, SelectList, Typography} from '@x5-react-uikit/core'
import {useSelector} from 'react-redux'
import {useGetAllRolesQuery, useGetUserinfoQuery} from '@root/redux/api/userApi'
import Paper from './Paper'
import {NOT_IMPLEMENTED} from 'utils'

const ProfileMenuButton = ({logout = () => {}}) => {
  const user = useSelector((state) => state.auth.user)
  const {data: userInfo} = useGetUserinfoQuery()
  const {data: rolesMap} = useGetAllRolesQuery()

  return (
    <div style={{alignSelf: 'center'}}>
      <Dropdown
        disableContentVisual
        action={<StyledButton icon="AccountCircle" />}
        placement="bottom-end"
      >
        <div style={{display: 'flex', flexDirection: 'column', gap: '2px'}}>
          <Paper sx={{padding: '8px 16px 8px 16px', backgroundColor: 'white'}}>
            <Typography variant="h5">{user.email}</Typography>
            <Typography variant="p3">{rolesMap?.[userInfo?.role]}</Typography>
          </Paper>
          <Paper sx={{padding: '8px 0 0 0', backgroundColor: 'white'}}>
            <SelectList
              items={[{name: 'Выход', value: 'logout'}]}
              onChange={(_, newValue) => {
                if (newValue === 'logout') logout()
              }}
            />
          </Paper>
        </div>
      </Dropdown>
    </div>
  )
}

const StyledButton = ({icon, onClick}) => (
  <IconButton
    icon={icon}
    size="small"
    style={{alignSelf: 'center'}}
    variant="text"
    onClick={onClick}
  />
)

const Header = ({logout}) => {
  return (
    <Box
      sx={{
        padding: '12px 16px 12px 16px',
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'center',
        height: '56px',
        backgroundColor: 'white',
      }}
    >
      <Box
        sx={{
          height: '24px',
          width: '289px',
          display: 'flex',
          gap: '8px',
          alignItems: 'end',
        }}
      >
        <Logo
          brand="x5"
          style={{
            borderRight: 'solid black 1px',
            paddingRight: '8px',
            width: 'fit-content',
          }}
          variant="icon"
        />
        <Typography style={{whiteSpace: 'nowrap'}} variant="p2">
          Портал нагрузочного тестирования
        </Typography>
      </Box>

      <Box
        sx={{
          height: '24px',
          width: '289px',
          display: 'flex',
          gap: '8px',
          alignItems: 'end',
          justifyContent: 'end',
        }}
      >
        <StyledButton icon="Notification" onClick={NOT_IMPLEMENTED} />
        <StyledButton icon="Help" onClick={NOT_IMPLEMENTED} />
        <ProfileMenuButton logout={logout} />
      </Box>
    </Box>
  )
}

export default Header
