import React, {FC, Fragment, useEffect, MouseEvent} from 'react'
import {CheckboxTree, CheckboxTreeOptionValue} from '../CheckboxTree'
import clsx from 'clsx'
import {ListCheckedItem} from '../ListCheckedItem'
import {ListItem} from '../ListItem'
import {Hint} from './Hint'
import {useScrollStyles} from '../Scroll'
import {StyledSelectMenu, selectMenuClasses} from './styles'
import {
  SelectValueType,
  SelectOptionType,
  SelectMultipleValueType,
  SelectListProps,
  HandleOptionClick,
} from './types'
import {getQaAttribute, highlightText} from '../utils'

export const SelectList: FC<SelectListProps> = ({
  multiple,
  noWrap,
  selected,
  items,
  qa,
  footer,
  header,
  hint,
  highlighted,
  inputValue,
  renderOption,
  onChange,
}) => {
  const getQA = getQaAttribute(qa)
  const scrollClasses = useScrollStyles()

  const isTree = items?.[0]?.childs

  const handleOptionClick: HandleOptionClick = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    option: SelectOptionType
  ) => {
    let newValue: SelectValueType

    event.preventDefault()
    event.stopPropagation()

    if (!multiple) {
      newValue = option.value
    } else {
      newValue = [...(selected as SelectMultipleValueType)]
      const index = newValue.indexOf(option.value)
      if (index === -1) newValue.push(option.value)
      else newValue.splice(index, 1)
    }
    onChange(event, newValue, option)
  }

  useEffect(() => {
    if (items && items.length > 100) {
      console.warn(
        'SelectList render more 100 items. Use WindowedSelectList for current implementation'
      )
    }
  }, [items])

  const defaultRenderOption = (option, isActive, onClick) => {
    let element = null
    const commonProps = {
      disabled: option.disabled,
      adornment: option.icon,
      noWrap,
      onClick: (event: MouseEvent<HTMLElement>) => !option.disabled && onClick(event, option),
      qa: getQA('item', option.disabled && 'disabled'),
      text: highlightText(option.name, inputValue, highlighted ? highlighted && !isActive : null),
    }

    if (multiple) {
      element = <ListCheckedItem {...commonProps} checked={isActive} />
    } else {
      element = <ListItem {...commonProps} selected={isActive} />
    }

    return (
      <Fragment key={option.value}>
        {option.label ? <div className={selectMenuClasses.label}>{option.label}</div> : null}
        {element}
        {option.caption ? <div className={selectMenuClasses.caption}>{option.caption}</div> : null}
      </Fragment>
    )
  }

  const renderItem = renderOption || defaultRenderOption

  return (
    <StyledSelectMenu isTree>
      <Hint>{hint}</Hint>
      {header ? <div className={selectMenuClasses.header}>{header}</div> : null}
      <div className={clsx(selectMenuClasses.root, scrollClasses.root)} data-qa={getQA()}>
        {isTree ? (
          <CheckboxTree
            showNotFound
            options={items}
            searchStr={inputValue}
            values={selected as CheckboxTreeOptionValue[]}
            onChange={(values, event) => onChange(event, values)}
          />
        ) : (
          items.map((item) => {
            let isActive = false
            if (!multiple) {
              isActive = selected === item.value
            } else {
              isActive = (selected as SelectMultipleValueType).includes(item.value)
            }

            return renderItem(item, isActive, handleOptionClick)
          })
        )}
      </div>
      {footer ? <div className={selectMenuClasses.footer}>{footer}</div> : null}
    </StyledSelectMenu>
  )
}

export default SelectList
