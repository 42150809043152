import {logoColors} from '../types'

export const defineColors =
  (dark = '#222626', light = '#ffffff') =>
  (color: keyof typeof logoColors, accent?: string): string => {
    switch (color) {
      case logoColors.white:
        return light
      case logoColors.black:
        return dark
      default:
        return accent ?? dark
    }
  }
