import {ChangeEventHandler, HTMLAttributes} from 'react'
import {sizeTokenValues} from '@x5-react-uikit/tokens'

import {QA} from '../types'

export const switchSizes = {
  small: sizeTokenValues.small,
  medium: sizeTokenValues.medium,
} as const

type IndeterminateValue = 'halfOn'

export interface SwitchProps extends QA, HTMLAttributes<HTMLInputElement> {
  className?: string
  size?: keyof typeof switchSizes
  label?: string
  checked?: boolean | IndeterminateValue
  halfOn?: boolean
  disabled?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
}
