import {ComponentPropsWithRef, FC} from 'react'
import {Link as LinkDom} from 'react-router-dom'
import {Link} from 'ui-kit'

type Props = ComponentPropsWithRef<typeof LinkDom> & Partial<ComponentPropsWithRef<typeof Link>>

export const RouterLink: FC<Props> = (props) => (
  <Link component={LinkDom} data-qa="router-link" {...props} />
)

export default RouterLink
