import React, {FC} from 'react'
import {Loader} from './Loader'
import {LoaderBlockProps} from './types'
import {restyled} from '../styles'

const StyledLoader = restyled(Loader)<LoaderBlockProps>(({theme, opacity}) => {
  return {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    backgroundColor: opacity ? 'rgba(255, 255, 255, 0.5)' : theme.colors.white,
    zIndex: theme.zIndex.loader,
    '& [data-type*="loader-message"]': opacity
      ? {
          backgroundColor: theme.colors.white,
        }
      : null,
  }
})

export const LoaderBlock: FC<LoaderBlockProps> = ({className, ...props}) => (
  <StyledLoader className={className} {...props} />
)

export default LoaderBlock
