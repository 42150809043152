import React, {FC, useCallback} from 'react'
import {Select, SelectOptionType, SelectProps} from '@root/components/kit'

type Props = {
  onChange: (next: string) => void
  value: string
  selectProps?: Omit<
    SelectProps,
    'value' | 'onChange' | 'options' | 'filter' | 'onFilterChange' | 'onClearClick'
  >
}

const allStatuses = ['В ОПЭ', 'Планируется', 'В разработке', 'В ПЭ', 'Архивирована']

const options: SelectOptionType[] = allStatuses.map((x) => ({name: x, value: x}))

export const MFSMStatusSelect: FC<Props> = ({onChange, value, selectProps}) => {
  const handleChange: SelectProps['onChange'] = useCallback(
    (_e, val) => {
      onChange(val as string)
    },
    [onChange]
  )

  const onClearClick: SelectProps['onClearClick'] = useCallback(() => {
    onChange(null)
  }, [onChange])

  return (
    <Select
      {...selectProps}
      options={options}
      value={value}
      width="100%"
      onChange={handleChange}
      onClearClick={onClearClick}
    />
  )
}
