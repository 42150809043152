import React, {
  FC,
  useState,
  useEffect,
  MouseEventHandler,
  useCallback,
  Fragment,
  useMemo,
} from 'react'
import clsx from 'clsx'
import {useDropdownStyles} from './styles'
import {DropdownProps} from './types'
import {getQaAttribute} from '../utils'
import {
  autoUpdate,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStyles,
  FloatingPortal,
  size,
} from '@floating-ui/react'

import {usePreviousValue} from 'shared'

export const Dropdown: FC<DropdownProps> = ({
  placement = 'bottom-start',
  width = 'fit-content',
  action,
  stretch,
  children,
  qa = 'dropdown',
  disableContentVisual,
  onChange,
  innerRef,
  // updateOnOpen = false,
  contentClassName,
  className,
  usePortal,
  useReferenceWidth,
}) => {
  const getQA = getQaAttribute(qa)
  const classes = useDropdownStyles({width, stretch})
  const [isOpen, setOpen] = useState(false)
  const prevIsOpen = usePreviousValue(isOpen)

  useEffect(() => {
    if (!innerRef) {
      return
    }
    innerRef.current = {
      open: () => setOpen(true),
      close: () => setOpen(false),
    }
  }, [innerRef])

  const middlewares = useMemo(
    () => [
      shift(),
      ...(useReferenceWidth
        ? [
            size({
              apply({rects, elements}) {
                Object.assign(elements.floating.style, {
                  width: `${rects.reference.width}px`,
                })
              },
            }),
          ]
        : []),
    ],
    [useReferenceWidth]
  )

  const {floatingStyles, refs, context} = useFloating({
    open: isOpen,
    onOpenChange: setOpen,
    strategy: 'absolute',
    placement,
    middleware: middlewares,
    whileElementsMounted: autoUpdate,
  })

  const {styles: transitionStyles, isMounted} = useTransitionStyles(context, {
    duration: 150,
  })

  const dismiss = useDismiss(context)

  const {getReferenceProps, getFloatingProps} = useInteractions([dismiss])

  useEffect(() => {
    if (prevIsOpen !== isOpen) {
      onChange?.(isOpen)
    }
  }, [onChange, isOpen, prevIsOpen])

  const handleClick: MouseEventHandler<HTMLDivElement> = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    setOpen((prev) => !prev)
  }, [])

  const ContentWrapper = usePortal ? FloatingPortal : Fragment

  return (
    <div
      ref={refs.setReference}
      className={clsx(classes.container, className)}
      data-qa={getQA()}
      {...getReferenceProps()}
    >
      <div data-qa={getQA('action')} onClick={handleClick}>
        {action}
      </div>
      {isMounted && (
        <ContentWrapper>
          <div
            ref={refs.setFloating}
            className={clsx(
              classes.content,
              !disableContentVisual && classes.contentVisual,
              // !isOpen && classes.hide,
              contentClassName
            )}
            data-qa={getQA('content')}
            style={{...floatingStyles, ...transitionStyles}}
            {...getFloatingProps()}
          >
            {children}
          </div>
        </ContentWrapper>
      )}
    </div>
  )
}

export default Dropdown
