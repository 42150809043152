import React, {FC, useRef, useEffect, useState, useLayoutEffect} from 'react'
import {createPortal} from 'react-dom'
import clsx from 'clsx'
import {IconButton, Typography, Button, useScrollStyles, ButtonDropDown} from '@x5-react-uikit/core'
import * as icons from '@x5-react-uikit/icons'
import {MoreHorizontal as MoreHorizontalIcon} from '@x5-react-uikit/icons'
import {useHeaderStyles, useStyles, useFooterStyles, useSideModalStyles} from './styles'
import {fixBodyPosition, getWidthWithoutPadding, setScrollPosition} from './helpers'
import {getQaAttribute} from '../utils'
import {sizeTokenValues} from '@x5-react-uikit/tokens'
import {ModalProps} from './types'

export const Modal: FC<ModalProps> = ({
  isOpen,
  buttons = [],
  closeOnOverlay = true,
  onClose,
  title,
  maxWidth,
  minWidth,
  size,
  description,
  icon,
  children,
  qa = 'modal',
  variant = 'default',
}) => {
  const getQA = getQaAttribute(qa)
  const portalRef = useRef<HTMLDivElement>()
  const contentRef = useRef<HTMLDivElement>()
  const leftSideRef = useRef<HTMLDivElement>()
  const rightSideRef = useRef<HTMLDivElement>()
  const sideWrapperRef = useRef<HTMLDivElement>()

  const footerSideSizeRef = useRef({left: 0, right: 0})

  const [hasContentScrollBar, setContentScrollBar] = useState(false)
  const [hasDropdown, setDropdown] = useState(false)

  const styles =
    variant === 'sideModal'
      ? useSideModalStyles({maxWidth, minWidth, size})
      : useStyles({maxWidth, minWidth, size})
  const headerStyles = useHeaderStyles()
  const footerStyles = useFooterStyles()
  const scrollStyles = useScrollStyles()

  const Icon = icons[icon]

  const leftSideButtons = buttons.filter((button) => button.side === 'left')
  const rightSideButtons = buttons.filter((button) => button.side === 'right')

  const setScrollbar = () => {
    const hasScrollbar = contentRef.current.scrollHeight > contentRef.current.clientHeight
    setContentScrollBar(hasScrollbar)
  }

  const onOverlay = (event: React.MouseEvent<HTMLDivElement>) => {
    closeOnOverlay && event.target === portalRef.current && onClose(event)
  }

  const onChangeLeftSide = () => {
    const {left, right} = footerSideSizeRef.current
    const width = getWidthWithoutPadding(sideWrapperRef.current)

    !left &&
      !right &&
      (footerSideSizeRef.current = {
        left: leftSideRef.current.offsetWidth,
        right: rightSideRef.current.offsetWidth,
      })

    if (width) {
      const {left, right} = footerSideSizeRef.current
      const hasDropdown = width <= left + right
      setDropdown(hasDropdown)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', setScrollPosition)
    window.addEventListener('resize', onChangeLeftSide)

    return () => {
      window.removeEventListener('scroll', setScrollPosition)
      window.removeEventListener('resize', onChangeLeftSide)
    }
    /* eslint-disable */
  }, [])

  useLayoutEffect(() => {
    onChangeLeftSide()
  }, [isOpen])

  useEffect(() => {
    const observer = new MutationObserver(setScrollbar)

    observer.observe(contentRef.current, {
      subtree: true,
      characterData: true,
      childList: true,
    })

    return () => observer.disconnect()
  }, [])

  useEffect(() => {
    fixBodyPosition(isOpen)
    isOpen && setScrollbar()
  }, [isOpen])

  return createPortal(
    <div
      className={clsx(styles.root, isOpen && styles.open)}
      onClick={onOverlay}
      ref={portalRef}
      data-qa={getQA('overlay')}
    >
      <div className={styles.contentWrapper} data-qa={getQA()}>
        <div className={headerStyles.root}>
          <div className={headerStyles.content}>
            <div className={headerStyles.text}>
              {icon && <Icon />}
              <Typography variant="h3">{title}</Typography>
            </div>
            {description && (
              <div className={clsx(headerStyles.text, 'description')}>
                {icon && <Icon />}
                <Typography variant="p1">{description}</Typography>
              </div>
            )}
          </div>
          <IconButton variant="text" size={sizeTokenValues.small} icon="Close" onClick={onClose} />
        </div>
        <div
          className={clsx(
            scrollStyles.root,
            styles.content,
            hasContentScrollBar && styles.contentBorder
          )}
          ref={contentRef}
        >
          {children}
        </div>
        <div className={footerStyles.root} ref={sideWrapperRef}>
          <div className="left-side" ref={leftSideRef}>
            {hasDropdown ? (
              <ButtonDropDown
                caption=""
                variant="secondary"
                size={sizeTokenValues.small}
                startIcon={<MoreHorizontalIcon />}
                onOptionClick={(event, value) => {
                  const button = leftSideButtons.find((item) => item.text === value)
                  button.onClick()
                }}
                options={leftSideButtons.map((button) => ({
                  name: button.text,
                  value: button.text,
                  disabled: button.disabled,
                }))}
              />
            ) : (
              leftSideButtons.map((button, i) => (
                <Button
                  className={button.disabled && styles.disablePointerEvents}
                  disabled={button.disabled}
                  key={i}
                  variant="text"
                  size={sizeTokenValues.small}
                  onClick={() => button.onClick()}
                  {...button.props}
                >
                  {button.text}
                </Button>
              ))
            )}
          </div>
          <div className="right-side" ref={rightSideRef}>
            {rightSideButtons.map(
              (button, i) =>
                button.content || (
                  <Button
                    key={i}
                    className={button.disabled && styles.disablePointerEvents}
                    disabled={button.disabled}
                    variant={
                      button.variant ?? (i === rightSideButtons.length - 1 ? 'primary' : 'outlined')
                    }
                    size={sizeTokenValues.small}
                    onClick={() => button.onClick()}
                    {...button.props}
                  >
                    {button.text}
                  </Button>
                )
            )}
            {!leftSideButtons.length && !rightSideButtons.length && (
              <Button variant="primary" size={sizeTokenValues.small} onClick={onClose}>
                Закрыть
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>,
    document.body
  )
}

export default Modal
