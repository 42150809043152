export interface FakeEvent {
  //} extends ChangeEvent<HTMLInputElement> {
  target: {
    name?: string
    type?: string
    value?: string | number
    checked?: boolean
  }
}

export function createFakeEvent(value, name?, isCheckbox = false): FakeEvent {
  const o: FakeEvent = {
    target: {},
  }

  if (name !== undefined) {
    o.target.name = name
  }

  if (isCheckbox) {
    o.target.type = 'checkbox'
    o.target.checked = value
  } else {
    o.target.value = value
  }

  return o
}
