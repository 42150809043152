export const valueIsEmpty = (val) => {
  if (typeof val === 'string' || val instanceof String) {
    // clear invisible spaces
    const clear = val.replace(/[^\d]/g, '')
    return clear === ''
  } else {
    return val === null || val === undefined
  }
}

export const fromClipboard = (value) => {
  return value
}

export const toClipboard = (value) => {
  return value
}
