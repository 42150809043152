import React, {
  forwardRef,
  useState,
  cloneElement,
  ReactElement,
  MouseEvent as ReactMouseEvent,
} from 'react'
import {getTableQA} from '../helpers'
import {DataGridRowProps, dataGridExpanderValues} from './types'
import {DataGridCellProps} from '../Cell/types'
import {StyledRow} from '../styles'

export const DataGridRow = forwardRef<HTMLTableRowElement, DataGridRowProps>(
  (
    {
      depth,
      children,
      expander = dataGridExpanderValues.close,
      qa = 'row',
      onExpanderToggle,
      ...props
    },
    ref
  ) => {
    const [cellExpander, setCellExpander] = useState(expander)

    const handleExpanderClick = (event: ReactMouseEvent<HTMLButtonElement>) => {
      const newExpander =
        cellExpander === dataGridExpanderValues.close
          ? dataGridExpanderValues.open
          : dataGridExpanderValues.close
      setCellExpander(newExpander)
      onExpanderToggle?.(event, depth, newExpander)
    }

    return (
      <StyledRow ref={ref} data-qa={getTableQA(qa)} {...props}>
        {React.Children.map(children, (child: ReactElement<DataGridCellProps>, index) => {
          if (child === null || child === undefined) return null

          return cloneElement(child, {
            expander: expander !== dataGridExpanderValues.none ? cellExpander : expander,
            depth: !index ? depth : null,
            onExpanderClick: !index ? handleExpanderClick : null,
          })
        })}
      </StyledRow>
    )
  }
)

export default DataGridRow
