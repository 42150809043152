export enum GitlabFields {
  gitlabProjectId = 'gitlabProjectId',
  gitlabRepoUrl = 'gitlabRepoUrl',
  gitlabBranch = 'gitlabBranch',
}

export enum GitlabStatuses {
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
  CANCELED = 'CANCELED',
  SKIPPED = 'SKIPPED',
  RUNNING = 'RUNNING',
  MANUAL = 'MANUAL',
  SCHEDULED = 'SCHEDULED',
  WAITING_FOR_RESOURCE = 'WAITING_FOR_RESOURCE',
}

export const GitlabFinishedStatuses = [
  GitlabStatuses.SUCCESS,
  GitlabStatuses.FAILED,
  GitlabStatuses.CANCELED,
]

export const SpecialistToolsTabs = {
  settings: {label: 'Настройки по ИС', value: 1},
  templates: {label: 'Шаблоны настроек', value: 2},
  history: {label: 'История тестов', value: 3},
}
