import {Box, Button, Link, Typography} from '@x5-react-uikit/core'
import Breadcrumbs from '@root/components/Breadcrumbs'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import LoaderFrame from '@root/components/Loader'
import Section from '@root/components/Section'
import useNotify from '@root/hooks/useNotify'
import VariablesModal from '@root/pages/report/ReportCreatePage/components/VariablesModal'
import {FC, useState} from 'react'
import {useParams} from 'react-router-dom'
import {
  useChangeReportConfigurationMutation,
  useGetLaunchByIdQuery,
} from '@root/redux/api/launchesApi'
import {formattedDate} from 'utils'
import ConfigurationsCombobox from '../../ReportCreatePage/components/ConfigurationsCombobox'
import {FilterItemType} from '@root/openapi'

type ReportUpdateConfProps = {
  onCancel: () => void
  form: any
}

const ReportUpdateConf: FC<ReportUpdateConfProps> = ({onCancel, form}) => {
  const {reportId} = useParams()
  const {notifyError, notifySuccess} = useNotify()

  const [selectedConfiguration, setSelectedConfiguration] = useState<string | null>(null)
  const [selectedConfigurationError, setSelectedConfigurationError] = useState(false)
  const [variablesModalShown, setVariablesModalShown] = useState(false)

  const {data: launch, isLoading: isLaunchLoading} = useGetLaunchByIdQuery({
    systemId: form.getValues('system.systemId'),
    taskId: form.getValues('taskId'),
    launchId: form.getValues('launchId'),
  })

  const [updateReportConf] = useChangeReportConfigurationMutation()
  const handleUpdate = () => {
    if (!selectedConfiguration) {
      setSelectedConfigurationError(true)
      return
    }
    updateReportConf({reportId, configurationId: selectedConfiguration})
      .unwrap()
      .then(() => {
        notifySuccess('Конфигурация изменена')
        onCancel()
      })
      .catch((error) => {
        console.error(error)
        notifyError()
      })
  }

  const onSelectConf = (_event, confId) => {
    setSelectedConfiguration(confId)
    setSelectedConfigurationError(null)
  }

  if (isLaunchLoading) return <LoaderFrame />

  return (
    <>
      <Box sx={{mx: 'x8', width: '1200px'}}>
        <Breadcrumbs
          routes={[
            {to: '/', label: 'Главная'},
            {to: '/reports', label: 'Отчёты'},
            {to: null, label: 'Формирование отчёта'},
          ]}
        />
        <Typography variant="h2">Редактирование отчета нагрузочного тестирования</Typography>
        <Section sx={{my: 'x10'}}>
          <FlexboxColumn sx={{gap: '40px', width: '400px'}}>
            <FlexboxColumn sx={{gap: '24px'}}>
              <FlexboxColumn sx={{gap: '8px'}}>
                <Typography variant="h3">Вводные данные</Typography>
                <Typography variant="p3">
                  Сверьтесь с вводной информацией и выберите конфигурацию отчета.
                </Typography>
              </FlexboxColumn>
              <FlexboxColumn>
                <Typography variant="h4">Информационная система</Typography>
                <Typography variant="p1">{launch.systemTag || launch.systemName}</Typography>
              </FlexboxColumn>
              <FlexboxColumn>
                <Typography variant="h4">Заявка</Typography>
                <Typography variant="p1">#{launch.taskNumber}</Typography>
              </FlexboxColumn>
              <FlexboxColumn>
                <Typography variant="h4">Тестирование</Typography>
                <Typography variant="p1">
                  ID {launch.pipelineId} от {formattedDate(launch.createdAt)}
                </Typography>
              </FlexboxColumn>
              <FlexboxColumn>
                <Typography variant="h4">Шаблон теста</Typography>
                <FlexboxRow sx={{gap: '8px', alignItems: 'center'}}>
                  <Typography variant="p1">{launch.variablesTemplateName}</Typography>
                  <Link onClick={() => setVariablesModalShown(true)}>Посмотреть</Link>
                </FlexboxRow>
              </FlexboxColumn>
              <ConfigurationsCombobox
                filters={{
                  'system.id': {
                    value: form.getValues('system.systemId'),
                    type: FilterItemType.EQUAL,
                  },
                }}
                isError={selectedConfigurationError}
                selectedConfId={selectedConfiguration}
                onChange={onSelectConf}
                onClear={() => setSelectedConfiguration(null)}
              />
            </FlexboxColumn>
            <FlexboxRow sx={{gap: '16px'}}>
              <Button variant="outlined" onClick={onCancel}>
                Отмена
              </Button>
              <Button onClick={handleUpdate}>Продолжить</Button>
            </FlexboxRow>
          </FlexboxColumn>
        </Section>
      </Box>
      <VariablesModal
        launch={launch}
        open={variablesModalShown}
        onClose={() => setVariablesModalShown(false)}
      />
    </>
  )
}

export default ReportUpdateConf
