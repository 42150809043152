import {MouseEventHandler, ReactNode, ChangeEvent, FC, HTMLProps, HTMLAttributes} from 'react'
import {IconProps} from '@x5-react-uikit/icons'
import {DataGridRowProps} from '../Row/types'
import {DataGridLabelProps} from '../Label/types'
import {QA} from '../../types'

export const cellModeValues = {
  default: 'default',
  text: 'text',
  number: 'number',
  custom: 'custom',
} as const

export const cellAlignValues = {
  left: 'left',
  right: 'right',
} as const

export const cellSideComponentValues = {
  icon: 'icon',
  button: 'button',
  label: 'label',
} as const

type CellModeValue = keyof typeof cellModeValues
type CellAlignValue = keyof typeof cellAlignValues
type SideComponentValue = keyof typeof cellSideComponentValues

interface DataGridSideComponent {
  component?: FC<IconProps>
}

interface DataGridIconComponent extends DataGridSideComponent {
  type: Extract<SideComponentValue, 'icon'>
}

interface DataGridIconButtonComponent extends DataGridSideComponent {
  type: Extract<SideComponentValue, 'button'>
}

interface DataGridLabelComponent {
  type: Extract<SideComponentValue, 'label'>
  value: string
  color: DataGridLabelProps['color']
}

export type DataGridStartComponent = DataGridIconComponent | DataGridLabelComponent

export type DataGridEndComponent =
  | DataGridIconComponent
  | DataGridIconButtonComponent
  | DataGridLabelComponent

export interface DataGridCellProps extends Partial<HTMLAttributes<HTMLTableCellElement>>, QA {
  id?: string
  disabled?: boolean
  indented?: boolean
  error?: boolean
  noWrap?: boolean
  mode?: CellModeValue
  align?: CellAlignValue
  children?: ReactNode
  startComponent?: DataGridStartComponent
  endComponent?: DataGridEndComponent
  expander?: DataGridRowProps['expander']
  depth?: DataGridRowProps['depth']
  onClick?: MouseEventHandler
  onCellChange?: (event: ChangeEvent, cellId?: DataGridCellProps['id']) => void
  onExpanderClick?: MouseEventHandler<HTMLButtonElement>
}
