import {FC, Fragment} from 'react'
import {Image, Text, View} from '@react-pdf/renderer'
import {GraphWithImageData} from '@root/features/reports/types'
import {combineStyles, Hr} from '@root/pdf-components'
import {styles} from './common'

type Props = {
  graphs: GraphWithImageData[]
  noComment?: boolean
}

export const StatisticsSection: FC<Props> = ({graphs = [], noComment}) => (
  <View style={{gap: '24px', flexDirection: 'column'}}>
    {graphs.map((x, i) => (
      <Fragment key={i}>
        <View style={{gap: '24px', flexDirection: 'column'}}>
          <Text style={styles.text}>{x.graphName}</Text>
          <Image src={x.imageBuffer} />

          {!noComment && (
            <View style={{gap: '16px', flexDirection: 'column'}}>
              <Text style={combineStyles(styles.text, {fontWeight: 500})}>Комментарий:</Text>
              <Text style={styles.text}>{x.comment}</Text>
            </View>
          )}
        </View>
        {i === graphs.length - 1 ? null : <Hr />}
      </Fragment>
    ))}
  </View>
)
