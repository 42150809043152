import FlexboxRow from '@root/components/FlexboxRow'
import Header from '@root/components/Header'
import Sidebar from '@root/components/sidebar/Sidebar'
import ErrorMessage from '@root/components/stubs/ErrorMessage'
import AdminPage from '@root/pages/AdminPage/AdminPage'
import CreateTaskPage from '@root/pages/tasks/CreateTaskPage/CreateTaskPage'
import SelectSystemPage from '@root/pages/SelectSystemPage/SelectSystemPage'
import SpecialistToolsPage from '@root/pages/SpecialistToolsPage/SpecialistToolsPage'
import SystemsPage from '@root/pages/SystemsPage/SystemsPage'
import TaskListPage from '@root/pages/tasks/TaskListPage/TaskListPage'
import TaskPage from '@root/pages/tasks/TaskPage/TaskPage'
import {Suspense, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Navigate, Outlet, createBrowserRouter, RouterProvider} from 'react-router-dom'
import {useGetUserinfoQuery} from '@root/redux/api/userApi'
import Loader from '../components/Loader'
import KeycloakCallbackReceiver from './KeycloakCallbackReceiver'
import useKeycloak from '@root/hooks/useKeycloak'
import {setOnAuthRedirect} from './utils'
import ReportViewPage from '@root/pages/report/ReportViewPage/ReportViewPage'
import ReportConfCreatePage from '@root/pages/report/ReportConfCreatePage/ReportConfCreatePage'
import ReportListPage from '@root/pages/report/ReportListPage/ReportListPage'
import CreateReportPage from '@root/pages/report/ReportCreatePage/CreateReportPage'
import ReportConfUpdatePage from '@root/pages/report/ReportConfCreatePage/ReportConfUpdatePage'
import ReportConfViewPage from '@root/pages/report/ReportConfViewPage/ReportConfViewPage'
import ReportUpdatePage from '@root/pages/report/ReportUpdatePage/ReportUpdatePage'
import PageAuthorization from './PageAuthorization'
import {FEATURE_ENABLE_REPORT_PDF_EXPORT, Roles} from '../constants'
import {RouteObject} from 'react-router-dom'
import {ReportPdfPreviewPage} from '@root/pages/report/ReportPdfPreview/page'
import {RootState} from '@root/redux/store'

const restrictedAccess = ({path, roles, element}) => ({
  element: <PageAuthorization allowedRoles={roles} />,
  path,
  children: [{path, element}],
})
const protectedRoutesList: RouteObject[] = [
  // Доступно всем аутентифицированным юзерам
  {path: '/', element: <Navigate to="/tasks" />},
  {path: '/tasks', element: <TaskListPage />},
  {path: '/tasks/:taskId', element: <TaskPage />},
  {path: '/reports', element: <ReportListPage />},
  {path: '/reports/:reportId', element: <ReportViewPage />},
  {path: '/tools', element: <SpecialistToolsPage />},
  {path: '*', element: <Navigate to="/tasks" />},

  // Доступы разграничены ролями
  restrictedAccess({
    path: '/admin',
    roles: [Roles.ADMIN],
    element: <AdminPage />,
  }),
  restrictedAccess({
    path: '/reports/create',
    roles: [Roles.ADMIN, Roles.SPECIALIST, Roles.MANAGER, Roles.BUSINESS],
    element: <CreateReportPage />,
  }),
  restrictedAccess({
    path: '/reports/:reportId/update',
    roles: [Roles.ADMIN, Roles.SPECIALIST, Roles.MANAGER],
    element: <ReportUpdatePage />,
  }),
  restrictedAccess({
    path: '/reports/:reportId/update/configuration',
    roles: [Roles.ADMIN, Roles.SPECIALIST, Roles.MANAGER],
    element: <ReportUpdatePage />,
  }),
  restrictedAccess({
    path: '/reports/configurations/create',
    roles: [Roles.ADMIN, Roles.SPECIALIST, Roles.MANAGER],
    element: <ReportConfCreatePage />,
  }),
  restrictedAccess({
    path: '/reports/configurations/:configurationId',
    roles: [Roles.ADMIN, Roles.SPECIALIST, Roles.MANAGER],
    element: <ReportConfViewPage />,
  }),
  restrictedAccess({
    path: '/reports/configurations/:configurationId/update',
    roles: [Roles.ADMIN, Roles.SPECIALIST, Roles.MANAGER],
    element: <ReportConfUpdatePage />,
  }),
  restrictedAccess({
    path: '/systems',
    roles: [Roles.ADMIN, Roles.SPECIALIST, Roles.MANAGER],
    element: <SystemsPage />,
  }),
  restrictedAccess({
    path: '/tasks/create',
    roles: [Roles.ADMIN, Roles.BUSINESS],
    // @ts-ignore
    element: <SelectSystemPage />,
  }),
  restrictedAccess({
    path: '/tasks/create/:taskId/steps/:stepId',
    roles: [Roles.ADMIN, Roles.BUSINESS],
    element: <CreateTaskPage />,
  }),
  restrictedAccess({
    path: '/tasks/create/:taskId/steps/:stepId#:fieldId',
    roles: [Roles.ADMIN, Roles.BUSINESS],
    element: <CreateTaskPage />,
  }),
  restrictedAccess({
    path: '/tasks/create/:taskId/system',
    roles: [Roles.ADMIN, Roles.BUSINESS],
    // @ts-ignore
    element: <SelectSystemPage />,
  }),
  restrictedAccess({
    path: '/tasks/draft/:taskId/steps/:stepId',
    roles: [Roles.ADMIN, Roles.BUSINESS],
    element: <CreateTaskPage type="draft" />,
  }),
  restrictedAccess({
    path: '/tasks/withdrawn/:taskId/steps/:stepId',
    roles: [Roles.ADMIN, Roles.BUSINESS],
    element: <CreateTaskPage type="withdrawn" />,
  }),
  restrictedAccess({
    path: '/tasks/draft/:taskId/system',
    roles: [Roles.ADMIN, Roles.BUSINESS],
    element: <SelectSystemPage draft />,
  }),
]

if (FEATURE_ENABLE_REPORT_PDF_EXPORT) {
  protectedRoutesList.push(
    restrictedAccess({
      path: '/reports/:reportId/pdf-preview',
      roles: [Roles.ADMIN, Roles.SPECIALIST, Roles.MANAGER],
      element: <ReportPdfPreviewPage />,
    })
  )
}

const ProtectedRoutes = ({keycloakClient, keycloakLogout}) => {
  const userToken = useSelector<RootState>((state) => state.auth.jwtToken)

  useEffect(() => {
    if (keycloakClient && !keycloakClient.authenticated) {
      setOnAuthRedirect()
      keycloakClient.login()
    }
  }, [keycloakClient])

  const {isSuccess: isUserInfoLoaded, error: userInfoError} = useGetUserinfoQuery(undefined, {
    skip: !userToken,
  })

  useEffect(() => {
    // @ts-ignore
    if (userInfoError && userInfoError.status === 401) {
      // setOnAuthRedirect();
      if (!keycloakClient.idToken) keycloakClient.login()
      else keycloakLogout()
    }
  }, [userInfoError, keycloakClient, keycloakLogout])

  if (userInfoError) {
    // @ts-ignore
    return <ErrorMessage error="Что-то пошло не так." />
  }

  if (!isUserInfoLoaded) return <Loader />

  return (
    <>
      <Header logout={keycloakLogout} />
      <FlexboxRow>
        <Sidebar />
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </FlexboxRow>
    </>
  )
}

export const AppRoutes = () => {
  const {keycloakClient, keycloakLogout} = useKeycloak()

  const router = createBrowserRouter([
    {
      path: '/auth-callback',
      element: <KeycloakCallbackReceiver keycloakClient={keycloakClient} />,
    },
    {
      path: '/',
      element: <ProtectedRoutes keycloakClient={keycloakClient} keycloakLogout={keycloakLogout} />,
      children: protectedRoutesList,
    },
  ])
  return <RouterProvider router={router} />
}
