const ErrorMessage = ({error, extraMessage}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
      }}
    >
      <h1>{typeof error === 'string' ? error : JSON.stringify(error)}</h1>
      {extraMessage && (
        <h2>{typeof extraMessage === 'string' ? extraMessage : JSON.stringify(extraMessage)}</h2>
      )}
    </div>
  )
}

export default ErrorMessage
