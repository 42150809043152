import {Link as PdfLink, type LinkProps} from '@react-pdf/renderer'
import {fontX5Ui} from './constants'
import type {FC} from 'react'
import {combineStyles, withLineHeight} from '@root/pdf-components/utils'
import {omit} from 'lodash'
import {baseTheme} from '@root/components/kit'

const styles = withLineHeight(16, 20, {
  color: baseTheme.colors.accent[90],
  fontWeight: 400,
  fontFamily: fontX5Ui,
  letterSpacing: '0.12px',
  textDecoration: 'none',
})

export const Link: FC<LinkProps> = ({children, ...other}) => {
  return (
    <PdfLink {...omit(other, 'style')} style={combineStyles(styles, other.style)}>
      {children}
    </PdfLink>
  )
}
