import {ReactNode} from 'react'
import {PREFIX, restyled} from '../../styles'
import {DataGridInputCellProps} from './types'
import {cellAlignValues} from '../types'
import {ButtonBase} from '../../Button/ButtonBase'

export const classes = {
  input: `${PREFIX}-cell-input`,
  icon: `${PREFIX}-cell-input-icon`,
  single: `${PREFIX}-cell-button-single`,
}

export const StyledButtonCell = restyled(ButtonBase)(({theme: {spaces, colors}}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'transparent',
  color: colors.grey[70],
  transition: 'transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
  cursor: 'pointer',
  borderRadius: spaces.x1,
  '&[data-mode=single]': {
    padding: spaces.x2,
  },
  '&:hover': {
    backgroundColor: colors.grey[30],
  },
  '&:hover:active': {
    backgroundColor: colors.grey[40],
  },
}))

export const StyledFieldCell = restyled.textarea<DataGridInputCellProps>(
  ({theme: {typography}}) => ({
    boxSizing: 'border-box',
    border: 'none',
    fontSize: 'inherit',
    fontFamily: typography.base.fontFamily,
    backgroundColor: 'inherit',
    width: '100%',
    outline: 'none',
    resize: 'vertical',
  })
)

export const StyledInputCell = restyled.div<{
  align: DataGridInputCellProps['align']
  disabled: DataGridInputCellProps['disabled']
  children: ReactNode
}>(({theme: {spaces, colors}, align, disabled}) => ({
  textAlign: 'inherit',
  backgroundColor: 'inherit',
  [`& .${classes.icon}`]: {
    position: 'absolute',
    top: spaces.x4,
    right: spaces.x4,
    display: 'none',
    backgroundColor: 'inherit',
    boxShadow: `0 0 ${spaces.x4}px ${colors.grey[10]}`,
    ...(align === cellAlignValues.right ? {right: 'auto', left: spaces.x4} : null),
  },
  [`tr:hover > td & > .${classes.icon}`]: {
    display: disabled ? 'none' : 'block',
  },
}))
