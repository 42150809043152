import React, {FC} from 'react'
import {defineColors} from '../utils/defineColors'
import {LogoProps} from '../types'

const primary = '#5faf2d'
const getPathColor = defineColors('#000000')

const Logo: FC<LogoProps> = ({color, ...props}) => (
  <svg fill="none" viewBox="0 0 307 100" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M93.7078 98.9866L82.6601 80.6333L71.8404 98.9866H58.8882L75.5275 71.3418L58.5677 45.6777H72.0992L82.9635 62.8128L93.3256 45.6777H106.278L89.5938 72.1797L106.735 98.9866H93.7078Z"
      fill={getPathColor(color)}
    />
    <path
      d="M148.838 80.8653C148.838 92.5931 139.999 99.9039 128.419 99.9039C118.209 99.9039 109.614 94.2688 108.776 82.0081H119.824C120.585 87.3383 124.853 89.6239 129.5 89.6239C135.291 89.6239 137.79 85.7397 137.79 80.7145C137.79 75.4597 133.904 72.1855 128.494 72.1855C124 72.1855 121.027 74.3186 119.656 76.6025H109.097L112.746 45.6836H147.477L146.318 56.0407H121.473L120.486 66.2728C120.486 66.2728 123.095 63.2008 131.004 63.2008C140.533 63.1973 148.838 69.5915 148.838 80.8653Z"
      fill={getPathColor(color)}
    />
    <path
      d="M110.084 39.0419C104.198 16.1105 85.5276 0 46.7809 0H0L6.35093 42.9209C11.1283 81.6492 25.9471 94.1086 52.1719 98.6969C52.2936 98.7157 53.125 98.834 53.1352 97.8625C53.1387 97.513 52.9176 97.2114 52.6141 97.0812C34.8881 89.6213 32.8654 65.1583 29.0789 40.6096L26.1494 21.0449H52.8541C77.4745 21.0449 96.4844 20.656 108.519 39.6535C108.698 39.9208 108.977 40.1178 109.308 40.1178C109.773 40.1178 110.119 39.7409 110.119 39.2766C110.119 39.1926 110.107 39.1138 110.084 39.0419Z"
      fill={getPathColor(color, primary)}
    />
    <path
      d="M174.63 99.3494H211.291V92.6939H182.284V45.799H174.63V99.3494Z"
      fill={getPathColor(color)}
    />
    <path
      d="M233.998 58.4216C227.493 58.4216 221.446 60.2576 217.237 63.6236L220.298 69.1317C223.436 66.4541 228.335 64.7711 233.156 64.7711C240.427 64.7711 244.025 68.3667 244.025 74.5632V76.0167H232.391C220.298 76.0167 216.089 81.3718 216.089 87.8743C216.089 94.9124 221.905 99.8084 231.09 99.8084C237.442 99.8084 241.958 97.6664 244.407 93.9944V99.3494H251.372V74.8692C251.372 63.7766 245.096 58.4216 233.998 58.4216ZM232.314 94.1474C226.727 94.1474 223.36 91.6229 223.36 87.5683C223.36 84.1258 225.426 81.2953 232.697 81.2953H244.025V86.9563C242.188 91.6229 237.825 94.1474 232.314 94.1474Z"
      fill={getPathColor(color)}
    />
    <path
      d="M286.476 58.4216C280.736 58.4216 275.685 60.5636 272.317 64.9241V42.5859H264.969V99.3494H272.011V92.9234C275.302 97.5134 280.506 99.8084 286.476 99.8084C298.34 99.8084 306.988 91.4699 306.988 79.0768C306.988 66.6836 298.34 58.4216 286.476 58.4216ZM285.864 93.3824C278.134 93.3824 272.24 87.7213 272.24 79.0768C272.24 70.4322 278.134 64.8476 285.864 64.8476C293.671 64.8476 299.564 70.4322 299.564 79.0768C299.564 87.7213 293.671 93.3824 285.864 93.3824Z"
      fill={getPathColor(color)}
    />
  </svg>
)

export default Logo
