import {Typography} from '@x5-react-uikit/core'
import Breadcrumbs from '@root/components/Breadcrumbs'
import LoaderFrame from '@root/components/Loader'
import {initStepFormData} from '@root/pages/tasks/CreateTaskPage/utils/formUtils'
import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate, useParams} from 'react-router-dom'
import {
  useEditReportMutation,
  useGetReportByIdQuery,
  useRemoveEditReportMutation,
} from '@root/redux/api/launchesApi'
import DataStep from './components/DataStep'
import ReportUpdateConf from './components/ReportUpdateConf'
import ReviewStep from './components/ReviewStep'
import StatisticsStep from './components/StatisticsStep'
import StepsSection from './components/StepsSection'
import {CommonFieldsOrder} from './constants'
import {useSaveReportBeforeTeardown} from './hooks/useSaveReportBeforeTeardown'

import {components} from '@root/openapi'
import {UpdateReportContextProvider} from './context'
import {useSelector} from 'react-redux'
import {userSelector} from '@root/redux/selectors'
import {useEditReportGuard} from '@root/features/reports/hooks/useEditReportGuard'
import useNotify from '@root/hooks/useNotify'

type FormValues = components['schemas']['ReportResponse'] | {loading: boolean}

const ReportUpdatePage = () => {
  const {reportId} = useParams()
  const {data: report, isLoading: isReportLoading} = useGetReportByIdQuery(reportId)

  useEditReportGuard(report)

  const [step, setStep] = useState(1)
  const [completedSteps, setCompletedSteps] = useState([1])
  const [touchedSteps, setTouchedSteps] = useState([1])
  const [sendLock] = useEditReportMutation()
  const currentUser = useSelector(userSelector)
  const navigate = useNavigate()
  const {notifyError} = useNotify()

  // locking report edit
  useEffect(() => {
    if (report == null) {
      return
    }
    const action = () => {
      sendLock(reportId)
        .unwrap()
        .catch((e) => {
          console.error('Unable to acquire edit lock', e)
          notifyError('Текущий отчет уже кто-то редактирует.')
          navigate('/reports')
        })
    }

    action()

    const timer = setInterval(action, 5 * 60 * 1000)

    return () => {
      clearInterval(timer)
    }
  }, [report, currentUser, reportId, sendLock, navigate, notifyError])

  // unlocking report edit
  const [sendUnlock] = useRemoveEditReportMutation()
  useEffect(() => {
    return (() => {
      sendUnlock(reportId)
    }) as any
  }, [])

  const [changeConfShown, setChangeConfShown] = useState(false)
  const [updated, setUpdated] = useState(false)

  const {
    reset: formReset,
    trigger: formTrigger,
    ...form
  } = useForm<FormValues>({defaultValues: {loading: true}})

  useEffect(() => {
    if (report) {
      const sortedReport = structuredClone(report)
      sortedReport.reportData.commonFields = CommonFieldsOrder.reduce((sorted, fieldMeta) => {
        let field = sortedReport.reportData.commonFields.find((f) => f.id === fieldMeta.id)
        if (!field) field = initStepFormData({[fieldMeta.id]: fieldMeta}).fields[0]
        sorted.push(field)
        return sorted
      }, [])
      // formReset({...sortedReport, systemId: report.system.systemId})
      formReset({...sortedReport})
    }
  }, [report, formReset])

  useSaveReportBeforeTeardown({
    updated,
    reportId,
    getValues: form.getValues,
    isDirty: form.formState.isDirty,
  })

  useEffect(() => {
    if (form.formState.isDirty) {
      setUpdated(false)
    }
  }, [form.formState.isDirty])

  if (changeConfShown)
    return <ReportUpdateConf form={form} onCancel={() => setChangeConfShown(false)} />

  if (isReportLoading || form.getValues('loading')) return <LoaderFrame />

  let currentStepComponent
  switch (Number(step)) {
    case 1:
      currentStepComponent = <DataStep form={form} />
      break
    case 2:
      currentStepComponent = <StatisticsStep form={form} />
      break
    case 3:
      currentStepComponent = <ReviewStep form={form} />
      break
    default:
      currentStepComponent = <DataStep form={form} />
  }

  return (
    <UpdateReportContextProvider value={{setUpdated}}>
      <div style={{margin: '0 16px 0 16px', width: '1200px'}}>
        <Breadcrumbs
          routes={[
            {to: '/', label: 'Главная'},
            {to: '/reports', label: 'Отчеты портала'},
            {to: null, label: 'Формирование отчета'},
          ]}
        />
        <Typography variant="h2">Редактирование отчета нагрузочного тестирования</Typography>
        <StepsSection
          completedSteps={completedSteps}
          currentStep={step}
          form={form}
          formTrigger={formTrigger}
          setChangeConfShown={setChangeConfShown}
          setCompletedSteps={setCompletedSteps}
          setCurrentStep={setStep}
          touchedSteps={touchedSteps}
        />
        {currentStepComponent}
        <StepsSection
          completedSteps={completedSteps}
          currentStep={step}
          form={form}
          formTrigger={formTrigger}
          position="bottom"
          setChangeConfShown={setChangeConfShown}
          setCompletedSteps={setCompletedSteps}
          setCurrentStep={setStep}
          setTouchedSteps={setTouchedSteps as any}
          touchedSteps={touchedSteps}
        />
      </div>
    </UpdateReportContextProvider>
  )
}

export default ReportUpdatePage
