import {Link, Typography} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'
import FormInputFile from './FormInputFile'
import useDownloadFile from '@root/hooks/useDownloadFile'
import {useContext} from 'react'
import {taskContext} from '../../context'

const DownloadLink = ({file}) => {
  const ctx = useContext(taskContext)
  if (ctx == null) {
    throw new Error(`Context was null!`)
  }

  const {download: downloadFile} = useDownloadFile({
    path: `/v1/tasks/${ctx.taskId}/form/meta/example`,
    method: 'GET',
    queryParams: {
      taskId: ctx.taskId,
      fileName: file,
    },
    defaultName: file,
  })

  return (
    <Link style={{width: 'fit-content'}} onClick={downloadFile}>
      {file}
    </Link>
  )
}

const FileContent = ({inputMeta, baseRegisterName, form, fileInputProps, commentInputProps}) => {
  const fileInputName = `${baseRegisterName}.file.items`
  const fileCommentInputName = `${baseRegisterName}.file.comment.value`

  return (
    <FlexboxRow sx={{gap: '160px'}}>
      <FlexboxColumn sx={{gap: '24px'}}>
        <FormInputFile
          control={form.control}
          form={form}
          name={fileInputName}
          {...fileInputProps}
        />
        {inputMeta.comment && (
          <FormInputText
            key={fileCommentInputName}
            control={form.control}
            label={inputMeta.comment.placeholder}
            name={fileCommentInputName}
            textarea={inputMeta.comment?.textarea}
            width="100%"
            {...commentInputProps}
          />
        )}
      </FlexboxColumn>
      {inputMeta.fileDescription && (
        <FlexboxColumn sx={{gap: '16px', width: '483px'}}>
          <Typography style={{whiteSpace: 'pre-wrap'}} variant="p1">
            {inputMeta.fileDescription.text}
          </Typography>

          <DownloadLink file={inputMeta.fileDescription.file} />
        </FlexboxColumn>
      )}
    </FlexboxRow>
  )
}

export default FileContent
