import React, {FC} from 'react'
import {LoaderProps} from './types'
import {getQaAttribute} from '../utils'
import {
  StyledRoot,
  StyledContainer,
  StyledSvg,
  StyledCircle,
  StyledMessage,
  StyledInner,
} from './styles'

export const LoaderDefaultSize = '48px'
const boxSize = 44
const thickness = 3.6

export const Loader: FC<LoaderProps> = ({
  children,
  className,
  size = LoaderDefaultSize,
  qa = 'loader',
}) => {
  const getQA = getQaAttribute(qa)

  return (
    <StyledRoot className={className} data-qa={getQA()}>
      <StyledInner>
        <StyledContainer data-qa={getQA('container')} size={size}>
          <StyledSvg viewBox={`${boxSize / 2} ${boxSize / 2} ${boxSize} ${boxSize}`}>
            <StyledCircle
              cx={boxSize}
              cy={boxSize}
              fill="none"
              r={(boxSize - thickness) / 2}
              strokeWidth={thickness}
            />
          </StyledSvg>
        </StyledContainer>
        {children && <StyledMessage data-type="loader-message">{children}</StyledMessage>}
      </StyledInner>
    </StyledRoot>
  )
}

export default Loader
