import React from 'react'
import createLogo from '../utils/createSvgLogo'
import {logoVariants, logoColors, logoBrands} from '../types'
import MnogoLososyaFull from './Full'
import MnogoLososyaSmall from './Small'

export default createLogo((variant = logoVariants.full, color = logoColors.multi, partialProps) => {
  const props = {
    color,
    width: '100%',
    height: '100%',
    ...partialProps,
  }

  if (variant === logoVariants.icon) return <MnogoLososyaSmall {...props} />

  return <MnogoLososyaFull {...props} />
}, `${logoBrands.mnogoLososya}Logo`)
