import useNotify from '@root/hooks/useNotify'
import {useSaveChangesBeforeTeardown} from '@root/hooks/useSaveChangesBeforeTeardown'
import {useCallback} from 'react'
import useUpdateReport from './useUpdateReport'

type Params = {
  updated: boolean
  reportId: string
  isDirty: boolean
  getValues: () => any
}

export const useSaveReportBeforeTeardown = ({updated, reportId, isDirty, getValues}: Params) => {
  const {notifySuccess, notifyError} = useNotify()

  const {update} = useUpdateReport()
  const saveAction = useCallback(async () => {
    if (updated) {
      return
    }

    const updateData = getValues()
    try {
      await update(updateData, reportId)
      notifySuccess('Изменения сохранены')
    } catch (e) {
      notifyError(e)
    }
  }, [getValues, notifyError, notifySuccess, reportId, update, updated])

  useSaveChangesBeforeTeardown({shouldBlock: isDirty, saveAction})
}
