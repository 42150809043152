import {Typography} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import Section from '@root/components/Section'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'
import {RequiredRule} from '@root/constants'

const ReportDataSection = ({form}) => {
  return (
    <Section sx={{display: 'flex', flexDirection: 'column', gap: '24px', my: 0}}>
      <Typography variant="h3">Данные отчёта</Typography>
      <FlexboxRow sx={{gap: '64px'}}>
        <FormInputText
          required
          control={form.control}
          label="Наименование отчёта"
          name="name"
          rules={RequiredRule}
          width="548px"
        />
        <FlexboxColumn sx={{gap: '4px'}}>
          <Typography>Конфигурация</Typography>
          <Typography>{form.getValues('reportConfigurationName')}</Typography>
        </FlexboxColumn>
      </FlexboxRow>
    </Section>
  )
}

export default ReportDataSection
