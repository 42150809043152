import React from 'react'
import createLogo from '../utils/createSvgLogo'
import {logoVariants, logoColors, logoBrands} from '../types'
import TS5Full from './Full'
import TS5Small from './Small'

export default createLogo((variant = logoVariants.full, color = logoColors.multi, partialProps) => {
  const props = {
    color,
    width: '100%',
    height: '100%',
    ...partialProps,
  }

  if (variant === logoVariants.icon) return <TS5Small {...props} />

  return <TS5Full {...props} />
}, `${logoBrands.pyaterochka}Logo`)
