import {LaunchInfo} from '@root/pages/SpecialistToolsPage/components/launchHistoryTab/types'
import Divider from '@root/components/Divider'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import {Modal, ModalButton, Typography} from '@root/components/kit'
import VariablesList from '@root/pages/SpecialistToolsPage/components/systemSettingsTab/VariablesList'
import {FC} from 'react'
import {formattedDate} from 'utils'

type VariablesModalProps = {
  launch: LaunchInfo
  open: boolean
  onClose: () => void
  onOpenReport?: () => void
}

const VariablesModal: FC<VariablesModalProps> = ({launch, open, onClose, onOpenReport}) => {
  const buttons: ModalButton[] = [
    // {
    //   side: 'left',
    //   text: 'Сохранить как новый шаблон',
    //   content: <SaveNewTemplateButton systemId={systemId} form={form} closeModal={onClose} />
    // },
    {
      onClick: onClose,
      side: 'right',
      text: 'Закрыть',
    },
  ]
  if (onOpenReport)
    buttons.push({
      onClick: onOpenReport,
      side: 'left',
      text: 'Посмотреть отчёт',
    })

  return (
    <>
      <Modal
        closeOnOverlay
        buttons={buttons}
        isOpen={open}
        size="medium"
        title="Редактирование шаблона настроек"
        variant="sideModal"
        onClose={onClose}
      >
        {launch && (
          <FlexboxColumn sx={{gap: '24px'}}>
            <FlexboxRow sx={{gap: '32px'}}>
              <FlexboxColumn sx={{width: '204px', gap: '16px'}}>
                <Typography variant="h4">Информационная система</Typography>
                <Typography variant="h4">Изначальный шаблон</Typography>
                <Typography variant="h4">Автор запуска</Typography>
                <Typography variant="h4">Дата и время</Typography>
              </FlexboxColumn>
              <FlexboxColumn sx={{gap: '16px'}}>
                <Typography variant="p1">{launch.systemTag || launch.systemName}</Typography>
                <Typography variant="p1">{launch.variablesTemplateName}</Typography>
                <Typography variant="p1">
                  {launch.creator ? `${launch.creator.name} (${launch.creator.email})` : '-'}
                </Typography>
                <Typography variant="p1">
                  {launch.createdAt && formattedDate(launch.createdAt)}
                </Typography>
              </FlexboxColumn>
            </FlexboxRow>
            <Divider />
            <VariablesList variables={launch.variables} />
          </FlexboxColumn>
        )}
      </Modal>
    </>
  )
}

export default VariablesModal
