import {
  ElementType,
  MouseEventHandler,
  ButtonHTMLAttributes,
  ReactNode,
  RefAttributes,
  CSSProperties,
} from 'react'
import {sizeTokenValues} from '@x5-react-uikit/tokens'
import {QA} from '../types'

export enum buttonVariants {
  primary = 'primary',
  secondary = 'secondary',
  outlined = 'outlined',
  text = 'text',
}

export const buttonSizes = sizeTokenValues

export enum buttonModes {
  light = 'light',
  dark = 'dark',
}

export enum buttonTypes {
  button = 'button',
  reset = 'reset',
  submit = 'submit',
}

export interface ButtonBaseProps
  extends RefAttributes<HTMLButtonElement>,
    ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: MouseEventHandler
  children?: ReactNode
}

export interface ButtonProps extends QA {
  variant?: keyof typeof buttonVariants
  size?: keyof typeof buttonSizes
  type?: keyof typeof buttonTypes
  mode?: keyof typeof buttonModes
  loading?: boolean
  disabled?: boolean
  equated?: boolean
  className?: string
  startIcon?: ReactNode
  endIcon?: ReactNode
  href?: string
  component?: ElementType
  children: ReactNode
  onClick?: MouseEventHandler
  style?: CSSProperties
}

export type StyledButtonProps = Partial<ButtonProps> & {
  'data-qa': string
  'data-type': string
  hasLoader: boolean
}
