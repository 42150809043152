import {Document, Page, View, Text} from '@react-pdf/renderer'
import {Section, Heading, Link, Title, Hr} from '@root/pdf-components'
import {ReportWithStatisticsImages} from '../types'
import {FC, Fragment, useMemo} from 'react'
import {formatDateRuLocale, formatDateTimeRuLocale} from '@root/utils'
import {ConfigurationTable} from './ConfigurationTable'
import {VariablesList} from './VariablesList'

import {
  type Anchor,
  styles,
  enhanceText,
  noDataStub,
  getCommonFieldsMap,
  getDynamicFieldsMap,
  getAnchorsWithDynamicFields,
} from './common'
import {StatisticsSection} from './StatisticsSection'

type Props = {
  report: ReportWithStatisticsImages
}

enum AnchorsKind {
  TestingObjective = '-1',
  Conclusions = '-2',
  Statistics = 'Statistics',
  TestingApproach = '-3',
  TestingLimitations = '-4',
  EnvironmentConfiguration = '-5',
  TestVariables = '-6',
}

const anchors: Anchor[] = [
  {
    text: 'Цель тестирования',
    href: AnchorsKind.TestingObjective,
  },
  {
    text: 'Выводы',
    href: AnchorsKind.Conclusions,
  },
  {
    text: 'Статистика',
    href: AnchorsKind.Statistics,
  },
  {
    text: 'Подход к тестированию',
    href: AnchorsKind.TestingApproach,
  },
  {
    text: 'Ограничения тестирования',
    href: AnchorsKind.TestingLimitations,
  },
  {
    text: 'Конфигурация среды',
    href: AnchorsKind.EnvironmentConfiguration,
  },
  {
    text: 'Переменные теста',
    href: AnchorsKind.TestVariables,
  },
]

export const PdfReport: FC<Props> = ({report}) => {
  const commonFieldsMap = useMemo(() => getCommonFieldsMap(report), [report])

  console.log(commonFieldsMap)

  const dynamicFieldsMap = useMemo(() => getDynamicFieldsMap(report), [report])

  const anchorsWithDynamicFields: Anchor[] = useMemo(
    () => getAnchorsWithDynamicFields(dynamicFieldsMap, anchors),
    [dynamicFieldsMap]
  )

  const formDateTimeFormatted = formatDateTimeRuLocale(report.createdAt)
  const pipelineDateFormatted = formatDateRuLocale(report.testFinishedAt)

  return (
    <Document>
      <Page wrap dpi={72} size={[1600]} style={styles.page}>
        <Section style={{gap: '16px'}}>
          <Heading
            subtitle={`Сформирован ${report.creator.name} (${report.creator.email}) ${formDateTimeFormatted}`}
            title={report.name}
          />
          <View style={styles.listItem}>
            <Text style={styles.text}>Тестирование</Text>
            <Text style={styles.text}>
              ID {report.launch?.pipelineId} от {pipelineDateFormatted}
            </Text>
          </View>
          <View style={styles.listItem}>
            <Text style={styles.text}>Шаблон переменных</Text>
            <Text style={styles.text}>{report.variablesTemplateName}</Text>
          </View>
          <View style={styles.listItem}>
            <Text style={styles.text}>Информационная система</Text>
            <Text style={styles.text}>{report.system.systemTag ?? report.system.systemName}</Text>
          </View>
          <View style={styles.listItem}>
            <Text style={styles.text}>Конфигурация</Text>
            <Text style={styles.text}>{report.reportConfigurationName}</Text>
          </View>
          <View style={styles.listItem}>
            <Text style={styles.text}>Заявка</Text>
            <Text style={styles.text}>#{report.taskNumber}</Text>
          </View>
          <View style={styles.listItem}>
            <Text style={styles.text}>Задачи в Kaiten</Text>
            <Text style={styles.text}>
              {report.kaitenCardLink ? (
                <Link href={report.kaitenCardLink}>Перейти к связанным задачам</Link>
              ) : (
                noDataStub
              )}
            </Text>
          </View>
        </Section>
        <Section>
          <View>
            <View style={{marginBottom: '24px'}}>
              <Title level="h2">Оглавление</Title>
            </View>
            <View>
              {anchorsWithDynamicFields.map((x, i) => (
                <Link key={i} src={`#${x.href}`}>
                  • {x.text}
                </Link>
              ))}
            </View>
          </View>
        </Section>
        <Section style={{gap: '24px'}}>
          <View>
            <View style={{marginBottom: '24px'}}>
              <Title level="h2">Цель тестирования</Title>
            </View>
            <Text id={AnchorsKind.TestingObjective} style={styles.text}>
              {enhanceText(commonFieldsMap?.goal?.values?.comment?.value) ?? noDataStub}
            </Text>
          </View>
          <Hr />
          <View>
            <View style={{marginBottom: '24px'}}>
              <Title level="h2">Выводы</Title>
            </View>
            <Text id={AnchorsKind.Conclusions} style={styles.text}>
              {enhanceText(commonFieldsMap.conclusion?.values?.comment?.value) ?? noDataStub}
            </Text>
          </View>

          {report.statistics?.graphs?.length > 0 && (
            <View>
              <View id={AnchorsKind.Statistics} style={{marginBottom: '24px'}}>
                <Title level="h2">Статистика</Title>
              </View>
              <View style={{gap: '24px', flexDirection: 'column'}}>
                <StatisticsSection graphs={report.statistics.graphs} />
              </View>
            </View>
          )}

          <Hr />

          <View>
            <View style={{marginBottom: '24px'}}>
              <Title level="h2">Подход к тестированию</Title>
            </View>
            <Text id={AnchorsKind.TestingApproach} style={styles.text}>
              {enhanceText(commonFieldsMap.approach?.values?.comment?.value) ?? noDataStub}
            </Text>
          </View>
          <Hr />
          <View>
            <View style={{marginBottom: '24px'}}>
              <Title level="h2">Ограничения тестирования</Title>
            </View>
            <Text id={AnchorsKind.TestingLimitations} style={styles.text}>
              {enhanceText(commonFieldsMap.limit?.values?.comment?.value) ?? noDataStub}
            </Text>
          </View>
          <Hr />
          <View>
            <View style={{marginBottom: '24px'}}>
              <Title level="h2">Конфигурация среды</Title>
            </View>
            <View id={AnchorsKind.EnvironmentConfiguration}>
              <ConfigurationTable commonFieldsMap={commonFieldsMap} />
            </View>
          </View>
          {Object.values(dynamicFieldsMap).length > 0 && <Hr />}
          {Object.values(dynamicFieldsMap).map((x, i) => (
            <Fragment key={`dynamic-section-${x.id}`}>
              <View>
                <View style={{marginBottom: '24px'}}>
                  <Title level="h2">{x.label}</Title>
                </View>
                <Text id={x.id} style={styles.text}>
                  {enhanceText(x.values?.comment?.value) ?? noDataStub}
                </Text>
              </View>
              {Object.values(dynamicFieldsMap).length - 1 !== i && <Hr />}
            </Fragment>
          ))}
        </Section>
        <Section>
          <View>
            <View style={{marginBottom: '24px'}}>
              <Title level="h2">Переменные теста</Title>
            </View>

            <VariablesList id={AnchorsKind.TestVariables} report={report} />
          </View>
        </Section>
      </Page>
    </Document>
  )
}
