import {ReactNode, FC} from 'react'
import {IconProps} from '@x5-react-uikit/icons'
import {QA} from '../../types'
import {CheckboxProps, CheckboxState} from '../../Checkbox/types'
import {sortDirections, columnfilterModes} from './HeadCell'

export const headCellComponentValues = {
  checkbox: 'checkbox',
  control: 'control',
} as const

export interface HeadCellCheckboxComponent {
  type: Extract<keyof typeof headCellComponentValues, 'checkbox'>
  checked?: CheckboxProps['checked']
}

export interface HeadCellControlComponent {
  type: Extract<keyof typeof headCellComponentValues, 'control'>
  sort?: keyof typeof sortDirections
  filter?: keyof typeof columnfilterModes
}

export type ControlParams =
  | {
      type: 'sort'
      dir: HeadCellControlComponent['sort']
    }
  | {
      type: 'filter'
      filter: HeadCellControlComponent['filter']
    }
  | {
      type: 'checkbox'
      checked: CheckboxState
    }

type HeadCellComponent = HeadCellCheckboxComponent | HeadCellControlComponent | null | undefined

export interface DataGridHeadCellProps
  extends Partial<Pick<HTMLTableCellElement, 'colSpan' | 'rowSpan' | 'scope'>>,
    QA {
  name?: string
  children?: ReactNode
  disabled?: boolean
  icon?: FC<IconProps>
  component?: HeadCellComponent
  onChange?: (name: DataGridHeadCellProps['name'], params: ControlParams) => void | null
}
