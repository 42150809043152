import React, {FC} from 'react'
import clsx from 'clsx'
import dayjs from 'dayjs'
import {Tooltip} from '@x5-react-uikit/core'

import {CalendarDayProps} from './types'
import {useStyles} from './styles'

const Day: FC<CalendarDayProps> = ({
  value,
  states,
  onClick: onClickFromProps,
  dateFormat,
  tooltip,
  qa,
  ...props
}) => {
  const classes = useStyles(states)
  const day = dayjs(value, dateFormat).date() || ''

  const onClick = (event) => {
    if (!states.includes('disabled')) {
      onClickFromProps(event)
    }
  }

  return (
    <Tooltip content={tooltip}>
      <div>
        <button
          className={clsx(
            classes.root,
            states.map((state) => classes[state])
          )}
          data-qa={qa}
          data-value={value}
          type="button"
          onClick={onClick}
          {...props}
        >
          {day}
        </button>
      </div>
    </Tooltip>
  )
}

export default Day
