import React, {FC} from 'react'
import {Dayjs} from 'dayjs'

import {capitalize, IconButton} from '@x5-react-uikit/core'
import {sizeTokenValues} from '@x5-react-uikit/tokens'

import Dropdown from '../Dropdown'
import {getMonths, getYears} from './helpers'
import {CalendarHeaderProps} from './types'

import {getQaAttribute} from '../../utils'
import {useStyles} from './styles'

const Header: FC<CalendarHeaderProps> = ({
  hideArrow,
  dateFormat,
  onChangeViewDate,
  onChangeViewDateHandle,
  viewDate,
  long,
  minDate,
  maxDate,
  qa,
}) => {
  const getQA = getQaAttribute(qa)
  const classes = useStyles()
  const year = viewDate.format('YYYY')
  const month = capitalize(viewDate.format('MMMM'))

  const previousDate = viewDate.month(viewDate.month() - (long ? 2 : 1)) as Dayjs
  const nextDate = viewDate.month(viewDate.month() + 1) as Dayjs

  const isDisabledPreviousDate = +previousDate < +minDate
  const isDisabledNextDate = +nextDate >= +maxDate

  const months = getMonths({date: viewDate, minDate, maxDate, dateFormat})
  const years = getYears({date: viewDate, minDate, maxDate, dateFormat})

  return (
    <div className={classes.root} data-qa={getQA()}>
      {hideArrow !== 'left' && (
        <div className={classes.leftArrow} data-qa={getQA('previous')}>
          <IconButton
            data-value={previousDate.format(dateFormat)}
            disabled={isDisabledPreviousDate}
            icon="ChevronLeft"
            size={sizeTokenValues.small}
            variant="text"
            onClick={onChangeViewDateHandle}
          />
        </div>
      )}
      <div className={classes.dropdowns}>
        <Dropdown
          disabled={long}
          options={months}
          qa={getQA('month')}
          title={month}
          width={120}
          onChange={onChangeViewDate}
        />
        <Dropdown
          isSameWidth
          disabled={long}
          options={years}
          qa={getQA('year')}
          title={year}
          onChange={onChangeViewDate}
        />
      </div>
      {hideArrow !== 'right' && (
        <div className={classes.rightArrow} data-qa={getQA('next')}>
          <IconButton
            data-value={nextDate.format(dateFormat)}
            disabled={isDisabledNextDate}
            icon="ChevronRight"
            size={sizeTokenValues.small}
            variant="text"
            onClick={onChangeViewDateHandle}
          />
        </div>
      )}
    </div>
  )
}

export default Header
