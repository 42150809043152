import React, {FC} from 'react'
import {LogoProps, logoColors} from '../types'
import Blank from '../Blank'

const Logo: FC<LogoProps> = ({color, ...props}) => {
  if (color === logoColors.white) return <Blank color={color} {...props} />

  return (
    <svg fill="none" viewBox="0 0 375 97" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M93.7029 33.1561C93.7029 44.9395 85.6683 54.4841 75.7518 54.4841C65.8354 54.4841 57.8008 44.9395 57.8008 33.1561C57.8008 21.3728 65.8354 11.8281 75.7518 11.8281C85.6683 11.8281 93.7029 21.3728 93.7029 33.1561Z"
        fill="white"
      />
      <path
        d="M153.772 36.6881L153.779 60.8129C151.941 62.0242 149.723 62.9882 147.195 63.5907C142.291 64.7639 139.154 64.4785 139.148 57.5848L139.141 40.1888L123.668 43.8925L123.674 65.4172C123.674 76.097 128.046 84.1767 143.596 80.473C147.886 79.4519 151.637 77.6952 153.785 76.2365L153.791 97.0001L169.265 93.3154L169.252 32.9844L153.772 36.6881Z"
        fill="black"
      />
      <path
        d="M203.177 41.2545L192.386 63.3245L189.63 69.324L189.478 69.362L189.782 62.9947L189.776 44.4508L175.912 47.7677L175.924 91.7301L189.25 88.5591L199.971 66.6033L202.803 60.4072L202.88 60.3881L202.651 66.654L202.658 85.3691L216.516 82.0713L216.509 38.0645L203.177 41.2545Z"
        fill="black"
      />
      <path
        d="M281.692 22.4824L266.529 26.11L258.031 48.8713L258.025 28.1458L244.085 31.4753L244.091 52.1945L235.582 33.5111L220.488 37.1133L230.684 56.4309L219.43 81.3802L234.904 77.6955L244.091 54.6996V75.5075L258.038 72.1907L258.031 51.3764L267.226 70.0027L282.699 66.318L271.433 46.7087L281.692 22.4824Z"
        fill="black"
      />
      <path
        d="M312.943 15.0118L302.152 37.1325L299.389 43.1511L299.237 43.1891L299.541 36.8091V18.2208L285.677 21.5313L285.683 65.6079L299.015 62.437L309.736 40.4304L312.569 34.2152L312.645 34.1962L312.417 40.4811V59.2406L326.281 55.9428L326.275 11.8281L312.943 15.0118Z"
        fill="black"
      />
      <path
        d="M374.381 44.4881L360.365 24.7075L372.461 0.779297L357.063 4.46398L346.272 28.4176L346.266 7.04515L332.402 10.362L332.408 54.483L346.272 51.1852V29.8064L358.223 48.3376L374.381 44.4881Z"
        fill="black"
      />
      <path
        d="M110.672 29.5976C110.596 28.8239 109.671 28.399 108.758 28.0692C106.933 27.4096 102.941 26.6042 99.5831 26.0778C98.9684 24.8348 98.1003 23.4078 96.7634 21.5877C94.8308 18.9558 90.2305 14.6369 85.662 11.8718C74.7126 5.25082 61.8307 4.10292 49.6838 8.1047C39.2858 11.5357 31.8848 16.7107 27.4303 25.5768C24.9908 30.4347 23.1849 38.3876 23.1849 38.3876C16.3036 36.9606 10.2903 33.9862 2.25574 29.4961C-1.37502 39.5354 0.0760157 54.0015 6.27303 61.8972C13.712 71.3784 23.3877 75.8558 32.1573 77.3082C26.125 78.4307 19.5415 79.9528 19.5415 79.9528C19.5415 79.9528 25.3076 85.3878 38.7218 88.8315C50.0513 91.7424 59.2328 91.6219 64.5554 91.1083C70.5686 90.6833 76.5565 88.7173 82.424 84.817C96.719 75.3358 104.766 56.3797 102.941 39.1549C105.977 36.1742 108.866 33.1998 110.171 31.0499C110.469 30.5552 110.716 30.0479 110.672 29.5976ZM89.787 40.4931C88.8302 43.4167 87.3918 46.315 84.965 48.2049C82.2403 50.3295 78.6349 50.9002 75.1879 51.0778C73.5151 51.1603 71.8232 51.1666 70.2075 50.729C66.8682 49.8221 64.2385 47.0634 62.8572 43.8924C61.4695 40.7214 61.1971 37.1636 61.3555 33.7072C61.5519 29.4454 62.4453 25.0568 65.0306 21.6701C67.6159 18.2835 72.9131 17.0405 77.1648 17.3005C82.9627 17.6557 87.151 21.2833 89.5208 25.8939C91.8083 30.3333 91.3457 35.743 89.787 40.4931ZM85.0347 31.1704C85.0347 35.2039 83.0894 38.47 80.7006 38.47C78.3054 38.47 76.3601 35.2039 76.3601 31.1704C76.3601 27.1369 78.3054 23.8708 80.7006 23.8708C83.0894 23.8708 85.0347 27.1369 85.0347 31.1704Z"
        fill="black"
      />
      <path
        d="M89.5716 40.7086C88.6148 43.6323 87.1764 46.5369 84.7496 48.4331C82.0249 50.5577 78.4068 51.1348 74.9598 51.2997C73.2933 51.3821 71.5952 51.3948 69.9794 50.9509C66.6337 50.0503 64.0041 47.2916 62.6228 44.1143C61.2288 40.9369 60.9563 37.3791 61.1147 33.9164C61.3111 29.6546 62.2046 25.2533 64.7898 21.8667C67.3751 18.4737 72.685 17.2307 76.9304 17.4907C82.7346 17.8522 86.9293 21.4798 89.3054 26.0967C91.5929 30.5425 91.1303 35.9585 89.5716 40.7086ZM108.555 28.272C106.731 27.6125 102.739 26.807 99.374 26.2743C98.7657 25.0313 97.8913 23.6044 96.5543 21.7906C94.6154 19.1586 90.0151 14.8271 85.4466 12.062C74.4909 5.4283 61.5963 4.28041 49.4367 8.28853C39.026 11.7195 31.6187 16.8946 27.1642 25.7733C24.731 30.6376 22.9188 38.5967 22.9188 38.5967C16.0248 37.1698 10.0115 34.1954 1.9643 29.699C-1.6728 39.7446 -0.215428 54.2233 5.98792 62.1318C13.4332 71.6193 23.1216 76.0967 31.8975 77.5617C25.8589 78.6779 19.269 80.2063 19.269 80.2063C19.269 80.2063 25.0415 85.6414 38.4684 89.0978C49.8042 92.0024 58.992 91.8819 64.321 91.3745C70.3342 90.9433 76.3348 88.9773 82.2023 85.0706C96.5163 75.5767 104.57 56.6143 102.739 39.3641C105.774 36.3834 108.67 33.409 109.975 31.2528C110.273 30.7644 110.52 30.2444 110.469 29.8005C110.399 29.0267 109.474 28.6018 108.555 28.272Z"
        fill="black"
      />
      <path
        d="M80.4725 38.6867C78.0773 38.6867 76.132 35.4142 76.132 31.3744C76.132 27.3409 78.0773 24.0685 80.4725 24.0685C82.8676 24.0685 84.8129 27.3409 84.8129 31.3744C84.8192 35.4142 82.8676 38.6867 80.4725 38.6867ZM89.3054 26.0979C86.9293 21.4809 82.7346 17.847 76.9304 17.4919C72.685 17.2255 67.3751 18.4685 64.7898 21.8678C62.2046 25.2544 61.3111 29.6557 61.1147 33.9175C60.9563 37.3802 61.2288 40.9381 62.6228 44.1154C64.0041 47.2927 66.6337 50.0515 69.9794 50.952C71.5951 51.396 73.2933 51.3833 74.9598 51.3008C78.4068 51.1296 82.0249 50.5588 84.7495 48.4279C87.1701 46.538 88.6148 43.6334 89.5716 40.7098C91.1303 35.9596 91.5929 30.5373 89.3054 26.0979Z"
        fill="white"
      />
      <path
        d="M84.8129 31.3802C84.8129 35.4137 82.8676 38.6924 80.4725 38.6924C78.0773 38.6924 76.132 35.42 76.132 31.3802C76.132 27.3467 78.0773 24.0742 80.4725 24.0742C82.8676 24.0679 84.8129 27.3403 84.8129 31.3802Z"
        fill="black"
      />
    </svg>
  )
}

export default Logo
