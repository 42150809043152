import React, {forwardRef} from 'react'
import {Close as CloseIcon} from '@x5-react-uikit/icons'
import {sizeTokenValues} from '@x5-react-uikit/tokens'

import {SelectChipProps} from './types'
import {StyledSelectChip, selectChipClasses} from './styles'

export const SelectChip = forwardRef<HTMLDivElement, SelectChipProps>(
  ({text, count, size, disabled, onClearClick}, ref) => {
    if (!count) return null
    return (
      <StyledSelectChip ref={ref} disabled={disabled} size={size}>
        <div className={selectChipClasses.count}>
          {text}
          {count}
        </div>
        <button
          aria-label="close"
          className={selectChipClasses.close}
          type="button"
          onClick={onClearClick}
        >
          <CloseIcon size={sizeTokenValues.small} />
        </button>
      </StyledSelectChip>
    )
  }
)

export default SelectChip
