import {useEffect, useRef} from 'react'
import {useLocation} from 'react-router-dom'

const useScrollToField = () => {
  const {hash: fieldId} = useLocation()
  const fieldRef = useRef()

  useEffect(
    () => (fieldRef.current ? fieldRef.current.scrollIntoView() : window.scrollTo(0, 0)),
    []
  )

  return {fieldId: fieldId.replace(/#/, ''), fieldRef}
}

export default useScrollToField
