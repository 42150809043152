import {PREFIX, restyled, doSelectors} from '../styles'
import {CheckboxProps} from './types'

export const checkboxClasses = {
  root: `${PREFIX}-checkbox-root`,
  input: `${PREFIX}-checkbox-input`,
  label: `${PREFIX}-checkbox-label`,
} as const

const selectors = doSelectors(checkboxClasses)

export const StyledCheckbox = restyled.label<CheckboxProps>(
  ({theme: {colors, spaces, typography}, checked, disabled, error}) => {
    const getStateStyles = (hovered?: boolean) => {
      if (disabled) {
        return {
          borderColor: !checked ? colors.grey[20] : colors.accent[20],
          backgroundColor: checked ? colors.accent[20] : null,
        }
      }

      if (hovered) {
        return {
          backgroundColor: checked ? colors.accent[80] : null,
          borderColor: checked ? colors.accent[80] : error ? colors.error : colors.grey[40],
        }
      }

      return {
        backgroundColor: checked ? colors.accent[90] : null,
        borderColor: checked ? colors.accent[90] : error ? colors.error : colors.grey[30],
      }
    }

    return {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 'fit-content',
      color: disabled ? colors.grey[40] : null,
      pointerEvents: disabled ? 'none' : null,
      userSelect: 'none',
      cursor: 'pointer',
      [selectors.root]: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        width: 14,
        height: 14,
        color: colors.white,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: spaces.x1,
        ...getStateStyles(),
      },
      [`&:hover ${selectors.root}`]: {
        ...getStateStyles(true),
      },
      [selectors.label]: {
        marginLeft: spaces.x4,
        ...typography.p1compact,
        '& a': {
          color: disabled ? colors.grey[40] : null,
        },
      },
      [selectors.input]: {
        position: 'absolute',
        visibility: 'hidden',
        opacity: 0,
        top: 0,
        left: 0,
        width: 1,
        height: 1,
      },
    }
  }
)
