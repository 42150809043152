import React, {FC} from 'react'
import {BodyCellInnerAutocompleteProps, AutocompleteDefaultOption} from './types'
import BodyCellInnerAutocompleteView from './View'
import BodyCellInnerAutocompleteEdit from './Edit'
import {defaultCellDensity} from '../../helpers'

export const BodyCellInnerAutocomplete: FC<BodyCellInnerAutocompleteProps> = (
  props: BodyCellInnerAutocompleteProps
) => {
  const {
    density = defaultCellDensity,
    noWrap,
    rootRef,
    editable,
    editing,
    onStartEdit,
    children: selected = AutocompleteDefaultOption,
    onCancel,
    onChange,
    rowId,
    getOptionsHandleRequest,
    renderValue,
    ListboxComponent,
    inputPlaceholder = '',
    filterOptions,
    renderInputSelectedValue,
    customRenderSelected,
    customOptionLabel,
    renderOption,
  } = props

  return (
    <>
      <BodyCellInnerAutocompleteView
        density={density}
        editable={editable}
        noWrap={noWrap}
        renderValue={renderValue}
        onStartEdit={onStartEdit}
      >
        {selected}
      </BodyCellInnerAutocompleteView>
      {editing && (
        <BodyCellInnerAutocompleteEdit
          customOptionLabel={customOptionLabel}
          customRenderSelected={customRenderSelected}
          density={density}
          filterOptions={filterOptions}
          getOptionsHandleRequest={getOptionsHandleRequest}
          inputPlaceholder={inputPlaceholder}
          ListboxComponent={ListboxComponent}
          noWrap={noWrap}
          renderInputSelectedValue={renderInputSelectedValue}
          renderOption={renderOption}
          rootRef={rootRef}
          rowId={rowId}
          onCancel={onCancel}
          onChange={onChange}
        >
          {selected}
        </BodyCellInnerAutocompleteEdit>
      )}
    </>
  )
}

export default BodyCellInnerAutocomplete
