import React, {FC, useRef, useState, useMemo, MouseEvent} from 'react'
import {sizeTokenValues} from '@x5-react-uikit/tokens'
import {ArrowDown as ArrowDownIcon, ArrowUp as ArrowUpIcon} from '@x5-react-uikit/icons'
import {PrivateDropdown} from '../PrivateDropdown'
import {SelectList} from '../Select/SelectList'
import {SelectListPropsOnChange} from '../Select/types'
import {Button} from '../Button/Button'
import {ButtonDropDownProps} from './types'
import {getQaAttribute} from '../utils'

export const ButtonDropDown: FC<ButtonDropDownProps> = ({
  caption,
  options,
  qa = 'btnDropdown',
  placement,
  onClick,
  onOptionClick,
  ...props
}) => {
  const getQA = getQaAttribute(qa)
  const buttonRef = useRef(null)
  const [open, setOpen] = useState(false)

  const buttonIcon = useMemo(
    () =>
      open ? (
        <ArrowUpIcon data-qa={getQA('adornment')} size={sizeTokenValues.small} />
      ) : (
        <ArrowDownIcon data-qa={getQA('adornment')} size={sizeTokenValues.small} />
      ),
    [getQA, open]
  )

  const handleClick = (event: MouseEvent) => {
    setOpen(!open)
    onClick?.(event)
  }
  const handleOptionChange: SelectListPropsOnChange = (event, newValue, item) => {
    setOpen(false)
    onOptionClick?.(event, newValue, item)
  }

  return (
    <>
      <Button
        ref={buttonRef}
        data-qa={getQA('', props.disabled && 'disabled')}
        endIcon={buttonIcon}
        onClick={handleClick}
        {...props}
      >
        <span>{caption}</span>
      </Button>
      <PrivateDropdown
        anchor={buttonRef.current}
        open={open}
        placement={placement}
        qa={getQA('select')}
        onClickAway={() => setOpen(false)}
      >
        <SelectList
          items={options}
          qa={getQA('selectlist')}
          selected={[]}
          onChange={handleOptionChange}
        />
      </PrivateDropdown>
    </>
  )
}

export default ButtonDropDown
