import {FilterCollectorCondition, FilterCollectorFilter} from './types'

export const contentHeight = 220
export const subItemWidth = '100%'
export const containerMaxWidth = 640
export const temporaryColumnNamePrefix = '__column__'

export const conditionColumns = [
  {name: 'Равно', value: FilterCollectorCondition.equal},
  {name: 'Содержит', value: FilterCollectorCondition.in},
  {name: 'Не содержит', value: FilterCollectorCondition.notIn},
]

export const updateFilters = (
  filters: FilterCollectorFilter[],
  name: FilterCollectorFilter['name'],
  filter: FilterCollectorFilter
): FilterCollectorFilter[] => {
  const position = filters.findIndex((item) => item.name === name)
  const newFilters = [...filters]

  newFilters[position] = filter
  return newFilters
}

export const excludeEmptyFilters = (filters: FilterCollectorFilter[]): FilterCollectorFilter[] =>
  filters.filter((item: FilterCollectorFilter) => !item.name.includes(temporaryColumnNamePrefix))
