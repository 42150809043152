import React, {FC} from 'react'
import clsx from 'clsx'
import {Typography} from '../../Typography'
import {Tooltip} from '../../Tooltip'
import {defaultCellDensity} from '../helpers'
import {TableCellDensity, TableCellLabelProps} from '../types'
import {useCellLabelStyles} from './styles'

export const TableCellLabel: FC<TableCellLabelProps> = (
  props: TableCellLabelProps
): JSX.Element => {
  const {
    density = defaultCellDensity,
    noWrap,
    description,
    limitHeight,
    children,
    bodyCell,
    left = true,
  } = props

  const classes = useCellLabelStyles()

  let variant
  if (bodyCell) {
    variant = density === TableCellDensity.l ? 'p1' : 'p2'
  } else {
    variant = density === TableCellDensity.l ? 'h4' : 'h5'
  }

  const labelNode = (
    <Typography
      className={clsx(
        classes.label,
        left && classes.left,
        noWrap && classes.labelNoWrap,
        limitHeight && classes.limitHeight
      )}
      data-type="table-cell-label"
      variant={variant}
    >
      {children}
    </Typography>
  )

  if (description && description !== children) {
    return (
      <Tooltip className={classes.labelTooltip} content={description}>
        {labelNode}
      </Tooltip>
    )
  }

  return labelNode
}

export default TableCellLabel
