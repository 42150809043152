import {makeUiStyles} from '../../../theme'

export const useCellEditIconStyles = makeUiStyles(
  (theme) => ({
    root: {
      position: 'absolute',
      top: theme.spaces.x3,
      bottom: 0,
      right: theme.spaces.x2,
      left: 'auto',
      lineHeight: 0,
      padding: 'var(--density)',
    },
    left: {
      left: theme.spaces.x2,
      right: 'auto',
    },
    icon: {
      lineHeight: 0,
      color: theme.colors.grey[60],
      '&:before': {
        content: '""',
        position: 'absolute',
        width: theme.spaces.x12,
        height: theme.spaces.x12,
        transform: 'translate(-4px, -4px)',
        cursor: 'pointer',
        borderRadius: '50%',
        //background: 'rgb(255 255 255 / 50%)',
      },
      '& svg': {
        position: 'relative',
        cursor: 'pointer',
      },
    },
  }),
  'table-base-cell-edit-icon'
)

export default useCellEditIconStyles
