import {MoreVertical} from '@x5-react-uikit/icons'
import {FC, useCallback, useContext, useMemo, useRef} from 'react'
import {tableContext} from './context'
import {System} from '@root/features/systems/types'
import {Dropdown, SelectList, DropdownInnerRefMethods} from '@root/components/kit'
import {ActionButton} from '@root/components/ActionButton'
import {useGetUserinfoQuery} from '@root/redux/api/userApi'

const SystemActionsButton: FC<{system: System}> = ({system}) => {
  const ctx = useContext(tableContext)
  if (ctx == null) {
    throw new Error('Context was null!')
  }

  const {data: userInfo} = useGetUserinfoQuery()

  const dropdownRef = useRef<DropdownInnerRefMethods>()

  const genericAction = useCallback(
    (action: () => void) => () => {
      ctx.setCurrentSystem(system)
      action()
    },
    [ctx, system]
  )

  const actions = useMemo(() => {
    return {
      downloadXLS: {
        name: 'Выгрузить в xls',
        value: 'downloadXLS',
        onClick: ctx.downloadXLS,
      },
      changeSystemTag: {
        name: 'Изменить тег ИС',
        value: 'changeSystemTag',
        onClick: genericAction(() => ctx.setShowTagModal(true)),
      },
      ...(userInfo?.isAdmin
        ? {
            changeLTStatus: {
              name: 'Изменить статус НТ',
              value: 'changeLTStatus',
              onClick: genericAction(() => ctx.setShowStatusModal(true)),
            },
          }
        : {}),
      openComment: {
        name: 'Открыть комментарий',
        value: 'openComment',
        onClick: genericAction(() => ctx.setShowCommentModal(true)),
      },
    }
  }, [ctx, genericAction, userInfo?.isAdmin])

  return (
    <Dropdown
      disableContentVisual
      action={
        <ActionButton>
          <MoreVertical size="small" />
        </ActionButton>
      }
      className="w-100"
      innerRef={dropdownRef}
      placement="bottom-end"
    >
      <SelectList
        items={Object.values(actions)}
        selected={[]}
        onChange={(_, actionName) => {
          const action = actions[actionName as keyof typeof actions]
          action.onClick?.()
          dropdownRef?.current?.close()
        }}
      />
    </Dropdown>
  )
}

export default SystemActionsButton
