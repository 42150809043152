import {TableBodyCellType} from '../types'
import {BodyCellInnerDate} from './Date/BodyCellInnerDate'
import {BodyCellInnerCheck} from './Check/BodyCellInnerCheck'
import {BodyCellInnerSelect} from './Select/BodyCellInnerSelect'
import {BodyCellInnerAutocomplete} from './Autocomplete/BodyCellInnerAutocomplete'
import {BodyCellInnerNumber} from './Number/BodyCellInnerNumber'
import {BodyCellInnerText} from './Text/BodyCellInnerText'
import {BodyCellInner} from './Default/BodyCellInner'

export const getInnerComponentByType = (type: TableBodyCellType) => {
  switch (type) {
    case TableBodyCellType.date:
      return BodyCellInnerDate

    case TableBodyCellType.check:
      return BodyCellInnerCheck

    case TableBodyCellType.select:
      return BodyCellInnerSelect

    case TableBodyCellType.autocomplete:
      return BodyCellInnerAutocomplete

    case TableBodyCellType.number:
      return BodyCellInnerNumber

    case TableBodyCellType.text:
      return BodyCellInnerText

    default:
      return BodyCellInner
  }
}

export default getInnerComponentByType
