import styled from '@emotion/styled'
import {FC} from 'react'

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const EmptyCell: FC = () => <Wrap>-</Wrap>
