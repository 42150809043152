import {RequiredRule} from '@root/constants'
import {FILES_REGISTER} from '@root/features/reports/constants'
import FormInputFile from '@root/pages/tasks/CreateTaskPage/components/FormInputFile'
import {IconButton} from '@x5-react-uikit/core'
import {Del as DelIcon} from '@x5-react-uikit/icons'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'

const StatisticsFileField = ({form, index, onRemove}) => {
  const registerName = `${FILES_REGISTER}.${index}.name`
  const registerFile = `${FILES_REGISTER}.${index}.files`
  const registerComment = `${FILES_REGISTER}.${index}.comment`

  return (
    <FlexboxColumn sx={{gap: '16px'}}>
      <FlexboxRow sx={{gap: '24px', alignItems: 'top', justifyContent: 'space-between'}}>
        <FormInputText
          required
          control={form.control}
          label="Название изображения"
          name={registerName}
          rules={RequiredRule}
          width="520px"
        />
        <IconButton
          disabled={!onRemove}
          IconComponent={<DelIcon />}
          variant="text"
          onClick={onRemove}
        />
      </FlexboxRow>
      <FormInputFile
        // label={}
        accept={['jpg', 'jpeg', 'png']}
        control={form.control}
        label="sd"
        maxFilesCount={1}
        name={registerFile}
        width="520px"
      />
      <FormInputText
        textarea
        control={form.control}
        label="Комментарий"
        name={registerComment}
        width="520px"
      />
    </FlexboxColumn>
  )
}

export default StatisticsFileField
