import {FC, ReactNode} from 'react'
import {ReportWithStatisticsImages} from '../types'
import {View} from '@react-pdf/renderer'
import {Accordion} from '@root/pdf-components/Accordion'

type ReportSwitchProps = {
  reports: ReportWithStatisticsImages[]
  children: (report: ReportWithStatisticsImages) => ReactNode
}

export const ReportAccordion: FC<ReportSwitchProps> = ({reports, children}) => {
  return (
    <View style={{gap: '24px'}}>
      {reports.map((x, i) => (
        <Accordion key={x.id} text={`Отчет №${i + 1}`}>
          {children(x)}
        </Accordion>
      ))}
    </View>
  )
}
