import React, {FC} from 'react'
import {defineColors} from '../utils/defineColors'
import {LogoProps} from '../types'

const primary = '#5faf2d'
const getPathColor = defineColors('#000000')

const Logo: FC<LogoProps> = ({color, ...props}) => (
  <svg fill="none" viewBox="0 0 520 100" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M151.964 86.3955V39.8887H169.78C183.94 39.8887 187.795 46.5986 187.795 53.8403C187.795 60.4845 183.142 65.5995 176.428 66.9959L191.318 86.397H183.94L170.113 68.1921H158.014V86.397H151.964V86.3955ZM158.013 45.5353V62.544H169.979C176.958 62.544 181.479 59.1554 181.479 53.8403C181.479 49.0568 178.487 45.5353 169.58 45.5353H158.013Z"
      fill={getPathColor(color)}
    />
    <path
      d="M193.712 69.0556C193.712 58.7569 201.357 51.0508 211.661 51.0508C221.898 51.0508 228.945 57.9609 228.945 68.0594C228.945 69.0556 228.879 70.1861 228.812 71.0493H199.429C199.762 77.2276 204.88 81.7452 211.661 81.7452C216.713 81.7452 219.903 79.9516 222.563 75.6998L227.615 77.5606C224.822 83.6732 219.306 86.9946 211.661 86.9946C201.092 86.9946 193.712 79.6201 193.712 69.0556ZM199.695 65.7999H223.228C223.029 60.5505 217.91 56.2987 211.661 56.2987C205.412 56.2987 200.426 60.2189 199.695 65.7999Z"
      fill={getPathColor(color)}
    />
    <path
      d="M234.463 60.684C236.657 54.6386 242.175 51.25 249.619 51.25C258.993 51.25 263.713 56.1664 263.713 65.9991V86.3949H258.129V80.4152C256.335 84.1353 251.017 86.9922 245.433 86.9922C238.253 86.9922 233.401 82.8734 233.401 76.6936C233.401 68.7873 240.248 65.7975 258.131 65.7975C258.131 59.4191 255.405 56.4964 249.489 56.4964C244.304 56.4964 240.914 58.5559 239.518 62.5418L234.463 60.684ZM258.128 71.6458V70.3838C243.902 70.3838 239.248 71.9788 239.248 76.6951C239.248 79.8178 242.041 81.7443 246.561 81.7443C253.342 81.7443 258.128 77.5583 258.128 71.6458Z"
      fill={getPathColor(color)}
    />
    <path
      d="M270.361 69.1878C270.361 58.6233 278.205 51.2488 287.844 51.2488C294.226 51.2488 298.081 53.4411 300.142 56.8969V37.8945H305.726V86.3951H300.142V80.0182C297.815 84.27 294.026 86.994 287.046 86.994C277.673 86.994 270.361 80.2825 270.361 69.1878ZM300.142 69.4537V68.1917C300.142 60.8172 295.223 56.4982 288.507 56.4982C281.86 56.4982 276.21 61.4802 276.21 69.0549C276.21 76.9611 281.196 81.7446 288.176 81.7446C295.489 81.7446 300.142 77.1597 300.142 69.4537Z"
      fill={getPathColor(color)}
    />
    <path
      d="M326.136 85.1332L310.713 51.8477H317.294L328.994 77.7586L338.699 51.8477H344.815L326.402 99.6837H320.286L326.136 85.1332Z"
      fill={getPathColor(color)}
    />
    <path
      d="M364.426 39.8887H397.863V45.5368H370.475V61.4822H396.001V67.1303H370.475V86.3955H364.426V39.8887Z"
      fill={getPathColor(color)}
    />
    <path
      d="M419.669 51.248C430.438 51.248 438.017 58.7555 438.017 69.0542C438.017 79.4858 430.505 86.9933 419.603 86.9933C408.967 86.9933 401.322 79.4858 401.322 69.0542C401.321 58.7555 408.767 51.248 419.669 51.248ZM419.603 81.7439C427.047 81.7439 432.167 76.5617 432.167 69.0542C432.167 61.6139 426.783 56.4974 419.603 56.4974C412.423 56.4974 407.172 61.5467 407.172 69.0542C407.171 76.4959 412.29 81.7439 419.603 81.7439Z"
      fill={getPathColor(color)}
    />
    <path
      d="M461.284 51.248C472.053 51.248 479.631 58.7555 479.631 69.0542C479.631 79.4858 472.12 86.9933 461.218 86.9933C450.582 86.9933 442.937 79.4858 442.937 69.0542C442.936 58.7555 450.381 51.248 461.284 51.248ZM461.216 81.7439C468.661 81.7439 473.78 76.5617 473.78 69.0542C473.78 61.6139 468.396 56.4974 461.216 56.4974C454.036 56.4974 448.786 61.5467 448.786 69.0542C448.786 76.4959 453.903 81.7439 461.216 81.7439Z"
      fill={getPathColor(color)}
    />
    <path
      d="M484.55 69.1878C484.55 58.6233 492.395 51.2488 502.033 51.2488C508.415 51.2488 512.27 53.4411 514.331 56.8969V37.8945H519.915V86.3951H514.331V80.0182C512.004 84.27 508.215 86.994 501.235 86.994C491.862 86.994 484.55 80.2825 484.55 69.1878ZM514.331 69.4537V68.1917C514.331 60.8172 509.412 56.4982 502.697 56.4982C496.049 56.4982 490.399 61.4802 490.399 69.0549C490.399 76.9611 495.385 81.7446 502.365 81.7446C509.678 81.7446 514.331 77.1597 514.331 69.4537Z"
      fill={getPathColor(color)}
    />
    <path
      d="M81.797 86.3961L72.1576 70.3836L62.7169 86.3961H51.4144L65.934 62.2772L51.1349 39.8848H62.9425L72.4221 54.8355L81.4638 39.8848H92.7663L78.2063 63.0075L93.1653 86.3961H81.797Z"
      fill={getPathColor(color)}
    />
    <path
      d="M129.901 70.5846C129.901 80.8176 122.19 87.196 112.084 87.196C103.175 87.196 95.677 82.2796 94.9448 71.5822H104.584C105.249 76.2328 108.971 78.2265 113.027 78.2265C118.079 78.2265 120.26 74.8379 120.26 70.4532C120.26 65.8684 116.87 63.0114 112.15 63.0114C108.227 63.0114 105.635 64.8722 104.438 66.8645H95.2242L98.4085 39.8887H128.715L127.705 48.9254H106.025L105.164 57.8516C105.164 57.8516 107.441 55.1709 114.342 55.1709C122.656 55.1694 129.901 60.7474 129.901 70.5846Z"
      fill={getPathColor(color)}
    />
    <path
      d="M96.0865 34.0934C90.9507 14.0874 74.6589 0.03125 40.8514 0.03125H0.0314331L5.57212 37.479C9.74109 71.2693 22.6709 82.14 45.5538 86.1423C45.6599 86.1588 46.3847 86.2618 46.3951 85.4151C46.3981 85.1089 46.2053 84.8475 45.9394 84.734C30.4723 78.2257 28.7076 56.8816 25.4038 35.4628L22.8472 18.393H46.1486C67.6314 18.393 84.2191 18.0539 94.7192 34.628C94.8761 34.861 95.1182 35.0327 95.4066 35.0327C95.8115 35.0327 96.1149 34.7042 96.1149 34.298C96.1164 34.2263 96.1059 34.1576 96.0865 34.0934Z"
      fill={getPathColor(color, primary)}
    />
  </svg>
)

export default Logo
