import {TaskStatuses} from '@root/constants'
import {FilterItemType} from '@root/openapi'

export const SEARCH_DEBOUNCE_MS = 1000

export const RoleTabs = {
  all: {value: 1, label: 'Все заявки'},
  creator: {value: 2, label: 'Я инициатор'},
  stakeholders: {value: 3, label: 'Я наблюдатель'},
  mine: {value: 4, label: 'Мои'},
  notAssigned: {value: 5, label: 'Не назначенные'},
}
export const OnlyActiveTabStatus = {
  value: [TaskStatuses.DRAFT, TaskStatuses.WITHDRAWN],
  type: FilterItemType.NOT_EQUAL,
}
