import styled from '@emotion/styled'

export const LineLimiter = styled.span<{lines: number}>`
  -webkit-line-clamp: ${({lines}) => lines};
  line-clamp: ${({lines}) => lines};
  word-wrap: break-word;
  display: inline-block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export default LineLimiter
