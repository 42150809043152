import {placements} from '@x5-react-uikit/tokens'
import {ReactElement, ReactNode} from 'react'
import {QA} from '../types'

export const tooltipPlacements = placements
export type TooltipPlacement = (typeof placements)[keyof typeof placements]

export type TooltipContentWidth = 'auto' | 'pre' | number

export interface TooltipProps extends QA {
  zIndex?: number
  placement?: TooltipPlacement
  content?: ReactNode
  className?: string
  children?: ReactElement
  contentWidth?: TooltipContentWidth
  interactive?: boolean
  usePortal?: boolean
  doNotClone?: boolean
}
