import React, {ComponentProps, forwardRef} from 'react'
import {PREFIX, restyled, buttonModes, buttonVariants, buttonSizes} from '@x5-react-uikit/core'
import {sizeTokenValues} from '@x5-react-uikit/tokens'

// import { buttonModes, buttonVariants, buttonSizes, StyledButtonProps } from './types'

export const buttonClasses = {
  content: `${PREFIX}-button-content`,
  loader: `${PREFIX}-button-loader`,
}

export const doSelectors = (
  classes: typeof buttonClasses
): Record<keyof typeof buttonClasses, string> => {
  const selectors = {}
  Object.entries(classes).forEach(([key, value]) => (selectors[key] = '.' + value))
  return selectors as Record<keyof typeof buttonClasses, string>
}

const selectors = doSelectors(buttonClasses)

export const largeButtonSize = 48
export const mediumButtonSize = 40
export const smallButtonSize = 32

export const StyledButtonBase = restyled.button(({theme}) => ({
  margin: 0,
  padding: 0,
  border: 'none',
  width: 'auto',
  overflow: 'visible',
  backgroundColor: 'transparent',
  color: 'inherit',
  font: 'inherit',
  lineHeight: 'inherit',
  ...theme.typography.base,
}))

const borderWidth = 1

type StyledButtonProps = {
  hasLoader: boolean
  size: sizeTokenValues
  variant: buttonVariants
  equated: boolean
  mode: buttonModes
  startIcon: boolean
  endIcon: boolean
}

// TODO: NOT IN USE
export const StyledButton = restyled(StyledButtonBase)<StyledButtonProps>(({
  theme: {spaces, colors, typography},
  hasLoader,
  size,
  variant,
  equated,
  mode,
  startIcon,
  endIcon,
}) => {
  const isTextVariant = variant === buttonVariants.text
  const cut = (spacing) => spacing - borderWidth
  const getPaddingByCondition = (defaultSpace, iconSpace, sideSpace) => ({
    padding: cut(defaultSpace),
    ...(!equated
      ? {
          paddingLeft: startIcon ? cut(iconSpace) : cut(sideSpace),
          paddingRight: endIcon ? cut(iconSpace) : cut(sideSpace),
        }
      : null),
  })
  const getOutlinedPadding = () => {
    if (size === buttonSizes.small) return getPaddingByCondition(spaces.x2, spaces.x4, spaces.x6)

    if (size === buttonSizes.large) return getPaddingByCondition(spaces.x6, spaces.x8, spaces.x10)

    return getPaddingByCondition(spaces.x4, spaces.x6, spaces.x8)
  }

  return {
    position: 'relative',
    boxSizing: 'border-box',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: isTextVariant || equated ? spaces.x4 : spaces.join('x4', 'x8'),
    paddingLeft: startIcon ? (isTextVariant ? spaces.x4 : spaces.x6) : null,
    paddingRight: endIcon ? (isTextVariant ? spaces.x4 : spaces.x6) : null,
    height: mediumButtonSize,
    minWidth: mediumButtonSize,
    backgroundColor: colors.grey[30],
    color: colors.grey[100],
    ...typography.p1,
    textDecoration: 'none',
    pointerEvents: hasLoader ? 'none' : null,
    borderRadius: spaces.x2,
    cursor: 'pointer',
    ':hover': {
      backgroundColor: colors.grey[20],
    },
    ':active': {
      backgroundColor: colors.grey[40],
    },
    '&[disabled], &[disabled]:hover': {
      backgroundColor: colors.grey[20],
      color: colors.grey[40],
      pointerEvents: 'none',
      cursor: 'default',
    },
    ...(variant === buttonVariants.primary
      ? {
          backgroundColor: colors.accent[90],
          color: colors.white,
          ':hover': {
            backgroundColor: colors.accent[80],
          },
          ':active': {
            backgroundColor: colors.accent[100],
          },
          '&[disabled]': {
            backgroundColor: colors.grey[20],
            color: colors.grey[40],
          },
        }
      : null),
    ...(isTextVariant
      ? {
          padding: spaces.x4,
          backgroundColor: 'transparent',
          color: mode === buttonModes.dark ? colors.accent[30] : colors.accent[90],
          ':hover': {
            backgroundColor: mode === buttonModes.dark ? colors.grey[80] : colors.accent[20],
          },
          ':active': {
            backgroundColor: mode === buttonModes.dark ? colors.grey[60] : colors.accent[30],
          },
          '&[disabled]': {
            backgroundColor: 'transparent',
            color: mode === buttonModes.dark ? colors.grey[60] : colors.grey[40],
          },
        }
      : null),
    ...(size === buttonSizes.small
      ? {
          padding: isTextVariant || equated ? spaces.x2 : spaces.join('x2', 'x6'),
          height: smallButtonSize,
          minWidth: smallButtonSize,
          lineHeight: 1,
          paddingLeft: startIcon ? (isTextVariant ? spaces.x2 : spaces.x4) : null,
          paddingRight: endIcon ? (isTextVariant ? spaces.x2 : spaces.x4) : null,
        }
      : null),
    ...(size === buttonSizes.large
      ? {
          padding: equated
            ? spaces.x6
            : isTextVariant
              ? spaces.join('x6', 'x4')
              : spaces.join('x6', 'x10'),
          height: largeButtonSize,
          minWidth: largeButtonSize,
          paddingTop: isTextVariant ? spaces.x6 : null,
          paddingBottom: isTextVariant ? spaces.x6 : null,
          paddingLeft: startIcon ? (isTextVariant ? spaces.x4 : spaces.x8) : null,
          paddingRight: endIcon ? (isTextVariant ? spaces.x4 : spaces.x8) : null,
        }
      : null),
    ...(variant === buttonVariants.outlined
      ? {
          ...getOutlinedPadding(),
          borderWidth,
          borderStyle: 'solid',
          borderColor: colors.grey[30],
          backgroundColor: colors.white,
          color: colors.grey[100],
          ':hover': {
            borderColor: colors.grey[40],
            backgroundColor: colors.grey[20],
          },
          ':active': {
            borderColor: colors.grey[40],
            backgroundColor: colors.grey[40],
          },
          '&[disabled]': {
            borderColor: colors.grey[20],
            backgroundColor: colors.white,
            color: colors.grey[40],
          },
        }
      : null),
    [selectors.content]: {
      display: 'flex',
      alignItems: 'center',
      visibility: hasLoader ? 'hidden' : null,
      marginLeft: startIcon ? spaces.x3 : null,
      marginRight: endIcon ? spaces.x3 : null,
    },
    '& > svg': {
      visibility: hasLoader ? 'hidden' : null,
    },
    [selectors.loader]: {
      padding: spaces.x4,
      position: 'absolute',
      left: '50%',
      color: 'currentColor',
      transform: 'translateX(-50%)',
    },
  }
})

type ButtonBaseProps = ComponentProps<typeof StyledButtonBase>

export const ButtonBase = forwardRef<HTMLButtonElement, ButtonBaseProps>(
  ({children, type = 'button', ...props}, ref) => (
    <StyledButtonBase ref={ref} type={type} {...props}>
      {children}
    </StyledButtonBase>
  )
)

export default ButtonBase
