import {Box, Button, Typography, baseTheme} from '@x5-react-uikit/core'
import {Error as ErrorIcon} from '@x5-react-uikit/icons'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import Section from '@root/components/Section'
import StepTitle from '@root/components/StepTitle'
import TypographyError from '@root/components/typography/TypographyError'
import useNotify from '@root/hooks/useNotify'
import {get as getFromObject} from 'lodash'
import {useContext, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {
  useCreateReportConfigurationMutation,
  useUpdateReportConfigurationMutation,
} from '@root/redux/api/reportConfigurationsApi'
import CancelModal from './CancelModal'
import {GRAPHS_REGISTER} from './StatisticsStep/EditGraphDataField'
import {getFormDirtyValues} from '@root/components/inputs/formInputs/formUtils'
import {updateReportContext} from '../../ReportUpdatePage/context'
import {confUpdateContext} from '../context'

const {colors} = baseTheme

const SubmitButton = ({form, disabled}) => {
  const navigate = useNavigate()
  const {configurationId} = useParams()
  const {notifySuccess, notifyError} = useNotify()

  const [createConfiguration, {isLoading: isCreating}] = useCreateReportConfigurationMutation()
  const handleCreate = () => {
    const createData = form.getValues()
    createConfiguration({createData})
      .unwrap()
      .then((created) => {
        notifySuccess(
          'Конфигурация создана',
          'Она будет отображена в общем списке конфигураций и доступна ' +
            'в своей ИС при формировании отчета.'
        )
        navigate(`/reports/configurations/${created.id}`)
      })
      .catch((error) => notifyError())
  }

  const [updateConfiguration, {isLoading: isUpdating}] = useUpdateReportConfigurationMutation()
  const handleUpdate = () => {
    const updateData = getFormDirtyValues(form.formState.dirtyFields, form.getValues())
    updateConfiguration({configurationId, updateData})
      .unwrap()
      .then((created) => {
        notifySuccess(
          'Изменения сохранены',
          'Изменения внесены в конфигурацию и будут использоваться при формировании отчета.'
        )
        navigate(`/reports?tab=configurations`)
      })
      .catch((error) => notifyError())
  }

  return configurationId ? (
    <Button disabled={disabled} loading={isUpdating} onClick={handleUpdate}>
      Сохранить конфигурацию
    </Button>
  ) : (
    <Button disabled={disabled} loading={isCreating} onClick={handleCreate}>
      Создать конфигурацию
    </Button>
  )
}

const StepsSection = ({
  form,
  formTrigger,
  currentStep,
  setCurrentStep,
  completedSteps,
  setCompletedSteps,
  touchedSteps,
  setTouchedSteps = () => {},
}) => {
  const navigate = useNavigate()
  const {configurationId} = useParams()
  const [cancelModalShown, setCancelModalShown] = useState(false)

  const ctx = useContext(confUpdateContext)

  useEffect(() => {
    if (touchedSteps.includes(currentStep)) {
      formTrigger()
    }
  }, [formTrigger, currentStep, touchedSteps])

  const handleNavigateTo = (step) =>
    form.handleSubmit(
      (fields) => {
        ctx.focusedFieldRef.current = null
        if (!touchedSteps.includes(step)) setTouchedSteps((prev) => [...prev, step])
        if (!completedSteps.includes(currentStep))
          setCompletedSteps((prev) => [...prev, currentStep])
        setCurrentStep(step)
      },
      (error) => {
        let valid
        if (Number(step) === 1) {
          valid = ['name', 'systemdId', 'reportData.dynamicFields'].some((field) =>
            getFromObject(error, field)
          )
        } else if (Number(step) === 2) {
          valid = !!getFromObject(error, GRAPHS_REGISTER)
        }

        if (!valid && completedSteps.includes(currentStep)) {
          setCompletedSteps((prev) => prev.filter((step) => step !== currentStep))
        }

        if (!touchedSteps.includes(step)) setTouchedSteps((prev) => [...prev, step])
        setCurrentStep(step)
      }
    )

  return (
    <>
      <Section sx={{display: 'flex', flexDirection: 'column', gap: '40px'}}>
        <FlexboxColumn sx={{gap: '8px'}}>
          <FlexboxRow sx={{gap: '8px', alignItems: 'center'}}>
            <StepTitle
              completed={completedSteps.includes(1)}
              current={Number(currentStep) === 1}
              handleClick={handleNavigateTo(1)}
              number={1}
              title="Данные конфигурации"
            />
            <Box sx={{height: '1px', flex: '1 0 0', backgroundColor: 'grey.30'}} />
            <StepTitle
              completed={completedSteps.includes(2)}
              current={Number(currentStep) === 2}
              disabled={!touchedSteps.includes(2)}
              handleClick={handleNavigateTo(2)}
              number={2}
              title="Статистика"
            />
            <Box sx={{height: '1px', flex: '1 0 0', backgroundColor: 'grey.30'}} />
            <StepTitle
              completed={completedSteps.includes(1) && completedSteps.includes(2)}
              current={Number(currentStep) === 3}
              disabled={!touchedSteps.includes(2)}
              handleClick={handleNavigateTo(3)}
              number={3}
              showWarning={false}
              title="Итоговая сверка"
            />
          </FlexboxRow>
          <Typography style={{color: colors.grey[50]}} variant="p3">
            Вы можете вернуться к любому пройденному шагу на протяжении всего процесса создания
            конфигурации отчета, нажав на его название или по кнопке назад.
          </Typography>
        </FlexboxColumn>
        <FlexboxColumn sx={{gap: '16px'}}>
          {(!completedSteps.includes(1) || !completedSteps.includes(2)) && currentStep === 3 && (
            <FlexboxRow sx={{gap: '8px', alignItems: 'center', color: 'error'}}>
              <ErrorIcon />
              <TypographyError variant="p2">
                Вы не заполнили обязательные поля. Шаг, в котором есть незаполненные пункты, а также
                пункты снизу отмечены.
              </TypographyError>
            </FlexboxRow>
          )}
          <FlexboxRow sx={{justifyContent: 'space-between'}}>
            <FlexboxRow sx={{gap: '16px'}}>
              <Button variant="outlined" onClick={() => setCancelModalShown(true)}>
                Отменить {configurationId ? 'редактирование' : 'создание'}
              </Button>
            </FlexboxRow>
            <FlexboxRow sx={{gap: '16px'}}>
              <Button
                disabled={Number(currentStep) === 1}
                onClick={handleNavigateTo(Number(currentStep) - 1)}
              >
                Предыдущий шаг
              </Button>
              {Number(currentStep) === 3 ? (
                <SubmitButton
                  disabled={!completedSteps.includes(1) || !completedSteps.includes(2)}
                  form={form}
                />
              ) : (
                <Button
                  disabled={Number(currentStep) === 3}
                  onClick={handleNavigateTo(Number(currentStep) + 1)}
                >
                  Следующий шаг
                </Button>
              )}
            </FlexboxRow>
          </FlexboxRow>
        </FlexboxColumn>
      </Section>
      <CancelModal
        isOpen={cancelModalShown}
        onClose={() => setCancelModalShown(false)}
        onSubmit={() => navigate('/reports')}
      />
    </>
  )
}

export default StepsSection
