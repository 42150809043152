export enum taskRoleTabKind {
  all,
  mine,
  notAssigned,
}

export enum taskStatusTabKind {
  active,
  draft,
  withdrawn,
}
