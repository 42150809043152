import {Typography} from '@x5-react-uikit/core'
import Divider from '@root/components/Divider'
import FlexboxColumn from '@root/components/FlexboxColumn'
import {useParams} from 'react-router-dom'
import {useGetReportByIdQuery} from '@root/redux/api/launchesApi'
import ReportImage from './ReportImage'

const ReportStatisticsTab = () => {
  const {reportId} = useParams()
  const {data: report} = useGetReportByIdQuery(reportId)

  return (
    <FlexboxColumn sx={{mt: 'x12', gap: '40px'}}>
      {report?.statistics.graphs?.map((stat, statIndex) => {
        const last = statIndex === report.statistics.graphs.length - 1
        return (
          <>
            <FlexboxColumn sx={{gap: '16px'}}>
              <Typography variant="h3">{stat.graphName}</Typography>
              <iframe src={stat.graphUrl} style={{height: '400px'}} title={stat.graphName}></iframe>
              <FlexboxColumn sx={{gap: '24px'}}>
                <Typography variant="p1">Комментарий:</Typography>
                <Typography variant="p1">{stat.comment}</Typography>
              </FlexboxColumn>
            </FlexboxColumn>
            {!last && <Divider />}
          </>
        )
      })}
      {report?.statistics.fileFields?.map((fileField, fieldIndex) => {
        const last = fieldIndex === report.statistics.fileFields.length - 1
        return (
          <>
            <ReportImage fileField={fileField} />
            {!last && <Divider />}
          </>
        )
      })}
    </FlexboxColumn>
  )
}

export default ReportStatisticsTab
