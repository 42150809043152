import {Box, Button, Typography, baseTheme} from '@x5-react-uikit/core'
import {Add, Del} from '@x5-react-uikit/icons'
import Accordion from '@root/components/Accordion'
import FlexboxRow from '@root/components/FlexboxRow'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'
import {useState} from 'react'
import {createEmptyListElement} from '../utils/formUtils'

const {colors} = baseTheme

const ListContent = ({listRegister, listMeta, form, readonly}) => {
  // WARNING: Предполагается, что в списке присутствуют ТОЛЬКО text-ИНПУТЫ

  // form.watch()

  // FIXME: костыльный способ вызвать ререндер.
  // Сеттер вызывается при изменении формы (добавление/удаление строки).
  // Альтернативный (и правильный) способ -- использовать form.watch,
  // но в этом случае происходит ререндер родительских компонент, что ломает интерфейес.
  const [key, setKey] = useState(Math.random())

  const [expandedId, setExpandedId] = useState(1)
  const isExpanded = (accId) => {
    const invalidLists = Object.keys(form.getFieldState(listRegister).error || {}).map(
      (index) => Number(index) + 1
    )
    return Number(expandedId) === Number(accId) || invalidLists.includes(accId)
  }
  const handleExpand = (accId) => setExpandedId(isExpanded(accId) ? null : accId)

  const handleAddElement = () => {
    const listElements = form.getValues(listRegister)
    const newElementNumber = listElements.length + 1
    const newElement = createEmptyListElement(newElementNumber, listMeta)
    form.setValue(listRegister, [...listElements, newElement], {shouldDirty: true})
    setKey(Math.random())
  }
  const handleRemoveElement = (elementNumber) => {
    form.setValue(
      listRegister,
      form.getValues(listRegister).filter((element) => element.number !== elementNumber),
      {shouldDirty: true}
    )
    setKey(Math.random())
  }

  return (
    <Box key={key}>
      <>
        {form.getValues(listRegister).map((listObj, listObjIndex) => {
          const elementRegister = `${listRegister}.${listObjIndex}`
          return (
            <Accordion
              expanded={isExpanded(listObj.number)}
              headerSx={{
                gap: '4px',
                py: 'x4',
                px: 'x12',
                alignItems: 'center',
                backgroundColor: 'accent.10',
                minHeight: '32px',
                borderBottom: `1px solid ${colors.accent[20]}`,
              }}
              titleEl={
                <FlexboxRow
                  sx={{width: '100%', alignItems: 'center', justifyContent: 'space-between'}}
                >
                  <Typography style={{cursor: 'pointer', userSelect: 'none'}} variant="h4">
                    {`${listMeta.elementLabel}${listObj.number}`}
                  </Typography>
                  {form.getValues(listRegister).length > 1 && (
                    <Button
                      size="small"
                      startIcon={<Del size="small" />}
                      variant="text"
                      onClick={() => handleRemoveElement(listObj.number)}
                    >
                      Удалить
                    </Button>
                  )}
                </FlexboxRow>
              }
              onClick={() => handleExpand(listObj.number)}
            >
              <FlexboxRow sx={{flexWrap: 'wrap', boxSizing: 'border-box', px: 'x12', mb: 'x12'}}>
                {listMeta.elements.map((elementMeta) => {
                  const elementValueIndex = listObj.values.text.findIndex(
                    (elementObj) => elementObj.id === elementMeta.id
                  )
                  const inputName = `${elementRegister}.values.text.${elementValueIndex}.value`
                  return (
                    <Box sx={{flex: '0 0 50%', boxSizing: 'border-box', pt: 'x12'}}>
                      {readonly ? (
                        <>
                          <FlexboxRow>
                            <Typography>Вопрос:&nbsp;</Typography>
                            <Typography>{elementMeta.placeholder}</Typography>
                          </FlexboxRow>
                          <FlexboxRow>
                            <Typography>Ответ:&nbsp;</Typography>
                            <Typography>{listObj.values.text[elementValueIndex].value}</Typography>
                          </FlexboxRow>
                        </>
                      ) : (
                        <FormInputText
                          key={inputName}
                          control={form.control}
                          label={elementMeta.placeholder}
                          name={inputName}
                          width="520px"
                        />
                      )}
                    </Box>
                  )
                })}
              </FlexboxRow>
            </Accordion>
          )
        })}
      </>
      {!readonly && (
        <Button
          startIcon={<Add />}
          style={{marginTop: '24px'}}
          variant="text"
          onClick={handleAddElement}
        >
          {listMeta.addButtonLabel || 'Добавить'}
        </Button>
      )}
    </Box>
  )
}

export default ListContent
