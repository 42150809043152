import {FC} from 'react'
import {PDFViewer} from '@react-pdf/renderer'
import {PdfReport} from '@root/features/reports/PdfReport'
import {useParams} from 'react-router-dom'
import {useGetLaunchByIdQuery, useGetReportByIdQuery} from '@root/redux/api/launchesApi'
import {useGetTaskByIdQuery} from '@root/redux/api/tasksApi'

export const ReportPdfPreviewPage: FC = () => {
  const {reportId} = useParams()

  const {data: report, isLoading: reportLoading} = useGetReportByIdQuery(reportId)

  const {data: launch} = useGetLaunchByIdQuery(
    {
      systemId: report?.system.systemId,
      launchId: report?.launchId,
      taskId: report?.taskId,
    },
    {
      skip: reportLoading,
    }
  )

  const {data: task} = useGetTaskByIdQuery(report?.taskId, {
    skip: reportLoading,
  })

  if (!report || !task || !launch) {
    return <>"Loading"</>
  }

  console.log('launch', launch)

  return (
    <PDFViewer width="100%">
      <PdfReport launch={launch} report={report} />
    </PDFViewer>
  )
}
