import {Modal, Typography} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import FormInputDatetime from '@root/components/inputs/formInputs/FormInputDatetime'
import FormInputSelect from '@root/components/inputs/formInputs/FormInputSelect'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'
import useNotify from '@root/hooks/useNotify'
import {useForm} from 'react-hook-form'
import {useUpdateSystemMutation} from '@root/redux/api/systemsApi'
import {SystemStatusesMap, SystemSubstatusesMap} from '@root/features/systems/constants'
import {MODAL_INPUT_WIDTH_PX} from '@root/constants'
import FormInputCheckbox from '@root/components/inputs/formInputs/FormInputCheckbox'
import {RequiredRule} from '@root/constants'
import {FC, useEffect} from 'react'
import {getFormDirtyValues} from '@root/components/inputs/formInputs/formUtils'
import {getTagOrName} from '@root/features/systems/utils'
import type {System} from '@root/features/systems/types'

const SystemStatusOptions = Object.keys(SystemStatusesMap)
  .filter((statusName) => statusName !== SystemStatusesMap.IN_DEVELOPMENT.name)
  .map((statusName) => {
    const status = SystemStatusesMap[statusName]
    return {
      value: statusName,
      name: status.label,
    }
  })

const SystemSubstatusOptions = Object.keys(SystemSubstatusesMap)
  .filter((substatusName) => substatusName !== SystemSubstatusesMap.WITHOUT_COMMENTS.name)
  .map((substatusName) => {
    const substatus = SystemSubstatusesMap[substatusName]
    return {
      value: substatusName,
      name: substatus.label,
    }
  })

type Props = {
  isOpen: boolean
  system: System
  onCancel: () => void
}

type FormValues = System & {noSubstatus: boolean}

const SystemStatusChangeModal: FC<Props> = ({system, onCancel, isOpen}) => {
  const {notifySuccess, notifyError} = useNotify()
  const {
    watch: formWatch,
    reset,
    unregister: formUnregisterField,
    setValue: formSetValue,
    ...form
  } = useForm<FormValues>({values: {...system, noSubstatus: !system?.substatus}})

  const formValue = formWatch()

  useEffect(() => {
    if (isOpen) {
      reset({...system, noSubstatus: !system?.substatus})
    }
  }, [reset, isOpen, system])

  useEffect(() => {
    const onFormUpdate = (value, change) => {
      if (change.name === 'noSubstatus') {
        // formSetValue('noSubstatus', null);
        formUnregisterField('substatus')
      } else if (change.name === 'status' && value.status === SystemStatusesMap.MISSING.name) {
        formUnregisterField('expirationDate')
      }
    }
    const subscription = formWatch(onFormUpdate)
    return () => subscription.unsubscribe()
  }, [formWatch, formUnregisterField, formSetValue])

  const [updateSystem] = useUpdateSystemMutation()
  const handleSubmit = form.handleSubmit(() => {
    const updateData = getFormDirtyValues(form.formState.dirtyFields, formValue)
    if (
      (updateData.status && updateData.status !== SystemStatusesMap.CARRIED_OUT.name) ||
      updateData.noSubstatus
    )
      updateData.substatus = SystemSubstatusesMap.WITHOUT_COMMENTS.name
    if (updateData.status === SystemStatusesMap.MISSING.name) updateData.expirationDate = null
    delete updateData.noSubstatus

    updateSystem({systemId: system.id, updateData})
      .unwrap()
      .then((updated) => {
        notifySuccess(
          'Изменения сохранены',
          'Новый статус отображен в столбце со статусами НТ. Комментарий также обновлен.'
        )
        onCancel()
      })
      .catch((error) => {
        notifyError()
        console.log(error)
      })
  })

  return (
    <Modal
      closeOnOverlay
      buttons={[
        {
          onClick: onCancel,
          side: 'right',
          text: 'Отмена',
        },
        {
          onClick: handleSubmit,
          side: 'right',
          text: 'Сохранить изменения',
        },
      ]}
      isOpen={isOpen}
      size="medium"
      title="Изменение статуса нагрузочного тестирования"
      onClose={onCancel}
    >
      {isOpen && form.getValues('id') === system.id && (
        <FlexboxColumn sx={{gap: '24px'}}>
          <FlexboxRow sx={{gap: '40px'}}>
            <Typography variant="h4">Информационная система</Typography>
            <Typography variant="p1">{getTagOrName(system)}</Typography>
          </FlexboxRow>
          <FormInputSelect
            key="status"
            caption="Выберите соответствующий статус для информационной системы"
            control={form.control}
            label="Статус НТ"
            name="status"
            options={SystemStatusOptions}
            width={MODAL_INPUT_WIDTH_PX}
          />
          {formValue.status === SystemStatusesMap.MISSING.name ? (
            <FormInputText
              key="expirationDate"
              disabled
              caption="В статусе “НТ отсутствует” дата не проставляется. Он действует бессрочно до его смены."
              control={form.control}
              label="Срок действия статуса"
              name="expirationDate"
              value="Бессрочно"
              width={MODAL_INPUT_WIDTH_PX}
            />
          ) : (
            <FormInputDatetime
              key="expirationDate"
              required
              caption="Выберите дату окончания действия статуса. По ее истечению статус изменится на “НТ отсутствует”"
              control={form.control}
              label="Срок действия статуса"
              name="expirationDate"
              rules={RequiredRule}
              width={MODAL_INPUT_WIDTH_PX}
            />
          )}
          {formValue.status === SystemStatusesMap.CARRIED_OUT.name && (
            <>
              <FormInputCheckbox
                key="noSubstatus"
                control={form.control}
                label="Замечания отсутствуют"
                name="noSubstatus"
              />
              {
                <FormInputSelect
                  key={`substatus.${formValue.noSubstatus}`}
                  required
                  control={form.control}
                  disabled={formValue.noSubstatus}
                  label="Предупреждающий статус"
                  name="substatus"
                  options={SystemSubstatusOptions}
                  rules={!formValue.noSubstatus && RequiredRule}
                  width={MODAL_INPUT_WIDTH_PX}
                />
              }
            </>
          )}
          <FormInputText
            key="comment"
            required
            textarea
            control={form.control}
            label="Комментарий о смене статуса"
            name="comment"
            rules={RequiredRule}
            width={MODAL_INPUT_WIDTH_PX}
          />
        </FlexboxColumn>
      )}
    </Modal>
  )
}

export default SystemStatusChangeModal
