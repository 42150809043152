import {FilterCollectorColumn} from '@root/components/kit'
import {sortDirections} from '@x5-react-uikit/core'
import {ExtractConstValues} from 'core/helperTypes'

export type RowItemBase = object

export enum SortTextKind {
  default = 0,
  alphabetical,
  alphabeticalCyrillic,
  newestFirst,
  lifeCycle,
  closestIndefinite,
}

export type SortText = {
  ascending: string
  descending: string
}

export type ValueRenderType = string &
  ('number' | 'boolean' | 'date' | 'dateTime' | 'string' | 'enum')

export type SortMap<Row extends RowItemBase> = Partial<
  Record<keyof Row, ExtractConstValues<typeof sortDirections>>
>

export type SortItem<Row extends RowItemBase> = {
  name: keyof Row,
  value: ExtractConstValues<typeof sortDirections>
}

export type GetIdFn<Row extends RowItemBase> = (row: Row) => Row[keyof Row] & (number | string)

export type AppTableChangeEventType =
  | 'pages'
  | 'filterApply'
  | 'filterReset'
  | 'filterChanged'
  | 'currentTab'
  | 'sort'
  | 'checkboxSelectAll'
  | 'checkboxSelect'
  | 'checkboxUnselectAll'

export type PatchFilterCollectorColumnsFn = (
  columns: FilterCollectorColumn[]
) => FilterCollectorColumn[]
