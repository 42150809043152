import {Modal, Typography} from '@x5-react-uikit/core'
import {getTaskStatusDisplay, TaskStatuses} from '@root/constants'
import useNotify from '@root/hooks/useNotify'
import {useNavigate} from 'react-router-dom'
import {useGetSystemFinishedTaskQuery} from '@root/redux/api/systemsApi'
import {useCopyTaskMutation, useCreateTaskMutation} from '@root/redux/api/tasksApi'
import React from 'react'
import {ExtractConstValues} from '@root/core/helperTypes'

interface CopyTaskModalProps {
  system: any // fixme
  open: boolean
  onClose: () => void
}

const CopyTaskModal = ({system, open, onClose}: CopyTaskModalProps) => {
  const navigate = useNavigate()
  const {notifyError, notifySuccess} = useNotify()

  const onError = (error) => {
    if (error.status === 409 && error.data?.messages?.[0])
      notifyError(error.data.messages[0], 'Ошибка', 999999)
    else notifyError()
    onClose()
  }

  const [createTask] = useCreateTaskMutation()
  const handleCreateTask = () => {
    createTask({system})
      .unwrap()
      .then((created) => {
        onClose()
        navigate(`/tasks/create/${created.id}/steps/1`)
      })
      .catch(onError)
  }

  const {data: lastTask} = useGetSystemFinishedTaskQuery(system?.id, {skip: !system})

  const [copyTask] = useCopyTaskMutation()
  const handleCopyTask = () => {
    copyTask(lastTask.id)
      .unwrap()
      .then((createdTask) => {
        onClose()
        navigate(`/tasks/create/${createdTask.id}/steps/1`)
        notifySuccess('Заявка скопирована')
      })
      .catch(onError)
  }

  return (
    <Modal
      closeOnOverlay
      buttons={[
        {
          onClick: handleCreateTask,
          side: 'right',
          text: 'Заполнить заново',
        },
        {
          onClick: handleCopyTask,
          side: 'right',
          text: 'Скопировать данные',
        },
      ]}
      isOpen={open}
      size="medium"
      title="Заявка по ИС в итоговом статусе"
      onClose={onClose}
    >
      <Typography variant="p1">
        По данной ИС уже есть предыдущая заявка со статусом "
        {getTaskStatusDisplay(lastTask?.status as ExtractConstValues<typeof TaskStatuses>)}". Вы
        можете скопировать данные из предыдущей заявки или заполнить их заново.
      </Typography>
    </Modal>
  )
}

export default CopyTaskModal
