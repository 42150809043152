import {Link, Typography} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import {ReactComponent as TemplateNotApliedSvg} from '../../assets/TemplateNotAplied.svg'
import {SpecialistToolsTabs} from '../../constants'

const TemplateNotApliedMessage = ({setTab}) => {
  return (
    <FlexboxColumn
      sx={{mt: 'x12', alignItems: 'center', justifyContent: 'center', height: '184px'}}
    >
      <TemplateNotApliedSvg />
      <FlexboxColumn sx={{mt: 'x20', gap: '8px', alignItems: 'center'}}>
        <Typography variant="h4">Для этой ИС еще не был применен шаблон</Typography>
        <Typography style={{textAlign: 'center'}} variant="p1">
          Выберите шаблон или создайте новый в подразделе
          <Link onClick={() => setTab(SpecialistToolsTabs.templates.value)}>
            &nbsp;Шаблоны настроек
          </Link>
        </Typography>
      </FlexboxColumn>
    </FlexboxColumn>
  )
}

export default TemplateNotApliedMessage
