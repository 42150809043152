import {FileUploaderItem, FileUploaderProps} from './types'

export const formatToMb = (bytes: number): number => {
  const k = 1024 * 1024
  return bytes / k
}
export const getUniqueFilename = ({name, size}: File): string => `${name}-${size}`
export const normalizeFileList = (items: FileUploaderItem[]): File[] => items.map(({file}) => file)
export const prepareFileTypesToText = (fileTypes: FileUploaderProps['accept']): string => {
  if (!fileTypes?.length) return ''
  const last = fileTypes.length - 1
  const endPhrase = last ? ' или ' + fileTypes[last] : ' формата ' + fileTypes[0]
  return fileTypes.slice(0, last).join(', ') + endPhrase
}

const pluralRules = new Intl.PluralRules('ru-RU')
export const pluralize = (count: number, singular: string, plural: string): string => {
  const grammaticalNumber = pluralRules.select(count)
  if (grammaticalNumber === 'one') return `${count} ${singular}`
  return `${count} ${plural}`
}
export const pluralizeCountFile = (count: number): string => pluralize(count, 'файла', 'файлов')

export const mimeTypes = {
  png: 'image/png',
  gif: 'image/gif',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  svg: 'image/svg+xml',
  webp: 'image/webp',
  avif: 'image/avif',

  mp4: 'video/mp4',
  zip: 'application/zip',
  csv: 'text/csv',
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  exe: 'application/vnd.microsoft.portable-executable',
} as const
