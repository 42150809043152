import {Loader, Select} from '@x5-react-uikit/core'
import {useGetAllRolesQuery} from '@root/redux/api/userApi'

const UserSelect = ({value, setValue, ...inputProps}) => {
  const {data: allRoles, isLoading: isRolesLoading} = useGetAllRolesQuery()

  if (isRolesLoading) return <Loader />

  return (
    <Select
      label="Выберите роль"
      name="role"
      options={Object.keys(allRoles).map((role) => ({value: role, name: allRoles[role]}))}
      value={value}
      width="272px"
      onChange={(e, value) => {
        setValue(value)
      }}
      {...inputProps}
    />
  )
}

export default UserSelect
