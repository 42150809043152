import {FC} from 'react'
import {CustomFilterInputProps} from '@root/components/kit'
import {SystemStatusSelect} from '@root/features/systems/components/SystemStatusSelect'
import {NameCombobox} from '@root/features/systems/components/NameCombobox'

export const FilterCollectorNameCombobox: FC<CustomFilterInputProps> = ({
  onChange,
  value,
  size,
}) => {
  return (
    <NameCombobox
      value={value as string}
      onChange={onChange}
    />
  )
}
