import {makeUiStyles} from '../theme'

export const useStyles = makeUiStyles(
  (theme) => ({
    unselected: {
      '&:hover': {
        backgroundColor: theme.colors.grey[20],
        color: theme.colors.grey[100],
        '& svg': {
          backgroundColor: theme.colors.white,
          color: theme.colors.grey[40],
        },
      },
    },
    selected: {
      cursor: 'default',
    },
  }),
  'list-checked-item'
)
