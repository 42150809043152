import React from 'react'
import createLogo from '../utils/createSvgLogo'
import {logoVariants, logoColors, logoBrands} from '../types'
import TSXFull from './Full'
import TSXSmall from './Small'

export default createLogo((variant = logoVariants.full, color = logoColors.multi, partialProps) => {
  const props = {
    color,
    width: '100%',
    height: '100%',
    ...partialProps,
  }

  if (variant === logoVariants.icon) return <TSXSmall {...props} />

  return <TSXFull {...props} />
}, `${logoBrands.perekrestok}Logo`)
