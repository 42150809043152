import Section from '@root/components/Section'
import SystemNotSelectedMessage from '../stubs/SystemNotSelectedMessage'
import TemplatesTable from './TemplatesTable'

const TemplatesTab = ({systemId}) => {
  if (systemId) return <TemplatesTable systemId={systemId} />

  return (
    <Section
      sx={{
        py: undefined,
        px: undefined,
        p: undefined,
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '1200px',
        minHeight: '424px',
      }}
    >
      <SystemNotSelectedMessage />
    </Section>
  )
}

export default TemplatesTab
