import {Modal} from '@x5-react-uikit/core'
import useNotify from '@root/hooks/useNotify'
import {useRef, useState} from 'react'
import SubmitChangeStatus from './SubmitChangeStatus'
import {
  useGetTaskByIdQuery,
  useUpdateTaskMutation,
  useUpdateTaskStatusMutation,
} from '@root/redux/api/tasksApi'
import {useParams} from 'react-router-dom'
import {Textarea} from '@root/components/kit'
import {TaskStatuses} from '@root/constants'
import {getTaskStatusDisplay} from '@root/constants'

const ChangeToSuspendModal = ({open, onClose, onOpen}) => {
  const {taskId} = useParams()
  const {notifyError, notifySuccess} = useNotify()

  const reasonRef = useRef()
  const [reasonValue, setReasonValue] = useState('')
  const [reasonInvalid, setReasonInvalid] = useState(false)

  const {data: task} = useGetTaskByIdQuery(taskId)

  const [approveModalShown, setApproveModalShown] = useState(false)
  const showApproveModal = () => {
    if (!reasonValue) {
      setReasonInvalid(true)
      reasonRef.current?.focus()
      return
    }
    setReasonInvalid(false)
    setApproveModalShown(true)
    onClose()
  }
  const closeApproveModal = () => setApproveModalShown(false)
  const cancelApproveModal = () => {
    closeApproveModal()
    onOpen()
    setReasonValue('')
    setReasonInvalid(false)
  }
  const cancelSuspendModal = () => {
    onClose()
    setReasonValue('')
    setReasonInvalid(false)
  }

  const [updateTaskStatus] = useUpdateTaskStatusMutation()
  const [updateTask] = useUpdateTaskMutation()
  const handleSetSuspended = () => {
    const updateStatusData = {nextTaskStatus: TaskStatuses.SUSPENDED}
    updateTask({taskId, update: {suspensionReason: reasonValue}})
      .unwrap()
      .then(() => updateTaskStatus({taskId, updateData: updateStatusData}).unwrap())
      .then(() => {
        notifySuccess(
          'Заявка успешно приостановлена',
          'Участники заявки получат уведомление об этом в outlook.'
        )
        closeApproveModal()
        setReasonValue('')
      })
      .catch(() => notifyError())
  }

  return (
    <>
      <Modal
        closeOnOverlay
        buttons={[
          {
            onClick: cancelSuspendModal,
            side: 'right',
            text: 'Отмена',
          },
          {
            onClick: showApproveModal,
            side: 'right',
            text: 'Приостановить',
          },
        ]}
        description="Укажите причину, по которой заявка будет приостановлена"
        isOpen={open}
        size="medium"
        title="Приостановление работ по заявке"
        onClose={cancelSuspendModal}
      >
        <Textarea
          required
          error={reasonInvalid}
          itemRef=""
          label="Причина приостановления заявки"
          textareaRef={reasonRef}
          textError={reasonInvalid && 'Поле не может быть пустым'}
          value={reasonValue}
          width="100%"
          onChange={(e) => setReasonValue(e.target.value)}
        />
      </Modal>
      <SubmitChangeStatus
        handleChangeStatus={handleSetSuspended}
        message={`
          Статус заявки перейдет с “${getTaskStatusDisplay(task?.status)}” в “Приостановлено”. 
          Участники заявки, кроме менеджера, не смогу совершать действия с заявкой. 
          Работу заявки можно будет возобновить.
        `}
        open={!open && approveModalShown}
        onCancel={cancelApproveModal}
        onClose={closeApproveModal}
      />
    </>
  )
}

export default ChangeToSuspendModal
