import React, {FC} from 'react'
import {defineColors} from '../utils/defineColors'
import {LogoProps} from '../types'

const primary = '#5faf2d'
const getPathColor = defineColors('#000000')

const Logo: FC<LogoProps> = ({color, ...props}) => (
  <svg fill="none" viewBox="0 0 542 100" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M173.805 45.791H211.993V52.2425H180.713V70.458H209.868V76.9095H180.713V98.9194H173.805V45.791Z"
      fill={getPathColor(color)}
    />
    <path
      d="M236.898 58.7695C249.197 58.7695 257.853 67.3453 257.853 79.1093C257.853 91.0253 249.274 99.6011 236.823 99.6011C224.675 99.6011 215.944 91.0253 215.944 79.1093C215.942 67.3453 224.446 58.7695 236.898 58.7695ZM236.823 93.6056C245.326 93.6056 251.172 87.6852 251.172 79.1093C251.172 70.6087 245.022 64.7651 236.823 64.7651C228.623 64.7651 222.625 70.5335 222.625 79.1093C222.623 87.61 228.469 93.6056 236.823 93.6056Z"
      fill={getPathColor(color)}
    />
    <path
      d="M284.425 58.7695C296.725 58.7695 305.38 67.3453 305.38 79.1093C305.38 91.0253 296.802 99.6011 284.35 99.6011C272.202 99.6011 263.471 91.0253 263.471 79.1093C263.471 67.3453 271.974 58.7695 284.425 58.7695ZM284.35 93.6056C292.854 93.6056 298.699 87.6852 298.699 79.1093C298.699 70.6087 292.55 64.7651 284.35 64.7651C276.15 64.7651 270.153 70.5335 270.153 79.1093C270.153 87.61 275.998 93.6056 284.35 93.6056Z"
      fill={getPathColor(color)}
    />
    <path
      d="M310.999 79.2616C310.999 67.1937 319.957 58.7698 330.967 58.7698C338.256 58.7698 342.66 61.2732 345.012 65.2213V43.5137H351.389V98.9184H345.012V91.6318C342.354 96.4884 338.027 99.5997 330.055 99.5997C319.351 99.6014 310.999 91.9358 310.999 79.2616ZM345.012 79.5639V78.1209C345.012 69.6971 339.394 64.7637 331.726 64.7637C324.134 64.7637 317.68 70.4553 317.68 79.1079C317.68 88.1397 323.374 93.6042 331.346 93.6042C339.698 93.6042 345.012 88.3685 345.012 79.5639Z"
      fill={getPathColor(color)}
    />
    <path
      d="M373.409 45.791H417.293V52.2425H398.767V98.9177H391.858V52.2425H373.409V45.791Z"
      fill={getPathColor(color)}
    />
    <path
      d="M414.409 79.1079C414.409 67.3439 423.14 58.541 434.909 58.541C446.602 58.541 454.648 66.4338 454.648 77.9707C454.648 79.1097 454.572 80.3989 454.496 81.386H420.937C421.316 88.4437 427.163 93.6042 434.907 93.6042C440.677 93.6042 444.321 91.555 447.358 86.6967L453.129 88.8211C449.94 95.8036 443.638 99.598 434.907 99.598C422.836 99.6014 414.409 91.1759 414.409 79.1079ZM421.242 75.3904H448.119C447.891 69.3948 442.046 64.5366 434.909 64.5366C427.771 64.5366 422.078 69.014 421.242 75.3904Z"
      fill={getPathColor(color)}
    />
    <path
      d="M498.306 88.8242C495.117 95.8067 488.815 99.6011 480.388 99.6011C468.24 99.6011 459.509 91.0253 459.509 79.1093C459.509 67.1934 468.165 58.7695 480.388 58.7695C488.892 58.7695 495.269 62.6408 498.458 69.5465L492.687 71.6708C489.954 67.1934 485.702 64.7634 480.388 64.7634C472.036 64.7634 466.19 70.6838 466.19 79.1076C466.19 87.6083 472.036 93.6039 480.388 93.6039C485.854 93.6039 489.65 91.4027 492.535 86.6964L498.306 88.8242Z"
      fill={getPathColor(color)}
    />
    <path
      d="M506.126 43.5137H512.503V65.5236C514.932 61.1981 519.488 58.7698 525.334 58.7698C536.115 58.7698 541.961 64.2343 541.961 76.6814V98.9184H535.584V77.2876C535.584 68.7118 531.94 64.7654 524.119 64.7654C517.666 64.7654 512.503 68.7118 512.503 76.4525V98.9184H506.126V43.5137Z"
      fill={getPathColor(color)}
    />
    <path
      d="M93.6474 98.9189L82.6376 80.6266L71.855 98.9189H58.9473L75.5295 71.366L58.6279 45.7871H72.113L82.94 62.8653L93.2665 45.7871H106.174L89.5458 72.201L106.63 98.9189H93.6474Z"
      fill={getPathColor(color)}
    />
    <path
      d="M148.589 80.8579C148.589 92.5467 139.781 99.8333 128.24 99.8333C118.065 99.8333 109.5 94.2168 108.665 81.9969H119.675C120.433 87.3094 124.687 89.5874 129.318 89.5874C135.088 89.5874 137.579 85.7161 137.579 80.7076C137.579 75.4702 133.706 72.2069 128.315 72.2069C123.836 72.2069 120.874 74.3329 119.507 76.6092H108.984L112.621 45.793H147.232L146.078 56.1157H121.318L120.334 66.3138C120.334 66.3138 122.934 63.252 130.816 63.252C140.312 63.2486 148.589 69.6215 148.589 80.8579Z"
      fill={getPathColor(color)}
    />
    <path
      d="M109.968 39.1739C104.102 16.3188 85.4954 0.261719 46.8817 0.261719H0.261292L6.59043 43.0401C11.3514 81.6398 26.1194 94.0578 52.2542 98.6309C52.3755 98.6497 53.204 98.7675 53.2143 97.7993C53.2177 97.4509 52.9973 97.1504 52.695 97.0206C35.0297 89.5855 33.014 65.2037 29.2404 40.7365L26.321 21.2368H52.9341C77.47 21.2368 96.4147 20.8492 108.408 39.7836C108.586 40.05 108.865 40.2464 109.194 40.2464C109.657 40.2464 110.002 39.8707 110.002 39.4079C110.002 39.3242 109.99 39.2457 109.968 39.1739Z"
      fill={getPathColor(color, primary)}
    />
  </svg>
)

export default Logo
