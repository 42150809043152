import {
  colorsUi,
  colorsTheme,
  colorsAdditional,
  colorsMore,
  shadows,
  spaces,
  typography,
  SpaceJoinArgs,
} from '@x5-react-uikit/tokens'
import {BaseThemeValues} from './types'

export const enum themeNameValues {
  base = 'base',
  tsx = 'tsx',
  ts5 = 'ts5',
  x5 = 'x5',
}

export class BaseTheme implements BaseThemeValues {
  name = themeNameValues.base
  colors = {
    accent: colorsTheme.base.accent,
    grey: colorsTheme.base.grey,
    white: '#ffffff',

    success: colorsUi.state.success,
    error: colorsAdditional.red[80],
    attention: colorsUi.state.attention,
    focus: colorsTheme.base.accent[90],

    red: colorsMore.red,
    green: colorsMore.green,
    lime: colorsMore.lime,
    indigo: colorsMore.indigo,
    purple: colorsMore.purple,
    teal: colorsMore.teal,
    cyan: colorsMore.cyan,
    bluegrey: colorsMore.bluegrey,
    amber: colorsMore.amber,
    pink: colorsMore.pink,
    brown: colorsMore.brown,
    yellow: colorsMore.yellow,
    orange: colorsMore.orange,
  }

  palette = colorsAdditional

  typography = typography

  shadows = shadows

  widths = {
    field: 200,
  }

  zIndex = {
    dropdown: 100,
    modal: 800,
    tooltip: 800,
    loader: 900,
    onboarding: 1000,
  }

  spaces = {
    ...spaces,
    join: (...args: SpaceJoinArgs): string =>
      args.map((key) => (key === 'x0' ? '0' : `${spaces[key]}px`)).join(' '),
  }

  styles = null
}
export const baseTheme: BaseThemeValues = new BaseTheme()

export class TsxTheme extends BaseTheme implements BaseThemeValues {
  name = themeNameValues.tsx
  colors = {
    ...baseTheme.colors,
    accent: colorsTheme.tsx.accent,
    focus: colorsTheme.tsx.accent[90],
    grey: colorsTheme.tsx.grey,
  }
}
export const tsxTheme: BaseThemeValues = new TsxTheme()

export class Ts5Theme extends BaseTheme implements BaseThemeValues {
  name = themeNameValues.ts5
  colors = {
    ...baseTheme.colors,
    accent: colorsTheme.ts5.accent,
    focus: colorsTheme.ts5.accent[90],
    grey: colorsTheme.ts5.grey,
  }
}
export const ts5Theme: BaseThemeValues = new Ts5Theme()

export class X5Theme extends BaseTheme implements BaseThemeValues {
  name = themeNameValues.x5
  colors = {
    ...baseTheme.colors,
    accent: colorsTheme.x5.accent,
    focus: colorsTheme.x5.accent[90],
    grey: colorsTheme.x5.grey,
  }
}
export const x5Theme: BaseThemeValues = new X5Theme()

export const themes = {
  [themeNameValues.base]: baseTheme,
  [themeNameValues.tsx]: tsxTheme,
  [themeNameValues.ts5]: ts5Theme,
  [themeNameValues.x5]: x5Theme,
}
