import {Typography} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import Section from '@root/components/Section'

const ReportAffilationSection = ({form}) => {
  return (
    <Section sx={{display: 'flex', flexDirection: 'column', gap: '24px', my: 0, width: '588px'}}>
      <Typography variant="h3">Принадлежность</Typography>
      <FlexboxRow sx={{justifyContent: 'space-between'}}>
        <FlexboxColumn sx={{width: '242px', gap: '4px'}}>
          <Typography>Тестирование</Typography>
          <Typography>ID {form.getValues('testNumber') || '-'}</Typography>
        </FlexboxColumn>
        <FlexboxColumn sx={{width: '242px', gap: '4px'}}>
          <Typography>Шаблон переменных</Typography>
          <Typography>{form.getValues('variablesTemplateName') || '-'}</Typography>
        </FlexboxColumn>
      </FlexboxRow>
      <FlexboxRow sx={{justifyContent: 'space-between', gap: '24px', my: 0}}>
        <FlexboxColumn sx={{width: '242px', gap: '4px'}}>
          <Typography>Информационная система</Typography>
          <Typography>
            {form.getValues('report.system.tag') || form.getValues('report.system.name')}
          </Typography>
        </FlexboxColumn>
        <FlexboxColumn sx={{width: '242px', gap: '4px'}}>
          <Typography>Заявка</Typography>
          <Typography>#{form.getValues('taskNumber')}</Typography>
        </FlexboxColumn>
      </FlexboxRow>
    </Section>
  )
}

export default ReportAffilationSection
