import React, {FC} from 'react'
import {defineColors} from '../utils/defineColors'
import {LogoProps} from '../types'

const primary = '#5faf2d'
const getPathColor = defineColors('#000000')

const Logo: FC<LogoProps> = ({color, ...props}) => (
  <svg fill="none" viewBox="0 0 361 100" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M146.314 86.4422H166.296C181.536 86.4422 191.9 76.816 191.9 62.7156C191.9 48.6153 181.536 38.989 166.296 38.989H146.314V86.4422ZM153.087 80.5444V44.8868H165.889C177.608 44.8868 185.126 52.0725 185.126 62.7156C185.126 73.3587 177.608 80.5444 165.889 80.5444H153.087Z"
      fill={getPathColor(color)}
    />
    <path
      d="M204.578 43.5988C207.152 43.5988 208.981 41.7006 208.981 39.2602C208.981 36.9553 207.085 35.125 204.578 35.125C202.072 35.125 200.175 37.0231 200.175 39.3958C200.175 41.7684 202.072 43.5988 204.578 43.5988ZM201.327 86.4422H207.83V50.5134H201.327V86.4422Z"
      fill={getPathColor(color)}
    />
    <path
      d="M248.424 50.5134V56.0722C245.375 52.1403 240.498 50.1744 235.147 50.1744C224.784 50.1744 216.926 57.1568 216.926 67.5287C216.926 77.9006 224.784 84.9508 235.147 84.9508C240.295 84.9508 244.969 83.1205 248.085 79.392V82.2392C248.085 90.374 244.224 94.3058 235.757 94.3058C230.474 94.3058 225.258 92.4755 221.939 89.6283L218.823 94.6448C222.752 98.1699 229.39 100 235.96 100C248.153 100 254.588 94.2381 254.588 81.5613V50.5134H248.424ZM235.892 79.2564C228.645 79.2564 223.497 74.4433 223.497 67.5287C223.497 60.5463 228.645 55.8688 235.892 55.8688C243.072 55.8688 248.22 60.5463 248.22 67.5287C248.22 74.4433 243.072 79.2564 235.892 79.2564Z"
      fill={getPathColor(color)}
    />
    <path
      d="M270.264 43.5988C272.838 43.5988 274.666 41.7006 274.666 39.2602C274.666 36.9553 272.77 35.125 270.264 35.125C267.757 35.125 265.861 37.0231 265.861 39.3958C265.861 41.7684 267.757 43.5988 270.264 43.5988ZM267.012 86.4422H273.515V50.5134H267.012V86.4422Z"
      fill={getPathColor(color)}
    />
    <path
      d="M304.084 79.6632C302.729 80.7478 300.9 81.3579 299.004 81.3579C295.414 81.3579 293.382 79.1886 293.382 75.3246V55.8688H303.678V50.5134H293.382V42.6497H286.879V50.5134H280.783V55.8688H286.879V75.5957C286.879 82.8493 291.011 86.8489 298.259 86.8489C301.171 86.8489 304.152 86.0354 306.116 84.3407L304.084 79.6632Z"
      fill={getPathColor(color)}
    />
    <path
      d="M327.051 50.1744C321.293 50.1744 315.942 51.8014 312.217 54.7842L314.926 59.6651C317.703 57.2924 322.038 55.801 326.306 55.801C332.741 55.801 335.924 58.9871 335.924 64.4782V65.7662H325.628C314.926 65.7662 311.201 70.5115 311.201 76.2737C311.201 82.5103 316.348 86.8489 324.477 86.8489C330.099 86.8489 334.095 84.9508 336.263 81.6969V86.4422H342.427V64.7493C342.427 54.9197 336.872 50.1744 327.051 50.1744ZM325.561 81.8324C320.616 81.8324 317.635 79.5954 317.635 76.0025C317.635 72.9519 319.464 70.4437 325.899 70.4437H335.924V75.4602C334.298 79.5954 330.438 81.8324 325.561 81.8324Z"
      fill={getPathColor(color)}
    />
    <path d="M354.46 86.4422H360.963V36.1419H354.46V86.4422Z" fill={getPathColor(color)} />
    <path
      d="M81.9693 86.6975L72.3057 70.6231L62.8415 86.6975H51.512L66.0682 62.4853L51.2316 40.0078H63.0679L72.5711 55.0153L81.635 40.0078H92.9645L78.3708 63.2191L93.3663 86.6975H81.9693Z"
      fill={getPathColor(color)}
    />
    <path
      d="M130.196 70.8261C130.196 81.0977 122.465 87.5008 112.335 87.5008C103.405 87.5008 95.8866 82.5653 95.1534 71.8255H104.817C105.483 76.4939 109.216 78.4957 113.281 78.4957C118.346 78.4957 120.532 75.0938 120.532 70.691C120.532 66.0887 117.133 63.2211 112.401 63.2211C108.47 63.2211 105.87 65.0893 104.67 67.0896H95.4322L98.6245 40.0098H129.005L127.992 49.0809H106.259L105.396 58.0425C105.396 58.0425 107.678 55.3504 114.596 55.3504C122.931 55.3519 130.196 60.9521 130.196 70.8261Z"
      fill={getPathColor(color)}
    />
    <path
      d="M96.2944 34.1942C91.1454 14.1101 74.814 0 40.9201 0H0L5.55527 37.593C9.7341 71.5141 22.6964 82.4249 45.6372 86.445C45.7437 86.4615 46.4709 86.5651 46.4799 85.7142C46.4829 85.4081 46.2894 85.144 46.024 85.03C30.5188 78.4964 28.7495 57.0693 25.4373 35.5687L22.8748 18.4334H46.234C67.7713 18.4334 84.3997 18.0927 94.9269 34.7314C95.0829 34.9655 95.3273 35.138 95.6167 35.138C96.023 35.138 96.3259 34.8079 96.3259 34.4012C96.3244 34.3277 96.3154 34.2572 96.2944 34.1942Z"
      fill={getPathColor(color, primary)}
    />
  </svg>
)

export default Logo
