import {Typography, baseTheme} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import Section from '@root/components/Section'
import {getTagOrName} from '@root/features/systems/utils'
import {useParams} from 'react-router-dom'
import {useGetConfigurationByIdQuery} from '@root/redux/api/reportConfigurationsApi'

const {colors} = baseTheme

const ConfAffilation = () => {
  const {configurationId} = useParams()
  const {data: configuration} = useGetConfigurationByIdQuery({configurationId})

  return (
    <Section sx={{my: 0, width: '588px'}}>
      <FlexboxColumn sx={{gap: '24px'}}>
        <Typography variant="h3">Принадлежность конфигурации</Typography>
        <FlexboxColumn sx={{width: '544px', gap: '4px'}}>
          <Typography>Информационная система</Typography>
          <Typography>{getTagOrName(configuration.system)}</Typography>
        </FlexboxColumn>
        <FlexboxColumn sx={{width: '544px', gap: '4px'}}>
          <Typography>Заявка</Typography>
          <Typography style={{color: colors.grey[60]}}>
            Автоматически при формировании отчета
          </Typography>
        </FlexboxColumn>
      </FlexboxColumn>
    </Section>
  )
}

export default ConfAffilation
