import {Box} from '@root/components/kit'
import type {BoxProps} from 'ui-kit'
import {FC} from 'react'

type Props = {
  sx?: BoxProps['sx']
  horizontal?: boolean
}

const Divider: FC<Props> = ({sx, horizontal = false}) =>
  horizontal ? (
    <Box sx={{width: '1px', backgroundColor: 'grey.30', ...sx}} />
  ) : (
    <Box sx={{height: '1px', backgroundColor: 'grey.30', ...sx}} />
  )

export default Divider
