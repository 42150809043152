import {Box, Button, Loader, Typography} from '@x5-react-uikit/core'
import {Search as SearchIcon} from '@x5-react-uikit/icons'
import Chip from '@root/components/Chip'
import FlexboxColumn from '@root/components/FlexboxColumn'
import FlexboxRow from '@root/components/FlexboxRow'
import TablePagination from '@root/components/dataGridTable/TablePagination'
import DataGridTable from '@root/components/dataGridTable/table/DataGridTable'
import FormInputText from '@root/components/inputs/formInputs/FormInputText'
import useNotify from '@root/hooks/useNotify'
import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {
  useGetAllRolesQuery,
  useGetUsersByFilterQuery,
  useUpdateUserMutation,
} from '@root/redux/api/userApi'
import useFilterUsers from '../hooks/useFilterUsers'
import RoleCheckList from './RoleCheckList'
import UserSelect from './UserSelect'
import {Roles} from '@root/constants'

export const roleToColor = (status) => {
  switch (status) {
    case Roles.BUSINESS:
      return {backgroundColor: 'green.10', textColor: 'green.80'}
    case Roles.MANAGER:
      return {backgroundColor: 'violet.10', textColor: 'violet.80'}
    case Roles.SPECIALIST:
      return {backgroundColor: 'blue.10', textColor: 'blue.80'}
    case Roles.ADMIN:
      return {backgroundColor: 'yellow.10', textColor: 'yellow.90'}
    default:
      return {}
  }
}

const FieldValue = ({field, value}) => (
  <FlexboxColumn sx={{gap: '2px'}}>
    <Typography variant="h4">{field}</Typography>
    <Typography variant="p1">{value}</Typography>
  </FlexboxColumn>
)

const UsersTable = () => {
  const {notifySuccess, notifyError} = useNotify()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(20)

  const form = useForm()
  const {filter} = useFilterUsers({
    pageNumber,
    pageSize,
    getFormValue: form.getValues,
    watchFormValue: form.watch,
  })

  const {data: users, isLoading: isUsersLoading} = useGetUsersByFilterQuery({filter})

  const {data: allRoles, isLoading: isRolesLoading} = useGetAllRolesQuery()

  const [selectedUser, setSelectedUser] = useState(null)

  const [selectedUserRole, setSelectedUserRole] = useState(null)
  useEffect(() => setSelectedUserRole(selectedUser?.role), [selectedUser])

  const [updateUser] = useUpdateUserMutation()
  const handleUpdateRole = () => {
    const updateData = {...selectedUser, role: selectedUserRole}
    updateUser({userId: selectedUser.id, updateData})
      .unwrap()
      .then(() =>
        notifySuccess(
          'Изменения сохранены',
          'Изменения вступили в силу. Роли будут\n применены мгновенно.'
        )
      )
      .catch(() => notifyError('Не удалось внести изменения.'))
  }

  if (isUsersLoading || isRolesLoading) return <Loader />

  return (
    <FlexboxRow sx={{borderRadius: 'x2'}}>
      <Box sx={{backgroundColor: 'white', borderTopLeftRadius: 'x2', borderBottomLeftRadius: 'x2'}}>
        <FlexboxRow sx={{p: 'x6', gap: '32px'}}>
          <FormInputText
            key="email"
            control={form.control}
            label="Поиск"
            name="filters.email.value"
            size="small"
            startAdornment={<SearchIcon size="small" />}
            width="260px"
          />
          <RoleCheckList control={form.control} />
        </FlexboxRow>
        <DataGridTable styles={{width: 'fit-content'}}>
          <DataGridTable.Head>
            <DataGridTable.HeadCell
              // style={{whiteSpace: 'pre-line', width: column.width, paddingLeft: 0, paddingRight: 0}}
              key="name"
              name="name"
              width="169px"
            >
              Имя
            </DataGridTable.HeadCell>
            <DataGridTable.HeadCell
              // style={{whiteSpace: 'pre-line', width: column.width, paddingLeft: 0, paddingRight: 0}}
              key="name"
              name="name"
              width="360px"
            >
              Почта
            </DataGridTable.HeadCell>
            <DataGridTable.HeadCell
              // style={{whiteSpace: 'pre-line', width: column.width, paddingLeft: 0, paddingRight: 0}}
              key="name"
              name="name"
              width="351px"
            >
              Роли
            </DataGridTable.HeadCell>
          </DataGridTable.Head>
          <DataGridTable.Section style={{}}>
            {users.items.map((user) => (
              <DataGridTable.Row
                key={user.id}
                selected={selectedUser?.id === user.id}
                onClick={() => {
                  setSelectedUser(user)
                }}
              >
                <DataGridTable.Cell
                  key={`${user.id}-name`}
                  shouldFocusOnClick={false}
                  style={{cursor: 'pointer'}}
                >
                  {user.name}
                </DataGridTable.Cell>
                <DataGridTable.Cell
                  key={`${user.id}-email`}
                  shouldFocusOnClick={false}
                  style={{cursor: 'pointer'}}
                >
                  {user.email}
                </DataGridTable.Cell>
                <DataGridTable.Cell
                  key={`${user.id}-role`}
                  shouldFocusOnClick={false}
                  style={{cursor: 'pointer'}}
                >
                  {<Chip sx={{mt: 0}} text={allRoles[user.role]} {...roleToColor(user.role)} />}
                </DataGridTable.Cell>
              </DataGridTable.Row>
            ))}
          </DataGridTable.Section>
        </DataGridTable>
        {users && (
          <TablePagination
            pageNumber={pageNumber}
            pageSizeActual={users.items.length}
            pageSizeOptions={[20]}
            selectedPageSizeOption={pageSize}
            setPageNumber={setPageNumber}
            totalPages={users.totalPages}
            totalSize={users.totalElements}
            setSelectedPageSizeOption={setPageSize}
            // style={{width: responsiveWidth}}
          />
        )}
      </Box>
      {selectedUser && (
        <FlexboxColumn
          sx={{
            px: 'x12',
            py: 'x8',
            gap: '32px',
            backgroundColor: 'grey.5',
            borderTopRightRadius: 'x2',
            borderBottomRightRadius: 'x2',
          }}
        >
          <FlexboxColumn sx={{gap: '16px'}}>
            <FieldValue field="Пользователь" value={selectedUser.name} />
            <FieldValue field="Имя" value={selectedUser.name} />
            <FieldValue field="Почта" value={selectedUser.email} />
            <FlexboxColumn sx={{gap: '8px'}}>
              <Typography variant="h4">Роль</Typography>
              <UserSelect
                setValue={setSelectedUserRole}
                value={selectedUserRole || selectedUser.role}
              />
            </FlexboxColumn>
          </FlexboxColumn>
          <Button size="medium" style={{width: 'fit-content'}} onClick={handleUpdateRole}>
            Сохранить
          </Button>
        </FlexboxColumn>
      )}
    </FlexboxRow>
  )
}

export default UsersTable
