import React, {FC} from 'react'
import {defineColors} from '../utils/defineColors'
import {LogoProps} from '../types'

const primary = '#5faf2d'
const getPathColor = defineColors('#000000')

const Logo: FC<LogoProps> = ({color, ...props}) => (
  <svg fill="none" viewBox="0 0 460 99" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M433.664 47.4528L428.54 38.7546L423.508 47.4528H417.493L425.195 34.343L417.34 22.1719H423.63L428.662 30.3068L433.48 22.1719H439.494L431.762 34.7497L439.709 47.4528H433.664Z"
      fill={getPathColor(color)}
    />
    <path
      d="M459.388 38.8817C459.401 40.128 459.158 41.3633 458.675 42.5085C458.193 43.6537 457.481 44.6834 456.586 45.5317C455.69 46.38 454.631 47.0283 453.475 47.4349C452.32 47.8415 451.094 47.9974 449.876 47.8927C448.715 48.0008 447.545 47.8626 446.439 47.4867C445.334 47.1109 444.316 46.5056 443.451 45.709C442.587 44.9124 441.893 43.9418 441.415 42.8583C440.936 41.7748 440.682 40.6019 440.67 39.4136H445.794C445.947 40.4902 446.501 41.4653 447.342 42.1355C448.182 42.8058 449.244 43.1197 450.305 43.0118C450.844 43.0447 451.383 42.9552 451.884 42.75C452.385 42.5447 452.836 42.2287 453.203 41.8247C453.569 41.4207 453.844 40.9388 454.006 40.4137C454.168 39.8885 454.214 39.3333 454.141 38.7879C454.148 38.2233 454.038 37.6635 453.818 37.1453C453.598 36.627 453.273 36.1621 452.865 35.781C452.456 35.3998 451.973 35.1111 451.447 34.9337C450.922 34.7564 450.365 34.6943 449.814 34.7517C449.013 34.7021 448.213 34.87 447.496 35.2382C446.78 35.6065 446.171 36.162 445.733 36.848H440.824L442.511 22.1738H458.651L458.099 27.0861H446.561L446.102 31.9357C446.784 31.3736 447.57 30.9565 448.413 30.7093C449.257 30.4621 450.14 30.3898 451.011 30.4965C452.107 30.4436 453.203 30.6237 454.227 31.0255C455.252 31.4273 456.183 32.0421 456.962 32.8306C457.742 33.6192 458.351 34.5643 458.753 35.606C459.155 36.6478 459.339 37.7633 459.296 38.8817"
      fill={getPathColor(color)}
    />
    <path
      d="M441.273 19.0481C438.511 8.15976 429.858 0.525391 411.847 0.525391H390.091L393.16 20.8941C395.4 39.2289 402.365 45.1424 414.455 47.3325C414.513 47.3483 414.574 47.3493 414.632 47.3355C414.691 47.3217 414.745 47.2936 414.791 47.2535C414.836 47.2134 414.871 47.1625 414.893 47.1054C414.914 47.0483 414.922 46.9867 414.915 46.9259C414.915 46.8456 414.892 46.7671 414.848 46.7002C414.804 46.6334 414.742 46.5812 414.67 46.5503C406.446 43.0148 405.464 31.4382 403.746 19.7989L402.365 10.4125H414.792C426.237 10.4125 435.044 10.2248 440.659 19.2358C440.699 19.2999 440.753 19.3532 440.817 19.3913C440.881 19.4295 440.953 19.4513 441.027 19.4548C441.078 19.455 441.129 19.4443 441.176 19.4235C441.223 19.4027 441.265 19.3723 441.299 19.334C441.334 19.2958 441.36 19.2505 441.377 19.2013C441.393 19.1521 441.4 19.0999 441.396 19.0481"
      fill={getPathColor(color, primary)}
    />
    <path
      d="M38.7544 98.9582C28.587 99.0667 18.7932 95.0541 11.5261 87.8024C4.25903 80.5507 0.113325 70.6534 0 60.286C0 36.7886 19.4846 21.2696 38.7851 21.2383C43.8788 21.0909 48.9495 21.9866 53.6977 23.8724C58.4459 25.7582 62.7752 28.5958 66.4292 32.2174C70.0831 35.839 72.9878 40.1711 74.9712 44.9573C76.9545 49.7435 77.9763 54.8867 77.9763 60.0826C77.9763 65.2786 76.9545 70.4218 74.9712 75.208C72.9878 79.9942 70.0831 84.3262 66.4292 87.9479C62.7752 91.5695 58.4459 94.4071 53.6977 96.2929C48.9495 98.1787 43.8788 99.0744 38.7851 98.927L38.7544 98.9582ZM39.0305 38.3843C34.7915 38.3845 30.6475 39.6636 27.12 42.0607C23.5926 44.4578 20.8395 47.8655 19.2077 51.8548C17.5759 55.8441 17.1384 60.2365 17.9499 64.4789C18.7614 68.7214 20.7856 72.6241 23.7681 75.6956C26.7505 78.7672 30.5576 80.8702 34.7099 81.7399C38.8621 82.6095 43.174 82.2069 47.1024 80.5828C51.0307 78.9586 54.3999 76.1855 56.7856 72.6127C59.1714 69.0399 60.4672 64.8272 60.5096 60.5051C60.62 57.5885 60.1417 54.6799 59.1042 51.9596C58.0667 49.2394 56.4922 46.7656 54.4783 44.6915C52.4644 42.6174 50.0537 40.9872 47.3964 39.9023C44.7391 38.8173 41.8917 38.3007 39.0305 38.3843Z"
      fill={getPathColor(color)}
    />
    <path
      d="M338.227 98.9592C330.745 98.6886 323.507 96.177 317.416 91.7377C311.325 87.2984 306.652 81.128 303.979 73.997C301.306 66.866 300.752 59.0903 302.387 51.6403C304.021 44.1904 307.771 37.3963 313.169 32.1063C318.567 26.8162 325.373 23.2645 332.738 21.8945C340.104 20.5246 347.702 21.397 354.584 24.4029C361.465 27.4089 367.326 32.4152 371.435 38.7968C375.544 45.1785 377.719 52.6529 377.687 60.287C377.599 65.4736 376.508 70.5916 374.475 75.347C372.443 80.1024 369.509 84.4015 365.842 87.9977C362.176 91.5938 357.849 94.4161 353.11 96.3026C348.371 98.189 343.313 99.1025 338.227 98.9905V98.9592ZM338.902 38.2914C334.654 38.2914 330.501 39.5759 326.969 41.9825C323.437 44.3891 320.684 47.8097 319.058 51.8117C317.432 55.8137 317.007 60.2174 317.836 64.4659C318.664 68.7144 320.71 72.6169 323.714 75.68C326.718 78.743 330.545 80.829 334.712 81.6741C338.878 82.5191 343.197 82.0854 347.122 80.4277C351.047 78.77 354.401 75.9627 356.761 72.361C359.121 68.7593 360.381 64.5249 360.381 60.1931C360.381 54.3844 358.118 48.8136 354.09 44.7063C350.062 40.5989 344.599 38.2914 338.902 38.2914Z"
      fill={getPathColor(color)}
    />
    <path
      d="M188.309 98.9536C167.014 98.9536 149.125 81.1819 149.217 60.1562C149.482 49.6934 153.766 39.7552 161.146 32.4812C168.527 25.2073 178.413 21.1795 188.677 21.265C207.61 21.265 227.37 36.3146 227.462 59.9685C227.451 65.1644 226.424 70.3059 224.441 75.0921C222.458 79.8784 219.559 84.2135 215.913 87.8436C212.267 91.4737 207.948 94.326 203.209 96.2337C198.469 98.1413 193.404 99.0659 188.309 98.9536ZM188.309 38.2545C184.049 38.2548 179.886 39.5468 176.348 41.966C172.81 44.3853 170.057 47.8227 168.44 51.8412C166.823 55.8597 166.414 60.2777 167.265 64.5337C168.117 68.7897 170.19 72.6916 173.221 75.7431C176.253 78.7946 180.106 80.858 184.29 81.671C188.475 82.484 192.802 82.0099 196.722 80.3089C200.642 78.608 203.977 75.757 206.303 72.1184C208.63 68.4798 209.843 64.218 209.788 59.8747C209.844 56.9959 209.324 54.1359 208.261 51.4685C207.197 48.8012 205.613 46.3825 203.603 44.3597C201.594 42.337 199.201 40.7526 196.572 39.703C193.943 38.6534 191.131 38.1605 188.309 38.2545Z"
      fill={getPathColor(color)}
    />
    <path
      d="M142.083 98.0126H125.176V69.8532H102.808V98.0126H85.9311V22.2325H102.838V49.8913L104.157 50.3606C117.321 46.2305 120.758 35.1232 121.985 22.2012H139.199C139.229 27.9644 138.094 33.6721 135.866 38.9688C133.639 44.2656 130.365 49.038 126.25 52.9889C126.381 53.2839 126.494 53.5868 126.588 53.8962H141.93L142.083 98.0126Z"
      fill={getPathColor(color)}
    />
    <path
      d="M227.503 98.5795V82.1846C229.221 81.9342 230.817 81.684 232.413 81.5275C234.661 81.4888 236.809 80.5713 238.414 78.9644C240.018 77.3574 240.956 75.1838 241.035 72.8919C241.342 69.7631 241.587 66.4466 241.679 63.2239C242.048 51.3031 242.293 39.4136 242.63 27.524C242.63 25.8658 242.968 24.2388 243.183 22.2363H291.971V98.0477H275.309V39.2884H259.046C258.77 43.1682 258.402 46.8601 258.249 50.5834C257.911 58.9687 257.727 67.3852 257.39 75.8017C256.899 87.2219 250.025 96.5146 240.022 98.4545C235.848 98.8495 231.647 98.8495 227.472 98.4545"
      fill={getPathColor(color)}
    />
  </svg>
)

export default Logo
