import type {FC, ChangeEventHandler} from 'react'
import {Checkbox} from '@root/components/kit'
import FlexboxRow from '@root/components/FlexboxRow'

type Props = {
  onChange: ChangeEventHandler<HTMLInputElement>
  checked: boolean
}

const CheckboxCellContent: FC<Props> = ({checked, onChange}) => (
  <FlexboxRow sx={{width: '100%', justifyContent: 'center'}}>
    <Checkbox checked={checked} onChange={onChange} />
  </FlexboxRow>
)

export default CheckboxCellContent
