import styled from '@emotion/styled'
import FlexboxRow from '@root/components/FlexboxRow'
import RouterLink from '@root/components/RouterLink'
import {
  AppTable,
  AppTableColumn,
  AppTableTab,
  ImprovedCellCopy,
  PatchFilterCollectorColumnsFn,
  SortTextKind,
  useAppTable,
} from '@root/components/appTable'
import {EmptyCell} from '@root/components/appTable/components/EmptyCell'
import {Box, Button} from '@root/components/kit'
import EmptyIconMessage from '@root/components/stubs/EmptyIconMessage'
import {useDownloadManyReportsPdf} from '@root/features/reports/hooks/useDownloadManyReportsPdf'
import {isCommonReport, isReportComparative} from '@root/features/reports/reportComparison'
import {UnionReport} from '@root/features/reports/types'
import {FilterCollectorSystemsCombobox} from '@root/features/systems/components/FilterCollectorSystemsCombobox'
import {PathsV1ReportsProjectionListPostParametersQueryGetReportType as GetReportType} from '@root/openapi'
import {CompareButton} from '@root/pages/report/ReportListPage/components/ReportsTab/CompareButton'
import {useGetUnionReportsQuery} from '@root/redux/api/report/unionReportApi'
import {useGetAllRolesQuery, useGetUserinfoQuery} from '@root/redux/api/userApi'
import {notEmpty} from '@root/utils'
import {Download as DownloadIcon} from '@x5-react-uikit/icons'
import {isEmpty} from 'lodash'
import {FC, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {match} from 'ts-pattern'
import DeleteReportModal from './DeleteReportModal'
import FilterCollectorEditorCombobox from './FilterCollectorEditorCombobox'
import {ReportTableActionsButton} from './ReportTableActionsButton'
import {tableContext, TableContextProvider} from './context'
import {fieldMapper} from './fileldMapper'

const ReportEditorCell: FC<{editor?: UnionReport['editor']}> = ({editor}) => {
  const ctx = useContext(tableContext)

  if (ctx?.rolesMap == null || editor == null) {
    return <EmptyCell />
  }

  return (
    <ImprovedCellCopy
      copiedText={editor.email}
      shownText={editor.email || ''}
      style={{padding: '12px'}}
      successMessage="Почта скопирована в буфер обмена"
    />
  )
}

const ReportsEmptyMessage = () => (
  <EmptyIconMessage
    message="Когда будет создан отчёт, он появится в этой таблице
             и будет доступен для редактирования."
    sx={{width: '1200px', height: '600px', backgroundColor: 'white'}}
    title="Отчёты отсутствуют"
  />
)

export enum ReportsTabKind {
  all,
  reports,
  comparativeReports,
}

function mapTabEnum(currentTab: ReportsTabKind) {
  return match(currentTab)
    .with(ReportsTabKind.all, () => GetReportType.All)
    .with(ReportsTabKind.reports, () => GetReportType.OnlyReport)
    .with(ReportsTabKind.comparativeReports, () => GetReportType.OnlyComparativeReport)
    .otherwise(() => {
      throw new Error('Out of range')
    })
}

const tabs: AppTableTab<ReportsTabKind>[] = [
  {
    label: 'Все',
    value: ReportsTabKind.all,
  },
  {
    label: 'Отчеты',
    value: ReportsTabKind.reports,
  },
  {
    label: 'Сравнительные отчеты',
    value: ReportsTabKind.comparativeReports,
  },
]

const StyledList = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;

  & > div {
    flex: 1;
  }
`

const baseColumns: AppTableColumn<UnionReport>[] = [
  {
    isCheckbox: true,
    width: 40,
  },
  {
    dataIndex: 'tasks',
    title: 'Заявка',
    width: '13.33333%',
    preventRowClickHandler: true,
    renderCell: (_value, row) => (
      <StyledList>
        {row.tasks.map((x) => (
          <div key={x.id}>
            <RouterLink to={`/tasks/${x.id}`}>#{x.number}</RouterLink>
          </div>
        ))}
      </StyledList>
    ),
  },
  {
    dataIndex: 'name',
    title: 'Название отчета',
    width: '36.666666%',
    sortable: {
      enabled: true,
      text: SortTextKind.alphabetical,
    },
  },
  {
    dataIndex: 'createdAt',
    title: 'Дата и время создания',
    valueRenderType: 'dateTime',
    width: '13.333333%',
    sortable: {
      enabled: true,
      text: SortTextKind.newestFirst,
    },
  },
  {
    dataIndex: 'updatedAt',
    title: 'Дата и время редактирования',
    valueRenderType: 'dateTime',
    width: '13.333333%',
    sortable: {
      enabled: true,
      text: SortTextKind.newestFirst,
    },
  },
  {
    dataIndex: 'editor',
    title: 'Редактор',
    width: 'auto',
    sortable: {
      enabled: true,
      text: SortTextKind.alphabetical,
    },
    preventRowClickHandler: true,
    filterableOptions: {
      type: 'custom',
      value: 'editorId',
      customInputComponent: FilterCollectorEditorCombobox,
    },
    bodyCellStyle: {
      padding: 0,
      height: 1,
    },
    renderCell: (_value, row) => <ReportEditorCell editor={row.editor} />,
  },
]

function getId(report: UnionReport) {
  if (report.id == null) {
    throw new Error('Id was null')
  }

  return report.id
}

function reportGroupBySystem(report: UnionReport) {
  const show = report?.system.tag ?? report?.systemName ?? ''
  return isEmpty(show) ? 'Без названия' : `ИС: ${show}`
}

const patchFilterCollectorColumns: PatchFilterCollectorColumnsFn = (columns) => {
  return [
    {
      name: 'Информационная система',
      value: 'systemId',
      type: 'custom',
      customInputComponent: FilterCollectorSystemsCombobox,
    },
    {
      value: 'taskNumber',
      type: 'number',
      name: '№ заявки',
    },
    ...columns,
  ]
}

export const ReportsTable = () => {
  const {tableState, tableProps} = useAppTable<UnionReport, ReportsTabKind>({
    pages: {
      currentPage: 1,
      pageSize: 10,
    },
    filterValues: [],
    sort: [
      {
        name: 'createdAt',
        value: 'desc',
      },
    ],
    currentTab: ReportsTabKind.all,
  })

  const navigate = useNavigate()

  const {data: rolesMap} = useGetAllRolesQuery()
  const {data: userInfo} = useGetUserinfoQuery()

  const reportsById = useRef(new Map<string, UnionReport>())

  const {
    data: cachedReports,
    currentData: reports,
    isFetching: isReportsLoading,
    isError: isReportsError,
  } = useGetUnionReportsQuery({
    getReportType: mapTabEnum(tableState.currentTab),
    requestBody: fieldMapper(tableState),
  })

  useEffect(() => {
    reports?.items.forEach((x) => {
      reportsById.current.set(x.id, x)
    })
  }, [reports?.items])

  const checkedReports = useMemo(() => {
    if (tableProps.checkedRows == null) {
      return []
    }

    return tableProps.checkedRows.map((x) => reportsById.current.get(x)).filter(notEmpty)
  }, [tableProps.checkedRows])

  const [reportToDelete, setReportToDelete] = useState<UnionReport>()

  const handleRowClick = useCallback(
    (report: UnionReport) => {
      if (isCommonReport(report)) {
        navigate(`/reports/${report.id}`)
      } else if (isReportComparative(report)) {
        navigate(`/reports/comparative/${report.id}`)
      } else {
        console.error(report)
        throw new Error(`Unknown type: not a common or comparative report`)
      }
    },
    [navigate]
  )

  const {
    download: downloadPdf,
    disabled: exportDisabled,
    loading: exportLoading,
  } = useDownloadManyReportsPdf(checkedReports)

  const columns: AppTableColumn<UnionReport>[] = useMemo(() => {
    if (userInfo.isAdmin || userInfo.isManager || userInfo.isSpecialist) {
      return [
        ...baseColumns,
        {
          key: 'actions',
          width: 40,
          title: '',
          bodyCellStyle: {
            padding: '8px 0 0 0',
            textAlign: 'center',
            verticalAlign: 'top',
          },
          preventRowClickHandler: true,
          renderCell: (_value, row) => <ReportTableActionsButton report={row} />,
        },
      ]
    }

    return baseColumns
  }, [userInfo])

  return (
    <TableContextProvider
      value={{
        onUpdate: (report) =>
          navigate(
            match(report.isComparative)
              .with(true, () => `/reports/comparative/${report.id}/update`)
              .with(false, () => `/reports/${report.id}/update`)
              .exhaustive()
          ),
        onDelete: (report) => setReportToDelete(report),
        userInfo,
        rolesMap,
      }}
    >
      <Box sx={{backgroundColor: 'white', minWidth: 1200}}>
        <AppTable
          noSideBorders
          cachedData={cachedReports?.items ?? []}
          columns={columns}
          components={{
            Row: {
              style: {cursor: 'pointer'},
            },
            FilterCollector: {
              size: 'large',
            },
            HeadFlexboxRow: {
              sx: {
                py: 'x8',
                height: 'auto',
              },
            },
            Cell: {
              style: {
                padding: '12px',
              },
            },
            Head: {
              style: {
                verticalAlign: 'top',
                whiteSpace: 'unset',
              },
            },
            HeadCell: {
              style: {
                padding: '12px',
              },
            },
          }}
          customEmpty={<ReportsEmptyMessage />}
          data={reports?.items ?? []}
          error={isReportsError}
          getCheckboxRowId={getId}
          getId={getId}
          groupBy={{
            getIdentity: reportGroupBySystem,
            getDefaultOpen: () => true,
          }}
          loading={isReportsLoading}
          pagination={
            cachedReports
              ? {
                  currentPage: tableState.pages?.currentPage ?? 1,
                  pageSize: tableState.pages?.pageSize ?? 10,
                  totalCount: cachedReports.totalElements,
                  totalPages: cachedReports.totalPages,
                }
              : undefined
          }
          patchFilterCollectorColumns={patchFilterCollectorColumns}
          renderHead={({uncheckWithDividerFn}) => (
            <FlexboxRow sx={{justifyContent: 'end', gap: '16px', flex: 1}}>
              {uncheckWithDividerFn()}
              <Button
                disabled={!checkedReports?.length || exportDisabled}
                loading={exportLoading}
                startIcon={<DownloadIcon size="small" />}
                variant="text"
                onClick={downloadPdf}
              >
                Скачать
              </Button>
              <CompareButton reports={checkedReports} />
            </FlexboxRow>
          )}
          style={{
            width: '1200px',
            maxWidth: '1200px',
            minHeight: 400,
          }}
          tabs={{
            current: tableState.currentTab,
            list: tabs,
          }}
          onRowClick={handleRowClick}
          {...tableProps}
        />
        <DeleteReportModal report={reportToDelete} onClose={() => setReportToDelete(null)} />
      </Box>
    </TableContextProvider>
  )
}

export default ReportsTable
