import React, {FC} from 'react'
import {getPxFromSize} from '@x5-react-uikit/icons'
import {Loader} from './Loader'
import {LoaderIconProps} from './types'
import {restyled} from '../styles'

const StyledLoader = restyled(Loader)({color: 'inherit'})

export const LoaderIcon: FC<LoaderIconProps> = ({className, size}) => {
  const sizeValue = getPxFromSize(size)
  return <StyledLoader className={className} size={sizeValue} />
}

export default LoaderIcon
