import {AppTableState} from '@root/components/appTable'
import {GetLaunchesPayload} from '@root/redux/api/launchesApi'
import {System} from '@root/features/systems/types'
import {
  components,
  FilterItemType,
  FilterWithSortPagedRequestSort,
  SortRequestType,
} from '@root/openapi'
import {FilterCollectorFilter} from '@root/components/kit'
import {ExtractConstValues} from '@root/core/helperTypes'
import {sortDirections} from '@x5-react-uikit/core'
import {isEmpty} from 'lodash'
import {SortItemRequest} from '@root/core/types'

// TODO: Same code structure as from LaunchHistoryTable's fieldMapper. Could be refactored, but later

function sortValueMapper(tableFormat: ExtractConstValues<typeof sortDirections>): SortRequestType {
  switch (tableFormat) {
    case 'asc':
      return SortRequestType.ASC
    case 'desc':
      return SortRequestType.DESC
    default:
      throw new Error('Out of range')
  }
}

function sortNameMapper(field: keyof System | string): string {
  if (field === 'currentEditor') {
    return 'currentEditor.editor.email'
  }
  return field
}

function getExpressionForField(
  field: FilterCollectorFilter
): [string, components['schemas']['FilterItem']] {
  const fieldName = field.name
  const value = field.value as any

  switch (fieldName) {
    case 'name':
      return ['name', {value, type: FilterItemType.LIKE}]
    case 'system':
      return ['launch.system.id', {value, type: FilterItemType.EQUAL}]
    case 'currentEditor':
      if (isEmpty(value)) {
        return [null, null]
      }
      return ['currentEditor.id', {value, type: FilterItemType.EQUAL}]
    default:
      throw new Error('Out of range')
  }
}

export function fieldMapper(
  tableState: AppTableState<System>,
  customerEmail?: string
): GetLaunchesPayload['filter'] {
  const filterExpressions = tableState.filterValues
    .map(getExpressionForField)
    .filter(([, value]) => !isEmpty(value))
    .reduce((acc, [key, value]) => ({...acc, [key]: value}), {})

  let sortExpressions: GetLaunchesPayload['filter']['sortList'] = (tableState.sort ?? [])
    .filter((sortItem) => sortItem.value !== 'unset')
    .reduce(
      (acc, sortItem) => [
        ...acc,
        {
          value: sortNameMapper(sortItem.name),
          type: sortValueMapper(sortItem.value),
        },
      ],
      []
    )

  if (filterExpressions['currentEditor.id'] != null) {
    sortExpressions = sortExpressions.filter(
      (sortRequestItem) => sortRequestItem.value !== 'currentEditor.editor.email'
    )
    // delete sortExpressions['currentEditor.editor.email']
  }

  if (!isEmpty(customerEmail)) {
    filterExpressions['launch.task.creator.email'] = {
      value: customerEmail,
      type: FilterItemType.EQUAL,
    }
  } else if (isEmpty(filterExpressions['launch.system.id'])) {
    // TODO: KLUDGE
    sortExpressions.unshift({
      value: 'launch.system',
      type: SortRequestType.DESC,
    })
  }

  return {
    filters: filterExpressions,
    sortList: sortExpressions,
    pageNumber: tableState.pages?.currentPage ?? 1,
    pageSize: tableState.pages?.pageSize ?? 20,
  }
}
