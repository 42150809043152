import type {FC} from 'react'
import {Box, BoxProps} from 'ui-kit'

const FlexboxColumn: FC<BoxProps> = ({children, sx, ...props}) => (
  <Box sx={{display: 'flex', flexDirection: 'column', ...sx}} {...props}>
    {children}
  </Box>
)

export default FlexboxColumn
