import {createApi} from '@reduxjs/toolkit/query/react'
import redirectLoginOn401Query from './queries/redirectLoginOn401Query'
import {OpenApiRequestBody, OpenApiResponseType} from '@root/core/helperTypes'

const tags = ['Templates']

const basePath = (systemId: string) => `/systems/${systemId}/variables`

export type GetTemplatesListResponse = OpenApiResponseType<
  '/v1/systems/{systemId}/variables/list',
  'post'
>
export type GetTemplatesListPayload = {
  systemId: string
  filter: OpenApiRequestBody<'/v1/systems/{systemId}/variables/list'>
}

export type GetTemplateByIdResponse = OpenApiResponseType<'/v1/systems/{systemId}/variables/{id}'>
export type GetTemplateByIdPayload = {
  systemId: string
  templateId: string
}

export type CreateTemplateResponse = OpenApiResponseType<'/v1/systems/{systemId}/variables', 'post'>
export type CreateTemplatePayload = {
  systemId: string
  createData: OpenApiRequestBody<'/v1/systems/{systemId}/variables', 'post'>
}

export type UpdateTemplateResponse = OpenApiResponseType<
  '/v1/systems/{systemId}/variables/{id}',
  'patch'
>
export type UpdateTemplatePayload = {
  systemId: string
  templateId: string
  updateData: OpenApiRequestBody<'/v1/systems/{systemId}/variables/{id}', 'patch'>
}

export type DeleteTemplateResponse = OpenApiResponseType<
  '/v1/systems/{systemId}/variables/{id}',
  'delete'
>
export type DeleteTemplatePayload = {
  systemId: string
  templateId: string
}

export const templatesApi = createApi({
  reducerPath: 'templatesApi',
  tagTypes: tags,
  baseQuery: redirectLoginOn401Query(),
  endpoints: (build) => ({
    getTemplates: build.query<GetTemplatesListResponse, GetTemplatesListPayload>({
      query: ({systemId, filter}) => ({
        url: `${basePath(systemId)}/list`,
        method: 'POST',
        body: filter,
      }),
      providesTags: tags,
    }),
    getTemplateById: build.query<GetTemplateByIdResponse, GetTemplateByIdPayload>({
      query: ({systemId, templateId}) => `${basePath(systemId)}/${templateId}`,
      providesTags: tags,
    }),
    createTemplate: build.mutation<CreateTemplateResponse, CreateTemplatePayload>({
      query: ({systemId, createData}) => ({
        url: basePath(systemId),
        method: 'POST',
        body: createData,
      }),
      invalidatesTags: tags,
    }),
    updateTemplate: build.mutation<UpdateTemplateResponse, UpdateTemplatePayload>({
      query: ({systemId, templateId, updateData}) => ({
        url: `${basePath(systemId)}/${templateId}`,
        method: 'PATCH',
        body: updateData,
      }),
      invalidatesTags: tags,
    }),
    deleteTemplate: build.mutation<DeleteTemplateResponse, DeleteTemplatePayload>({
      query: ({systemId, templateId}) => ({
        url: `${basePath(systemId)}/${templateId}`,
        method: 'DELETE',
      }),
      invalidatesTags: tags,
    }),
  }),
})

export const {
  useGetTemplatesQuery,
  useGetTemplateByIdQuery,
  useCreateTemplateMutation,
  useDeleteTemplateMutation,
  useUpdateTemplateMutation,
} = templatesApi
