import React from 'react'
import createLogo from '../utils/createSvgLogo'
import {logoVariants, logoColors, logoBrands} from '../types'
import ChizhikTextFull from './Full'
import ChizhikSmall from '../Chizhik/Small'

export default createLogo((variant, color = logoColors.multi, partialProps) => {
  const props = {
    color,
    width: '100%',
    height: '100%',
    ...partialProps,
  }

  if (variant === logoVariants.icon) return <ChizhikSmall {...props} />

  return <ChizhikTextFull {...props} />
}, `${logoBrands.chizhikText}Logo`)
