import React, {FC} from 'react'
import {Loader} from './Loader'
import {Typography} from '../Typography'
import {LoaderItemProps} from './types'
import {classes, StyledItem} from './styles'

export const LoaderItem: FC<LoaderItemProps> = ({size = '16px', text}) => (
  <StyledItem className={classes.item}>
    <Loader size={size} />
    <Typography variant="p1compact">{text}</Typography>
  </StyledItem>
)

export default LoaderItem
