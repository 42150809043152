import {Typography} from '@x5-react-uikit/core'
import FlexboxColumn from '@root/components/FlexboxColumn'
import {ReactComponent as EmptySvg} from 'assets/Empty.svg'

const EmptyIconMessage = ({title = 'Список пуст.', message, sx}) => {
  return (
    <FlexboxColumn
      sx={{mt: 'x12', alignItems: 'center', justifyContent: 'center', height: '184px', ...sx}}
    >
      <EmptySvg />
      <FlexboxColumn sx={{mt: 'x20', gap: '8px', alignItems: 'center'}}>
        <Typography variant="h4">{title}</Typography>
        <Typography style={{textAlign: 'center'}} variant="p1">
          {message}
        </Typography>
      </FlexboxColumn>
    </FlexboxColumn>
  )
}

export default EmptyIconMessage
